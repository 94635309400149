"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SendNotificationOnApprovedOrRejectedTracking = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var AuthClient_1 = require("upmesh-auth-core/build/src/client/AuthClient");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var UserEntity_1 = require("upmesh-auth-core/build/src/client/query/entities/UserEntity");
var uuid = require("uuid");
var LogBookEntryCreated_1 = require("../../../client/events/logbook/LogBookEntryCreated");
var NotificationCreated_1 = require("../../../client/events/logbook/NotificationCreated");
var NotificationsSettingsEntity_1 = require("../../../client/query/entities/NotificationsSettingsEntity");
var UserOrCompany_1 = require("../UserOrCompany");
var CommandReadModels_1 = require("./CommandReadModels");
var SendNotificationOnApprovedOrRejectedTracking = function (_CommandRoot_1$Comman) {
  function SendNotificationOnApprovedOrRejectedTracking(data, trackingId) {
    var _this;
    (0, _classCallCheck2.default)(this, SendNotificationOnApprovedOrRejectedTracking);
    _this = _callSuper(this, SendNotificationOnApprovedOrRejectedTracking, [data, trackingId, CurrentUser_1.CurrentUser.token]);
    _this.aggregate = 'company';
    _this.commandName = 'SendNotificationOnApprovedOrRejectedTracking';
    return _this;
  }
  (0, _inherits2.default)(SendNotificationOnApprovedOrRejectedTracking, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(SendNotificationOnApprovedOrRejectedTracking, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var n = [];
        var member = yield CommandReadModels_1.CommandReadModels.instance.companyMember.getById(this.data.memberId);
        if (member.userId != null) {
          try {
            var user = yield UserOrCompany_1.UserOrCompany.getById(member.userId);
            var settings = new NotificationsSettingsEntity_1.NotificationsSettingsEntity({
              userId: user.id
            });
            try {
              var userSettings = yield CommandReadModels_1.CommandReadModels.instance.notificationsSettings.get({
                filter: `userId eq '${user.id}'`,
                top: 1
              });
              if (userSettings.length > 0) settings = userSettings[0];
            } catch (e) {}
            settings.userId = user.id;
            var memberName = '';
            if (this.data.memberId) {
              try {
                var _member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(this.data.memberId);
                var _user = new UserEntity_1.UserEntity({
                  id: _member.id,
                  firstname: _member.firstName,
                  lastname: _member.lastName
                });
                if (_member.userId != null) {
                  try {
                    _user = yield AuthClient_1.AuthClient.instance.modals.user.getById(_member.userId);
                  } catch (e) {}
                }
                memberName = _user.getFullName();
              } catch (e) {}
            }
            var creatorName = '';
            try {
              var creatorUser = yield AuthClient_1.AuthClient.instance.modals.user.getById(this.data.actionBy);
              creatorName = creatorUser.getFullName();
            } catch (e) {}
            var eventName = this.data.approved ? 'PendingApprovalApproved' : 'PendingApprovalRejected';
            var event = new LogBookEntryCreated_1.LogBookEntryCreated(uuid.v1(), {
              eventName: eventName,
              creator: this.data.actionBy,
              userIds: [{
                userId: user.id
              }],
              creatorName: creatorName,
              entityId: this.entityId,
              entityTitle: '',
              entityType: 'Tracking',
              data: {
                memberName: memberName,
                creatorName: creatorName,
                onType: this.data.trackingType,
                forDay: this.data.trackingForDay
              }
            }, user.id);
            n.push(event);
            if (settings.approvedPermits) {
              var not = new NotificationCreated_1.NotificationCreated(uuid.v1(), {
                event: event,
                userId: user.id,
                now: !!settings.notifyNow,
                email: settings.email,
                sendPush: !!settings.pushNotifications
              }, user.id);
              n.push(not);
            }
          } catch (e) {}
        }
        return n;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.SendNotificationOnApprovedOrRejectedTracking = SendNotificationOnApprovedOrRejectedTracking;