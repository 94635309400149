import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Dialog, DialogState } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { KeyboardEvents } from 'materialTheme/src/theme/components/utils/KeyboardEvents';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
export function PayrollPeriodSelector(props) {
    const [data, setData] = useState();
    const loadData = async () => {
        if (CompanyUserInfo.company == null)
            return;
        const d = await UpmeshClient.instance.modals.payrollAccountingPeriods.get({
            filter: `companyId eq '${CompanyUserInfo.company.id}'`,
            orderby: 'starts DESC',
        });
        setData(d);
    };
    useEffect(() => {
        loadData().catch((err) => console.error(err));
    }, []);
    const prev = () => {
        if (data != null && data.length > 0) {
            const selectedIndex = data.findIndex((a) => a.id === props.selectedId) + 1;
            if (data.length > selectedIndex)
                props.onChange(data[selectedIndex].id);
        }
    };
    const next = () => {
        if (data != null && data.length > 0) {
            const selectedIndex = data.findIndex((a) => a.id === props.selectedId) - 1;
            if (data.length > selectedIndex && selectedIndex > -1)
                props.onChange(data[selectedIndex].id);
        }
    };
    useEffect(() => {
        KeyboardEvents.onKeyDown((e) => {
            if (Dialog.instance?.dialogState === DialogState.opened)
                return;
            switch (e.keyCode) {
                case 37:
                    e.preventDefault();
                    if (!e.altKey)
                        prev();
                    break;
                case 39:
                    e.preventDefault();
                    if (!e.altKey)
                        next();
                    break;
            }
        }, 'PayrollPeriodSelector');
        return () => {
            KeyboardEvents.remove('PayrollPeriodSelector');
        };
    }, [props.selectedId, data]);
    const openSelector = (e) => {
        if (data == null || data.length === 0) {
            return;
        }
        const p = [];
        for (const period of data) {
            p.push(<Ripple style={{ width: '100%', padding: 8, flexDirection: 'row', justifyContent: 'space-between' }} onPress={() => {
                    Dialog.instance?.close(() => {
                        props.onChange(period.id);
                    });
                }}>
          <MaterialText>{`${I18n.m.dateCurrent.monthsNames()[period.starts.getMonth()]} ${period.starts.getFullYear()}`}</MaterialText>
          <MaterialText>{`${I18n.m.dateCurrent.localeDateString(period.starts)} - ${I18n.m.dateCurrent.localeDateString(period.ends)}`}</MaterialText>
        </Ripple>);
        }
        const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
        Dialog.instance?.open({
            openPosition,
            contentPadding: false,
            content: (<>
          <DialogTitle>{I18n.m.getMessage('timeTrackingChoosePeriod')}</DialogTitle>
          <DialogContent>{p}</DialogContent>
        </>),
        });
    };
    if (data == null || data.length === 0)
        return null;
    const selectedIndex = data.findIndex((a) => a.id === props.selectedId);
    if (selectedIndex === -1)
        return null;
    const selected = data[selectedIndex];
    return (<View>
      <Chip thumbnail={<Icon icon="chevron-left" disabled={selectedIndex >= data.length} toolTip={`${I18n.m.getMessage('prev')} (${I18n.m.getMessage('hotkeyLeft')})`} onPress={prev}/>} backgroundColor="#FFFFFF" removeIconSize={24} removeIcon="chevron-right" removeIconTooltip={`${I18n.m.getMessage('next')} (${I18n.m.getMessage('hotkeyRight')})`} button onPressButton={next} title={`${I18n.m.dateCurrent.localeDateString(selected.starts)} - ${I18n.m.dateCurrent.localeDateString(selected.ends)}`} onPressText={openSelector}/>
    </View>);
}
