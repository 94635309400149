import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Page } from 'materialTheme/src/theme/components/Page';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialTextWithOutToolTip';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AddressEntity } from 'upmesh-auth-core/src/client/query/entities/AddressEntity';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ChangePlanTags } from 'upmesh-core/src/client/commands/plans/ChangePlanTags';
import { CreateMap } from 'upmesh-core/src/client/commands/plans/CreateMap';
import { CreatePlan } from 'upmesh-core/src/client/commands/plans/CreatePlan';
import { DeletePlan } from 'upmesh-core/src/client/commands/plans/DeletePlan';
import { PlanEntity } from 'upmesh-core/src/client/query/entities/PlanEntity';
import { PlanFilter } from 'upmesh-core/src/client/query/filter/PlanFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { Config } from '../../config/Config';
import { I18n } from '../../i18n/I18n';
import { upmeshSession } from '../../utils/Storage';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { FabWithCamera } from '../FabWithCamera';
import { NoRights } from '../NoRights';
import { CurrentProject } from '../project/CurrentProject';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { PageView } from '../root/PageView';
import { TicketWithPlanDownloader } from '../tickets/TicketWithPlanDownloader';
import EmptyPlanListView from './EmptyPlanListView';
import PlanReadyForReleaseNotice from './PlanReadyForReleaseNotice';
import { openPlanFilterDialog } from './PlansFilterDialog';
import { PlanTagFilterIcon } from './PlanTagFilterIcon';
import { PlanThumb169 } from './PlanThumb169';
const notFoundImage = require('../../assets/img/no_blueprint.png');
export const PlansView = React.memo((props) => {
    const [error, setError] = useState('');
    const [init, setInit] = useState(false);
    const [plansTempNumber, setPlansTempNumber] = useState(0);
    const [searchWords, setSearchWords] = useState('');
    const [visiblePlans, setVisiblePlans] = useState([]);
    const [tableViewModeEnabled, setTableViewModeEnabled] = useState(false);
    const [releasePlanHeight, setReleasePlanHeight] = useState(0);
    const [plans, setPlans] = useState([]);
    const [filter, setFilter] = useState(new PlanFilter());
    const mounted = useRef(false);
    const counterTO = 0;
    const filterKey = `planfilter_${props.projectId}`;
    const updateVisiblePlans = async (filter, search) => {
        let visiblePlans = [...plans];
        for (const plan of visiblePlans) {
            const versions = await UpmeshClient.instance.modals.planVersion.get({
                filter: `planId eq '${plan.id}' and deleted ne true`,
            });
            plan.numberOfVersions = versions.length > 0 ? versions.length : 1;
            plan.versions = versions;
        }
        const searchText = search != null ? search : searchWords;
        if (searchText != null && searchText.length > 0) {
            visiblePlans = await PlanFilter.filterPlansByText(searchText, visiblePlans);
        }
        if (filter != null && PlanFilter.isSet(filter)) {
            visiblePlans = await PlanFilter.filterPlans(visiblePlans, filter);
        }
        setVisiblePlans([...visiblePlans]);
    };
    const handlePlanChange = (p) => {
        setPlans([...p]);
    };
    useEffect(() => {
        updateVisiblePlans(filter).catch((err) => console.debug(err));
    }, [plans]);
    const onTempPlansChanged = (_e) => {
        const { projectId } = props;
        UpmeshClient.instance.modals.planFiles
            .count(`projectId eq '${projectId}' and uploaded eq true and processed ne true`)
            .then((count) => {
            setPlansTempNumber(count);
        })
            .catch((err) => console.error(err));
    };
    const initFunction = async () => {
        if (CurrentUser.entity == null) {
            setError('forbidden');
            setInit(true);
            return;
        }
        if (!mounted.current)
            return;
        const { projectId } = props;
        const userId = CurrentUser.entity.id;
        const [canRead, planListPageTableViewModeEnabled] = await Promise.all([
            RightsManager.hasReadRight(projectId, userId, 'plans'),
            SimpleStorage.get('planListPageTableViewModeEnabled'),
        ]);
        if (!canRead) {
            setError('forbidden');
            setInit(true);
            return;
        }
        setPlans([...CurrentProject.instance.getCurrentPlans()]);
        for (const plan of plans) {
            const versions = await UpmeshClient.instance.modals.planVersion.get({
                filter: `planId eq '${plan.id}' and deleted ne true`,
            });
            plan.numberOfVersions = versions.length > 0 ? versions.length : 1;
            plan.versions = versions;
        }
        setInit(true);
        setTableViewModeEnabled(planListPageTableViewModeEnabled === 'true');
        updateVisiblePlans(filter).catch((err) => console.debug(err));
        onTempPlansChanged();
    };
    useEffect(() => {
        mounted.current = true;
        if (props.fp != null) {
            try {
                setFilter(props.fp != null ? JSON.parse(props.fp) : new PlanFilter());
            }
            catch (e) {
                setFilter(new PlanFilter());
                console.debug('cant parse ticketfilter');
            }
        }
        else {
            const f = upmeshSession.getItem(filterKey);
            if (f) {
                Routing.instance.changeQueryParameter({
                    fp: JSON.stringify(filter),
                });
                setFilter(f);
            }
        }
        CurrentProject.plansChanged.attach(handlePlanChange);
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'PlanFiles',
            callback: onTempPlansChanged,
            attachKey: 'PlansView',
        });
        initFunction().catch((err) => console.debug(err));
        return () => {
            UpmeshClient.eventDispatcher.detach('PlanFiles', 'PlansView');
            CurrentProject.plansChanged.detach(handlePlanChange);
            if (counterTO != null) {
                clearTimeout(counterTO);
            }
        };
    }, []);
    const onSearch = (text) => {
        setSearchWords(text);
        updateVisiblePlans(filter, text).catch((err) => console.debug(err));
        Routing.instance.changeQueryParameter({ q: text });
    };
    const pressCreateMap = async (e) => {
        if (error != null && error.length > 0) {
            Routing.instance.alert.post({ text: I18n.m.getMessage(error) });
            return;
        }
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (projectId == null) {
            return;
        }
        const catched = await DefaultErrorHandler.getProjectErrors(projectId);
        if (catched) {
            return;
        }
        const c = new CreateMap({
            activatedBy: '',
            activatedOn: new Date(),
            projectId,
            title: '',
            address: new AddressEntity(),
        });
        c.canI()
            .then(() => Routing.instance.openDialog('addMap', {})(e))
            .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
    };
    const pressAddPlan = async (e) => {
        if (error != null && error.length > 0) {
            Routing.instance.alert.post({ text: I18n.m.getMessage(error) });
            return;
        }
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (projectId == null) {
            return;
        }
        const catched = await DefaultErrorHandler.getProjectErrors(projectId);
        if (catched) {
            return;
        }
        const c = new CreatePlan({ activatedBy: '', activatedOn: new Date(), activePlanId: '', projectId, title: '' });
        c.canI()
            .then(() => Routing.instance.openDialog('uploadPlan', {})(e))
            .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
    };
    const pressTempPlan = async (e) => {
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (projectId != null) {
            Routing.instance.openDialog('tempPlans', { projectId })(e);
        }
    };
    const deletePlanNow = (item) => () => {
        Alert.instance?.close(() => {
            try {
                const d = new DeletePlan({}, item.id);
                d.execute()
                    .then(() => {
                    const index = plans.findIndex((p) => p.id === item.id);
                    if (index > -1) {
                        plans.splice(index, 1);
                        updateVisiblePlans(filter).catch((err) => console.debug(err));
                    }
                })
                    .catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err, I18n.m, false);
                });
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, false);
            }
        });
    };
    const deletePlan = (item) => () => {
        RightsManager.hasWriteRight(item.projectId, CurrentUser.userId, 'commandDiscardPlanFile')
            .then((hasRight) => {
            if (!hasRight)
                throw { message: 'Keine Berechtigung', messageCode: 'forbiddenCommand' };
            return UpmeshClient.instance.modals.ticket.get({ filter: `planId eq '${item.id}'`, top: 1 });
        })
            .then((tickets) => {
            let text = I18n.m.getMessage('planDeleteQuestionTextWithoutTickets');
            if (tickets.length > 0)
                text = I18n.m.getMessage('planDeleteQuestionTextWithTickets');
            Routing.instance.alert.post({
                text,
                title: I18n.m.getMessage('planDeleteQuestionTitle', { plantitle: item.title }),
                buttons: [
                    <ContainedButton key="cancel" onPress={Alert.instance?.close} title={I18n.m.getMessage('cancel')}/>,
                    <ContainedButton key="del" onPress={deletePlanNow(item)} title={I18n.m.getMessage('delete')} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        })
            .catch((e) => {
            DefaultErrorHandler.showDefaultErrorAlert(e);
        });
    };
    const toggleTableViewModeEnabled = () => {
        SimpleStorage.set('planListPageTableViewModeEnabled', (!tableViewModeEnabled).toString());
        setTableViewModeEnabled(!tableViewModeEnabled);
    };
    const downloadPlan = (item) => () => {
        const project = CurrentProject.instance.getCurrentProject();
        UpmeshClient.instance.modals.planVersion
            .getById(item.activePlanId)
            .then((planVersion) => {
            if (!project?.showPlanQrCode) {
                const ex = planVersion.orgFilename.substr(planVersion.orgFilename.lastIndexOf('.'));
                CurrentProject.instance.downloadPlanNow(item, planVersion, ex).catch((error) => {
                    DefaultErrorHandler.showDefaultErrorAlert(error, I18n.m, error);
                });
            }
            else {
                const pdfStarter = new TicketWithPlanDownloader([], planVersion, item, project, Config.b2cURL);
                pdfStarter.open(false).catch((e) => {
                    console.error('error on downloading/creating pdf with tickets as download', e);
                });
            }
        })
            .catch((error) => {
            DefaultErrorHandler.showDefaultErrorAlert(error, I18n.m, error);
        });
    };
    const changeView = (index, _button) => {
        setTableViewModeEnabled(index === 1);
        SimpleStorage.set('planListPageTableViewModeEnabled', (index === 1).toString());
    };
    const editTags = (plan) => (e) => {
        const projectTags = [];
        const project = CurrentProject.instance.getCurrentProject();
        if (project == null || !project.tagGroups)
            return;
        const projectTagGroups = [];
        for (const key in project.tagGroups) {
            projectTagGroups.push({
                backgroundColor: project.tagGroups[key].color,
                groupName: project.tagGroups[key].groupName,
                id: project.tagGroups[key].groupName,
            });
        }
        project.tags?.forEach((t) => {
            projectTags.push({
                id: t.tagName,
                groupId: t.groupName,
                title: t.tagName,
                backgroundColor: t.color,
                secondTextLine: t.groupName,
                thumbnail: <View style={{ height: 24, width: 24, borderRadius: 12, backgroundColor: t.color }}/>,
            });
        });
        const planTags = [];
        if (plan != null && plan.tags != null && plan.tags.length > 0) {
            plan.tags.forEach((t) => {
                planTags.push({
                    id: t.tagName,
                    title: t.tagName,
                    backgroundColor: t.color,
                    groupId: t.groupName,
                    secondTextLine: t.groupName,
                    thumbnail: <View style={{ height: 24, width: 24, borderRadius: 12, backgroundColor: t.color }}/>,
                });
            });
        }
        const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
        const listItems = [];
        for (let i = 0; i < projectTags.length; i += 1) {
            const aChip = projectTags[i];
            if (aChip.title != null) {
                listItems.push({
                    selected: planTags != null && planTags.length > 0 ? planTags.findIndex((a) => a.title === aChip.title) > -1 : false,
                    secondTextLine: aChip.secondTextLine,
                    thirdTextLine: aChip.thirdTextLine,
                    thumbnail: aChip.thumbnail ? { thumbnail: aChip.thumbnail, width: 24, rounded: true } : undefined,
                    title: aChip.title,
                    groupId: aChip.groupId,
                    id: aChip.id ? aChip.id : aChip.title,
                });
            }
        }
        Dialog.instance?.open({
            openPosition,
            content: (<ChipDialogForm key="TagsChipDialogForm" title={I18n.m.getMessage('tags')} sortByGroup items={listItems} chipGroups={projectTagGroups} onCancel={Dialog.instance.close} multiselect onSave={(selectedTags) => {
                    const tags = [];
                    if (selectedTags != null) {
                        selectedTags.forEach((c) => {
                            if (c.selected) {
                                const tag = projectTags.find((a) => a.title === c.title);
                                if (tag != null) {
                                    tags.push({ tagName: tag.title, color: tag.backgroundColor, groupName: tag.groupId });
                                }
                            }
                        });
                    }
                    Dialog.instance?.close(() => {
                        const c = new ChangePlanTags({
                            tags,
                        }, plan.id);
                        c.execute().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                    });
                }} canAddNewChips={false}/>),
            contentPadding: false,
            fullscreenResponsive: true,
            showCloseButton: false,
            showCloseIcon: false,
        });
    };
    const gotoPlanVersions = (plan) => () => {
        Dialog.instance?.close(() => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (plan != null && plan.id != null && projectId != null) {
                Routing.instance.goTo(`/projects/${projectId}/plans/${plan.id}/versions`);
            }
        });
    };
    const openNotLatestVersionDialog = (plan) => (_e) => {
        Dialog.instance?.open({
            content: I18n.m.getMessage('planCurrentVersionNotActiveOne'),
            buttons: [
                <ContainedButton title={I18n.m.getMessage('ok')} backgroundColor="#ffffff" textColor={ThemeManager.style.brandPrimary} onPress={Dialog.instance?.close} key="okay"/>,
                <ContainedButton title={I18n.m.getMessage('planToTheVersions')} backgroundColor="#ffffff" textColor={ThemeManager.style.brandPrimary} onPress={gotoPlanVersions(plan)} key="goToVersions"/>,
            ],
        });
    };
    const updateFilter = (f) => {
        setFilter(f);
        updateVisiblePlans(f).catch((err) => console.debug(err));
        upmeshSession.setItem(filterKey, filter);
    };
    const openFilter = () => {
        openPlanFilterDialog(plans, updateFilter, filter);
    };
    const onLayoutPlanReleaseNotice = (e) => {
        if (e?.nativeEvent?.layout?.height > 0) {
            setReleasePlanHeight(e.nativeEvent.layout.height);
        }
        else {
            setReleasePlanHeight(0);
        }
    };
    const calculateColumns = () => {
        const { size } = props;
        let targetWidth = ThemeManager.style.getScreenRelativePixelSize(212);
        const width = size.contentWidth - ThemeManager.style.contentPaddingValue;
        const numColumns = Math.max(1, Math.round(width / targetWidth));
        targetWidth = width / numColumns;
        return { targetWidth, numColumns };
    };
    const getItemKey = (item, _index) => `plan${item.id}`;
    const gotoPlanDetails = (plan) => {
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (projectId != null) {
            Routing.instance.goTo(`/projects/${projectId}/plans/${plan.id}`);
        }
    };
    const renderTags = (item, columnData, _index) => {
        if (item == null)
            return <View />;
        const { style } = columnData;
        let chips = [];
        if (item.tags && item.tags.length > 0) {
            chips = item.tags.map((e) => {
                return {
                    onPressChip: (_e) => { },
                    title: e.tagName,
                    groupName: e.groupName,
                    groupId: e.groupName,
                    backgroundColor: e.color,
                    textColor: '#ffffff',
                };
            });
        }
        return (<View style={[{ justifyContent: 'center', overflow: 'hidden', alignItems: 'flex-start' }, style]} key={`ViewAroundTags${item.id}}`}>
        <View style={{
                zIndex: 2,
                width: 5000,
                overflow: 'hidden',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
          <Ripple toolTip={I18n.m.getMessage('editTags')} onPress={editTags(item)} style={{
                width: 36,
                height: 36,
                borderRadius: 18,
                borderStyle: 'dotted',
                borderColor: ThemeManager.style.borderColor,
                borderWidth: ThemeManager.style.borderWidth,
                justifyContent: 'center',
                marginRight: 8,
            }}>
            <MaterialText centeredBox centeredText>
              {chips.length}
            </MaterialText>
          </Ripple>
          {chips.map((c) => (<Chip {...c} key={c.title} onPressChip={() => {
                    const letT = filter.t != null && filter.t.length > 0 ? [...filter.t] : [];
                    const isIn = letT.findIndex((a) => a === c.title);
                    if (isIn > -1)
                        letT.splice(isIn, 1);
                    else if (isIn === -1)
                        letT.push(c.title);
                    const currentFilter = { ...filter, t: letT };
                    Routing.instance.changeQueryParameter({
                        fp: JSON.stringify(currentFilter),
                    });
                    updateFilter(currentFilter);
                }}/>))}
        </View>
      </View>);
    };
    const renderThumbRow = ({ item }) => {
        let versionAlert = false;
        if (item.versions != null && item.versions.length > 0) {
            item.versions.sort((a, b) => {
                return b.version - a.version;
            });
            if (item.versions[0].id !== item.activePlanId) {
                versionAlert = true;
            }
        }
        const { targetWidth } = calculateColumns();
        return (<View key={`PlanThumb${item.id}`} style={{
                padding: ThemeManager.style.getScreenRelativePixelSize(8),
                width: targetWidth,
            }}>
        <PlanThumb169 plan={item} projectId={item.projectId} fileSource={item.activePlanId === 'MAP' ? 'map' : 'planVersion'} fileId={item.activePlanId} width={targetWidth - 2 * ThemeManager.style.getScreenRelativePixelSize(8)} onPress={gotoPlanDetails} onPressData={item} title={item.title} deleteFunction={deletePlan(item)} downloadFunction={item.activePlanId !== 'MAP' ? downloadPlan(item) : undefined} bottomRightIcon={versionAlert
                ? {
                    icon: 'alert-circle-outline',
                    toolTip: '',
                    color: ThemeManager.style.brandDanger,
                    onPress: openNotLatestVersionDialog(item),
                }
                : undefined}/>
      </View>);
    };
    const renderThumbnails = (planThumbs) => {
        const { size } = props;
        const { numColumns } = calculateColumns();
        return (<View>
        <FlatList key={`planlist_${numColumns}`} keyExtractor={getItemKey} style={{
                width: size.contentWidth,
                height: size.contentHeight - ThemeManager.style.headerHeight,
                margin: 8,
            }} numColumns={numColumns} data={planThumbs} renderItem={renderThumbRow} ListFooterComponent={<View />} ListFooterComponentStyle={{ height: 200 }} onEndReachedThreshold={0.5}/>
      </View>);
    };
    const renderPlanThumbTableCell = (item, columnData, _index, _sLayout) => {
        if (item == null)
            return <View />;
        const { style } = columnData;
        return (<View style={[{ justifyContent: 'flex-start' }, style]} key={`ViewAroundThumb${item.id}}`}>
        <PlanThumb169 plan={item} fileSource={item.activePlanId === 'MAP' ? 'map' : 'planVersion'} projectId={item.projectId} fileId={item.activePlanId} width={90} onPress={gotoPlanDetails} onPressData={item} noBorder/>
      </View>);
    };
    const renderTable = (visiblePlans, tableMaxHeight) => (<Card style={{
            marginHorizontal: 8,
            marginTop: 16,
            height: tableMaxHeight + 16,
        }}>
      <Table tableName="PlansView" actionItemsLength={2} actions={(item) => {
            const actions = [{ onAction: deletePlan, toolTip: I18n.m.getMessage('deletePlan'), icon: 'delete-outline' }];
            if (item.activePlanId !== 'MAP') {
                actions.unshift({
                    onAction: downloadPlan,
                    toolTip: I18n.m.getMessage('downloadPlan'),
                    icon: 'download-outline',
                });
            }
            return actions;
        }} maxHeight={tableMaxHeight} emptyTableImage={notFoundImage} emptyTableText={I18n.m.getMessage('planUploadDescription')} emptyTableHint={I18n.m.getMessage('plansUploadHint')} data={visiblePlans} sortBy="createdAt" sortDirection="desc" columns={[
            {
                title: '',
                hiddenTitleForSettings: I18n.m.getMessage('thumb'),
                keyInData: 'title',
                style: { width: 100 },
                onCellPress: gotoPlanDetails,
                sortable: false,
                cellRenderer: renderPlanThumbTableCell,
            },
            {
                title: I18n.m.getMessage('planTitle'),
                keyInData: 'title',
                style: { width: 200 },
                dataType: 'string',
                onCellPress: gotoPlanDetails,
            },
            {
                title: I18n.m.getMessage('plansCreatedDate'),
                keyInData: 'createdAt',
                dataType: 'Date',
                style: { width: 160 },
                cellStyle: { justifyContent: 'flex-end' },
                onCellPress: gotoPlanDetails,
            },
            {
                title: I18n.m.getMessage('plansActiveVersionDate'),
                keyInData: 'activatedOn',
                dataType: 'Date',
                style: { width: 160 },
                cellStyle: { justifyContent: 'flex-end' },
                onCellPress: gotoPlanDetails,
            },
            {
                title: I18n.m.getMessage('plansNumberOfVersions'),
                keyInData: 'numberOfVersions',
                dataType: 'number',
                style: { width: 130 },
                onCellPress: gotoPlanDetails,
            },
            {
                title: I18n.m.getMessage('tags'),
                keyInData: 'tags',
                dataType: 'string',
                style: { width: 130 },
                cellRenderer: renderTags,
            },
        ]}/>
    </Card>);
    const renderPlanReleaseNotice = () => {
        if (plansTempNumber == null || plansTempNumber === 0)
            return null;
        const tp = new PlanEntity();
        tp.id = '0';
        return (<PlanReadyForReleaseNotice onPress={() => {
                pressTempPlan(tp).catch((err) => console.error(err));
            }}/>);
    };
    const renderFab = () => {
        const buttons = [
            {
                icon: 'plus',
                onPress: (_e) => {
                    if (Fab.instance != null)
                        Fab.instance.closeButtons();
                    pressAddPlan(_e).catch((err) => console.debug(err));
                },
                text: I18n.m.getMessage('tooltipUploadPlan'),
            },
        ];
        if (CurrentProject.instance.getCurrentProject()?.projectSubscription === 'enterprise' &&
            CompanyUserInfo.companySettings != null &&
            CompanyUserInfo.companySettings.hasModule('maps')) {
            buttons.push({
                icon: 'plus',
                onPress: (_e) => {
                    if (Fab.instance != null)
                        Fab.instance.closeButtons();
                    pressCreateMap(_e).catch((err) => console.debug(err));
                },
                text: I18n.m.getMessage('tooltipCreateMap'),
            });
        }
        return (<FabWithCamera size={props.size} showCamera={false} additionalActionButtons={buttons} projectId={props.projectId}/>);
    };
    const render = () => {
        if (!init) {
            return (<Page>
          <Spinner />
        </Page>);
        }
        if (error != null && error.length > 0) {
            return (<PageView headerProps={{ title: I18n.m.getMessage('menuProjectPlans') }}>
          <NoRights error={error}/>
        </PageView>);
        }
        const sViewHeight = props.size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        const maxTableHeight = props.size.contentHeight -
            ThemeManager.style.getScreenRelativePixelSize(48) -
            ThemeManager.style.headerHeight -
            sViewHeight -
            releasePlanHeight;
        let view = <EmptyPlanListView width={props.size.contentWidth} searchWords={searchWords}/>;
        if (visiblePlans != null && visiblePlans.length > 0) {
            view = tableViewModeEnabled ? renderTable(visiblePlans, maxTableHeight) : renderThumbnails(visiblePlans);
        }
        const isFilterActive = PlanFilter.isSet(filter);
        const tagIcon = (<PlanTagFilterIcon currentFilter={filter} setFilter={(f) => {
                Routing.instance.changeQueryParameter({
                    fp: JSON.stringify(f),
                });
                updateFilter(f);
            }} plans={plans}/>);
        let filterIcon = (<Icon key={`planFilter_${isFilterActive}`} icon={isFilterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={openFilter} color={isFilterActive ? ThemeManager.style.brandPrimary : ThemeManager.style.defaultIconColor}/>);
        if (isFilterActive && ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM) {
            filterIcon = (<View key={`filterButtonBigOuter${isFilterActive}`} style={{ marginLeft: 8 }}>
          <ContainedButton key={`planFilter_big_${isFilterActive}`} title={I18n.m.getMessage('filterChange')} icon={{ icon: 'filter-remove', color: '#ffffff' }} onPress={openFilter}/>
        </View>);
        }
        return (<PageView headerProps={{
                title: '',
                searchBarProps: {
                    searchBarValue: searchWords,
                    searchBarPlaceholder: I18n.m.getMessage('planSiteSearchPlans'),
                    searchOnChange: onSearch,
                    tooltip: I18n.m.getMessage('searchPlans'),
                },
                rightButtons: [
                    filterIcon,
                    tagIcon,
                    <View key="toggleButton">
              {ResizeEvent.current.contentWidth <= ThemeManager.style.breakpointM ? (<Icon key={`showCanbanOrListButton${tableViewModeEnabled}`} icon={tableViewModeEnabled ? 'view-grid' : 'format-list-bulleted-square'} toolTip={I18n.m.getMessage(tableViewModeEnabled ? 'planDetailsViewChangeToTiles' : 'planViewChangeToListView')} onPress={toggleTableViewModeEnabled}/>) : (<View style={{ width: 144, marginLeft: 12 }} key="viewButton">
                  <SegmentedButton buttons={[{ icon: { icon: 'view-grid' } }, { icon: { icon: 'format-list-bulleted-square' } }]} onPress={changeView} singleSelectSelected={tableViewModeEnabled ? 1 : 0} density={2}/>
                </View>)}
            </View>,
                ],
            }} scrollable={false} style={{ flexGrow: 1 }}>
        <View onLayout={onLayoutPlanReleaseNotice} style={{ backgroundColor: 'transparent' }}>
          {renderPlanReleaseNotice()}
        </View>
        {renderFab()}
        {view}
      </PageView>);
    };
    return render();
});
