import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CustomFieldMultiPesronOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import { I18n } from '../../../../i18n/I18n';
import { CurrentProject } from '../../CurrentProject';
import { TicketLayoutDefaultOptions } from './TicketLayoutDefaultOptions';
export const chipList = () => {
    const chips = [];
    const team = CurrentProject.instance.getCurrentProjectTeam();
    for (let i = 0; i < team.length; i += 1) {
        chips.push({
            title: `${team[i].user.firstname} ${team[i].user.lastname}`,
            id: team[i].user.id,
            thumbnail: <UserImage size={24} user={team[i].user}/>,
        });
    }
    return chips;
};
export function TicketLayoutMultiPersonFieldOptions(props) {
    const [currentField, setCurrentField] = useState(props.field);
    const [selectedChips, setSelectedChips] = useState([]);
    const [formError, setFormError] = useState(new Map());
    const icon = TicketLayoutsEntity.getIconName(props.field);
    useEffect(() => {
        if (currentField?.customField?.options?.multiperson) {
            const selectedChips = chipList().filter((chip) => currentField?.customField?.options.multiperson?.includes(chip.id));
            setSelectedChips(selectedChips);
        }
    }, [currentField]);
    const submitForm = (_e) => {
        if (currentField.label == null || currentField.label.length === 0) {
            const s = new Map();
            s.set('label', I18n.m.getMessage('required'));
            setFormError(s);
            return;
        }
        setFormError(new Map());
        props.onSave(currentField);
        Dialog.instance?.close();
    };
    return (<>
      <DialogTitle iconRight={{ icon, color: ThemeManager.style.brandSuccess, toolTip: '' }}>
        {props.newField
            ? I18n.m.getMessage('ticketLayoutOptionsTileNew')
            : I18n.m.getMessage('ticketLayoutOptionsTileEdit', { title: currentField.label })}
      </DialogTitle>
      <DialogContent>
        <TicketLayoutDefaultOptions approverCanVisible={props.approverCanVisible} assigneeCanEditVisible={props.assigneeCanEditVisible} field={currentField} onChange={(f) => {
            setCurrentField({ ...currentField, ...f });
        }} onError={(err) => {
            setFormError(err);
        }} onSuccess={() => setFormError(new Map())}>
          {chipList().length !== 0 && (<>
              <View style={{ width: '100%', padding: 5 }}>
                <MaterialText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2}>
                  {I18n.m.getMessage('ticketLayoutOptionsDefaultValue')}
                </MaterialText>
              </View>
              <ChipGroup chips={selectedChips} bordered={false} availableChips={chipList()} onChanged={(items) => {
                setSelectedChips(items);
                const ids = [];
                items.forEach((item) => {
                    if (item.id)
                        ids.push(item.id);
                });
                const customField = currentField.customField
                    ? currentField.customField
                    : { type: 'multiperson', options: new CustomFieldMultiPesronOptions() };
                if (customField.options == null) {
                    customField.options = new CustomFieldMultiPesronOptions();
                }
                customField.options.multiperson = ids;
                setCurrentField({ ...currentField, customField });
            }} key={`added_${true}_${selectedChips.toString()}`}/>
            </>)}
        </TicketLayoutDefaultOptions>
      </DialogContent>
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => Dialog.instance?.close()} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton disabled={formError.size > 0 || currentField.label == null || currentField.label.length === 0} onPress={submitForm} title={props.newField ? I18n.m.getMessage('create') : I18n.m.getMessage('save')}/>
      </DialogActions>
    </>);
}
