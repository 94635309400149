import React from 'react';
import { View } from 'react-native';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import _ from 'lodash';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { TicketTextInputs } from './ticketdetails/TicketTextInputs';
import { TicketProjectTitle } from './TicketProjectTitle';
import { TicketTypeChip } from './ticketdetails/TicketTypeChip';
import { CustomFields } from './ticketdetails/CustomFields';
import { TicketBuilder } from './ticketdetails/TicketBuilder';
import { TicketDetailsBottomBar } from './TicketDetailsBottomBar';
import { SubTickets } from './ticketdetails/SubTickets';
import { UpTicket } from './ticketdetails/UpTicket';
export const TicketDetailsEditable = React.memo((props) => {
    const loadTicketLayout = () => {
        if (props.ticketLayout != null) {
            return props.ticketLayout;
        }
        return ChangeTicketCustomField.getTicketLayout(props.ticket, props.project);
    };
    const labelStyle = { marginTop: ThemeManager.style.gap, width: '100%' };
    return (<View>
        <ScrollContainer containerId="ticketDetails" key="ticketDetailsScroll" style={{ width: props.contentWidth, height: props.contentHeight, maxHeight: props.contentHeight }} contentContainerStyle={{ padding: ThemeManager.style.contentPaddingValue }}>
          {props.showProject ? <TicketProjectTitle project={props.project}/> : null}
          <TicketTextInputs ticketTitle={props.ticket.title} ticketId={props.ticketId} errorResult={props.errorResult}/>
          <TicketTypeChip type={props.ticket.type} ticketId={props.ticketId} projectId={props.project.id} errorResult={props.errorResult}/>
          <CustomFields labelStyle={labelStyle} errorResult={props.errorResult} ticketId={props.ticketId} ticket={props.ticket} projectId={props.project.id} ticketLayout={loadTicketLayout()}/>
          {props.ticket.subTicketFrom != null && props.ticket.subTicketFrom.length > 0 && (<UpTicket ticket={props.ticket} labelStyle={labelStyle} canEditSubTickets={props.canEditSubTickets}/>)}
          {props.hasSubTickets && (<SubTickets ticket={props.ticket} labelStyle={labelStyle} scrollContainer="ticketDetails" canEditSubTickets={props.canEditSubTickets}/>)}
          <TicketBuilder labelStyle={labelStyle} ticket={props.ticket}/>
          <View style={{ height: ThemeManager.style.gap }}/>
        </ScrollContainer>
        {props.bottomBar ? (<TicketDetailsBottomBar contentWidth={props.contentWidth} fullscreen={props.fullScreen} addAttachment={props.addAttachment} ticketId={props.ticketId} ticketState={props.ticket.ticketStatus} archived={props.ticket.archived} openCam={props.openCam}/>) : null}
      </View>);
}, _.isEqual);
