import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Header } from 'materialTheme/src/theme/components/header/Header';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialTextWithOutToolTip';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { PlanFilter } from 'upmesh-core/src/client/query/filter/PlanFilter';
import { I18n } from '../../i18n/I18n';
import { openPlanFilterDialog } from '../plans/PlansFilterDialog';
import { PlanTagFilterIcon } from '../plans/PlanTagFilterIcon';
import { PlanThumb169 } from '../plans/PlanThumb169';
const notFoundImage = require('../../assets/img/no_blueprint.png');
const STORAGE_KEY = 'PlanSelectorTableViewEnabled';
export function PlanSelector(props) {
    const [searchWords, setSearchWords] = useState('');
    const [filter, setFilter] = useState(props.currentFilter);
    const [visiblePlans, setVisiblePlans] = useState(props.plans);
    const [tableViewEnabled, setTableViewEnabled] = useState(false);
    useEffect(() => {
        const val = SimpleStorage.get(STORAGE_KEY);
        setTableViewEnabled(val === 'true' && !!props.allowTable);
    }, []);
    useEffect(() => {
        let mounted = true;
        PlanFilter.filterPlansByText(searchWords, props.plans)
            .then((plans) => {
            if (mounted)
                return PlanFilter.filterPlans(plans, filter);
            return [];
        })
            .then((v) => {
            if (mounted)
                setVisiblePlans(v);
        })
            .catch((err) => console.debug(err));
        return () => {
            mounted = false;
        };
    }, [filter, searchWords]);
    const forWidth = (props.dialogState?.windowWidth ?? props.width) || 300;
    let targetWidth = ThemeManager.style.getScreenRelativePixelSize(212);
    const width = forWidth - 24;
    const numColumns = Math.max(1, Math.round(width / targetWidth));
    targetWidth = width / numColumns;
    const showPlanNotes = (plan) => () => {
        Dialog.instance?.open({
            title: I18n.m.getMessage('planDetailsPlanNotes'),
            content: plan.notes,
            buttons: [
                <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('ok')} key="close" onPress={() => Dialog.instance?.close(() => props.onClose?.(filter))}/>,
            ],
        });
    };
    const renderRow = ({ item }) => {
        const { currentSelecedPlanId } = props;
        const selected = currentSelecedPlanId === item.id;
        return (<View style={{
                padding: ThemeManager.style.getScreenRelativePixelSize(8),
                width: targetWidth,
                overflow: 'visible',
            }} key={`PlanThumb_${item.id}`}>
        <PlanThumb169 plan={item} projectId={item.projectId} fileSource={item.activePlanId === 'MAP' ? 'map' : 'planVersion'} fileId={item.activePlanId} width={targetWidth - ThemeManager.style.getScreenRelativePixelSize(8)} selected={selected} onPress={(e) => {
                Dialog.instance?.close(() => {
                    props.onSelect(item.id, filter)(e);
                });
            }} title={item.title} bottomRightIcon={item.notes && item.notes.length > 0
                ? { icon: 'information-outline', toolTip: '', onPress: showPlanNotes(item) }
                : undefined}/>
      </View>);
    };
    const renderTags = (item, columnData, _index) => {
        if (item == null)
            return <View />;
        const { style } = columnData;
        let chips = [];
        if (item.tags && item.tags.length > 0) {
            chips = item.tags.map((e) => {
                return {
                    title: e.tagName,
                    groupName: e.groupName,
                    groupId: e.groupName,
                    backgroundColor: e.color,
                    textColor: '#ffffff',
                };
            });
        }
        return (<View style={[{ justifyContent: 'center', overflow: 'hidden', alignItems: 'flex-start' }, style]} key={`ViewAroundTags${item.id}}`}>
        <View style={{
                zIndex: 2,
                width: 5000,
                overflow: 'hidden',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
          <View style={{
                width: 36,
                height: 36,
                borderRadius: 18,
                borderStyle: 'dotted',
                borderColor: ThemeManager.style.borderColor,
                borderWidth: ThemeManager.style.borderWidth,
                justifyContent: 'center',
                marginRight: 8,
            }}>
            <MaterialText centeredBox centeredText>
              {chips.length}
            </MaterialText>
          </View>
          {chips.map((c) => (<Chip {...c} key={`${c.title}_${c.groupId}`} onPressChip={() => {
                    const letT = filter.t != null && filter.t.length > 0 ? [...filter.t] : [];
                    const isIn = letT.findIndex((a) => a === c.title);
                    if (isIn > -1)
                        letT.splice(isIn, 1);
                    else if (isIn === -1)
                        letT.push(c.title);
                    const currentFilter = { ...filter, t: letT };
                    setFilter(currentFilter);
                }}/>))}
        </View>
      </View>);
    };
    const renderPlanThumbTableCell = (item, columnData, _index, _sLayout) => {
        if (item == null)
            return <View />;
        const { style } = columnData;
        return (<View style={[{ justifyContent: 'flex-start' }, style]} key={`ViewAroundThumb${item.id}}`}>
        <PlanThumb169 plan={item} fileSource={item.activePlanId === 'MAP' ? 'map' : 'planVersion'} projectId={item.projectId} fileId={item.activePlanId} width={90} onPressData={item} noBorder/>
      </View>);
    };
    const selectPlan = (item, e) => {
        Dialog.instance?.close(() => {
            props.onSelect(item.id, filter)(e);
        });
    };
    const renderTable = () => {
        let maxHeight = (props.dialogState?.windowHeight ?? props.dialogHeight ?? 0) - 48;
        if (props.withAll)
            maxHeight -= 52;
        return (<View>
        <Table actionItemsLength={0} tableName="PlansView" emptyTableImage={notFoundImage} emptyTableText={I18n.m.getMessage('planUploadDescription')} emptyTableHint={I18n.m.getMessage('plansUploadHint')} data={visiblePlans} maxHeight={maxHeight} sortBy="createdAt" sortDirection="desc" columns={[
                {
                    title: '',
                    hiddenTitleForSettings: I18n.m.getMessage('thumb'),
                    keyInData: 'title',
                    style: { width: 100 },
                    sortable: false,
                    cellRenderer: renderPlanThumbTableCell,
                    onCellPress: selectPlan,
                },
                {
                    title: I18n.m.getMessage('planTitle'),
                    keyInData: 'title',
                    style: { width: 200 },
                    dataType: 'string',
                    onCellPress: selectPlan,
                },
                {
                    title: I18n.m.getMessage('plansCreatedDate'),
                    keyInData: 'createdAt',
                    dataType: 'Date',
                    style: { width: 160 },
                    cellStyle: { justifyContent: 'flex-end' },
                    onCellPress: selectPlan,
                },
                {
                    title: I18n.m.getMessage('plansActiveVersionDate'),
                    keyInData: 'activatedOn',
                    dataType: 'Date',
                    style: { width: 160 },
                    cellStyle: { justifyContent: 'flex-end' },
                    onCellPress: selectPlan,
                },
                {
                    title: I18n.m.getMessage('tags'),
                    keyInData: 'tags',
                    dataType: 'string',
                    style: { width: 130 },
                    cellRenderer: renderTags,
                },
            ]}/>
      </View>);
    };
    const getItemKey = (item, _index) => `plan_${item.id}`;
    const openPlanFilter = () => {
        openPlanFilterDialog(props.plans, (f) => setFilter(f), filter);
    };
    const changeView = (selected) => {
        SimpleStorage.set(STORAGE_KEY, (selected === 1).toString());
        setTableViewEnabled(selected === 1);
    };
    const isFilterActive = PlanFilter.isSet(filter);
    const filterIcon = (<Icon key={`planFilter_${isFilterActive}`} icon={isFilterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={openPlanFilter} color={isFilterActive ? ThemeManager.style.brandPrimary : ThemeManager.style.defaultIconColor}/>);
    const tagIcon = (<PlanTagFilterIcon currentFilter={filter} setFilter={(f) => {
            setFilter(f);
        }} plans={props.plans}/>);
    let segmentedButton = <View />;
    if (props.allowTable)
        segmentedButton =
            ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? (<Icon icon={tableViewEnabled ? 'view-grid' : 'format-list-bulleted-square'} toolTip={I18n.m.getMessage(tableViewEnabled ? 'planDetailsViewChangeToTiles' : 'planViewChangeToListView')} onPress={() => changeView(tableViewEnabled ? 0 : 1)}/>) : (<View style={{ width: 144, marginHorizontal: 12 }}>
          <SegmentedButton buttons={[{ icon: { icon: 'view-grid' } }, { icon: { icon: 'format-list-bulleted-square' } }]} onPress={changeView} density={2} singleSelectSelected={tableViewEnabled ? 1 : 0}/>
        </View>);
    return (<View style={ThemeManager.style.absoluteStyle}>
      <Header searchBarProps={{
            searchBarValue: searchWords,
            searchBarPlaceholder: I18n.m.getMessage('planSiteSearchPlans'),
            searchOnChange: (t) => setSearchWords(t),
            tooltip: I18n.m.getMessage('searchTickets'),
        }} rightButtons={[
            filterIcon,
            tagIcon,
            segmentedButton,
            <Icon icon="close" toolTip="" onPress={(e) => (props.onDialogClose ? props.onDialogClose(e) : Dialog.instance?.close())}/>,
        ]}/>
      {props.withAll && (<View style={{ paddingHorizontal: 16, paddingVertical: 8, height: 52 }}>
          <ContainedButton onPress={(e) => {
                Dialog.instance?.close(() => {
                    props.onSelect('all', filter)(e);
                });
            }} title={`${I18n.m.getMessage('allTickets')}`} raised={false} full backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF"/>
        </View>)}
      {tableViewEnabled ? (renderTable()) : (<FlatList key={`planTicketsList_${numColumns}`} keyExtractor={getItemKey} renderItem={renderRow} numColumns={numColumns} data={visiblePlans} style={{ ...ThemeManager.style.absoluteStyle, top: props.withAll ? 96 : 48, left: 8 }}/>)}
    </View>);
}
export const openPlanSelectorDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        content: <PlanSelector {...props} key="PlanSelectorDialog"/>,
        fullscreen: true,
        width: 768,
        contentPadding: false,
        openPosition,
        scrollable: false,
        openImmediately: false,
        closeOnTouchOutside: true,
        showCloseIcon: false,
    });
};
