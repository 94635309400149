import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { BackHandler, Platform, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { CombinedExtraPayAndTimeTrackingFilter, } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export class CombinedExtraPayAndTimeTrackingDialogFilter extends PureComponent {
    constructor(props) {
        super(props);
        this.calculateFiltered = () => {
            const { all } = this.props;
            this.setState({ calculateAmount: true }, () => {
                try {
                    const filter = this.createCurrentFilter();
                    const filtered = CombinedExtraPayAndTimeTrackingFilter.filterTimeTrackings(all, filter);
                    this.setState({ calculateAmount: false, amountSelected: filtered.length });
                }
                catch (e) {
                    this.setState({ calculateAmount: false, amountSelected: 0 });
                }
            });
        };
        this.fastSelectDate = (dates) => (_e) => {
            this.setState({ date: dates.from.getTime(), dateTo: dates.to.getTime() }, () => this.calculateFiltered());
        };
        this.getTheMondayBefore = (date) => {
            if (date.getDay() === 1)
                return date;
            if (date.getDay() === 0)
                return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6, 0, 0, 0, 0);
            return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1, 0, 0, 0, 0);
        };
        this.removeMember = (members) => () => {
            const { selectedCollaborators } = this.state;
            if (selectedCollaborators) {
                for (const m of members) {
                    const i = selectedCollaborators.findIndex((r) => (m.userId && r.id === m.userId) || r.id === m.id);
                    if (i > -1)
                        selectedCollaborators.splice(i, 1);
                }
                const userIds = this.selectedCollaboratorsToUserIds(selectedCollaborators);
                this.setState({ selectedCollaborators, userIds }, this.calculateFiltered);
            }
        };
        this.addMember = (members) => () => {
            const { selectedCollaborators } = this.state;
            if (selectedCollaborators) {
                const asyncNow = async () => {
                    for (const m of members) {
                        const i = selectedCollaborators.findIndex((r) => (m.userId && r.id === m.userId) || r.id === m.id);
                        if (i === -1) {
                            let user = new UserEntity({ firstname: m.firstName, lastname: m.lastName, id: m.id });
                            if (m.userId) {
                                user = await AuthClient.instance.modals.user.getById(m.userId);
                            }
                            const thumbnail = <UserImage user={user} size={24}/>;
                            selectedCollaborators.push({ title: user.getFullName(), thumbnail, id: user.id, groupId: m.groupId });
                        }
                    }
                    const userIds = this.selectedCollaboratorsToUserIds(selectedCollaborators);
                    this.setState({ selectedCollaborators, userIds }, this.calculateFiltered);
                };
                asyncNow().catch(DefaultErrorHandler.showDefaultErrorAlert);
            }
        };
        this.setSelectedTasks = (e) => {
            this.setState({ selectedTasks: e }, this.calculateFiltered);
        };
        this.setSelectedExtraPays = (e) => {
            this.setState({ selectedExtraPayId: e }, this.calculateFiltered);
        };
        this.changeDate = (newDate) => {
            const date = newDate == null ? undefined : typeof newDate === 'number' ? newDate : newDate.getTime();
            this.setState({ date }, this.calculateFiltered);
        };
        this.changeDateTo = (newDate) => {
            const dateTo = newDate == null ? undefined : typeof newDate === 'number' ? newDate : newDate.getTime();
            this.setState({ dateTo }, this.calculateFiltered);
        };
        this.onReset = () => {
            this.resetFilters();
        };
        this.onSelect = () => {
            Dialog.instance?.close(() => {
                const filter = JSON.stringify(this.createCurrentFilter());
                SimpleStorage.set(`${CurrentUser.userId}_saved_timetrackingfilter2`, filter);
                Routing.instance.changeQueryParameter({
                    ft: filter,
                });
            });
        };
        this.me = CompanyUserInfo.companyMember.find((m) => m.userId === CurrentUser.userId);
        this.state = {
            date: props.currentFilter.date,
            dateTo: props.currentFilter.dateTo,
            projectIds: props.currentFilter.projectIds,
            userIds: props.currentFilter.userIds,
            collaboratorGroups: [],
            amountSelected: 0,
            calculateAmount: true,
            ...this.getTasksFromFilter(props.currentFilter, props.all),
            ...this.getExtraPaysFromFilter(props.currentFilter, props.all),
            ...this.getCollaboratorsFromFilter(props.currentFilter, props.all),
            ...this.getProjectsFromFilter(props.currentFilter, props.all),
            ...this.getCostCentersFromFilter(props.currentFilter, props.all),
            companySettings: CompanyUserInfo.companySettings,
        };
    }
    componentDidMount() {
        if (Platform.OS === 'android')
            this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
                Dialog.instance?.close();
                return true;
            });
        this.calculateFiltered();
        if (CompanyUserInfo.company) {
            UpmeshClient.instance.modals.group
                .get({
                filter: `deleted ne true and companyId eq '${CompanyUserInfo.company.id}'`,
            })
                .then((groupEntities) => {
                const collaboratorGroups = [];
                groupEntities.forEach((group) => {
                    collaboratorGroups.push({
                        id: group.id,
                        groupName: group.groupName !== 'default_employees'
                            ? group.groupName
                            : I18n.m.getMessage('companyGroupDefaultName'),
                        backgroundColor: 'transparent',
                    });
                });
                this.setState({ collaboratorGroups });
            })
                .catch((err) => console.debug('cant get groups', err));
        }
    }
    componentWillUnmount() {
        if (this.backHandler)
            this.backHandler.remove();
    }
    getCollaboratorsFromFilter(filter, entries) {
        const collaboratorList = [];
        const selectedCollaborators = [];
        entries.forEach((e) => {
            if (collaboratorList.findIndex((u) => u.id === e.userId) === -1) {
                const m = CompanyUserInfo.companyMember.find((a) => a.userId != null ? a.userId === e.userId : a.id === e.memberId);
                const thumbnail = <UserImage user={e.user} size={24}/>;
                collaboratorList.push({ title: e.memberName, id: e.userId, thumbnail, groupId: m?.groupId });
                if (filter.userIds != null && e.userId != null && filter.userIds.includes(e.userId)) {
                    selectedCollaborators.push({ title: e.memberName, thumbnail, id: e.userId, groupId: m?.groupId });
                }
            }
        });
        return { selectedCollaborators, collaboratorList };
    }
    getTasksFromFilter(filter, entries) {
        const selectedTasks = [];
        const taskList = [];
        entries.forEach((e) => {
            if (e.taskName != null && e.taskId != null && taskList.findIndex((elem) => elem.id === e.taskId) === -1) {
                taskList.push({ title: e.taskName, id: e.taskId });
                if (filter.taskIds != null && filter.taskIds.includes(e.taskId)) {
                    selectedTasks.push({ title: e.taskName, id: e.taskId });
                }
            }
        });
        return { selectedTasks, taskList };
    }
    getExtraPaysFromFilter(filter, entries) {
        const selectedExtraPayId = [];
        const extraPayList = [];
        entries.forEach((e) => {
            if (e.extraPayId != null && extraPayList.findIndex((elem) => elem.id === e.extraPayId) === -1) {
                extraPayList.push({ title: e.extraPayTitle, id: e.extraPayId });
                if (filter.extraPayIds != null && filter.extraPayIds.includes(e.extraPayId)) {
                    selectedExtraPayId.push({ title: e.extraPayTitle, id: e.extraPayId });
                }
            }
        });
        return { selectedExtraPayId, extraPayList };
    }
    getCostCentersFromFilter(filter, entries) {
        const costCenterList = [];
        const selectedCostCenters = [];
        entries.forEach((e) => {
            if (e.costCenterId != null && e.costCenterName != null) {
                if (costCenterList.findIndex((u) => u.id === e.costCenterId) === -1) {
                    costCenterList.push({ title: e.costCenterName, id: e.costCenterId });
                    if (filter.costCenterIds != null && filter.costCenterIds.includes(e.costCenterId)) {
                        selectedCostCenters.push({ title: e.costCenterName, id: e.costCenterId });
                    }
                }
            }
        });
        return { selectedCostCenters, costCenterList };
    }
    getProjectsFromFilter(filter, entries) {
        const projectList = [];
        const selectedProjects = [];
        entries.forEach((e) => {
            if (e.projectId != null && e.projectTitle != null) {
                if (projectList.findIndex((u) => u.id === e.projectId) === -1) {
                    projectList.push({ title: e.projectTitle, id: e.projectId });
                    if (filter.projectIds != null && filter.projectIds.includes(e.projectId)) {
                        selectedProjects.push({ title: e.projectTitle, id: e.projectId });
                    }
                }
            }
        });
        return { selectedProjects, projectList };
    }
    static open(all, currentFilter, withDuration = true) {
        if (Dialog.instance != null) {
            const content = (<CombinedExtraPayAndTimeTrackingDialogFilter all={all} currentFilter={currentFilter} withDuration={withDuration}/>);
            Dialog.instance?.open({
                content,
                contentPadding: false,
                closeOnTouchOutside: false,
                scrollable: false,
                fullscreenResponsive: true,
                showCloseIcon: true,
            });
        }
    }
    createCurrentFilter() {
        const onlyIds = this.state.selectedTasks.map((elem) => elem.id);
        const onlyExtraPayIds = this.state.selectedExtraPayId.map((elem) => elem.id);
        return {
            projectIds: this.state.projectIds,
            costCenterIds: this.state.costCenterIds,
            date: this.state.date,
            dateTo: this.state.dateTo,
            userIds: this.state.userIds,
            words: this.props.currentFilter.words,
            taskIds: onlyIds,
            extraPayIds: onlyExtraPayIds,
        };
    }
    renderTimeQuickfilters() {
        const { date, dateTo, companySettings } = this.state;
        const todayMorning = new Date();
        const lastMonday = this.getTheMondayBefore(todayMorning);
        todayMorning.setHours(0, 0, 0, 0);
        const today = {
            from: todayMorning,
            to: new Date(todayMorning.getFullYear(), todayMorning.getMonth(), todayMorning.getDate() + 1, 0, 0, 0, -1),
        };
        const thisWeek = {
            from: new Date(lastMonday.getFullYear(), lastMonday.getMonth(), lastMonday.getDate(), 0, 0, 0, 0),
            to: new Date(lastMonday.getFullYear(), lastMonday.getMonth(), lastMonday.getDate() + 7, 0, 0, 0, -1),
        };
        const thisMonth = {
            from: new Date(todayMorning.getFullYear(), todayMorning.getMonth(), 1, 0, 0, 0, 0),
            to: new Date(todayMorning.getFullYear(), todayMorning.getMonth() + 1, 1, 0, 0, 0, -1),
        };
        const lastMonth = {
            from: new Date(todayMorning.getFullYear(), todayMorning.getMonth() - 1, 1, 0, 0, 0, 0),
            to: new Date(todayMorning.getFullYear(), todayMorning.getMonth(), 1, 0, 0, 0, -1),
        };
        let thisCalcMonth = {
            from: new Date(),
            to: new Date(),
        };
        let lastCalcMonth = {
            from: new Date(),
            to: new Date(),
        };
        let hasCompanySettings = false;
        if (companySettings && companySettings.billingDate != null && companySettings.billingDate > 1) {
            hasCompanySettings = true;
            const from = new Date(todayMorning.getFullYear(), todayMorning.getMonth(), companySettings.billingDate, 0, 0, 0, 0);
            if (from.getTime() > todayMorning.getTime()) {
                from.setMonth(from.getMonth() - 1);
            }
            thisCalcMonth = {
                from,
                to: new Date(from.getFullYear(), from.getMonth() + 1, from.getDate(), 0, 0, 0, -1),
            };
            lastCalcMonth = {
                from: new Date(from.getFullYear(), from.getMonth() - 1, from.getDate(), 0, 0, 0, 0),
                to: new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0, -1),
            };
        }
        return (<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        <Chip key={`todaysTimetracks${date}_${dateTo}`} backgroundColor={date != null && date === today.from.getTime() && dateTo != null && dateTo === today.to.getTime()
                ? ThemeManager.style.brandPrimary
                : undefined} textColor={date != null && date === today.from.getTime() && dateTo != null && dateTo === today.to.getTime()
                ? '#FFFFFF'
                : undefined} title={I18n.m.getMessage('timeTrackingFilterToday')} onPressChip={this.fastSelectDate(today)}/>
        <Chip key={`thisWeeksTimetracks${date}_${dateTo}`} backgroundColor={date != null && date === thisWeek.from.getTime() && dateTo != null && dateTo === thisWeek.to.getTime()
                ? ThemeManager.style.brandPrimary
                : undefined} textColor={date != null && date === thisWeek.from.getTime() && dateTo != null && dateTo === thisWeek.to.getTime()
                ? '#FFFFFF'
                : undefined} title={I18n.m.getMessage('timeTrackingFilterThisWeek')} onPressChip={this.fastSelectDate(thisWeek)}/>

        {hasCompanySettings ? (<Chip key={`thisCalcMonthsTimetracks${date}_${dateTo}`} backgroundColor={date != null &&
                    date === thisCalcMonth.from.getTime() &&
                    dateTo != null &&
                    dateTo === thisCalcMonth.to.getTime()
                    ? ThemeManager.style.brandPrimary
                    : undefined} textColor={date != null &&
                    date === thisCalcMonth.from.getTime() &&
                    dateTo != null &&
                    dateTo === thisCalcMonth.to.getTime()
                    ? '#FFFFFF'
                    : undefined} title={I18n.m.getMessage('timeTrackingFilterThisCalcMonth')} onPressChip={this.fastSelectDate(thisCalcMonth)}/>) : (<Chip key={`thisMonthsTimetracks${date}_${dateTo}`} backgroundColor={date != null && date === thisMonth.from.getTime() && dateTo != null && dateTo === thisMonth.to.getTime()
                    ? ThemeManager.style.brandPrimary
                    : undefined} textColor={date != null && date === thisMonth.from.getTime() && dateTo != null && dateTo === thisMonth.to.getTime()
                    ? '#FFFFFF'
                    : undefined} title={I18n.m.getMessage('timeTrackingFilterThisMonth')} onPressChip={this.fastSelectDate(thisMonth)}/>)}
        {hasCompanySettings ? (<Chip key={`lastCalcMonthsTimetracks${date}_${dateTo}`} backgroundColor={date != null &&
                    date === lastCalcMonth.from.getTime() &&
                    dateTo != null &&
                    dateTo === lastCalcMonth.to.getTime()
                    ? ThemeManager.style.brandPrimary
                    : undefined} textColor={date != null &&
                    date === lastCalcMonth.from.getTime() &&
                    dateTo != null &&
                    dateTo === lastCalcMonth.to.getTime()
                    ? '#FFFFFF'
                    : undefined} title={I18n.m.getMessage('timeTrackingFilterLastCalcMonth')} onPressChip={this.fastSelectDate(lastCalcMonth)}/>) : (<Chip key={`lastMonthsTimetracks${date}_${dateTo}`} backgroundColor={date != null && date === lastMonth.from.getTime() && dateTo != null && dateTo === lastMonth.to.getTime()
                    ? ThemeManager.style.brandPrimary
                    : undefined} textColor={date != null && date === lastMonth.from.getTime() && dateTo != null && dateTo === lastMonth.to.getTime()
                    ? '#FFFFFF'
                    : undefined} title={I18n.m.getMessage('timeTrackingFilterLastMonth')} onPressChip={this.fastSelectDate(lastMonth)}/>)}
      </View>);
    }
    selectedCollaboratorsToUserIds(chips) {
        const userIds = [];
        if (chips.length > 0) {
            chips.forEach((c) => {
                if (c.id != null)
                    userIds.push(c.id);
            });
        }
        return userIds;
    }
    render() {
        const { all, withDuration } = this.props;
        const { date, dateTo, amountSelected, calculateAmount, collaboratorList, selectedCollaborators, projectList, selectedProjects, selectedTasks, taskList, costCenterList, selectedCostCenters, selectedExtraPayId, extraPayList, collaboratorGroups, } = this.state;
        const showMyTimes = selectedCollaborators.findIndex((r) => r.id === CurrentUser.userId) > -1;
        const myGroup = CompanyUserInfo.me?.groupId;
        const myGroupMember = [];
        let showMyGroup = false;
        let inSelected = 0;
        if (myGroup) {
            CompanyUserInfo.companyMember.forEach((a) => {
                if (a.groupId === myGroup) {
                    myGroupMember.push(a);
                    if (selectedCollaborators.find((c) => (a.userId && c.id === a.userId) || c.id === a.id)) {
                        inSelected += 1;
                    }
                }
            });
            showMyGroup = inSelected === myGroupMember.length;
        }
        const myMember = [];
        inSelected = 0;
        CompanyUserInfo.companyMember.forEach((a) => {
            if (a.leadership && a.leadership === CompanyUserInfo.me?.id) {
                myMember.push(a);
                if (selectedCollaborators.find((c) => (a.userId && c.id === a.userId) || c.id === a.id)) {
                    inSelected += 1;
                }
            }
        });
        const showMyMember = inSelected === myMember.length;
        return [
            <DialogTitle key="title">{I18n.m.getMessage('timeTrackingFilter')}</DialogTitle>,
            <DialogContent key="TimeTrackingFilterContent">
        {collaboratorList.length <= 0 ? null : (<View>
            <View style={{ height: 8 }}/>
            <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('collaborator')}</MaterialText>
            <View style={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
              <Chip thumbnail={<UserImage user={CurrentUser.entity} size={24}/>} title={I18n.m.getMessage('timeTrackingMyTimes')} backgroundColor={showMyTimes ? ThemeManager.style.brandPrimary : undefined} textColor={showMyTimes ? '#FFFFFF' : undefined} onPressChip={showMyTimes ? this.removeMember([CompanyUserInfo.me]) : this.addMember([CompanyUserInfo.me])} key={`myTimesChip_${showMyTimes}`}/>
              {myGroupMember.length > 1 && (<Chip thumbnail={<UserImage user={CurrentUser.entity} size={24}/>} title={I18n.m.getMessage('timeTrackingMyGroup')} backgroundColor={showMyGroup ? ThemeManager.style.brandPrimary : undefined} textColor={showMyGroup ? '#FFFFFF' : undefined} onPressChip={showMyGroup ? this.removeMember(myGroupMember) : this.addMember(myGroupMember)} key={`myGroupChip_${showMyGroup}`}/>)}
              {myMember.length > 0 && (<Chip thumbnail={<UserImage user={CurrentUser.entity} size={24}/>} title={I18n.m.getMessage('timeTrackingMyMember')} backgroundColor={showMyMember ? ThemeManager.style.brandPrimary : undefined} textColor={showMyMember ? '#FFFFFF' : undefined} onPressChip={showMyMember ? this.removeMember(myMember) : this.addMember(myMember)} key={`myMemberChip_${showMyMember}`}/>)}
            </View>
            <ChipGroup key={`${JSON.stringify(selectedCollaborators)}_${JSON.stringify(collaboratorList)}_${collaboratorGroups.length}`} dialogTitle={I18n.m.getMessage('collaborator')} chips={selectedCollaborators} chipGroups={collaboratorGroups} sortFormByGroup availableChips={collaboratorList} onChanged={(chips) => {
                        const userIds = this.selectedCollaboratorsToUserIds(chips);
                        this.setState({ selectedCollaborators: chips, userIds }, this.calculateFiltered);
                    }}/>
          </View>)}
        {projectList.length <= 1 ? null : (<ChipGroup chips={selectedProjects} availableChips={projectList} label={I18n.m.getMessage('projects')} onChanged={(chips) => {
                        const projectIds = [];
                        if (chips.length > 0) {
                            chips.forEach((c) => {
                                if (c.id != null)
                                    projectIds.push(c.id);
                            });
                        }
                        this.setState({ selectedProjects: chips, projectIds }, this.calculateFiltered);
                    }}/>)}
        {costCenterList.length <= 1 ? null : (<ChipGroup chips={selectedCostCenters} availableChips={costCenterList} label={I18n.m.getMessage('costCenters')} onChanged={(chips) => {
                        const costCenterIds = [];
                        if (chips.length > 0) {
                            chips.forEach((c) => {
                                if (c.id != null)
                                    costCenterIds.push(c.id);
                            });
                        }
                        this.setState({ selectedCostCenters: chips, costCenterIds }, this.calculateFiltered);
                    }}/>)}
        {withDuration && (<View style={{ width: '100%', paddingTop: 8 }}>
            <MaterialText type={MaterialTextTypes.Caption}>
              {I18n.m.getMessage('timeTrackingFilterDateRange')}
            </MaterialText>
            {this.renderTimeQuickfilters()}
            <DateInput labelText={I18n.m.getMessage('timeTrackingFilterDay')} placeholder={I18n.m.getMessage('timeTrackingFilterDay')} key={`projectTime${date}`} onChange={this.changeDate} selectedDate={date == null ? undefined : new Date(date)}/>
            <DateInput labelText={I18n.m.getMessage('timeTrackingFilterDayTo')} placeholder={I18n.m.getMessage('timeTrackingFilterDayTo')} key={`projectTimeTo${dateTo}`} onChange={this.changeDateTo} selectedDate={dateTo == null ? undefined : new Date(dateTo)}/>
          </View>)}
        <View style={{ height: 8 }}/>
        <ChipGroup label={I18n.m.getMessage('timeTrackingFilterTask')} bordered={false} availableChips={taskList} chips={selectedTasks} onChanged={this.setSelectedTasks}/>
        {extraPayList == null || extraPayList.length === 0 ? null : (<ChipGroup label={I18n.m.getMessage('extraPays')} bordered={false} availableChips={extraPayList} chips={selectedExtraPayId} onChanged={this.setSelectedExtraPays}/>)}
      </DialogContent>,
            <View key="errorText" style={{ alignSelf: 'flex-end', padding: 0, paddingRight: 16 }}>
        <MaterialText textPadding={0} type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {amountSelected === 0 ? I18n.m.getMessage('noEntriesFound') : ''}
        </MaterialText>
      </View>,
            <DialogActions key="TimeTrackingFilterActions">
        <ContainedButton title={I18n.m.getMessage('reset')} onPress={this.onReset} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton loading={calculateAmount} disabled={calculateAmount} title={`${I18n.m.getMessage('apply')} ${amountSelected}/${all.length}`} onPress={this.onSelect}/>
      </DialogActions>,
        ];
    }
    resetFilters() {
        const { withDuration } = this.props;
        const today = new Date();
        this.setState({
            date: withDuration ? new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0).getTime() : undefined,
            dateTo: withDuration
                ? new Date(today.getFullYear(), today.getMonth() + 1, 1, 0, 0, 0, -1).getTime()
                : undefined,
            userIds: undefined,
            selectedCollaborators: [],
            selectedProjects: [],
            projectIds: undefined,
            selectedExtraPayId: [],
            selectedTasks: [],
            selectedCostCenters: [],
        }, this.calculateFiltered);
    }
}
