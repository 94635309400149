import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { I18n } from '../../../i18n/I18n';
import { CompanyUserMemberPicker } from '../CompanyUserMemberPicker';
export function ApprovalProcessStepDialog(props) {
    const [step, setStep] = useState(props.step ? props.step : { who: 'leadership', canEditInDays: 0 });
    return [
        <DialogTitle>
      {props.step
                ? I18n.m.getMessage('approvalProcessStepWhoTitleChange')
                : I18n.m.getMessage('approvalProcessStepWhoTitle')}
    </DialogTitle>,
        <DialogContent>
      <FormInputPicker labelText={I18n.m.getMessage('approvalProcessStepWho')} list={[
                { title: I18n.m.getMessage('approvalProcessStepWhoMember'), data: 'member' },
                { title: I18n.m.getMessage('approvalProcessStepWhoLeadership'), data: 'leadership' },
                { title: I18n.m.getMessage('approvalProcessStepWhoLeadershipOfLeadership'), data: 'leadershipOfLeadership' },
                { title: I18n.m.getMessage('approvalProcessStepWhoPayroll'), data: 'payroll' },
            ]} selectedIndex={step.who === 'leadership' ? 1 : step.who === 'leadershipOfLeadership' ? 2 : step.who === 'payroll' ? 3 : 0} onChange={(a) => {
                setStep({
                    ...step,
                    who: a.value.data,
                    whoExtra: undefined,
                    canEditInDays: a.value.data === 'payroll' ? undefined : step.canEditInDays,
                });
            }}/>
      {step.who === 'member' && (<CompanyUserMemberPicker labelText={I18n.m.getMessage('approvalProcessStepWhoMember')} selectedId={step.whoExtra} onChange={(a) => {
                    setStep({ ...step, whoExtra: a });
                }}/>)}
      {step.who !== 'payroll' && (<FormInputFilled labelText={I18n.m.getMessage('approvalProcessStepEditInDays')} helperText={I18n.m.getMessage('approvalProcessStepEditInDaysHelper')} value={step.canEditInDays ? step.canEditInDays.toString() : '0'} max={99} min={0} onChange={(a) => {
                    setStep({ ...step, canEditInDays: Math.min(Number.parseInt(a, 10), 99) });
                }}/>)}
    </DialogContent>,
        <DialogActions>
      <ContainedButton title={I18n.m.getMessage('cancel')} onPress={() => Dialog.instance?.close()} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>
      <ContainedButton title={props.step ? I18n.m.getMessage('approvalProcessChangeButton') : I18n.m.getMessage('approvalProcessAddButton')} onPress={() => Dialog.instance?.close(() => {
                props.onAdd(step);
            })}/>
    </DialogActions>,
    ];
}
export const openApprovalProcessStepDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <ApprovalProcessStepDialog onAdd={props.onAdd} step={props.step}/>,
    });
};
