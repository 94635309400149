import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { ProjectImage } from '../../project/ProjectImage';
export class CommentCard extends PureComponent {
    constructor(props) {
        super(props);
        this.openComment = (e) => {
            const { onOpen, comment } = this.props;
            Dialog.instance?.close(() => {
                Routing.instance.openDialog('ticket', { id: comment.ticketId })(e);
                if (onOpen != null)
                    onOpen(comment, e);
            });
        };
        this.state = {};
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        const { comment } = this.props;
        let project = null;
        if (this.props.showProjects === true)
            project = await UpmeshClient.instance.modals.project.getById(comment.projectId);
        const user = await AuthClient.instance.modals.user.getById(comment.creator);
        this.setState({ project, user });
    }
    renderUserIcon() {
        const { user } = this.state;
        if (user == null) {
            return (<View style={{
                    borderColor: ThemeManager.style.borderColor,
                    borderStyle: 'dashed',
                    borderWidth: 1,
                    borderRadius: 12,
                    flexDirection: 'row',
                    margin: ThemeManager.style.getScreenRelativePixelSize(4),
                    marginRight: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 24,
                    width: 24,
                }}>
          <Icon toolTip="" icon="help" iconSize={20} outerSize={24} radius={0}/>
        </View>);
        }
        return (<View style={{
                flexDirection: 'row',
                marginLeft: ThemeManager.style.getScreenRelativePixelSize(4),
                borderRadius: 12,
                height: 24,
                width: 24,
                overflow: 'hidden',
            }}>
        <UserImage size={24} user={user}/>
      </View>);
    }
    renderCreatedAtIcon() {
        const { comment } = this.props;
        const completionColor = ThemeManager.style.defaultTextColor;
        const completionBGColor = ThemeManager.style.brandLight;
        return (<View style={{
                flexDirection: 'row',
                margin: ThemeManager.style.getScreenRelativePixelSize(4),
                marginRight: 0,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: completionBGColor,
                paddingLeft: 4,
                paddingRight: 4,
                height: 24,
                borderRadius: ThemeManager.style.borderRadius,
            }}>
        <MaterialText color={completionColor} numberOfLines={1} centeredBox centeredText type={MaterialTextTypes.Caption}>
          {I18n.m.dateCurrent.localeDateWithTimeString(comment.createdAt)}
        </MaterialText>
      </View>);
    }
    render() {
        const { comment, showProjects } = this.props;
        const { project } = this.state;
        return (<View style={{ width: '100%', height: 'auto', padding: 6, overflow: 'visible' }}>
        <Card outerPadding={0} style={{ width: '100%', height: 'auto', overflow: 'visible', margin: 0 }}>
          <View pointerEvents="box-none" style={{ width: '100%', height: 'auto', overflow: 'hidden', borderRadius: 6, paddingRight: 4, margin: 0 }}>
            <Ripple onPress={this.openComment} style={{
                width: '100%',
                padding: ThemeManager.style.getScreenRelativePixelSize(4),
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                alignItems: 'center',
                height: 'auto',
            }}>
              <View style={{ height: 'auto', maxWidth: '100%', alignSelf: 'center', flexGrow: 1 }}>
                <MaterialText type={MaterialTextTypes.Body1} ellipsizeMode="tail">
                  {comment.comment}
                </MaterialText>
              </View>
              <View style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignContent: 'flex-end',
                alignItems: 'center',
                justifyContent: 'flex-end',
                alignSelf: 'flex-end',
                height: 'auto',
                minHeight: 36,
                flexGrow: 1,
            }}>
                {this.renderCreatedAtIcon()}
                {this.renderUserIcon()}
                {showProjects !== true || project == null ? null : (<View style={{ paddingLeft: 4 }}>
                    <ProjectImage project={project} size={24}/>
                  </View>)}
              </View>
            </Ripple>
          </View>
        </Card>
      </View>);
    }
}
