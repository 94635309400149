"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteApprovalProcess = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ApprovalProcessDeleted_1 = require("../../../events/company/approvalprocess/ApprovalProcessDeleted");
var DeleteApprovalProcess = function (_CommandRoot_1$Comman) {
  function DeleteApprovalProcess(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v4();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, DeleteApprovalProcess);
    _this = _callSuper(this, DeleteApprovalProcess, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'DeleteApprovalProcess';
    return _this;
  }
  (0, _inherits2.default)(DeleteApprovalProcess, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(DeleteApprovalProcess, [{
    key: "getApprovalProcess",
    value: function () {
      var _getApprovalProcess = (0, _asyncToGenerator2.default)(function* () {
        if (!this._approvalProcess) this._approvalProcess = yield CommandReadModels_1.CommandReadModels.approvalProcess.getById(this.entityId);
        return this._approvalProcess;
      });
      function getApprovalProcess() {
        return _getApprovalProcess.apply(this, arguments);
      }
      return getApprovalProcess;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var approvalProcess = yield this.getApprovalProcess();
        if (userId === 'all') return true;
        if (userId.startsWith('company') && userId.slice(7) === approvalProcess.companyId) return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(approvalProcess.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var approvalProcess = yield this.getApprovalProcess();
        if (approvalProcess.deleted) return [];
        return new ApprovalProcessDeleted_1.ApprovalProcessDeleted(this.entityId, {}, approvalProcess.companyId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.DeleteApprovalProcess = DeleteApprovalProcess;