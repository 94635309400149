"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpmeshClient = exports.UpmeshConfig = exports.ClientModals = void 0;
var EventHandler_1 = require("cqrs-core/build/src/client/events/EventHandler");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var ReadRemoteStore_1 = require("cqrs-core/build/src/core/query/ReadRemoteStore");
var ServerConnection_1 = require("cqrs-core/build/src/core/ServerConnection");
var WaitFor_1 = require("cqrs-shared/build/src/WaitFor");
var AuthClient_1 = require("upmesh-auth-core/build/src/client/AuthClient");
var AuthConfig_1 = require("upmesh-auth-core/build/src/client/AuthConfig");
var RightsManager_1 = require("../access/rights/RightsManager");
var RightsManagerClient_1 = require("../access/rights/RightsManagerClient");
var CommandReadModels_1 = require("../server/webserver/commands/CommandReadModels");
var Envirement_1 = require("../server/webserver/Envirement");
var ClientStore_1 = require("./ClientStore");
var OfflineCommands_1 = require("./commands/OfflineCommands");
var Absence_1 = require("./query/Absence");
var ApiToken_1 = require("./query/ApiToken");
var AppData_1 = require("./query/AppData");
var ApprovalProcess_1 = require("./query/ApprovalProcess");
var Bookmark_1 = require("./query/Bookmark");
var Company_1 = require("./query/Company");
var CompanyMember_1 = require("./query/CompanyMember");
var CompanyProject_1 = require("./query/CompanyProject");
var CompanyProjectInfo_1 = require("./query/CompanyProjectInfo");
var CompanySettings_1 = require("./query/CompanySettings");
var CostCenter_1 = require("./query/CostCenter");
var ExtraPay_1 = require("./query/ExtraPay");
var ExtraPayTracking_1 = require("./query/ExtraPayTracking");
var Folder_1 = require("./query/Folder");
var Group_1 = require("./query/Group");
var Holiday_1 = require("./query/Holiday");
var Journal_1 = require("./query/Journal");
var JournalProtocol_1 = require("./query/JournalProtocol");
var JournalSettings_1 = require("./query/JournalSettings");
var LogBook_1 = require("./query/LogBook");
var Note_1 = require("./query/Note");
var NotificationsSettings_1 = require("./query/NotificationsSettings");
var PayrollAccounting_1 = require("./query/PayrollAccounting");
var PayrollAccountingPeriods_1 = require("./query/PayrollAccountingPeriods");
var PayrollAccountingPeriodsPerMember_1 = require("./query/PayrollAccountingPeriodsPerMember");
var Plan_1 = require("./query/Plan");
var PlanFiles_1 = require("./query/PlanFiles");
var PlanVersion_1 = require("./query/PlanVersion");
var Project_1 = require("./query/Project");
var ProjectAddress_1 = require("./query/ProjectAddress");
var ProjectRoles_1 = require("./query/ProjectRoles");
var ProjectUser_1 = require("./query/ProjectUser");
var Region_1 = require("./query/Region");
var ReportSettings_1 = require("./query/ReportSettings");
var ReSync_1 = require("./query/ReSync");
var Share_1 = require("./query/Share");
var StoredFile_1 = require("./query/StoredFile");
var Subscription_1 = require("./query/Subscription");
var Tag_1 = require("./query/Tag");
var TagGroup_1 = require("./query/TagGroup");
var Task_1 = require("./query/Task");
var Ticket_1 = require("./query/Ticket");
var TicketComments_1 = require("./query/TicketComments");
var TimeTracking_1 = require("./query/TimeTracking");
var UpmeshPartner_1 = require("./query/UpmeshPartner");
var UserProjectSettings_1 = require("./query/UserProjectSettings");
var View_1 = require("./query/View");
var WorkingTimeModel_1 = require("./query/WorkingTimeModel");
var ClientModals = (0, _createClass2.default)(function ClientModals() {
  (0, _classCallCheck2.default)(this, ClientModals);
  this.appData = new AppData_1.AppData();
  this.apiToken = new ApiToken_1.ApiToken();
  this.company = new Company_1.Company();
  this.companyMember = new CompanyMember_1.CompanyMember();
  this.companySettings = new CompanySettings_1.CompanySettings();
  this.companyProject = new CompanyProject_1.CompanyProject();
  this.upmeshPartner = new UpmeshPartner_1.UpmeshPartner();
  this.project = new Project_1.Project();
  this.projectRoles = new ProjectRoles_1.ProjectRoles();
  this.projectUser = new ProjectUser_1.ProjectUser();
  this.planFiles = new PlanFiles_1.PlanFiles();
  this.plan = new Plan_1.Plan();
  this.planVersion = new PlanVersion_1.PlanVersion();
  this.tag = new Tag_1.Tag();
  this.tagGroup = new TagGroup_1.TagGroup();
  this.ticket = new Ticket_1.Ticket();
  this.ticketComments = new TicketComments_1.TicketComments();
  this.folder = new Folder_1.Folder();
  this.storedFile = new StoredFile_1.StoredFile();
  this.reSync = new ReSync_1.ReSync();
  this.logBook = new LogBook_1.LogBook();
  this.projectAddress = new ProjectAddress_1.ProjectAddress();
  this.subscription = new Subscription_1.Subscription();
  this.reportSettings = new ReportSettings_1.ReportSettings();
  this.task = new Task_1.Task();
  this.workingTimeModel = new WorkingTimeModel_1.WorkingTimeModel();
  this.journal = new Journal_1.Journal();
  this.journalSettings = new JournalSettings_1.JournalSettings();
  this.journalProtocol = new JournalProtocol_1.JournalProtocol();
  this.notificationsSettings = new NotificationsSettings_1.NotificationsSettings();
  this.group = new Group_1.Group();
  this.costCenter = new CostCenter_1.CostCenter();
  this.timeTracking = new TimeTracking_1.TimeTracking();
  this.region = new Region_1.Region();
  this.extraPay = new ExtraPay_1.ExtraPay();
  this.extraPayTracking = new ExtraPayTracking_1.ExtraPayTracking();
  this.holiday = new Holiday_1.Holiday();
  this.bookmark = new Bookmark_1.Bookmark();
  this.note = new Note_1.Note();
  this.absence = new Absence_1.Absence();
  this.share = new Share_1.Share();
  this.companyProjectInfo = new CompanyProjectInfo_1.CompanyProjectInfo();
  this.view = new View_1.View();
  this.userProjectSettings = new UserProjectSettings_1.UserProjectSettings();
  this.approvalProcess = new ApprovalProcess_1.ApprovalProcess();
  this.payrollAccounting = new PayrollAccounting_1.PayrollAccounting();
  this.payrollAccountingPeriods = new PayrollAccountingPeriods_1.PayrollAccountingPeriods();
  this.payrollAccountingPeriodsPerMember = new PayrollAccountingPeriodsPerMember_1.PayrollAccountingPeriodsPerMember();
});
exports.ClientModals = ClientModals;
var UpmeshConfig = function (_AuthConfig_1$AuthCon) {
  function UpmeshConfig() {
    var _this;
    (0, _classCallCheck2.default)(this, UpmeshConfig);
    _this = _callSuper(this, UpmeshConfig, arguments);
    _this.remoteUrl = 'http://127.0.0.1:3012';
    return _this;
  }
  (0, _inherits2.default)(UpmeshConfig, _AuthConfig_1$AuthCon);
  return (0, _createClass2.default)(UpmeshConfig);
}(AuthConfig_1.AuthConfig);
exports.UpmeshConfig = UpmeshConfig;
var UpmeshClient = function () {
  function UpmeshClient() {
    var _this2 = this;
    (0, _classCallCheck2.default)(this, UpmeshClient);
    this.initWithT = false;
    this.initialized = false;
    this.registerOfflineCommands = false;
    this.initOnced = false;
    this.reSync = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (er) {
        er.entities.forEach(function (e) {
          var _e$entity, _e$entity2, _e$entity3;
          if (((_e$entity = e.entity) == null ? void 0 : _e$entity.ticketId) != null) {
            _this2.modals.ticket.reSync({
              idOnly: e.entity.ticketId,
              deleteBefore: true
            }).catch(function (err) {
              return void 0;
            });
            _this2.modals.ticketComments.reSync({
              deleteBefore: true,
              filter: `ticketId eq '${e.entity.ticketId}'`
            }).catch(function (err) {
              return void 0;
            });
            _this2.modals.storedFile.reSync({
              deleteBefore: true,
              filter: `forEntityId eq '${e.entity.ticketId}'`
            }).catch(function (err) {
              return void 0;
            });
          } else if (((_e$entity2 = e.entity) == null ? void 0 : _e$entity2.projectId) != null) {
            for (var key in _this2.modals) {
              try {
                if (key === 'project') {
                  var model = _this2.modals.project;
                  model.reSync({
                    deleteBefore: true,
                    filter: `id eq '${e.entity.projectId}'`
                  }).catch(function (err) {
                    return void 0;
                  });
                } else if (key !== 'reSync') {
                  var _model = _this2.modals[key];
                  _model.reSync({
                    deleteBefore: true,
                    filter: `projectId eq '${e.entity.projectId}'`
                  }).catch(function (err) {
                    return void 0;
                  });
                }
              } catch (err) {}
            }
          } else if (((_e$entity3 = e.entity) == null ? void 0 : _e$entity3.readModel) != null) {
            var _e$entity4;
            if (((_e$entity4 = e.entity) == null ? void 0 : _e$entity4.readModel) === 'folder') {
              _this2.modals.folder.reSync({
                filter: `id eq '${e.entity.entityId}'`,
                deleteBefore: true
              }).catch(function (err) {
                return void 0;
              });
              _this2.modals.storedFile.reSync({
                filter: `folder eq '${e.entity.entityId}'`,
                deleteBefore: true
              }).catch(function (err) {
                return void 0;
              });
            } else {
              try {
                var getModel = ReadModels_1.ReadModels.get(e.entity.readModel);
                if (getModel != null && typeof getModel['reSync'] === 'function') {
                  var filter = e.entity.entityId != null ? `id eq '${e.entity.entityId}'` : undefined;
                  getModel.reSync({
                    filter: filter,
                    deleteBefore: true
                  }).catch(function (err) {
                    return void 0;
                  });
                }
              } catch (err) {}
            }
          }
        });
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    this.startSync = function () {
      if (AuthClient_1.AuthClient.instance != null) AuthClient_1.AuthClient.instance.startSync().catch(function (err) {
        return void 0;
      });
    };
    this.onLogin = function (data) {
      _this2.initWithToken(data.token).catch(function (err) {
        return void 0;
      });
    };
    this.onLogout = function (_data) {
      _this2.initWithT = false;
      if (_this2.resyncKey != null) {
        AuthClient_1.AuthClient.instance.eventDispatcher.detach('ReSync', _this2.resyncKey);
        _this2.resyncKey = undefined;
      }
      _this2.resetModels().catch(function (err) {});
    };
    if (UpmeshClient._instance != null) {
      throw new Error('use UpmeshClient.instance');
    }
  }
  return (0, _createClass2.default)(UpmeshClient, [{
    key: "localDb",
    get: function get() {
      return AuthClient_1.AuthClient.instance.localDb;
    }
  }, {
    key: "ready",
    get: function get() {
      return AuthClient_1.AuthClient.instance.ready && this.initialized;
    }
  }, {
    key: "modals",
    get: function get() {
      return this._modals;
    }
  }, {
    key: "serverConnection",
    get: function get() {
      return AuthClient_1.AuthClient.instance.serverConnection;
    }
  }, {
    key: "syncDispatcher",
    get: function get() {
      return AuthClient_1.AuthClient.instance.syncDispatcher;
    }
  }, {
    key: "url",
    get: function get() {
      return this.upmeshConfig.remoteUrl;
    }
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* (upmeshConfig, token) {
        var _this3 = this,
          _AuthClient_1$AuthCli;
        this.upmeshConfig = upmeshConfig;
        if (!this.initialized) {
          CommandReadModels_1.CommandReadModels.instance = this.modals;
          RightsManager_1.RightsManager.instance = new RightsManagerClient_1.RightsManagerClient();
          AuthClient_1.AuthClient.onLogin(this.onLogin);
          AuthClient_1.AuthClient.onLogout(this.onLogout);
          if (this.upmeshConfig.serverConnection == null) {
            var s = new ServerConnection_1.ServerConnection({
              remoteOptions: {
                apiUrl: this.upmeshConfig.remoteUrl,
                authToken: token
              },
              autoConnect: false,
              query: {
                clientversion: Envirement_1.Envirement.minimumClientVersion.toString()
              }
            });
            this.upmeshConfig.serverConnection = function () {
              return s;
            };
          }
        }
        var oldval = this.upmeshConfig.startSync;
        this.upmeshConfig.startSync = false;
        yield AuthClient_1.AuthClient.instance.init(this.upmeshConfig, token);
        this.upmeshConfig.startSync = oldval;
        if (this.resyncKey == null) {
          this.resyncKey = AuthClient_1.AuthClient.instance.eventDispatcher.attach({
            readModelName: 'ReSync',
            callback: function callback(e) {
              _this3.reSync(e).catch(function (err) {
                return void 0;
              });
            },
            attachKey: 'UpmeshClientReSync'
          });
        }
        ClientStore_1.ClientStore.commandStore = AuthClient_1.AuthClient.instance.commandStore;
        if (this.remoteDB == null && AuthClient_1.AuthClient.instance.commandStore != null) {
          this.remoteDB = new ReadRemoteStore_1.ReadRemoteStore(AuthClient_1.AuthClient.instance.commandStore);
          yield this.remoteDB.initDB();
        }
        yield (_AuthClient_1$AuthCli = AuthClient_1.AuthClient.instance.serverConnection) == null ? void 0 : _AuthClient_1$AuthCli.connect(2000);
        yield this.waitForStartUp(token);
        if (!this.initialized) {
          this.initialized = true;
        }
        if (!this.registerOfflineCommands) {
          OfflineCommands_1.OfflineCommands.registerOfflineCommands();
          this.registerOfflineCommands = true;
        }
      });
      function init(_x2, _x3) {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }, {
    key: "waitForStartUp",
    value: function () {
      var _waitForStartUp = (0, _asyncToGenerator2.default)(function* (token) {
        var _this4 = this;
        if (token != null && token.length > 0) {
          yield WaitFor_1.WaitFor.instance.waitFor(function () {
            return _this4.initWithT;
          }, 33, 10000);
        } else {
          yield WaitFor_1.WaitFor.instance.waitFor(function () {
            return _this4.serverConnection.isConnected;
          }, 33, 10000);
        }
      });
      function waitForStartUp(_x4) {
        return _waitForStartUp.apply(this, arguments);
      }
      return waitForStartUp;
    }()
  }, {
    key: "resetModels",
    value: function () {
      var _resetModels = (0, _asyncToGenerator2.default)(function* () {
        if (this.modals != null) {
          for (var key in this.modals) {
            var model = this.modals[key];
            yield model.reset();
          }
        }
      });
      function resetModels() {
        return _resetModels.apply(this, arguments);
      }
      return resetModels;
    }()
  }, {
    key: "serverConnected",
    value: function serverConnected() {
      return AuthClient_1.AuthClient.instance.serverConnected();
    }
  }, {
    key: "initWithToken",
    value: function () {
      var _initWithToken = (0, _asyncToGenerator2.default)(function* (_token) {
        var _this5 = this;
        if (!this.initWithT) {
          ClientStore_1.ClientStore.commandStore = AuthClient_1.AuthClient.instance.commandStore;
          if (this.remoteDB == null && AuthClient_1.AuthClient.instance.commandStore != null) {
            this.remoteDB = new ReadRemoteStore_1.ReadRemoteStore(AuthClient_1.AuthClient.instance.commandStore);
          }
          yield this.remoteDB.initDB();
          if (this._modals == null) {
            this._modals = new ClientModals();
            CommandReadModels_1.CommandReadModels.instance = this.modals;
          }
          var repo = this.remoteDB.getRepos(this.modals.project.exampleEntity);
          if (AuthClient_1.AuthClient.instance.syncDispatcher == null) throw new Error('need syncdispatcher');
          var readModelConfig = {
            remoteDB: this.remoteDB,
            serverConnection: function serverConnection() {
              return _this5.serverConnection;
            },
            syncDispatcher: AuthClient_1.AuthClient.instance.syncDispatcher,
            repo: repo,
            events: EventHandler_1.EventHandler.defaultHandler,
            eventDispatcher: AuthClient_1.AuthClient.instance.eventDispatcher
          };
          yield this.resetModels();
          for (var key in this.modals) {
            var model = this.modals[key];
            if (this.initOnced) {}
            var _repo = this.remoteDB.getRepos(model.exampleEntity);
            yield model.init(Object.assign({}, readModelConfig, {
              repo: _repo,
              priority: model['syncPriority'] && typeof model['syncPriority'] === 'number' ? model['syncPriority'] : 3
            }));
          }
          this.initOnced = true;
          this.initWithT = true;
          if (this.serverConnection.isConnected && this.upmeshConfig.startSync) {
            this.startSync();
          }
        }
      });
      function initWithToken(_x5) {
        return _initWithToken.apply(this, arguments);
      }
      return initWithToken;
    }()
  }], [{
    key: "initialized",
    get: function get() {
      return UpmeshClient.instance.initialized;
    }
  }, {
    key: "eventDispatcher",
    get: function get() {
      return AuthClient_1.AuthClient.instance.eventDispatcher;
    }
  }, {
    key: "instance",
    get: function get() {
      if (UpmeshClient._instance == null) {
        UpmeshClient._instance = new UpmeshClient();
      }
      return UpmeshClient._instance;
    }
  }]);
}();
exports.UpmeshClient = UpmeshClient;