import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import React from 'react';
import { ChangeCostCenter, } from 'upmesh-core/src/client/commands/companies/costcenter/ChangeCostCenter';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { TextButton } from 'materialTheme/src/theme/components/button/TextButton';
import { CreateCostCenter, } from 'upmesh-core/src/client/commands/companies/costcenter/CreateCostCenter';
import { I18n } from '../../i18n/I18n';
export const AddOrChangeCostCenterToCompanyDialog = React.memo((props) => {
    const onSaved = (_result) => {
        Dialog.instance?.close();
    };
    if (props.costCenter != null) {
        const currentData = {
            mark: props.costCenter.mark,
            description: props.costCenter.description == null ? '' : props.costCenter.description,
            costType: props.costCenter.costType,
            foreignId: props.costCenter.foreignId,
            active: props.costCenter.active != null ? props.costCenter.active : true,
        };
        const dataOptions = new Map();
        dataOptions.set('mark', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('costCenterMark') },
        });
        dataOptions.set('description', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('costCenterDescription') },
        });
        dataOptions.set('costType', {
            formType: 'switch',
            cols: 1,
            mapValue: (value, _fromFormInput) => {
                if (value === 'costCenter')
                    return false;
                if (value === 'costUnit')
                    return true;
                if (value === true)
                    return 'costUnit';
                return 'costCenter';
            },
            props: { labelText: I18n.m.getMessage('costCenterIsCostUnit') },
        });
        dataOptions.set('active', {
            formType: 'switch',
            cols: 1,
            props: { labelText: I18n.m.getMessage('taskActive') },
        });
        return (<Form key="AddOrChangeCostCenterToCompanyForm" formHeaderProps={{
                formTitle: I18n.m.getMessage('companyChangeCostCenterDialogTitle'),
            }} stickyActions={false} command={new ChangeCostCenter(currentData, props.costCenter.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={onSaved} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[<TextButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close}/>]}/>);
    }
    const currentData = {
        mark: '',
        description: '',
        companyId: props.companyId,
        costType: 'costCenter',
        foreignId: '',
        active: true,
    };
    const dataOptions = new Map();
    dataOptions.set('mark', {
        formType: 'string',
        cols: 1,
        props: { labelText: I18n.m.getMessage('costCenterMark') },
    });
    dataOptions.set('description', {
        formType: 'string',
        cols: 1,
        props: { labelText: I18n.m.getMessage('costCenterDescription') },
    });
    dataOptions.set('costType', {
        formType: 'switch',
        cols: 1,
        mapValue: (value, _fromFormInput) => {
            if (value === 'costCenter')
                return false;
            if (value === 'costUnit')
                return true;
            if (value === true)
                return 'costUnit';
            return 'costCenter';
        },
        props: { labelText: I18n.m.getMessage('costCenterIsCostUnit') },
    });
    dataOptions.set('active', {
        formType: 'switch',
        cols: 1,
        props: { labelText: I18n.m.getMessage('taskActive') },
    });
    return (<Form key="AddOrChangeCostCenterToCompanyForm" formHeaderProps={{
            formTitle: I18n.m.getMessage('companyAddCostCenterDialogTitle'),
        }} stickyActions stickyHeader command={new CreateCostCenter(currentData)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={onSaved} saveButtonLabel={I18n.m.getMessage('add')} additionalButtons={[<TextButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close}/>]}/>);
});
