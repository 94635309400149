import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { DeletePlanVersion } from 'upmesh-core/src/client/commands/plans/DeletePlanVersion';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
import { PlanThumb169 } from './PlanThumb169';
import { openPlanVersionTicketMappingDialog } from './PlanVersionTicketMappingDialog';
import { TicketWithPlanDownloader } from '../tickets/TicketWithPlanDownloader';
import { Config } from '../../config/Config';
const itemWidth = 256;
export const PlanVersionsList = React.memo((props) => {
    const [planVersions, setPlanVersions] = useState(props.planVersions);
    const [selectedPlanVersionId, setSelectedPlanVersionId] = useState('');
    const [numColumns, setNumColumns] = useState(Math.floor(props.width / itemWidth));
    const gotoPlanVersion = (id) => () => {
        const { plan } = props;
        Routing.instance.goTo(`/projects/${plan.projectId}/plans/${plan.id}/versions/${id}`);
    };
    const handleVersionsChange = (e) => {
        const { plan } = props;
        if (plan != null && plan.projectId === e.projectId) {
            UpmeshClient.instance.modals.planVersion
                .get({
                filter: `planId eq '${plan.id}' and deleted ne true`,
            })
                .then((getPlanVersions) => {
                getPlanVersions.sort((a, b) => (a.version > b.version ? -1 : a.version < b.version ? 1 : 0));
                setPlanVersions(getPlanVersions);
            })
                .catch((err) => console.error(err));
        }
    };
    useEffect(() => {
        CurrentProject.planVersionsChanged.attach(handleVersionsChange);
        return () => {
            CurrentProject.planVersionsChanged.detach(handleVersionsChange);
        };
    }, []);
    useEffect(() => {
        if (props.selectedPlanVersionId !== selectedPlanVersionId)
            setSelectedPlanVersionId(props.selectedPlanVersionId);
        if (Math.floor(props.width / itemWidth) !== numColumns)
            setNumColumns(Math.floor(props.width / itemWidth));
    }, [props.plan, props.width, props.selectedPlanVersionId]);
    const activateVersion = (id) => (_e) => {
        const { plan, planVersions } = props;
        const planVersionNewIndex = planVersions.findIndex((p) => p.id === id);
        const planVersionOldIndex = planVersions.findIndex((p) => p.id === plan.activePlanId);
        openPlanVersionTicketMappingDialog({
            plan,
            planVersionNew: planVersions[planVersionNewIndex],
            planVersionOld: planVersions[planVersionOldIndex],
        })(_e);
    };
    const deleteVersionNow = (item) => () => {
        Alert.instance?.close(() => {
            const { selectedPlanVersionId, plan } = props;
            try {
                const d = new DeletePlanVersion({}, item.id);
                d.execute()
                    .then(() => {
                    if (selectedPlanVersionId === item.id) {
                        requestAnimationFrame(() => {
                            Routing.instance.goTo(`/projects/${plan.projectId}/plans/${plan.id}/versions/${plan.activePlanId}`, true);
                        });
                    }
                })
                    .catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e));
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
            }
        });
    };
    const deleteVersion = (item) => () => {
        RightsManager.hasWriteRight(item.projectId, CurrentUser.userId, 'commandDiscardPlanFile')
            .then((hasRight) => {
            if (!hasRight)
                throw { message: 'Keine Berechtigung', messageCode: 'forbiddenCommand' };
            Routing.instance.alert.post({
                title: I18n.m.getMessage('planVersionDeleteQuestionTitle', { plantitle: item.orgFilename }),
                text: I18n.m.getMessage('planVersionDeleteQuestionText'),
                buttons: [
                    <ContainedButton key="cancel" onPress={Alert.instance?.close} title={I18n.m.getMessage('cancel')}/>,
                    <ContainedButton key="del" onPress={deleteVersionNow(item)} title={I18n.m.getMessage('delete')} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        })
            .catch((e) => {
            DefaultErrorHandler.showDefaultErrorAlert(e);
        });
    };
    const downloadVersionNow = (planVersion) => () => {
        const project = CurrentProject.instance.getCurrentProject();
        Alert.instance?.close(() => {
            const { plan } = props;
            if (!project?.showPlanQrCode) {
                const ex = planVersion.orgFilename.substr(planVersion.orgFilename.lastIndexOf('.'));
                CurrentProject.instance.downloadPlanNow(plan, planVersion, ex).catch((error) => {
                    DefaultErrorHandler.showDefaultErrorAlert(error, I18n.m, error);
                });
            }
            else {
                const pdfStarter = new TicketWithPlanDownloader([], planVersion, plan, project, Config.b2cURL);
                pdfStarter.open(false).catch((e) => {
                    console.error('error on downloading/creating pdf with tickets as download', e);
                });
            }
        });
    };
    const renderRow = ({ item }) => {
        const { selectedPlanVersionId, plan } = props;
        const selected = item.id === selectedPlanVersionId;
        const active = item.id === plan.activePlanId;
        return (<View style={{ padding: ThemeManager.style.contentPaddingValue, paddingBottom: 0 }} key={`PV1${item.id}`}>
          <View style={{
                paddingBottom: 0,
                backgroundColor: '#FFFFFF',
                margin: 0,
                borderWidth: selected ? ThemeManager.style.borderWidth * 2 : ThemeManager.style.borderWidth,
                borderStyle: 'solid',
                borderColor: selected === true ? ThemeManager.style.brandPrimary : ThemeManager.style.borderColor,
                borderRadius: ThemeManager.style.borderRadius,
                overflow: 'hidden',
            }}>
            <PlanThumb169 projectId={item.projectId} onPress={gotoPlanVersion(item.id)} noBorder fileSource="planVersion" fileId={item.id} width={256 - 2 * ThemeManager.style.contentPaddingValue} title={item.orgFilename} deleteFunction={active ? undefined : deleteVersion(item)} downloadFunction={downloadVersionNow(item)}/>
            <View style={{
                width: '100%',
                padding: 0,
            }}>
              <View style={{ marginTop: 0, paddingRight: 8, paddingLeft: 6, paddingBottom: 0 }}>
                <MaterialText color={ThemeManager.style.black54} type={MaterialTextTypes.Body1}>
                  {I18n.m.dateCurrent.localeDateString(item.createdAt)}
                </MaterialText>
              </View>
              {active ? (<View key="planBadge" style={{
                    alignSelf: 'stretch',
                    backgroundColor: ThemeManager.style.brandPrimary,
                    justifyContent: 'center',
                    padding: 0,
                }}>
                  <MaterialText centeredText centeredBox type={MaterialTextTypes.Body1} color="#FFFFFF">
                    {I18n.m.getMessage('planVersionActive')}
                  </MaterialText>
                </View>) : (<ContainedButton disabled={active} title={I18n.m.getMessage('planActivate')} full onPress={activateVersion(item.id)} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>)}
            </View>
          </View>
        </View>);
    };
    const getItemKey = (item, _index) => `planversion_${item.id}`;
    return (<View style={{
            width: props.width,
            height: props.height,
            padding: 0,
        }}>
        <FlatList key={`planVersionsList_${numColumns}`} keyExtractor={getItemKey} renderItem={renderRow} numColumns={numColumns} data={planVersions} contentContainerStyle={{
            alignItems: numColumns < 2 ? 'center' : 'flex-start',
            paddingBottom: 32,
        }} style={{
            width: props.width,
            height: props.height,
        }}/>
      </View>);
});
