"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeExtraPayTracking = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ExtraPayTrackingChanged_1 = require("../../../events/company/extrapay/ExtraPayTrackingChanged");
var OrderedApprovalProcess_1 = require("../../../query/entities/simple/OrderedApprovalProcess");
var TimeTrackingEntity_1 = require("../../../query/entities/TimeTrackingEntity");
var ChangeExtraPayTracking = function (_CommandRoot_1$Comman) {
  function ChangeExtraPayTracking(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v4();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeExtraPayTracking);
    _this = _callSuper(this, ChangeExtraPayTracking, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeExtraPayTracking';
    return _this;
  }
  (0, _inherits2.default)(ChangeExtraPayTracking, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeExtraPayTracking, [{
    key: "getExtraPay",
    value: function () {
      var _getExtraPay = (0, _asyncToGenerator2.default)(function* () {
        if (this.data.extraPayId == null || this.data.extraPayId.length === 0) return null;
        if (this._extraPay == null) this._extraPay = yield CommandReadModels_1.CommandReadModels.extraPay.getById(this.data.extraPayId);
        return this._extraPay;
      });
      function getExtraPay() {
        return _getExtraPay.apply(this, arguments);
      }
      return getExtraPay;
    }()
  }, {
    key: "getExtraPayTracking",
    value: function () {
      var _getExtraPayTracking = (0, _asyncToGenerator2.default)(function* () {
        if (this._extraPayTracking == null) this._extraPayTracking = yield CommandReadModels_1.CommandReadModels.extraPayTracking.getById(this.entityId);
        return this._extraPayTracking;
      });
      function getExtraPayTracking() {
        return _getExtraPayTracking.apply(this, arguments);
      }
      return getExtraPayTracking;
    }()
  }, {
    key: "getMe",
    value: function () {
      var _getMe = (0, _asyncToGenerator2.default)(function* () {
        if (this._me == null) {
          var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
          var extraPayTracking = yield this.getExtraPayTracking();
          var company = yield CommandReadModels_1.CommandReadModels.company.getById(extraPayTracking.companyId);
          var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
            filter: `companyId eq '${company.id}' and userId eq '${userId}' and deleted ne true`,
            top: 1
          });
          if (companyMember.length === 1) this._me = companyMember[0];
        }
        return this._me;
      });
      function getMe() {
        return _getMe.apply(this, arguments);
      }
      return getMe;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var extraPayTracking = yield this.getExtraPayTracking();
        if (userId === 'all') return true;
        var me = yield this.getMe();
        if (me != null) {
          if (me.role !== 'inactive' && me.payroll) {
            return true;
          }
          if (extraPayTracking.isBlocked === 'open' && extraPayTracking.nextApprovalStepFor) {
            var isIn = extraPayTracking.nextApprovalStepFor.find(function (a) {
              return a.userId === userId;
            });
            if (isIn && isIn.canEditInDays) {
              var today = new Date();
              today.setDate(today.getDate() - isIn.canEditInDays);
              if (today.getTime() < extraPayTracking.createdAt.getTime()) return true;
            }
          }
          var forCompanyMember = yield CommandReadModels_1.CommandReadModels.companyMember.getById(extraPayTracking.memberId);
          if (extraPayTracking.createdBy === userId || extraPayTracking.memberId === me.id || forCompanyMember.userId === userId) {
            var memberId = this.data.memberId != null ? this.data.memberId : extraPayTracking.memberId;
            var companyMember2 = yield CommandReadModels_1.CommandReadModels.companyMember.getById(memberId);
            var period = yield TimeTrackingEntity_1.TimeTrackingEntity.getRecordingPeriod(companyMember2);
            var starts = new Date(this.data.day != null ? this.data.day : extraPayTracking.day);
            if (starts.getTime() < period.start.getTime() || starts.getTime() > period.end.getTime()) {
              throw {
                message: 'Das Startdatum liegt nicht im möglichen Erfassungszeitraum',
                messageCode: 'timeTrackingNotInRecordingPeriod'
              };
            }
            if (me.role !== 'inactive') {
              return true;
            }
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "checkForRecordingPeriod",
    value: function () {
      var _checkForRecordingPeriod = (0, _asyncToGenerator2.default)(function* (memberId) {
        var extraPayTracking = yield this.getExtraPayTracking();
        var member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(memberId);
        if (member != null) {
          var period = yield TimeTrackingEntity_1.TimeTrackingEntity.getRecordingPeriod(member);
          var starts = new Date(this.data.day != null ? this.data.day : extraPayTracking.day);
          if (starts.getTime() < period.start.getTime() || starts.getTime() > period.end.getTime()) {
            throw {
              message: 'Das Datum liegt nicht im möglichen Erfassungszeitraum',
              messageCode: 'timeTrackingNotInRecordingPeriod'
            };
          }
        }
      });
      function checkForRecordingPeriod(_x) {
        return _checkForRecordingPeriod.apply(this, arguments);
      }
      return checkForRecordingPeriod;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var extraPayTracking = yield this.getExtraPayTracking();
        var hasChanged = false;
        var data = {};
        if (this.data.projectId != null && extraPayTracking.projectId !== this.data.projectId) {
          data.projectId = this.data.projectId;
          hasChanged = true;
        }
        if (this.data.memberId != null && extraPayTracking.memberId !== this.data.memberId) {
          data.memberId = this.data.memberId;
          hasChanged = true;
        }
        if (this.data.day != null && extraPayTracking.day !== this.data.day) {
          data.day = this.data.day;
          hasChanged = true;
        }
        if (this.data.extraPayId != null && extraPayTracking.extraPayId !== this.data.extraPayId) {
          data.extraPayId = this.data.extraPayId;
          hasChanged = true;
        }
        if (this.data.extraField != null && extraPayTracking.extraField !== this.data.extraField) {
          data.extraField = this.data.extraField;
          hasChanged = true;
        }
        if (this.data.notes != null && extraPayTracking.notes !== this.data.notes) {
          data.notes = this.data.notes;
          hasChanged = true;
        }
        if (this.data.foreignId != null && extraPayTracking.foreignId !== this.data.foreignId) {
          data.foreignId = this.data.foreignId;
          hasChanged = true;
        }
        if (this.data.costCenterId != null && extraPayTracking.costCenterId !== this.data.costCenterId) {
          data.costCenterId = this.data.costCenterId;
          hasChanged = true;
        }
        if (!hasChanged) return [];
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.getById(extraPayTracking.memberId);
        var approvalStep = yield OrderedApprovalProcess_1.OrderedApprovalProcess.getInitialApprovalProcessStep('extraPay', extraPayTracking.companyId, companyMember.id, companyMember.groupId, this.data.extraPayId ? this.data.extraPayId : extraPayTracking.extraPayId);
        return new ExtraPayTrackingChanged_1.ExtraPayTrackingChanged(this.entityId, Object.assign({}, data, approvalStep), extraPayTracking.companyId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        var data = this.data;
        var extraPayTracking = yield this.getExtraPayTracking();
        var me = yield this.getMe();
        if (me != null && !me.payroll && (key == null || key === 'memberId' || key === 'day')) {
          try {
            var memberId = data.memberId != null ? data.memberId : extraPayTracking.memberId;
            yield this.checkForRecordingPeriod(memberId);
          } catch (err) {
            errors.push({
              message: err.message,
              messageCode: err.messageCode,
              key: 'day'
            });
          }
        }
        if (key == null || key === 'costCenterId' || key === 'projectId') {
          if (data.costCenterId != null && data.costCenterId.length > 0) {
            try {
              var cc = yield CommandReadModels_1.CommandReadModels.costCenter.getById(data.costCenterId);
              if (cc.costType === 'costUnit') {
                if (data.projectId == null) {
                  errors.push({
                    message: 'Wenn ein Kostenträger gewählt ist, wird die Projekt id benötigt',
                    messageCode: 'costUnitNeedsProject',
                    key: 'projectId'
                  });
                }
                var isIn = cc.projectIds == null ? -1 : cc.projectIds.findIndex(function (p) {
                  return p === data.projectId;
                });
                if (isIn === -1) {
                  errors.push({
                    message: 'Das Projekt ist dem Kostenträger nicht zugeordnet',
                    messageCode: 'projectNotInCostUnit',
                    key: 'projectId'
                  });
                }
              }
            } catch (e) {
              errors.push({
                message: 'Diese Kostenstelle existiert nicht',
                messageCode: 'costCenterIdNotFound',
                key: 'costCenterId'
              });
            }
          }
        }
        var extraPay = yield this.getExtraPay();
        if ((key == null || key === 'extraPayId') && extraPay != null) {
          var _extraPay = yield this.getExtraPay();
          if (data.extraPayId != null && data.extraPayId.length > 0 && _extraPay != null) {
            var _memberId = data.memberId != null ? data.memberId : extraPayTracking.memberId;
            if (_extraPay.onceADay && _memberId != null) {
              var day = new Date(this.data.day != null ? this.data.day : extraPayTracking.day);
              var start = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0, 0);
              var end = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1, 0, 0, 0);
              var exists = yield CommandReadModels_1.CommandReadModels.extraPayTracking.get({
                filter: `memberId eq '${_memberId}' and extraPayId eq '${data.extraPayId}' and deleted ne true and day ge ${start.toISOString()} and day lt ${end.toISOString()}`
              });
              if (exists.length > 0 && exists[0].id !== this.entityId) {
                errors.push({
                  message: 'wird benötigt',
                  messageCode: 'extraOnlyOnceADay'
                });
              }
            }
            if (_extraPay.fieldType === 'number') {
              if (this.data.extraField == null) {
                errors.push({
                  message: 'wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (typeof data.extraField !== 'number') {
                errors.push({
                  message: 'Zahl wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (_extraPay.field != null && _extraPay.field.min != null && data.extraField < _extraPay.field.min) {
                errors.push({
                  message: `Der Wert ist zu klein, min ${_extraPay.field.min}`,
                  messageCode: 'numberToSmall',
                  messageData: {
                    value: _extraPay.field.min
                  },
                  key: 'extraField'
                });
              } else if (_extraPay.field != null && _extraPay.field.max != null && data.extraField > _extraPay.field.max) {
                errors.push({
                  message: `Der Wert ist zu groß, maximum ${_extraPay.field.min}`,
                  messageCode: 'numberToBig',
                  messageData: {
                    value: _extraPay.field.max
                  },
                  key: 'extraField'
                });
              }
            } else if (_extraPay.fieldType === 'duration') {
              var _data$extraField, _data$extraField2;
              if (this.data.extraField == null) {
                errors.push({
                  message: 'wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (typeof data.extraField === 'number') {
                errors.push({
                  message: 'Zeitspanne wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (((_data$extraField = data.extraField) == null ? void 0 : _data$extraField.from) == null) {
                errors.push({
                  message: 'Zeit wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (((_data$extraField2 = data.extraField) == null ? void 0 : _data$extraField2.to) == null) {
                errors.push({
                  message: 'Zeit wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              }
            }
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validate(_x2) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeExtraPayTracking = ChangeExtraPayTracking;