"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeTrackingEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var WorkingTimeModelEntity_1 = require("./WorkingTimeModelEntity");
var TimeTrackingEntity = function (_ReadModelEntity_1$Re) {
  function TimeTrackingEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, TimeTrackingEntity);
    _this = _callSuper(this, TimeTrackingEntity, [obj]);
    _this.deleted = false;
    _this.entityName = 'TimeTracking';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(TimeTrackingEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(TimeTrackingEntity, [{
    key: "getMemberId",
    value: function () {
      var _getMemberId = (0, _asyncToGenerator2.default)(function* () {
        if (this.userId != null) {
          if (TimeTrackingEntity.userIdMemberIdMapperCache.has(this.userId)) return TimeTrackingEntity.userIdMemberIdMapperCache.get(this.userId);
          var m = ReadModels_1.ReadModels.get('CompanyMember');
          var me = yield m.get({
            filter: `companyId eq '${this.companyId}' and userId eq '${this.userId}'`,
            orderby: 'lastModifiedAt DESC'
          });
          if (me.length > 0) {
            TimeTrackingEntity.userIdMemberIdMapperCache.set(this.userId, me[0].id);
            return me[0].id;
          }
        }
        if (this.memberId != null) return this.memberId;
        return '';
      });
      function getMemberId() {
        return _getMemberId.apply(this, arguments);
      }
      return getMemberId;
    }()
  }, {
    key: "getMember",
    value: function () {
      var _getMember = (0, _asyncToGenerator2.default)(function* () {
        var memberId = yield this.getMemberId();
        return ReadModels_1.ReadModels.get('CompanyMember').getById(memberId);
      });
      function getMember() {
        return _getMember.apply(this, arguments);
      }
      return getMember;
    }()
  }, {
    key: "createOne",
    value: function createOne(obj) {
      return new TimeTrackingEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      baseMap.set('ends', new OdataEntity_1.OdataIndex(true));
      baseMap.set('foreignId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('taskId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('note', new OdataEntity_1.OdataIndex(true, false, false, true));
      baseMap.set('pause', new OdataEntity_1.OdataIndex());
      baseMap.set('status', new OdataEntity_1.OdataIndex(true));
      baseMap.set('costCenterId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectTitle', new OdataEntity_1.OdataIndex());
      baseMap.set('starts', new OdataEntity_1.OdataIndex(true));
      baseMap.set('memberId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('absenceId', new OdataEntity_1.OdataIndex());
      baseMap.set('absence', new OdataEntity_1.OdataIndex());
      baseMap.set('absendeOvertimeReduction', new OdataEntity_1.OdataIndex());
      baseMap.set('userId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('nextApprovalStepFor', new OdataEntity_1.OdataIndex(true));
      baseMap.set('approvalProcessId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('currentApprovalProcessStep', new OdataEntity_1.OdataIndex(true));
      baseMap.set('isBlocked', new OdataEntity_1.OdataIndex(true));
      baseMap.set('approvals', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }, {
    key: "getWorkingTimeInMs",
    value: function getWorkingTimeInMs() {
      var _this$ends;
      if (this.absendeOvertimeReduction) return 0;
      var pause = this.pause ? this.pause.minutes * 60 * 1000 + this.pause.hours * 60 * 60 * 1000 : 0;
      var workingMS = new Date((_this$ends = this.ends) != null ? _this$ends : 0).getTime() - new Date(this.starts).getTime();
      return Math.max(workingMS - pause, 0);
    }
  }], [{
    key: "msToTime",
    value: function msToTime(duration, incluseSeconds, moreThanADay) {
      if (duration == null || Number.isNaN(duration)) return '00:00';
      var minus = false;
      if (duration < 0) {
        duration = Math.abs(duration);
        minus = true;
      }
      var seconds = Math.floor(duration / 1000 % 60);
      var minutes = Math.floor(duration / 60000 % 60);
      var hours = duration / 3600000;
      if (moreThanADay !== true) {
        hours %= 24;
      }
      hours = Math.floor(hours);
      var h = hours < 10 ? `0${hours}` : hours.toString(10);
      var m = minutes < 10 ? `0${minutes}` : minutes.toString(10);
      var s = seconds < 10 ? `0${seconds}` : seconds.toString(10);
      if (incluseSeconds) return `${h}:${m}:${s}`;
      return `${minus ? '-' : ''}${h}:${m}`;
    }
  }, {
    key: "msToPause",
    value: function msToPause(duration) {
      var minutes = Math.floor(duration / (1000 * 60) % 60);
      var hours = Math.floor(duration / (1000 * 60 * 60) % 24);
      return {
        hours: hours,
        minutes: minutes
      };
    }
  }, {
    key: "getRecordingPeriod",
    value: function () {
      var _getRecordingPeriod = (0, _asyncToGenerator2.default)(function* (companyMember) {
        var companySettingsC = ReadModels_1.ReadModels.get('CompanySettings');
        var workingTimeModelC = ReadModels_1.ReadModels.get('WorkingTimeModel');
        var companySettings = yield companySettingsC.getById(companyMember.companyId);
        var wId = companyMember.workingTimeModelId != null ? companyMember.workingTimeModelId : companySettings.defaultWorkingTimeModelId;
        var workingTimeModel = yield workingTimeModelC.getById(wId);
        var todayDate = new Date();
        var start = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - workingTimeModel.postRecordingInDays, 0, 0, 0, 0);
        var end = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate(), 23, 59, 59, 59);
        return {
          start: start,
          end: end
        };
      });
      function getRecordingPeriod(_x) {
        return _getRecordingPeriod.apply(this, arguments);
      }
      return getRecordingPeriod;
    }()
  }, {
    key: "offlineDateLimit",
    value: function () {
      var _offlineDateLimit = (0, _asyncToGenerator2.default)(function* () {
        var today = new Date();
        return new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0, 0);
      });
      function offlineDateLimit() {
        return _offlineDateLimit.apply(this, arguments);
      }
      return offlineDateLimit;
    }()
  }, {
    key: "getRunningTime",
    value: function getRunningTime(timeTracking) {
      if (timeTracking.ends == null) {
        var ends = new Date();
        var pause = timeTracking.pause == null ? 0 : (timeTracking.pause.hours * 60 + timeTracking.pause.minutes) * 60000;
        return ends.getTime() - new Date(timeTracking.starts).getTime() - pause;
      }
      return null;
    }
  }, {
    key: "isCollidingWithList",
    value: function isCollidingWithList(entry, entries) {
      try {
        var start = new Date(entry.starts);
        var end = entry.ends == null ? new Date(start.getTime() + 60 * 1000) : new Date(entry.ends);
        return entries.filter(function (element) {
          if (element.isBlocked === 'rejected') return false;
          if (element.deleted) return false;
          if (element.id === entry.id) return false;
          if (element.ends != null && end != null && element.starts != null && start != null) {
            element.starts = new Date(element.starts);
            element.ends = new Date(element.ends);
            if (start.getTime() >= element.ends.getTime()) return false;
            if (element.starts.getTime() >= end.getTime()) return false;
            return true;
          }
          return false;
        });
      } catch (err) {
        return [];
      }
    }
  }, {
    key: "isColliding",
    value: function () {
      var _isColliding = (0, _asyncToGenerator2.default)(function* (entry) {
        var compareTime = new TimeTrackingEntity(entry);
        compareTime.starts = new Date(compareTime.starts);
        if (compareTime.ends == null) {
          compareTime.ends = new Date(compareTime.starts.getTime() + 60 * 1000);
        } else {
          compareTime.ends = new Date(compareTime.ends);
        }
        var collidingDay = new Date(compareTime.starts.getFullYear(), compareTime.starts.getMonth(), compareTime.starts.getDate(), 0, 0, 0, 0);
        var collidingDayEnd = new Date(compareTime.starts.getFullYear(), compareTime.starts.getMonth(), compareTime.starts.getDate(), 23, 59, 59, 59);
        var possibleColliding = yield ReadModels_1.ReadModels.get('TimeTracking').get({
          filter: entry.userId != null && entry.userId.length > 0 ? `userId eq '${entry.userId}' and deleted ne true and starts gt ${collidingDay.toISOString()} and starts lt ${collidingDayEnd.toISOString()} and end ne null and id ne '${entry.id}'` : `memberId eq '${entry.memberId}' and deleted ne true and starts gt ${collidingDay.toISOString()} and starts lt ${collidingDayEnd.toISOString()} and end ne null and id ne '${entry.id}'`
        });
        return TimeTrackingEntity.isCollidingWithList(compareTime, possibleColliding);
      });
      function isColliding(_x2) {
        return _isColliding.apply(this, arguments);
      }
      return isColliding;
    }()
  }, {
    key: "convertAbsenceToTimeTracking",
    value: function () {
      var _convertAbsenceToTimeTracking = (0, _asyncToGenerator2.default)(function* (a, _w, startMin, endMax) {
        if (!(a.starts instanceof Date)) a.starts = new Date(a.starts);
        if (!(a.ends instanceof Date)) a.ends = new Date(a.ends);
        if (startMin != null && a.starts.getTime() < startMin.getTime()) a.starts = new Date(startMin);
        if (endMax != null && a.ends.getTime() > endMax.getTime()) a.ends = new Date(endMax);
        var countDays = a.getDayDiff();
        var tts = [];
        for (var i = 0; i < countDays; i += 1) {
          var starts = new Date(a.starts.getFullYear(), a.starts.getMonth(), a.starts.getDate() + i, 0, 0, 0, 0);
          var ends = new Date(starts.getFullYear(), starts.getMonth(), starts.getDate());
          var wt = yield _w(starts, a.memberId);
          if (i === 0) {
            starts.setHours(a.starts.getHours());
            starts.setMinutes(a.starts.getMinutes());
          }
          if (i === countDays - 1) {
            ends.setHours(a.ends.getHours());
            ends.setMinutes(a.ends.getMinutes());
          }
          if (wt != null && wt.targetStart != null && wt.targetEnd != null) {
            var wholeDay = (wt.targetStart.getHours() > starts.getHours() || wt.targetStart.getHours() === starts.getHours() && wt.targetStart.getMinutes() >= starts.getMinutes()) && (ends.getHours() === 0 || wt.targetEnd.getHours() < ends.getHours() || wt.targetEnd.getHours() === ends.getHours() && wt.targetEnd.getMinutes() <= ends.getMinutes());
            if (wt.targetStart.getHours() > starts.getHours()) {
              starts.setHours(wt.targetStart.getHours());
              starts.setMinutes(wt.targetStart.getMinutes());
            }
            if (ends.getHours() === 0 || wt.targetEnd.getHours() < ends.getHours()) {
              ends.setHours(wt.targetEnd.getHours());
              ends.setMinutes(wt.targetEnd.getMinutes());
            }
            var diff = ends.getTime() - starts.getTime();
            if (wt.holidayTimeInMS < diff) {
              var absendeOvertimeReduction = false;
              try {
                var rTasks = ReadModels_1.ReadModels.get('task');
                var reason = yield rTasks.getById(a.taskId);
                absendeOvertimeReduction = reason.taskTypeId === 'overtimeReduction';
              } catch (e) {}
              if (wt.holidayTimeInMS !== 0) {
                starts.setMilliseconds(starts.getMilliseconds() + wt.holidayTimeInMS);
              }
              tts.push(new TimeTrackingEntity({
                starts: starts,
                ends: ends,
                pause: wholeDay && wt.targetPause ? WorkingTimeModelEntity_1.Daytime.fromDate(wt.targetPause) : undefined,
                absenceId: a.id,
                absence: a.createOne(Object.assign({}, a)),
                absendeOvertimeReduction: absendeOvertimeReduction,
                lastModifiedAt: a.lastModifiedAt,
                createdAt: a.createdAt,
                memberId: a.memberId,
                userId: a.userId,
                companyId: a.companyId,
                createdBy: a.createdBy,
                taskId: a.taskId,
                costCenterId: a.costCenterId,
                projectId: a.projectId,
                note: a.note,
                isBlocked: a.isBlocked,
                approvalProcessId: a.approvalProcessId,
                currentApprovalProcessStep: a.currentApprovalProcessStep,
                nextApprovalStepFor: a.nextApprovalStepFor
              }));
            }
          }
        }
        return tts;
      });
      function convertAbsenceToTimeTracking(_x3, _x4, _x5, _x6) {
        return _convertAbsenceToTimeTracking.apply(this, arguments);
      }
      return convertAbsenceToTimeTracking;
    }()
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.TimeTrackingEntity = TimeTrackingEntity;
TimeTrackingEntity.userIdMemberIdMapperCache = new Map();
TimeTrackingEntity.memberCache = new Map();