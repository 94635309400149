import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddProjectToCostUnit } from 'upmesh-core/src/client/commands/companies/costcenter/AddProjectToCostUnit';
import { DeleteCostCenter } from 'upmesh-core/src/client/commands/companies/costcenter/DeleteCostCenter';
import { RemoveProjectFromCostUnit } from 'upmesh-core/src/client/commands/companies/costcenter/RemoveProjectFromCostUnit';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddOrChangeCostCenterToCompanyDialog } from './AddOrChangeCostCenterToCompanyDialog';
const noCostCenterImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export function CompanyCostCenterView(props) {
    const [projectListItems, setProjectListItems] = useState([]);
    const [data, setData] = useState([]);
    const myRole = () => {
        return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
    };
    const updateAll = async () => {
        const costCenters = await UpmeshClient.instance.modals.costCenter.get({
            filter: `deleted ne true and companyId eq '${props.company.id}'`,
        });
        const data = [];
        const companyProjects = await UpmeshClient.instance.modals.companyProject.get();
        for (const costCenter of costCenters) {
            const projectTitles = [];
            const projects = [];
            costCenter.projectIds?.forEach((id) => {
                const c = companyProjects.find((p) => p.id === id);
                if (c != null && !c.deleted) {
                    projectTitles.push(c.title);
                    projects.push({ id: c.id, title: c.title });
                }
                else {
                    const title = c != null ? `${c.title} (${I18n.m.getMessage('deletedProject')})` : I18n.m.getMessage('deletedProject');
                    projectTitles.push(title);
                    projects.push({ id, title });
                }
            });
            data.push({
                ...costCenter,
                id: costCenter.id,
                projectTitles: projectTitles.join(', '),
                projects,
            });
        }
        const projectListItems = [];
        for (let i = 0; i < companyProjects.length; i += 1) {
            const project = companyProjects[i];
            projectListItems.push({
                title: `${project.title}`,
                data: project.id,
            });
        }
        setProjectListItems(projectListItems);
        setData(data);
    };
    const updateTimeOutRef = useRef();
    const updateWrapper = () => {
        if (updateTimeOutRef.current != null) {
            clearTimeout(updateTimeOutRef.current);
        }
        const tmp = setTimeout(() => {
            updateAll().catch((err) => {
                console.error('error on live update', err);
            });
        }, 300);
        updateTimeOutRef.current = tmp;
    };
    const openAddDialog = () => {
        Dialog.instance?.open({
            closeOnTouchOutside: false,
            content: <AddOrChangeCostCenterToCompanyDialog companyId={props.company.id}/>,
            contentPadding: false,
            showCloseIcon: true,
            scrollable: false,
        });
    };
    const openFab = () => {
        if (Fab.instance != null) {
            const permitted = myRole() === 'admin';
            Fab.instance.open({
                fabIcon: 'plus',
                fabIconOpen: 'close',
                small: false,
                fabColor: ThemeManager.style.brandPrimary,
                fabColorOpen: ThemeManager.style.brandSecondary,
                onPressFab: permitted
                    ? openAddDialog
                    : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
            });
        }
        else {
            window.setTimeout(openFab, 100);
        }
    };
    useEffect(() => {
        const attachKey = uuid.v4();
        UpmeshClient.eventDispatcher.attach({
            attachKey,
            readModelName: 'CostCenter',
            callback: updateWrapper,
        });
        updateAll().catch((err) => {
            console.error('could not get projects for cost center view', err);
        });
        openFab();
        return () => {
            UpmeshClient.eventDispatcher.detach('CostCenter', attachKey);
        };
    }, []);
    const tableMaxHeight = props.size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(60);
    const openEditDialog = (costCenter) => (_e) => {
        if (myRole() === 'admin') {
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddOrChangeCostCenterToCompanyDialog costCenter={costCenter} companyId={props.company.id}/>,
                contentPadding: false,
                showCloseIcon: true,
                scrollable: false,
            });
        }
        else {
            Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
        }
    };
    const removeCostCenterNow = (costCenter) => (_e) => {
        LoadingEvents.instance.startLoading();
        Alert.instance?.close(() => {
            const remove = new DeleteCostCenter({}, costCenter.id, CurrentUser.token);
            remove
                .execute(ClientStore.commandStore)
                .catch((e) => {
                console.error(e);
                Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
            })
                .finally(() => {
                LoadingEvents.instance.stopLoading();
            });
        });
    };
    const removeCostCenter = (costCenter) => (_e) => {
        Routing.instance.alert.post({
            text: I18n.m.getMessage('companyRemoveCostCenterQuestion'),
            buttons: [
                <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={removeCostCenterNow(costCenter)} backgroundColor={ThemeManager.style.brandDanger}/>,
            ],
        });
    };
    const onSaveCostCenterProjects = async (items, costCenter) => {
        try {
            const addToCostCenterPromises = [];
            items.forEach((p) => {
                const ind = costCenter.projectIds == null ? -1 : costCenter.projectIds.findIndex((a) => a === p.data);
                if (!p.selected && ind > -1) {
                    const c = new RemoveProjectFromCostUnit({ projectId: p.data }, costCenter.id);
                    addToCostCenterPromises.push(c.execute(ClientStore.commandStore));
                }
                else if (p.selected && ind === -1) {
                    const c = new AddProjectToCostUnit({ projectId: p.data }, costCenter.id);
                    addToCostCenterPromises.push(c.execute(ClientStore.commandStore));
                }
            });
            await Promise.all(addToCostCenterPromises);
        }
        catch (e) {
            DefaultErrorHandler.showDefaultErrorAlert(e);
        }
        Dialog.instance?.close();
    };
    const openChangeProjectsDialog = (item) => (_e) => {
        const list = [...projectListItems];
        for (const p of list) {
            p.selected = p.data != null && item.projectIds?.includes(p.data);
        }
        if (item.projectIds != null) {
            for (const p of item.projectIds) {
                const isInList = list.find((a) => a.data === p);
                if (!isInList) {
                    list.push({
                        title: I18n.m.getMessage('deletedProject'),
                        data: p,
                        selected: true,
                    });
                }
            }
        }
        Dialog.instance?.open({
            content: (<ChipDialogForm key="MembersDialogForm" title={I18n.m.getMessage('companyCostCenterChangeProjects')} items={list} onCancel={() => Dialog.instance?.close()} multiselect onSave={(e) => {
                    onSaveCostCenterProjects(e, item).catch((err) => console.error(err));
                }} canAddNewChips={false} searchBarPlaceholder={I18n.m.getMessage('search')}/>),
            contentPadding: false,
            fullscreenResponsive: true,
        });
    };
    return (<View style={{
            width: '100%',
            maxWidth: '100%',
            paddingTop: ThemeManager.style.contentPaddingValue,
            alignSelf: 'center',
            paddingHorizontal: 8,
        }}>
      <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
        <MaterialText centeredBox type={MaterialTextTypes.H6}>
          {I18n.m.getMessage('costCenters')}
        </MaterialText>
      </View>
      <Card style={{ width: '100%', height: tableMaxHeight }}>
        <Table tableName="CompanyCostCenter" actionItemsLength={3} actions={myRole() === 'admin'
            ? (_item) => {
                const buttons = [
                    {
                        icon: 'pencil-outline',
                        onAction: openEditDialog,
                        toolTip: I18n.m.getMessage('edit'),
                    },
                    {
                        icon: 'delete-outline',
                        onAction: removeCostCenter,
                        toolTip: I18n.m.getMessage('delete'),
                        disabled: _item.id === props.company.id,
                    },
                ];
                if (_item.costType === 'costUnit') {
                    buttons.unshift({
                        icon: 'factory',
                        onAction: openChangeProjectsDialog,
                        toolTip: I18n.m.getMessage('companyCostCenterChangeProjects'),
                    });
                }
                return buttons;
            }
            : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noCostCenterImg} emptyTableHint={I18n.m.getMessage('companyCostCenterNoDataHint')} emptyTableText={I18n.m.getMessage('companyCostCenterNoDataText')} data={data} sortBy="mark" sortDirection="asc" columns={[
            {
                title: I18n.m.getMessage('costCenterMark'),
                keyInData: 'mark',
                style: { width: 200 },
                sortable: true,
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('costCenterDescription'),
                keyInData: 'description',
                style: { width: 200 },
                sortable: true,
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('costCenterType'),
                keyInData: 'costType',
                style: { width: 200 },
                sortable: true,
                dataType: 'string',
                cellRenderer: (item, column, _index) => {
                    return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
                    <MaterialText>
                      {item.costType === 'costUnit' ? I18n.m.getMessage('yes') : I18n.m.getMessage('no')}
                    </MaterialText>
                  </View>);
                },
            },
            {
                title: I18n.m.getMessage('status'),
                keyInData: 'active',
                style: { width: 200 },
                sortable: true,
                dataType: 'string',
                cellRenderer: (item, column, _index) => {
                    return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
                    <MaterialText>
                      {item.active !== false ? I18n.m.getMessage('taskActive') : I18n.m.getMessage('taskInactive')}
                    </MaterialText>
                  </View>);
                },
            },
            {
                title: I18n.m.getMessage('projects'),
                keyInData: 'projectTitles',
                style: { width: 200 },
                sortable: true,
                dataType: 'string',
            },
        ]}/>
      </Card>
    </View>);
}
