import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { SUPPORTED_EXPORTS, } from 'upmesh-core/src/server/webserver/commands/timetracking/ExportInformation';
import { I18n } from '../../../../i18n/I18n';
export function AddisonOptions(props) {
    const [addisonCompanyNumber, setAddisonCompanyNumber] = useState('');
    useEffect(() => {
        const value = SimpleStorage.get(`timeTrackingOptions${SUPPORTED_EXPORTS.addison.short}`);
        if (value != null && value.length > 0) {
            const storedData = JSON.parse(value);
            setAddisonCompanyNumber(storedData.addisonCompanyNumber);
        }
    }, []);
    useEffect(() => {
        const data = SUPPORTED_EXPORTS.addison;
        data.additionalData.addisonCompanyNumber = addisonCompanyNumber;
        props.onChange(data);
    }, [addisonCompanyNumber]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            SimpleStorage.set(`timeTrackingOptions${SUPPORTED_EXPORTS.addison.short}`, JSON.stringify(SUPPORTED_EXPORTS.addison.additionalData));
        }, 250);
        return () => {
            clearTimeout(timeout);
        };
    }, [addisonCompanyNumber]);
    const addisonCmpnyRegex = /^\d{1,7}$/g;
    return (<View>
      <MaterialText>{I18n.m.getMessage('timeTrackingExportAddisonCompanyNumberNeeded')}</MaterialText>
      <FormInputFilled labelText={I18n.m.getMessage('timeTrackingExportAddisonCompanyNumber')} onChange={setAddisonCompanyNumber} numberOfLines={1} value={addisonCompanyNumber}/>
      {!addisonCmpnyRegex.test(addisonCompanyNumber) ? (<MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage('timeTrackingExportAddisonCompanyInvalid', { error: addisonCompanyNumber })}
        </MaterialText>) : null}
    </View>);
}
