import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateInputFormFilled';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { JournalEntity } from 'upmesh-core/src/client/query/entities/JournalEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
export class CopyJournalsDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.storedCopySettings = '';
        this.init = async () => {
            const storedSettings = SimpleStorage.get('journalCopySettings');
            const { journalSettings } = this.props;
            const { copyOptions } = this.state;
            if (journalSettings != null && journalSettings.additionalFields != null) {
                journalSettings.additionalFields.forEach((settingField) => {
                    copyOptions.push({ title: settingField.name, key: settingField.id, checked: false });
                });
            }
            if (storedSettings != null) {
                this.storedCopySettings = storedSettings;
                for (let i = 0; i < copyOptions.length; i += 1) {
                    if (storedSettings.indexOf(copyOptions[i].key) > -1) {
                        copyOptions[i].checked = true;
                    }
                }
                this.setState({ copyOptions });
            }
        };
        this.onChangeCheckedCopyOption = (value, index) => {
            const { copyOptions } = this.state;
            const options = copyOptions;
            options[index].checked = value;
            let storedSettings = '';
            storedSettings = this.storedCopySettings;
            if (value === false) {
                storedSettings += storedSettings.replace(copyOptions[index].key, '');
            }
            else {
                storedSettings += copyOptions[index].key;
            }
            SimpleStorage.set('journalCopySettings', storedSettings);
            this.setState({ copyOptions: options });
        };
        this.onChangeCopyDate = (date) => {
            let journalToBeCopiedFrom;
            const projectId = CurrentProject.instance.getCurrentProjectId();
            const asyncNow = async () => {
                if (date != null && projectId != null) {
                    const d = JournalEntity.getNormalizedDateShort(date);
                    const journals = await UpmeshClient.instance.modals.journal.get({
                        filter: `projectId eq '${projectId}' and forDay eq ${d}`,
                    }, CurrentUser.userId);
                    if (journals.length > 0)
                        journalToBeCopiedFrom = journals[0];
                }
                this.setState({ selectedDateToCopyFrom: date, journalToBeCopiedFrom });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.copyJournalSettingsNow = () => {
            const { onCopyJournal } = this.props;
            const { selectedDateToCopyFrom, copyOptions, journalToBeCopiedFrom } = this.state;
            if (selectedDateToCopyFrom != null && journalToBeCopiedFrom != null) {
                const current = new JournalEntity();
                if (copyOptions[0].checked === true && journalToBeCopiedFrom.employee)
                    current.employee = journalToBeCopiedFrom.employee;
                if (copyOptions[1].checked === true && journalToBeCopiedFrom.attendance)
                    current.attendance = journalToBeCopiedFrom.attendance;
                if (copyOptions[2].checked === true && journalToBeCopiedFrom.weather)
                    current.weather = journalToBeCopiedFrom.weather;
                if (copyOptions[3].checked === true && journalToBeCopiedFrom.workDone)
                    current.workDone = journalToBeCopiedFrom.workDone;
                if (copyOptions[4].checked === true && journalToBeCopiedFrom.specialOccurrences)
                    current.specialOccurrences = journalToBeCopiedFrom.specialOccurrences;
                if (copyOptions.length > 4 && journalToBeCopiedFrom.additionalFields != null) {
                    for (let i = 5; i < copyOptions.length; i += 1) {
                        if (copyOptions[i].checked === true) {
                            const found = journalToBeCopiedFrom.additionalFields.find((f) => f.id === copyOptions[i].key);
                            if (found != null && found.content != null && found.content.length > 0) {
                                const foundInCurrent = current.additionalFields == null
                                    ? -1
                                    : current.additionalFields.findIndex((f) => f.id === copyOptions[i].key);
                                if (current.additionalFields != null && foundInCurrent !== -1)
                                    current.additionalFields[foundInCurrent].content = found.content;
                                else if (current.additionalFields != null)
                                    current.additionalFields.push(found);
                                else
                                    current.additionalFields = [found];
                            }
                        }
                    }
                }
                if (onCopyJournal)
                    onCopyJournal(current);
            }
            Dialog.instance?.close();
        };
        this.state = {
            selectedDateToCopyFrom: undefined,
            copyOptions: [
                { title: I18n.m.getMessage('journalEmployee'), key: 'employee', checked: false },
                { title: I18n.m.getMessage('journalAttendances'), key: 'subcontractors', checked: false },
                { title: I18n.m.getMessage('journalWeather'), key: 'weather', checked: false },
                { title: I18n.m.getMessage('journalWorkDone'), key: 'work', checked: false },
                { title: I18n.m.getMessage('journalSpecialOccurrences'), key: 'special', checked: false },
            ],
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    render() {
        const { coloredDates, journalSettings } = this.props;
        const { journalToBeCopiedFrom, copyOptions, selectedDateToCopyFrom } = this.state;
        const buttons = [
            <ContainedButton title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={() => {
                    this.setState({
                        journalToBeCopiedFrom: undefined,
                    });
                    Dialog.instance?.close();
                }} key="abort"/>,
            <ContainedButton title={I18n.m.getMessage('journalImport')} onPress={this.copyJournalSettingsNow} disabled={journalToBeCopiedFrom == null} key={`import${!journalToBeCopiedFrom}`}/>,
        ];
        const listItems = [];
        if (journalToBeCopiedFrom && journalToBeCopiedFrom.employee != null && journalToBeCopiedFrom.employee.length > 0) {
            listItems.push(<CheckboxListItem title={copyOptions[0].title} key={`${copyOptions[0].key}_${selectedDateToCopyFrom}`} onChange={(value) => this.onChangeCheckedCopyOption(value, 0)} initValue={copyOptions[0].checked}/>);
        }
        if (journalToBeCopiedFrom &&
            journalToBeCopiedFrom.attendance != null &&
            journalToBeCopiedFrom.attendance.length > 0) {
            listItems.push(<CheckboxListItem title={copyOptions[1].title} key={`${copyOptions[1].key}_${selectedDateToCopyFrom}`} onChange={(value) => this.onChangeCheckedCopyOption(value, 1)} initValue={copyOptions[1].checked}/>);
        }
        if (journalToBeCopiedFrom && journalToBeCopiedFrom.weather != null && journalToBeCopiedFrom.weather.length > 0) {
            listItems.push(<CheckboxListItem title={copyOptions[2].title} key={`${copyOptions[2].key}_${selectedDateToCopyFrom}`} onChange={(value) => this.onChangeCheckedCopyOption(value, 2)} initValue={copyOptions[2].checked}/>);
        }
        if (journalToBeCopiedFrom && journalToBeCopiedFrom.workDone != null) {
            listItems.push(<CheckboxListItem title={copyOptions[3].title} key={`${copyOptions[3].key}_${selectedDateToCopyFrom}`} onChange={(value) => this.onChangeCheckedCopyOption(value, 3)} initValue={copyOptions[3].checked}/>);
        }
        if (journalToBeCopiedFrom && journalToBeCopiedFrom.specialOccurrences != null) {
            listItems.push(<CheckboxListItem title={copyOptions[4].title} key={`${copyOptions[4].key}_${selectedDateToCopyFrom}`} onChange={(value) => this.onChangeCheckedCopyOption(value, 4)} initValue={copyOptions[4].checked}/>);
        }
        if (journalToBeCopiedFrom &&
            journalToBeCopiedFrom.additionalFields != null &&
            journalToBeCopiedFrom.additionalFields.length > 0 &&
            journalSettings != null &&
            journalSettings.additionalFields != null) {
            const addFields = journalToBeCopiedFrom.additionalFields;
            journalSettings.additionalFields.forEach((settingField, i) => {
                const found = addFields.find((af) => af.id === settingField.id);
                if (found != null && found.content.length > 0) {
                    listItems.push(<CheckboxListItem title={settingField.name} key={`${settingField.id}_${selectedDateToCopyFrom}`} onChange={(value) => this.onChangeCheckedCopyOption(value, 5 + i)} initValue={copyOptions[5 + i].checked}/>);
                }
            });
        }
        return [
            <DialogTitle key="journalImportTitle">{I18n.m.getMessage('journalImportFromOthers')}</DialogTitle>,
            <DialogContent key="dateSelection">
        <DateInputFormFilled labelText={I18n.m.getMessage('date')} coloredDates={coloredDates} onChange={this.onChangeCopyDate} error={journalToBeCopiedFrom == null && selectedDateToCopyFrom != null} helperText={journalToBeCopiedFrom == null && selectedDateToCopyFrom != null
                    ? I18n.m.getMessage('journalDateHasNoJournal')
                    : undefined}/>
        {listItems}
      </DialogContent>,
            <DialogActions key="journalActions">{buttons}</DialogActions>,
        ];
    }
}
