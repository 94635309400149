"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubscriptionEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var SubscriptionEntity = function (_ReadModelEntity_1$Re) {
  function SubscriptionEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, SubscriptionEntity);
    _this = _callSuper(this, SubscriptionEntity, [obj]);
    _this.entityName = 'Subscription';
    _this.expired = false;
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(SubscriptionEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(SubscriptionEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new SubscriptionEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('userId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('expireDate', new OdataEntity_1.OdataIndex(true));
      baseMap.set('expired', new OdataEntity_1.OdataIndex(true));
      baseMap.set('contractId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('customerId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('licenses', new OdataEntity_1.OdataIndex(true));
      baseMap.set('partnerId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('testAccount', new OdataEntity_1.OdataIndex(true));
      baseMap.set('paymentIntervallInMonth', new OdataEntity_1.OdataIndex());
      baseMap.set('itemNumber', new OdataEntity_1.OdataIndex());
      baseMap.set('subscription', new OdataEntity_1.OdataIndex(true));
      baseMap.set('licenceType', new OdataEntity_1.OdataIndex(true));
      baseMap.set('note', new OdataEntity_1.OdataIndex());
      baseMap.set('startDate', new OdataEntity_1.OdataIndex(true));
      baseMap.set('paymentIntervall', new OdataEntity_1.OdataIndex(true));
      baseMap.set('locked', new OdataEntity_1.OdataIndex(true));
      baseMap.set('lockedReason', new OdataEntity_1.OdataIndex());
      baseMap.set('price', new OdataEntity_1.OdataIndex());
      baseMap.set('oneTimePrice', new OdataEntity_1.OdataIndex());
      baseMap.set('minimumTermInMonth', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.SubscriptionEntity = SubscriptionEntity;