"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExtraPayTracking = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var ExtraPayTrackingEntity_1 = require("./entities/ExtraPayTrackingEntity");
var TimeTrackingEntity_1 = require("./entities/TimeTrackingEntity");
var ExtraPayTracking = function (_ReadModelClientOffli) {
  function ExtraPayTracking() {
    var _this;
    (0, _classCallCheck2.default)(this, ExtraPayTracking);
    _this = _callSuper(this, ExtraPayTracking, arguments);
    _this.exampleEntity = new ExtraPayTrackingEntity_1.ExtraPayTrackingEntity();
    _this.onEvents = [{
      eventName: 'ExtraPayTrackingChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'ExtraPayTrackingCreated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'ExtraPayTrackingDeleted',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'ExtraPayTrackingStatusChanged',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(ExtraPayTracking, _ReadModelClientOffli);
  return (0, _createClass2.default)(ExtraPayTracking, [{
    key: "onExtraPayTrackingDeleted",
    value: function () {
      var _onExtraPayTrackingDeleted = (0, _asyncToGenerator2.default)(function* (e, ent) {
        ent.deleted = true;
        ent.lastModifiedAt = e.createdAt;
        return ent;
      });
      function onExtraPayTrackingDeleted(_x, _x2) {
        return _onExtraPayTrackingDeleted.apply(this, arguments);
      }
      return onExtraPayTrackingDeleted;
    }()
  }, {
    key: "onExtraPayTrackingCreated",
    value: function () {
      var _onExtraPayTrackingCreated = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = new ExtraPayTrackingEntity_1.ExtraPayTrackingEntity(yield this.defaultEventHandler(e, ent));
        entity.lastModifiedAt = e.createdAt;
        entity.createdBy = e.createdBy;
        try {
          if (e.data.projectId != null && e.data.projectId.length > 0) {
            var projects = ReadModels_1.ReadModels.get('project');
            var project = yield projects.getById(e.data.projectId);
            entity.projectTitle = project.title;
          }
        } catch (e) {}
        return entity;
      });
      function onExtraPayTrackingCreated(_x3, _x4) {
        return _onExtraPayTrackingCreated.apply(this, arguments);
      }
      return onExtraPayTrackingCreated;
    }()
  }, {
    key: "onExtraPayTrackingChanged",
    value: function () {
      var _onExtraPayTrackingChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = new ExtraPayTrackingEntity_1.ExtraPayTrackingEntity(yield this.defaultEventHandler(e, ent));
        if (entity == null) return undefined;
        try {
          if (e.data.projectId != null && e.data.projectId.length > 0) {
            var projects = ReadModels_1.ReadModels.get('project');
            var project = yield projects.getById(e.data.projectId);
            entity.projectTitle = project.title;
          } else if (entity.projectTitle != null && entity.projectTitle.length > 0 && (entity.projectId == null || entity.projectId.length === 0)) {
            entity.projectTitle = '';
          }
        } catch (e) {}
        entity.lastModifiedAt = e.createdAt;
        return entity;
      });
      function onExtraPayTrackingChanged(_x5, _x6) {
        return _onExtraPayTrackingChanged.apply(this, arguments);
      }
      return onExtraPayTrackingChanged;
    }()
  }, {
    key: "getSyncFilter",
    value: function () {
      var _getSyncFilter = (0, _asyncToGenerator2.default)(function* (lastModifiedAt, reSyncFilter) {
        var date = yield TimeTrackingEntity_1.TimeTrackingEntity.offlineDateLimit();
        if (lastModifiedAt == null) {
          if (this.localRepo) yield this.localRepo.deleteFilter(`day lt ${date.toISOString()}`);
          var all = new Date(-1);
          if (all.toISOString() === this.lastSyncFirstValue.toISOString()) {
            return `day ge ${date.toISOString()}`;
          }
          return `day ge ${date.toISOString()} and lastModifiedAt gt ${this.lastSyncFirstValue.toISOString()}`;
        }
        return reSyncFilter != null && reSyncFilter.length > 0 ? `${reSyncFilter} and day ge ${date.toISOString()} and lastModifiedAt gt ${lastModifiedAt.toISOString()}` : `day ge ${date.toISOString()} and lastModifiedAt gt ${lastModifiedAt.toISOString()}`;
      });
      function getSyncFilter(_x7, _x8) {
        return _getSyncFilter.apply(this, arguments);
      }
      return getSyncFilter;
    }()
  }]);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.ExtraPayTracking = ExtraPayTracking;