"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeProject = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var ProjectChanged_1 = require("../../events/project/ProjectChanged");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var CreateProject_1 = require("./CreateProject");
var ChangeProject = function (_ClientProjectCommand) {
  function ChangeProject(data, projectId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeProject);
    _this = _callSuper(this, ChangeProject, [data, projectId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeProject';
    return _this;
  }
  (0, _inherits2.default)(ChangeProject, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangeProject, [{
    key: "getProject",
    value: function () {
      var _getProject = (0, _asyncToGenerator2.default)(function* () {
        if (this._project == null) this._project = yield CommandReadModels_1.CommandReadModels.project.getById(this.entityId);
        return this._project;
      });
      function getProject() {
        return _getProject.apply(this, arguments);
      }
      return getProject;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var project = yield this.getProject();
        var title = this.data.title != null ? this.data.title : project.title;
        var c = new CreateProject_1.CreateProject(Object.assign({
          title: title
        }, this.data));
        yield c.validate(key);
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield _superPropGet(ChangeProject, "checkProjectStatusAndGeneralAccess", this, 3)([this.entityId, userId]);
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.entityId, userId, 'commandChangeProject')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _project$projectStart, _project$projectEnd, _project$construction, _project$construction2;
        var project = yield this.getProject();
        var title = this.data.title != null && this.data.title.length > 0 ? this.data.title : project.title;
        var data = {
          title: title
        };
        var hasChanged = false;
        if (this.data.projectStart !== ((_project$projectStart = project.projectStart) == null ? void 0 : _project$projectStart.toISOString())) {
          data.projectStart = this.data.projectStart;
          hasChanged = true;
        }
        if (this.data.projectEnd !== ((_project$projectEnd = project.projectEnd) == null ? void 0 : _project$projectEnd.toISOString())) {
          data.projectEnd = this.data.projectEnd;
          hasChanged = true;
        }
        if (this.data.constructionStart !== ((_project$construction = project.constructionStart) == null ? void 0 : _project$construction.toISOString())) {
          data.constructionStart = this.data.constructionStart;
          hasChanged = true;
        }
        if (this.data.constructionEnd !== ((_project$construction2 = project.constructionEnd) == null ? void 0 : _project$construction2.toISOString())) {
          data.constructionEnd = this.data.constructionEnd;
          hasChanged = true;
        }
        for (var k in ChangeProject.exampleData) {
          if (this.data[k] !== project[k] && (k !== 'title' || this.data[k] != null)) {
            hasChanged = true;
            data[k] = this.data[k];
          }
        }
        if (hasChanged) {
          data.title = title;
          return new ProjectChanged_1.ProjectChanged(this.entityId, data);
        }
        return [];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeProject = ChangeProject;
ChangeProject.exampleData = {
  title: 'string',
  projectType: 'string',
  description: 'string',
  projectStart: new Date(),
  projectEnd: new Date(),
  constructionStart: new Date(),
  constructionEnd: new Date(),
  color: 'string'
};