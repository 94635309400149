import { Card } from 'materialTheme/src/theme/components/Card';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextAsync } from 'materialTheme/src/theme/components/text/MaterialTextAsync';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { CombinedExtraPayAndTimeTrackingEntity } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { CombinedExtraPayTrackingDialogsControl } from './control/CombinedExtraPayTrackingDialogsControl';
import { openTimeTrackingDialog } from './dialogs/openTimeTrackingDialog';
import { CollaboratorThumb } from './elementcomponents/CollaboratorThumb';
import { PayrollMemberSelector } from './elementcomponents/PayrollMemberSelector';
import { PayrollPeriodSelector } from './elementcomponents/PayrollPeriodSelector';
export function PayrollViewMemberDetailsview(props) {
    const [data, setData] = useState();
    const [periodSelectorHeight, setPeriodSelectorHeight] = useState(0);
    const { maxHeight } = props;
    const loadData = async () => {
        if (CompanyUserInfo.company == null)
            return;
        const d = await UpmeshClient.instance.modals.payrollAccounting.get({
            filter: `periodId eq '${props.periodId}' and memberId eq '${props.memberId}'`,
        });
        const d2 = [];
        if (d && d.length > 0) {
            for (const p of d) {
                const en = await CombinedExtraPayAndTimeTrackingEntity.convertFromPayroll(p, CachedEntities.knownUsers, CachedEntities.knownTasks, CachedEntities.knownCompanyMember, CachedEntities.knownExtraPay);
                d2.push(en);
            }
        }
        setData(d2);
    };
    useEffect(() => {
        loadData().catch((err) => console.error(err));
    }, []);
    const onChangePeriod = (id) => {
        Routing.instance.goTo(`/timeTracking/payroll/${id}/${props.memberId}`, true);
    };
    const onChangeMember = (id) => {
        Routing.instance.goTo(`/timeTracking/payroll/${props.periodId}/${id}`, true);
    };
    const renderTypeCell = (item, column, index, sLayout) => {
        let text = '-';
        if (item.type != null) {
            text =
                item.type === 'TimeTrackingEntity'
                    ? I18n.m.getMessage('trackingTypeTime')
                    : I18n.m.getMessage('trackingTypeExtraPay');
        }
        return (<View key={`cellType-${item.id}-${index}`} style={[
                {
                    height: '100%',
                    minHeight: sLayout ? undefined : 54,
                    maxWidth: '100%',
                    alignSelf: sLayout ? 'flex-start' : 'flex-start',
                    justifyContent: sLayout ? 'flex-start' : 'center',
                    paddingHorizontal: 16,
                },
                column.style,
            ]}>
        {column.keyInData ? (<View style={{
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingRight: 0,
                }}>
            <MaterialText centeredBox>{text}</MaterialText>
          </View>) : null}
      </View>);
    };
    const renderTimeStartCell = (item, column, index, sLayout) => {
        const text = item.starts != null ? I18n.m.date().getLocalTimeString(new Date(item.starts)) : '';
        return (<View key={`cell-${item.id}-${index}`} style={[
                {
                    height: '100%',
                    minHeight: sLayout ? undefined : 54,
                    maxWidth: '100%',
                    alignSelf: sLayout ? 'flex-end' : 'flex-end',
                    justifyContent: sLayout ? 'flex-end' : 'center',
                    paddingHorizontal: 16,
                },
                column.style,
            ]}>
        {column.keyInData ? (<View style={{
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: 0,
                }}>
            <MaterialTextAsync asyncText={async () => {
                    try {
                        const isCollidating = item.type === 'TimeTrackingEntity'
                            ? await TimeTrackingEntity.isColliding(item.orgEntry)
                            : [];
                        return {
                            text,
                            color: isCollidating.length > 0 ? ThemeManager.style.brandDanger : undefined,
                            onPress: isCollidating.length > 0
                                ? () => CombinedExtraPayTrackingDialogsControl.showColliding(item.orgEntry, isCollidating)
                                : undefined,
                        };
                    }
                    catch (e) {
                        console.debug('cant get colliding entries', e);
                        return text;
                    }
                }} ellipsizeMode="tail" numberOfLines={sLayout ? undefined : 1} centeredBox/>
          </View>) : null}
      </View>);
    };
    const renderTimeEndsCell = (item, column, index, sLayout) => {
        let text = '-';
        if (item['ends'] != null) {
            text = I18n.m.date().getLocalTimeString(new Date(item['ends']));
        }
        return (<View key={`cell-${item.id}-${index}`} style={[
                {
                    height: '100%',
                    minHeight: sLayout ? undefined : 54,
                    maxWidth: '100%',
                    alignSelf: sLayout ? 'flex-end' : 'flex-end',
                    justifyContent: sLayout ? 'flex-end' : 'center',
                    paddingHorizontal: 16,
                },
                column.style,
            ]}>
        {column.keyInData ? (<View style={{
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: 0,
                }}>
            <MaterialTextAsync asyncText={async () => {
                    try {
                        const isCollidating = item.type === 'TimeTrackingEntity'
                            ? await TimeTrackingEntity.isColliding(item.orgEntry)
                            : [];
                        return {
                            text,
                            color: isCollidating.length > 0 ? ThemeManager.style.brandDanger : undefined,
                            onPress: isCollidating.length > 0
                                ? () => CombinedExtraPayTrackingDialogsControl.showColliding(item.orgEntry, isCollidating)
                                : undefined,
                        };
                    }
                    catch (e) {
                        console.debug('cant get colliding entries', e);
                        return text;
                    }
                }} ellipsizeMode="tail" numberOfLines={sLayout ? undefined : 1} centeredBox/>
          </View>) : null}
      </View>);
    };
    const columns = [
        {
            title: { icon: 'account-circle', toolTip: I18n.m.getMessage('userImage') },
            keyInData: 'id',
            style: { width: 64, minWidth: 64 },
            sortable: false,
            cellRenderer: (item, columnData, _index, sLayout) => (<CollaboratorThumb item={{ firstName: item.firstName, lastName: item.lastName, id: item.memberId, user: item.user }} columnData={columnData} sLayout={sLayout}/>),
            dataType: 'string',
            minWidth: 64,
        },
        {
            title: I18n.m.getMessage('firstName'),
            keyInData: 'firstName',
            style: { width: 150 },
            dataType: 'string',
            sortable: true,
        },
        {
            title: I18n.m.getMessage('lastName'),
            keyInData: 'lastName',
            style: { width: 150 },
            dataType: 'string',
            sortable: true,
        },
        {
            title: I18n.m.getMessage('staffNumber'),
            keyInData: 'staffNumber',
            style: { width: 150 },
            dataType: 'string',
            sortable: true,
        },
        {
            title: I18n.m.getMessage('trackingType'),
            keyInData: 'type',
            dataType: 'string',
            style: { width: 200 },
            cellRenderer: renderTypeCell,
        },
        { title: I18n.m.getMessage('date'), keyInData: 'day', dataType: 'Date', style: { width: 130 } },
        {
            title: {
                icon: 'clock-start',
                toolTip: I18n.m.getMessage('workingTimeModelsStart'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'startTimeInMS',
            dataType: 'number',
            style: { width: 90 },
            cellRenderer: renderTimeStartCell,
        },
        {
            title: {
                icon: 'clock-end',
                toolTip: I18n.m.getMessage('workingTimeModelsStop'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'ends',
            dataType: 'number',
            style: { width: 90 },
            cellRenderer: renderTimeEndsCell,
        },
        {
            title: {
                icon: 'coffee-outline',
                toolTip: I18n.m.getMessage('workingTimeModelsPause'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'pauseInMS',
            dataType: 'number',
            style: { width: 90 },
            format: (a) => TimeTrackingEntity.msToTime(a.pauseInMS, false, true),
        },
        {
            title: {
                icon: 'sigma',
                toolTip: I18n.m.getMessage('timeTrackingSum'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'sumInMS',
            dataType: 'number',
            style: { width: 90 },
            format: (a) => TimeTrackingEntity.msToTime(a.sumInMS, false, true),
        },
        {
            title: I18n.m.getMessage('task'),
            keyInData: 'taskName',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('extraPayFieldValue'),
            keyInData: 'extraFieldValue',
            style: { width: 150 },
            sortable: true,
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('extraPay'),
            keyInData: 'extraPayTitle',
            style: { width: 200 },
            sortable: true,
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('timeTrackingNotes'),
            keyInData: 'note',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('costCenter'),
            keyInData: 'costCenterName',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('project'),
            keyInData: 'projectTitle',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('origin'),
            keyInData: 'originId',
            style: { width: 150 },
            dataType: 'number',
            sortable: false,
            cellRenderer: (item, column, _index) => {
                const text = item.type === 'ExtraPayTrackingEntity'
                    ? I18n.m.getMessage('extraPayTracking')
                    : item.orgEntry['absenceId']
                        ? I18n.m.getMessage('absence')
                        : I18n.m.getMessage('timeTracking');
                return (<View key={`diff_${item.id}`} style={[{ justifyContent: 'center', paddingLeft: 16 }, column.style]}>
            <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
              <MaterialText onPress={openTimeTrackingDialog({ entry: item })}>{text}</MaterialText>
            </View>
          </View>);
            },
        },
    ];
    const renderTable = () => {
        return (<Table data={data} columns={columns} tableName="payrollAccountingMemberDetailsview" maxHeight={maxHeight - 32 - periodSelectorHeight} actionItemsLength={0} emptyTableText={I18n.m.getMessage('payrollNoDataText')}/>);
    };
    if (props.tableOnly)
        return renderTable();
    columns.push({
        title: I18n.m.getMessage('timeTrackingFilterStatusExported'),
        keyInData: 'exported',
        style: { width: 150 },
        dataType: 'object',
        sortable: true,
        cellRenderer: (item, column, _index) => {
            const icon = item.exported ? (<Icon icon="check" toolTip={I18n.m.getMessage('timeTrackingFilterStatusExported')}/>) : (<Icon icon="close" toolTip={I18n.m.getMessage('timeTrackingFilterStatusExported')}/>);
            return (<View key={`diff_${item.id}`} style={[{ justifyContent: 'center', paddingLeft: 16 }, column.style]}>
          <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>{icon}</View>
        </View>);
        },
    });
    return (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <View style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 16,
            alignItems: 'center',
        }} onLayout={(e) => setPeriodSelectorHeight(e.nativeEvent.layout.height)}>
        <Icon icon="arrow-left" outerSize={32} backgroundColor="#FFFFFF" toolTip="" onPress={() => Routing.instance.goTo(`/timeTracking/payroll/${props.periodId}`, true)}/>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <PayrollMemberSelector selectedId={props.memberId} onChange={onChangeMember} periodId={props.periodId}/>
          <PayrollPeriodSelector selectedId={props.periodId} onChange={onChangePeriod}/>
        </View>
      </View>
      <View style={{ width: '100%', maxWidth: '100%', paddingHorizontal: 8 }}>
        <Card outerPadding={8} style={{ height: maxHeight - 16 - periodSelectorHeight }}>
          {renderTable()}
        </Card>
      </View>
    </View>);
}
