import { Card } from 'materialTheme/src/theme/components/Card';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextAsync } from 'materialTheme/src/theme/components/text/MaterialTextAsync';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { View } from 'react-native';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { I18n } from '../../../i18n/I18n';
import { ApprovalStatusChip } from '../../companies/approvalprocess/ApprovalStatusChip';
import { CombinedExtraPayTrackingDialogsControl } from '../control/CombinedExtraPayTrackingDialogsControl';
import { TrackingMultiSelectControl } from '../control/TrackingMultiSelectControl';
import { openTimeTrackingDialog } from '../dialogs/openTimeTrackingDialog';
import { CollaboratorThumb } from '../elementcomponents/CollaboratorThumb';
const noTimeTrackings = require('../../../assets/img/noTimeTrackings.png');
export function TimeTrackingTable(props) {
    const [selectedIDs, setSelectedIDs] = useState(new Set());
    const renderStatusCell = (item, column, index, sLayout) => {
        const chip = (<ApprovalStatusChip currentStep={item} onType={item.type === 'ExtraPayTrackingEntity'
                ? 'extraPay'
                : item.orgEntry.absenceId != null
                    ? 'absence'
                    : 'timeTracking'} entityId={item.id}/>);
        return (<View key={`cell-${item.id}-${index}`} style={[
                {
                    height: '100%',
                    minHeight: sLayout ? undefined : 54,
                    maxWidth: '100%',
                    alignSelf: sLayout ? 'flex-end' : 'flex-start',
                    justifyContent: sLayout ? 'flex-end' : 'center',
                    paddingHorizontal: 16,
                },
                column.style,
            ]}>
        {column.keyInData ? chip : null}
      </View>);
    };
    const renderTextCell = (text, column, item, sLayout, index, color = ThemeManager.style.defaultTextColor) => {
        return (<View key={`cell-${item.id}-${index}`} style={[
                {
                    height: '100%',
                    minHeight: sLayout ? undefined : 54,
                    maxWidth: '100%',
                    alignSelf: sLayout ? 'flex-end' : 'flex-end',
                    justifyContent: sLayout ? 'flex-end' : 'center',
                    paddingHorizontal: 16,
                },
                column.style,
            ]}>
        {column.keyInData ? (<View style={{
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: 0,
                }}>
            <MaterialText color={color} centeredBox>
              {text}
            </MaterialText>
          </View>) : null}
      </View>);
    };
    const renderTimeEndsCell = (item, column, index, sLayout) => {
        let text = '-';
        if (item['ends'] != null) {
            text = I18n.m.date().getLocalTimeString(new Date(item['ends']));
        }
        return (<View key={`cell-${item.id}-${index}`} style={[
                {
                    height: '100%',
                    minHeight: sLayout ? undefined : 54,
                    maxWidth: '100%',
                    alignSelf: sLayout ? 'flex-end' : 'flex-end',
                    justifyContent: sLayout ? 'flex-end' : 'center',
                    paddingHorizontal: 16,
                },
                column.style,
            ]}>
        {column.keyInData ? (<View style={{
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: 0,
                }}>
            <MaterialTextAsync asyncText={async () => {
                    try {
                        const isCollidating = item.type === 'TimeTrackingEntity'
                            ? await TimeTrackingEntity.isColliding(item.orgEntry)
                            : [];
                        return {
                            text,
                            color: isCollidating.length > 0 ? ThemeManager.style.brandDanger : undefined,
                            onPress: isCollidating.length > 0
                                ? () => CombinedExtraPayTrackingDialogsControl.showColliding(item.orgEntry, isCollidating)
                                : undefined,
                        };
                    }
                    catch (e) {
                        console.debug('cant get colliding entries', e);
                        return text;
                    }
                }} ellipsizeMode="tail" numberOfLines={sLayout ? undefined : 1} centeredBox/>
          </View>) : null}
      </View>);
    };
    const renderTimePauseCell = (item, column, index, sLayout) => {
        let text = '-';
        if (item['pause'] != null) {
            text = `${item['pause'].hours}:${item['pause'].minutes > 9 ? item['pause'].minutes : `0${item['pause'].minutes}`}`;
        }
        return renderTextCell(text, column, item, sLayout, index);
    };
    const renderTypeCell = (item, column, index, sLayout) => {
        let text = '-';
        if (item.type != null) {
            text =
                item.type === 'TimeTrackingEntity' && !item.orgEntry['absenceId']
                    ? I18n.m.getMessage('trackingTypeTime')
                    : item.type === 'TimeTrackingEntity'
                        ? I18n.m.getMessage('trackingTypeAbsence')
                        : I18n.m.getMessage('trackingTypeExtraPay');
        }
        return (<View key={`cellType-${item.id}-${index}`} style={[
                {
                    height: '100%',
                    minHeight: sLayout ? undefined : 54,
                    maxWidth: '100%',
                    alignSelf: sLayout ? 'flex-start' : 'flex-start',
                    justifyContent: sLayout ? 'flex-start' : 'center',
                    paddingHorizontal: 16,
                },
                column.style,
            ]}>
        {column.keyInData ? (<View style={{
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingRight: 0,
                }}>
            <MaterialText centeredBox>{text}</MaterialText>
          </View>) : null}
      </View>);
    };
    const renderTimeStartCell = (item, column, index, sLayout) => {
        const text = item.starts != null ? I18n.m.date().getLocalTimeString(new Date(item.starts)) : '';
        return (<View key={`cell-${item.id}-${index}`} style={[
                {
                    height: '100%',
                    minHeight: sLayout ? undefined : 54,
                    maxWidth: '100%',
                    alignSelf: sLayout ? 'flex-end' : 'flex-end',
                    justifyContent: sLayout ? 'flex-end' : 'center',
                    paddingHorizontal: 16,
                },
                column.style,
            ]}>
        {column.keyInData ? (<View style={{
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: 0,
                }}>
            <MaterialTextAsync asyncText={async () => {
                    try {
                        const isCollidating = item.type === 'TimeTrackingEntity'
                            ? await TimeTrackingEntity.isColliding(item.orgEntry)
                            : [];
                        return {
                            text,
                            color: isCollidating.length > 0 ? ThemeManager.style.brandDanger : undefined,
                            onPress: isCollidating.length > 0
                                ? () => CombinedExtraPayTrackingDialogsControl.showColliding(item.orgEntry, isCollidating)
                                : undefined,
                        };
                    }
                    catch (e) {
                        console.debug('cant get colliding entries', e);
                        return text;
                    }
                }} ellipsizeMode="tail" numberOfLines={sLayout ? undefined : 1} centeredBox/>
          </View>) : null}
      </View>);
    };
    const renderTimeSumCell = (item, column, index, sLayout) => {
        let text = '-';
        let color = ThemeManager.style.defaultTextColor;
        if (item.sumInMS !== 0)
            text = TimeTrackingEntity.msToTime(item.sumInMS, false, true);
        if (item.sumInMS < 0)
            color = ThemeManager.style.brandDanger;
        return renderTextCell(text, column, item, sLayout, index, color);
    };
    const columns = [
        { title: I18n.m.getMessage('date'), keyInData: 'day', dataType: 'Date', style: { width: 130 } },
        {
            title: { icon: 'account-circle', toolTip: I18n.m.getMessage('userImage') },
            keyInData: 'memberName',
            style: { width: 64 },
            sortable: false,
            cellRenderer: (item, columnData, _index, sLayout) => (<CollaboratorThumb item={{ firstName: item.firstName, lastName: item.lastName, id: item.memberId, user: item.user }} columnData={columnData} sLayout={sLayout}/>),
            dataType: 'string',
            minWidth: 64,
        },
        {
            title: I18n.m.getMessage('employee'),
            keyInData: 'memberName',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('trackingType'),
            keyInData: 'type',
            dataType: 'string',
            style: { width: 200 },
            cellRenderer: renderTypeCell,
        },
        {
            title: I18n.m.getMessage('costCenter'),
            keyInData: 'costCenterName',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('project'),
            keyInData: 'projectTitle',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('timeTrackingRecorder'),
            keyInData: 'creatorName',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('task'),
            keyInData: 'taskNameFull',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('extraPayFieldValue'),
            keyInData: 'extraFieldValue',
            style: { width: 150 },
            sortable: true,
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('extraPay'),
            keyInData: 'extraPayTitle',
            style: { width: 200 },
            sortable: true,
            dataType: 'string',
        },
        {
            title: {
                icon: 'clock-start',
                toolTip: I18n.m.getMessage('workingTimeModelsStart'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'startTimeInMS',
            dataType: 'number',
            style: { width: 90 },
            cellRenderer: renderTimeStartCell,
        },
        {
            title: {
                icon: 'clock-end',
                toolTip: I18n.m.getMessage('workingTimeModelsStop'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'ends',
            dataType: 'number',
            style: { width: 90 },
            cellRenderer: renderTimeEndsCell,
        },
        {
            title: {
                icon: 'coffee-outline',
                toolTip: I18n.m.getMessage('workingTimeModelsPause'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'pauseInMS',
            dataType: 'number',
            style: { width: 90 },
            cellRenderer: renderTimePauseCell,
        },
        {
            title: {
                icon: 'sigma',
                toolTip: I18n.m.getMessage('timeTrackingSum'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'sumInMS',
            dataType: 'number',
            style: { width: 90 },
            cellRenderer: renderTimeSumCell,
        },
        {
            title: I18n.m.getMessage('timeTrackingStatus'),
            keyInData: 'status',
            dataType: 'string',
            style: { width: 132 },
            cellRenderer: renderStatusCell,
        },
        {
            title: I18n.m.getMessage('timeTrackingNotes'),
            keyInData: 'note',
            dataType: 'string',
            style: { width: 200 },
        },
    ];
    const openTimeTrackingEntry = (item, e) => {
        openTimeTrackingDialog({ entry: item })(e);
    };
    const onMultiSelect = (e) => {
        setSelectedIDs(e);
        if (e.size > 0) {
            TrackingMultiSelectControl.openMultiselectHeader(e, props.data, () => setSelectedIDs(new Set()));
        }
        else {
            TrackingMultiSelectControl.closeMultiselectHeader();
        }
    };
    const { maxHeight, data } = props;
    return (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ width: '100%', maxWidth: '100%', paddingHorizontal: 8 }}>
        <Card outerPadding={8} style={{ height: maxHeight }}>
          <Table onRowPress={openTimeTrackingEntry} tableName="TimeTrackingTable" maxHeight={maxHeight - 16} actionItemsLength={1} isSelectable={(item) => item.type === 'ExtraPayTrackingEntity' ||
            (item.type === 'TimeTrackingEntity' && !item.orgEntry['absenceId'])} data={data} sortDirection="desc" keyExtractor={(item) => `${item.id}_${item.day}_${item.orgEntry.id}_${item.orgEntry.lastModifiedAt}`} columns={columns} actions={(_item) => [
            { icon: 'dots-vertical', toolTip: '', onAction: CombinedExtraPayTrackingDialogsControl.openMenu },
        ]} emptyTableImage={noTimeTrackings} emptyTableHint={I18n.m.getMessage('timeTrackingNoDataHint')} emptyTableText={I18n.m.getMessage('timeTrackingNoDataText')} multiselectEnabled onMultiSelect={onMultiSelect} selectedIDs={selectedIDs}/>
        </Card>
      </View>
    </View>);
}
