import { Url } from 'cqrs-shared/src/uri/Url';
import { WaitFor } from 'cqrs-shared/src/WaitFor';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { Keyboard, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { ProjectUploadButton } from '../file/ProjectUploadButton';
import { CurrentProject } from '../project/CurrentProject';
export const PlanUploadPU = React.memo((_props) => {
    const [completedFiles, setCompletedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const title = I18n.m.getMessage('planUploadTitle');
    const getCurrentPlanUploads = async () => {
        await WaitFor.instance.waitFor(() => AuthClient.instance.ready);
        if (AuthClient.instance.ready) {
            await WaitFor.instance.waitFor(() => CurrentUser.userId != null);
            await WaitFor.instance.waitFor(() => CurrentProject.instance.getCurrentProjectId() != null);
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                const completedFiles = await UpmeshClient.instance.modals.planFiles.get({
                    filter: `projectId eq '${projectId}' and uploaded eq true and processed ne true`,
                    orderby: 'createdAt DESC',
                });
                setCompletedFiles([...completedFiles]);
                setIsLoading(false);
            }
            else {
                Routing.instance.closeDialog(true)(null);
            }
        }
    };
    const planfilesChanged = () => {
        getCurrentPlanUploads().catch((err) => console.debug(err));
    };
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'PlanFiles',
            attachKey: 'plansUpload',
            callback: planfilesChanged,
        });
        getCurrentPlanUploads().catch((err) => console.debug(err));
        return () => {
            UpmeshClient.eventDispatcher.detach('PlanFiles', 'plansUpload');
            if (Keyboard != null) {
                Keyboard.dismiss();
            }
        };
    }, []);
    const onUploading = (_uploading) => {
        getCurrentPlanUploads().catch((err) => console.debug(err));
    };
    const pressTempPlan = (e) => {
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (projectId != null) {
            Routing.instance.openDialog('tempPlans', { projectId }, true)(e);
        }
    };
    const renderSpinner = () => {
        return (<View style={{
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                minHeight: 242,
            }}>
        <Spinner />
      </View>);
    };
    const renderOfflineMessage = () => {
        return (<View style={{
                ...ThemeManager.style.contentPadding,
                paddingBottom: 4,
            }}>
        <MaterialText centeredText>{I18n.m.getMessage('commandOfflineNotPossible')}</MaterialText>
        <View style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <View>
            <ContainedButton onPress={Routing.instance.goBackButton} title={I18n.m.getMessage('ok')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          </View>
        </View>
      </View>);
    };
    const renderButtons = () => {
        return [
            <ContainedButton key="close" onPress={Routing.instance.closeDialog(true)} title={I18n.m.getMessage('close')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            <ContainedButton key={`save_${completedFiles.length === 0}`} disabled={completedFiles.length === 0} onPress={pressTempPlan} title={I18n.m.getMessage('next')} backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF"/>,
        ];
    };
    const projectId = CurrentProject.instance.getCurrentProjectId();
    if (isLoading || projectId == null) {
        return renderSpinner();
    }
    if (!AuthClient.instance.serverConnection.isConnected) {
        return renderOfflineMessage();
    }
    const uploaded = [];
    for (const f of completedFiles) {
        uploaded.push({ name: f.orgFileName, targetUrl: `${UpmeshClient.instance.url}/planfiles/upload/${f.id}` });
    }
    return (<View style={{ flex: 1 }}>
      <DialogTitle key="title">{title}</DialogTitle>
      <View key="content" style={{
            paddingTop: 0,
            padding: ThemeManager.style.contentPaddingValue,
            overflow: 'hidden',
            flex: 1,
        }}>
        <ProjectUploadButton endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/planfiles/upload/`)} uploadText={I18n.m.getMessage('planUploadTextButton')} uploadIcon="cloud-upload-outline" multiple id={`${projectId}_plan_upload`} filter={['pdf', 'images']} data={{ projectId }} uploaded={uploaded} onUploading={onUploading} maxImageSize={0} selfManageUploads/>
      </View>
      <DialogActions onBottom key="buttons">
        {renderButtons()}
      </DialogActions>
    </View>);
});
