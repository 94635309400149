"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationsSettingsEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var NotificationsSettingsEntity = function (_ReadModelEntity_1$Re) {
  function NotificationsSettingsEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, NotificationsSettingsEntity);
    _this = _callSuper(this, NotificationsSettingsEntity, [obj]);
    _this.entityName = 'NotificationsSettings';
    _this.addedPlanVersion = true;
    _this.planDetailChange = true;
    _this.ticketCreateChange = true;
    _this.allTickets = true;
    _this.filesCreatedOrChanged = true;
    _this.folderCreatedOrChanged = true;
    _this.ticketsCreator = true;
    _this.assigned = true;
    _this.watcher = true;
    _this.tester = true;
    _this.notifyNow = false;
    _this.dailyCompilation = false;
    _this.excludedProjectIds = [];
    _this.autoAddNewProjects = true;
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(NotificationsSettingsEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(NotificationsSettingsEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new NotificationsSettingsEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('userId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('addedPlanVersion', new OdataEntity_1.OdataIndex());
      baseMap.set('planDetailChange', new OdataEntity_1.OdataIndex());
      baseMap.set('ticketCreateChange', new OdataEntity_1.OdataIndex());
      baseMap.set('allTickets', new OdataEntity_1.OdataIndex());
      baseMap.set('filesCreatedOrChanged', new OdataEntity_1.OdataIndex());
      baseMap.set('folderCreatedOrChanged', new OdataEntity_1.OdataIndex());
      baseMap.set('ticketsCreator', new OdataEntity_1.OdataIndex());
      baseMap.set('assigned', new OdataEntity_1.OdataIndex());
      baseMap.set('watcher', new OdataEntity_1.OdataIndex());
      baseMap.set('tester', new OdataEntity_1.OdataIndex());
      baseMap.set('notifyNow', new OdataEntity_1.OdataIndex());
      baseMap.set('dailyCompilation', new OdataEntity_1.OdataIndex());
      baseMap.set('email', new OdataEntity_1.OdataIndex());
      baseMap.set('pushNotifications', new OdataEntity_1.OdataIndex());
      baseMap.set('excludedProjectIds', new OdataEntity_1.OdataIndex());
      baseMap.set('autoAddNewProjects', new OdataEntity_1.OdataIndex());
      baseMap.set('pendingApprovals', new OdataEntity_1.OdataIndex());
      baseMap.set('approvedPermits', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }, {
    key: "notifyEnabled",
    value: function notifyEnabled(projectId) {
      var index = this.excludedProjectIds.findIndex(function (current) {
        return current === projectId;
      });
      if (this.autoAddNewProjects) {
        return !(index >= 0);
      }
      return index >= 0;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.NotificationsSettingsEntity = NotificationsSettingsEntity;