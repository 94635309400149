import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeTimeTracking, } from 'upmesh-core/src/client/commands/companies/timetracking/ChangeTimeTracking';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
export class TimeTrackingStopDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onSave = () => {
            this.setState({ isLoading: true }, () => {
                this.saveAll()
                    .then(() => Dialog.instance?.close())
                    .catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                    this.setState({ isLoading: false });
                });
            });
        };
        this.onChange = (id) => (value) => {
            const { selectedIds } = this.state;
            const ind = selectedIds.findIndex((c) => c === id);
            if (!value && ind > -1) {
                selectedIds.splice(ind, 1);
            }
            else if (value && ind === -1)
                selectedIds.push(id);
            this.setState({ selectedIds: [...selectedIds] });
        };
        const selectedIds = [];
        props.entries.forEach((c) => selectedIds.push(c.id));
        this.state = { selectedIds, isLoading: false };
    }
    async saveAll() {
        const { selectedIds } = this.state;
        const promises = [];
        selectedIds.forEach((e) => {
            const data = {
                ends: new Date(),
                changeTime: new Date(),
            };
            const create = new ChangeTimeTracking(data, e);
            promises.push(create.execute(ClientStore.commandStore));
        });
        await Promise.all(promises);
    }
    render() {
        const { entries } = this.props;
        const { selectedIds, isLoading } = this.state;
        return [
            <DialogTitle key="stopTitle">{I18n.m.getMessage('stopTimeTrackingTitle')}</DialogTitle>,
            <DialogContent key="stopContent">
        {entries.map((e) => {
                    const v = selectedIds.includes(e.id);
                    return (<CheckboxListItem key={`${e.id}_${v.toString()}`} title={e.memberName} value={v} onChange={this.onChange(e.id)}/>);
                })}
      </DialogContent>,
            <DialogActions onBottom key="stopActions">
        <ContainedButton disabled={isLoading} title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton disabled={isLoading} title={I18n.m.getMessage('timeTrackingStopTimer')} onPress={this.onSave}/>
      </DialogActions>,
        ];
    }
}
TimeTrackingStopDialog.openDialog = (entries) => (e) => {
    let openPosition;
    if (e != null && e.nativeEvent != null && e.nativeEvent.pageX != null && e.nativeEvent.pageY != null) {
        openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    }
    Dialog.instance?.open({
        content: <TimeTrackingStopDialog entries={entries}/>,
        contentPadding: false,
        fullscreenResponsive: true,
        openPosition,
        scrollable: true,
    });
};
