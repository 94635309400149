import { Card } from 'materialTheme/src/theme/components/Card';
import { Datepicker } from 'materialTheme/src/theme/components/datepickerv2/Datepicker';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ChangePayrollAccountingPeriod } from 'upmesh-core/src/client/commands/companies/ChangePayrollAccountingPeriod';
import { CreatePayrollAccountingPeriod } from 'upmesh-core/src/client/commands/companies/CreatePayrollAccountingPeriod';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { PayrollAccountingPeriodStatusChip } from './elementcomponents/PayrollAccountingPeriodStatusChip';
import { openTimeTrackingExportDialog } from './exports/TimeTrackingExportDialog';
export function PayrollViewOverview(props) {
    const [data, setData] = useState();
    const [updateCounter, setUpdateCounter] = useState(0);
    const { maxHeight } = props;
    const loadData = async () => {
        if (CompanyUserInfo.company == null)
            return;
        const d = await UpmeshClient.instance.modals.payrollAccountingPeriods.get({
            filter: `companyId eq '${CompanyUserInfo.company.id}'`,
            orderby: 'starts DESC',
        });
        setData(d);
    };
    useEffect(() => {
        loadData().catch((err) => console.error(err));
    }, [updateCounter]);
    const createNextPeriod = (event) => {
        if (data == null || data.length === 0)
            return;
        Datepicker.open({
            labelText: 'Wähle wann der nächste Zeitraum enden soll',
            range: false,
            mode: 'date',
            minDate: new Date(data[0].ends.getFullYear(), data[0].ends.getMonth(), data[0].ends.getDate() + 1, 0, 0, 0, 0),
            selectedDate: new Date(data[0].ends.getFullYear(), data[0].ends.getMonth() + 1, data[0].ends.getDate(), 0, 0, 0, 0),
            onChange: (date) => {
                if (CompanyUserInfo.company == null)
                    return;
                const d = date['from'] ? null : date;
                if (d) {
                    LoadingEvents.instance.startLoading('', 1200000);
                    const ends = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1, 0, 0, 0, -1);
                    const c = new CreatePayrollAccountingPeriod({ ends, companyId: CompanyUserInfo.company.id });
                    c.execute(AuthClient.instance.commandStore)
                        .then(() => {
                        setUpdateCounter(updateCounter + 1);
                    })
                        .catch(DefaultErrorHandler.showDefaultErrorAlert)
                        .finally(() => {
                        LoadingEvents.instance.stopLoading();
                    });
                }
            },
        }, event);
    };
    const createNewPeriod = (event) => {
        if (data != null && data.length > 0)
            return;
        Datepicker.open({
            labelText: 'Wähle wann der nächste Zeitraum enden soll',
            range: true,
            mode: 'date',
            onChange: (date) => {
                if (CompanyUserInfo.company == null)
                    return;
                const d = date['from'] ? date : null;
                if (d != null && d.from) {
                    LoadingEvents.instance.startLoading('', 1200000);
                    const starts = new Date(d.from.getFullYear(), d.from.getMonth(), d.from.getDate(), 0, 0, 0, 0);
                    const ends = new Date(d.to.getFullYear(), d.to.getMonth(), d.to.getDate() + 1, 0, 0, 0, -1);
                    const c = new CreatePayrollAccountingPeriod({ starts, ends, companyId: CompanyUserInfo.company.id });
                    c.execute(AuthClient.instance.commandStore)
                        .then(() => {
                        setUpdateCounter(updateCounter + 1);
                    })
                        .catch(DefaultErrorHandler.showDefaultErrorAlert)
                        .finally(() => {
                        LoadingEvents.instance.stopLoading();
                    });
                }
            },
        }, event);
    };
    useEffect(() => {
        if (data != null)
            Fab.instance?.open({
                fabIcon: 'plus',
                fabIconOpen: 'close',
                small: false,
                fabColor: ThemeManager.style.brandPrimary,
                fabColorOpen: ThemeManager.style.brandSecondary,
                onPressFab: (event) => {
                    if (data != null && data.length > 0) {
                        createNextPeriod(event);
                    }
                    else {
                        createNewPeriod(event);
                    }
                },
            });
        return () => {
            Fab.instance?.close();
        };
    }, [data]);
    const columns = [
        {
            title: I18n.m.getMessage('payrollStarts'),
            keyInData: 'starts',
            style: { width: 150 },
            dataType: 'Date',
            sortable: false,
            onCellPress: (i) => {
                Routing.instance.goTo(`/timeTracking/payroll/${i.id}`);
            },
        },
        {
            title: I18n.m.getMessage('payrollEnds'),
            keyInData: 'ends',
            style: { width: 150 },
            dataType: 'Date',
            sortable: false,
            onCellPress: (i) => {
                Routing.instance.goTo(`/timeTracking/payroll/${i.id}`);
            },
        },
        {
            title: I18n.m.getMessage('payrollPeriodDurationInDays'),
            onCellPress: (i) => {
                Routing.instance.goTo(`/timeTracking/payroll/${i.id}`);
            },
            cellRenderer: (item, column, _index) => {
                const a = moment(item.ends);
                const b = moment(item.starts);
                const diff = a.diff(b, 'days') + 1;
                return (<View key={`diff_${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
            <MaterialText fixedWidth="100%" numberOfLines={1} onPress={() => {
                        Routing.instance.goTo(`/timeTracking/payroll/${item.id}`);
                    }}>
              {diff}
            </MaterialText>
          </View>);
            },
            sortable: false,
            keyInData: 'id',
            style: { width: 150 },
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('payrollUpToDate'),
            keyInData: 'lastUpdate',
            minWidth: 250,
            style: { width: 250, minWidth: 250 },
            cellRenderer: (item, column, _index) => {
                return (<View key={`diff_${item.id}_${item.lastUpdate}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
            <PayrollAccountingPeriodStatusChip key={`status_${item.id}_${item.lastUpdate}`} accounting={item} onUpdated={() => {
                        setUpdateCounter(updateCounter + 1);
                    }}/>
          </View>);
            },
            dataType: 'string',
            sortable: false,
        },
    ];
    const onAction = (action) => (item) => (event) => {
        if (action === 'edit') {
            Datepicker.open({
                range: true,
                mode: 'date',
                selectedDate: { from: item.starts, to: item.ends },
                onChange: (date) => {
                    const d = date['from'] ? date : null;
                    if (d && d.from && d.to) {
                        LoadingEvents.instance.startLoading('', 1200000);
                        const starts = new Date(d.from.getFullYear(), d.from.getMonth(), d.from.getDate(), 0, 0, 0, 0);
                        const ends = new Date(d.to.getFullYear(), d.to.getMonth(), d.to.getDate() + 1, 0, 0, 0, -1);
                        const c = new ChangePayrollAccountingPeriod({ starts, ends }, item.id);
                        c.execute(AuthClient.instance.commandStore)
                            .then(() => {
                            setUpdateCounter(updateCounter + 1);
                        })
                            .catch(DefaultErrorHandler.showDefaultErrorAlert)
                            .finally(() => {
                            LoadingEvents.instance.stopLoading();
                        });
                    }
                },
            }, event);
        }
        else if (action === 'export') {
            openTimeTrackingExportDialog({ periodId: item.id })(event);
        }
        else {
            console.log('onAction TODO // TODO', action, item, event);
        }
    };
    return (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ width: '100%', maxWidth: '100%', paddingHorizontal: 8 }}>
        <Card outerPadding={8} style={{ height: maxHeight - 16 }}>
          <Table data={data} columns={columns} tableName="payrollAccountingPeriodsTable" maxHeight={maxHeight - 16} actionItemsLength={3} actions={(_item) => [
            { onAction: onAction('export'), toolTip: I18n.m.getMessage('export'), icon: 'export' },
            { onAction: onAction('edit'), toolTip: I18n.m.getMessage('edit'), icon: 'pencil-outline' },
        ]} emptyTableText={I18n.m.getMessage('payrollNoDataText')} sortBy="starts" sortable={false} sortDirection="desc"/>
        </Card>
      </View>
    </View>);
}
