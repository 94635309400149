import Color from 'color';
import { PromisePool } from 'cqrs-shared/src/PromisePool';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Datepicker } from 'materialTheme/src/theme/components/datepickerv2/Datepicker';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { KeyboardEvents } from 'materialTheme/src/theme/components/utils/KeyboardEvents';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ScrollView, UIManager, View } from 'react-native';
import { EntriesPerUserForDay } from 'upmesh-core/src/client/query/entities/TimeTrackingCombinedEntities';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { Daytime } from 'upmesh-core/src/client/query/entities/WorkingTimeModelEntity';
import { CombinedExtraPayAndTimeTrackingEntity } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../../config/CachedEntities';
import { I18n } from '../../../i18n/I18n';
import { openAbsenceCreationChangeDialog } from '../../absences/AbsenceCreationChangeDialog';
import { CompanyUser } from '../../companies/member/CompanyUser';
import { openCompanyMemberDialog } from '../../companies/member/openCompanyMemberDialog';
import { AddOrChangeExtraPayTrackDialog } from '../../extrapay/AddOrChangeExtraPayTrackDialog';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
import { CombinedExtraPayTrackingDialogsControl } from '../control/CombinedExtraPayTrackingDialogsControl';
import { openTimeTrackingDialog } from './openTimeTrackingDialog';
import { TimeTrackingDayForUserDialogTimeTextBlock } from './TimeTrackingDayForUserDialogTimeTextBlock';
export function TimeTrackingDayForUserDialog(props) {
    const [current, setCurrent] = useState({
        memberId: props.memberId,
        day: props.day,
        userId: props.userId,
    });
    const connection = useContext(ConnectionContext);
    const [entry, setEntry] = useState();
    const [entryOnlineOnly, setEntryOnlineOnly] = useState(false);
    const [runningTime, setRunningTime] = useState(0);
    const [hoverId, setHoverId] = useState();
    const next = () => {
        const day = new Date(current.day.getFullYear(), current.day.getMonth(), current.day.getDate() + 1);
        setCurrent({ memberId: current.memberId, day, userId: current.userId });
    };
    const prev = () => {
        const day = new Date(current.day.getFullYear(), current.day.getMonth(), current.day.getDate() - 1);
        setCurrent({ memberId: current.memberId, day, userId: current.userId });
    };
    const nextUser = () => {
        if (CompanyUser.currentMemberList == null || CompanyUser.currentMemberList.length <= 1) {
            CompanyUser.currentMemberList = [...CompanyUserInfo.companyMember];
        }
        const index = CompanyUser.currentMemberList.findIndex((a) => a.id === current.memberId);
        let newIndex = index + 1;
        if (newIndex >= CompanyUser.currentMemberList.length)
            newIndex = 0;
        setCurrent({
            memberId: CompanyUser.currentMemberList[newIndex].id,
            day: current.day,
            userId: CompanyUser.currentMemberList[newIndex].userId,
        });
    };
    const prevUser = () => {
        if (CompanyUser.currentMemberList == null || CompanyUser.currentMemberList.length <= 1) {
            CompanyUser.currentMemberList = [...CompanyUserInfo.companyMember];
        }
        const index = CompanyUser.currentMemberList.findIndex((a) => a.id === current.memberId);
        let newIndex = index - 1;
        if (newIndex < 0)
            newIndex = CompanyUser.currentMemberList.length - 1;
        setCurrent({
            memberId: CompanyUser.currentMemberList[newIndex].id,
            day: current.day,
            userId: CompanyUser.currentMemberList[newIndex].userId,
        });
    };
    const runningInterval = useRef();
    useEffect(() => {
        KeyboardEvents.onKeyDown((e) => {
            switch (e.keyCode) {
                case 27:
                    e.preventDefault();
                    Dialog.instance?.close();
                    break;
                case 37:
                    e.preventDefault();
                    if (e.altKey)
                        prevUser();
                    else
                        prev();
                    break;
                case 39:
                    e.preventDefault();
                    if (e.altKey)
                        nextUser();
                    else
                        next();
                    break;
            }
        }, 'TimeTrackingDayForUser');
        return () => {
            KeyboardEvents.remove('TimeTrackingDayForUser');
        };
    }, [current.day, current.memberId]);
    useLayoutEffect(() => {
        setRunningTime(0);
        if (entry != null && entry.memberEntries != null) {
            const timeTrackingEntries = entry.memberEntries.filter((e) => e.type === 'TimeTrackingEntity');
            timeTrackingEntries.forEach((a) => {
                const o = a.orgEntry;
                if (o.ends == null && o.starts != null) {
                    const starts = new Date(o.starts);
                    const t = new Date();
                    if (starts != null)
                        setRunningTime(t.getTime() - starts.getTime());
                    runningInterval.current = setInterval(() => {
                        const t = new Date();
                        if (starts != null)
                            setRunningTime(t.getTime() - starts.getTime());
                    }, 10000);
                }
            });
        }
        return () => {
            if (runningInterval.current)
                clearInterval(runningInterval.current);
        };
    }, [entry]);
    const getTimeTrackingEntityForTable = async (e) => {
        return CombinedExtraPayAndTimeTrackingEntity.convert(e, CachedEntities.knownUsers, CachedEntities.knownTasks, CachedEntities.knownCompanyMember, CachedEntities.knownExtraPay, CachedEntities.knownCostCenter);
    };
    const updateEntries = async (memberId, date, userId) => {
        const currentPeriod = {
            from: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0),
            to: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, -1),
        };
        const models = await CombinedExtraPayTrackingDialogsControl.getOnlineOrOfflineModels(currentPeriod.from);
        setEntryOnlineOnly(models.onlineOnly);
        const { absence, timeTracking, extraPayTracking } = models;
        let timeTrackings = [];
        let extraPayTrackings = [];
        const collection = [];
        try {
            timeTrackings = await timeTracking.get({
                filter: `starts gt ${currentPeriod.from.toISOString()} and starts lt ${currentPeriod.to.toISOString()} and memberId eq '${memberId}' and deleted ne true`,
                orderby: 'memberId ASC, starts DESC',
            });
            collection.push(...timeTrackings);
            extraPayTrackings = await extraPayTracking.get({
                filter: `day gt ${currentPeriod.from.toISOString()} and day lt ${currentPeriod.to.toISOString()} and memberId eq '${memberId}' and deleted ne true`,
                orderby: 'memberId ASC, day DESC',
            });
            collection.push(...extraPayTrackings);
        }
        catch (err) {
            console.debug('cant get time trackings', err);
        }
        const absences = await absence.get({
            filter: `(starts gt ${currentPeriod.from.toISOString()} or ends gt ${currentPeriod.from.toISOString()}) and memberId eq '${memberId}' and deleted ne true`,
        });
        for (const a of absences) {
            const tts = await TimeTrackingEntity.convertAbsenceToTimeTracking(a, CombinedExtraPayAndTimeTrackingEntity.getWorkingTimeModelForMember, currentPeriod.from, currentPeriod.to);
            tts.forEach((t) => {
                if (t.starts.getTime() >= currentPeriod.from.getTime() && t.starts.getTime() < currentPeriod.to.getTime()) {
                    collection.push(t);
                }
            });
        }
        const a = await PromisePool.run({
            collection,
            maxConcurrency: 5,
            task: getTimeTrackingEntityForTable,
        });
        const b = await EntriesPerUserForDay.convert(a, currentPeriod.from, currentPeriod.to, userId ? [userId] : [memberId], CompanyUserInfo.companyMember, true);
        if (b != null && b.length > 0 && b[0].entries != null && b[0].entries.length > 0) {
            return b[0].entries[0];
        }
        let memberName = '?';
        let member = CompanyUserInfo.companyMember.find((a) => (a.userId == null ? a.id === userId : a.userId === userId));
        try {
            if (member == null)
                member = await UpmeshClient.instance.modals.companyMember.getById(memberId);
        }
        catch (e) {
            console.warn('cant find member', e);
        }
        if (member != null)
            memberName = `${member.firstName} ${member.lastName}`;
        return new EntriesPerUserForDay({ memberId, member, memberName, userId, date, memberEntries: [] });
    };
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: `TimeTrackingDay_${current.memberId}_${current.day.toISOString()}`,
            readModelName: 'TimeTracking',
            callback: (e) => {
                const today = new Date(current.day);
                const startDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0).getTime();
                const endDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0, 0).getTime();
                let shouldUpdate = false;
                e.entities.forEach((en) => {
                    if (en.entity && en.entity.starts)
                        en.entity.starts = new Date(en.entity.starts);
                    if (en.entity &&
                        en.entity.memberId &&
                        en.entity.memberId === current.memberId &&
                        en.entity.starts &&
                        en.entity.starts.getTime() >= startDay &&
                        en.entity.starts.getTime() <= endDay) {
                        shouldUpdate = true;
                    }
                });
                if (shouldUpdate) {
                    updateEntries(current.memberId, current.day, current.userId)
                        .then((e) => {
                        if (e)
                            setEntry(e);
                    })
                        .catch((err) => console.error(err));
                }
            },
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: `TimeTrackingDayExtra_${current.memberId}_${current.day.toISOString()}`,
            readModelName: 'ExtraPayTracking',
            callback: (e) => {
                const today = new Date(current.day);
                const startDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0).getTime();
                const endDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0, 0).getTime();
                let shouldUpdate = false;
                e.entities.forEach((en) => {
                    if (en.entity && en.entity.day)
                        en.entity.day = new Date(en.entity.day);
                    if (en.entity &&
                        en.entity.memberId &&
                        en.entity.memberId === current.memberId &&
                        en.entity.day &&
                        en.entity.day.getTime() >= startDay &&
                        en.entity.day.getTime() <= endDay) {
                        shouldUpdate = true;
                    }
                });
                if (shouldUpdate) {
                    updateEntries(current.memberId, current.day, current.userId)
                        .then((e) => {
                        if (e)
                            setEntry(e);
                    })
                        .catch((err) => console.error(err));
                }
            },
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: `TimeTrackingDayAbsence_${current.memberId}_${current.day.toISOString()}`,
            readModelName: 'Absence',
            callback: (e) => {
                let shouldUpdate = false;
                e.entities.forEach((en) => {
                    if (en.entity && en.entity.memberId && en.entity.memberId === current.memberId) {
                        shouldUpdate = true;
                    }
                });
                if (shouldUpdate) {
                    updateEntries(current.memberId, current.day, current.userId)
                        .then((e) => {
                        if (e)
                            setEntry(e);
                    })
                        .catch((err) => console.error(err));
                }
            },
        });
        updateEntries(current.memberId, current.day, current.userId)
            .then((e) => {
            if (e)
                setEntry(e);
        })
            .catch((err) => console.error(err));
        return () => {
            UpmeshClient.eventDispatcher.detach('TimeTracking', `TimeTrackingDay_${current.memberId}_${current.day.toISOString()}`);
            UpmeshClient.eventDispatcher.detach('ExtraPayTracking', `TimeTrackingDayExtra_${current.memberId}_${current.day.toISOString()}`);
            UpmeshClient.eventDispatcher.detach('Absence', `TimeTrackingDayAbsence_${current.memberId}_${current.day.toISOString()}`);
        };
    }, [current.day, current.memberId, connection.connectedToServer]);
    const renderDetailView = (data) => {
        const { line1_1, backgroundColor, iconOnPress, icon, line1_2, line2_1, line2_2, iconColor, iconToolTip, key, t, } = data;
        return (<View key={key} style={{
                width: '100%',
                padding: 0,
                ...ThemeManager.style.borderStyle,
                borderWidth: 0,
                borderBottomWidth: 1,
                paddingVertical: 8,
                height: 48,
                justifyContent: 'center',
                backgroundColor,
            }}>
        <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{
                width: 40,
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Icon icon={icon} toolTip={iconToolTip} color={iconColor} onPress={iconOnPress}/>
          </View>
          <Ripple onPress={t ? openTimeTrackingDialog({ entry: t }) : undefined} style={{ flex: 1, paddingLeft: 8, justifyContent: 'center' }} toolTip={data.toolTip} onMouseEnter={() => {
                if (t)
                    setHoverId(t.id);
            }} onMouseLeave={() => {
                if (t && hoverId === t.id)
                    setHoverId(undefined);
            }}>
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
              {line1_1 == null ? null : (<View style={{ flex: 1 }}>
                  <MaterialText numberOfLines={1} fixedWidth="100%">
                    {line1_1}
                  </MaterialText>
                </View>)}
              {line1_2 == null ? null : (<View style={{ width: 100 }}>
                  <MaterialText numberOfLines={1} fixedWidth="100%" strong textAlign="right">
                    {line1_2}
                  </MaterialText>
                </View>)}
            </View>
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ flex: 1 }}>
                <MaterialText numberOfLines={1} fixedWidth="100%" type={MaterialTextTypes.Body2}>
                  {line2_1}
                </MaterialText>
              </View>
              <View style={{ width: 160 }}>
                <MaterialText numberOfLines={1} fixedWidth="100%" type={MaterialTextTypes.Body2} textAlign="right">
                  {line2_2}
                </MaterialText>
              </View>
            </View>
          </Ripple>
          <View style={{ width: 48, alignItems: 'flex-end' }}>
            <Icon disabled={t == null} icon="dots-vertical" toolTip="" onPress={t != null ? CombinedExtraPayTrackingDialogsControl.openMenu(t) : undefined}/>
          </View>
        </View>
      </View>);
    };
    const renderDetail = (t, colliding) => {
        const duration = t.type === 'TimeTrackingEntity' ? t.sumInMS / 1000 / 60 : 64;
        let backgroundColor;
        if (hoverId === t.id)
            backgroundColor = '#F2F2F2';
        let line1_1 = t.costCenterName;
        if (t.projectTitle)
            line1_1 += ` - ${t.projectTitle}`;
        const line2_1 = t.taskNameFull != null && t.taskNameFull.length > 0
            ? t.taskNameFull
            : t.extraPayTitle != null
                ? t.extraPayTitle
                : '_';
        let { sumInMS } = t;
        if (t.orgEntry.ends == null) {
            sumInMS += runningTime;
        }
        const line1_2 = t.type === 'TimeTrackingEntity'
            ? TimeTrackingEntity.msToTime(sumInMS, false, true)
            : t.extraFieldValue != null
                ? t.extraFieldValue
                : ' ';
        const line2_2 = t.type === 'TimeTrackingEntity'
            ? `${I18n.m.date().getLocalTimeString(t.starts)} - ${I18n.m.date().getLocalTimeString(t.ends)}`
            : ' ';
        const icon = t.type === 'TimeTrackingEntity' ? 'clock-outline' : 'database-plus-outline';
        const iconColor = colliding ? ThemeManager.style.brandDanger : ThemeManager.style.defaultIconColor;
        const iconToolTip = colliding ? I18n.m.getMessage('timeTrackingChangeFoundCollision') : '';
        const toolTip = colliding ? I18n.m.getMessage('timeTrackingChangeFoundCollision') : '';
        const key = `track_${t.id}-${(hoverId === t.id).toString()}`;
        return renderDetailView({
            icon,
            iconColor,
            iconToolTip,
            toolTip,
            line2_2,
            line1_1,
            line2_1,
            line1_2,
            duration,
            key,
            backgroundColor,
            t,
        });
    };
    const openAddMenu = (e) => {
        const today = new Date();
        if (entry == null)
            return;
        const isToday = current.day.getFullYear() === today.getFullYear() &&
            current.day.getMonth() === today.getMonth() &&
            current.day.getDate() === today.getDate();
        const items = [];
        if (CompanyUserInfo.me?.payroll || CompanyUserInfo.me?.canTimeTrackFor !== 'nobody') {
            items.push({
                thumbnail: { thumbnail: <Icon icon="calendar-clock-outline" toolTip=""/>, width: 24 },
                onPress: (e) => {
                    Menu.instance?.close();
                    CombinedExtraPayTrackingDialogsControl.openAddTimeTrackingDialog({
                        memberIds: [current.memberId],
                        selectedDate: current.day,
                        start: isToday ? undefined : entry.targetStart,
                        end: isToday ? undefined : entry.targetEnd,
                        pause: isToday ? undefined : entry.targetPause,
                    })(e);
                },
                text: isToday
                    ? I18n.m.getMessage('timeTrackingCaptureTime')
                    : I18n.m.getMessage('timeTrackingCaptureTimeLater'),
            });
        }
        if (CompanyUserInfo.me?.payroll || CompanyUserInfo.me?.absenceFor !== 'nobody') {
            items.push({
                thumbnail: { thumbnail: <Icon icon="calendar-arrow-right" toolTip=""/>, width: 24 },
                onPress: (e) => {
                    Menu.instance?.close();
                    openAbsenceCreationChangeDialog({ memberIds: [entry.memberId] })(e);
                },
                text: I18n.m.getMessage('absenceDialogTitleAdd'),
            });
        }
        if (CompanyUserInfo.me?.payroll || CompanyUserInfo.me?.canExtraPayFor !== 'nobody') {
            items.push({
                thumbnail: { thumbnail: <Icon icon="database-plus-outline" toolTip=""/>, width: 24 },
                onPress: (_e) => {
                    Menu.instance?.close();
                    Dialog.instance?.open({
                        content: <AddOrChangeExtraPayTrackDialog selectedDate={current.day} memberIds={[entry.memberId]}/>,
                        fullscreenResponsive: true,
                        contentPadding: false,
                        scrollable: false,
                    });
                },
                text: I18n.m.getMessage('extraPayAddTrack'),
            });
        }
        UIManager.measure(e.nativeEvent.target, (_x, _y, width, height, pageX, pageY) => {
            const client = {
                height,
                width,
                x: pageX,
                y: pageY,
            };
            Menu.instance?.open({
                client,
                items,
            });
        });
    };
    const renderDetails = () => {
        const content = [];
        if (entry == null)
            return null;
        const timeTrackingEntries = entry.memberEntries.filter((e) => e.type === 'TimeTrackingEntity');
        if (timeTrackingEntries.length === 0) {
            if (entry.targetTimeInMS && entry.targetTimeInMS > 0) {
                content.push(<View style={{ width: '100%', paddingVertical: 8 }} key="missing">
            <MaterialText strong color={ThemeManager.style.brandDanger} numberOfLines={1} fixedWidth="100%" textAlign="center" type={MaterialTextTypes.Body2}>
              {I18n.m.getMessage('noTimeTrackingRecordsForDay')}
            </MaterialText>
          </View>);
            }
            else {
                const today = new Date();
                if (today.getTime() < entry.date.getTime()) {
                    content.push(<View style={{ width: '100%', paddingVertical: 8 }} key="freeDay">
              <MaterialText strong color={ThemeManager.style.brandSuccess} numberOfLines={1} fixedWidth="100%" textAlign="center" type={MaterialTextTypes.Body2}>
                {I18n.m.getMessage('noTimeTrackingRecordsForDayNeededForFuture')}
              </MaterialText>
            </View>);
                }
                else {
                    content.push(<View style={{ width: '100%', paddingVertical: 8 }} key="freeDay">
              <MaterialText strong color={ThemeManager.style.brandSuccess} numberOfLines={1} fixedWidth="100%" textAlign="center" type={MaterialTextTypes.Body2}>
                {I18n.m.getMessage('noTimeTrackingRecordsForDayNeeded')}
              </MaterialText>
            </View>);
                }
            }
        }
        const entries = timeTrackingEntries.map((a) => a.orgEntry);
        entry.memberEntries.forEach((next, index) => {
            let backgroundColor = '#F2F2F2';
            if (hoverId === next.id)
                backgroundColor = Color(backgroundColor).lighten(0.75).toString();
            if (index && next.type === 'TimeTrackingEntity') {
                const last = entry.memberEntries[index - 1];
                if (last.type === 'TimeTrackingEntity' && last.starts != null && next.ends != null) {
                    const pause = (last.starts.getTime() - next.ends.getTime()) / 1000 / 60;
                    if (pause > 1) {
                        const line2_2 = `${I18n.m.date().getLocalTimeString(next.ends)} - ${I18n.m
                            .date()
                            .getLocalTimeString(last.starts)}`;
                        content.push(renderDetailView({
                            icon: 'pause-circle-outline',
                            duration: pause,
                            line2_1: I18n.m.getMessage('pause'),
                            line2_2,
                            iconColor: ThemeManager.style.defaultIconColor,
                            iconToolTip: '',
                            backgroundColor,
                            key: `pause_${next.id}`,
                        }));
                    }
                }
            }
            try {
                const hasConfilct = next.type !== 'TimeTrackingEntity'
                    ? []
                    : TimeTrackingEntity.isCollidingWithList(next.orgEntry, entries);
                content.push(renderDetail(next, hasConfilct.length > 0));
            }
            catch (e) {
                console.warn('cant check confilct', e);
            }
        });
        return (<View style={{ width: '100%', padding: 8 }}>
        {content}
        <View style={{ width: '100%', paddingVertical: 8 }}>
          <ContainedButton title={I18n.m.getMessage('add')} icon={{ icon: 'plus', toolTip: '' }} onPress={openAddMenu} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} outlineColor={ThemeManager.style.brandPrimary} borderStyle="solid" full/>
        </View>
      </View>);
    };
    const renderEntries = () => {
        if (!connection.connectedToServer && entryOnlineOnly)
            return (<View style={{ width: '100%', paddingVertical: 8 }}>
          <MaterialText>{I18n.m.getMessage('offlineNotAvailable')}</MaterialText>
        </View>);
        if (entry == null)
            return null;
        const start = new Date(entry.date.getFullYear(), entry.date.getMonth(), entry.date.getDate(), 0, 0, 0, 0);
        const end = new Date(entry.date.getFullYear(), entry.date.getMonth(), entry.date.getDate() + 1, 0, 0, 0, -1);
        const hours = Math.min(Math.ceil((end.getTime() - start.getTime()) / 1000 / 60 / 60), 47);
        const background = [];
        const blocks = [];
        const sView = props.dialogState != null && props.dialogState.windowWidth <= ThemeManager.style.breakpointM;
        const height = props.dialogState && sView && props.dialogState.windowHeight > 0
            ? Math.max(24, Math.floor((props.dialogState.windowHeight - 176) / hours))
            : 24;
        const leftWidth = sView ? 0 : 192;
        for (let i = 0; i < hours; i += 1) {
            background.push(<View key={`bg_${i}`} style={{
                    width: leftWidth,
                    height,
                    ...ThemeManager.style.borderStyle,
                    borderWidth: 0,
                    borderTopWidth: 1,
                }}>
          <MaterialText>{i}</MaterialText>
        </View>);
        }
        if (entry.targetStart != null && entry.targetEnd != null) {
            const hStart = entry.targetStart.getHours() + entry.targetStart.getMinutes() / 60;
            const hEnd = entry.targetEnd.getHours() + entry.targetEnd.getMinutes() / 60;
            const blockWidth = 10;
            const left = 30;
            const backgroundColor = ThemeManager.style.brandPrimary;
            if (entry.targetPause != null) {
                const pause = Daytime.fromDate(entry.targetPause);
                const pauseHeight = (pause.hours + pause.minutes / 60) * height;
                const bHeight = (hEnd - hStart) * height;
                blocks.push(<Ripple key={`targetwithpause_${entry.id}_${entry.memberId}_${hStart * height}_${left}`} toolTip={I18n.m.getMessage('timeTrackingExportExcelTargetTime')} style={{
                        width: blockWidth,
                        left,
                        position: 'absolute',
                        top: hStart * height,
                        height: bHeight,
                        backgroundColor: 'transparent',
                    }}>
            <View style={{ backgroundColor, width: '100%', height: (bHeight - pauseHeight) / 2 }}/>
            <View style={{ backgroundColor: 'transparent', width: '100%', height: pauseHeight }}/>
            <View style={{ backgroundColor, width: '100%', height: (bHeight - pauseHeight) / 2 }}/>
          </Ripple>);
            }
            else {
                blocks.push(<Ripple key={`target_${entry.id}_${entry.memberId}_${hStart * height}_${left}`} toolTip={I18n.m.getMessage('timeTrackingExportExcelTargetTime')} style={{
                        width: blockWidth,
                        left,
                        position: 'absolute',
                        top: hStart * height,
                        height: (hEnd - hStart) * height,
                        backgroundColor,
                    }}/>);
            }
        }
        const timeTrackingEntries = entry.memberEntries.filter((e) => e.type === 'TimeTrackingEntity');
        const entries = timeTrackingEntries.map((a) => a.orgEntry);
        timeTrackingEntries.forEach((a, index) => {
            if (a.isBlocked === 'rejected')
                return;
            let { sumInMS } = a;
            if (a.orgEntry.ends == null) {
                sumInMS += runningTime;
            }
            const ends = a.ends != null ? new Date(a.ends) : new Date();
            if (a.starts != null && ends != null) {
                const hStart = a.starts.getHours() + a.starts.getMinutes() / 60;
                const hEnd = ends.getHours() + ends.getMinutes() / 60;
                let left = 44;
                let blockWidth = leftWidth - left - 4;
                let backgroundColor = ThemeManager.style.brandSuccess;
                let borderColor = ThemeManager.style.brandSuccess;
                if (a.orgEntry['absenceId']) {
                    backgroundColor = ThemeManager.style.brandDanger;
                    borderColor = ThemeManager.style.brandDanger;
                }
                backgroundColor = Color(backgroundColor).alpha(0.6).toString();
                if (a.isBlocked === 'open') {
                    backgroundColor = Color(backgroundColor).alpha(0).toString();
                }
                const hasConfilct = TimeTrackingEntity.isCollidingWithList(a.orgEntry, entries);
                if (hasConfilct.length > 0) {
                    left += 8 * index;
                    blockWidth -= 16 * index;
                    backgroundColor = ThemeManager.style.brandWarning;
                    backgroundColor = Color(backgroundColor).alpha(0.3).toString();
                }
                if (hoverId === a.id)
                    backgroundColor = Color(backgroundColor).alpha(1).toString();
                if (!a.orgEntry['absenceId'] && a.pause != null && (a.pause.hours || a.pause.minutes)) {
                    const pauseHeight = (a.pause.hours + a.pause.minutes / 60) * height;
                    const halfSum = a.sumInMS / 2;
                    const bHeight = (hEnd - hStart) * height;
                    blocks.push(<Ripple key={`${a.id}-${(hoverId === a.id).toString()}`} onMouseEnter={() => {
                            setHoverId(a.id);
                        }} onMouseLeave={() => {
                            if (hoverId === a.id)
                                setHoverId(undefined);
                        }} onPress={openTimeTrackingDialog({ entry: a })} toolTip={a.orgEntry['absenceId'] ? I18n.m.getMessage('absence') : I18n.m.getMessage('timeTracking')} style={{
                            width: blockWidth,
                            left,
                            position: 'absolute',
                            top: hStart * height,
                            height: bHeight,
                            backgroundColor: 'transparent',
                        }}>
              <View style={{
                            backgroundColor,
                            borderRadius: ThemeManager.style.borderRadius,
                            borderWidth: 1,
                            borderColor,
                            padding: 4,
                            width: '100%',
                            height: (bHeight - pauseHeight) / 2,
                        }}>
                <TimeTrackingDayForUserDialogTimeTextBlock sumInMS={halfSum} taskNameFull={a.taskNameFull} costCenterName={a.costCenterName}/>
              </View>
              <View style={{ backgroundColor: 'transparent', width: '100%', height: pauseHeight }}>
                
                
                
                
              </View>
              <View style={{
                            backgroundColor,
                            borderRadius: ThemeManager.style.borderRadius,
                            borderWidth: 1,
                            borderColor,
                            padding: 4,
                            width: '100%',
                            height: (bHeight - pauseHeight) / 2,
                        }}>
                <TimeTrackingDayForUserDialogTimeTextBlock sumInMS={halfSum} taskNameFull={a.taskNameFull} costCenterName={a.costCenterName}/>
              </View>
            </Ripple>);
                }
                else {
                    blocks.push(<Ripple key={`${a.id}-${(hoverId === a.id).toString()}`} onMouseEnter={() => {
                            setHoverId(a.id);
                        }} onMouseLeave={() => {
                            if (hoverId === a.id)
                                setHoverId(undefined);
                        }} onPress={openTimeTrackingDialog({ entry: a })} toolTip={a.orgEntry['absenceId'] ? I18n.m.getMessage('absence') : I18n.m.getMessage('timeTracking')} style={{
                            width: blockWidth,
                            left,
                            position: 'absolute',
                            top: hStart * height,
                            height: (hEnd - hStart) * height,
                            backgroundColor,
                            borderRadius: ThemeManager.style.borderRadius,
                            borderWidth: 1,
                            borderColor,
                            padding: 4,
                        }}>
              <TimeTrackingDayForUserDialogTimeTextBlock sumInMS={sumInMS} taskNameFull={a.taskNameFull} costCenterName={a.costCenterName}/>
            </Ripple>);
                }
            }
        });
        return (<View style={{
                width: '100%',
                position: 'relative',
                height: height * hours,
            }}>
        <View style={{ width: leftWidth }}>
          {background}
          {blocks}
        </View>
        <ScrollView style={{
                position: 'absolute',
                top: 0,
                left: leftWidth,
                right: 0,
                borderRadius: ThemeManager.style.borderRadius,
                backgroundColor: '#fafafa',
                minHeight: hours * height,
            }}>
          {renderDetails()}
        </ScrollView>
      </View>);
    };
    const renderHeader = () => {
        if (entry == null)
            return null;
        let diffString = '';
        let diffColor = ThemeManager.style.defaultTextColor;
        let tt = entry.totalTime;
        let diff = entry.diffTimeInMS != null ? entry.diffTimeInMS : 0;
        if (runningTime) {
            tt += runningTime;
            diff += runningTime;
        }
        const timeTextLine = `${TimeTrackingEntity.msToTime(tt, false, true)} ${I18n.m.getMessage('unitForHours')} / ${TimeTrackingEntity.msToTime(entry.targetTimeInMS, false, true)} ${I18n.m.getMessage('unitForHours')}`;
        if (diff != null && diff !== 0) {
            if (diff < 0)
                diffColor = ThemeManager.style.brandDanger;
            else if (diff > 0)
                diffColor = ThemeManager.style.brandSuccess;
            diffString = `${TimeTrackingEntity.msToTime(diff, false, true)} ${I18n.m.getMessage('unitForHours')}`;
        }
        return (<View style={{ width: '100%', paddingBottom: 16 }}>
        <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
          <Icon toolTip={`${I18n.m.getMessage('prev')} (${I18n.m.getMessage('hotkeyAltLeft')})`} icon="chevron-left" onPress={prevUser}/>
          <Icon toolTip={`${I18n.m.getMessage('next')} (${I18n.m.getMessage('hotkeyAltRight')})`} icon="chevron-right" onPress={nextUser}/>
          <View style={{ width: 72 }}>{entry.user != null && <UserImage lightBox size={56} user={entry.user}/>}</View>
          <Ripple style={{ flex: 1 }} onPress={(e) => {
                Dialog.instance?.close(() => {
                    openCompanyMemberDialog({ memberId: entry.memberId, currentList: [entry.member] })(e);
                });
            }}>
            {entry.memberName != null && entry.memberName.length > 0 && (<MaterialText strong>{entry.memberName}</MaterialText>)}
            <MaterialText>{timeTextLine}</MaterialText>
            <MaterialText color={diffColor} type={MaterialTextTypes.Body2}>
              {diffString}
            </MaterialText>
          </Ripple>
        </View>
      </View>);
    };
    return (<>
      <DialogTitle onPressTitle={() => {
            Datepicker.open({
                selectedDate: current.day,
                mode: 'date',
                onChange: (d) => {
                    if (d != null)
                        setCurrent({ day: new Date(d), memberId: current.memberId, userId: current.userId });
                },
            });
        }} leftIcons={[
            {
                toolTip: `${I18n.m.getMessage('prev')} (${I18n.m.getMessage('hotkeyLeft')})`,
                icon: 'chevron-left',
                onPress: prev,
            },
            {
                toolTip: `${I18n.m.getMessage('next')} (${I18n.m.getMessage('hotkeyRight')})`,
                icon: 'chevron-right',
                onPress: next,
            },
        ]} iconRight={{ toolTip: I18n.m.getMessage('close'), icon: 'close', onPress: () => Dialog.instance?.close() }}>
        {`${I18n.m.dateCurrent.weekdays()[current.day.getDay()]}, ${I18n.m.dateCurrent.localeDateString(current.day)}`}
      </DialogTitle>
      <DialogContent>
        {renderHeader()}
        {renderEntries()}
      </DialogContent>
    </>);
}
