import { Card } from 'materialTheme/src/theme/components/Card';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialTextEditableNumber } from 'materialTheme/src/theme/components/text/MaterialTextEditableNumber';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ChangeMembersUnpaidOvertime } from 'upmesh-core/src/client/commands/companies/ChangeMembersUnpaidOvertime';
import { PayrollAccountingPeriodsPerMemberEntity, } from 'upmesh-core/src/client/query/entities/PayrollAccountingPeriodsPerMemberEntity';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { openTrackingProblemsDialog } from './dialogs/TrackingProblemsDialog';
import { CollaboratorThumb } from './elementcomponents/CollaboratorThumb';
import { PayrollAccountingPeriodStatusChip } from './elementcomponents/PayrollAccountingPeriodStatusChip';
import { PayrollPeriodSelector } from './elementcomponents/PayrollPeriodSelector';
export function PayrollViewMemberview(props) {
    const [data, setData] = useState();
    const [periodSelectorHeight, setPeriodSelectorHeight] = useState(0);
    const [period, setPeriod] = useState();
    const [updateCounter, setUpdateCounter] = useState(0);
    const { maxHeight } = props;
    const loadData = async () => {
        if (CompanyUserInfo.company == null)
            return;
        const p = await UpmeshClient.instance.modals.payrollAccountingPeriods.getById(props.periodId);
        setPeriod(p);
        const d = await UpmeshClient.instance.modals.payrollAccountingPeriodsPerMember.get({
            filter: `periodId eq '${props.periodId}'`,
        });
        const d2 = [];
        if (d && d.length > 0) {
            for (const p of d) {
                const e = await PayrollAccountingPeriodsPerMemberEntity.convertToPayrollAccountingPeriodsPerMemberDetails(p);
                const memberName = CachedEntities.knownCompanyMember.get(e.memberId);
                const en = e;
                en.memberFirstName = memberName && memberName.firstName ? memberName.firstName : '?';
                en.memberLastName = memberName && memberName.lastName ? memberName.lastName : '?';
                en.staffNumber = memberName && memberName.staffNumber ? memberName.staffNumber : '';
                en.user = memberName && memberName.userId ? CachedEntities.knownUsers.get(memberName.userId) : undefined;
                d2.push(en);
            }
        }
        setData(d2);
    };
    useEffect(() => {
        loadData().catch((err) => console.error(err));
    }, [updateCounter]);
    const onChangePeriod = (id) => {
        Routing.instance.goTo(`/timeTracking/payroll/${id}`, true);
    };
    const onMemberCellPress = (item) => {
        Routing.instance.goTo(`/timeTracking/payroll/${item.periodId}/${item.memberId}`);
    };
    const columns = [
        {
            title: { icon: 'account-circle', toolTip: I18n.m.getMessage('userImage') },
            keyInData: 'memberFirstName',
            style: { width: 64, minWidth: 64 },
            sortable: false,
            cellRenderer: (item, columnData, _index, sLayout) => (<CollaboratorThumb item={{ firstName: item.memberFirstName, lastName: item.memberLastName, id: item.memberId, user: item.user }} columnData={columnData} sLayout={sLayout}/>),
            dataType: 'string',
            minWidth: 64,
        },
        {
            title: I18n.m.getMessage('firstName'),
            keyInData: 'memberFirstName',
            style: { width: 150 },
            dataType: 'string',
            sortable: true,
            onCellPress: onMemberCellPress,
        },
        {
            title: I18n.m.getMessage('lastName'),
            keyInData: 'memberLastName',
            style: { width: 150 },
            dataType: 'string',
            sortable: true,
            onCellPress: onMemberCellPress,
        },
        {
            title: I18n.m.getMessage('staffNumber'),
            keyInData: 'staffNumber',
            style: { width: 150 },
            dataType: 'string',
            sortable: true,
            onCellPress: onMemberCellPress,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelTargetTime'),
            format: (a) => TimeTrackingEntity.msToTime(a.targetHoursInMs, false, true),
            keyInData: 'targetHoursInMs',
            style: { width: 150 },
            dataType: 'number',
            sortable: true,
            onCellPress: onMemberCellPress,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelActualTime'),
            format: (a) => TimeTrackingEntity.msToTime(a.hoursInMs, false, true),
            keyInData: 'hoursInMs',
            style: { width: 150 },
            dataType: 'number',
            sortable: true,
            onCellPress: onMemberCellPress,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelOverTime'),
            keyInData: 'overtime',
            style: { width: 150 },
            format: (a) => TimeTrackingEntity.msToTime(a.overtime, false, true),
            dataType: 'number',
            sortable: true,
            onCellPress: onMemberCellPress,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelOverTimeMonthBefore'),
            keyInData: 'overtimeRestMonthBefore',
            style: { width: 150 },
            format: (a) => TimeTrackingEntity.msToTime(a.overtimeRestMonthBefore, false, true),
            dataType: 'number',
            sortable: true,
            onCellPress: onMemberCellPress,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelOverTimeTotal'),
            keyInData: 'overtimeTotal',
            style: { width: 150 },
            format: (a) => TimeTrackingEntity.msToTime(a.overtimeTotal, false, true),
            dataType: 'number',
            sortable: true,
            onCellPress: onMemberCellPress,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelOverTimePaid'),
            keyInData: 'paidOverTimeInMs',
            style: { width: 150 },
            format: (a) => TimeTrackingEntity.msToTime(a.paidOverTimeInMs, false, true),
            dataType: 'number',
            sortable: true,
            onCellPress: onMemberCellPress,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelRestTime'),
            keyInData: 'unPaidOverTimeInMs',
            style: { width: 150 },
            format: (a) => TimeTrackingEntity.msToTime(a.unPaidOverTimeInMs, false, true),
            dataType: 'number',
            sortable: true,
            cellRenderer: (item, column, _index) => {
                const value = Math.round(item.unPaidOverTimeInMs / 1000 / 6 / 6) / 100;
                return (<View key={`diff_${item.id}`} style={[{ justifyContent: 'center', paddingLeft: 16 }, column.style]}>
            <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
              <MaterialTextEditableNumber style={{ width: '100%' }} materailTextProps={{
                        textAlign: 'right',
                        fixedWidth: '100%',
                        numberOfLines: 1,
                    }} onChanged={(v) => {
                        const value = Math.round(Number.parseFloat(v) * 1000 * 60 * 60);
                        const c = new ChangeMembersUnpaidOvertime({ unPaidOverTimeInMs: value }, item.id);
                        c.execute(AuthClient.instance.commandStore)
                            .then(() => {
                            setUpdateCounter(updateCounter + 1);
                        })
                            .catch(DefaultErrorHandler.showDefaultErrorAlert);
                    }} formatNumber={(v) => TimeTrackingEntity.msToTime(v * 1000 * 60 * 60, false, true)}>
                {value.toString(10)}
              </MaterialTextEditableNumber>
            </View>
          </View>);
            },
        },
    ];
    if (CompanyUserInfo.me?.payroll) {
        columns.push({
            title: I18n.m.getMessage('timeTrackingProblems'),
            keyInData: 'problems',
            style: { width: 150 },
            sortable: false,
            cellRenderer: (item, column, _index) => {
                let problems = 0;
                let lastDay = '';
                item.problems?.forEach((a) => {
                    const d = I18n.m.dateCurrent.localeDateString(new Date(a.day));
                    if (d !== lastDay)
                        problems += 1;
                    lastDay = d;
                });
                return (<View key={`diff_${item.id}_${item.lastModifiedAt}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
            <View style={{ alignSelf: 'flex-end' }}>
              <Chip title={problems.toString(10)} backgroundColor={problems > 0 ? ThemeManager.style.brandDanger : ThemeManager.style.brandSuccess} textColor="#FFFFFF" onPressChip={item.problems && item.problems.length > 0
                        ? openTrackingProblemsDialog({
                            problems: item.problems,
                            memberId: item.memberId,
                            userId: item.user ? item.user.id : undefined,
                        })
                        : undefined}/>
            </View>
          </View>);
            },
        });
    }
    return (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <View style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 16,
            alignItems: 'center',
        }} onLayout={(e) => setPeriodSelectorHeight(e.nativeEvent.layout.height)}>
        <Icon icon="arrow-left" outerSize={32} backgroundColor="#FFFFFF" toolTip="" onPress={() => Routing.instance.goTo(`/timeTracking/payroll`, true)}/>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          {period == null ? null : (<PayrollAccountingPeriodStatusChip key={`status_${period.id}_${period.lastUpdate}`} accounting={period} onUpdated={() => {
                setUpdateCounter(updateCounter + 1);
            }}/>)}

          <PayrollPeriodSelector selectedId={props.periodId} onChange={onChangePeriod}/>
        </View>
      </View>
      <View style={{ width: '100%', maxWidth: '100%', paddingHorizontal: 8 }}>
        <Card outerPadding={8} style={{ height: maxHeight - 16 - periodSelectorHeight }}>
          <Table data={data} columns={columns} tableName="payrollAccountingPeriodsPerMemberOverview" maxHeight={maxHeight - 16 - periodSelectorHeight} actionItemsLength={0} emptyTableText={I18n.m.getMessage('payrollNoDataText')}/>
        </Card>
      </View>
    </View>);
}
