"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthClient = exports.ClientModals = void 0;
var CommandOfflineFirstStore_1 = require("cqrs-core/build/src/client/commands/CommandOfflineFirstStore");
var EventHandler_1 = require("cqrs-core/build/src/client/events/EventHandler");
var ReadModelChangedDefaultClientDispatcher_1 = require("cqrs-core/build/src/client/query/ReadModelChangedDefaultClientDispatcher");
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var CommandRemoteStore_1 = require("cqrs-core/build/src/core/commands/CommandRemoteStore");
var ReadRemoteStore_1 = require("cqrs-core/build/src/core/query/ReadRemoteStore");
var SyncDispatcher_1 = require("cqrs-core/build/src/core/query/SyncDispatcher");
var ServerConnection_1 = require("cqrs-core/build/src/core/ServerConnection");
var WaitFor_1 = require("cqrs-shared/build/src/WaitFor");
var ApplicationError_1 = require("odatarepos/build/src/entities/ApplicationError");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var ts_events_1 = require("ts-events");
var LoginWithToken_1 = require("./commands/guest/LoginWithToken");
var Logout_1 = require("./commands/guest/Logout");
var CurrentUser_1 = require("./CurrentUser");
var NetworkStatus_1 = require("./NetworkStatus");
var UserSettingsEntity_1 = require("./query/entities/UserSettingsEntity");
var User_1 = require("./query/User");
var UserAdress_1 = require("./query/UserAdress");
var UserMails_1 = require("./query/UserMails");
var UserSettings_1 = require("./query/UserSettings");
var ClientModals = (0, _createClass2.default)(function ClientModals() {
  (0, _classCallCheck2.default)(this, ClientModals);
  this.user = new User_1.User();
  this.userMails = new UserMails_1.UserMails();
  this.userAdress = new UserAdress_1.UserAdress();
  this.userSettings = new UserSettings_1.UserSettings();
});
exports.ClientModals = ClientModals;
var AuthClient = function () {
  function AuthClient() {
    var _this = this;
    (0, _classCallCheck2.default)(this, AuthClient);
    this.stayLoggedIn = true;
    this.deleteClientDB = false;
    this.initialized = false;
    this._eventDispatcher = ReadModelChangedDefaultClientDispatcher_1.ReadModelChangedDefaultClientDispatcher.instance;
    this._loggedIn = undefined;
    this._userId = '';
    this.beforeLogoutFunctions = [];
    this.isInit = false;
    this.syncIntervalRoutine = function () {
      var _this$serverConnectio, _this$syncDispatcher;
      if (_this.syncInterval != null) clearTimeout(_this.syncInterval);
      if (_this.loggedIn && (_this$serverConnectio = _this.serverConnection) != null && _this$serverConnectio.isConnected && _this.token.length > 3) {
        _this.startSync(true).catch(function (err) {
          return void 0;
        });
      }
      if (((_this$syncDispatcher = _this.syncDispatcher) == null ? void 0 : _this$syncDispatcher.db) != null) _this.syncInterval = setTimeout(_this.syncIntervalRoutine, 600000);
    };
    this.serverConnectionsChanged = new ts_events_1.AsyncEvent();
    this.onConnectionChanged = function () {
      _this.serverConnectionsChanged.post(_this.serverConnected());
    };
    this.validatedToken = '';
    this.validatingToken = '';
    this.validateToken = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (reason) {
        var _this$authConfig;
        var token = _this.token;
        if (token == null || token.length === 0) {
          throw new ApplicationError_1.ApplicationError('TokenError', 'tokenError', ApplicationError_1.ApplicationErrorStatusCode.UnauthorizedError);
        }
        if (_this.validateTokenTimer != null) clearTimeout(_this.validateTokenTimer);
        if (((_this$authConfig = _this.authConfig) == null ? void 0 : _this$authConfig.worker) == null) {
          if (token != null && token.length > 3 && token === _this.validatedToken) {
            _this._userId = _this.getUserIdFromToken(token);
            return true;
          }
          if (token != null && token.length > 3 && _this.validatingToken === token) {
            yield WaitFor_1.WaitFor.instance.waitFor(function () {
              return _this.validatingToken === '';
            });
            return _this.validateToken(reason);
          }
          if (_this.commandStore != null && _this.serverConnection != null && _this.serverConnection.isConnected && token != null && token.length > 3) {
            _this.validatingToken = token;
            var loginWithToken = new LoginWithToken_1.LoginWithToken({
              token: token
            });
            try {
              yield loginWithToken.execute(_this.commandStore);
              _this.validatingToken = '';
              _this._userId = _this.getUserIdFromToken(token);
              _this.validatedToken = token;
              return true;
            } catch (e) {
              _this.validatedToken = '';
              _this.validatingToken = '';
              if (e != null && e.messageCode != null && (e.messageCode === 'loginErrorNoAccount' || e.messageCode === 'loginError' || e.messageCode === 'accountMarkedForDeletion' || e.messageCode === 'accountLocked' || e.messageCode === 'loginErrorPasswordRequired' || e.messageCode === 'unauthorized' || e.messageCode === 'tokenError')) {
                _this.isInit = false;
                try {
                  yield _this.logOut(false);
                  AuthClient.onLogoutEvent.post({
                    userId: '',
                    goTo: _this.goTo
                  });
                } catch (err) {
                  _this._userId = '';
                  _this._loggedIn = false;
                  yield _this.setToken({
                    token: '',
                    goTo: undefined
                  });
                  AuthClient.onLogoutEvent.post({
                    userId: ''
                  });
                }
                throw e;
              }
            }
          } else if (token != null && token.length > 3) {
            if (_this.serverConnection == null || !_this.serverConnection.isConnected) {
              _this._userId = _this.getUserIdFromToken(token);
              return true;
            }
            return new Promise(function (resolve, reject) {
              setTimeout(function () {
                _this.validateToken(reason).then(function (e) {
                  resolve(e);
                }).catch(function (err) {
                  return reject(err);
                });
              }, 1000);
            });
          }
        }
        _this._userId = _this.getUserIdFromToken(token);
        return true;
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    this.registerOfflineCommands = false;
    this.onCommandError = function (e) {
      if (e.statusCode === 401) _this.validateToken('on command Error').catch(function (err) {
        return void 0;
      });
    };
    this.onServerConnection = function (_socket) {
      if (_this.token != null && _this.token.length > 3 && _this.commandStore != null && _this.initialized) {
        try {
          _this.startSync().catch(function (err) {
            return void 0;
          });
        } catch (e) {}
      }
    };
    this.onServerDisconnection = function () {
      _this.validatedToken = '';
      if (_this.authConfig == null) return;
      if (_this.serverConnection != null) {
        _this.serverConnection.onSyncShouldStop.post();
      }
    };
    this.getUserSettings = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (userId) {
        try {
          var s = new UserSettingsEntity_1.UserSettingsEntity({
            id: userId,
            userId: userId
          });
          try {
            s = yield _this.modals.userSettings.getById(userId);
          } catch (err) {
            if (_this.serverConnected()) {
              s = yield _this.modals.userSettings.getRemote.getById(userId);
            }
          }
          return s;
        } catch (e) {
          return new UserSettingsEntity_1.UserSettingsEntity({
            id: userId,
            userId: userId
          });
        }
      });
      return function (_x2) {
        return _ref2.apply(this, arguments);
      };
    }();
    this.setCurrentUser = function () {
      var _ref3 = (0, _asyncToGenerator2.default)(function* (e) {
        var retries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
        if (_this.token == null || _this.token.length === 0) {
          return;
        }
        var hasErrors;
        try {
          if (e != null && e.id != null) {
            if (_this.userId === e.id) {
              var settings = yield _this.getUserSettings(_this.userId);
              CurrentUser_1.CurrentUser.setUser(e, _this.token, settings);
            }
          } else if (_this.userId !== 'all' && _this.userId.length > 0) {
            try {
              var u;
              try {
                u = yield _this.modals.user.getById(_this.userId);
              } catch (err) {
                if (_this.serverConnected()) {
                  u = yield _this.modals.user.getRemote.getById(_this.userId);
                }
              }
              if (u == null && retries < 3) yield new Promise(function (resolve, reject) {
                setTimeout(function () {
                  _this.setCurrentUser(e, retries + 1).then(function () {
                    resolve();
                  }).catch(function (err) {
                    return reject(err);
                  });
                }, 1000);
              });
              if (u == null) throw StdApplicationError_1.StdApplicationError.notFound(`UsernotFound`, `UsernotFound`, {
                id: _this.userId
              });
              var _settings = yield _this.getUserSettings(_this.userId);
              CurrentUser_1.CurrentUser.setUser(u, _this.token, _settings);
            } catch (err) {
              hasErrors = err;
            }
          }
        } catch (err) {
          hasErrors = e;
        }
        if (hasErrors != null) {
          throw hasErrors;
        }
      });
      return function (_x3) {
        return _ref3.apply(this, arguments);
      };
    }();
    this.setToken = function () {
      var _ref4 = (0, _asyncToGenerator2.default)(function* (data) {
        if (_this.authConfig == null) throw new Error('first set up authClient');
        if (_this.authConfig.loginEvents) {
          _this.goTo = data.goTo;
          var serverConnection = _this.serverConnection;
          if (_this.initialized && serverConnection) {
            if (_this.token !== data.token) {
              yield serverConnection.changeAuthToken(data.token);
              yield _this.reInit();
            }
          } else {
            yield WaitFor_1.WaitFor.instance.waitFor(function () {
              return _this.initialized;
            });
            if (_this.initialized) yield _this.setToken(data);
          }
        }
      });
      return function (_x4) {
        return _ref4.apply(this, arguments);
      };
    }();
    this.setSyncDisabled = function (syncDisabled) {
      if (_this.authConfig) _this.authConfig.syncDisabled = syncDisabled;
    };
    this.startSync = (0, _asyncToGenerator2.default)(function* () {
      var _this$authConfig2;
      var start = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (!start && (_this$authConfig2 = _this.authConfig) != null && _this$authConfig2.syncDisabled) return;
      try {
        var _this$authConfig3;
        if (_this.token == null || _this.token.length === 0) {
          _this.syncDispatcher.syncStop.post();
          return;
        }
        var validated = yield _this.validateToken('on start sync');
        if (!validated) {
          throw new ApplicationError_1.ApplicationError('TokenError', 'tokenError', ApplicationError_1.ApplicationErrorStatusCode.UnauthorizedError);
        }
        if (_this.authConfig == null || !start || !_this.loggedIn || _this.syncDispatcher.db == null) return;
        if (((_this$authConfig3 = _this.authConfig) == null ? void 0 : _this$authConfig3.worker) != null) {
          _this.syncDispatcher.postSyncStatus({
            running: true,
            percent: 1,
            step: {
              readModelName: '',
              percent: 0
            }
          });
          _this.authConfig.worker.postToWorker('toLeaderµstartSync');
        } else {
          yield _this.syncDispatcher.startSync();
        }
      } catch (err) {}
    });
    if (AuthClient._instance != null) {
      throw new Error('use AuthClient.instance');
    }
  }
  return (0, _createClass2.default)(AuthClient, [{
    key: "eventDispatcher",
    get: function get() {
      return this._eventDispatcher;
    }
  }, {
    key: "localDb",
    get: function get() {
      if (this._syncDispatcher != null && this._syncDispatcher.db != null) {
        return this._syncDispatcher.db;
      }
      return undefined;
    }
  }, {
    key: "loggedIn",
    get: function get() {
      return this._loggedIn === true;
    }
  }, {
    key: "modals",
    get: function get() {
      return this._modals;
    }
  }, {
    key: "serverConnection",
    get: function get() {
      var _this$authConfig4;
      if (((_this$authConfig4 = this.authConfig) == null ? void 0 : _this$authConfig4.worker) != null && this._serverConnection) return this._serverConnection;
      if (this.authConfig != null && this.authConfig.serverConnection) {
        return this.authConfig.serverConnection();
      }
      if (this._serverConnection == null) throw new Error('need Serverconnection in AuthCLient');
      return this._serverConnection;
    }
  }, {
    key: "syncDispatcher",
    get: function get() {
      return this._syncDispatcher;
    }
  }, {
    key: "token",
    get: function get() {
      if (this.serverConnection.token == null) {
        return '';
      }
      return this.serverConnection.token;
    }
  }, {
    key: "ready",
    get: function get() {
      return this.initialized && !this.isInit;
    }
  }, {
    key: "registerBeforeLogout",
    value: function registerBeforeLogout(register) {
      this.beforeLogoutFunctions.push(register);
    }
  }, {
    key: "url",
    get: function get() {
      return this.authConfig != null ? this.authConfig.remoteUrl : '';
    }
  }, {
    key: "userId",
    get: function get() {
      if (this._userId == null || this._userId === 'undefined') return '';
      return this._userId;
    }
  }, {
    key: "getUserIdFromToken",
    value: function getUserIdFromToken(token) {
      var _this$serverConnectio2,
        _this2 = this;
      var userId = '';
      var t = token != null && token.length > 3 ? token : (_this$serverConnectio2 = this.serverConnection) == null ? void 0 : _this$serverConnectio2.token;
      if (t != null && t.length > 1) {
        try {
          var uId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(t);
          if (uId == null || uId._id == null || uId._id.length === 0) {
            throw {
              key: '',
              messageCode: 'loginErrorWrongCredentials',
              message: 'Fehlerhafte Zugangsdaten'
            };
          }
          userId = uId._id;
        } catch (e) {
          this.logOut(false).then(function () {
            AuthClient.onLogoutEvent.post({
              userId: '',
              goTo: _this2.goTo
            });
          }).catch(function (err) {
            AuthClient.onLogoutEvent.post({
              userId: '',
              goTo: _this2.goTo
            });
          });
          throw {
            key: '',
            messageCode: 'loginErrorWrongCredentials',
            message: 'Fehlerhafte Zugangsdaten'
          };
        }
      }
      return userId;
    }
  }, {
    key: "close",
    value: function () {
      var _close = (0, _asyncToGenerator2.default)(function* () {
        if (this.serverConnection != null) yield this.serverConnection.close();
        if (this._syncDispatcher != null && this._syncDispatcher.db != null) {
          yield this._syncDispatcher.db.closeDB();
        }
      });
      function close() {
        return _close.apply(this, arguments);
      }
      return close;
    }()
  }, {
    key: "hasWorker",
    value: function hasWorker() {
      return this.authConfig != null && this.authConfig.worker != null;
    }
  }, {
    key: "serverConnected",
    value: function serverConnected() {
      if (this.authConfig != null) {
        if (this.authConfig.worker != null) return this.authConfig.worker.serverConnected();
        if (this.serverConnection != null) return this.serverConnection.isConnected;
      }
      if (this.serverConnection != null) return this.serverConnection.isConnected;
      return false;
    }
  }, {
    key: "setSyncDispachter",
    value: function () {
      var _setSyncDispachter = (0, _asyncToGenerator2.default)(function* (db, dbOptions, userId, token) {
        var _this3 = this;
        if (this._syncDispatcher) {
          yield this._syncDispatcher.resetSync(db, dbOptions, userId, token, this.url);
        } else {
          this._syncDispatcher = new SyncDispatcher_1.SyncDispatcher(db, dbOptions, userId, token, function () {
            return _this3.serverConnection;
          }, this.url);
        }
        yield this._syncDispatcher.initSD();
      });
      function setSyncDispachter(_x5, _x6, _x7, _x8) {
        return _setSyncDispachter.apply(this, arguments);
      }
      return setSyncDispachter;
    }()
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* (authConfig, token) {
        var _this4 = this;
        yield WaitFor_1.WaitFor.instance.waitFor(function () {
          return !_this4.isInit;
        });
        this.isInit = true;
        if (token != null && token.length > 3) AuthClient.onStartLogin.post();
        this.authConfig = authConfig;
        if (this.authConfig.loginEvents == null) {
          this.authConfig.loginEvents = true;
        }
        var t = token != null ? token : '';
        if (this.authConfig.worker != null) {
          if (this._syncDispatcher == null) yield this.setSyncDispachter(undefined, {}, this.getUserIdFromToken(t), t);
          if (this._serverConnection == null) {
            this._serverConnection = this.authConfig.worker.getClientSC(token != null ? token : '', function () {
              return _this4._syncDispatcher;
            });
          }
          yield this.authConfig.reInitDB(this.getUserIdFromToken(token));
          this.authConfig.loginEvents = true;
          yield this.authConfig.worker.initWorker(token != null ? token : '');
          this.authConfig.worker.serverConnectionsChanged.detach(this.onConnectionChanged);
          this.authConfig.worker.serverConnectionsChanged.attach(this.onConnectionChanged);
        } else if (this.serverConnection == null) {
          this._serverConnection = new ServerConnection_1.ServerConnection({
            remoteOptions: {
              apiUrl: authConfig.remoteUrl,
              authToken: token
            },
            autoConnect: false
          });
        } else if (this.serverConnection.token !== t) {
          yield this.serverConnection.changeAuthToken(t);
        }
        this._userId = token != null && token.length > 1 ? this.getUserIdFromToken(token) : '';
        if (this.serverConnection != null) {
          this.serverConnection.onConnectionChanged.detach(this.onConnectionChanged);
          this.serverConnection.onConnect.detach(this.onServerConnection);
          this.serverConnection.onDisconnect.detach(this.onServerDisconnection);
          this.serverConnection.onConnectionChanged.attach(this.onConnectionChanged);
          this.serverConnection.onConnect.attach(this.onServerConnection);
          this.serverConnection.onDisconnect.attach(this.onServerDisconnection);
        }
        if (NetworkStatus_1.NetworkStatus.online && this.serverConnection != null && !this.serverConnection.isConnected && this.authConfig.startSync) {
          yield this.connectToServer();
        }
        this.initialized = true;
        if (token != null && token.length > 3) {
          yield this.initWithToken(token);
        } else {
          yield this.initWithOutToken();
        }
        this.isInit = false;
      });
      function init(_x9, _x10) {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }, {
    key: "logIn",
    value: function () {
      var _logIn = (0, _asyncToGenerator2.default)(function* (result) {
        var _this$authConfig5;
        if (result.token != null && result.token.length > 3) AuthClient.onStartLogin.post();
        if ((_this$authConfig5 = this.authConfig) != null && _this$authConfig5.worker) {
          yield this.authConfig.worker.initWorker(result.token);
        }
        return this.setToken(result);
      });
      function logIn(_x11) {
        return _logIn.apply(this, arguments);
      }
      return logIn;
    }()
  }, {
    key: "logOut",
    value: function () {
      var _logOut = (0, _asyncToGenerator2.default)(function* (deleteClientDB, goToAfterLogout) {
        var _this5 = this;
        yield WaitFor_1.WaitFor.instance.waitFor(function () {
          return !_this5.isInit;
        });
        this.deleteClientDB = deleteClientDB;
        this.syncDispatcher.syncStop.post();
        yield WaitFor_1.WaitFor.instance.waitFor(function () {
          return !_this5.syncDispatcher.isSyncing;
        });
        if (this.serverConnection) {
          this.serverConnection.onSyncShouldStop.post();
        }
        try {
          var promises = [];
          this.beforeLogoutFunctions.forEach(function (f) {
            return promises.push(f());
          });
          yield Promise.all(promises);
        } catch (e) {}
        this.beforeLogoutFunctions = [];
        if (this.userId !== 'all') {
          try {
            if (this.serverConnection != null && this.serverConnection.isConnected) {
              var logout = new Logout_1.Logout({
                goTo: goToAfterLogout
              }, this.token);
              yield logout.execute(this.commandStore);
            }
          } catch (e) {}
          try {
            if (this.authConfig != null) {
              var userId = this.getUserIdFromToken();
              var clientDB = yield this.authConfig.reInitDB(userId);
              if (deleteClientDB) {
                try {
                  if (this.syncDispatcher.db != null) yield this.syncDispatcher.db.deleteDB();
                } catch (e) {}
              }
              yield this.setSyncDispachter(clientDB.db, clientDB.dbOptions, userId, this.token);
            }
          } catch (e) {}
        }
        yield this.setToken({
          token: '',
          goTo: goToAfterLogout
        });
      });
      function logOut(_x12, _x13) {
        return _logOut.apply(this, arguments);
      }
      return logOut;
    }()
  }, {
    key: "offlineCommandRepo",
    get: function get() {
      return this._offlineCommandRepo;
    }
  }, {
    key: "freshDatabase",
    value: function () {
      var _freshDatabase = (0, _asyncToGenerator2.default)(function* () {
        if (this.authConfig == null || !this.syncDispatcher.db) return false;
        try {
          if (this.authConfig.worker != null) {
            var f = yield this.authConfig.worker.getFreshDatabase();
            return f;
          }
          if (this.syncDispatcher.db != null) {
            var offlineStaterepo = this.syncDispatcher.db.getRepos(new ReadModelClientOfflineFirst_1.ReadOfflineModel());
            if (offlineStaterepo) {
              var one = yield offlineStaterepo.get({
                top: 1,
                orderby: 'lastSync DESC'
              });
              if (one.length > 0) {
                var t = new Date();
                var thirtyDays = new Date(t.getFullYear(), t.getMonth(), t.getDate() - 30, 0, 0, 0);
                return one[0].lastSync.getTime() < thirtyDays.getTime();
              }
              return true;
            }
          }
        } catch (e) {
          return true;
        }
        return false;
      });
      function freshDatabase() {
        return _freshDatabase.apply(this, arguments);
      }
      return freshDatabase;
    }()
  }, {
    key: "resetModels",
    value: function () {
      var _resetModels = (0, _asyncToGenerator2.default)(function* () {
        if (this.modals != null && this.syncDispatcher.db) {
          for (var key in this.modals) {
            var model = this.modals[key];
            yield model.reset();
          }
        }
      });
      function resetModels() {
        return _resetModels.apply(this, arguments);
      }
      return resetModels;
    }()
  }, {
    key: "initWithOutToken",
    value: function () {
      var _initWithOutToken = (0, _asyncToGenerator2.default)(function* () {
        this._userId = '';
        if (this.syncInterval != null) clearTimeout(this.syncInterval);
        CurrentUser_1.CurrentUser.setUser(undefined, '', new UserSettingsEntity_1.UserSettingsEntity({
          id: ''
        }));
        if (this.authConfig == null) return;
        this.serverConnection.onSyncShouldStop.post();
        yield this.setSyncDispachter(undefined, {}, '', '');
        this.createCommandStore();
        if (this.remoteDB == null && this.commandStore != null) {
          this.remoteDB = new ReadRemoteStore_1.ReadRemoteStore(this.commandStore);
        }
        yield this.remoteDB.initDB();
        yield this.resetModels();
        if (this.userListener != null) {
          this.eventDispatcher.detach('User', this.userListener);
          this.userListener = undefined;
        }
        if (this._loggedIn !== false) {
          this._loggedIn = false;
          AuthClient.onLogoutEvent.post({
            userId: '',
            goTo: this.goTo
          });
          if (this.goTo != null) {
            this.goTo = undefined;
          }
        }
      });
      function initWithOutToken() {
        return _initWithOutToken.apply(this, arguments);
      }
      return initWithOutToken;
    }()
  }, {
    key: "initWithToken",
    value: function () {
      var _initWithToken = (0, _asyncToGenerator2.default)(function* (token) {
        var _this6 = this,
          _this$serverConnectio3,
          _this$serverConnectio4;
        if (this.authConfig == null) return;
        this.createCommandStore();
        var validated = yield this.validateToken('on init');
        if (!validated) {
          throw new ApplicationError_1.ApplicationError('TokenError', 'tokenError', ApplicationError_1.ApplicationErrorStatusCode.UnauthorizedError);
        }
        if (this._modals == null) {
          this._modals = new ClientModals();
        }
        if (this.remoteDB == null && this.commandStore != null) {
          this.remoteDB = new ReadRemoteStore_1.ReadRemoteStore(this.commandStore);
        }
        yield this.remoteDB.initDB();
        var userId = this.getUserIdFromToken(token);
        var clientDB = yield this.authConfig.reInitDB(userId);
        if (!clientDB.db) {}
        yield this.setSyncDispachter(clientDB.db, clientDB.dbOptions, userId, token);
        this.createCommandStore();
        if (this._syncDispatcher == null) throw new Error('need Syncdispatcher to init');
        yield this.syncDispatcher.initSD();
        this._offlineCommandRepo = this.syncDispatcher.db ? this.syncDispatcher.db.getRepos(new CommandOfflineFirstStore_1.CommandOfflineModel()) : this.remoteDB.getRepos(new CommandOfflineFirstStore_1.CommandOfflineModel());
        var repo = this.remoteDB.getRepos(this.modals.user.exampleEntity);
        var readModelConfig = {
          remoteDB: this.remoteDB,
          serverConnection: function serverConnection() {
            return _this6.serverConnection;
          },
          syncDispatcher: this._syncDispatcher,
          repo: repo,
          events: EventHandler_1.EventHandler.defaultHandler,
          eventDispatcher: this._eventDispatcher
        };
        for (var key in this.modals) {
          var model = this.modals[key];
          var _repo = this.remoteDB.getRepos(model.exampleEntity);
          yield model.init(Object.assign({}, readModelConfig, {
            repo: _repo,
            priority: model['syncPriority'] && typeof model['syncPriority'] === 'number' ? model['syncPriority'] : 3
          }));
        }
        if (this.authConfig.startSync && !((_this$serverConnectio3 = this.serverConnection) != null && _this$serverConnectio3.isConnected)) yield (_this$serverConnectio4 = this.serverConnection) == null ? void 0 : _this$serverConnectio4.connect();
        if (this.authConfig.startSync) this.startSync(this.authConfig.startSync).catch(function (err) {
          return void 0;
        });
        if (this._loggedIn !== true) {
          this._userId = this.getUserIdFromToken(token);
          if (this.syncInterval != null) clearTimeout(this.syncInterval);
          this.syncInterval = setTimeout(this.syncIntervalRoutine, 300000);
          this._loggedIn = true;
          if (this.userId !== 'all') {
            yield this.setCurrentUser();
            if (this.userListener == null) {
              this.userListener = this.eventDispatcher.attach({
                readModelName: 'User',
                callback: function callback(r) {
                  r.entities.forEach(function (f) {
                    if (f.entity.id === _this6.userId) _this6.setCurrentUser(f.entity).catch(function (err) {
                      return void 0;
                    });
                  });
                }
              });
            }
          }
          yield WaitFor_1.WaitFor.instance.waitFor(function () {
            return AuthClient.onLoginEvent.listenerCount() > 0;
          }, 200, 30000);
          AuthClient.onLoginEvent.post({
            token: token,
            userId: this.userId,
            goTo: this.goTo
          });
          if (this.goTo != null) {
            this.goTo = undefined;
          }
        }
      });
      function initWithToken(_x14) {
        return _initWithToken.apply(this, arguments);
      }
      return initWithToken;
    }()
  }, {
    key: "reInit",
    value: function () {
      var _reInit = (0, _asyncToGenerator2.default)(function* () {
        if (this.authConfig == null) return;
        try {
          var userId = this.getUserIdFromToken();
          var clientDB = yield this.authConfig.reInitDB(userId);
          yield this.setSyncDispachter(clientDB.db, clientDB.dbOptions, userId, this.token);
        } catch (e) {
          this.isInit = false;
          yield this.logOut(false);
          this._userId = '';
          throw e;
        }
        this.deleteClientDB = false;
        yield this.init(this.authConfig, this.token);
      });
      function reInit() {
        return _reInit.apply(this, arguments);
      }
      return reInit;
    }()
  }, {
    key: "connectToServer",
    value: function () {
      var _connectToServer = (0, _asyncToGenerator2.default)(function* () {
        var _this$serverConnectio5,
          _this7 = this;
        if (this.authConfig == null) return;
        yield (_this$serverConnectio5 = this.serverConnection) == null ? void 0 : _this$serverConnectio5.connect();
        if (!this.serverConnection.isConnected) {
          yield WaitFor_1.WaitFor.instance.waitFor(function () {
            return _this7.serverConnection.isConnected;
          }, 66, 3000);
        }
      });
      function connectToServer() {
        return _connectToServer.apply(this, arguments);
      }
      return connectToServer;
    }()
  }, {
    key: "createCommandStore",
    value: function createCommandStore() {
      var _this8 = this;
      if (this.authConfig == null) return;
      if (this.syncDispatcher == null) {
        var remoteStoreConfig = new CommandRemoteStore_1.CommandRemoteStoreConfig(function () {
          return _this8.serverConnection;
        }, this._eventDispatcher);
        this.commandStore = new CommandRemoteStore_1.CommandRemoteStore(remoteStoreConfig);
        this.commandStore.onError.attach(this.onCommandError);
      } else {
        this.commandStore = new CommandOfflineFirstStore_1.CommandOfflineFirstStore({
          databaseVersion: 6,
          syncDispatcher: function syncDispatcher() {
            return _this8.syncDispatcher;
          },
          dispatcher: this._eventDispatcher,
          serverConnection: function serverConnection() {
            return _this8.serverConnection;
          },
          eventHandler: EventHandler_1.EventHandler.defaultHandler,
          discardOnErrors: this.authConfig.discardOnErrors
        });
      }
    }
  }], [{
    key: "instance",
    get: function get() {
      if (AuthClient._instance == null) {
        AuthClient._instance = new AuthClient();
      }
      return AuthClient._instance;
    }
  }, {
    key: "onLogin",
    value: function onLogin(callback) {
      AuthClient.onLoginEvent.attach(callback);
    }
  }, {
    key: "onLoginDetach",
    value: function onLoginDetach(callback) {
      AuthClient.onLoginEvent.detach(callback);
    }
  }, {
    key: "onLogout",
    value: function onLogout(callback) {
      AuthClient.onLogoutEvent.attach(callback);
    }
  }, {
    key: "onLogoutDetach",
    value: function onLogoutDetach(callback) {
      AuthClient.onLogoutEvent.detach(callback);
    }
  }]);
}();
exports.AuthClient = AuthClient;
AuthClient.webClientId = '576732586755-pl5uu1469orb2cnubsc2ic317ip5mtir.apps.googleusercontent.com';
AuthClient.onStartLogin = new ts_events_1.AsyncEvent();
AuthClient.onLoginEvent = new ts_events_1.AsyncEvent();
AuthClient.onLogoutEvent = new ts_events_1.AsyncEvent();