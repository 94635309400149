var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TableSettings;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _reactNativeDraggableFlatlist = _interopRequireWildcard(require("react-native-draggable-flatlist"));
var _I18n = require("../i18n/I18n");
var _ThemeManager = require("../ThemeManager");
var _ContainedButton = require("./button/ContainedButton");
var _CheckboxListItem = require("./CheckboxListItem");
var _Dialog = require("./Dialog");
var _DialogActions = require("./dialog/DialogActions");
var _DialogContent = require("./dialog/DialogContent");
var _DialogTitle = require("./dialog/DialogTitle");
var _Icon = require("./Icon");
var _MaterialText = require("./text/MaterialText");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function TableSettings(props) {
  var _useState = (0, _react.useState)(props.order.map(function (columnIndex) {
      var _ref, _props$selectedColumn, _props$selectedColumn2;
      var c = props.columns[columnIndex];
      var t = c.hiddenTitleForSettings != null ? c.hiddenTitleForSettings : typeof c.title === 'string' ? c.title : c.title.toolTip;
      return {
        t: t,
        selected: (_ref = ((_props$selectedColumn = props.selectedColumns) == null ? void 0 : _props$selectedColumn.length) === 0 || ((_props$selectedColumn2 = props.selectedColumns) == null ? void 0 : _props$selectedColumn2.includes(t))) != null ? _ref : true,
        width: props.columnWidth[columnIndex].toString(),
        index: columnIndex
      };
    })),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    columnData = _useState2[0],
    setColumnData = _useState2[1];
  var renderItem = function renderItem(_ref2) {
    var item = _ref2.item,
      getIndex = _ref2.getIndex,
      drag = _ref2.drag;
    var t = item.t;
    return (0, _jsxRuntime.jsx)(_reactNativeDraggableFlatlist.ScaleDecorator, {
      activeScale: 1.05,
      children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          flexDirection: 'row',
          height: 56,
          alignItems: 'center'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.TouchableOpacity, {
          onPressIn: drag,
          style: {
            width: 56,
            height: 56,
            justifyContent: 'center',
            alignItems: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "drag-horizontal",
            toolTip: ""
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flex: 1
          },
          children: (0, _jsxRuntime.jsx)(_CheckboxListItem.CheckboxListItem, {
            title: t,
            value: item.selected,
            onChange: function onChange(val) {
              var index = getIndex();
              if (index != null) setColumnData(function (oldColumns) {
                var newColumns = JSON.parse(JSON.stringify(oldColumns));
                newColumns[index].selected = val;
                return newColumns;
              });
            }
          })
        }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '20%',
            alignItems: 'center',
            height: 48,
            flexDirection: 'row'
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.TextInput, {
            value: item.width,
            style: {
              textAlignVertical: 'center',
              textAlign: 'center',
              height: 48,
              backgroundColor: '#fafafa',
              width: 48,
              fontSize: _ThemeManager.ThemeManager.style.getTextInputFontSize()
            },
            keyboardType: "number-pad",
            onChange: function onChange(e) {
              if (e.persist != null && typeof e.persist === 'function') e.persist();
              var index = getIndex();
              if (index != null) setColumnData(function (oldColumns) {
                var newColumns = JSON.parse(JSON.stringify(oldColumns));
                newColumns[index].width = e.nativeEvent.text.replace(/[^0-9]/g, '');
                return newColumns;
              });
            }
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: 48
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: "px"
            })
          })]
        })]
      }, t)
    });
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_DialogTitle.DialogTitle, {
      children: _I18n.I18n.m.getMessage('displayedColumns')
    }, "title"), (0, _jsxRuntime.jsx)(_DialogContent.DialogContent, {
      children: (0, _jsxRuntime.jsx)(_reactNativeDraggableFlatlist.default, {
        style: {
          width: '100%'
        },
        data: columnData,
        renderItem: renderItem,
        keyExtractor: function keyExtractor(item) {
          return item.t;
        },
        onDragEnd: function onDragEnd(_ref3) {
          var data = _ref3.data;
          return setColumnData(data);
        }
      })
    }, "content"), (0, _jsxRuntime.jsxs)(_DialogActions.DialogActions, {
      children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
        title: _I18n.I18n.m.getMessage('cancel'),
        onPress: function onPress(_e) {
          var _Dialog$instance;
          (_Dialog$instance = _Dialog.Dialog.instance) == null || _Dialog$instance.close();
        },
        backgroundColor: "transparent",
        textColor: _ThemeManager.ThemeManager.style.brandPrimary
      }, "cancel"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
        title: _I18n.I18n.m.getMessage('save'),
        onPress: function onPress() {
          var _Dialog$instance2;
          var sorted = (0, _toConsumableArray2.default)(columnData).sort(function (a, b) {
            return a.index - b.index;
          });
          props.onSave({
            currentSelected: sorted.filter(function (s) {
              return s.selected;
            }).map(function (s) {
              return s.t;
            }),
            columnWidth: sorted.map(function (s) {
              var x = Number.parseInt(s.width, 10);
              if (Number.isNaN(x)) return 100;
              return Math.max(x, 100);
            }),
            order: columnData.map(function (s) {
              return s.index;
            })
          });
          (_Dialog$instance2 = _Dialog.Dialog.instance) == null || _Dialog$instance2.close();
        }
      }, "close")]
    }, "actions")]
  });
}