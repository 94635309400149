import { scrollbarWidth } from '@xobotyi/scrollbar-width';
import Color from 'color';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useRef, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { EntriesPerUserForDay, } from 'upmesh-core/src/client/query/entities/TimeTrackingCombinedEntities';
import { CachedEntities } from '../../../config/CachedEntities';
import { I18n } from '../../../i18n/I18n';
import { openCompanyMemberDialog } from '../../companies/member/openCompanyMemberDialog';
import { openTimeTrackingDayForUserDialog } from '../dialogs/openTimeTrackingDayForUserDialog';
import { openTimeTrackingDialog } from '../dialogs/openTimeTrackingDialog';
export function TimeTrackingTimeline(props) {
    const [scrollPositionVertical, setScrollPositionVertical] = useState(0);
    const [scrollPositionHoriziontal, setScrollPositionHorizontal] = useState(0);
    const scrollBarWidth = scrollbarWidth() || 0;
    const itemHeight = 48;
    const calendarHeaderHeight = 48;
    const memberNamesWidth = Math.min(256, ResizeEvent.current.windowWidth / 3);
    const memberNames = Array.from(props.members.values());
    const contentHeight = itemHeight * memberNames.length;
    const dayCount = props.currentPeriod != null
        ? EntriesPerUserForDay.dateDiffInDays(props.currentPeriod.from, props.currentPeriod.to) + 1
        : 0;
    const targetWidth = Math.floor(((ResizeEvent.current.windowWidth - memberNamesWidth - 16 - scrollBarWidth) / dayCount) * 10) / 10;
    const dayWidth = Math.max(4 * 24, targetWidth);
    const contentWidth = dayWidth * dayCount;
    const getLineBackgroundColor = (i) => {
        return i % 2 === 0 ? '#FFFFFF' : '#EEEEEE';
    };
    const lastMoveX = useRef(0);
    const onMoveStartX = useRef();
    const lastMoveY = useRef(0);
    const onMoveStartY = useRef();
    const scrollContainerV = useRef();
    const scrollContainerH = useRef();
    const moveCSS = Platform.OS === 'web' ? { cursor: 'move' } : {};
    const webListener = {
        onMouseMove: (e) => {
            try {
                if (onMoveStartX.current != null) {
                    const movedX = e.pageX - lastMoveX.current;
                    if (Math.abs(movedX) > 5) {
                        const newScroll = {
                            x: onMoveStartX.current - movedX,
                            y: scrollPositionVertical,
                            animated: false,
                        };
                        scrollContainerH.current.scrollTo(newScroll);
                    }
                }
                if (onMoveStartY.current != null) {
                    const movedY = e.pageY - lastMoveY.current;
                    if (Math.abs(movedY) > 5) {
                        const newScroll = {
                            y: onMoveStartY.current - movedY,
                            x: scrollPositionHoriziontal,
                            animated: false,
                        };
                        scrollContainerV.current.scrollTo(newScroll);
                    }
                }
            }
            catch (err) {
                console.error(err);
            }
        },
        onMouseDown: (e) => {
            lastMoveX.current = e.pageX;
            lastMoveY.current = e.pageY;
            onMoveStartX.current = scrollPositionHoriziontal;
            onMoveStartY.current = scrollPositionVertical;
        },
        onMouseUp: (_e) => {
            onMoveStartX.current = undefined;
            onMoveStartY.current = undefined;
        },
        onMouseLeave: (_e) => {
            onMoveStartX.current = undefined;
            onMoveStartY.current = undefined;
        },
    };
    if (props.currentPeriod == null)
        return <Spinner />;
    const renderCalendarBackground = () => {
        const days = [<View style={{ width: memberNamesWidth, height: calendarHeaderHeight }}/>];
        for (const i = new Date(props.currentPeriod.from); i.getTime() < props.currentPeriod.to.getTime(); i.setDate(i.getDate() + 1)) {
            days.push(<View key={`day_${i.toISOString()}`} style={{
                    width: dayWidth,
                    height: props.maxHeight - scrollBarWidth,
                    ...ThemeManager.style.borderStyle,
                    borderWidth: 0,
                    borderRightWidth: 1,
                }}>
          <View style={{
                    width: dayWidth,
                    height: calendarHeaderHeight,
                    justifyContent: 'center',
                    ...ThemeManager.style.borderStyle,
                    borderWidth: 0,
                    borderRightWidth: 1,
                    borderBottomWidth: 1,
                }}>
            <MaterialText centeredBox>{i.getDate()}</MaterialText>
          </View>
        </View>);
        }
        return (<View style={{
                position: 'absolute',
                top: 0,
                left: -scrollPositionHoriziontal,
                height: props.maxHeight - scrollBarWidth,
                flexDirection: 'row',
                width: (days.length - 1) * dayWidth + memberNamesWidth,
                flexWrap: 'nowrap',
                backgroundColor: '#FAFAFA',
            }}>
        {days}
      </View>);
    };
    const renderUserNames = () => {
        const names = [];
        memberNames.forEach((a, i) => {
            const backgroundColor = getLineBackgroundColor(i);
            const user = a.userId != null && CachedEntities.knownUsers.has(a.userId)
                ? CachedEntities.knownUsers.get(a.userId)
                : new UserEntity({ firstname: a.firstName, lastname: a.lastName });
            names.push(<Ripple onPress={(e) => {
                    openCompanyMemberDialog({ memberId: a.id, currentList: Array.from(props.members.values()) })(e);
                }} key={`username_${a.id}`} style={{
                    width: memberNamesWidth,
                    height: itemHeight,
                    backgroundColor,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 8,
                    flexDirection: 'row',
                }}>
          <View style={{ width: 48 }}>
            <UserImage size={36} user={user} lightBox/>
          </View>
          <View style={{ flex: 1 }}>
            <MaterialText fixedWidth="100%" numberOfLines={1}>
              {a.firstName} {a.lastName}
            </MaterialText>
          </View>
        </Ripple>);
        });
        return (<View style={{
                height: props.maxHeight,
                overflow: 'hidden',
                width: memberNamesWidth,
                position: 'absolute',
                backgroundColor: '#FAFAFA',
                top: 0,
            }}>
        <View style={{
                height: contentHeight,
                width: memberNamesWidth,
                position: 'absolute',
                top: -scrollPositionVertical + calendarHeaderHeight,
                ...ThemeManager.style.borderStyle,
                borderWidth: 0,
                borderRightWidth: 1,
            }}>
          {names}
        </View>
      </View>);
    };
    const getHoursForDay = (date) => {
        const norm = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0);
        const midnight = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        return (norm.getTime() - midnight.getTime()) / (1000 * 60 * 60);
    };
    const renderEntries = () => {
        const entries = [];
        memberNames.forEach((a, i) => {
            const entriesForMember = [];
            props.entriesInDates.forEach((b) => {
                b.entries.forEach((c) => {
                    if (c.memberId === a.id) {
                        entriesForMember.push(c);
                    }
                });
            });
            const entriesForMemberViews = [];
            const top = 4;
            for (const d = new Date(props.currentPeriod.from); d.getTime() < props.currentPeriod.to.getTime(); d.setDate(d.getDate() + 1)) {
                const backgroundColor = getLineBackgroundColor(i);
                const dStart = 0;
                const dEnd = 24;
                const hourWidth = dayWidth / 24;
                const leftDay = EntriesPerUserForDay.dateDiffInDays(props.currentPeriod.from, d) * dayWidth + dStart * hourWidth;
                const width = (dEnd - dStart) * hourWidth;
                entriesForMemberViews.push(<View key={`day${a.id}_${a.id}_${leftDay.toString()}}`} pointerEvents="box-none" style={{
                        width,
                        height: itemHeight,
                        left: leftDay,
                        top: top - 4,
                        position: 'absolute',
                        backgroundColor,
                        ...ThemeManager.style.borderStyle,
                        borderWidth: 0,
                        borderRightWidth: 1,
                    }}/>);
            }
            entriesForMember.forEach((a) => {
                const dayLength = getHoursForDay(a.date);
                const hourWidth = dayWidth / dayLength;
                if (a.targetStart != null && a.targetEnd != null) {
                    const tStart = a.targetStart.getHours() + a.targetStart.getMinutes() / 60;
                    const tEnd = a.targetEnd.getHours() + a.targetEnd.getMinutes() / 60;
                    const left = EntriesPerUserForDay.dateDiffInDays(props.currentPeriod.from, a.date) * dayWidth + tStart * hourWidth;
                    const width = (tEnd - tStart) * hourWidth;
                    entriesForMemberViews.push(<Ripple key={`target_${a.id}_${a.memberId}_${a.targetStart.toString()}_${a.targetEnd.toString()}`} onPress={openTimeTrackingDayForUserDialog({ memberId: a.memberId, day: a.date, userId: a.userId })} toolTip={I18n.m.getMessage('timeTrackingExportExcelTargetTime')} style={{
                            width,
                            height: 8,
                            left,
                            top,
                            position: 'absolute',
                            backgroundColor: ThemeManager.style.brandPrimary,
                            borderRadius: ThemeManager.style.borderRadius,
                        }}/>);
                }
                const timeTrackingEntries = [];
                const absenceEntries = [];
                a.memberEntries.forEach((b) => {
                    if (b.type === 'TimeTrackingEntity' && !b.orgEntry['absenceId']) {
                        timeTrackingEntries.push(b);
                    }
                    else if (b.type === 'TimeTrackingEntity' &&
                        b.orgEntry['absence'] &&
                        b.orgEntry['absenceId'] &&
                        absenceEntries.findIndex((a) => a.orgEntry['absenceId'] === b.orgEntry['absenceId']) === -1) {
                        absenceEntries.push(b);
                    }
                });
                timeTrackingEntries.forEach((t) => {
                    if (t.starts != null && t.isBlocked !== 'rejected') {
                        const hStart = t.starts.getHours() + t.starts.getMinutes() / 60;
                        const ends = t.ends != null ? t.ends : new Date();
                        const hEnd = ends.getHours() + ends.getMinutes() / 60;
                        const left = EntriesPerUserForDay.dateDiffInDays(props.currentPeriod.from, t.starts) * dayWidth + hStart * hourWidth;
                        const width = (hEnd - hStart) * hourWidth;
                        let backgroundColor = ThemeManager.style.brandSuccess;
                        const borderColor = ThemeManager.style.brandSuccess;
                        if (t.isBlocked === 'open')
                            backgroundColor = Color(backgroundColor).alpha(0).toString();
                        entriesForMemberViews.push(<Ripple key={`time_${t.id}_${a.memberId}_${top}_${left}`} onPress={openTimeTrackingDialog({ entry: t })} toolTip={I18n.m.getMessage('timeTracking')} style={{
                                width,
                                height: itemHeight - 32,
                                left,
                                top: top + 16,
                                position: 'absolute',
                                backgroundColor,
                                borderRadius: ThemeManager.style.borderRadius,
                                borderWidth: 1,
                                borderColor,
                            }}/>);
                    }
                });
                absenceEntries.forEach((t) => {
                    if (t.orgEntry['absence'] != null && t.isBlocked !== 'rejected') {
                        const absence = t.orgEntry['absence'];
                        if (absence.state !== 'rejected') {
                            absence.starts = new Date(absence.starts);
                            absence.ends = new Date(absence.ends);
                            const daydiff = EntriesPerUserForDay.dateDiffInDays(absence.starts, absence.ends);
                            const hStart = absence.starts.getHours() + absence.starts.getMinutes() / 60;
                            const { ends } = absence;
                            const hEnd = ends.getHours() + ends.getMinutes() / 60;
                            const left = EntriesPerUserForDay.dateDiffInDays(props.currentPeriod.from, absence.starts) * dayWidth +
                                hStart * hourWidth;
                            const width = (hEnd - hStart + daydiff * 24) * hourWidth;
                            let backgroundColor = ThemeManager.style.brandDanger;
                            const borderColor = ThemeManager.style.brandDanger;
                            if (t.isBlocked === 'open')
                                backgroundColor = Color(backgroundColor).alpha(0).toString();
                            entriesForMemberViews.push(<Ripple key={`absence_${a.id}_${a.memberId}_${top}_${left}`} onPress={openTimeTrackingDialog({ entry: t })} toolTip={I18n.m.getMessage('absence')} style={{
                                    width,
                                    height: itemHeight - 32,
                                    left,
                                    top: top + 16,
                                    position: 'absolute',
                                    backgroundColor,
                                    ...ThemeManager.style.borderStyle,
                                    borderRadius: ThemeManager.style.borderRadius,
                                    borderWidth: 1,
                                    borderColor,
                                }}/>);
                        }
                    }
                });
            });
            const backgroundColor = getLineBackgroundColor(i);
            entries.push(<View pointerEvents="box-none" key={`memberEntries_${a.id}`} style={{ width: 'auto', height: itemHeight, flexDirection: 'row', justifyContent: 'center' }}>
          <View pointerEvents="box-none" style={{ backgroundColor, width: contentWidth, position: 'relative', height: itemHeight }}>
            {entriesForMemberViews}
          </View>
        </View>);
        });
        return (<View pointerEvents="box-none" style={{ height: contentHeight, width: 'auto', left: 0, position: 'absolute' }}>
        {entries}
      </View>);
    };
    const onScrollVertical = (e) => {
        setScrollPositionVertical(e.nativeEvent.contentOffset.y);
    };
    const onScrollHorizontal = (e) => {
        setScrollPositionHorizontal(e.nativeEvent.contentOffset.x);
    };
    return (<Card style={{ width: '100%' }}>
      <View style={{ position: 'relative', width: '100%', height: props.maxHeight, ...moveCSS }} {...webListener}>
        {renderCalendarBackground()}
        <ScrollView ref={scrollContainerH} scrollEventThrottle={1} nestedScrollEnabled horizontal showsVerticalScrollIndicator showsHorizontalScrollIndicator directionalLockEnabled={false} onScroll={onScrollHorizontal} style={{
            right: 0,
            position: 'absolute',
            top: calendarHeaderHeight,
            left: memberNamesWidth,
            height: props.maxHeight - calendarHeaderHeight,
        }} contentContainerStyle={{
            width: contentWidth,
            height: props.maxHeight - calendarHeaderHeight,
            position: 'relative',
        }}>
          <View style={{ width: contentWidth, height: props.maxHeight - calendarHeaderHeight }}/>
        </ScrollView>
        <ScrollView ref={scrollContainerV} pointerEvents="box-none" nestedScrollEnabled scrollEventThrottle={1} onScroll={onScrollVertical} style={{
            width: '100%',
            position: 'absolute',
            height: props.maxHeight - calendarHeaderHeight - scrollBarWidth,
            top: calendarHeaderHeight,
        }} contentContainerStyle={{ width: 1, position: 'relative' }}>
          <View pointerEvents="none" style={{ height: contentHeight, width: contentWidth }}/>
        </ScrollView>

        {renderUserNames()}
        <View pointerEvents="box-none" style={{
            position: 'absolute',
            left: memberNamesWidth,
            top: calendarHeaderHeight,
            bottom: scrollBarWidth,
            right: scrollBarWidth,
            overflow: 'hidden',
        }}>
          <View pointerEvents="box-none" style={{
            position: 'relative',
            height: contentHeight,
            width: contentWidth,
            top: -scrollPositionVertical,
            left: -scrollPositionHoriziontal,
        }}>
            {renderEntries()}
          </View>
        </View>
        <View style={{
            width: memberNamesWidth,
            height: calendarHeaderHeight,
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundColor: '#FAFAFA',
            ...ThemeManager.style.borderStyle,
            borderWidth: 0,
            borderRightWidth: 1,
            borderBottomWidth: 1,
        }}/>
      </View>
    </Card>);
}
