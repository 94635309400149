import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import React from 'react';
import { View } from 'react-native';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
export function CollaboratorThumb(props) {
    if (props.item == null)
        return <View />;
    const { style } = props.columnData;
    return (<View style={[
            {
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingRight: 16,
                paddingLeft: 16,
                flexDirection: 'row',
                direction: props.sLayout ? 'rtl' : 'ltr',
                overflow: 'hidden',
                maxWidth: '100%',
            },
            style,
        ]} key={`ViewAroundThumb${props.item.id}}`}>
      <View style={{ paddingRight: props.sLayout ? 0 : 8, paddingLeft: props.sLayout ? 8 : 0, paddingVertical: 4 }}>
        <UserImage size={36} user={props.item.user == null
            ? new UserEntity({ firstname: props.item.firstName, lastname: props.item.lastName })
            : props.item.user} lightBox/>
      </View>
    </View>);
}
