import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { Linking, View } from 'react-native';
import { I18n } from '../../../../i18n/I18n';
import { QRCodeView } from '../../../QRCodeView';
export function CompanyUserInformations(props) {
    const openUrl = (url) => (_e) => {
        Linking.openURL(url).catch((err) => console.debug(err));
    };
    const renderSpace = <View style={{ height: 8 }}/>;
    const renderPhone = (phone) => {
        if (phone == null || phone.length === 0) {
            return null;
        }
        return (<View key="phone" style={{
                width: '100%',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 8,
            }}>
        <View style={{
                flex: 1,
                alignContent: 'flex-start',
                justifyContent: 'flex-start',
            }}>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
            {I18n.m.getMessage('addressPhone')}
          </MaterialText>
          <MaterialText>{phone}</MaterialText>
        </View>
        <Icon onPress={openUrl(`tel:${phone}`)} icon="phone" color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('addressCall')} backgroundColor="#FFFFFF" borderStyle="solid"/>
      </View>);
    };
    const renderMail = (mails) => {
        if (mails == null || mails.length === 0) {
            return null;
        }
        return (<View key="mail" style={{
                width: '100%',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 8,
            }}>
        <View style={{
                flex: 1,
                alignContent: 'flex-start',
                justifyContent: 'flex-start',
            }}>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
            {I18n.m.getMessage('email')}
          </MaterialText>
          <MaterialText>{mails.join(', ')}</MaterialText>
        </View>
        <Icon onPress={openUrl(`mailto:${mails[0]}`)} icon="email-outline" color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('email')} backgroundColor="#FFFFFF" borderStyle="solid"/>
      </View>);
    };
    return (<View style={{ width: '100%', maxWidth: 512, alignSelf: 'center' }}>
      <View style={{
            flexDirection: 'column',
            padding: ThemeManager.style.getScreenRelativePixelSize(16),
            width: '100%',
        }} pointerEvents="box-none">
        {props.user.user && (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <View style={{
                borderRadius: ThemeManager.style.getScreenRelativePixelSize(64),
                width: ThemeManager.style.getScreenRelativePixelSize(128),
                alignSelf: 'center',
            }}>
              <UserImage lightBox size={128} user={props.user.user}/>
            </View>
            <View style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
            }} pointerEvents="box-none">
              <QRCodeView size={128} user={props.user.user} hideButton/>
            </View>
          </View>)}
        <View style={{
            width: '100%',
            paddingTop: ThemeManager.style.getScreenRelativePixelSize(16),
        }}>
          <MaterialText type={MaterialTextTypes.H6}>{`${props.user.getName()}`}</MaterialText>
          {renderSpace}
          {props.user.user && props.user.user.phone && renderPhone(props.user.user.phone)}
          {renderSpace}
          {renderPhone(props.user.member.phone)}
          {renderSpace}
          {props.user.user && renderMail(props.user.user.emails)}
        </View>
      </View>
    </View>);
}
