"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CombinedExtraPayAndTimeTrackingFilter = exports.CombinedExtraPayAndTimeTrackingEntity = void 0;
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var AuthClient_1 = require("upmesh-auth-core/build/src/client/AuthClient");
var UserEntity_1 = require("upmesh-auth-core/build/src/client/query/entities/UserEntity");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var CostCenterEntity_1 = require("../entities/CostCenterEntity");
var HolidayEntity_1 = require("../entities/HolidayEntity");
var TaskEntity_1 = require("../entities/TaskEntity");
var TimeTrackingEntity_1 = require("../entities/TimeTrackingEntity");
var WorkingTimeModelEntity_1 = require("../entities/WorkingTimeModelEntity");
var Filter_1 = require("./Filter");
var CombinedExtraPayAndTimeTrackingEntity = function () {
  function CombinedExtraPayAndTimeTrackingEntity() {
    (0, _classCallCheck2.default)(this, CombinedExtraPayAndTimeTrackingEntity);
    this.creatorName = '';
    this.memberName = '?';
    this.lastName = '?';
    this.firstName = '?';
    this.staffNumber = '?';
    this.memberId = '';
    this.deleted = false;
    this.sumInMS = 0;
    this.startTimeInMS = 0;
    this.pauseInMS = 0;
  }
  return (0, _createClass2.default)(CombinedExtraPayAndTimeTrackingEntity, [{
    key: "currentInMS",
    get: function get() {
      if (this.type === 'TimeTrackingEntity' && this.ends == null && this.orgEntry.status === 'Running') {
        var e = this.orgEntry;
        var pause = e.pause ? e.pause.minutes * 60 * 1000 + e.pause.hours * 60 * 60 * 1000 : 0;
        var workingMS = new Date().getTime() - new Date(e.starts).getTime();
        return workingMS - pause;
      }
      return 0;
    }
  }], [{
    key: "getUserById",
    value: function () {
      var _getUserById = (0, _asyncToGenerator2.default)(function* (userId) {
        if (userId !== 'all') {
          try {
            var user = yield AuthClient_1.AuthClient.instance.modals.user.getById(userId);
            return user;
          } catch (e) {}
        }
        return undefined;
      });
      function getUserById(_x) {
        return _getUserById.apply(this, arguments);
      }
      return getUserById;
    }()
  }, {
    key: "isCollidingWithList",
    value: function isCollidingWithList(entry, entries) {
      try {
        if (entry.type === 'TimeTrackingEntity' && entry.starts != null) {
          var start = new Date(entry.starts);
          var end = entry.ends == null ? new Date(start.getTime() + 60 * 1000) : new Date(entry.ends);
          return entries.filter(function (element) {
            if (element.isBlocked === 'rejected') return false;
            if (element.deleted) return false;
            if (element.id === entry.id) return false;
            if (element.ends != null && end != null && element.starts != null && start != null) {
              element.starts = new Date(element.starts);
              element.ends = new Date(element.ends);
              if (start.getTime() >= element.ends.getTime()) return false;
              if (element.starts.getTime() >= end.getTime()) return false;
              return true;
            }
            return false;
          });
        }
      } catch (err) {}
      return [];
    }
  }, {
    key: "getCurrentPaymentPeriod",
    value: function () {
      var _getCurrentPaymentPeriod = (0, _asyncToGenerator2.default)(function* (online, companySettings) {
        var todayMorning = new Date();
        var now = todayMorning.toISOString();
        todayMorning.setHours(0, 0, 0, 0);
        var thisPeroid = {
          from: new Date(todayMorning.getFullYear(), todayMorning.getMonth(), 1, 0, 0, 0, 0),
          to: new Date(todayMorning.getFullYear(), todayMorning.getMonth() + 1, 1, 0, 0, 0, -1)
        };
        try {
          if (online) {
            var d = yield CommandReadModels_1.CommandReadModels.payrollAccountingPeriods.get({
              filter: `starts ge ${now} and end le ${now}`
            });
            if (d.length > 0) return {
              from: new Date(d[0].starts),
              to: new Date(d[0].ends),
              period: d[0]
            };
          }
          if (companySettings && companySettings.billingDate != null && companySettings.billingDate > 1) {
            var from = new Date(todayMorning.getFullYear(), todayMorning.getMonth(), companySettings.billingDate, 0, 0, 0, 0);
            if (from.getTime() > todayMorning.getTime()) {
              from.setMonth(from.getMonth() - 1);
            }
            thisPeroid = {
              from: from,
              to: new Date(from.getFullYear(), from.getMonth() + 1, from.getDate(), 0, 0, 0, -1)
            };
          }
        } catch (err) {}
        return thisPeroid;
      });
      function getCurrentPaymentPeriod(_x2, _x3) {
        return _getCurrentPaymentPeriod.apply(this, arguments);
      }
      return getCurrentPaymentPeriod;
    }()
  }, {
    key: "getWorkingTimeModelForMember",
    value: function () {
      var _getWorkingTimeModelForMember = (0, _asyncToGenerator2.default)(function* (day, memberId) {
        if (memberId != null) {
          var member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(memberId);
          if (member != null && (member.workingTimeModelId != null || member.workingTimeModels != null)) {
            try {
              var workinTimeModel;
              if (member.workingTimeModels != null && member.workingTimeModels.length > 0) {
                var workinTimeModels = [];
                for (var i of member.workingTimeModels) {
                  var w = yield CommandReadModels_1.CommandReadModels.workingTimeModel.getById(i.workingTimeModelId);
                  workinTimeModels.push({
                    w: w,
                    validFrom: new Date(i.validFromDate)
                  });
                }
                workinTimeModels.sort(function (a, b) {
                  return a.validFrom.getTime() - b.validFrom.getTime();
                });
                workinTimeModels.forEach(function (a) {
                  if (a.validFrom.getTime() <= day.getTime()) {
                    workinTimeModel = a.w;
                  }
                });
              } else if (member.workingTimeModelId != null) {
                workinTimeModel = yield CommandReadModels_1.CommandReadModels.workingTimeModel.getById(member.workingTimeModelId);
              }
              if (workinTimeModel == null) return {
                targetTimeInMS: 0,
                holidayTimeInMS: 0
              };
              var d = workinTimeModel.getCorrectDaytime(day);
              var targetStart;
              var targetEnd;
              var targetPause;
              if (d != null) {
                if (d.start != null) targetStart = WorkingTimeModelEntity_1.Daytime.toDate(d.start);
                if (d.stop != null) targetEnd = WorkingTimeModelEntity_1.Daytime.toDate(d.stop);
                if (d.pause != null) targetPause = WorkingTimeModelEntity_1.Daytime.toDate(d.pause);
              }
              var targetTimeInMS = workinTimeModel.getTargetSumInMs(day);
              var holidayTimeInMS = 0;
              var holidays = yield HolidayEntity_1.HolidayEntity.getHolidayForDayAndUser(member, day);
              if (holidays.length > 0) {
                var maxHoliday = holidays[0];
                holidays.forEach(function (holiday) {
                  if (holiday.type === 'day') {
                    maxHoliday = holiday;
                  } else if (holiday.amount > maxHoliday.amount) {
                    maxHoliday = holiday;
                  }
                });
                if (maxHoliday.type === 'day') {
                  holidayTimeInMS = 24 * 60 * 60 * 1000;
                } else {
                  holidayTimeInMS = maxHoliday.amount * 60 * 60 * 1000;
                }
              }
              return {
                workinTimeModel: workinTimeModel,
                targetStart: targetStart,
                targetEnd: targetEnd,
                targetPause: targetPause,
                targetTimeInMS: targetTimeInMS != null ? targetTimeInMS : 0,
                holidayTimeInMS: holidayTimeInMS
              };
            } catch (err) {}
          }
        }
        return {
          targetTimeInMS: 0,
          holidayTimeInMS: 0
        };
      });
      function getWorkingTimeModelForMember(_x4, _x5) {
        return _getWorkingTimeModelForMember.apply(this, arguments);
      }
      return getWorkingTimeModelForMember;
    }()
  }, {
    key: "convertFromPayroll",
    value: function () {
      var _convertFromPayroll = (0, _asyncToGenerator2.default)(function* (entry, userCache, taskCache, memberCache, extraPayCache) {
        var c = new CombinedExtraPayAndTimeTrackingEntity();
        c.projectId = entry.projectId;
        c.lastModifiedAt = entry.lastModifiedAt;
        c.id = entry.id;
        c.note = entry.notes;
        c.exported = entry.exported;
        c.type = entry.origin === 'extraPayTracking' ? 'ExtraPayTrackingEntity' : 'TimeTrackingEntity';
        c.starts = entry.from;
        c.ends = entry.to;
        c.day = entry.day ? new Date(entry.day) : new Date(entry.from ? entry.from : new Date());
        c.memberId = entry.memberId;
        c.originId = entry.originId && entry.originId.length > 0 ? entry.originId : undefined;
        var cachedMember = memberCache == null ? void 0 : memberCache.get(entry.memberId);
        var member = cachedMember || (yield ReadModels_1.ReadModels.get('CompanyMember').getById(entry.memberId));
        c.userId = member.userId == null ? member.id : member.userId;
        var task = new TaskEntity_1.TaskEntity({
          mark: '',
          description: ''
        });
        if (entry.taskId != null && entry.taskId.length > 0) {
          task = taskCache != null && taskCache.has(entry.taskId) ? taskCache.get(entry.taskId) : yield ReadModels_1.ReadModels.get('Task').getById(entry.taskId);
        }
        c.taskId = entry.taskId;
        c.taskName = task.description;
        c.taskMark = task.mark;
        c.taskNameFull = task.getName();
        c.wageType = entry.wageType;
        c.followUpWageType1 = entry.followUpWageType1;
        c.followUpWageType2 = entry.followUpWageType2;
        c.sumInMS = entry.sumInMS;
        c.extraFieldOrgValue = entry.extraFieldOrgValue;
        if (entry.from) {
          var s = new Date(entry.from);
          c.startTimeInMS = new Date(1970, 1, 1, s.getHours(), s.getMinutes(), s.getSeconds(), s.getMilliseconds()).getTime();
        }
        c.pause = entry.pause;
        if (entry.pause) c.pauseInMS = (60 * entry.pause.hours + entry.pause.minutes) * 60000;
        if (entry.origin === 'extraPayTracking' && entry.originId) {
          var org = yield ReadModels_1.ReadModels.get('ExtraPayTracking').getById(entry.originId);
          c.orgEntry = org;
          c.extraPayTitle = '?';
          c.extraFieldValue = '-';
          try {
            if (org.extraPayId != null) {
              c.extraPayId = org.extraPayId;
              var extraPay = extraPayCache != null && extraPayCache.has(org.memberId) ? extraPayCache.get(org.extraPayId) : yield ReadModels_1.ReadModels.get('ExtraPay').getById(org.extraPayId);
              c.extraPayTitle = `${extraPay.mark} - ${extraPay.description}`;
              if (extraPay.fieldType === 'number' && org.extraField != null) {
                var _extraPay$field;
                c.extraFieldValue = `${org.extraField.toString()} ${(_extraPay$field = extraPay.field) == null ? void 0 : _extraPay$field.unit}`;
              } else if (extraPay.fieldType === 'duration' && org.extraField != null) {
                var ef = org.extraField;
                c.extraFieldValue = `${WorkingTimeModelEntity_1.Daytime.toString(ef.from)} - ${WorkingTimeModelEntity_1.Daytime.toString(ef.to)}`;
              }
              c.wageType = extraPay.wageType;
            }
          } catch (err) {}
        } else if (entry.origin === 'timeTracking' && entry.originId) {
          var _org = yield ReadModels_1.ReadModels.get('TimeTracking').getById(entry.originId);
          c.orgEntry = _org;
        } else if (entry.origin === 'absence' && entry.originId) {
          var _org2 = yield ReadModels_1.ReadModels.get('Absence').getById(entry.originId);
          c.orgEntry = new TimeTrackingEntity_1.TimeTrackingEntity(Object.assign({}, entry, {
            absence: _org2,
            absenceId: _org2.id
          }));
        }
        c.costCenterId = entry.costCenterId;
        c.costCenterDescription = entry.costCenterDescription;
        c.costCenterMark = entry.costCenterMark;
        c.costCenterName = new CostCenterEntity_1.CostCenterEntity({
          id: entry.costCenterId,
          mark: entry.costCenterMark,
          description: entry.costCenterDescription
        }).getName();
        try {
          c.memberName = `${member.firstName} ${member.lastName}`;
          if (member.lastName) c.lastName = member.lastName;
          if (member.firstName) c.firstName = member.firstName;
          if (member.staffNumber) c.staffNumber = member.staffNumber;
          c.user = new UserEntity_1.UserEntity({
            firstname: member.firstName,
            lastname: member.lastName,
            id: member.id
          });
          if (member.userId != null) {
            var user = userCache != null && userCache.has(member.userId) ? userCache.get(member.userId) : yield CombinedExtraPayAndTimeTrackingEntity.getUserById(member.userId);
            if (user) {
              c.memberName = user.getFullName();
              if (user.firstname) c.firstName = user.firstname;
              if (user.lastname) c.lastName = user.lastname;
              c.user = user;
            }
          }
        } catch (err) {}
        return c;
      });
      function convertFromPayroll(_x6, _x7, _x8, _x9, _x10) {
        return _convertFromPayroll.apply(this, arguments);
      }
      return convertFromPayroll;
    }()
  }, {
    key: "convert",
    value: function () {
      var _convert = (0, _asyncToGenerator2.default)(function* (entry, userCache, taskCache, memberCache, extraPayCache, costCenterCache) {
        var c = new CombinedExtraPayAndTimeTrackingEntity();
        c.projectId = entry.projectId;
        c.projectTitle = entry.projectTitle;
        c.status = entry.status;
        c.deleted = entry.deleted;
        c.foreignId = entry.foreignId;
        c.orgEntry = entry;
        c.id = entry.id;
        c.note = entry['note'] != null ? entry['note'] : entry['notes'] != null ? entry['notes'] : null;
        c.nextApprovalStepFor = entry.nextApprovalStepFor;
        c.approvalProcessId = entry.approvalProcessId;
        c.currentApprovalProcessStep = entry.currentApprovalProcessStep;
        c.isBlocked = entry.isBlocked;
        var member;
        if (entry['starts'] != null) {
          var e = entry;
          c.type = 'TimeTrackingEntity';
          c.day = e.starts;
          member = yield e.getMember();
          c.memberId = member.id;
          c.userId = member.userId == null ? member.id : member.userId;
          var task = new TaskEntity_1.TaskEntity({
            mark: '',
            description: ''
          });
          if (e.taskId != null && e.taskId.length > 0) {
            task = taskCache != null && taskCache.has(e.taskId) ? taskCache.get(e.taskId) : yield ReadModels_1.ReadModels.get('Task').getById(e.taskId);
          }
          c.taskId = e.taskId;
          c.taskName = task.description;
          c.taskMark = task.mark;
          c.taskNameFull = task.getName();
          c.wageType = task.wageType;
          c.followUpWageType1 = task.followUpWageType1;
          c.followUpWageType2 = task.followUpWageType2;
          if (e['ends'] != null) {
            var pause = e.pause ? e.pause.minutes * 60 * 1000 + e.pause.hours * 60 * 60 * 1000 : 0;
            var workingMS = new Date(e.ends).getTime() - new Date(e.starts).getTime();
            if (!e.absendeOvertimeReduction) {
              c.sumInMS = workingMS - pause;
            } else {
              c.sumInMS = 0;
            }
            c.ends = new Date(e.ends);
          }
          if (e['starts'] != null) {
            var s = new Date(e['starts']);
            c.startTimeInMS = new Date(1970, 1, 1, s.getHours(), s.getMinutes(), s.getSeconds(), s.getMilliseconds()).getTime();
            c.starts = s;
          }
          c.pause = e.pause;
          if (e['pause'] != null) c.pauseInMS = (60 * e.pause.hours + e.pause.minutes) * 60000;
        } else {
          var _e = entry;
          c.type = 'ExtraPayTrackingEntity';
          c.note = _e.notes;
          c.day = _e.day;
          member = memberCache != null && memberCache.has(_e.memberId) ? memberCache.get(_e.memberId) : yield ReadModels_1.ReadModels.get('CompanyMember').getById(_e.memberId);
          c.memberId = _e.memberId;
          c.userId = member.userId == null ? member.id : member.userId;
          c.extraPayTitle = '?';
          c.extraFieldValue = '-';
          c.extraFieldOrgValue = _e.extraField;
          try {
            if (_e.extraPayId != null) {
              c.extraPayId = _e.extraPayId;
              var extraPay = extraPayCache != null && extraPayCache.has(_e.memberId) ? extraPayCache.get(_e.extraPayId) : yield ReadModels_1.ReadModels.get('ExtraPay').getById(_e.extraPayId);
              c.extraPayTitle = `${extraPay.mark} - ${extraPay.description}`;
              if (extraPay.fieldType === 'number' && _e.extraField != null) {
                var _extraPay$field2;
                c.extraFieldValue = `${_e.extraField.toString()} ${(_extraPay$field2 = extraPay.field) == null ? void 0 : _extraPay$field2.unit}`;
              } else if (extraPay.fieldType === 'duration' && _e.extraField != null) {
                var ef = _e.extraField;
                c.extraFieldValue = `${WorkingTimeModelEntity_1.Daytime.toString(ef.from)} - ${WorkingTimeModelEntity_1.Daytime.toString(ef.to)}`;
              }
              c.wageType = extraPay.wageType;
            }
          } catch (err) {}
        }
        var costCenterName = '';
        var projectId = entry.projectId,
          costCenterId = entry.costCenterId;
        try {
          if (costCenterId != null && costCenterId.length > 0) {
            var costCenter = costCenterCache != null && costCenterCache.has(costCenterId) ? costCenterCache.get(costCenterId) : yield ReadModels_1.ReadModels.get('CostCenter').getById(costCenterId);
            costCenterName = costCenter.getName();
            c.costCenterId = costCenter.id;
            c.costCenterDescription = costCenter.description;
            c.costCenterMark = costCenter.mark;
          } else if (projectId != null && projectId.length > 0) {
            var costCenters = yield ReadModels_1.ReadModels.get('CostCenter').get({
              filter: `companyId eq '${entry.companyId}' and deleted ne true`
            });
            for (var _c of costCenters) {
              if (_c.projectIds != null && _c.projectIds.length > 0 && _c.projectIds.includes(projectId)) {
                costCenterName = _c.getName();
                _c.costCenterId = _c.id;
                _c.costCenterDescription = _c.description;
                _c.costCenterMark = _c.mark;
                break;
              }
            }
          }
        } catch (err) {}
        c.costCenterName = costCenterName;
        try {
          if (entry.createdBy !== 'all') {
            var creator = yield CombinedExtraPayAndTimeTrackingEntity.getUserById(entry.createdBy);
            if (creator != null) c.creatorName = creator.getFullName();
          }
        } catch (err) {}
        try {
          c.memberName = `${member.firstName} ${member.lastName}`;
          if (member.lastName) c.lastName = member.lastName;
          if (member.firstName) c.firstName = member.firstName;
          if (member.staffNumber) c.staffNumber = member.staffNumber;
          c.user = new UserEntity_1.UserEntity({
            firstname: member.firstName,
            lastname: member.lastName,
            id: member.id
          });
          if (member.userId != null) {
            var user = userCache != null && userCache.has(member.userId) ? userCache.get(member.userId) : yield CombinedExtraPayAndTimeTrackingEntity.getUserById(member.userId);
            if (user) {
              c.memberName = user.getFullName();
              if (user.firstname) c.firstName = user.firstname;
              if (user.lastname) c.lastName = user.lastname;
              c.user = user;
            }
          }
        } catch (err) {}
        return c;
      });
      function convert(_x11, _x12, _x13, _x14, _x15, _x16) {
        return _convert.apply(this, arguments);
      }
      return convert;
    }()
  }]);
}();
exports.CombinedExtraPayAndTimeTrackingEntity = CombinedExtraPayAndTimeTrackingEntity;
var CombinedExtraPayAndTimeTrackingFilter = function () {
  function CombinedExtraPayAndTimeTrackingFilter() {
    (0, _classCallCheck2.default)(this, CombinedExtraPayAndTimeTrackingFilter);
  }
  return (0, _createClass2.default)(CombinedExtraPayAndTimeTrackingFilter, null, [{
    key: "isSet",
    value: function isSet(filter) {
      return filter != null && (filter.dateTo != null || filter.status != null && filter.status.length > 0 || filter.date != null || filter.userIds != null && filter.userIds.length > 0 || filter.projectIds != null && filter.projectIds.length > 0 || filter.costCenterIds != null && filter.costCenterIds.length > 0 || filter.extraPayIds != null && filter.extraPayIds.length > 0 || filter.taskIds != null && filter.taskIds.length > 0);
    }
  }, {
    key: "filterTimeTrackingByText",
    value: function filterTimeTrackingByText(text, tt) {
      var words = text.trim().split(' ');
      if (tt == null || tt.length === 0) {
        return tt;
      }
      var filterByNote = function filterByNote(element, _index, _array) {
        if (element.note != null && element.note.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.note.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      var filterByMemberName = function filterByMemberName(element, _index, _array) {
        if (element.memberName != null && element.memberName.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.memberName.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      var filterByCostCenterName = function filterByCostCenterName(element, _index, _array) {
        if (element.costCenterName != null && element.creatorName.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.costCenterName.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      var filterByCreatorName = function filterByCreatorName(element, _index, _array) {
        if (element.creatorName != null && element.creatorName.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.creatorName.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      var filterByTask = function filterByTask(element, _index, _array) {
        var taskName = element['taskName'] ? element['taskName'] : null;
        if (taskName != null && taskName.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = taskName.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      var filterByProject = function filterByProject(element, _index, _array) {
        if (element.projectTitle != null && element.projectTitle.length > 0) {
          var matches = 0;
          for (var i = 0; i < words.length; i += 1) {
            var w = words[i].trim();
            if (w.length > 0) {
              var regexp = new RegExp(Filter_1.Filter.escapeRegExp(w), 'i');
              var matchN = element.projectTitle.match(regexp);
              if (matchN != null && matchN.length > 0) {
                matches += 1;
              }
            } else {
              matches += 1;
            }
          }
          return matches === words.length;
        }
        return false;
      };
      if (text != null && text.length > 0) {
        var filteredByNotes = tt.filter(filterByNote);
        var filteredByMemberNames = tt.filter(filterByMemberName);
        var filteredByCreatorNames = tt.filter(filterByCreatorName);
        var filteredByCostCenterNames = tt.filter(filterByCostCenterName);
        var filteredByTasks = tt.filter(filterByTask);
        var filteredByProjects = tt.filter(filterByProject);
        var result = [].concat((0, _toConsumableArray2.default)(filteredByNotes), (0, _toConsumableArray2.default)(filteredByMemberNames), (0, _toConsumableArray2.default)(filteredByCreatorNames), (0, _toConsumableArray2.default)(filteredByCostCenterNames), (0, _toConsumableArray2.default)(filteredByTasks), (0, _toConsumableArray2.default)(filteredByProjects));
        var counts = [];
        result.forEach(function (item) {
          if (counts[item.id] === undefined) {
            counts[item.id] = 0;
          }
          counts[item.id] += 1;
          return counts;
        });
        var unique = result.filter(function (x, i, l) {
          return l.indexOf(x) === i;
        });
        var sorted = unique.sort(function (v1, v2) {
          return counts[v2.id] - counts[v1.id];
        });
        return sorted;
      }
      return tt;
    }
  }, {
    key: "filterByMember",
    value: function filterByMember(memberIds, tt) {
      if (memberIds.length === 0) return tt;
      return tt.filter(function (t) {
        return t.memberId != null && memberIds.includes(t.memberId);
      });
    }
  }, {
    key: "filterByUser",
    value: function filterByUser(userIds, tt) {
      if (userIds.length === 0) return tt;
      return tt.filter(function (t) {
        return t.userId != null && userIds.includes(t.userId);
      });
    }
  }, {
    key: "filterByProjects",
    value: function filterByProjects(projectIds, tt) {
      if (projectIds.length === 0) return tt;
      return tt.filter(function (t) {
        return t.projectId == null ? false : projectIds.includes(t.projectId);
      });
    }
  }, {
    key: "filterByStatus",
    value: function filterByStatus(status, tt) {
      if (status == null || status.length === 0) return tt;
      return tt.filter(function (t) {
        return status.includes(t.status);
      });
    }
  }, {
    key: "filterByDateRange",
    value: function filterByDateRange(tt, from, to) {
      var dateFrom = from != null ? new Date(from) : null;
      var dateTo = to != null ? new Date(to) : null;
      var start = dateFrom == null ? null : new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), 0, 0, 0, 0);
      var end = dateTo == null ? null : new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 23, 59, 59, 59);
      return tt.filter(function (t) {
        return (start == null || t.day >= start && t.day) && (end == null || t.day != null && t.day < end);
      });
    }
  }, {
    key: "filterByTaskId",
    value: function filterByTaskId(tt, taskIds) {
      return tt.filter(function (t) {
        var taskId = t['taskId'] != null ? t['taskId'] : null;
        if (taskId != null) {
          return taskIds.findIndex(function (elem) {
            return elem === taskId;
          }) >= 0;
        }
        return false;
      });
    }
  }, {
    key: "filterByCostCenters",
    value: function filterByCostCenters(costCenterIds, tt) {
      if (costCenterIds.length === 0) return tt;
      return tt.filter(function (t) {
        return t.costCenterId == null ? false : costCenterIds.includes(t.costCenterId);
      });
    }
  }, {
    key: "filterByExtraPayId",
    value: function filterByExtraPayId(tt, extraPayIds) {
      return tt.filter(function (t) {
        if (t.extraPayId != null && t.extraPayId.length > 0) {
          return extraPayIds.findIndex(function (elem) {
            return elem === t.extraPayId;
          }) >= 0;
        }
        return false;
      });
    }
  }]);
}();
exports.CombinedExtraPayAndTimeTrackingFilter = CombinedExtraPayAndTimeTrackingFilter;
CombinedExtraPayAndTimeTrackingFilter.filterTimeTrackings = function (tts, filter) {
  var projectIds = filter.projectIds,
    date = filter.date,
    words = filter.words,
    dateTo = filter.dateTo,
    status = filter.status,
    taskIds = filter.taskIds,
    memberIds = filter.memberIds,
    userIds = filter.userIds,
    costCenterIds = filter.costCenterIds,
    extraPayIds = filter.extraPayIds;
  var filtered = (0, _toConsumableArray2.default)(tts);
  if (memberIds != null) filtered = CombinedExtraPayAndTimeTrackingFilter.filterByMember(memberIds, filtered);
  if (userIds != null) filtered = CombinedExtraPayAndTimeTrackingFilter.filterByUser(userIds, filtered);
  if (projectIds != null) filtered = CombinedExtraPayAndTimeTrackingFilter.filterByProjects(projectIds, filtered);
  if (costCenterIds != null) filtered = CombinedExtraPayAndTimeTrackingFilter.filterByCostCenters(costCenterIds, filtered);
  if (status != null && status.length > 0) filtered = CombinedExtraPayAndTimeTrackingFilter.filterByStatus(status, filtered);
  if (date != null || dateTo != null) filtered = CombinedExtraPayAndTimeTrackingFilter.filterByDateRange(filtered, date, dateTo);
  if (words != null && words.length > 0) {
    filtered = CombinedExtraPayAndTimeTrackingFilter.filterTimeTrackingByText(words, filtered);
  }
  if (taskIds != null && taskIds.length > 0) filtered = CombinedExtraPayAndTimeTrackingFilter.filterByTaskId(filtered, taskIds);
  if (extraPayIds != null && extraPayIds.length > 0) filtered = CombinedExtraPayAndTimeTrackingFilter.filterByExtraPayId(filtered, extraPayIds);
  return filtered;
};