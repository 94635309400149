var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MaterialTextEditableNumber = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _ScrollHandler = require("../scroll/ScrollHandler");
var _Measurement = require("../utils/Measurement");
var _MaterialText = require("./MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var MaterialTextEditableNumber = exports.MaterialTextEditableNumber = function (_PureComponent) {
  function MaterialTextEditableNumber(props) {
    var _this;
    (0, _classCallCheck2.default)(this, MaterialTextEditableNumber);
    _this = _callSuper(this, MaterialTextEditableNumber, [props]);
    _this.componentHeight = {
      height: 0,
      width: 0,
      x: 0,
      y: 0,
      pageX: 0,
      pageY: 0
    };
    _this.init = (0, _asyncToGenerator2.default)(function* () {
      if (_this.outerView != null) _this.componentHeight = yield _Measurement.Measurement.measure(_this.outerView);
    });
    _this.abort = function () {
      var abortValue = _this.state.abortValue;
      _this.setState({
        currentValue: abortValue.toString(10)
      });
      _this.blur();
    };
    _this.onActivateEdit = function () {
      _this.focus();
    };
    _this.blockBlurOnce = false;
    _this.onBlur = function (e) {
      if (_this.blockBlurOnce) {
        _this.blockBlurOnce = false;
        return;
      }
      setTimeout(function () {
        _this.setState({
          editMode: false,
          isFocused: false
        });
        if (_reactNativeWeb.Platform.OS !== 'web' || e != null && !_this.isNewFocusInMaterialTextV2ActionArea(e)) {
          _this.onSaved();
        }
      }, 150);
    };
    _this.onChangeText = function (currentValue, callBack) {
      _this.setState({
        currentValue: currentValue
      }, function () {
        if (_this.props.onChange != null) {
          _this.props.onChange(currentValue);
        }
        if (callBack != null) {
          callBack();
        }
      });
    };
    _this.onContentSizeChange = function (e) {
      if (_this.props.materailTextProps != null && _this.props.materailTextProps.numberOfLines !== 1) {
        if (e && e.nativeEvent) {
          var textFieldHeight = _this.state.textFieldHeight;
          var contentHeight = textFieldHeight;
          if (!textFieldHeight) return;
          if (e.nativeEvent.contentSize && e.nativeEvent.contentSize.height && e.nativeEvent.contentSize.height !== textFieldHeight) {
            contentHeight = e.nativeEvent.contentSize.height;
            _this.setState({
              textFieldHeight: contentHeight
            });
          } else if (e.nativeEvent.target && e.nativeEvent.target.scrollHeight) {
            var target = e.nativeEvent.target;
            _this.setState({
              textFieldHeight: 0
            }, function () {
              _this.setState({
                textFieldHeight: target.scrollHeight + 4
              });
            });
          }
        }
      }
    };
    _this.onFocus = function () {
      if (_this.props.onFocus != null) {
        _this.props.onFocus();
      }
      _this.setState({
        isFocused: true
      });
    };
    _this.onLayout = function (e) {
      if (e && e.nativeEvent && e.nativeEvent.layout) {
        var layout = e.nativeEvent.layout;
        _this.setState({
          textFieldHeight: layout.height
        });
      }
    };
    _this.isNewFocusInMaterialTextV2ActionArea = function (_ref2) {
      var relatedTarget = _ref2.relatedTarget;
      if (!relatedTarget) return false;
      var node = relatedTarget.parentNode;
      while (node) {
        if (node.id === 'MATERIAL-TEXT-V2-ACTION-AREA-ID') return true;
        node = node.parentNode;
      }
      return false;
    };
    _this.save = function () {
      _this.onSaved();
    };
    var editMode = props.autoFocus === true || _this.state != null && _this.state.editMode;
    _this.state = {
      editMode: editMode,
      isFocused: false,
      currentValue: _this.getCurrentValue(props.children),
      abortValue: Number.parseFloat(props.children),
      autoCompleteSuggestions: _this.createAutoCompleteSuggestions(props.autoCompleteList, props.children),
      selection: {
        start: 0,
        end: 0
      }
    };
    return _this;
  }
  (0, _inherits2.default)(MaterialTextEditableNumber, _PureComponent);
  return (0, _createClass2.default)(MaterialTextEditableNumber, [{
    key: "getCurrentValue",
    value: function getCurrentValue(value) {
      var val = typeof value === 'number' ? value.toString(10) : value;
      return this.props.formatNumber ? this.props.formatNumber(Number.parseFloat(val)) : Number.parseFloat(val).toString(10);
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      this.init().catch(function (err) {
        return void 0;
      });
    }
  }, {
    key: "blur",
    value: function blur() {
      var _this2 = this;
      this.setState({
        editMode: false
      }, function () {
        _this2.onBlur();
      });
    }
  }, {
    key: "scrollTo",
    value: function () {
      var _scrollTo = (0, _asyncToGenerator2.default)(function* () {
        var scrollContainerId = this.props.scrollContainerId;
        var containerId = scrollContainerId != null ? scrollContainerId : _ScrollHandler.ScrollHandler.getLastAddedScrollContainer();
        if (containerId != null && this.outerView != null) {
          _ScrollHandler.ScrollHandler.scrollTo({
            containerId: containerId,
            animated: true,
            x: 0,
            y: 0,
            ref: this.outerView
          });
        }
      });
      function scrollTo() {
        return _scrollTo.apply(this, arguments);
      }
      return scrollTo;
    }()
  }, {
    key: "focus",
    value: function focus() {
      var currentValue = this.state.currentValue;
      var selection = {
        start: currentValue.toString().length,
        end: currentValue.toString().length
      };
      this.scrollTo().catch(function (err) {
        return void 0;
      });
      this.setState({
        editMode: true,
        selection: selection
      });
    }
  }, {
    key: "createAutoCompleteSuggestions",
    value: function createAutoCompleteSuggestions(autoCompleteList, value) {
      var suggestions = [];
      if (autoCompleteList != null) {
        var autoCompletes = this.sortAutoComplete(autoCompleteList);
        if (value != null && value.length > 0) {
          for (var i = 0; i < autoCompletes.length; i += 1) {
            var autoCompleteString = typeof autoCompletes[i] !== 'string' ? autoCompletes[i].title : autoCompletes[i];
            var regexp = new RegExp(this.escapeRegExp(value), 'i');
            var match = autoCompleteString.match(regexp);
            if (match != null && match.length > 0 && autoCompletes[i] !== value) {
              suggestions.push(autoCompletes[i]);
            }
          }
          return suggestions;
        }
        return autoCompletes;
      }
      return [];
    }
  }, {
    key: "escapeRegExp",
    value: function escapeRegExp(s) {
      return s.replace(/([.*+?^${}()|\[\]\/\\])/g, '\\$1');
    }
  }, {
    key: "sortAutoComplete",
    value: function sortAutoComplete(autoCompleteList) {
      if (typeof autoCompleteList[0] !== 'string') {
        autoCompleteList.sort(function (a, b) {
          var x = a.title.toLowerCase();
          var y = b.title.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      } else {
        autoCompleteList.sort();
      }
      return autoCompleteList;
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;
      var _this$props = this.props,
        materailTextProps = _this$props.materailTextProps,
        editable = _this$props.editable,
        placeholder = _this$props.placeholder,
        style = _this$props.style,
        toolTip = _this$props.toolTip,
        formatNumber = _this$props.formatNumber;
      var _this$state = this.state,
        editMode = _this$state.editMode,
        currentValue = _this$state.currentValue;
      var setToolTip = toolTip != null ? toolTip : _I18n.I18n.m.getMessage('edit');
      if (editable !== true) {
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [this.renderLabel(), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              padding: 0
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, Object.assign({
              centeredText: true,
              centeredBox: true
            }, materailTextProps, {
              textPadding: 2,
              children: formatNumber ? formatNumber(currentValue) : currentValue.toString()
            }))
          })]
        });
      }
      if (editMode) {
        return this.renderEditMode();
      }
      if (currentValue == null || currentValue.toString().length === 0) {
        materailTextProps.color = _ThemeManager.ThemeManager.style.defaultTextColorLighten;
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        ref: function ref(e) {
          _this3.outerView = e;
          _this3.init().catch(function (err) {
            return void 0;
          });
        },
        onLayout: this.onLayout,
        style: Object.assign({
          position: 'relative',
          minHeight: 36,
          marginBottom: 1
        }, style, {
          overflow: 'visible',
          paddingRight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36),
          justifyContent: 'center'
        }),
        children: [this.renderLabel(), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            padding: 0
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, Object.assign({}, materailTextProps, {
            textPadding: 2,
            centeredText: true,
            centeredBox: true,
            numberOfLines: 1,
            children: currentValue == null || currentValue.toString().length === 0 ? placeholder : formatNumber ? formatNumber(currentValue) : currentValue.toString()
          }))
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            top: 0,
            right: 0
          },
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "pencil-outline",
            onPress: this.onActivateEdit,
            toolTip: setToolTip,
            color: _ThemeManager.ThemeManager.style.defaultIconColor
          })
        })]
      });
    }
  }, {
    key: "renderLabel",
    value: function renderLabel() {
      var label = this.props.label;
      if (label && label.length > 0) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%'
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Caption,
            children: label
          })
        });
      }
      return null;
    }
  }, {
    key: "onSaved",
    value: function onSaved() {
      var _this4 = this;
      var currentValue = this.state.currentValue;
      var _this$props2 = this.props,
        onChanged = _this$props2.onChanged,
        onBlur = _this$props2.onBlur;
      requestAnimationFrame(function () {
        if (currentValue !== _this4.getCurrentValue(_this4.state.abortValue)) {
          _this4.setState({
            currentValue: currentValue,
            editMode: false,
            abortValue: Number.parseFloat(currentValue)
          }, function () {
            if (onChanged != null) onChanged(currentValue);
            if (onBlur != null) onBlur();
            _this4.forceUpdate();
          });
        }
      });
    }
  }, {
    key: "renderEditMode",
    value: function renderEditMode() {
      var _this5 = this;
      var _this$props3 = this.props,
        materailTextProps = _this$props3.materailTextProps,
        placeholder = _this$props3.placeholder,
        style = _this$props3.style,
        blurOnSubmit = _this$props3.blurOnSubmit;
      var _this$state2 = this.state,
        currentValue = _this$state2.currentValue,
        textFieldHeight = _this$state2.textFieldHeight,
        selection = _this$state2.selection;
      var onSubmitEditing = function onSubmitEditing() {
        _this5.blockBlurOnce = true;
        _this5.save();
      };
      var typesStyle = Object.assign({}, (0, _MaterialText.getStyleForType)(materailTextProps.type, materailTextProps.color, materailTextProps.backgroundColor, materailTextProps.centeredText, materailTextProps.centeredBox, materailTextProps.textPadding));
      var textInputStyle = Object.assign({
        padding: 0,
        margin: 0
      }, typesStyle, {
        width: '100%',
        height: textFieldHeight == null ? 'auto' : textFieldHeight,
        overflow: 'visible',
        fontSize: _ThemeManager.ThemeManager.style.getTextInputFontSize(typesStyle.fontSize)
      });
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: Object.assign({}, style, {
          overflow: 'visible',
          position: 'relative'
        }),
        children: [this.renderLabel(), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: Object.assign({}, style, {
            overflow: 'visible',
            backgroundColor: _ThemeManager.ThemeManager.style.formInputFilledBackgroundColor
          }),
          children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              width: '100%',
              flexDirection: 'row'
            },
            children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                flex: 1,
                overflow: 'visible',
                padding: 4
              },
              children: (0, _jsxRuntime.jsx)(_reactNativeWeb.TextInput, {
                ref: function ref(r) {
                  requestAnimationFrame(function () {
                    if (!_this5.state.isFocused && r && typeof r.focus === 'function') r.focus();
                  });
                },
                underlineColorAndroid: "transparent",
                onContentSizeChange: this.onContentSizeChange,
                onChange: this.onContentSizeChange,
                blurOnSubmit: blurOnSubmit,
                onSubmitEditing: onSubmitEditing,
                returnKeyType: "done",
                style: textInputStyle,
                onChangeText: this.onChangeText,
                placeholder: placeholder,
                multiline: false,
                value: currentValue.toString(),
                onBlur: this.onBlur,
                onFocus: this.onFocus,
                keyboardType: "numeric",
                selection: selection,
                onSelectionChange: function onSelectionChange(e) {
                  return _this5.setState({
                    selection: e.nativeEvent.selection
                  });
                }
              }, "text")
            }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
              style: {
                alignSelf: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                width: 96
              },
              nativeID: "MATERIAL-TEXT-V2-ACTION-AREA-ID",
              children: [(0, _jsxRuntime.jsx)(_Icon.Icon, {
                iconSize: 24,
                toolTip: _I18n.I18n.m.getMessage('save'),
                icon: "check",
                onPress: this.save,
                color: _ThemeManager.ThemeManager.style.brandSuccess,
                outerSize: 36
              }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                style: {
                  width: 8
                }
              }), (0, _jsxRuntime.jsx)(_Icon.Icon, {
                toolTip: _I18n.I18n.m.getMessage('cancel'),
                iconSize: 24,
                outerSize: 36,
                icon: "close",
                onPress: this.abort
              })]
            })]
          })
        })]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        underlineColor: _ThemeManager.ThemeManager.style.brandPrimary,
        hoverColor: _ThemeManager.ThemeManager.style.materialTextHoverColor,
        children: '',
        editable: true,
        autoFocus: false,
        blurOnSubmit: true,
        placeholder: '...hinzufügen',
        materailTextProps: {
          type: _MaterialText.MaterialTextTypes.Body1,
          color: _ThemeManager.ThemeManager.style.defaultTextColor,
          centeredText: false,
          numberOfLines: undefined,
          ellipsizeMode: 'tail'
        },
        maxAutoCompleteHeight: 270
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      var editMode = nextProps.autoFocus === true || prevState != null && prevState.editMode;
      if (editMode !== prevState.editMode || nextProps.children !== prevState.abortValue) {
        return {
          editMode: editMode,
          currentValue: nextProps.children,
          abortValue: nextProps.children,
          currentNumberValue: nextProps.numberField ? nextProps.numberField.value : undefined
        };
      }
      return null;
    }
  }]);
}(_react.PureComponent);