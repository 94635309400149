import color from 'color';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { ChangeTicketStatus } from 'upmesh-core/src/client/commands/tickets/ChangeTicketStatus';
import { TicketStatus, TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
const generateStatusList = (project) => {
    return [
        {
            title: I18n.m.getMessage('ticketsDetailsStateopen', {}, project.messagesOverride),
            onPressChipData: TicketStatus.open,
            backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.open),
            hoverColor: color(TicketStatusColor.getColorForStatus(TicketStatus.open)).darken(0.2).toString(),
            textColor: ThemeManager.style.black87,
            thumbnail: <View style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: 'rgb(245, 124, 0)' }}/>,
        },
        {
            title: I18n.m.getMessage('ticketsDetailsStateprocessing', {}, project.messagesOverride),
            onPressChipData: TicketStatus.processing,
            backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.processing),
            hoverColor: color(TicketStatusColor.getColorForStatus(TicketStatus.processing)).darken(0.2).toString(),
            textColor: ThemeManager.style.black87,
            thumbnail: <View style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: 'rgb(255,213,0)' }}/>,
        },
        {
            title: I18n.m.getMessage('ticketsDetailsStateclosed', {}, project.messagesOverride),
            onPressChipData: TicketStatus.closed,
            backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.closed),
            hoverColor: color(TicketStatusColor.getColorForStatus(TicketStatus.closed)).darken(0.2).toString(),
            textColor: ThemeManager.style.black87,
            thumbnail: <View style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: 'rgb(0, 115, 255)' }}/>,
        },
        {
            title: I18n.m.getMessage('ticketsDetailsStatechecked', {}, project.messagesOverride),
            onPressChipData: TicketStatus.checked,
            backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.checked),
            hoverColor: color(TicketStatusColor.getColorForStatus(TicketStatus.checked)).darken(0.2).toString(),
            textColor: ThemeManager.style.black87,
            thumbnail: <View style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: 'rgb(0, 205, 158)' }}/>,
        },
    ];
};
export const TicketStatusChip = React.memo((props) => {
    const [disabledChip, setDisabledChip] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const checkDisabled = useCallback(async (project) => {
        const statusListNew = generateStatusList(project);
        let numberOfDisables = 0;
        const permissions = await Promise.all(statusListNew.map(async (status) => {
            if (props.ticketId) {
                try {
                    const c = new ChangeTicketStatus({ ticketStatus: status.onPressChipData }, props.ticketId);
                    await c.canI();
                    return true;
                }
                catch {
                    return false;
                }
            }
            return false;
        }));
        permissions.forEach((cPerm, index) => {
            if (!cPerm) {
                numberOfDisables += 1;
                statusListNew[index].chipDisabled = true;
                statusListNew[index].buttonDisabled = true;
                statusListNew[index].textColor = ThemeManager.style.black54;
            }
        });
        setStatusList(statusListNew);
        setDisabledChip(numberOfDisables === statusListNew.length);
    }, [props.ticketId]);
    const fetchProject = useCallback(async () => {
        return UpmeshClient.instance.modals.project.getById(props.projectId);
    }, [props.projectId]);
    useEffect(() => {
        let isMounted = true;
        fetchProject()
            .then((rProject) => {
            if (isMounted) {
                checkDisabled(rProject).catch((err) => {
                    console.error(err);
                });
            }
        })
            .catch((err) => {
            console.error(err);
        });
        return () => {
            isMounted = false;
        };
    }, [checkDisabled, fetchProject, props.ticketId, props.projectId]);
    const onChangeStatus = (e) => {
        if (props.ticketId != null) {
            const c = new ChangeTicketStatus({ ticketStatus: e }, props.ticketId);
            c.execute().catch((err) => {
                props.errorResult(err);
            });
        }
    };
    return (<>
      <View key="statusLabel" style={props.labelStyle}>
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('ticketsDetailsState')}
        </MaterialText>
      </View>
      <OpenableChip dialogTitle={I18n.m.getMessage('ticketsDetailsState')} accessibilityLabel="status" key={`status_${props.ticket.ticketStatus}`} elevation={40} selected={props.ticket.ticketStatus} onPressChip={onChangeStatus} widthOpen={256} textColor={ThemeManager.style.black87} chipsList={statusList} chipDisabled={disabledChip} thumbOnlyOnList/>
    </>);
});
