import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { ChangeTicketApprover, } from 'upmesh-core/src/client/commands/tickets/ChangeTicketApprover';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../../project/CurrentProject';
import { ProjectMemberDetailsPU } from '../../team/ProjectMemberDetailsPU';
export const ApproverChip = React.memo((props) => {
    const [disabledChip, setDisabledChip] = React.useState(true);
    useEffect(() => {
        if (props.ticketId != null) {
            const c = new ChangeTicketApprover({}, props.ticketId);
            c.canI()
                .then((canI) => {
                if (canI === true) {
                    setDisabledChip(false);
                }
                else {
                    setDisabledChip(true);
                }
            })
                .catch((_err) => {
                setDisabledChip(true);
            });
        }
    }, [props.ticketId]);
    const assignedTeamMemberList = () => {
        const chips = [];
        const team = CurrentProject.instance.getCurrentProjectTeam();
        for (let i = 0; i < team.length; i += 1) {
            chips.push({
                title: `${team[i].user.firstname} ${team[i].user.lastname}`,
                onPressChipData: team[i].user.id,
                subtitle: team[i].user.company,
                thumbnail: <UserImage size={24} user={team[i].user}/>,
            });
        }
        return chips;
    };
    const chipList = assignedTeamMemberList();
    let approverSelected = -1;
    if (props.approverUserId != null && props.approverUserId.length > 0) {
        for (let i = 0; i < chipList.length; i += 1) {
            if (chipList[i].onPressChipData === props.approverUserId) {
                approverSelected = i;
                break;
            }
        }
    }
    const onChangeApprover = (index) => {
        if (props.ticketId != null) {
            const c = new ChangeTicketApprover({ approverUserId: index }, props.ticketId);
            c.execute().catch((e) => {
                props.errorResult(e);
            });
        }
    };
    const openMemberDetails = async (_e) => {
        if (props.approverUserId != null) {
            const projectUser = await UpmeshClient.instance.modals.projectUser.get({
                filter: `projectId eq '${props.projectId}' and userId eq '${props.approverUserId}'`,
            });
            const projectUserId = projectUser.length === 0 ? `creator_${props.approverUserId}` : projectUser[0].id;
            Dialog.instance?.open({
                content: (<ProjectMemberDetailsPU projectId={props.projectId} data={{ projectUserId, userId: props.approverUserId }} closeFunction={Dialog.instance?.close}/>),
            });
        }
    };
    return (<View>
      <View style={props.labelStyle} key="approverLabel">
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('ticketsDetailsTitleApprover')}
        </MaterialText>
      </View>
      <OpenableChip key="approverChip" dialogTitle={I18n.m.getMessage('ticketsDetailsTitleApprover')} showDeleteIcon emptyStateChip={{
            thumbnail: (<View style={{
                    borderStyle: 'dashed',
                    borderWidth: ThemeManager.style.borderWidth,
                    borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                    width: ThemeManager.style.getScreenRelativePixelSize(24),
                    height: ThemeManager.style.getScreenRelativePixelSize(24),
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}>
              <Icon toolTip="" icon="help" iconSize={20} outerSize={ThemeManager.style.getScreenRelativePixelSize(24)} radius={0}/>
            </View>),
            title: I18n.m.getMessage('ticketsDetailsTitleNoApproverAssignedToUser'),
            onPressChipData: '0',
        }} accessibilityLabel="approver" elevation={40} selected={approverSelected} onPressChip={onChangeApprover} widthOpen={256} textColor={ThemeManager.style.black87} chipsList={chipList} chipDisabled={disabledChip} onPressMainChip={props.approverUserId != null ? openMemberDetails : undefined}/>
    </View>);
});
