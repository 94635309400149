import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { I18n } from '../../../i18n/I18n';
export const TimeTrackingDayForUserDialogTimeTextBlock = React.memo((props) => {
    return (<View style={{ height: '100%', width: '100%', justifyContent: 'space-between' }}>
        <View>
          <MaterialText type={MaterialTextTypes.Body2}>{props.costCenterName}</MaterialText>
          <MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.black42}>
            {props.taskNameFull}
          </MaterialText>
        </View>
        <MaterialText fixedWidth="100%" textAlign="right" type={MaterialTextTypes.Body2} strong>
          {TimeTrackingEntity.msToTime(props.sumInMS, false, false)} {I18n.m.getMessage('unitForHours')}
        </MaterialText>
      </View>);
}, (prevProps, nextProps) => {
    return (prevProps.costCenterName === nextProps.costCenterName &&
        prevProps.taskNameFull === nextProps.taskNameFull &&
        prevProps.sumInMS === nextProps.sumInMS);
});
