var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MessagesDe = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _MessagesDe = require("upmesh-auth-core/build/src/i18n/MessagesDe");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var MessagesDe = exports.MessagesDe = function (_AuthMessages) {
  function MessagesDe() {
    var _this;
    (0, _classCallCheck2.default)(this, MessagesDe);
    _this = _callSuper(this, MessagesDe, arguments);
    _this['?'] = '?';
    _this.SignUpErrorGoogleUserAlreadyExisting = 'Dieser Google Account ist bereits registriert.';
    _this.SignUpErrorMicrosoftUserAlreadyExisting = 'Dieser Microsoft Account ist bereits registriert.';
    _this.SignUpErrorUserAlreadyExisting = 'Diese E-Mail-Adresse ist bereits registriert.';
    _this.abort = 'Abbrechen';
    _this.account = 'Mein Konto';
    _this.accountAddress = 'Adresse';
    _this.accountChangeAddressCity = 'Ort';
    _this.accountChangeAddressCountry = 'Land';
    _this.accountChangeAddressStreet = 'Straße';
    _this.accountChangeAddressStreetN = 'Hausnummer';
    _this.accountChangeAddressTitle = 'Adresse ändern';
    _this.accountChangeAddressZip = 'PLZ';
    _this.accountChangeCompanyTitle = 'Firma ändern';
    _this.accountChangeData = 'Daten ändern';
    _this.accountChangeNames = 'Name ändern';
    _this.accountChangeNew = 'Neues Passwort';
    _this.accountChangeOld = 'Altes Passwort';
    _this.accountChangePW = 'Passwort ändern';
    _this.accountCompany = 'Firma';
    _this.accountDeleteEmailQuestion = 'Diese E-Mail-Adresse wirklich löschen?';
    _this.accountDisconnectGoogleAlert = 'Verbindung mit Google trennen?';
    _this.accountEmailNotValidated = 'E-Mail noch nicht verifiziert';
    _this.accountEmailRequestValidateMail = 'Verifizierungsmail erneut senden?';
    _this.accountEmails = 'E-Mail-Adressen';
    _this.accountEmailsAdd = 'E-Mail-Adresse hinzufügen';
    _this.accountEmailsNotification = 'an diese E-Mail Benachrichtigungen schicken';
    _this.accountFunctionInCompany = 'Position';
    _this.accountImageDeleteHint = 'Benutzerbild löschen';
    _this.accountImageDeleteQuestion = 'Möchtest du dein Nutzerbild wirklich löschen?';
    _this.accountImageDropHint = 'Benutzerbild ändern';
    _this.accountImageDropOnMultiSelect = 'als Benutzerbild hinzufügen';
    _this.accountLogout = 'Abmelden';
    _this.accountLogoutQuestion = 'Wenn du dich abmeldest, kannst du dich nur mit vorhandener Internetverbindung wieder anmelden';
    _this.accountLogoutQuestionTitle = 'Abmelden bei upmesh?';
    _this.accountLogoutQuestionUploads = 'Sollen die Uploads verworfen werden oder willst du mit dem Abmelden warten bis die Uploads abgeschlossen sind?';
    _this.accountLogoutQuestionUploadsDiscard = 'Uploads Verwerfen';
    _this.accountLogoutQuestionUploadsWait = 'Warten';
    _this.accountLogoutTitleUploads = 'Uploads laufen noch im Hintergrund';
    _this.accountMarkedForDeletionDialogReactivateButton = 'Support kontaktieren';
    _this.accountMarkedForDeletionDialogText = 'Für den Account wurde die Löschung beantragt. Möchtest du die Löschung' + ' verhindern, kontaktiere bitte unseren Support!';
    _this.accountName = 'Name';
    _this.accountNotWithGoogleConnectedButton = 'Jetzt Konto mit Google verbinden';
    _this.accountNotifications = 'Benachrichtigungen';
    _this.accountPersonalData = 'Persönliche Daten';
    _this.accountPhone = 'Telefon';
    _this.accountSettings = 'Einstellungen';
    _this.accountWithGoogleConnectedButton = 'Googleverbindung aufheben';
    _this.accountWithGoogleConnectedButtonShort = 'aufheben';
    _this.activities = 'Aktivitäten';
    _this.add = 'hinzufügen';
    _this.addMapWidget = 'Karte hinzufügen';
    _this.addOption = 'Option hinzufügen';
    _this.addWidget = 'Widget hinzufügen';
    _this.address = 'Adresse';
    _this.addressAdd = 'Adresse hinzufügen';
    _this.addressAddText = 'Füge die Projektadresse und Kontaktdaten hinzu, um weitere Funktionen freizuschalten';
    _this.addressCall = 'anrufen';
    _this.addressChange = 'Adresse ändern';
    _this.addressContactAndWeather = 'Kontakt & Wetter';
    _this.addressContactFunction = 'Funktion';
    _this.addressContactName = 'Kontaktname';
    _this.addressErrorRequireCity = 'Stadt wird benötigt';
    _this.addressErrorRequireCountry = 'Land wird benötigt';
    _this.addressErrorRequireStreet = 'Straße wird benötigt';
    _this.addressErrorRequireStreetNumber = 'Hausnummer wird benötigt';
    _this.addressErrorRequireZip = 'Postleitzahl wird benötigt';
    _this.addressGetCurrentLocationError = 'Fehler bei der Lokalisierung. Bitte stelle sicher, dass du GPS aktiviert hast und upmesh in den' + ' Geräte-Einstellungen das Recht gegeben hast, auf deinen Standort zuzugreifen.';
    _this.addressPhone = 'Telefon';
    _this.addressSetCurrentLocation = 'Aktuellen Standort nutzen';
    _this.allTickets = 'Für alle Tickets';
    _this.and = 'und';
    _this.assigned = 'zugewiesen';
    _this.back = 'zurück';
    _this.camera = 'Kamera';
    _this.cameraPreviewImagesDiscardHintText = 'Möchtest du die noch nicht gespeicherten Fotos verwerfen oder speichern?';
    _this.cameraPreviewImagesDiscardHintTitle = 'Nicht gespeicherte Fotos';
    _this.cameraSensorOrientation = 'Sensororientierung';
    _this.cameraSensorOrientationLandscapeLeft = 'Querformat Links';
    _this.cameraSensorOrientationLandscapeRight = 'Querformat Rechts';
    _this.cameraSensorOrientationPortrait = 'Hochformat';
    _this.cameraSensorOrientationPortraitUpsideDown = 'Hochformat (umgekehrt)';
    _this.cameraSettings = 'Kameraeinstellungen';
    _this.cancel = 'abbrechen';
    _this.cancelUpload = 'Upload abbrechen?';
    _this.cancelUploadText = 'Dein Upload ist nicht vollständig. Möchtest du den Upload abbrechen?';
    _this.cancelUploadTitle = 'Upload abbrechen';
    _this.cantDeleteEmail = 'Diese E-Mail-Adresse kann nicht gelöscht werden!';
    _this.cantEditImageAlreadyUploaded = 'Das Bild konnte nicht bearbeitet werden, es wurde bereits hochgeladen';
    _this.cantEditImageAlreadyUploading = 'Das Bild konnte nicht bearbeitet werden, es wird bereits hochgeladen';
    _this.cantSyncCommand = 'Aktion konnte nicht synchronisiert werden: {{error}}';
    _this.cantUpload = 'Datei {{filename}} konnte nicht hochgeladen werden: {{error}}';
    _this.chipAddNewGroupTitle = 'neue Gruppe erstellen';
    _this.chipDialogDeselectAll = 'Alle abwählen';
    _this.chipDialogSelectAll = 'Alle auswählen';
    _this.chipGroupNoGroup = 'Keine Gruppe';
    _this.chipSelectGroupTitle = 'Gruppe auswählen';
    _this.clear = 'löschen';
    _this.close = 'schließen';
    _this.closeAppText = 'Willst du die App wirklich beenden?';
    _this.closeAppTitle = 'App beenden?';
    _this.commandAddEmail = 'E-Mail-Adresse hinzufügen';
    _this.commandChangePassword = 'Passwort ändern';
    _this.commandChangeUserAdress = 'Adresse ändern';
    _this.commandChangeUserCompany = 'Firma ändern';
    _this.commandChangeUserNames = 'Name ändern in {{firstname}} {{lastname}}';
    _this.commandConnectWithGoogle = 'Mit Google verbinden';
    _this.commandDisconnectError = 'Serververbindung verloren. Versuche es bitte später noch einmal';
    _this.commandExecuteTimeout = 'Es sieht so aus, als würde der Server zu lange brauchen, um zu antworten. Bitte versuche' + ' es später noch einmal';
    _this.commandOfflineNotPossible = 'Die Aktion kann offline nicht ausgeführt werden!';
    _this.commandPublishViews = 'Ansichten veröffentlichen';
    _this.commandRemoveEmail = 'E-Mail-Adresse entfernen';
    _this.commandRemoveGoogleLogin = 'Googleverbindung trennen';
    _this.commandSetLocale = 'Sprache ändern';
    _this.commandSetLocaleError = 'Fehler beim ändern der Sprache';
    _this.commandSyncList = 'Offline Daten zum Synchronisieren:';
    _this.companyErrorRequireCompanyName = 'Firma wird benötigt';
    _this.companyErrorRequireFunctionInCompany = 'Position wird benötigt';
    _this.companyImageDeleteQuestion = 'Möchtest du dein Firmenlogo wirklich löschen?';
    _this.connectWithMicrosoft = 'Mit Microsoft verbinden';
    _this.constructionSite = 'Baustelle';
    _this.contact = 'Kontakt';
    _this.continueUploadTitle = 'Upload fortsetzen';
    _this.couldNotBeSaved = 'Die Datei konnte nicht gespeichert werden. Bitte versuche es später noch einmal';
    _this.couldNotDelete = 'Die Datei konnte nicht gelöscht werden. Bitte versuche es später noch einmal';
    _this.dailyCompilation = 'Tägliche Zusammenstellung';
    _this.dashboardChartItemTicketOverview = 'Ticketübersicht';
    _this.dashboardFocusTicketsItem = 'Fokustickets';
    _this.date = 'Datum';
    _this.dateNotInRangeBoth = 'Bitte ein Datum zwischen dem {{minDate}} und dem {{maxDate}} angeben';
    _this.dateNotInRangeMax = 'Bitte ein Datum bis zum {{maxDate}} angeben';
    _this.dateNotInRangeMin = 'Bitte ein Datum ab dem {{minDate}} angeben';
    _this.dateWeekNumberShort = 'KW';
    _this.dbError = 'Datenbank Fehler';
    _this.delete = 'löschen';
    _this.deleteCompanyImage = 'Firmenlogo löschen';
    _this.deleteWidgetsQuestion = 'Möchtest du das Widget wirklich entfernen?';
    _this.deselect = 'abwählen';
    _this.discard = 'Verwerfen';
    _this.displayedColumns = 'Angezeigte Spalten';
    _this.dropHint = 'Datei hier ablegen';
    _this.dropHintMulti = 'Dateien hier ablegen';
    _this.eMailNotFound = 'E-Mail-Adresse nicht gefunden';
    _this.edit = 'bearbeiten';
    _this.email = 'E-Mail-Adresse';
    _this.emailChangeNotificationError = 'Die E-Mail Benachrichtigung konnte nicht aktiviert werden. ';
    _this.emailConfirm = 'E-Mail-Adresse bestätigen';
    _this.emailRemovalError = 'Fehler bei der Entfernung der E-Mail';
    _this.emailRequestEmailValidationError = 'Fehler bei der Validierung der E-Mail-Adresse ';
    _this.emailValidateError = 'Deine E-Mail-Adresse konnte nicht verifiziert werden';
    _this.emailValidateErrorNoEmail = 'Deine E-Mail-Adresse konnte nicht verifiziert werden, E-Mail nicht gefunden';
    _this.emailValidateErrorTokenInvalid = 'Deine E-Mail-Adresse konnte nicht verifiziert werden, Code ungültig';
    _this.emailValidateNoSuchCode = 'Deine E-Mail-Adresse konnte nicht verifiziert werden, Code ungültig';
    _this.emailValidateSend = 'Es wurde eine neue Verifizierungsmail versendet.';
    _this.emailValidateSuccess = 'Deine E-Mail-Adresse wurde erfolgreich verifiziert';
    _this.emailValidateTitle = 'E-Mail Verifizierung';
    _this.emailValidateTokenExpired = 'Deine E-Mail-Adresse konnte nicht verifiziert werden,' + ' der Code ist abgelaufen. Es wurde eine neue Verifizierungsmail versendet.';
    _this.emailValidationError = 'Keine gültige E-Mail-Adresse';
    _this.enterDate = 'Datum eingeben';
    _this.enterValidDate = 'Bitte ein gültiges Datum angeben!';
    _this.error = 'Fehler';
    _this.excelDialogDownloadExample = 'Beispieldatei herunterladen';
    _this.excelDialogEntriesFound = 'Insgesamt: {{entries}} Einträge gefunden';
    _this.excelDialogGeneratedName = 'Automatisch generiert';
    _this.excelDialogSelectFile = 'Excel Datei auswählen';
    _this.excelDialogTitle = 'Excel-Datei importieren';
    _this.expand = 'vergrößern';
    _this.fileDragError = 'Fehler beim Auswählen der Datei';
    _this.fileError = 'Diese Datei wird nicht unterstützt';
    _this.fileNotFound = 'Datei wurde nicht gefunden';
    _this.fileResizeError = 'Fehler beim Versuch, die Datei zu komprimieren';
    _this.fileSelectionError = 'Mindestens eine Datei muss ausgewählt sein';
    _this.fileTooLarge = 'Die Datei ist zu groß (maximal {{maxSize}})';
    _this.fileUploadStartError = 'Fehler beim Hochladen der Datei';
    _this.fileUploadText = 'Wird hochladen';
    _this.filesSyncList = 'Ausstehende Uploads';
    _this.filter = 'Filtern';
    _this.firstName = 'Vorname';
    _this.forbidden = 'Keine Berechtigung';
    _this.forgotPwCodeCodeTitle = 'Neues Passwort setzen';
    _this.forgotPwCodeLogOutFirst = 'Du bist bereits mit einem Account angemeldet und musst dich erst abmelden, um ein neues Passwort zu setzen.';
    _this.forgotPwCodeLogOutFirstButton = 'Jetzt abmelden';
    _this.forgotPwCodeSubmit = 'Passwort setzen';
    _this.forgotPwError = 'Unbekannter Fehler aufgetreten, versuche es später noch einmal';
    _this.forgotPwErrorNoSuchAccount = 'Account mit E-Mail-Adresse nicht gefunden';
    _this.forgotPwLength = 'Passwortlänge';
    _this.forgotPwLowerCase = 'Kleinbuchstaben';
    _this.forgotPwMinLength = 'Mindestens 8 Zeichen';
    _this.forgotPwMinTotal = 'Mindestens 65 Punkte';
    _this.forgotPwNoInsecure = 'Kein bekanntes unsicheres Passwort';
    _this.forgotPwNumbers = 'Ziffern';
    _this.forgotPwRegisterNow = 'Jetzt registrieren!';
    _this.forgotPwSpecialChars = 'Sonderzeichen';
    _this.forgotPwRequestCode = 'Code beantragen';
    _this.forgotPwSubmit = 'zurücksetzen';
    _this.forgotPwSuccess = 'Du solltest innerhalb der nächsten Minuten eine E-Mail mit einem Link zur Wiederherstellung deines Passwortes' + ' erhalten';
    _this.forgotPwText = 'Gib deine E-Mail-Adresse ein. Du erhältst einen Link per E-Mail, um ein neues Passwort zu setzen.';
    _this.forgotPwTitle = 'Passwort zurücksetzen';
    _this.forgotPwUpperCase = 'Großbuchstaben';
    _this.formSubmitError = 'Fehlerhafte Eingaben';
    _this.fromCamera = 'Kamera';
    _this.fromImageLibrary = 'Fotos';
    _this.goToHome = 'Zur Startseite';
    _this.gpsLock = 'Position fixieren';
    _this.gpsLockRelease = 'Position lösen';
    _this.helpArea = 'Hilfebereich';
    _this.hide = 'verbergen';
    _this.hour = 'Stunde';
    _this.imageNotFound = 'Bild nicht verfügbar!';
    _this.imageNotReachable = 'Bild steht offline nicht zur Verfügung';
    _this.imagePickerOpenCameraError = 'Fehler beim Öffnen der Kamera. Bitte stelle sicher, dass du upmesh in den Geräten-Einstellungen das Recht' + ' gegeben hast, die Kamera zu öffnen.';
    _this.imagePickerOpenLibraryError = 'Fehler beim Öffnen der Foto- Bibliothek. Bitte stelle sicher, dass du upmesh in den Geräten-Einstellungen das' + ' Recht gegeben hast, die Foto-Bibliothek zu öffnen.';
    _this.imageUploadError = 'Es ist ein Fehler beim Hochladen des Bildes aufgetreten';
    _this.impressum = 'Impressum';
    _this.internalServer = 'Interner Serverfehler';
    _this.invalidDate = 'ungültiges Datum';
    _this.invalidTime = 'Ungültiges Zeitformat (HH:MM)';
    _this.language = 'Sprache';
    _this.languagePick = 'Wähle eine Sprache';
    _this.lastName = 'Nachname';
    _this.latitude = 'Breitengrad';
    _this.lightBoxDetails = 'Details';
    _this.lightBoxDownload = 'Bild herunterladen';
    _this.lightBoxEditImage = 'Bild bearbeiten';
    _this.lightBoxInformation = 'Bildinformationen anzeigen';
    _this.lightBoxPlace = 'Ort';
    _this.lightBoxShowPanorama = 'Panorama ansehen';
    _this.listOptionsSubTitle = 'Tabelleninhalte mit Strg/⌘+v einfügen';
    _this.loading = 'wird geladen';
    _this.logOutFirst = 'Du bist bereits mit einem Account angemeldet und musst sich erst abmelden.';
    _this.logOutFirstButton = 'Jetzt abmelden';
    _this.login = 'anmelden';
    _this.loginError = 'Fehlerhafte Zugangsdaten';
    _this.loginErrorAccountDisabled = 'Account gesperrt, bitte wende dich an den Support!';
    _this.loginErrorEmailNotValidated = 'Deine E-Mail-Adresse ist noch nicht verifiziert. Du solltest in Kürze eine E-Mail erhalten. Bitte bestätige' + ' zunächst den Link in der E-Mail, um dich wieder einloggen zu können.';
    _this.loginErrorNoAccount = 'Account nicht vorhanden, jetzt Registrieren?';
    _this.loginErrorNoAccountGoogle = 'Bist du bereits registriert? Dann melde sich an und verbinden deinen Google Account mit deinem upmesh Konto.';
    _this.loginErrorNoAccountGoogleSub = 'Du bist neu hier, dann registriere dich jetzt mit deinem Google Account!';
    _this.loginErrorNoAccountGoogleTitle = 'Google Account noch nicht verbunden';
    _this.loginErrorNoAccountMicrosoft = 'Möchtest du dich jetzt mit deinem Microsoft Account registrieren?';
    _this.loginErrorNoAccountMicrosoftTitle = 'Microsoft Account noch nicht registriert';
    _this.loginErrorNoAccountRegister = 'Registrieren';
    _this.loginErrorNoGoogle = 'Google Login nicht möglich';
    _this.loginErrorPasswordRequired = 'Dein Passwort wird benötigt';
    _this.loginErrorRemovingGoogleLogin = 'Fehler beim Entfernen des Google-Logins';
    _this.loginErrorSetupGoogleSingin = 'Fehler beim Erstellen des Google-Logins';
    _this.loginErrorWrongCredentials = 'Fehlerhafte Zugangsdaten';
    _this.loginForgotPw = 'Passwort vergessen?';
    _this.loginInfo = 'Indem du auf "anmelden" tippst, stimmst du den Nutzungsbedingungen und Zahlungsbedingungen von upmesh zu.';
    _this.loginOffline = 'Du benötigst eine Internetverbindung, um dich anmelden zu können';
    _this.loginRegister = 'Registrieren';
    _this.loginTitle = 'Anmelden';
    _this.loginWithGoogle = 'Mit Google anmelden';
    _this.loginWithMicrosoft = 'Mit Microsoft anmelden';
    _this.longitude = 'Längengrad';
    _this.minute = 'Minute';
    _this.modusBrush = 'modusBrush';
    _this.more = 'mehr';
    _this.myProjects = 'Meine Projekte';
    _this.next = 'weiter';
    _this.nextMonth = 'nächster Monat';
    _this.no = 'Nein';
    _this.noConnection = 'Keine Verbindung zum Server. Internetverbindung überprüfen!';
    _this.noSelection = 'Keine Auswahl';
    _this.notFound = 'Seite nicht gefunden!';
    _this.notifyNow = 'Sofort benachrichtigen';
    _this.offline = 'offline';
    _this.ok = 'ok';
    _this.onlineOnly = 'Bitte überprüfe deine Internetverbindung. Diese Aktion steht nur online zur Verfügung.';
    _this.open = 'öffnen';
    _this.or = 'oder';
    _this.overviewItemThumb = 'overviewItemThumb';
    _this.page = 'Seite';
    _this.pageFrom = 'von';
    _this.paintMenuColorButton = 'Farbauswahl';
    _this.paintMenuFormCircleButton = 'Kreis';
    _this.paintMenuFormGestureButton = 'Freihandzeichnen';
    _this.paintMenuFormRectangleButton = 'Rechteck';
    _this.paintMenuFormsButton = 'Form einfügen';
    _this.paintMenuRedoButton = 'Wiederherstellen';
    _this.paintMenuSelectButton = 'Element auswählen';
    _this.paintMenuTextButton = 'Text einfügen';
    _this.paintMenuThicknessButton = 'Stiftbreite';
    _this.paintMenuUndoButton = 'Rückgängig machen';
    _this.password = 'Passwort';
    _this.passwordErrorPwWeak = 'Passwort ist zu schwach';
    _this.passwordErrorRequirePw = 'Passwort wird benötigt';
    _this.passwordErrorRequireToken = 'Code wird benötigt';
    _this.passwordErrorTokenInvalid = 'Code abgelaufen';
    _this.passwordHint = `• mind. 8 Zeichen\n• Großschreibung (A-Z)\n• Kleinschreibung (a-z)\n• Zahlen (0-9)\n• Sonderzeichen (z.B. § & ? * ! ? )\n• NICHT im Wörterbuch zu finden`;
    _this.photoEditorSaveText = 'Du kannst wählen, ob die Änderungen gespeichert oder verworfen werden sollen.';
    _this.photoEditorSaveTitle = 'Möchtest du die Änderungen speichern?';
    _this.photoEditor_Apply = 'Übernehmen';
    _this.photoEditor_Arrow = 'Pfeil';
    _this.photoEditor_Arrow_2 = 'Pfeil-2';
    _this.photoEditor_Arrow_3 = 'Pfeil-3';
    _this.photoEditor_Blend = 'Vermischen';
    _this.photoEditor_Blur = 'Verwischen';
    _this.photoEditor_Bold = 'Fett';
    _this.photoEditor_Brightness = 'Helligkeit';
    _this.photoEditor_Bubble = 'Sprechblase';
    _this.photoEditor_Cancel = 'Abbrechen';
    _this.photoEditor_Center = 'Zentriert';
    _this.photoEditor_Circle = 'Kreis';
    _this.photoEditor_Color = 'Farbe';
    _this.photoEditor_Color_Filter = 'Farbfilter';
    _this.photoEditor_Crop = 'Abschneiden';
    _this.photoEditor_Custom = 'Benutzerdefiniert';
    _this.photoEditor_Custom_icon = 'Benutzerdefiniertes Icon';
    _this.photoEditor_Delete = 'Löschen';
    _this.photoEditor_Delete_all = 'Alles löschen';
    _this.photoEditor_Distance = 'Abstand';
    _this.photoEditor_Download = 'Herunterladen';
    _this.photoEditor_Draw = 'Zeichnen';
    _this.photoEditor_Emboss = 'Prägen';
    _this.photoEditor_Fill = 'Ausfüllen';
    _this.photoEditor_Filter = 'Filter';
    _this.photoEditor_Flip = 'Spiegeln';
    _this.photoEditor_Flip_X = 'Horizontal spiegeln';
    _this.photoEditor_Flip_Y = 'Vertikal spiegeln';
    _this.photoEditor_Free = 'Frei';
    _this.photoEditor_Grayscale = 'Graustufen';
    _this.photoEditor_Heart = 'Herz';
    _this.photoEditor_Icon = 'Icon';
    _this.photoEditor_Invert = 'Invertiert';
    _this.photoEditor_Italic = 'Kursiv';
    _this.photoEditor_Left = 'Links';
    _this.photoEditor_Load = 'Bild laden';
    _this.photoEditor_Load_Mask_Image = 'Maskenbild laden';
    _this.photoEditor_Location = 'Position';
    _this.photoEditor_Mask = 'Maskieren';
    _this.photoEditor_Multiply = 'Multiplizieren';
    _this.photoEditor_Noise = 'Rauschen';
    _this.photoEditor_Pixelate = 'Pixeln';
    _this.photoEditor_Polygon = 'Polygon';
    _this.photoEditor_Range = 'Breite';
    _this.photoEditor_Rectangle = 'Rechteck';
    _this.photoEditor_Redo = 'Wiederholen';
    _this.photoEditor_Remove_White = 'Weiß entfernen';
    _this.photoEditor_Reset = 'Zurücksetzen';
    _this.photoEditor_Right = 'Rechts';
    _this.photoEditor_Rotate = 'Rotieren';
    _this.photoEditor_Sepia = 'Sepia';
    _this.photoEditor_Sepia2 = 'Sepia2';
    _this.photoEditor_Shape = 'Formen';
    _this.photoEditor_Sharpen = 'Schärfen';
    _this.photoEditor_Square = 'Viereck';
    _this.photoEditor_Star_1 = 'Stern1';
    _this.photoEditor_Star_2 = 'Stern2';
    _this.photoEditor_Straight = 'Gerade';
    _this.photoEditor_Stroke = 'Kontur';
    _this.photoEditor_Text = 'Text';
    _this.photoEditor_Text_size = 'Textgröße';
    _this.photoEditor_Threshold = 'Schwellenwert';
    _this.photoEditor_Tint = 'Tönen';
    _this.photoEditor_Triangle = 'Dreieck';
    _this.photoEditor_Underline = 'Unterstrichen';
    _this.photoEditor_Undo = 'Rückgängig machen';
    _this.photoEditor_Value = 'Wert';
    _this.planOfflineNotAvailable = 'Der Plan steht offline nicht zur Verfügung';
    _this.precipitation = 'Niederschlag';
    _this.preferableEmail = 'Bevorzugte E-Mail:';
    _this.prev = 'vorheriges';
    _this.prevMonth = 'vorheriger Monat';
    _this.privacyPolicy = 'Datenschutzerklärung';
    _this.projects = 'Projekte';
    _this.redo = 'wiederherstellen';
    _this.registerErrorRequireFirstName = 'Dein Vorname wird benötigt';
    _this.registerErrorRequireLastName = 'Dein Nachname wird benötigt';
    _this.registerErrorRequireUserId = 'User id wird benötigt';
    _this.registerPleaseActivate = 'Bitte aktiviere dein Konto';
    _this.registerPrivacyText = 'Hiermit stimme ich den Nutzungsbedingungen und Datenschutzerklärung zu';
    _this.registerPrivacyTextAlert = 'Du musst erst den Nutzungsbedingungen und der Datenschutzerklärung zustimmen, um dich zu registrieren.';
    _this.registerSuccess = 'Fast geschafft, {{name}}!';
    _this.registerSuccessButton = 'Weiter zur Anmeldung';
    _this.registerSuccessText = 'Wir haben eine E-Mail an ';
    _this.registerSuccessText2 = ' gesendet. Bitte überprüfe deinen Posteingang und klicke auf den Link, um dein Passwort zu setzen.';
    _this.registerWithGoogle = 'Mit Google registrieren';
    _this.registerWithMicrosoft = 'Mit Microsoft registrieren';
    _this.remove = 'entfernen';
    _this.removeWidget = 'Widget entfernen';
    _this.replace = 'Ersetzen';
    _this.reset = 'zurücksetzen';
    _this.resetWidgetsQuestion = 'Möchtest du das Dashboard wirklich auf die Standardansicht zurücksetzen?';
    _this.rowsPerPage = 'Zeilen pro Seite';
    _this.save = 'speichern';
    _this.saveCopy = 'Kopie speichern';
    _this.search = 'suchen';
    _this.searchNoResult = 'Keine Suchergebnisse';
    _this.searchNoun = 'Suche';
    _this.select = 'selektieren';
    _this.selectDate = 'Datum auswählen';
    _this.selectFileHandler = 'Ziel auswählen:';
    _this.selectTime = 'Zeit auswählen';
    _this.selectYear = 'Jahr wählen';
    _this.serverNotReachable = 'Server nicht erreichbar!';
    _this.settingsCameraApp = 'Kamera App';
    _this.settingsCameraAppNative = 'Gerätekamera';
    _this.settingsCameraAppVision = 'Visionkamera';
    _this.settingsHourFormat12 = '12 Stunden';
    _this.settingsHourFormat24 = '24 Stunden';
    _this.settingsHourFormatLabel = 'Zeitformat';
    _this.settingsImageEditor = 'Editor nach Kameraaufnahme';
    _this.settingsImageUploadQuali = 'Bildupload Auflösung';
    _this.settingsImageUploadQuali0 = 'Org';
    _this.settingsImageUploadQuali1 = '10MP';
    _this.settingsImageUploadQuali2 = '6MP';
    _this.settingsImageUploadQuali3 = '2MP';
    _this.settingsLanguage = 'Sprache';
    _this.settingsPersonal = 'Persönliche Einstellungen';
    _this.settingsRegional = 'Regionale Einstellungen';
    _this.settingsShowKw = 'Kalenderwochen anzeigen';
    _this.settingsTicketsInSidePanel = 'Tickets in der Seitenleiste anzeigen';
    _this.settingsWeekStartsWithMonday = 'Woche startet mit Montag';
    _this.show = 'ansehen';
    _this.specificTaskInTicket = 'Nur Tickets, in denen ich folgende Rolle habe:';
    _this.statusPage = 'API status';
    _this.stayLoggegIn = 'In diesem Browser angemeldet bleiben?';
    _this.stringToLong = 'zu lang';
    _this.syncAgain = 'Jetzt synchronisieren';
    _this.syncStatus = 'Sync Status';
    _this.syncStatusError = 'Sync Error';
    _this.syncStatusOffline = 'Offline ({{pending}})';
    _this.syncStatusOk = 'Sieht gut aus! Alles ist synchronisiert';
    _this.syncStatusOnline = 'Online';
    _this.syncStatusSyncing = 'Synchronisiere...';
    _this.syncTitle = 'Synchronisation';
    _this.tableActions = 'Aktionen';
    _this.tableCopyHelper = 'Zwischenablage';
    _this.tableExportHelper = 'Excel-Export';
    _this.tableSettings = 'Spalten Einstellungen';
    _this.takeSnapshot = 'Bildschirmaufnahme';
    _this.temperature = 'Temperatur';
    _this.termsOfUse = 'Nutzungsbedingungen';
    _this.testLang = 'Sprachen test';
    _this.testLangWithOr = 'no context';
    _this.testLangWithOr_test = 'test context';
    _this.testLangWithPlural = 'singular';
    _this.testLangWithPlural_plural = 'plural';
    _this.testLangWithValues = 'Sprachen Test mit value! Deine Sprache: {{lang}}, heute: {{date}}!';
    _this.tester = 'Prüfer';
    _this.ticketCreateChange = 'Ticket erstellt, zugewiesen oder geändert';
    _this.tickets = 'Tickets';
    _this.ticketsCreator = 'Ticketersteller';
    _this.timeTrackingEndTimeNeeded = 'Endzeit benötigt bei Nacherfassungen';
    _this.toggleInput = 'Eingabemethode ändern';
    _this.tooltipEditProfileDetails = 'Profildetails bearbeiten';
    _this.tryAgain = 'Noch einmal probieren';
    _this.unauthorized = 'Keine Berechtigung';
    _this.undo = 'wiederherstellen';
    _this.unexpectedError = 'Diese Informationen können derzeit nicht angezeigt werden.\nBitte versuche es später noch einmal.';
    _this.uploadEndpointNotFound = 'Der Upload-Endpunkt ist nicht erreichbar. Bitte versuche es später noch ein mal!';
    _this.uploadFiles = 'Datei(en) hochladen';
    _this.uploadNoConnectionHint = 'Offline. Upload wird fortgesetzt, sobald eine Verbindung besteht';
    _this.uploadsRunning = 'Ein Upload läuft';
    _this.uploadsRunning_plural = '{{count}} Uploads laufen';
    _this.userImage = 'Benutzerbild';
    _this.userMailNotFound = 'E-Mail-Adresse nicht gefunden';
    _this.userNotFound = 'Benutzer nicht gefunden';
    _this.versionNumber = 'Version';
    _this.voiceControl = 'Aufnahme steuern';
    _this.voiceErrorGeneral = 'Konnte das Mikrofon nicht öffnen';
    _this.voiceErrorNotSupportedBrowser = 'Dein Browser wird zurzeit nicht für die Spracherkennung unterstützt. Nutze z.B Chrome oder Edge';
    _this.voiceErrorNotUnderstand = 'Ich habe dich nicht verstanden';
    _this.watcher = 'Beobachter';
    _this.webBeforeUnloadHint = 'Willst du die Seite wirklich verlassen? Es gehen nicht gespeicherte Änderungen verloren.';
    _this.webBeforeUnloadUploadHint = 'Datei Uploads sind noch nicht abgeschlossen und werden beim Verlassen abgebrochen.' + ' Willst du die Seite wirklich verlassen?';
    _this.widgetAddDialogTitle = 'Widget hinzufügen';
    _this.widgetMultiplePossible = 'Mehrfach verwendbar';
    _this.widgetOptionDialogAddTitle = 'Optionen';
    _this.widgetOptionDialogEditTitle = 'Optionen bearbeiten';
    _this.widgetSettings = 'Einstellungen';
    _this.wind = 'Wind';
    _this.yes = 'Ja';
    return _this;
  }
  (0, _inherits2.default)(MessagesDe, _AuthMessages);
  return (0, _createClass2.default)(MessagesDe);
}(_MessagesDe.MessagesDe);