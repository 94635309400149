"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogBookEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var ErrorReporter_1 = require("odatarepos/build/src/reporting/ErrorReporter");
var TicketEntity_1 = require("./TicketEntity");
var LogBookEntity = function (_ReadModelEntity_1$Re) {
  function LogBookEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, LogBookEntity);
    _this = _callSuper(this, LogBookEntity, [obj]);
    _this.entityName = 'LogBook';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(LogBookEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(LogBookEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new LogBookEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectTitle', new OdataEntity_1.OdataIndex());
      baseMap.set('userIds', new OdataEntity_1.OdataIndex(true));
      baseMap.set('readByUserIds', new OdataEntity_1.OdataIndex(true));
      baseMap.set('creator', new OdataEntity_1.OdataIndex(true));
      baseMap.set('creatorName', new OdataEntity_1.OdataIndex());
      baseMap.set('entityId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('entityTitle', new OdataEntity_1.OdataIndex());
      baseMap.set('entityType', new OdataEntity_1.OdataIndex(true));
      baseMap.set('eventName', new OdataEntity_1.OdataIndex(true));
      baseMap.set('data', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }], [{
    key: "createText",
    value: function () {
      var _createText = (0, _asyncToGenerator2.default)(function* (logbookItem, i18n, language, planModel, planVersionModel, projectModel, journalModel, currentUserId) {
        var text = '';
        var context = currentUserId === logbookItem.creator ? 'me' : '';
        try {
          var values = Object.assign({
            lng: language,
            context: context
          }, logbookItem, logbookItem.data, yield LogBookEntity.getEventBasedValues(logbookItem, i18n, language, planModel, planVersionModel, projectModel, journalModel, context, currentUserId));
          if (logbookItem.entityType === 'TicketComments' && logbookItem.eventName === 'TicketCommentAdded') {
            if (logbookItem.data.attachedFileId != null && logbookItem.data.attachedFileType === 'image') {
              text = i18n.getMessage('logBookTicketCommentAddedPicture', values);
            } else if (logbookItem.data.attachedFileId != null) {
              text = i18n.getMessage('logBookTicketCommentAddedFile', values);
            } else {
              text = i18n.getMessage('logBookTicketCommentAdded', values);
            }
          } else if (logbookItem.eventName === 'PendingApproval') {
            text = i18n.getMessage('logBookPendingApproval', values);
          } else if (logbookItem.eventName === 'TicketTypeChanged' && values['type'] == null) {
            text = i18n.getMessage('logBookTicketTypeRemoved', values);
          } else if (logbookItem.eventName === 'TicketCraftChanged' && values['craft'] == null) {
            text = i18n.getMessage('logBookTicketCraftRemoved', values);
          } else if (logbookItem.entityType === 'ProjectUser' && logbookItem.eventName === 'ProjectUserAdded') {
            if (values.creator === values.data.userId && values.data.accepted) {
              text = i18n.getMessage('logBookProjectUserAddedHimself', values);
            } else {
              text = i18n.getMessage('logBookProjectUserAdded', values);
            }
          } else if (logbookItem.entityType === 'ProjectUser' && logbookItem.eventName === 'ProjectUserRemoved') {
            if (values.creatorName === values.userName) {
              text = i18n.getMessage('logBookProjectUserRemovedHimself', values);
            } else {
              text = i18n.getMessage('logBookProjectUserRemoved', values);
            }
          } else {
            text = i18n.getMessage(`logBook${logbookItem.eventName}`, values);
          }
          if (text == null || text.length === 0 || text === `logBook${logbookItem.eventName}`) {
            if (logbookItem.entityType === 'Ticket') {
              text = i18n.getMessage('logBookTicketDefault', values);
            } else {
              text = `missing text: ${logbookItem.eventName}`;
            }
          }
          text = text.replace(/&quot;/g, '"');
          return text;
        } catch (e) {
          e.description = `item: ${JSON.stringify(logbookItem)}, details: ${e.description}`;
          ErrorReporter_1.ErrorReporter.sendReport({
            subject: `cant create logBook Text for logbookItem: ${e.name} - ${e.message}`,
            data: e,
            type: 'warn'
          });
          return '?';
        }
      });
      function createText(_x, _x2, _x3, _x4, _x5, _x6, _x7, _x8) {
        return _createText.apply(this, arguments);
      }
      return createText;
    }()
  }, {
    key: "getEventBasedValues",
    value: function () {
      var _getEventBasedValues = (0, _asyncToGenerator2.default)(function* (logbookItem, i18n, language, planModel, planVersionModel, projectModel, journalModel, currentContext, currentUserId) {
        var values = Object.assign({}, logbookItem.data);
        try {
          if (logbookItem.eventName === 'TicketAssignedChanged') {
            if (currentUserId != null && logbookItem.data.assignedToUserId === currentUserId) {
              values.context = `${currentContext}you`;
            } else if (logbookItem.data.assignedToUserId === logbookItem.creator) {
              values.context = 'himself';
            }
          } else if (logbookItem.eventName === 'TicketAssignerRemoved') {
            if (currentUserId != null && logbookItem.data.userId === currentUserId) {
              values.context = `${currentContext}you`;
            } else if (logbookItem.data.userId === logbookItem.creator) {
              values.context = 'himself';
            }
          } else if (logbookItem.eventName === 'TicketApproverChanged') {
            if (currentUserId != null && logbookItem.data.approverUserId === currentUserId) {
              values.context = `${currentContext}you`;
            } else if (logbookItem.data.approverUserId === logbookItem.creator) {
              values.context = 'himself';
            }
          } else if (logbookItem.eventName === 'TicketApproverRemoved') {
            if (currentUserId != null && logbookItem.data.userId === currentUserId) {
              values.context = `${currentContext}you`;
            } else if (logbookItem.data.userId === logbookItem.creator) {
              values.context = 'himself';
            }
          } else if (logbookItem.eventName === 'TicketWatcherRemoved' || logbookItem.eventName === 'TicketWatcherAdded') {
            if (currentUserId != null && logbookItem.data.userId === currentUserId) {
              values.context = `${currentContext}you`;
            } else if (logbookItem.data.userId === logbookItem.creator) {
              values.context = 'himself';
            }
          } else if (logbookItem.eventName === 'JournalSaved') {
            if (logbookItem.projectId != null) {
              values.formattedDate = i18n.date(language).localeDateString(logbookItem.data.forDay.toString());
            }
          } else if (logbookItem.eventName === 'JournalCreated') {
            if (logbookItem.projectId != null) {
              var journal = yield journalModel.getById(logbookItem.data.journalId);
              values.formattedDate = i18n.date(language).localeDateString(new Date(journal.forDay));
            }
          } else if (logbookItem.eventName === 'TicketStatusChanged') {
            if (logbookItem.projectId != null) {
              var project = yield projectModel.getById(logbookItem.projectId);
              values.status = i18n.getMessage(`ticketsDetailsState${TicketEntity_1.TicketStatus[logbookItem.data.ticketStatus]}`, {
                lng: language
              }, project.messagesOverride);
            }
          } else if (logbookItem.eventName === 'TicketTypeChanged') {
            if (logbookItem.data.ticketType == null) {
              values.type = null;
            } else if (typeof logbookItem.data.ticketType === 'number') {
              values.type = i18n.getMessage(`ticketType${TicketEntity_1.TicketTypes[logbookItem.data.ticketType]}`, {
                lng: language
              });
            } else {
              values.type = logbookItem.data.ticketType;
            }
          } else if (logbookItem.eventName === 'TicketArchiveStatusChanged') {
            if (values.archived) {
              values.context = `true${currentContext}`;
            } else {
              values.context = `false${currentContext}`;
            }
          } else if (logbookItem.eventName === 'TicketDeleteStatusChanged') {
            if (values.deleted) {
              values.context = `true${currentContext}`;
            } else {
              values.context = `false${currentContext}`;
            }
          } else if (logbookItem.eventName === 'PlanVersionDeleted') {
            var pv = yield planVersionModel.getById(logbookItem.entityId);
            values.version = pv.version;
          } else if (logbookItem.eventName === 'TicketCraftChanged') {
            if (logbookItem.data.craft == null) {
              values.craft = null;
            } else {
              values.craft = logbookItem.data.craft;
            }
          } else if (logbookItem.eventName === 'ProjectUserRoleChanged') {
            if (i18n.getMessage(`${logbookItem.data.roleName}`, {
              lng: language
            }) != null && i18n.getMessage(`${logbookItem.data.roleName}`, {
              lng: language
            }).length > 1) {
              values.roleName = i18n.getMessage(`${logbookItem.data.roleName}`, {
                lng: language
              });
            }
          } else if (logbookItem.eventName === 'TicketPlanAffiliationChanged') {
            var plan = yield planModel.getById(logbookItem.data.planId);
            values.planTitle = plan.title;
          } else if (logbookItem.eventName === 'TicketCompletionOnChanged') {
            var d = logbookItem.data.completionOn;
            values.date = d != null ? `${i18n.date(language).localeDateWithTimeString(d)}` : i18n.getMessage('undefined', {
              lng: language
            });
          } else if (logbookItem.eventName === 'StoredFileAdded' || logbookItem.eventName === 'StoredFileChanged' || logbookItem.eventName === 'StoredFileDeleted' || logbookItem.eventName === 'StoredFilenameChanged') {
            if (logbookItem.data.forEntityId != null && logbookItem.data.forEntityId.length > 0) {
              values.context = `${currentContext}ticket`;
            } else if (logbookItem.data.folderName != null) values.context = `${currentContext}folder`;
          }
        } catch (e) {
          e.description = `item: ${JSON.stringify(logbookItem)}, details: ${e.description}`;
          ErrorReporter_1.ErrorReporter.sendReport({
            subject: `cant getEventBasedValues for logbookItem`,
            data: e,
            type: 'warn'
          });
        }
        return values;
      });
      function getEventBasedValues(_x9, _x10, _x11, _x12, _x13, _x14, _x15, _x16, _x17) {
        return _getEventBasedValues.apply(this, arguments);
      }
      return getEventBasedValues;
    }()
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.LogBookEntity = LogBookEntity;