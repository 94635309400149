import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialTextWithOutToolTip';
import { MapMarker } from 'materialTheme/src/theme/components/zoom/MapMarker';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ActivatePlanVersion } from 'upmesh-core/src/client/commands/plans/ActivatePlanVersion';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { PlanZoomTileMapper } from './PlanZoomTileMapper';
export const PlanVersionTicketMappingDialog = React.memo((props) => {
    const [entrySize, setEntrySize] = useState({ width: 0, height: 0 });
    const [mapMarker, setMapMarker] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [oldMapInfo, setOldMapInfo] = useState(null);
    const [newMapInfo, setNewMapInfo] = useState(null);
    const onDragEnd = (id, mapMarker) => (newPosition) => {
        const index = mapMarker.findIndex((a) => a.id === id);
        if (mapMarker.length === 4 && index >= 0) {
            mapMarker[index].position = newPosition;
            mapMarker.forEach((mm) => (mm.onDragged = onDragEnd(mm.id, mapMarker)));
            setMapMarker([...mapMarker]);
        }
    };
    const updateMarker = (currentTickets) => {
        const { planVersionOld, planVersionNew } = props;
        if (currentTickets.length === 2 &&
            currentTickets[0].planPositionX != null &&
            currentTickets[0].planPositionY != null &&
            currentTickets[1].planPositionX != null &&
            currentTickets[1].planPositionY != null) {
            const m = [
                new MapMarker('0', 'blue', planVersionOld.getPointInPageFromRealpointInCM({
                    x: currentTickets[0].planPositionX,
                    y: currentTickets[0].planPositionY,
                }), undefined, undefined, true),
                new MapMarker('1', 'yellow', planVersionOld.getPointInPageFromRealpointInCM({
                    x: currentTickets[1].planPositionX,
                    y: currentTickets[1].planPositionY,
                }), undefined, undefined, true),
                new MapMarker('2', 'blue', planVersionNew.getPointInPageFromRealpointInCM({
                    x: currentTickets[0].planPositionX,
                    y: currentTickets[0].planPositionY,
                }), undefined, undefined, true),
                new MapMarker('3', 'yellow', planVersionNew.getPointInPageFromRealpointInCM({
                    x: currentTickets[1].planPositionX,
                    y: currentTickets[1].planPositionY,
                }), undefined, undefined, true),
            ];
            m.forEach((mm) => (mm.onDragged = onDragEnd(mm.id, m)));
            setMapMarker(m);
        }
        else if (oldMapInfo != null && newMapInfo != null) {
            const p0 = {
                x: oldMapInfo.orgImageWidth / 100,
                y: oldMapInfo.orgImageHeight / 10 - oldMapInfo.orgImageHeight / 100,
            };
            const p1 = {
                x: oldMapInfo.orgImageWidth / 10 - oldMapInfo.orgImageWidth / 100,
                y: oldMapInfo.orgImageHeight / 100,
            };
            const p2 = {
                x: newMapInfo.orgImageWidth / 100,
                y: newMapInfo.orgImageHeight / 10 - newMapInfo.orgImageHeight / 100,
            };
            const p3 = {
                x: newMapInfo.orgImageWidth / 10 - newMapInfo.orgImageWidth / 100,
                y: newMapInfo.orgImageHeight / 100,
            };
            const m = [
                new MapMarker('0', 'blue', p0, undefined, undefined, true),
                new MapMarker('1', 'yellow', p1, undefined, undefined, true),
                new MapMarker('2', 'blue', p2, undefined, undefined, true),
                new MapMarker('3', 'yellow', p3, undefined, undefined, true),
            ];
            m.forEach((mm) => (mm.onDragged = onDragEnd(mm.id, m)));
            setMapMarker(m);
        }
    };
    useEffect(() => {
        const { plan } = props;
        UpmeshClient.instance.modals.ticket
            .get({ filter: `planId eq '${plan.id}'`, top: 100 })
            .then((newTickets) => {
            if (newTickets.length > 1) {
                let maxDistance = 0;
                let ticket1 = null;
                let ticket2 = null;
                newTickets.forEach((t1) => {
                    newTickets.forEach((t2) => {
                        if (t1.planPositionX != null &&
                            t2.planPositionX != null &&
                            t1.planPositionY != null &&
                            t2.planPositionY != null) {
                            const distance = (t2.planPositionX - t1.planPositionX) ** 2 + (t2.planPositionY - t1.planPositionY) ** 2;
                            if (distance > maxDistance) {
                                maxDistance = distance;
                                ticket1 = t1;
                                ticket2 = t2;
                            }
                        }
                    });
                });
                if (ticket1 != null && ticket2 != null) {
                    setTickets([ticket1, ticket2]);
                    updateMarker([ticket1, ticket2]);
                }
            }
        })
            .catch((err) => console.debug(err));
    }, []);
    const onLayoutEntryText = (e) => {
        const { layout } = e.nativeEvent;
        setEntrySize(layout);
    };
    const onOldMapReady = (oldInfo) => {
        setOldMapInfo(oldInfo);
        updateMarker(tickets);
    };
    const onNewMapReady = (newInfo) => {
        setNewMapInfo(newInfo);
        updateMarker(tickets);
    };
    const renderTickets = () => {
        if (mapMarker.length === 0) {
            return (<View>
            <MaterialText>{I18n.m.getMessage('ticketMappingDialogTextNoTickets')}</MaterialText>
          </View>);
        }
        const { planVersionNew, planVersionOld, plan, size, dialogState } = props;
        let width = '50%';
        let direction = 'row';
        let maxHeight = 400;
        if (size != null && dialogState != null) {
            const isfullscreen = dialogState.fullscreen === true ||
                (dialogState.fullscreenResponsive === true &&
                    ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM);
            const contentHeight = isfullscreen ? size.contentHeight : 0.9 * size.windowHeight;
            maxHeight = contentHeight - 256 - entrySize.height;
            if (size.contentWidth < size.contentHeight) {
                direction = 'column';
                width = '100%';
                maxHeight /= 2;
            }
        }
        return (<View>
          <View onLayout={onLayoutEntryText}>
            <MaterialText>{I18n.m.getMessage('ticketMappingDialogText')}</MaterialText>
          </View>
          <View style={{ flexDirection: direction, width: '100%', alignSelf: 'center' }}>
            <View style={{ width }}>
              <MaterialText fixedWidth="100%" centeredText type={MaterialTextTypes.H6}>
                {I18n.m.getMessage('ticketMappingDialogOld')}
              </MaterialText>
              <View style={{
                height: maxHeight,
                margin: 8,
                padding: 1,
                ...ThemeManager.style.borderStyle,
                borderRadius: ThemeManager.style.borderRadius,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <PlanZoomTileMapper onMapReady={onOldMapReady} plan={plan} clusteringDisabled planVersion={planVersionOld} type="plan" marker={mapMarker.length > 3 ? [mapMarker[0], mapMarker[1]] : undefined} zoomAble offlineData={OfflineDataDownloader.isPlanVersionSynced(planVersionOld.projectId, planVersionOld.id)} key={`planVersion_${planVersionOld.id}_${mapMarker.length}`} url={`${UpmeshClient.instance.url}/plan/tiles/${planVersionOld.projectId}/planVersion/${planVersionOld.id}`}/>
              </View>
            </View>
            <View style={{ width }}>
              <MaterialText fixedWidth="100%" centeredText type={MaterialTextTypes.H6}>
                {I18n.m.getMessage('ticketMappingDialogNew')}
              </MaterialText>
              <View style={{
                height: maxHeight,
                margin: 8,
                padding: 1,
                ...ThemeManager.style.borderStyle,
                borderRadius: ThemeManager.style.borderRadius,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <PlanZoomTileMapper onMapReady={onNewMapReady} clusteringDisabled plan={plan} planVersion={planVersionNew} type="plan" marker={mapMarker.length > 3 ? [mapMarker[2], mapMarker[3]] : undefined} zoomAble offlineData={OfflineDataDownloader.isPlanVersionSynced(planVersionNew.projectId, planVersionNew.id)} key={`planVersion_${planVersionNew.id}_${mapMarker.length}`} url={`${UpmeshClient.instance.url}/plan/tiles/${planVersionNew.projectId}/planVersion/${planVersionNew.id}`}/>
              </View>
            </View>
          </View>
        </View>);
    };
    const activatePlanVersion = (id) => (_e) => {
        const { planVersionOld } = props;
        setIsLoading(false);
        try {
            let markerScaleShift = { scale: 1, shift: { x: 0, y: 0 } };
            if (mapMarker.length === 4) {
                const distanceOld = Math.sqrt((mapMarker[1].position.x - mapMarker[0].position.x) ** 2 +
                    (mapMarker[1].position.y - mapMarker[0].position.y) ** 2);
                const distanceNew = Math.sqrt((mapMarker[3].position.x - mapMarker[2].position.x) ** 2 +
                    (mapMarker[3].position.y - mapMarker[2].position.y) ** 2);
                const scale = distanceNew / distanceOld;
                const scaledOldPoint1 = { x: mapMarker[1].position.x * scale, y: mapMarker[1].position.y * scale };
                const shift = {
                    x: mapMarker[3].position.x - scaledOldPoint1.x,
                    y: mapMarker[3].position.y - scaledOldPoint1.y,
                };
                markerScaleShift =
                    planVersionOld.markerScaleShift == null
                        ? { scale, shift }
                        : {
                            scale: scale * planVersionOld.markerScaleShift.scale,
                            shift: {
                                x: shift.x + planVersionOld.markerScaleShift.shift.x,
                                y: shift.y + planVersionOld.markerScaleShift.shift.y,
                            },
                        };
            }
            const { plan } = props;
            const activate = new ActivatePlanVersion({ markerScaleShift, activePlanId: id }, plan.id);
            activate
                .execute()
                .then(() => {
                Dialog.instance?.close();
            })
                .catch((err) => {
                setIsLoading(false);
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        }
        catch (err) {
            setIsLoading(false);
            DefaultErrorHandler.showDefaultErrorAlert(err);
        }
    };
    return (<View>
        <DialogTitle key="Mapping_Dialog">
          {I18n.m.getMessage(mapMarker.length === 0 ? 'ticketMappingDialogTitleNoTickets' : 'ticketMappingDialogTitle')}
        </DialogTitle>
        <DialogContent scrollDisabled key="Mapping_Content">
          {renderTickets()}
        </DialogContent>
        <DialogActions onBottom key="Mapping_Actions">
          <ContainedButton title={I18n.m.getMessage('cancel')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={() => Dialog.instance?.close()}/>
          <ContainedButton title={I18n.m.getMessage('ticketMappingDialogAction')} textColor="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary} onPress={activatePlanVersion(props.planVersionNew.id)} loading={isLoading}/>
        </DialogActions>
      </View>);
});
export const openPlanVersionTicketMappingDialog = (props) => (e) => {
    let openPosition;
    if (e != null && e.nativeEvent != null && e.nativeEvent.pageX != null && e.nativeEvent.pageY != null) {
        openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    }
    Dialog.instance?.open({
        content: <PlanVersionTicketMappingDialog {...props}/>,
        fullscreenResponsive: true,
        openPosition,
        scrollable: false,
        contentPadding: false,
        width: 1280,
    });
};
