import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
export function TimeTrackingCalendarDay({ onPress, sMode, holidayTooltip, isSelected, withMonth, date, diffTime, timeTotalString, diffTimeString, }) {
    const fontSize = ThemeManager.style.getScreenRelativePixelSize(sMode ? 12 : 14);
    const renderDateDisplay = () => withMonth || date.getDate() === 1 ? `${date.getDate()}.${date.getMonth() + 1}` : date.getDate();
    const renderTimeDetails = () => diffTime || timeTotalString !== '00:00' ? (<View collapsable={false} style={{
            top: sMode ? 12 : 0,
            position: 'absolute',
            right: 4,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
        }}>
        <MaterialText numberOfLines={1} fontSize={fontSize} fixedWidth="100%" textAlign="right" type={MaterialTextTypes.Body2}>
          {timeTotalString}
        </MaterialText>
        <MaterialText numberOfLines={1} fontSize={fontSize} fixedWidth="100%" textAlign="right" color={diffTime < 0 ? ThemeManager.style.brandDanger : ThemeManager.style.brandSuccess} type={MaterialTextTypes.Body2}>
          {`${diffTime > 0 ? '+ ' : ''}${diffTimeString}`}
        </MaterialText>
      </View>) : null;
    return (<Ripple onPress={onPress} key={`renderedDay_${date}`} toolTip={holidayTooltip} style={{
            flex: 1,
            position: 'relative',
            flexDirection: sMode ? 'column' : 'row',
            borderLeftColor: ThemeManager.style.borderColor,
            borderLeftWidth: ThemeManager.style.borderWidth,
            alignItems: 'center',
            backgroundColor: isSelected ? 'rgba(0, 115, 255, 0.1)' : '#FFFFFF',
        }}>
      <View style={{ top: 0, left: 0, position: 'absolute' }}>
        <MaterialText type={MaterialTextTypes.Caption} color={holidayTooltip.length ? ThemeManager.style.brandDanger : ThemeManager.style.black87} centeredText centeredBox>
          {renderDateDisplay()}
        </MaterialText>
      </View>
      {renderTimeDetails()}
    </Ripple>);
}
