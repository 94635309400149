import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Dialog, DialogState } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { KeyboardEvents } from 'materialTheme/src/theme/components/utils/KeyboardEvents';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { CompanyUser } from '../../companies/member/CompanyUser';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
export function PayrollMemberSelector(props) {
    const [data, setData] = useState();
    const loadData = async () => {
        if (CompanyUserInfo.company == null)
            return;
        const d = await UpmeshClient.instance.modals.payrollAccounting.get({
            filter: `periodId eq '${props.periodId}'`,
        });
        const d2 = [];
        for (const item of d) {
            if (d2.findIndex((a) => a.member.id === item.memberId) === -1) {
                const a = await CompanyUser.load(item.memberId);
                d2.push(a);
            }
        }
        setData(d2);
    };
    useEffect(() => {
        loadData().catch((err) => console.error(err));
    }, []);
    const prev = () => {
        if (data != null && data.length > 0) {
            const selectedIndex = data.findIndex((a) => a.member.id === props.selectedId) + 1;
            if (data.length > selectedIndex)
                props.onChange(data[selectedIndex].member.id);
        }
    };
    const next = () => {
        if (data != null && data.length > 0) {
            const selectedIndex = data.findIndex((a) => a.member.id === props.selectedId) - 1;
            if (data.length > selectedIndex && selectedIndex > -1)
                props.onChange(data[selectedIndex].member.id);
        }
    };
    useEffect(() => {
        KeyboardEvents.onKeyDown((e) => {
            if (Dialog.instance?.dialogState === DialogState.opened)
                return;
            switch (e.keyCode) {
                case 37:
                    e.preventDefault();
                    if (e.altKey)
                        prev();
                    break;
                case 39:
                    e.preventDefault();
                    if (e.altKey)
                        next();
                    break;
            }
        }, 'PayrollMemberSelector');
        return () => {
            KeyboardEvents.remove('PayrollMemberSelector');
        };
    }, [props.selectedId, data]);
    const openSelector = (e) => {
        if (data == null || data.length === 0) {
            return;
        }
        const p = [];
        for (const member of data) {
            p.push(<Ripple style={{ width: '100%', padding: 8, flexDirection: 'row', alignItems: 'center' }} onPress={() => {
                    Dialog.instance?.close(() => {
                        props.onChange(member.member.id);
                    });
                }}>
          <UserImage user={member.user} size={48}/>
          <View style={{ width: 6 }}/>
          <MaterialText centeredText centeredBox>{`${member.getName()}`}</MaterialText>
        </Ripple>);
        }
        const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
        Dialog.instance?.open({
            openPosition,
            contentPadding: false,
            content: <DialogContent>{p}</DialogContent>,
        });
    };
    if (data == null || data.length === 0)
        return null;
    const selectedIndex = data.findIndex((a) => a.member.id === props.selectedId);
    if (selectedIndex === -1)
        return null;
    const selected = data[selectedIndex];
    return (<View>
      <Chip thumbnail={<Icon icon="chevron-left" disabled={selectedIndex >= data.length} toolTip={`${I18n.m.getMessage('prev')} (${I18n.m.getMessage('hotkeyAltLeft')})`} onPress={prev}/>} backgroundColor="#FFFFFF" removeIconSize={24} removeIcon="chevron-right" removeIconTooltip={`${I18n.m.getMessage('next')} (${I18n.m.getMessage('hotkeyAltRight')})`} button onPressButton={next} title={`${selected.getName()}`} onPressText={openSelector}/>
    </View>);
}
