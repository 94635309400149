import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../../config/CachedEntities';
export class CompanyUser {
    static async load(memberId, update = false) {
        const c = new CompanyUser();
        if (!update && CompanyUser.currentMemberList != null && CompanyUser.currentMemberList.length > 0) {
            const find = CompanyUser.currentMemberList.find((a) => a.id === memberId);
            if (find != null)
                c.member = find;
        }
        if (c.member == null)
            c.member = await UpmeshClient.instance.modals.companyMember.getById(memberId);
        if (c.member.userId != null) {
            c.user = CachedEntities.knownUsers.get(c.member.userId);
        }
        return c;
    }
    getName() {
        if (this.user)
            return this.user.getFullName();
        return `${this.member.firstName} ${this.member.lastName}`;
    }
}
CompanyUser.currentMemberList = [];
