import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateCostCenter } from 'upmesh-core/src/client/commands/companies/costcenter/CreateCostCenter';
import { CreateProjectWithCostUnit, } from 'upmesh-core/src/client/commands/project/CreateProjectWithCostUnit';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { ColorComponent } from './ColorComponent';
import { CompanyProjectStatePicker } from './CompanyProjectStatePicker';
export function CreateProjectDialog(props) {
    const [costUnitSelection, setCostUnitSelection] = useState(0);
    const [newCostUnit, setNewCostUnit] = useState({
        mark: undefined,
        name: undefined,
    });
    const [costUnits, setCostUnits] = useState([]);
    const [currentFormData, setCurrentFormData] = useState({
        title: '',
        projectSubscription: CompanyUserInfo.me != null ? 'enterprise' : 'basic',
    });
    const [userSubscription, setUserSubscription] = useState(null);
    const [hasTimeTracking, setHasTimeTracking] = useState(false);
    const [addToChip, setAddToChip] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [newId] = useState(uuid.v1());
    const [projectTemplates, setProjectTemplates] = useState([]);
    const initCostCenters = async () => {
        const { company } = CompanyUserInfo;
        if (company != null) {
            const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                filter: `deleted ne true and companyId eq '${company.id}' and costType eq 'costUnit' and active ne false`,
                orderby: 'mark',
            });
            const qCostUnits = [];
            costCenters.forEach((c, i) => {
                qCostUnits.push({ title: `${c.mark} - ${c.description}`, onPressChipData: { id: c.id, index: i } });
            });
            setCostUnits(qCostUnits);
            setAddToChip(qCostUnits[0] ? qCostUnits[0].onPressChipData : undefined);
        }
    };
    const initTemplates = async () => {
        const { company } = CompanyUserInfo;
        if (company != null) {
            const templateProjects = await UpmeshClient.instance.modals.project.get({
                filter: `template eq true and deleted ne true and creator eq 'company${company.id}'`,
            });
            if (templateProjects.length > 0) {
                setProjectTemplates(templateProjects);
            }
        }
    };
    useEffect(() => {
        RightsManager.hasVersion(CurrentUser.userId)
            .then((userSubscription) => {
            setUserSubscription(userSubscription);
        })
            .catch((err) => {
            console.error('cant get version', err);
        });
        const settings = CompanyUserInfo.companySettings;
        if (settings != null) {
            if (settings.hasModule('timeTracking')) {
                setHasTimeTracking(true);
            }
            initCostCenters().catch((err) => {
                console.error('cant init cost centers in create project dialog', err);
            });
            initTemplates().catch((err) => {
                console.error('cant get project templates', err);
            });
            UpmeshClient.instance.modals.project
                .get({
                filter: `template eq true and deleted ne true and creator eq 'company${settings.id}'`,
            })
                .then((templateProjects) => {
                if (templateProjects.length > 0) {
                    setProjectTemplates(templateProjects);
                }
            })
                .catch((err) => {
                console.error('cant init template projects', err);
            });
        }
    }, []);
    const newCostUnitComponent = () => {
        if (costUnitSelection === 1) {
            return (<View>
          <FormInputFilled initValue={newCostUnit.mark} labelText={I18n.m.getMessage('costCenterMark')} onChange={(v) => {
                    setNewCostUnit({ mark: v, name: newCostUnit.name });
                }}/>
          <FormInputFilled initValue={newCostUnit.name} labelText={I18n.m.getMessage('costCenterDescription')} onChange={(v) => {
                    setNewCostUnit({ mark: newCostUnit.mark, name: v });
                }}/>
        </View>);
        }
        return null;
    };
    const addToCostUnitComponent = () => {
        if (costUnitSelection === 2) {
            return (<View>
          <OpenableChip looksLikeFI dialogTitle={I18n.m.getMessage('createProjectAddCostUnitLabel')} formInputLabel={I18n.m.getMessage('createProjectAddCostUnitLabel')} chipsList={costUnits} onPressChip={(d) => {
                    setAddToChip(d);
                }} selected={addToChip ? addToChip.index : 0}/>
        </View>);
        }
        return null;
    };
    const costComponent = () => {
        const cList = [I18n.m.getMessage('createProjectNoCostUnit'), I18n.m.getMessage('createProjectNewCostUnit')];
        if (costUnits.length > 0) {
            cList.push(I18n.m.getMessage('createProjectAddCostUnit'));
        }
        return (<View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ width: '100%' }}>
            <FormInputPicker labelText={I18n.m.getMessage('costUnit')} selectedIndex={costUnitSelection} list={cList} onChange={(value) => {
                let newCostUnit = { mark: '', name: '' };
                if (value.index === 1) {
                    if (currentFormData.title != null) {
                        newCostUnit = { mark: currentFormData.title, name: currentFormData.title };
                    }
                }
                setCostUnitSelection(value.index);
                setNewCostUnit(newCostUnit);
            }}/>
          </View>
        </View>
        {newCostUnitComponent()}
        {addToCostUnitComponent()}
      </View>);
    };
    const colorComponent = (props) => {
        return (<View style={{ paddingBottom: 21 }}>
        <ColorComponent {...props} initColor="#0073ff"/>
      </View>);
    };
    const waitForProject = async (projectId, maxWaitTime = 5000) => {
        return await new Promise((resolve) => {
            const key = uuid.v4();
            const checkAndResolve = (en) => {
                en.entities.forEach((e) => {
                    if (e.entity?.id === projectId) {
                        resolve(projectId);
                    }
                });
            };
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'Project',
                attachKey: key,
                callback: checkAndResolve,
            });
            setTimeout(resolve, maxWaitTime);
        });
    };
    const onSaved = (_result) => {
        waitForProject(newId)
            .then((value) => {
            if (value != null && value.length > 0) {
                Routing.instance.goTo(`/projects/${newId}/project`);
            }
            else {
                Dialog.instance?.close();
                Toast.instance?.open({
                    title: I18n.m.getMessage('createProjectFromTemplateHint'),
                });
                waitForProject(newId, 100 * 1000)
                    .then((wResult) => {
                    if (wResult != null && wResult.length > 0) {
                        Toast.instance?.open({
                            title: I18n.m.getMessage('createProjectFromTemplateFinish'),
                            onPressButton: () => {
                                Toast.instance?.close();
                                Routing.instance.goTo(`projects/${newId}/project`);
                            },
                            buttonTitle: I18n.m.getMessage('enterProject'),
                        });
                    }
                })
                    .catch((err) => {
                    console.error('error on long project create', err);
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        })
            .catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    const stateComponent = (props) => {
        return (<CompanyProjectStatePicker onPressChip={(e) => {
                const projectState = e;
                setSelectedState(projectState);
            }} projectId="" {...props}/>);
    };
    const getTemplatePickerList = () => {
        const list = [];
        list.push({
            title: 'Keine Vorlage',
            data: {
                id: '',
            },
        });
        projectTemplates.forEach((cPr) => {
            list.push({
                title: cPr.title,
                data: {
                    id: cPr.id,
                },
            });
        });
        return list;
    };
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
    const onChangeTemplate = (val) => {
        const templateProject = projectTemplates.find((elem) => elem.id === val.value.data.id);
        if (templateProject != null) {
            const templateFormData = {
                constructionEnd: templateProject.constructionEnd,
                constructionStart: templateProject.constructionStart,
                description: templateProject.description,
                projectEnd: templateProject.projectEnd,
                projectStart: templateProject.projectStart,
                projectType: templateProject.projectType,
                color: templateProject.color,
                template: templateProject.id,
                projectSubscription: templateProject.projectSubscription,
            };
            setCurrentFormData(templateFormData);
        }
        else {
            const cData = currentFormData;
            cData.template = undefined;
            setCurrentFormData(cData);
        }
        setSelectedTemplateIndex(val.index);
    };
    const templateComponent = () => {
        return (<FormInputPicker labelText="Projekt aus Vorlage erstellen" list={getTemplatePickerList()} selectedIndex={selectedTemplateIndex} onChange={onChangeTemplate}/>);
    };
    const beforeSaveForm = async (_e) => {
        if (costUnitSelection === 1 && CompanyUserInfo.company?.id != null) {
            const command = new CreateCostCenter({
                costType: 'costUnit',
                mark: newCostUnit.mark != null ? newCostUnit.mark : '',
                description: newCostUnit.name != null ? newCostUnit.name : '',
                companyId: CompanyUserInfo.company?.id,
                active: true,
            }, uuid.v1());
            try {
                await command.canI();
                await command.validate();
                return { canSave: true };
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e);
                return { canSave: false };
            }
        }
        return { canSave: true };
    };
    if (userSubscription == null) {
        return (<View style={{ width: '100%', height: 200, alignItems: 'center', padding: ThemeManager.style.contentPaddingValue }}>
        {props['open'] ? <Spinner /> : null}
      </View>);
    }
    const pickerList = [];
    if ((userSubscription === 'enterprise' || userSubscription === 'proAndEnterprise') &&
        (CompanyUserInfo.me?.role === 'admin' || CompanyUserInfo.me?.role === 'user')) {
        pickerList.push({
            title: I18n.m.getMessage('projectCreationOwnerCompany'),
            data: 'enterprise',
        });
    }
    pickerList.push({
        title: I18n.m.getMessage('projectCreationOwnerMe', {
            context: userSubscription === 'pro' || userSubscription === 'proAndEnterprise' ? 'pro' : 'basic',
        }),
        data: userSubscription === 'pro' || userSubscription === 'proAndEnterprise' ? 'pro' : 'basic',
    });
    const width = ResizeEvent.current.windowWidth;
    const dataOptions = new Map();
    if (projectTemplates.length > 0) {
        dataOptions.set('template', {
            formType: 'individiual',
            cols: 2,
            props: { labelText: 'Vorlage' },
            component: templateComponent,
        });
    }
    dataOptions.set('title', {
        formType: 'string',
        cols: 2,
        props: { labelText: I18n.m.getMessage('projectName') },
    });
    dataOptions.set('projectType', {
        formType: 'string',
        optional: true,
        cols: 2,
        props: { labelText: I18n.m.getMessage('projectType') },
    });
    if (currentFormData.projectSubscription === 'enterprise' &&
        CompanyUserInfo.companySettings != null &&
        CompanyUserInfo.companySettings.projectStateNames.length > 0) {
        dataOptions.set('custom_project_data', {
            formType: 'individiual',
            optional: true,
            cols: 2,
            props: { labelText: I18n.m.getMessage('projectType') },
            component: stateComponent,
        });
    }
    dataOptions.set('projectStart', {
        formType: 'Date',
        optional: true,
        cols: width <= ThemeManager.style.breakpointM ? 2 : 1,
        props: { labelText: I18n.m.getMessage('projectStart') },
    });
    dataOptions.set('projectEnd', {
        formType: 'Date',
        optional: true,
        cols: width <= ThemeManager.style.breakpointM ? 2 : 1,
        props: { labelText: I18n.m.getMessage('projectEnd') },
    });
    dataOptions.set('constructionStart', {
        formType: 'Date',
        optional: true,
        cols: width <= ThemeManager.style.breakpointM ? 2 : 1,
        props: { labelText: I18n.m.getMessage('projectBuildingStart') },
    });
    dataOptions.set('constructionEnd', {
        formType: 'Date',
        optional: true,
        cols: width <= ThemeManager.style.breakpointM ? 2 : 1,
        props: { labelText: I18n.m.getMessage('projectBuildingEnd') },
    });
    dataOptions.set('description', {
        formType: 'string',
        optional: true,
        cols: 2,
        props: { labelText: I18n.m.getMessage('projectDescription'), numberOfLines: 4 },
    });
    if (pickerList.length > 1) {
        dataOptions.set('projectSubscription', {
            formType: 'Picker',
            cols: 2,
            props: { labelText: I18n.m.getMessage('projectOwner'), list: pickerList },
            mapValue: (value, fromFormInput) => {
                if (fromFormInput)
                    return value.value.data;
                let index = 0;
                for (let i = 0; i < pickerList.length; i += 1) {
                    if (pickerList[i].data === value) {
                        index = i;
                        break;
                    }
                }
                const value2 = pickerList[index];
                return { index, value: value2 };
            },
        });
    }
    if (!(currentFormData.projectSubscription === 'enterprise' &&
        CompanyUserInfo.companySettings != null &&
        CompanyUserInfo.companySettings.projectStateNames.length > 0)) {
        dataOptions.set('color', {
            formType: 'individiual',
            optional: true,
            cols: 2,
            props: { labelText: I18n.m.getMessage('projectType') },
            component: colorComponent,
        });
    }
    if ((userSubscription === 'enterprise' || userSubscription === 'proAndEnterprise') &&
        currentFormData.projectSubscription === 'enterprise' &&
        hasTimeTracking) {
        dataOptions.set('extraData', {
            formType: 'individiual',
            optional: true,
            cols: 2,
            props: { labelText: I18n.m.getMessage('createProjectAddCostUnit') },
            component: costComponent,
        });
    }
    const getCustomFormData = () => {
        const cCreateProjectData = currentFormData;
        if (costUnitSelection === 1 && CompanyUserInfo.company?.id != null) {
            cCreateProjectData.createCostUnitData = {
                costType: 'costUnit',
                mark: newCostUnit.mark ?? '',
                description: newCostUnit.name ?? '',
                companyId: CompanyUserInfo.company.id,
                active: true,
            };
        }
        if (costUnitSelection === 2 && addToChip != null) {
            cCreateProjectData.costUnitId = addToChip.id;
        }
        if (selectedState != null && selectedState.id !== '-1') {
            cCreateProjectData.companyProjectStateId = selectedState.id;
        }
        return cCreateProjectData;
    };
    return (<Form key={JSON.stringify(currentFormData, ['template'])} formHeaderProps={{ formTitle: I18n.m.getMessage('projectCreateTitle') }} baseCols={2} beforeSave={beforeSaveForm} stickyActions stickyHeader command={new CreateProjectWithCostUnit(getCustomFormData(), newId)} store={ClientStore.commandStore} saveButtonLabel={I18n.m.getMessage('create')} dataOptions={dataOptions} resetButton={false} onSaved={onSaved} onChangeData={(nextCurrentFormData) => {
            setCurrentFormData(nextCurrentFormData);
        }}/>);
}
