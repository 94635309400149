import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { CustomFieldPersonOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { chipList } from '../../project/settings/ticketlayouts/TicketLayoutPersonFieldOptions';
import { ProjectMemberDetailsPU } from '../../team/ProjectMemberDetailsPU';
export const PersonChip = React.memo((props) => {
    const o = new CustomFieldPersonOptions(props.personField?.customField?.options);
    const [selectedChip, setSelectedChip] = useState(o.person ? o.person : '');
    const [assignedUserId, setAssignedUserId] = useState('');
    useEffect(() => {
        if (props.ticketFields != null) {
            const arr = chipList();
            props.ticketFields.forEach((field) => {
                if (field.id === props.personField.id) {
                    setAssignedUserId(field.value);
                    for (let i = 0; i < arr.length; i += 1) {
                        if (arr[i].onPressChipData === field.value || field.value === '0') {
                            setSelectedChip(field.value);
                            break;
                        }
                    }
                }
            });
        }
    }, [props.ticketFields]);
    const getChipIndexHandler = (e) => {
        const arr = chipList();
        for (let i = 0; i < arr.length; i += 1) {
            if (arr[i].onPressChipData === e) {
                return i;
            }
        }
        return -1;
    };
    const changeSelectedPersonHandler = (e) => {
        setSelectedChip(e);
        if (props.ticketId != null) {
            try {
                const c = new ChangeTicketCustomField({ fieldId: props.personField.id, value: e }, props.ticketId);
                c.execute().catch((err) => props.errorResult(err));
            }
            catch (e) {
                props.errorResult(e);
            }
        }
    };
    const openMemberDetails = async (_e) => {
        const projectUser = await UpmeshClient.instance.modals.projectUser.get({
            filter: `projectId eq '${props.projectId}' and userId eq '${assignedUserId}' and status eq 'accepted'`,
        });
        const projectUserId = projectUser.length === 0 ? `creator_${assignedUserId}` : projectUser[0].id;
        Dialog.instance?.open({
            content: (<ProjectMemberDetailsPU projectId={props.projectId} data={{ projectUserId, userId: assignedUserId }} closeFunction={Dialog.instance?.close}/>),
        });
    };
    return (<>
      <View style={props.labelStyle} key="PersonLabel">
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {props.personField.label}
        </MaterialText>
      </View>
      <OpenableChip key="TicketPersonChip" showDeleteIcon emptyStateChip={{
            thumbnail: (<View style={{
                    borderStyle: 'dashed',
                    borderWidth: ThemeManager.style.borderWidth,
                    borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                    width: ThemeManager.style.getScreenRelativePixelSize(24),
                    height: ThemeManager.style.getScreenRelativePixelSize(24),
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}>
              <Icon toolTip="" icon="help" iconSize={20} outerSize={ThemeManager.style.getScreenRelativePixelSize(24)} radius={0}/>
            </View>),
            title: I18n.m.getMessage('ticketsDetailsTitleNotAssignedToUser'),
            onPressChipData: '0',
        }} accessibilityLabel="assignedPerson" elevation={40} selected={getChipIndexHandler(selectedChip)} onPressChip={changeSelectedPersonHandler} widthOpen={256} textColor={ThemeManager.style.black87} chipsList={chipList()} onPressMainChip={assignedUserId !== '0' && assignedUserId !== '' ? openMemberDetails : undefined}/>
    </>);
});
