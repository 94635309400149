import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import React, { PureComponent } from 'react';
import { ChangeCompanyUserForm } from './ChangeCompanyUserForm';
export class ChangeCompanyUserDialog extends PureComponent {
    render() {
        const { companyMember } = this.props;
        return (<ChangeCompanyUserForm companyMember={companyMember} cancel={(_e) => {
                Dialog.instance?.close();
            }} saved={() => {
                Dialog.instance?.close();
            }}/>);
    }
}
