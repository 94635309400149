var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChipGroup = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _Dialog = require("../Dialog");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _Chip = require("./Chip");
var _ChipDialogForm = require("./ChipDialogForm");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var getListItem = function getListItem(chip, selected, chipGroups) {
  var secondTextLine = chip.secondTextLine,
    thirdTextLine = chip.thirdTextLine;
  var thumbnail = chip.thumbnail ? {
    thumbnail: chip.thumbnail,
    width: 24,
    rounded: true
  } : undefined;
  if (chipGroups != null && chip.groupId != null) {
    var group = chipGroups.find(function (group) {
      return group.id === chip.groupId;
    });
    if (group != null) {
      if (secondTextLine == null) {
        secondTextLine = group.groupName;
      } else if (thirdTextLine == null) {
        thirdTextLine = group.groupName;
      }
      if (thumbnail == null && group.backgroundColor && group.backgroundColor.length > 0) {
        thumbnail = {
          width: 24,
          rounded: true,
          thumbnail: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              height: 24,
              width: 24,
              borderRadius: 12,
              backgroundColor: group.backgroundColor
            }
          })
        };
      }
    }
  }
  return {
    selected: selected,
    secondTextLine: secondTextLine,
    thirdTextLine: thirdTextLine,
    thumbnail: thumbnail,
    title: chip.title,
    groupId: chip.groupId,
    id: chip.id ? chip.id : chip.title
  };
};
var createAutoCompleteList = function createAutoCompleteList(availableChips, chips, chipGroups) {
  if (availableChips.length === 0) return [];
  var listItems = [];
  if (chips != null && chips.length > 0) {
    availableChips.forEach(function (aChip) {
      var includes = false;
      var j = 0;
      do {
        if (aChip.id === chips[j].id) {
          includes = true;
        }
        j += 1;
      } while (!includes && j < chips.length);
      if (aChip.title != null) {
        listItems.push(getListItem(aChip, includes, chipGroups));
      }
    });
    chips.forEach(function (chip) {
      if (listItems.findIndex(function (li) {
        return li['id'] === chip.id;
      }) === -1) {
        listItems.push(getListItem(chip, true, chipGroups));
      }
    });
  } else {
    availableChips.forEach(function (aChip) {
      if (aChip.title != null) {
        var chipListItem = getListItem(aChip, false, chipGroups);
        listItems.push(chipListItem);
      }
    });
  }
  return listItems;
};
var ChipGroup = exports.ChipGroup = _react.default.memo(function (props) {
  var _props$availableChips, _props$chips, _ref, _props$chips2;
  var _useState = (0, _react.useState)(createAutoCompleteList((_props$availableChips = props.availableChips) != null ? _props$availableChips : [], (_props$chips = props.chips) != null ? _props$chips : [], props.chipGroups)),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    autocompleteList = _useState2[0],
    setAutocompleteList = _useState2[1];
  var _useState3 = (0, _react.useState)((_ref = (_props$chips2 = props.chips) != null ? _props$chips2 : props.initChips) != null ? _ref : []),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    chips = _useState4[0],
    setChips = _useState4[1];
  (0, _react.useEffect)(function () {
    var _props$availableChips2, _props$chips3;
    if (props.chips) setChips(props.chips);
    setAutocompleteList(createAutoCompleteList((_props$availableChips2 = props.availableChips) != null ? _props$availableChips2 : [], (_props$chips3 = props.chips) != null ? _props$chips3 : [], props.chipGroups));
  }, [props.chips, props.availableChips, props.chipGroups]);
  var onSave = (0, _react.useCallback)(function (items) {
    var _Dialog$instance;
    var onChanged = props.onChanged;
    var availableChips = props.availableChips ? props.availableChips.slice() : chips;
    var newChips = [];
    var _loop = function _loop(i) {
      if (items[i] != null && items[i].selected) {
        var invisibleIndex = availableChips.findIndex(function (chip) {
          return chip.id === items[i].id;
        });
        if (invisibleIndex === -1) {
          var createdChip = {
            title: items[i].title,
            groupId: items[i].groupId
          };
          newChips.push(createdChip);
        } else {
          newChips.push(availableChips[invisibleIndex]);
        }
      }
    };
    for (var i = 0; i < items.length; i += 1) {
      _loop(i);
    }
    (_Dialog$instance = _Dialog.Dialog.instance) == null || _Dialog$instance.close();
    if (onChanged != null) onChanged([].concat(newChips));
    setChips(newChips);
    setAutocompleteList((0, _toConsumableArray2.default)(items));
  }, [props.onChanged, props.availableChips, autocompleteList, chips]);
  var onAddedNewItem = (0, _react.useCallback)(function (chip, groupId) {
    var onAddedNewItem = props.onAddedNewItem;
    var newChips = (0, _toConsumableArray2.default)(chips);
    newChips.push(Object.assign({}, chip, {
      groupId: groupId || chip.groupId
    }));
    setChips(newChips);
    if (onAddedNewItem != null) onAddedNewItem(chip, groupId);
  }, [props.onAddedNewItem, chips]);
  var openDialog = function openDialog() {
    var _Dialog$instance2, _Dialog$instance3;
    var dialogTitle = props.dialogTitle,
      dialogIcon = props.dialogIcon,
      canAddNewChips = props.canAddNewChips,
      addNewText = props.addNewText,
      checkBeforeEnterNewText = props.checkBeforeEnterNewText,
      searchBarPlaceholder = props.searchBarPlaceholder,
      label = props.label,
      sortFormByGroup = props.sortFormByGroup,
      chipGroups = props.chipGroups,
      addNewButtons = props.addNewButtons,
      canAddNewChipsViaSearchbar = props.canAddNewChipsViaSearchbar,
      disableAutoSort = props.disableAutoSort;
    (_Dialog$instance2 = _Dialog.Dialog.instance) == null || _Dialog$instance2.open({
      content: (0, _jsxRuntime.jsx)(_ChipDialogForm.ChipDialogForm, {
        title: dialogTitle != null ? dialogTitle : label,
        icon: dialogIcon,
        items: autocompleteList,
        onCancel: (_Dialog$instance3 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance3.close,
        multiselect: true,
        onSave: onSave,
        canAddNewChips: canAddNewChips,
        addNewText: addNewText,
        onAddedNewItem: onAddedNewItem,
        checkBeforeEnterNewText: checkBeforeEnterNewText,
        searchBarPlaceholder: searchBarPlaceholder,
        sortByGroup: sortFormByGroup,
        chipGroups: chipGroups,
        addNewButtons: addNewButtons,
        canAddNewChipsViaSearchbar: canAddNewChipsViaSearchbar,
        disableAutoSort: disableAutoSort
      }, "ChipDialogForm"),
      contentPadding: false,
      fullscreenResponsive: true,
      showCloseButton: false,
      showCloseIcon: true
    });
  };
  var onPressButton = function onPressButton(index) {
    return function (_e) {
      var onPressButton = props.onPressButton,
        availableChips = props.availableChips,
        chipGroups = props.chipGroups,
        onChanged = props.onChanged;
      var newAutoComplete = (0, _toConsumableArray2.default)(autocompleteList);
      if (autocompleteList) {
        var autocompleteIndex = autocompleteList.findIndex(function (chip) {
          return chip.title === chips[index].title;
        });
        if (autocompleteIndex > -1) {
          newAutoComplete[autocompleteIndex].selected = false;
        } else {
          var chip = chips[index];
          newAutoComplete.push(getListItem(chip, false, chipGroups));
        }
      } else {
        newAutoComplete = createAutoCompleteList(availableChips != null ? availableChips : [], chips, chipGroups);
      }
      var prevChip = chips[index];
      var newChips = (0, _toConsumableArray2.default)(chips);
      newChips.splice(index, 1);
      prevChip.onPressButton == null || prevChip.onPressButton(_e);
      onPressButton == null || onPressButton(_e);
      onChanged == null || onChanged(newChips);
      setChips(newChips);
      setAutocompleteList(newAutoComplete);
    };
  };
  var renderChips = function renderChips() {
    var textColor = props.textColor,
      removeIconColor = props.removeIconColor,
      backgroundColor = props.backgroundColor,
      leftContent = props.leftContent,
      onPressChip = props.onPressChip,
      chipDisabled = props.chipDisabled,
      buttonDisabled = props.buttonDisabled,
      availableChips = props.availableChips,
      editable = props.editable,
      displayDisabledButtons = props.displayDisabledButtons,
      outlined = props.outlined,
      borderProps = props.borderProps;
    if (chips.length === 0) return [];
    var renderedChips = [];
    chips.forEach(function (chip, i) {
      var _chip$textColor, _chip$removeIconColor, _chip$backgroundColor, _chip$thumbnail, _chip$onPressChip, _chip$chipDisabled, _chip$outlined, _chip$borderProps;
      var disableButton = chip.buttonDisabled != null ? chip.buttonDisabled : buttonDisabled;
      var hasButton = editable !== false && chip.button !== false;
      hasButton = hasButton && availableChips != null && availableChips.length > 0;
      hasButton = hasButton && !(disableButton === true && displayDisabledButtons === false);
      renderedChips.push((0, _jsxRuntime.jsx)(_Chip.Chip, {
        title: chip.title,
        textColor: (_chip$textColor = chip.textColor) != null ? _chip$textColor : textColor,
        removeIconColor: (_chip$removeIconColor = chip.removeIconColor) != null ? _chip$removeIconColor : removeIconColor,
        backgroundColor: (_chip$backgroundColor = chip.backgroundColor) != null ? _chip$backgroundColor : backgroundColor,
        thumbnail: (_chip$thumbnail = chip.thumbnail) != null ? _chip$thumbnail : leftContent,
        onPressButton: onPressButton(i),
        onPressChip: (_chip$onPressChip = chip.onPressChip) != null ? _chip$onPressChip : onPressChip,
        button: hasButton,
        chipDisabled: (_chip$chipDisabled = chip.chipDisabled) != null ? _chip$chipDisabled : chipDisabled,
        outlined: (_chip$outlined = chip.outlined) != null ? _chip$outlined : outlined,
        borderProps: (_chip$borderProps = chip.borderProps) != null ? _chip$borderProps : borderProps,
        buttonDisabled: disableButton
      }, `chip${i.toString()}${chip.title}`));
    });
    return renderedChips;
  };
  var availableChips = props.availableChips,
    bordered = props.bordered,
    editable = props.editable,
    label = props.label,
    chipDisabled = props.chipDisabled,
    tooltipAddChip = props.tooltipAddChip;
  var borderStyle = {};
  if (bordered) {
    borderStyle = {
      borderWidth: 1,
      borderRadius: 2,
      borderColor: _ThemeManager.ThemeManager.style.borderColor
    };
  }
  var addButton = null;
  if (availableChips != null && editable !== false && chipDisabled !== true) {
    addButton = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        margin: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(4),
        height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32),
        width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32),
        borderRadius: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: _ThemeManager.ThemeManager.style.black42,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
      },
      children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
        icon: "plus",
        toolTip: tooltipAddChip != null ? tooltipAddChip : _I18n.I18n.m.getMessage('add'),
        onPress: openDialog,
        outerSize: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32),
        color: _ThemeManager.ThemeManager.style.brandPrimary
      })
    });
  }
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: _reactNativeWeb.StyleSheet.flatten([borderStyle, {
      overflow: 'visible'
    }]),
    children: [label != null && label.length > 0 ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
      type: _MaterialText.MaterialTextTypes.Caption,
      children: label
    }) : null, (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        overflow: 'visible'
      },
      children: [renderChips(), addButton]
    })]
  });
});