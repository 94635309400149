import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { DeleteExtraPay } from 'upmesh-core/src/client/commands/companies/extrapay/DeleteExtraPay';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddOrChangeExtraPayToCompanyDialog } from './AddOrChangeExtraPayToCompanyDialog';
const noExtraPayImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyExtraPayView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.updateWrapper = () => {
            if (this.updateTimeOut != null)
                clearTimeout(this.updateTimeOut);
            this.updateTimeOut = setTimeout(this.updateAll, 300);
        };
        this.updateAll = () => {
            const { company } = this.props;
            const asyncNow = async () => {
                const data = await UpmeshClient.instance.modals.extraPay.get({
                    filter: `deleted ne true and companyId eq '${company.id}'`,
                });
                const companyProjects = await UpmeshClient.instance.modals.companyProject.get();
                const projectListItems = [];
                for (let i = 0; i < companyProjects.length; i += 1) {
                    const project = companyProjects[i];
                    projectListItems.push({
                        title: `${project.title}`,
                        data: project.id,
                    });
                }
                this.setState({ data, projectListItems });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.removeExtraPay = (extraPay) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveExtraPayQuestion'),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeExtraPayNow(extraPay)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeExtraPayNow = (ExtraPay) => (_e) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new DeleteExtraPay({}, ExtraPay.id, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((e) => {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.openFab = () => {
            if (Fab.instance != null) {
                const permitted = this.myRole() === 'admin';
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: permitted
                        ? this.openAddDialog
                        : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                });
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.openAddDialog = (_e) => {
            const { company } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddOrChangeExtraPayToCompanyDialog company={company}/>,
                contentPadding: false,
                showCloseIcon: true,
                scrollable: false,
            });
        };
        this.openEditDialog = (extraPay) => (_e) => {
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <AddOrChangeExtraPayToCompanyDialog extraPay={extraPay} company={company}/>,
                    contentPadding: false,
                    showCloseIcon: true,
                    scrollable: false,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.state = {
            myRole: this.myRole(),
            projectListItems: [],
            data: [],
        };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyExtraPayView',
            readModelName: 'ExtraPay',
            callback: this.updateWrapper,
        });
        this.openFab();
        this.updateAll();
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('ExtraPay', 'CompanyExtraPayView');
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { company, size } = this.props;
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(60);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('extraPays')}
          </MaterialText>
        </View>
        <Card style={{ width: '100%', height: tableMaxHeight }}>
          <Table tableName="CompanyExtraPay" actionItemsLength={2} actions={myRole === 'admin'
                ? (_item) => {
                    const buttons = [
                        {
                            icon: 'pencil-outline',
                            onAction: this.openEditDialog,
                            toolTip: I18n.m.getMessage('edit'),
                        },
                        {
                            icon: 'delete-outline',
                            onAction: this.removeExtraPay,
                            toolTip: I18n.m.getMessage('delete'),
                            disabled: _item.id === company.id,
                        },
                    ];
                    return buttons;
                }
                : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noExtraPayImg} emptyTableHint={I18n.m.getMessage('companyExtraPayNoDataHint')} emptyTableText={I18n.m.getMessage('companyExtraPayNoDataText')} data={data} sortBy="mark" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('extraPayMark'),
                    keyInData: 'mark',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('extraPayDescription'),
                    keyInData: 'description',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('extraPayOnceAday'),
                    keyInData: 'onceADay',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                    cellRenderer: (item, column, _index) => {
                        return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
                      <MaterialText>{item.onceADay ? I18n.m.getMessage('yes') : I18n.m.getMessage('no')}</MaterialText>
                    </View>);
                    },
                },
                {
                    title: I18n.m.getMessage('extraPayWageType'),
                    keyInData: 'wageType',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
            ]}/>
        </Card>
      </View>);
    }
}
