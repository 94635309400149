import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { ContentHeaderEventHandler, } from 'materialTheme/src/theme/components/header/ContentHeaderEventHandler';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Page } from 'materialTheme/src/theme/components/Page';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Image, View } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import { I18n } from '../../i18n/I18n';
import { FabWithCamera } from '../FabWithCamera';
import { CurrentProject } from '../project/CurrentProject';
import { BulkChangeDialogContent, isSupportedByBulkChange, } from './bulkChangeDialog/BulkChangeDialogContent';
import { ReportIcon } from './ReportIcon';
import { TicketCalender } from './TicketCalendar/TicketCalendar';
import { TicketCanban } from './TicketCanban';
import { TicketList } from './TicketList';
import { TicketTable } from './TicketTable';
const notFoundImage = require('../../assets/img/no_tickets.png');
const hasNotch = DeviceInfo.hasNotch();
const iosContentInset = hasNotch ? 30 : 10;
export class TicketsOverView extends PureComponent {
    constructor(props) {
        super(props);
        this.getFields = async () => {
            const { showProjects, tickets } = this.props;
            const fields = [];
            if (!showProjects) {
                const project = CurrentProject.instance.getCurrentProject();
                if (project != null && project.ticketLayouts != null) {
                    project.ticketLayouts.forEach((layout) => {
                        if (this.layoutIsUsed(tickets, layout))
                            layout.fields.forEach((field) => {
                                if (field.customField != null)
                                    fields.push({ ...field, onTypes: layout.onTypes });
                            });
                    });
                }
                this.setState({ customFields: fields });
            }
        };
        this.layoutIsUsed = (tickets, layout) => {
            const { onTypes } = layout;
            if (onTypes == null || onTypes.length === 0)
                return false;
            const commonItem = tickets.find((t) => t.type != null && onTypes.indexOf(t.type) > -1);
            return commonItem != null;
        };
        this.closeMultiselectHeader = () => {
            const { onChangeMultiselectStatus } = this.props;
            const data = {
                open: false,
            };
            ContentHeaderEventHandler.statusEvent.post(data);
            if (onChangeMultiselectStatus) {
                onChangeMultiselectStatus(false);
            }
            this.setState({
                selectedIDs: new Set(),
            });
        };
        this.openBulkChangeDialog = (topic) => () => {
            const { tickets } = this.props;
            const { selectedIDs } = this.state;
            Dialog.instance?.open({
                closeOnTouchOutside: true,
                scrollable: false,
                fullscreenResponsive: true,
                width: topic === 'dueDate' ? 372 : ThemeManager.style.maxDialogWidth,
                content: (<BulkChangeDialogContent changedTopic={topic} tickets={tickets} selectedIDs={selectedIDs} onChange={this.checkSelectedTicketVisibility} errorDialog={this.openNoRightsErrorDialog}/>),
                contentPadding: false,
            });
        };
        this.openNoRightsErrorDialog = (errors) => {
            Dialog.instance?.close();
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: (<View>
          <MaterialText>{I18n.m.getMessage('bulkChangesNoRights', { count: errors })}</MaterialText>
          <View style={{ paddingTop: 28, alignSelf: 'flex-end' }}>
            <ContainedButton title={I18n.m.getMessage('ok')} onPress={Dialog.instance?.close}/>
          </View>
        </View>),
            });
        };
        this.checkSelectedTicketVisibility = () => {
            const { tickets } = this.props;
            const { selectedIDs, activeMultiselect } = this.state;
            const selecetd = new Set();
            selectedIDs.forEach((v) => {
                if (tickets.findIndex((t) => t.id === v) > -1)
                    selecetd.add(v);
            });
            this.setState({ selectedIDs: selecetd }, () => {
                if (activeMultiselect)
                    this.openMultiselectHeader();
            });
        };
        this.getBulkChangeOptions = () => {
            const { tickets } = this.props;
            const { selectedIDs } = this.state;
            const maxOptions = Math.floor((ResizeEvent.current.contentWidth - 172) / 36);
            const project = CurrentProject.instance.getCurrentProject();
            const customFields = new Map();
            tickets.forEach((t) => {
                if (selectedIDs.has(t.id) && project != null) {
                    const layout = ChangeTicketCustomField.getTicketLayout(t, project);
                    layout.fields.forEach((field) => {
                        customFields.set(field.id, { ...field, onTypes: layout.onTypes });
                    });
                }
            });
            const menuElements = [];
            const options = [
                {
                    icon: 'account-plus-outline',
                    title: I18n.m.getMessage('bulkChangesChangeAssignee'),
                    color: ThemeManager.style.brandPrimary,
                    onPress: this.openBulkChangeDialog('assignee'),
                },
                {
                    icon: 'calendar-check-outline',
                    title: I18n.m.getMessage('bulkChangesChangeDueDate'),
                    color: ThemeManager.style.brandPrimary,
                    onPress: this.openBulkChangeDialog('dueDate'),
                },
                {
                    icon: 'archive-outline',
                    title: I18n.m.getMessage('bulkChangesArchive'),
                    color: ThemeManager.style.brandPrimary,
                    onPress: this.openBulkChangeDialog('archive'),
                },
                {
                    icon: 'ticket-outline',
                    title: I18n.m.getMessage('bulkChangesChangeType'),
                    color: ThemeManager.style.brandPrimary,
                    onPress: this.openBulkChangeDialog('type'),
                },
                {
                    icon: 'account-check-outline',
                    title: I18n.m.getMessage('bulkChangesChangeApprover'),
                    color: ThemeManager.style.brandPrimary,
                    onPress: this.openBulkChangeDialog('approver'),
                },
                {
                    icon: 'eye-outline',
                    title: I18n.m.getMessage('bulkChangesAddWatcher'),
                    color: ThemeManager.style.brandPrimary,
                    onPress: this.openBulkChangeDialog('watcher'),
                },
                {
                    icon: 'eye-off-outline',
                    title: I18n.m.getMessage('bulkChangesRemoveWatcher'),
                    color: ThemeManager.style.brandPrimary,
                    onPress: this.openBulkChangeDialog('removeWatcher'),
                },
                {
                    icon: 'tag-outline',
                    title: I18n.m.getMessage('bulkChangesAddTags'),
                    color: ThemeManager.style.brandPrimary,
                    onPress: this.openBulkChangeDialog('tags'),
                },
                {
                    icon: 'tag-off-outline',
                    title: I18n.m.getMessage('bulkChangesRemoveTags'),
                    color: ThemeManager.style.brandPrimary,
                    onPress: this.openBulkChangeDialog('removeTags'),
                },
                {
                    icon: 'archive-off-outline',
                    title: I18n.m.getMessage('bulkChangesUnarchive'),
                    color: ThemeManager.style.brandPrimary,
                    onPress: this.openBulkChangeDialog('unarchive'),
                },
            ];
            customFields.forEach((field) => {
                if (field != null &&
                    field.customField != null &&
                    field.customField.type != null &&
                    isSupportedByBulkChange(field.customField.type)) {
                    const icon = TicketLayoutsEntity.getIconName(field);
                    options.push({
                        title: field.label,
                        color: ThemeManager.style.brandSecondary,
                        onPress: this.openBulkChangeDialog(field),
                        icon,
                    });
                }
            });
            options.push({
                icon: 'delete-off-outline',
                title: I18n.m.getMessage('bulkChangesRestore'),
                color: ThemeManager.style.brandPrimary,
                onPress: this.openBulkChangeDialog('restore'),
            });
            options.push({
                icon: 'delete-outline',
                title: I18n.m.getMessage('bulkChangesDelete'),
                color: ThemeManager.style.brandDanger,
                onPress: this.openBulkChangeDialog('delete'),
            });
            const icons = [
                <ReportIcon key="bulkChangeReportIcon" selectedTickets={this.getSelectedTickets()} color={ThemeManager.style.brandPrimary} disabled={selectedIDs.size === 0}/>,
            ];
            for (let i = 0; i < options.length; i += 1) {
                const option = options[i];
                const icon = (<Icon key={`bulkChangeOption_${option.title}_${i}`} icon={option.icon} color={option.color} toolTip={option.title} onPress={option.onPress} disabled={selectedIDs.size === 0}/>);
                if (i < maxOptions) {
                    icons.push(icon);
                }
                else {
                    menuElements.push({
                        thumbnail: { thumbnail: icon, width: 40 },
                        text: option.title,
                        disabled: selectedIDs.size === 0,
                        onPress: () => {
                            Menu.instance?.close();
                            option.onPress();
                        },
                    });
                }
            }
            if (menuElements.length > 0) {
                icons.push(<Icon key="bulkChangeMenuIcon" icon="dots-vertical" color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('bulkChangesMoreOptions')} onPress={this.openMultiselectOptionsMenu(menuElements)}/>);
            }
            return icons;
        };
        this.openMultiselectOptionsMenu = (menuElements) => (e) => {
            Measurement.measure(e.currentTarget)
                .then((p) => {
                const client = {
                    height: p.height,
                    width: p.width,
                    x: p.pageX,
                    y: p.pageY,
                };
                Menu.instance?.open({ items: menuElements, client });
            })
                .catch((err) => {
                console.debug('cant open Menu', err);
            });
        };
        this.openMultiselectHeader = () => {
            const { tickets } = this.props;
            const { selectedIDs } = this.state;
            const globalbar = ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM;
            const headerHeight = globalbar
                ? ThemeManager.style.headerHeight + ThemeManager.style.getScreenRelativePixelSize(48)
                : ThemeManager.style.headerHeight;
            const options = this.getBulkChangeOptions();
            const headerContent = (<View style={{
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'space-between',
                    paddingLeft: ThemeManager.style.contentPaddingValue,
                    paddingRight: ThemeManager.style.contentPaddingValue,
                    width: '100%',
                    alignItems: 'center',
                }}>
        <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
          <Icon key="bulkChangeCloseIcon" toolTip={I18n.m.getMessage('close')} icon="close" color={ThemeManager.style.brandPrimary} onPress={this.closeMultiselectHeader}/>
          <MaterialText color={ThemeManager.style.brandPrimary} centeredText centeredBox>
            {selectedIDs.size}
          </MaterialText>
        </View>
        <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
          {options}
          <Icon key="bulkChangeSelectAllOrNone" icon={selectedIDs.size < tickets.length ? 'checkbox-multiple-marked-outline' : 'checkbox-multiple-blank-outline'} toolTip={selectedIDs.size < tickets.length
                    ? I18n.m.getMessage('bulkChangesSelectAll')
                    : I18n.m.getMessage('bulkChangesSelectNone')} color={ThemeManager.style.brandPrimary} onPress={this.selectAll}/>
        </View>
      </View>);
            const data = {
                headerContent,
                open: true,
                headerHeight,
            };
            ContentHeaderEventHandler.statusEvent.post(data);
        };
        this.getSelectedTickets = () => {
            const { tickets } = this.props;
            const { selectedIDs } = this.state;
            const selectedTickets = [];
            tickets.forEach((t) => {
                if (selectedIDs.has(t.id))
                    selectedTickets.push(t);
            });
            return selectedTickets;
        };
        this.openTicket = async (e) => {
            Routing.instance.openDialog('ticket', { type: 'show' })(e);
        };
        this.renderTicketsNotFoundView = () => {
            const { planId, showProjects } = this.props;
            const searchActivated = this.props.searchWords != null && this.props.searchWords.length > 0;
            let headerText = I18n.m.getMessage('ticketsNotFoundDescription');
            let bodyText = I18n.m.getMessage('ticketsNotFoundHint');
            if (showProjects) {
                headerText = I18n.m.getMessage('ticketsNotFoundDescriptionAllProjects');
                bodyText = I18n.m.getMessage('ticketsNotFoundHintAllProjects');
            }
            else if (searchActivated) {
                headerText = I18n.m.getMessage('ticketsNotFoundDueToFilterDescription');
                bodyText = I18n.m.getMessage('ticketsNotFoundDueToFilterDescription');
            }
            else if (planId != null) {
                bodyText = I18n.m.getMessage('ticketsNotFoundHintSplitView');
            }
            return (<View style={[
                    this.props.style,
                    {
                        alignItems: 'center',
                        alignContent: 'center',
                    },
                ]}>
        {planId == null ? (<Image style={{ height: 300, width: 400, resizeMode: 'contain', marginTop: 16 }} resizeMode="contain" source={notFoundImage}/>) : null}
        <View style={{ marginTop: 16 }}>
          <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2} strong>
            {headerText}
          </MaterialText>
          <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2}>
            {bodyText}
          </MaterialText>
        </View>
      </View>);
        };
        this.selectAll = () => {
            const { tickets } = this.props;
            const { selectedIDs } = this.state;
            const selected = selectedIDs.size < tickets.length ? new Set(tickets.map((t) => t.id)) : new Set();
            this.setState(() => ({ selectedIDs: selected }), this.openMultiselectHeader);
        };
        this.getItemKey = (item, _index) => `ticket_card_${item.id}`;
        this.goToTickets = () => {
            const { currentUrl } = RouterControl.instance;
            const path = currentUrl.pathname.split('/');
            path.splice(path.length - 2, 1);
            const pathNew = `${path.join('/')}?${currentUrl.search}`;
            Routing.instance.goTo(pathNew, true);
        };
        this.onMultiSelect2 = (ticket) => {
            const { onChangeMultiselectStatus } = this.props;
            const { selectedIDs } = this.state;
            const newIds = new Set(selectedIDs.keys());
            if (newIds.has(ticket.id)) {
                newIds.delete(ticket.id);
            }
            else {
                newIds.add(ticket.id);
            }
            if (onChangeMultiselectStatus) {
                onChangeMultiselectStatus(true);
            }
            this.setState({ selectedIDs: newIds }, this.openMultiselectHeader);
        };
        this.onMultiSelect = (selectedIDs) => {
            const { onChangeMultiselectStatus } = this.props;
            if (onChangeMultiselectStatus) {
                onChangeMultiselectStatus(true);
            }
            this.setState({ selectedIDs: new Set(selectedIDs) }, () => {
                if (selectedIDs.size === 0)
                    this.closeMultiselectHeader();
                else
                    this.openMultiselectHeader();
            });
        };
        this.state = {
            init: true,
            selectedIDs: new Set(),
            dateChangeProgress: 0,
            customFields: [],
        };
    }
    static getDerivedStateFromProps(nextProps, _prevState) {
        return { activeMultiselect: nextProps.activeMultiselect };
    }
    componentWillUnmount() {
        this.closeMultiselectHeader();
    }
    shouldComponentUpdate(nextProps, nextState) {
        const changedProps = JSON.stringify(this.props) !== JSON.stringify(nextProps);
        const changedState = JSON.stringify(this.state) !== JSON.stringify(nextState) || this.state.selectedIDs !== nextState.selectedIds;
        return changedState || changedProps;
    }
    componentDidUpdate(prevProps, _prevState) {
        if (this.props.activeMultiselect && !prevProps.activeMultiselect) {
            this.openMultiselectHeader();
        }
        if (JSON.stringify(prevProps.tickets) !== JSON.stringify(this.props.tickets)) {
            this.checkSelectedTicketVisibility();
            this.getFields().catch((err) => console.debug(err));
        }
    }
    componentDidMount() {
        const { multiselectEnabled } = this.props;
        if (multiselectEnabled)
            this.getFields().catch((err) => console.debug(err));
    }
    render() {
        if (!this.state.init) {
            return (<Page>
          <Spinner />
        </Page>);
        }
        const { showFab, selectedView, views } = this.props;
        const projectId = CurrentProject.instance.getCurrentProjectId();
        let view = () => this.renderCanban();
        const hasTickets = this.props.tickets.length > 0;
        if (!hasTickets) {
            view = () => this.renderTicketsNotFoundView();
        }
        else if (views != null && views.length > 0) {
            view = () => this.renderTabContent(views);
        }
        else if (views != null || selectedView === 'table') {
            view = () => this.renderTableView();
        }
        else if (selectedView === 'list') {
            view = () => this.renderListView();
        }
        else if (selectedView === 'calendar') {
            view = () => this.renderCalendarView();
        }
        if (showFab === true) {
            const { planId, size } = this.props;
            return (<View style={ThemeManager.style.absoluteStyle}>
          {showFab != null && showFab ? (<FabWithCamera size={size} additionalActionButtons={[
                        {
                            icon: 'map-marker-check-outline',
                            onPress: (_e) => {
                                if (Fab.instance != null) {
                                    Fab.instance.closeButtons();
                                }
                                this.props.pressCreateTicket(planId)(_e);
                            },
                            text: I18n.m.getMessage('ticketsAddTicket'),
                        },
                    ]} projectId={projectId}/>) : null}
          {view()}
        </View>);
        }
        return view();
    }
    renderTabContent(views) {
        const { selectedTab, selectedView } = this.props;
        if (selectedTab == null || views.length < selectedTab)
            return <View />;
        const type = selectedView;
        if (type === 'list')
            return this.renderListView();
        if (type === 'canban')
            return this.renderCanban();
        if (type === 'calendar')
            return this.renderCalendarView();
        return this.renderTableView();
    }
    renderCanban() {
        const { width, height, style, tickets, multiselectEnabled, activeMultiselect, planId, onChangeTicketOrder, showProjects, } = this.props;
        const { selectedIDs } = this.state;
        return (<TicketCanban planId={planId} tickets={tickets} width={width} height={height} style={style} onChangeTicketOrder={onChangeTicketOrder} iosContentInset={iosContentInset} activeMultiselect={activeMultiselect} onMultiSelect={this.onMultiSelect2} selectedIDs={selectedIDs} multiselectEnabled={multiselectEnabled} showProjects={showProjects} searchBarTextColor={showProjects ? '#FFFFFF' : undefined}/>);
    }
    renderListView() {
        const { width, height, style, tickets, multiselectEnabled, activeMultiselect, planId, showProjects } = this.props;
        const { selectedIDs } = this.state;
        return (<TicketList planId={planId} width={width} height={height} style={style} tickets={tickets} iosContentInset={iosContentInset} activeMultiselect={multiselectEnabled ? activeMultiselect : undefined} onMultiSelect={multiselectEnabled ? this.onMultiSelect2 : undefined} selectedIDs={selectedIDs} showProjects={showProjects}/>);
    }
    renderTableView() {
        const { width, height, style, tickets, showProjects, multiselectEnabled, onChangeTicketOrder, planId, currentTicketFilter, searchWords, updateTicketFilter, hideTicketHierarchy, views, selectedTab, } = this.props;
        const { selectedIDs } = this.state;
        const viewId = views && selectedTab && views?.length >= selectedTab + 1 ? views[selectedTab].id : '';
        return (<TicketTable sortable={searchWords == null || searchWords.length === 0} planId={planId} height={height - 16} width={width} tickets={tickets} iosContentInset={iosContentInset} style={style} showProject={showProjects} multiselectEnabled={multiselectEnabled} onMultiSelect={this.onMultiSelect} selectedIDs={selectedIDs} onMultiSelectAll={this.selectAll} onChangeTicketOrder={onChangeTicketOrder} currentFilter={currentTicketFilter} updateFilter={updateTicketFilter} hideTicketHierarchy={hideTicketHierarchy} viewId={viewId}/>);
    }
    renderCalendarView() {
        const { width, height, style, tickets, showProjects, multiselectEnabled, activeMultiselect } = this.props;
        const { selectedIDs } = this.state;
        return (<TicketCalender height={height} width={width} tickets={tickets} iosContentInset={iosContentInset} style={style} showProject={showProjects} multiselectEnabled={multiselectEnabled} activeMultiselect={activeMultiselect} onMultiSelect={this.onMultiSelect2} selectedIDs={selectedIDs} onMultiSelectAll={this.selectAll}/>);
    }
    getDirection() {
        if (ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM) {
            return 'row';
        }
        return 'column';
    }
}
TicketsOverView.defaultProps = {
    title: 'upmesh',
    selectedView: false,
    multiselectEnabled: true,
};
