import { Card } from 'materialTheme/src/theme/components/Card';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { I18n } from '../../../i18n/I18n';
import { openTimeTrackingDayForUserDialog } from '../dialogs/openTimeTrackingDayForUserDialog';
import { CollaboratorThumb } from '../elementcomponents/CollaboratorThumb';
const noTimeTrackings = require('../../../assets/img/noTimeTrackings.png');
export function TimeTrackingTablePerDay(props) {
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        const d = [];
        for (const e of props.entriesInDates) {
            d.push(...e.entries);
        }
        setTableData(d);
    }, [props.entriesInDates]);
    const renderTextCell = (text, column, item, sLayout, index, color = ThemeManager.style.defaultTextColor) => {
        return (<View key={`cell-${item.id}-${index}`} style={[
                {
                    height: '100%',
                    minHeight: sLayout ? undefined : 54,
                    maxWidth: '100%',
                    alignSelf: sLayout ? 'flex-end' : 'flex-end',
                    justifyContent: sLayout ? 'flex-end' : 'center',
                    paddingHorizontal: 16,
                },
                column.style,
            ]}>
        {column.keyInData ? (<View style={{
                    alignItems: 'flex-end',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: 0,
                }}>
            <MaterialText color={color} centeredBox>
              {text}
            </MaterialText>
          </View>) : null}
      </View>);
    };
    const renderTimeDiffCell = (item, column, index, sLayout) => {
        let text = '-';
        let color = ThemeManager.style.defaultTextColor;
        if (item.diffTimeInMS !== 0)
            text = TimeTrackingEntity.msToTime(item.diffTimeInMS, false, true);
        if (item.diffTimeInMS < 0)
            color = ThemeManager.style.brandDanger;
        return renderTextCell(text, column, item, sLayout, index, color);
    };
    const columns = [
        { title: I18n.m.getMessage('date'), keyInData: 'date', dataType: 'Date', style: { width: 130 } },
        {
            title: { icon: 'account-circle', toolTip: I18n.m.getMessage('userImage') },
            keyInData: 'memberName',
            style: { width: 64 },
            sortable: false,
            cellRenderer: (item, columnData, _index, sLayout) => (<CollaboratorThumb item={{ firstName: item.memberFirstname, lastName: item.memberLastname, id: item.memberId, user: item.user }} columnData={columnData} sLayout={sLayout}/>),
            dataType: 'string',
            minWidth: 64,
        },
        {
            title: I18n.m.getMessage('firstName'),
            keyInData: 'memberFirstname',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('lastName'),
            keyInData: 'memberLastname',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: I18n.m.getMessage('staffNumber'),
            keyInData: 'staffNumber',
            dataType: 'string',
            style: { width: 200 },
        },
        {
            title: {
                icon: 'clock-start',
                toolTip: I18n.m.getMessage('workingTimeModelsStart'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'startTime',
            dataType: 'Time',
            style: { width: 90 },
        },
        {
            title: {
                icon: 'clock-end',
                toolTip: I18n.m.getMessage('workingTimeModelsStop'),
                iconSize: 24,
                outerSize: 24,
                radius: 0,
            },
            keyInData: 'endTime',
            dataType: 'Time',
            style: { width: 90 },
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelActualTime'),
            keyInData: 'totalTime',
            dataType: 'number',
            style: { width: 90 },
            format: (item) => {
                return TimeTrackingEntity.msToTime(item.totalTime, false, true);
            },
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelTargetTime'),
            keyInData: 'targetTimeInMS',
            dataType: 'number',
            style: { width: 90 },
            format: (item) => {
                return TimeTrackingEntity.msToTime(item.targetTimeInMS, false, true);
            },
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelDiffTime'),
            keyInData: 'diffTimeInMS',
            dataType: 'number',
            style: { width: 90 },
            format: (item) => {
                return TimeTrackingEntity.msToTime(item.diffTimeInMS, false, true);
            },
            cellRenderer: renderTimeDiffCell,
        },
        {
            title: I18n.m.getMessage('tasks'),
            keyInData: 'tasks',
            dataType: 'string',
            style: { width: 90 },
            format: (item) => {
                return item.tasks != null && item.tasks.length > 0 ? item.tasks.join(', ') : '';
            },
        },
        {
            title: I18n.m.getMessage('projects'),
            keyInData: 'projectTitles',
            dataType: 'string',
            style: { width: 90 },
            format: (item) => {
                return item.projectTitles != null && item.projectTitles.length > 0 ? item.projectTitles.join(', ') : '';
            },
        },
    ];
    const openDay = (item, e) => {
        openTimeTrackingDayForUserDialog({ memberId: item.memberId, day: item.date, userId: item.userId })(e);
    };
    const { maxHeight } = props;
    return (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ width: '100%', maxWidth: '100%', paddingHorizontal: 8 }}>
        <Card outerPadding={8} style={{ height: maxHeight }}>
          <Table onRowPress={openDay} tableName="TimeTrackingTablePerDay" maxHeight={maxHeight - 16} actionItemsLength={1} data={tableData} sortDirection="desc" keyExtractor={(item) => `${item.id}`} columns={columns} emptyTableImage={noTimeTrackings} emptyTableHint={I18n.m.getMessage('timeTrackingNoDataHint')} emptyTableText={I18n.m.getMessage('timeTrackingNoDataText')} multiselectEnabled={false}/>
        </Card>
      </View>
    </View>);
}
