import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { PlanFilter } from 'upmesh-core/src/client/query/filter/PlanFilter';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
export const planFilterGetSelectedChipsByIds = (chips, ids) => {
    const selectedTags = [];
    if (ids != null && ids.length > 0) {
        for (let i = 0; i < ids.length; i += 1) {
            for (let j = 0; j < chips.length; j += 1) {
                if (ids[i] === chips[j].id) {
                    selectedTags.push(chips[j]);
                    break;
                }
            }
        }
    }
    return selectedTags;
};
export const planFilterGetTagsGroupsOfProjects = (projects) => {
    const projectTagGroups = [];
    for (const project of projects) {
        for (const key in project.tagGroups) {
            const { groupName } = project.tagGroups[key];
            if (groupName != null && projectTagGroups.findIndex((a) => a.id === groupName) === -1)
                projectTagGroups.push({
                    backgroundColor: project.tagGroups[key].color,
                    groupName,
                    id: groupName,
                });
        }
    }
    return projectTagGroups;
};
export const planFilterGetTagsFromPlans = (currentPlans) => {
    const usedTags = [];
    const tags = [];
    currentPlans.forEach((t) => {
        if (t.tags != null && t.tags.length > 0) {
            t.tags.forEach((tag) => {
                if (tags.findIndex((tt) => tt.tagName === tag.tagName) === -1)
                    tags.push(tag);
            });
        }
    });
    for (let i = 0; i < tags.length; i += 1) {
        usedTags.push({
            id: tags[i].tagName,
            backgroundColor: tags[i].color,
            groupId: tags[i].groupName,
            title: tags[i].tagName,
            textColor: '#FFFFFF',
        });
    }
    return usedTags;
};
export const PlansFilterDialog = React.memo((props) => {
    const [loading, setLoading] = useState(true);
    const [amountSelected, setAmountSelected] = useState(0);
    const [availableTeammember, setAvailableTeammember] = useState([]);
    const [selectedCreator, setSelectedCreator] = useState([]);
    const [intervalStart, setIntervalStart] = useState(undefined);
    const [intervalEnd, setIntervalEnd] = useState(undefined);
    const [projectTags, setProjectTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [projectTagGroups, setProjectTagGroups] = useState([]);
    const createCurrentFilter = (tags, creator, start, end) => {
        const currentFilter = new PlanFilter();
        if (tags != null && tags.length > 0) {
            currentFilter.t = [];
            tags.forEach((t) => {
                if (t.id != null)
                    currentFilter.t.push(t.id);
            });
        }
        if (creator != null && creator.length > 0) {
            currentFilter.u = [];
            if (creator != null && creator.length > 0) {
                for (let i = 0; i < creator.length; i += 1) {
                    const c = creator[i];
                    if (c != null && c.id != null) {
                        currentFilter.u.push(c.id);
                    }
                }
            }
        }
        currentFilter.is = start != null ? start : undefined;
        currentFilter.ie = end != null ? end : undefined;
        return currentFilter;
    };
    const calculateFiltered = (tags, creator, start, end) => {
        const { plans } = props;
        try {
            const filter = createCurrentFilter(tags, creator, start, end);
            PlanFilter.filterPlans(plans, filter)
                .then((filtered) => {
                setAmountSelected(filtered.length);
            })
                .catch((_err) => {
                setAmountSelected(0);
            });
        }
        catch (e) {
            setAmountSelected(0);
        }
    };
    const getPersons = async () => {
        const { plans } = props;
        const values = [];
        const availableUserIds = [];
        plans.forEach((t) => {
            if (t.createdBy != null && t.createdBy.length > 0) {
                if (availableUserIds.indexOf(t.createdBy) === -1)
                    availableUserIds.push(t.createdBy);
            }
        });
        for (let i = 0; i < availableUserIds.length; i += 1) {
            try {
                const user = await AuthClient.instance.modals.user.getById(availableUserIds[i]);
                values.push({
                    id: user.id,
                    title: user.getFullName(),
                });
            }
            catch (e) {
                console.debug('cant get user', availableUserIds[i]);
            }
        }
        return values;
    };
    const initFunction = async () => {
        const { currentFilter, plans } = props;
        const project = CurrentProject.instance.getCurrentProject();
        if (project == null)
            return;
        const tagGroupsProject = planFilterGetTagsGroupsOfProjects([project]);
        const tagsProject = planFilterGetTagsFromPlans(plans);
        const teammemberAvailable = await getPersons();
        const creator = planFilterGetSelectedChipsByIds(teammemberAvailable, currentFilter?.u);
        const tags = planFilterGetSelectedChipsByIds(tagsProject, currentFilter?.t);
        const start = currentFilter != null && currentFilter.is != null ? currentFilter.is : undefined;
        const end = currentFilter != null && currentFilter.ie != null ? currentFilter.ie : undefined;
        setSelectedCreator(creator);
        setSelectedTags(tags);
        setProjectTagGroups(tagGroupsProject);
        setProjectTags(tagsProject);
        setIntervalEnd(end);
        setIntervalStart(start);
        setAvailableTeammember(teammemberAvailable);
        setLoading(false);
        calculateFiltered(tags, creator, start, end);
    };
    useEffect(() => {
        initFunction().catch((err) => console.debug(err));
    }, []);
    const setTags = (e) => {
        setSelectedTags(e);
        calculateFiltered(e, selectedCreator, intervalStart, intervalEnd);
    };
    const saveIntervalStart = (newDate) => {
        const start = newDate == null ? undefined : newDate.getTime();
        setIntervalStart(start);
        calculateFiltered(selectedTags, selectedCreator, start, intervalEnd);
    };
    const saveIntervalEnd = (newDate) => {
        const end = newDate == null ? undefined : newDate.getTime();
        setIntervalEnd(end);
        calculateFiltered(selectedTags, selectedCreator, intervalStart, end);
    };
    const setPersons = (e) => {
        setSelectedCreator(e);
        calculateFiltered(selectedTags, e, intervalStart, intervalEnd);
    };
    const onSelect = () => {
        Dialog.instance?.close(() => {
            const filter = createCurrentFilter(selectedTags, selectedCreator, intervalStart, intervalEnd);
            Routing.instance.changeQueryParameter({
                fp: JSON.stringify(filter),
            });
            if (props.callback != null)
                props.callback(filter);
        });
    };
    const resetFilters = () => {
        setSelectedCreator([]);
        setIntervalStart(undefined);
        setIntervalEnd(undefined);
        setSelectedTags([]);
        calculateFiltered([], [], undefined, undefined);
    };
    const render = () => {
        const { plans } = props;
        if (loading) {
            return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
            <Spinner />
          </View>);
        }
        return (<View>
          <DialogTitle>{I18n.m.getMessage('planFilterHeader')}</DialogTitle>
          <View style={{
                position: 'absolute',
                top: ThemeManager.style.contentPaddingValue,
                right: ThemeManager.style.contentPaddingValue,
            }}>
            <ContainedButton key="ticketFilterModalCloseButton" textColor="#000000" icon={{ icon: 'close' }} onPress={() => Dialog.instance?.close()} backgroundColor="transparent"/>
          </View>
          <View style={{ padding: ThemeManager.style.contentPaddingValue }}>
            <ChipGroup dialogTitle={I18n.m.getMessage('planFilterTags')} label={I18n.m.getMessage('planFilterTags')} bordered={false} sortFormByGroup chipGroups={projectTagGroups} availableChips={projectTags} chips={selectedTags} onChanged={setTags}/>
            <View style={{ height: 6 }}/>
            <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('planFilterPersons')}</MaterialText>
            <ChipGroup dialogTitle={I18n.m.getMessage('planFilterPersons')} chips={selectedCreator} bordered={false} availableChips={availableTeammember} onChanged={setPersons} tooltipAddChip={I18n.m.getMessage('planFilterAddTeammember')}/>
            <View style={{ height: 6 }}/>

            <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('planFilterTimePeriod')}</MaterialText>
            <View style={{ alignContent: 'center', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
              <DateInput selectedDate={intervalStart == null ? undefined : new Date(intervalStart)} placeholder={I18n.m.getMessage('date')} key={`intervalStart${intervalStart}`} onChange={saveIntervalStart}/>
              <View style={{
                justifyContent: 'center',
                paddingLeft: ThemeManager.style.contentPaddingValue,
                paddingRight: ThemeManager.style.contentPaddingValue,
            }}>
                <MaterialText centeredText>{I18n.m.getMessage('till')}</MaterialText>
              </View>
              <DateInput selectedDate={intervalEnd == null ? undefined : new Date(intervalEnd)} placeholder={I18n.m.getMessage('date')} key={`intervalEnd${intervalEnd}`} onChange={saveIntervalEnd}/>
            </View>
          </View>
          <DialogActions key="actions" onBottom>
            <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('clear')} onPress={resetFilters}/>
            <ContainedButton title={`${I18n.m.getMessage('apply')} ${amountSelected}/${plans.length}`} onPress={onSelect} disabled={amountSelected === 0}/>
          </DialogActions>
        </View>);
    };
    return render();
});
export const openPlanFilterDialog = (plans, callback, currentFilter) => {
    if (Dialog.instance != null) {
        const content = <PlansFilterDialog plans={plans} callback={callback} currentFilter={currentFilter}/>;
        Dialog.instance?.open({
            closeOnTouchOutside: false,
            content,
            contentPadding: false,
            scrollable: false,
            fullscreenResponsive: true,
        });
    }
};
