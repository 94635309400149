import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { CustomFieldDividerOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import { I18n } from '../../../../i18n/I18n';
const ID_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export function TicketLayoutDividerOptions(props) {
    const [currentField, setCurrentField] = useState(props.field);
    const submitForm = (_e) => {
        const id = currentField.identifier ||
            `divider${Array.from({ length: 16 }, () => ID_CHARACTERS[Math.floor(Math.random() * ID_CHARACTERS.length)]).join('')}`;
        props.onSave({
            ...currentField,
            identifier: id,
            label: currentField.customField?.options.title || I18n.m.getMessage('customFieldDivider'),
        });
        Dialog.instance?.close();
    };
    const icon = TicketLayoutsEntity.getIconName(props.field);
    return (<>
      <DialogTitle iconRight={{ icon, color: ThemeManager.style.brandSuccess, toolTip: '' }}>
        {props.newField
            ? I18n.m.getMessage('ticketLayoutOptionsTileNew')
            : I18n.m.getMessage('ticketLayoutOptionsTileEdit', { title: currentField.label })}
      </DialogTitle>
      <DialogContent>
        <FormInputFilled labelText={I18n.m.getMessage('ticketLayoutOptionsDividerTitle')} initValue={currentField.customField && currentField.customField.options && currentField.customField.options.title
            ? currentField.customField.options.title
            : undefined} onChange={(v) => {
            const customField = currentField.customField
                ? currentField.customField
                : { type: 'divider', options: new CustomFieldDividerOptions() };
            if (customField.options == null)
                customField.options = new CustomFieldDividerOptions();
            customField.options.title = v;
            setCurrentField({ ...currentField, customField });
        }}/>
        <FormInputFilled labelText={I18n.m.getMessage('ticketLayoutOptionsDividerDescription')} initValue={currentField.customField && currentField.customField.options && currentField.customField.options.description
            ? currentField.customField.options.description
            : ''} onChange={(v) => {
            const customField = currentField.customField
                ? currentField.customField
                : { type: 'divider', options: new CustomFieldDividerOptions() };
            if (customField.options == null)
                customField.options = new CustomFieldDividerOptions();
            customField.options.description = v;
            setCurrentField({ ...currentField, customField });
        }}/>
      </DialogContent>
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => Dialog.instance?.close()} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton onPress={submitForm} title={props.newField ? I18n.m.getMessage('create') : I18n.m.getMessage('save')}/>
      </DialogActions>
    </>);
}
