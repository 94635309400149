import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import React from 'react';
import { View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
import { openTimeTrackingDayForUserDialog } from './openTimeTrackingDayForUserDialog';
export function TrackingProblemsDialog(props) {
    let lastDay = '';
    return (<>
      <DialogTitle iconRight={{ icon: 'close', onPress: Dialog.instance?.close, toolTip: '' }}>
        {I18n.m.getMessage('trackingProblemsDialogTitle')}
      </DialogTitle>
      <DialogContent>
        <View>
          {props.problems.map((problem) => {
            const d = I18n.m.dateCurrent.localeDateString(new Date(problem.day));
            if (lastDay === d)
                return null;
            lastDay = d;
            return (<View style={{ width: '100%', paddingVertical: 8 }}>
                <MaterialText onPress={openTimeTrackingDayForUserDialog({
                    memberId: props.memberId,
                    day: new Date(problem.day),
                    userId: props.userId,
                })}>
                  {I18n.m.getMessage('trackingProblemsDialogItemText', {
                    date: lastDay,
                })}
                </MaterialText>
              </View>);
        })}
        </View>
      </DialogContent>
    </>);
}
export const openTrackingProblemsDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        content: <TrackingProblemsDialog problems={props.problems} memberId={props.memberId} userId={props.userId}/>,
        fullscreenResponsive: true,
        width: 768,
        contentPadding: false,
        openPosition,
        scrollable: false,
        openImmediately: false,
        closeOnTouchOutside: true,
        showCloseIcon: false,
    });
};
