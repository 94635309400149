import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import React from 'react';
import { BimViewerDialog } from './BimViewerDialog';
export const openBimViewer = (props) => (e) => {
    const openPosition = e != null ? { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY } : { x: 0, y: 0 };
    Dialog.instance?.open({
        openPosition,
        fullscreen: true,
        scrollable: false,
        contentPadding: false,
        content: <BimViewerDialog file={props.file} json={props.json}/>,
    });
};
