import { PromisePool } from 'cqrs-shared/src/PromisePool';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { EntriesPerUserForDay, } from 'upmesh-core/src/client/query/entities/TimeTrackingCombinedEntities';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { CombinedExtraPayAndTimeTrackingEntity, CombinedExtraPayAndTimeTrackingFilter, } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
import { openAbsenceCreationChangeDialog } from '../absences/AbsenceCreationChangeDialog';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { AddOrChangeExtraPayTrackDialog } from '../extrapay/AddOrChangeExtraPayTrackDialog';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { PageView } from '../root/PageView';
import { CombinedExtraPayAndTimeTrackingFilterIcon } from './CombinedExtraPayAndTimeTrackingFilterIcon';
import { CombinedExtraPayTrackingDialogsControl } from './control/CombinedExtraPayTrackingDialogsControl';
import { TimeTrackingExcelExport } from './exports/TimeTrackingExcelExport';
import { TimeTrackingCalendar } from './viewcomponents/TimeTrackingCalendar';
import { TimeTrackingList } from './viewcomponents/TimeTrackingList';
import { TimeTrackingListPerDay } from './viewcomponents/TimeTrackingListPerDay';
import { TimeTrackingTable } from './viewcomponents/TimeTrackingTable';
import { TimeTrackingTablePerDay } from './viewcomponents/TimeTrackingTablePerDay';
import { TimeTrackingTimeline } from './viewcomponents/TimeTrackingTimeline';
export function TimeTrackingsView(props) {
    const initialView = SimpleStorage.get('newTimeTrackingView') || 'table';
    const [view, setView] = useState(initialView === 'timeline' ? 'timeline' : initialView === 'list' ? 'list' : 'table');
    const [searchWords, setSearchWords] = useState('');
    const [filter, setFilter] = useState(new CombinedExtraPayAndTimeTrackingFilter());
    const [updateCounter, setUpdateCounter] = useState(0);
    const [viewSingleEntries, setViewSingleEntries] = useState(SimpleStorage.get('timeTrackingViewSingleEntries') === '1');
    const [loading, setLoading] = useState(true);
    const [currentPeriod, setCurrentPeriod] = useState(undefined);
    const [periods, setPeriods] = useState([]);
    const [calenderHeight, setCalenderHeight] = useState(0);
    const connection = useContext(ConnectionContext);
    const [entryOnlineOnly, setEntryOnlineOnly] = useState(false);
    const [data, setData] = useState({ entriesInDates: [], filtered: [], members: undefined });
    const [allEntries, setAllEntries] = useState([]);
    const sDisplay = !(ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM);
    const maxHeight = sDisplay ? props.height : props.height - 48;
    const getTimeTrackingEntityForTable = async (e) => {
        return CombinedExtraPayAndTimeTrackingEntity.convert(e, CachedEntities.knownUsers, CachedEntities.knownTasks, CachedEntities.knownCompanyMember, CachedEntities.knownExtraPay, CachedEntities.knownCostCenter);
    };
    const loadData = async () => {
        if (currentPeriod == null)
            return [];
        let timeTrackings = [];
        let extraPayTrackings = [];
        const models = await CombinedExtraPayTrackingDialogsControl.getOnlineOrOfflineModels(currentPeriod.from);
        setEntryOnlineOnly(models.onlineOnly);
        const { absence, timeTracking, extraPayTracking } = models;
        if (models.onlineOnly && !connection.connectedToServer)
            return [];
        const collection = [];
        try {
            timeTrackings = await timeTracking.get({
                filter: `starts ge ${currentPeriod.from.toISOString()} and starts le ${currentPeriod.to.toISOString()} and deleted ne true and isBlocked ne 'rejected'`,
                orderby: 'memberId ASC, starts DESC',
            });
            collection.push(...timeTrackings);
            extraPayTrackings = await extraPayTracking.get({
                filter: `day ge ${currentPeriod.from.toISOString()} and day le ${currentPeriod.to.toISOString()} and deleted ne true and isBlocked ne 'rejected'`,
                orderby: 'memberId ASC, day DESC',
            });
            collection.push(...extraPayTrackings);
        }
        catch (err) {
            console.debug('cant get time trackings', err);
        }
        const absences = await absence.get({
            filter: `((starts gt ${currentPeriod.from.toISOString()} and starts le ${currentPeriod.to.toISOString()}) or ends gt ${currentPeriod.from.toISOString()}) and deleted ne true and isBlocked ne 'rejected'`,
        });
        for (const a of absences) {
            const tts = await TimeTrackingEntity.convertAbsenceToTimeTracking(a, CombinedExtraPayAndTimeTrackingEntity.getWorkingTimeModelForMember, currentPeriod.from, currentPeriod.to);
            collection.push(...tts);
        }
        const col = await PromisePool.run({
            collection,
            maxConcurrency: 5,
            task: getTimeTrackingEntityForTable,
        });
        const filteredCollection = CombinedExtraPayAndTimeTrackingFilter.filterTimeTrackings(col, {
            date: currentPeriod.from.getTime(),
            dateTo: currentPeriod.to.getTime(),
        });
        return filteredCollection;
    };
    const getfilteredEntries = async (allEntries, filter, period, showMissing = false) => {
        const startDate = period.from != null ? period.from : new Date();
        const to = period.to != null ? period.to : new Date();
        filter.date = new Date(startDate).getTime();
        filter.dateTo = new Date(to).getTime();
        const { me } = CompanyUserInfo;
        if (me == null)
            return { entriesInDates: [], filtered: [], members: undefined };
        const userIds = filter.userIds != null && filter.userIds.length > 0 ? [...filter.userIds] : [CurrentUser.userId];
        if (me.payroll && (filter.userIds == null || filter.userIds.length === 0)) {
            CompanyUserInfo.companyMember.forEach((m) => {
                if (m.userId != null && m.userId !== me.userId) {
                    userIds.push(m.userId);
                }
                else if (m.userId == null)
                    userIds.push(m.id);
            });
        }
        const showMissingPossible = (filter) => {
            if (filter != null &&
                ((filter.words != null && filter.words.length > 0) ||
                    (filter.status != null && filter.status.length > 0) ||
                    (filter.taskIds != null && filter.taskIds.length > 0) ||
                    (filter.costCenterIds != null && filter.costCenterIds.length > 0) ||
                    (filter.projectIds != null && filter.projectIds.length > 0)))
                return false;
            return true;
        };
        const showM = showMissing && showMissingPossible(filter);
        const filtered = CombinedExtraPayAndTimeTrackingFilter.filterTimeTrackings(allEntries, filter);
        const entriesInDates = await EntriesPerUserForDay.convert(filtered, startDate, to, userIds, CompanyUserInfo.companyMember, showM);
        const members = new Map();
        entriesInDates.forEach((e) => {
            e.entries.forEach((m) => {
                if (!members.has(m.memberId) && m.member)
                    members.set(m.memberId, m.member);
            });
        });
        return { entriesInDates, filtered, members };
    };
    useEffect(() => {
        let mounted = true;
        if (props.useFilterPeriod && filter.date != null && filter.dateTo != null) {
            setCurrentPeriod({ from: new Date(filter.date), to: new Date(filter.dateTo) });
        }
        else if (connection.connectedToServer) {
            UpmeshClient.instance.modals.payrollAccountingPeriods
                .get({ orderby: 'starts DESC' })
                .then((d) => {
                if (mounted) {
                    const p = d.map((a) => ({ from: new Date(a.starts), to: new Date(a.ends) }));
                    setPeriods(p);
                    if (p.length > 0) {
                        const c = p.find((a) => a.from.getTime() <= new Date().getTime() && a.to.getTime() >= new Date().getTime());
                        if (c)
                            setCurrentPeriod(c);
                        else
                            setCurrentPeriod(p[0]);
                    }
                    else {
                        const today = new Date();
                        setCurrentPeriod({
                            from: new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0),
                            to: new Date(today.getFullYear(), today.getMonth() + 1, 1, 0, 0, 0, -1),
                        });
                    }
                }
            })
                .catch((err) => {
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        }
        else {
            CombinedExtraPayAndTimeTrackingEntity.getCurrentPaymentPeriod(connection.connectedToServer, props.companySettings)
                .then((d) => {
                if (mounted) {
                    setCurrentPeriod(d);
                }
            })
                .catch((err) => {
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        }
        return () => {
            mounted = false;
        };
    }, [props.companySettings, connection.connectedToServer]);
    useEffect(() => {
        let mounted = true;
        setLoading(true);
        requestAnimationFrame(() => {
            loadData()
                .then((d) => {
                if (mounted && currentPeriod != null) {
                    setData({ members: data ? data.members : undefined, entriesInDates: [], filtered: d });
                    setAllEntries(d);
                    return getfilteredEntries(d, filter, currentPeriod, true);
                }
                return new Promise((r) => {
                    r(null);
                });
            })
                .then((d) => {
                if (mounted && d != null) {
                    setData(d);
                }
                setLoading(false);
            })
                .catch((err) => {
                setLoading(false);
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        });
        return () => {
            mounted = false;
        };
    }, [currentPeriod, updateCounter, connection.connectedToServer]);
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'TimeTrackingsView',
            readModelName: 'TimeTracking',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'TimeTrackingsViewExtra',
            readModelName: 'ExtraPayTracking',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'TimeTrackingsViewAbsence',
            readModelName: 'Absence',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        return () => {
            UpmeshClient.eventDispatcher.detach('TimeTracking', 'TimeTrackingsView');
            UpmeshClient.eventDispatcher.detach('ExtraPayTracking', 'TimeTrackingsViewExtra');
            UpmeshClient.eventDispatcher.detach('Absence', 'TimeTrackingsViewAbsence');
        };
    }, [updateCounter]);
    const openAddExtraTrackDialog = () => {
        let selectedDate = new Date();
        if (currentPeriod &&
            !(selectedDate.getTime() > currentPeriod.from.getTime() && selectedDate.getTime() < currentPeriod.to.getTime())) {
            selectedDate = currentPeriod.from;
        }
        Dialog.instance?.open({
            content: <AddOrChangeExtraPayTrackDialog selectedDate={selectedDate}/>,
            fullscreenResponsive: true,
            contentPadding: false,
            scrollable: false,
        });
    };
    useEffect(() => {
        if (Fab.instance != null) {
            const actions = [];
            if (CompanyUserInfo.me?.payroll || CompanyUserInfo.me?.canTimeTrackFor !== 'nobody') {
                let selectedDate = new Date();
                if (currentPeriod &&
                    !(selectedDate.getTime() > currentPeriod.from.getTime() && selectedDate.getTime() < currentPeriod.to.getTime())) {
                    selectedDate = currentPeriod.from;
                }
                actions.push({
                    icon: 'calendar-clock-outline',
                    onPress: CombinedExtraPayTrackingDialogsControl.openAddTimeTrackingDialog({
                        selectedDate,
                    }),
                    text: I18n.m.getMessage('timeTrackingCaptureTimeLater'),
                }, {
                    icon: 'clock-outline',
                    onPress: CombinedExtraPayTrackingDialogsControl.openAddTimeTrackingDialog({
                        hideTimes: { start: false, end: true, date: true, pause: false },
                        selectedDate: new Date(),
                    }),
                    text: I18n.m.getMessage('timeTrackingCaptureTime'),
                });
            }
            if (CompanyUserInfo.me?.payroll || CompanyUserInfo.me?.absenceFor !== 'nobody') {
                actions.push({
                    icon: 'calendar-arrow-right',
                    onPress: openAbsenceCreationChangeDialog({}),
                    text: I18n.m.getMessage('absenceDialogTitleAdd'),
                });
            }
            if (CompanyUserInfo.me?.payroll || CompanyUserInfo.me?.canExtraPayFor !== 'nobody') {
                actions.push({
                    icon: 'database-plus-outline',
                    onPress: openAddExtraTrackDialog,
                    text: I18n.m.getMessage('extraPayAddTrack'),
                });
            }
            if (actions.length > 0) {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    fabActions: actions,
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    extraPaddingBottom: ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0,
                });
            }
        }
        return () => {
            Fab.instance?.close();
        };
    }, [currentPeriod]);
    useEffect(() => {
        let mounted = true;
        if (currentPeriod != null)
            getfilteredEntries(allEntries, filter, currentPeriod, true)
                .then((d) => {
                if (mounted) {
                    setData(d);
                }
            })
                .catch((err) => {
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        return () => {
            mounted = false;
        };
    }, [filter]);
    useEffect(() => {
        try {
            if (props.ft != null && props.ft.length > 0) {
                const d = JSON.parse(props.ft);
                setFilter(d);
            }
            else {
                setFilter(new CombinedExtraPayAndTimeTrackingFilter());
            }
        }
        catch (err) {
            console.debug(err);
        }
    }, [props.ft]);
    const createAndDownloadExcel = () => {
        Menu.instance?.close();
        if (AuthClient.instance.serverConnected() && AuthClient.instance.syncDispatcher.currentSyncStatus.percent >= 100) {
            TimeTrackingExcelExport.createExcel(data.entriesInDates, filter.date ? new Date(filter.date) : new Date(), filter.dateTo ? new Date(filter.dateTo) : new Date()).catch((e) => {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            });
        }
        else {
            Routing.instance.alert.post({ text: I18n.m.getMessage('timeTrackingExportExcelNotOnline') });
        }
    };
    const headerIcons = [
        <CombinedExtraPayAndTimeTrackingFilterIcon sDisplay={sDisplay} currentFilter={{ ...filter, date: undefined, dateTo: undefined }} allEntries={allEntries}/>,
        <Icon icon="download-outline" color="#FFFFFF" toolTip={I18n.m.getMessage('timeTrackingExportExcelTooltip')} onPress={createAndDownloadExcel}/>,
        <Icon icon={viewSingleEntries ? 'calendar' : 'view-list-outline'} color="#FFFFFF" toolTip={I18n.m.getMessage(viewSingleEntries ? 'timeTrackingViewDailyEntries' : 'timeTrackingViewSingleEntries')} onPress={() => {
                SimpleStorage.set('timeTrackingViewSingleEntries', !viewSingleEntries ? '1' : '0');
                setViewSingleEntries(!viewSingleEntries);
            }}/>,
    ];
    const onPressChangeView = (index, _button) => {
        if (view !== 'table' && index === 1) {
            SimpleStorage.set('newTimeTrackingView', 'table');
            setView('table');
        }
        else if (view !== 'timeline' && index === 2) {
            SimpleStorage.set('newTimeTrackingView', 'timeline');
            setView('timeline');
        }
        else if (view !== 'list' && index === 0) {
            SimpleStorage.set('newTimeTrackingView', 'list');
            setView('list');
        }
    };
    const openViewChangeMenu = (e) => {
        Measurement.measure(e.nativeEvent.target)
            .then((s) => {
            const client = {
                x: s.pageX,
                y: s.pageY,
                height: s.height,
                width: 320,
            };
            Menu.instance?.open({
                client,
                items: [
                    {
                        text: I18n.m.getMessage('timeTrackingViewChangeList'),
                        onPress: () => {
                            Menu.instance?.close();
                            onPressChangeView(0);
                        },
                        thumbnail: {
                            thumbnail: <Icon toolTip="" icon="format-list-bulleted"/>,
                            width: 40,
                        },
                    },
                    {
                        text: I18n.m.getMessage('ticketsDetailsViewChangeToTableView'),
                        onPress: () => {
                            Menu.instance?.close();
                            onPressChangeView(1);
                        },
                        thumbnail: {
                            thumbnail: <Icon toolTip="" icon="table-large"/>,
                            width: 40,
                        },
                    },
                    {
                        text: I18n.m.getMessage('timeTrackingViewChangeCalandar'),
                        onPress: () => {
                            Menu.instance?.close();
                            onPressChangeView(2);
                        },
                        thumbnail: {
                            thumbnail: <Icon toolTip="" icon="chart-gantt"/>,
                            width: 40,
                        },
                    },
                ],
            });
        })
            .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    };
    const getViewIcon = () => {
        let icon = {
            icon: 'format-list-bulleted',
            rotation: 0,
            iconIconMoon: false,
            toolTip: I18n.m.getMessage('changeView'),
            onPress: openViewChangeMenu,
        };
        if (view === 'table') {
            icon = {
                icon: 'table-large',
                rotation: 0,
                iconIconMoon: false,
                toolTip: I18n.m.getMessage('changeView'),
                onPress: openViewChangeMenu,
            };
        }
        else if (view === 'timeline') {
            icon = {
                icon: 'chart-gantt',
                rotation: 0,
                iconIconMoon: false,
                toolTip: I18n.m.getMessage('changeView'),
                onPress: openViewChangeMenu,
            };
        }
        icon.toolTip = I18n.m.getMessage('changeView');
        icon.color = 'white';
        return icon;
    };
    if (!sDisplay) {
        headerIcons.push(<View key="segmentedButton" style={{ width: 144, marginLeft: 8 }}>
        <SegmentedButton buttons={[
                { icon: { icon: 'format-list-bulleted' } },
                { icon: { icon: 'table-large' } },
                { icon: { icon: 'chart-gantt' } },
            ]} onPress={onPressChangeView} singleSelectSelected={view === 'table' ? 1 : view === 'timeline' ? 2 : 0} backgroundColor="transparent" borderColor="#ffffff" textColor="#ffffff" selectedColor="rgba(196, 196, 196, 0.2)" density={2}/>
      </View>);
    }
    else
        headerIcons.push(<Icon {...getViewIcon()} key="viewIcon"/>);
    const onLayoutCalendar = (e) => {
        setCalenderHeight(e.nativeEvent.layout.height);
    };
    return (<PageView showAccountIcon={sDisplay} showMenu={false} style={{
            backgroundColor: 'transparent',
            width: '100%',
            overflow: 'hidden',
            position: 'absolute',
            height: '100%',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
        }} headerProps={{
            backgroundColor: 'transparent',
            withBorder: false,
            textColor: '#FFFFFF',
            title: '',
            searchBarProps: {
                backgroundColor: 'transparent',
                textColor: '#ffffff',
                iconColor: '#ffffff',
                hoverColor: ThemeManager.style.getDefaultHoverColor('#ffffff'),
                searchOnChange: setSearchWords,
                tooltip: I18n.m.getMessage('searchProjects'),
                onlyAppBar: true,
                searchBarValue: searchWords,
                searchBarPlaceholder: `${I18n.m.getMessage('timeTracking')}`,
            },
            rightButtons: headerIcons,
        }} scrollable={false}>
      <View style={{
            height: maxHeight,
            maxHeight,
            width: '100%',
            position: 'relative',
        }}>
        <View style={{ width: '100%', maxWidth: '100%', alignItems: 'center' }}>
          <View collapsable={false} onLayout={onLayoutCalendar} style={{ width: '100%', maxWidth: 1280, height: 'auto' }}>
            {currentPeriod != null && !props.useFilterPeriod && (<TimeTrackingCalendar entriesInDates={data.entriesInDates} onChangeSelected={(d) => {
                setLoading(true);
                setCurrentPeriod({ from: d.startDate, to: d.endDate });
            }} periodSelection={periods} start={currentPeriod.from} end={currentPeriod.to}/>)}
          </View>

          {loading || !data || !data.members || currentPeriod == null ? (<Spinner />) : !connection.connectedToServer && entryOnlineOnly ? (<View style={{ width: '100%', paddingVertical: 8 }}>
              <MaterialText centeredText centeredBox fixedWidth="100%" color="#FFFFFF">
                {I18n.m.getMessage('offlineNotAvailable')}
              </MaterialText>
            </View>) : viewSingleEntries && view === 'table' ? (<TimeTrackingTable maxHeight={maxHeight - 16 - calenderHeight} data={data.filtered}/>) : view === 'table' ? (<TimeTrackingTablePerDay maxHeight={maxHeight - 16 - calenderHeight} entriesInDates={data.entriesInDates}/>) : view === 'list' && viewSingleEntries ? (<TimeTrackingList maxHeight={maxHeight - calenderHeight} data={data.filtered}/>) : view === 'list' ? (<TimeTrackingListPerDay maxHeight={maxHeight - calenderHeight} entries={data.entriesInDates}/>) : (<TimeTrackingTimeline currentPeriod={currentPeriod} companySettings={props.companySettings} maxHeight={maxHeight - 16 - calenderHeight} entriesInDates={data.entriesInDates} members={data.members}/>)}
        </View>
      </View>
    </PageView>);
}
