import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React from 'react';
import { View } from 'react-native';
import { openCompanyMemberDialog } from '../../companies/member/openCompanyMemberDialog';
export function TimeTrackingDialogHeader(props) {
    const { entry, extraText, forDay, status } = props;
    let thirdTextLine = entry.taskName != null && entry.taskName.length > 0 ? entry.taskName : '';
    if (entry.costCenterName != null && entry.costCenterName.length > 0) {
        if (thirdTextLine.length > 0)
            thirdTextLine += ' • ';
        thirdTextLine += entry.costCenterName;
    }
    if (extraText != null)
        thirdTextLine += ` • ${extraText}`;
    return (<View style={{ width: '100%', paddingBottom: 16 }}>
      <View style={{ width: '100%', flexDirection: 'row' }}>
        <View style={{ width: 72 }}>{entry.user != null && <UserImage lightBox size={56} user={entry.user}/>}</View>
        <View style={{ flex: 1 }}>
          {entry.memberName != null && entry.memberName.length > 0 && (<MaterialText onPress={(e) => {
                Dialog.instance?.close(() => {
                    openCompanyMemberDialog({ memberId: entry.memberId, currentList: [] })(e);
                });
            }} strong>
              {entry.memberName}
            </MaterialText>)}
          <MaterialText>
            {forDay}
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body2}>{thirdTextLine}</MaterialText>
        </View>
        {status}
      </View>
    </View>);
}
