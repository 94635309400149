var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoredStartPages = exports.StartPages = exports.ProjectStartPages = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var _I18n = require("../theme/i18n/I18n");
var StartPages;
(function (StartPages) {
  StartPages[StartPages["dashboard"] = 0] = "dashboard";
  StartPages[StartPages["projects"] = 1] = "projects";
  StartPages[StartPages["allTickets"] = 2] = "allTickets";
  StartPages[StartPages["timeTracking/timeTracking"] = 3] = "timeTracking/timeTracking";
})(StartPages || (exports.StartPages = StartPages = {}));
var ProjectStartPages;
(function (ProjectStartPages) {
  ProjectStartPages[ProjectStartPages["project"] = 0] = "project";
  ProjectStartPages[ProjectStartPages["tickets"] = 1] = "tickets";
  ProjectStartPages[ProjectStartPages["files"] = 2] = "files";
  ProjectStartPages[ProjectStartPages["projectsettings"] = 3] = "projectsettings";
})(ProjectStartPages || (exports.ProjectStartPages = ProjectStartPages = {}));
var StoredStartPages = exports.StoredStartPages = function () {
  function StoredStartPages() {
    (0, _classCallCheck2.default)(this, StoredStartPages);
  }
  return (0, _createClass2.default)(StoredStartPages, null, [{
    key: "getAvailableProjectStartPages",
    value: function getAvailableProjectStartPages() {
      var keys = Object.keys(ProjectStartPages);
      var result = [];
      for (var i = 0; i < keys.length / 2; i += 1) {
        var stringKey = ProjectStartPages[i];
        result.push({
          title: _I18n.I18n.m.getMessage(`start${stringKey}`),
          data: {
            id: stringKey
          }
        });
      }
      return result;
    }
  }, {
    key: "getAvailableStartPages",
    value: function getAvailableStartPages() {
      var withTimeTracking = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var keys = Object.keys(StartPages);
      var result = [];
      for (var i = 0; i < keys.length / 2; i += 1) {
        var stringKey = StartPages[i];
        if (stringKey !== 'timeTracking/timeTracking') {
          result.push({
            title: _I18n.I18n.m.getMessage(`start${stringKey}`),
            data: {
              id: stringKey
            }
          });
        }
        if (stringKey === 'timeTracking/timeTracking' && withTimeTracking) {
          result.push({
            title: _I18n.I18n.m.getMessage('starttimeTracking'),
            data: {
              id: stringKey
            }
          });
        }
      }
      return result;
    }
  }, {
    key: "storeProjectStartPage",
    value: function storeProjectStartPage(newStartPage) {
      var _ProjectStartPages$ne;
      var key = (_ProjectStartPages$ne = ProjectStartPages[newStartPage]) != null ? _ProjectStartPages$ne : ProjectStartPages[ProjectStartPages.project];
      return _SimpleStorage.SimpleStorage.set(StoredStartPages.projectStartPageStoreKey, key);
    }
  }, {
    key: "storeStartPage",
    value: function storeStartPage(newStartPage) {
      var _StartPages$newStartP;
      var key = (_StartPages$newStartP = StartPages[newStartPage]) != null ? _StartPages$newStartP : StartPages[StartPages.dashboard];
      return _SimpleStorage.SimpleStorage.set(StoredStartPages.startPageStoreKey, key);
    }
  }, {
    key: "getStoredProjectStartPage",
    value: function getStoredProjectStartPage() {
      try {
        var storeResult = _SimpleStorage.SimpleStorage.get(StoredStartPages.projectStartPageStoreKey);
        if (storeResult != null && ProjectStartPages[storeResult] != null) {
          return storeResult;
        }
      } catch (e) {}
      return ProjectStartPages[ProjectStartPages.project];
    }
  }, {
    key: "getStoredStartPage",
    value: function getStoredStartPage() {
      try {
        var storeResult = _SimpleStorage.SimpleStorage.get(StoredStartPages.startPageStoreKey);
        if (storeResult != null && StartPages[storeResult] != null) {
          return storeResult;
        }
      } catch (e) {}
      return StartPages[StartPages.dashboard];
    }
  }]);
}();
StoredStartPages.projectStartPageStoreKey = 'projectStartPageKey';
StoredStartPages.startPageStoreKey = 'startPageKey';