import { ExcelDownloadHelper } from 'materialTheme/src/theme/components/file/ExcelDownloadHelper';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { HolidayEntity } from 'upmesh-core/src/client/query/entities/HolidayEntity';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as XLSX from 'xlsx';
import { I18n } from '../../../i18n/I18n';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
export class TimeTrackingExcelExport {
    static isEmpty(entries) {
        if (entries.length < 1)
            return true;
        for (const entry of entries) {
            for (const entry2 of entry.entries) {
                if (entry2.memberEntries.length > 0)
                    return false;
            }
        }
        return true;
    }
    static getKeyForMember(value) {
        if (TimeTrackingExcelExport.persNumbers == null) {
            console.error('staff numbers should be initialized wrong usage');
        }
        const staff = TimeTrackingExcelExport.persNumbers.get(value.userId ?? '');
        if (staff == null || staff.length < 1) {
            return value.memberName.substring(0, 31);
        }
        return `${staff} ${value.memberName}`.substring(0, 31);
    }
    static async loadStaffNumbers() {
        const staffNumbers = new Map();
        const timeModels = new Map();
        for (const member of CompanyUserInfo.companyMember) {
            staffNumbers.set(member.userId ?? '', member.staffNumber != null && member.staffNumber.length > 0 ? member.staffNumber : '');
            if (member.workingTimeModelId != null && member.workingTimeModelId.length > 0) {
                const timeModel = await UpmeshClient.instance.modals.workingTimeModel.getById(member.workingTimeModelId);
                timeModels.set(member.userId ?? '', timeModel);
            }
        }
        TimeTrackingExcelExport.persNumbers = staffNumbers;
        TimeTrackingExcelExport.workingTimeModels = timeModels;
    }
    static getKeyForDate(date) {
        return `${date.getDate()}${date.getMonth()}${date.getFullYear()}`;
    }
    static async createExcel(entries, start, end) {
        if (TimeTrackingExcelExport.isEmpty(entries)) {
            Routing.instance.alert.post({ text: I18n.m.getMessage('timeTrackingExportExcelNoEntries') });
            return;
        }
        await TimeTrackingExcelExport.loadStaffNumbers();
        const allCleartextColumns = [];
        const selectedColumns = new Set([
            'day',
            'memberName',
            'type',
            'costCenterName',
            'projectTitle',
            'creatorName',
            'taskName',
            'extraFieldValue',
            'extraPayTitle',
            'startTimeInMS',
            'ends',
            'pauseInMS',
            'sumInMS',
            'status',
            'note',
        ]);
        const allAvailableColumns = new Map([
            ['day', I18n.m.getMessage('date')],
            ['memberName', I18n.m.getMessage('employee')],
            ['type', I18n.m.getMessage('trackingType')],
            ['costCenterName', I18n.m.getMessage('costCenter')],
            ['projectTitle', I18n.m.getMessage('project')],
            ['creatorName', I18n.m.getMessage('timeTrackingRecorder')],
            ['taskName', I18n.m.getMessage('task')],
            ['extraFieldValue', I18n.m.getMessage('extraPayFieldValue')],
            ['extraPayTitle', I18n.m.getMessage('extraPay')],
            ['startTimeInMS', I18n.m.getMessage('workingTimeModelsStart')],
            ['ends', I18n.m.getMessage('workingTimeModelsStop')],
            ['pauseInMS', I18n.m.getMessage('workingTimeModelsPause')],
            ['sumInMS', I18n.m.getMessage('timeTrackingSum')],
            ['status', I18n.m.getMessage('timeTrackingStatus')],
            ['note', I18n.m.getMessage('timeTrackingNotes')],
        ]);
        try {
            const tableSettingsRaw = SimpleStorage.get(`tableSettings_TimeTrackingTableView`);
            if (tableSettingsRaw != null) {
                const tableSettings = JSON.parse(tableSettingsRaw);
                if (tableSettings != null && tableSettings.columns != null && tableSettings.currentSelected != null) {
                    const { currentSelected } = tableSettings;
                    for (const key of allAvailableColumns.keys()) {
                        const value = allAvailableColumns.get(key) ?? '';
                        if (currentSelected.findIndex((e) => e === value) < 0) {
                            selectedColumns.delete(key);
                        }
                    }
                }
            }
        }
        catch (e) {
            console.error('can not load saved columns:', e);
        }
        for (const key of selectedColumns) {
            allCleartextColumns.push(allAvailableColumns.get(key) ?? '');
        }
        const workBook = XLSX.utils.book_new();
        workBook.Props = {
            Title: I18n.m.getMessage('timeTrackingExportExcelFilename'),
            CreatedDate: new Date(),
        };
        const dataSets = new Map();
        entries.forEach((data) => {
            data.entries.forEach((dateData) => {
                dateData.memberEntries.forEach((entry) => {
                    if (!dataSets.has(entry.memberId)) {
                        const overviewKey = `${I18n.m.getMessage('timeTrackingExportExcelOverview')} ${TimeTrackingExcelExport.getKeyForMember(entry)}`.substring(0, 31);
                        workBook.SheetNames.push(overviewKey);
                        workBook.SheetNames.push(TimeTrackingExcelExport.getKeyForMember(entry));
                    }
                    const memberArray = dataSets.get(entry.memberId) ?? [];
                    memberArray.push(entry);
                    dataSets.set(entry.memberId, memberArray);
                });
            });
        });
        let lastDateAsNumber = 0;
        for (const user of dataSets.values()) {
            const currentData = [];
            currentData.push(allCleartextColumns);
            let sumForUser = 0;
            const helperForDay = new Map();
            const userTimeModel = TimeTrackingExcelExport.workingTimeModels.get(user[0].userId ?? '');
            if (userTimeModel != null) {
                const iteratorDate = new Date(start);
                const companyMember = await UpmeshClient.instance.modals.companyMember.getById(user[0].memberId);
                while (iteratorDate.getTime() < end.getTime() + 1) {
                    const holidayForDay = await HolidayEntity.getHolidayForDayAndUser(companyMember, iteratorDate);
                    let biggestHoliday = 0;
                    if (holidayForDay.length > 0) {
                        holidayForDay.forEach((holiday) => {
                            if (holiday.type === 'day') {
                                biggestHoliday = 24;
                            }
                            else if (biggestHoliday < holiday.amount) {
                                biggestHoliday = holiday.amount;
                            }
                        });
                    }
                    biggestHoliday = biggestHoliday * 60 * 60 * 1000;
                    const targetItt = userTimeModel.getTargetSumInMs(iteratorDate);
                    let targetTimeInMs = userTimeModel.getTargetSumInMs(iteratorDate) ?? 0;
                    targetTimeInMs = Math.max(targetTimeInMs - biggestHoliday, 0);
                    if (targetItt != null && targetItt > 0) {
                        const currentHelper = {
                            tasks: [],
                            notes: [],
                            targetTimeInMs,
                            summedWorkTime: 0,
                            start: -1,
                            end: -1,
                            date: new Date(iteratorDate),
                        };
                        helperForDay.set(TimeTrackingExcelExport.getKeyForDate(iteratorDate), currentHelper);
                    }
                    iteratorDate.setDate(iteratorDate.getDate() + 1);
                }
            }
            user.forEach((userEntry) => {
                const key = TimeTrackingExcelExport.getKeyForDate(new Date(userEntry.day));
                if (!helperForDay.has(key)) {
                    const currentHelper = {
                        date: new Date(userEntry.day),
                        tasks: userEntry.taskName != null ? [userEntry.taskName] : [],
                        notes: userEntry.note != null && userEntry.note.trim().length > 0 ? [userEntry.note] : [],
                        targetTimeInMs: 0,
                        summedWorkTime: userEntry.sumInMS,
                        start: userEntry.starts != null ? userEntry.starts.getTime() : -1,
                        end: userEntry.ends != null ? userEntry.ends.getTime() : -1,
                    };
                    helperForDay.set(key, currentHelper);
                }
                else {
                    const currentHelper = helperForDay.get(key);
                    if (currentHelper != null) {
                        if (userEntry.taskName != null && !currentHelper.tasks.includes(userEntry.taskName)) {
                            currentHelper.tasks.push(userEntry.taskName);
                        }
                        currentHelper.notes =
                            userEntry.note != null && userEntry.note.trim().length > 0
                                ? [...currentHelper.notes, userEntry.note]
                                : currentHelper.notes;
                        currentHelper.summedWorkTime += userEntry.sumInMS;
                        if (userEntry.starts != null) {
                            currentHelper.start =
                                currentHelper.start > userEntry.starts.getTime() || currentHelper.start === -1
                                    ? userEntry.starts.getTime()
                                    : currentHelper.start;
                        }
                        currentHelper.end =
                            userEntry.ends != null && userEntry.ends.getTime() > currentHelper.end
                                ? userEntry.ends.getTime()
                                : currentHelper.end;
                        helperForDay.set(key, currentHelper);
                    }
                }
                const rowData = [];
                selectedColumns.forEach((cell) => {
                    const value = userEntry[cell] ?? '';
                    if (cell === 'day') {
                        if (Number(value) > lastDateAsNumber) {
                            lastDateAsNumber = value;
                        }
                        rowData.push(new Date(value).toLocaleDateString());
                    }
                    else if (cell === 'startTimeInMS') {
                        if (userEntry.type === 'ExtraPayTrackingEntity') {
                            rowData.push('');
                        }
                        else {
                            rowData.push(`${new Date(value).toLocaleTimeString()}`);
                        }
                    }
                    else if (cell === 'ends') {
                        if (userEntry.type === 'ExtraPayTrackingEntity') {
                            rowData.push('');
                        }
                        else {
                            rowData.push(new Date(value).toLocaleTimeString());
                        }
                    }
                    else if (cell === 'pauseInMS') {
                        if (userEntry.type === 'ExtraPayTrackingEntity') {
                            rowData.push('');
                        }
                        else {
                            rowData.push(TimeTrackingEntity.msToTime(value, false, false));
                        }
                    }
                    else if (cell === 'sumInMS') {
                        sumForUser += value;
                        rowData.push(TimeTrackingEntity.msToTime(value, false, true));
                    }
                    else if (cell === 'status') {
                        rowData.push(I18n.m.getMessage(`timeTrackingFilterStatus${value}`));
                    }
                    else if (cell === 'type') {
                        if (value === 'TimeTrackingEntity') {
                            rowData.push(I18n.m.getMessage('trackingTypeTime'));
                        }
                        else if (value === 'ExtraPayTrackingEntity') {
                            rowData.push(I18n.m.getMessage('trackingTypeExtraPay'));
                        }
                        else {
                            rowData.push(I18n.m.getMessage('trackingTypeAbsence'));
                        }
                    }
                    else {
                        rowData.push(value);
                    }
                });
                currentData.push(rowData);
            });
            currentData.push([
                I18n.m.getMessage('timeTrackingExportExcelSumAsDecimal'),
                (sumForUser / (1000 * 60 * 60)).toFixed(2),
            ]);
            const sheetKey = TimeTrackingExcelExport.getKeyForMember(user[0]);
            const trackingHeader = [
                [I18n.m.getMessage('timeTrackingExportExcelRecords')],
                [
                    `${I18n.m.getMessage('name')}:`,
                    user[0].memberName,
                    null,
                    I18n.m.getMessage('timeTrackingExportExcelStaffNumber'),
                    TimeTrackingExcelExport.persNumbers.get(user[0].userId ?? ''),
                ],
                [
                    `${I18n.m.getMessage('absenceStarts')}:`,
                    `${I18n.m.date().localeDateString(start)}`,
                    null,
                    `${I18n.m.getMessage('absenceEnds')}:`,
                    `${I18n.m.date().localeDateString(end)}`,
                ],
                [],
            ];
            workBook.Sheets[sheetKey] = XLSX.utils.aoa_to_sheet(trackingHeader.concat(currentData), {
                cellDates: true,
            });
            const overview = [
                [I18n.m.getMessage('timeTrackingExportExcelOverview')],
                [
                    `${I18n.m.getMessage('name')}:`,
                    user[0].memberName,
                    null,
                    I18n.m.getMessage('timeTrackingExportExcelStaffNumber'),
                    TimeTrackingExcelExport.persNumbers.get(user[0].userId ?? ''),
                ],
                [
                    `${I18n.m.getMessage('absenceStarts')}:`,
                    `${I18n.m.date().localeDateString(start)}`,
                    null,
                    `${I18n.m.getMessage('absenceEnds')}:`,
                    `${I18n.m.date().localeDateString(end)}`,
                ],
                [],
            ];
            const sortedArray = Array.from(helperForDay.values()).sort((a, b) => {
                return a.date.getTime() - b.date.getTime();
            });
            const overviewData = [];
            let totalDiffSum = 0;
            let totalDoneSum = 0;
            let totalDueSum = 0;
            let row = 5;
            const rowStart = row + 1;
            sortedArray.forEach((element) => {
                row += 1;
                totalDiffSum += element.summedWorkTime - element.targetTimeInMs;
                totalDoneSum += element.summedWorkTime;
                totalDueSum += element.targetTimeInMs;
                const startDate = new Date(element.start);
                const endDate = new Date(element.end);
                const startTime = element.start > 0 ? (startDate.getHours() + startDate.getMinutes() / 60) / 24 : 0;
                const endTime = element.end > 0 ? (endDate.getHours() + endDate.getMinutes() / 60) / 24 : 0;
                overviewData.push([
                    I18n.m.date().localeDateString(element.date),
                    { t: 'n', v: startTime, z: '[h]:mm' },
                    { t: 'n', v: endTime, z: '[h]:mm' },
                    { t: 'n', v: (element.end - element.start - element.summedWorkTime) / 1000 / 3600 / 24, z: '[h]:mm' },
                    { t: 'n', v: element.targetTimeInMs / 1000 / 3600 / 24, z: '[h]:mm' },
                    {
                        t: 'n',
                        f: `IF(C${row}=B${row},0,IF(C${row}>B${row},C${row}-B${row}-D${row},1-B${row}+C${row}-D${row}))`,
                        z: '[h]:mm',
                    },
                    { t: 'n', f: `F${row}-E${row}`, z: '[h]:mm' },
                    element.tasks.join(';'),
                    element.notes.join(';'),
                ]);
            });
            const overViewHeader = [
                [
                    I18n.m.getMessage('date'),
                    I18n.m.getMessage('timeTrackingCSVexportStart'),
                    I18n.m.getMessage('timeTrackingCSVexportEnde'),
                    I18n.m.getMessage('timeTrackingCSVexportPause'),
                    I18n.m.getMessage('timeTrackingExportExcelTargetTime'),
                    I18n.m.getMessage('timeTrackingExportExcelActualTime'),
                    I18n.m.getMessage('timeTrackingExportExcelDiffTime'),
                    I18n.m.getMessage('task'),
                    I18n.m.getMessage('timeTrackingCSVexportNote'),
                ],
            ];
            const overviewFooter = [
                [],
                [
                    I18n.m.getMessage('timeTrackingSum'),
                    '',
                    '',
                    '',
                    { t: 'n', f: `SUM(E${rowStart}:E${row})`, z: '[h]:mm' },
                    { t: 'n', f: `SUM(F${rowStart}:F${row})`, z: '[h]:mm' },
                    { t: 'n', f: `SUM(G${rowStart}:G${row})`, z: '[h]:mm' },
                ],
            ];
            const overviewKey = `${I18n.m.getMessage('timeTrackingExportExcelOverview')} ${sheetKey}`.substring(0, 31);
            if (!workBook.Workbook)
                workBook.Workbook = {};
            if (!workBook.Workbook.WBProps)
                workBook.Workbook.WBProps = {};
            if (workBook.Workbook && workBook.Workbook.WBProps)
                workBook.Workbook.WBProps.date1904 = true;
            workBook.Sheets[overviewKey] = XLSX.utils.aoa_to_sheet(overview.concat(overViewHeader, overviewData, overviewFooter));
        }
        const lastDate = new Date(lastDateAsNumber);
        const filename = `${lastDate.getFullYear()}_${(lastDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}_${I18n.m.getMessage('timeTrackingExportExcelFilename')}`;
        await ExcelDownloadHelper.saveAndOpen(filename, workBook);
    }
}
