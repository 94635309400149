import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { SUPPORTED_EXPORTS, } from 'upmesh-core/src/server/webserver/commands/timetracking/ExportInformation';
import { I18n } from '../../../../i18n/I18n';
export function DatevLodasOptions(props) {
    const [datevMandantNr, setDatevMandantNr] = useState('');
    const [datevBeraterNr, setDatevBeraterNr] = useState('');
    useEffect(() => {
        const value = SimpleStorage.get(`timeTrackingOptions${SUPPORTED_EXPORTS.datevLodas.short}`);
        if (value != null && value.length > 0) {
            const storedData = JSON.parse(value);
            setDatevMandantNr(storedData.datevMandantNr);
            setDatevBeraterNr(storedData.datevBeraterNr);
        }
    }, []);
    useEffect(() => {
        const data = SUPPORTED_EXPORTS.datevLodas;
        data.additionalData.datevMandantNr = datevMandantNr;
        data.additionalData.datevBeraterNr = datevBeraterNr;
        props.onChange(data);
    }, [datevMandantNr, datevBeraterNr]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            SimpleStorage.set(`timeTrackingOptions${SUPPORTED_EXPORTS.datevLodas.short}`, JSON.stringify(SUPPORTED_EXPORTS.datevLodas.additionalData));
        }, 250);
        return () => {
            clearTimeout(timeout);
        };
    }, [datevMandantNr, datevBeraterNr]);
    const datevBeraterRegex = /^\w{4,7}$/g;
    const datevMandantenRegex = /^\w{1,5}$/g;
    return (<View>
      <MaterialText>{I18n.m.getMessage('timeTrackingExportDatevMandantenNrNeeded')}</MaterialText>
      <FormInputFilled labelText={I18n.m.getMessage('timeTrackingExportDatevMandantenNrNeeded')} onChange={setDatevMandantNr} numberOfLines={1} value={datevMandantNr}/>
      <FormInputFilled labelText={I18n.m.getMessage('timeTrackingExportDatevBeraterNrNeeded')} onChange={setDatevBeraterNr} numberOfLines={1} value={datevBeraterNr}/>
      {!datevMandantenRegex.test(datevMandantNr) ? (<MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage('timeTrackingExportDatevMandantenInvalid', { error: datevMandantNr })}
        </MaterialText>) : null}
      {!datevBeraterRegex.test(datevBeraterNr) ? (<MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage('timeTrackingExportDatevBeraterInvalid', { error: datevBeraterNr })}
        </MaterialText>) : null}
    </View>);
}
