import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { I18n } from '../../i18n/I18n';
import { PlanThumb169 } from './PlanThumb169';
import { CurrentProject } from '../project/CurrentProject';
export const PlanDetailsComponent = React.memo((props) => {
    const [canEdit, setCanEdit] = useState(false);
    const fetchRights = async () => {
        const projectId = CurrentProject.instance.getCurrentProjectId();
        const { userId } = CurrentUser;
        const editRights = projectId
            ? await RightsManager.hasWriteRight(projectId, userId, 'commandChangePlanDetails')
            : false;
        setCanEdit(editRights);
    };
    useEffect(() => {
        fetchRights().catch((err) => console.debug(err));
    }, []);
    const getPlanTagChips = () => {
        const { plan } = props;
        const tags = [];
        if (plan != null && plan.tags != null && plan.tags.length > 0) {
            plan.tags.forEach((t) => {
                tags.push({
                    title: t.tagName,
                    backgroundColor: t.color,
                });
            });
        }
        return tags;
    };
    const pressEditPlan = (e) => {
        const { plan } = props;
        if (plan != null) {
            Routing.instance.openDialog('editPlan', { type: 'edit', planId: plan.id })(e);
        }
    };
    const gotoActivePlanVersion = () => {
        const { plan } = props;
        Routing.instance.goTo(`/projects/${plan.projectId}/plans/${plan.id}/versions/${plan.activePlanId}`);
    };
    if (props.plan == null) {
        return <View />;
    }
    return (<View style={{
            width: '100%',
            position: 'relative',
            flexDirection: 'column',
            height: 'auto',
            padding: 16,
            paddingTop: 32,
        }}>
        <View style={{ position: 'absolute', right: 16, top: 16 }}>
          <Icon toolTip={I18n.m.getMessage('tooltipEditPlanDetails')} key="edit" color={ThemeManager.style.headerButtonColor} disabled={!canEdit} onPress={pressEditPlan} icon="pencil-outline"/>
        </View>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('planDetailsPlanTitle')}</MaterialText>
        <MaterialText type={MaterialTextTypes.Body1}>{props.plan.title}</MaterialText>
        {!props.showThumb ? null : (<View style={{ paddingTop: 16, paddingBottom: 0 }}>
            <MaterialText type={MaterialTextTypes.Caption}>
              {I18n.m.getMessage('planDetailsPlanActivePlan')}
            </MaterialText>
            <PlanThumb169 plan={props.plan} projectId={props.plan.projectId} fileSource={props.plan.activePlanId === 'MAP' ? 'map' : 'planVersion'} width={200} fileId={props.plan.activePlanId} onPress={gotoActivePlanVersion}/>
          </View>)}
        <View style={{ paddingTop: 16, paddingBottom: 0 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('planDetailsPlanTags')}</MaterialText>
        </View>
        <ChipGroup buttonDisabled textColor="#FFFFFF" chips={getPlanTagChips()} dialogTitle={I18n.m.getMessage('planDetailsPlanTags')}/>
        <View style={{ paddingTop: 16, paddingBottom: 0 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('planDetailsPlanNotes')}</MaterialText>
        </View>
        <MaterialText type={MaterialTextTypes.Body1}>{props.plan.notes}</MaterialText>
      </View>);
});
