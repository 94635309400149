import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { KeyboardEvents } from 'materialTheme/src/theme/components/utils/KeyboardEvents';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
import { CompanyUser } from './CompanyUser';
import { CompanyUserInformations } from './tabs/CompanyUserInformations';
import { CompanyUserRights } from './tabs/CompanyUserRights';
import { CompanyUserTimeTrackingOverview } from './tabs/CompanyUserTimeTrackingOverview';
import { CompanyUserWorkingTimeModels } from './tabs/CompanyUserWorkingTImeModels';
export function CompanyMemberDialog(props) {
    const [member, setMember] = useState();
    const tabs = [{ title: I18n.m.getMessage('companyMemberDialogInformation') }];
    const hasModule = CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTracking');
    if (hasModule)
        tabs.push({ title: I18n.m.getMessage('companyMemberDialogWorkingTimeModel') });
    tabs.push({ title: I18n.m.getMessage('companyMemberDialogRights') });
    if (hasModule)
        tabs.push({ title: I18n.m.getMessage('companyMemberDialogTimeTracking') });
    const st = props.data.selectedTab ? Number.parseInt(props.data.selectedTab, 10) : 0;
    const selectedTab = st > 0 ? st : 0;
    const contentWidth = Math.min(props.maxWidth, props.size.contentWidth);
    useEffect(() => {
        let mounted = true;
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyMemberDialogContent',
            readModelName: 'CompanyMember',
            callback: (e) => {
                if (e.entities != null && e.entities.size > 0) {
                    e.entities.forEach((a) => {
                        if (a.entity != null && a.entity.id === props.data.memberId) {
                            CompanyUser.load(props.data.memberId, true)
                                .then((e) => {
                                if (mounted) {
                                    setMember(e);
                                }
                            })
                                .catch(DefaultErrorHandler.showDefaultErrorAlert);
                        }
                    });
                }
            },
        });
        CompanyUser.load(props.data.memberId)
            .then((e) => {
            if (mounted) {
                setMember(e);
            }
        })
            .catch(DefaultErrorHandler.showDefaultErrorAlert);
        return () => {
            UpmeshClient.eventDispatcher.detach('CompanyMember', 'CompanyMemberDialogContent');
            mounted = false;
        };
    }, [props.data.memberId]);
    const next = (e) => {
        if (member == null)
            return;
        if (CompanyUser.currentMemberList == null || CompanyUser.currentMemberList.length <= 1) {
            CompanyUser.currentMemberList = [...CompanyUserInfo.companyMember];
        }
        const index = CompanyUser.currentMemberList.findIndex((a) => a.id === member.member.id);
        let newIndex = index + 1;
        if (newIndex >= CompanyUser.currentMemberList.length)
            newIndex = 0;
        Routing.instance.openDialog('member', { memberId: CompanyUser.currentMemberList[newIndex].id, selectedTab }, true)(e);
    };
    const prev = (e) => {
        if (member == null)
            return;
        if (CompanyUser.currentMemberList == null || CompanyUser.currentMemberList.length <= 1) {
            CompanyUser.currentMemberList = [...CompanyUserInfo.companyMember];
        }
        const index = CompanyUser.currentMemberList.findIndex((a) => a.id === member.member.id);
        let newIndex = index - 1;
        if (newIndex < 0)
            newIndex = CompanyUser.currentMemberList.length - 1;
        Routing.instance.openDialog('member', { memberId: CompanyUser.currentMemberList[newIndex].id, selectedTab }, true)(e);
    };
    useEffect(() => {
        KeyboardEvents.onKeyDown((e) => {
            switch (e.keyCode) {
                case 27:
                    e.preventDefault();
                    Dialog.instance?.close();
                    break;
                case 37:
                    e.preventDefault();
                    prev();
                    break;
                case 39:
                    e.preventDefault();
                    next();
                    break;
            }
        }, 'UserProfile');
        return () => {
            KeyboardEvents.remove('UserProfile');
        };
    }, [member, props.data.selectedTab]);
    if (member == null)
        return <Spinner />;
    const renderContent = () => {
        let content = <View />;
        const minHeight = Math.min(0.9 * props.size.windowHeight, 1024) - 196;
        if (selectedTab === 0) {
            content = <CompanyUserInformations key={`info_${member.member.id}`} user={member}/>;
        }
        else if (selectedTab === 1 && hasModule) {
            content = <CompanyUserWorkingTimeModels user={member}/>;
        }
        else if (selectedTab === 2 || (selectedTab === 1 && hasModule)) {
            content = <CompanyUserRights user={member}/>;
        }
        else if (selectedTab === 3) {
            return (<View style={{ width: '100%', minHeight }}>
          <CompanyUserTimeTrackingOverview user={member} maxHeight={minHeight}/>
        </View>);
        }
        return (<ScrollContainer style={{ height: minHeight, width: '100%' }}>
        <View style={{ width: '100%', minHeight }}>{content}</View>
      </ScrollContainer>);
    };
    return (<>
      <DialogTitle key="title" iconRight={{
            icon: 'close',
            onPress: Routing.instance?.closeDialog(true),
            toolTip: I18n.m.getMessage('close'),
        }} onPressTitle={() => {
            const content = (<View style={{ width: '100%' }}>
              {CompanyUser.currentMemberList.map((m) => {
                    return (<Ripple key={`member-${m.id}`} style={{ width: '100%', flexDirection: 'row', alignItems: 'center', marginVertical: 8 }} onPress={(e) => {
                            Dialog.instance?.close(() => {
                                Routing.instance.openDialog('member', { memberId: m.id, selectedTab: props.data.selectedTab }, true)(e);
                            });
                        }}>
                    <View style={{ width: 48, paddingLeft: 4 }}>
                      <UserImage size={36} user={m['user'] && typeof m['user'].getFullName === 'function'
                            ? m['user']
                            : new UserEntity({ firstname: m.firstName, lastname: m.lastName })}/>
                    </View>
                    <View style={{ flex: 1 }}>
                      <MaterialText fixedWidth="100%" numberOfLines={1}>
                        {m['user'] && typeof m['user'].getFullName === 'function'
                            ? m['user'].getFullName()
                            : `${m.firstName} ${m.lastName}`}
                      </MaterialText>
                    </View>
                  </Ripple>);
                })}
            </View>);
            Dialog.instance?.open({
                content,
                scrollable: true,
                fullscreenResponsive: true,
            });
        }} leftIcons={[
            {
                toolTip: `${I18n.m.getMessage('prev')} (${I18n.m.getMessage('hotkeyLeft')})`,
                icon: 'chevron-left',
                onPress: prev,
            },
            {
                toolTip: `${I18n.m.getMessage('next')} (${I18n.m.getMessage('hotkeyRight')})`,
                icon: 'chevron-right',
                onPress: next,
            },
        ]}>
        {member['user'] && typeof member['user'].getFullName === 'function'
            ? member['user'].getFullName()
            : `${member.getName()}`}
        
      </DialogTitle>
      <View style={{ flex: 1 }}>
        <View style={{ height: 48 }}>
          <TabBar textColor={ThemeManager.style.brandPrimary} unfocusedTextColor={ThemeManager.style.disabledColor} contentHeight={0} width={contentWidth} tabId="memberTabs" onChangedSelected={(i) => {
            Routing.instance.openDialog('member', { memberId: member.member.id, selectedTab: i }, true)(null);
        }} selected={selectedTab} underlineColor={ThemeManager.style.brandPrimary} tabs={tabs}/>
        </View>
        <View style={{ flex: 1 }}>{renderContent()}</View>
      </View>
    </>);
}
