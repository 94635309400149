import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Collapsible } from 'materialTheme/src/theme/components/Collapsible';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { FlatList, Image, StyleSheet, UIManager, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ChangeUserSettings } from 'upmesh-auth-core/src/client/commands/user/ChangeUserSettings';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserSettingsEntity } from 'upmesh-auth-core/src/client/query/entities/UserSettingsEntity';
import { MarkLogBookAsNotRead } from 'upmesh-core/src/client/commands/MarkLogBookAsNotRead';
import { MarkLogBookAsRead } from 'upmesh-core/src/client/commands/MarkLogBookAsRead';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { LogBookItem } from '../logbook/LogBookItem';
const styles = StyleSheet.create({
    container: { width: '100%', height: '100%' },
    rowContainer: { flexDirection: 'row', width: '100%' },
    rowContainerWithBorder: {
        flexDirection: 'row',
        width: '100%',
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: ThemeManager.style.borderColor,
        borderBottomWidth: ThemeManager.style.borderWidth,
    },
    rowReadContainer: {
        width: 48,
        overflow: 'visible',
        justifyContent: 'center',
        borderWidth: 0,
    },
    rowItemContainer: { flex: 1 },
});
const noNotificationImg = require('../../assets/img/no_notifications.png');
export const saveLastOpenNotifications = async () => {
    try {
        if (CurrentUser.settings != null) {
            const appSettings = CurrentUser.settings.appSettings != null ? CurrentUser.settings.appSettings : {};
            const newSettings = new UserSettingsEntity({
                ...CurrentUser.settings,
                appSettings: { ...appSettings, lastOpenNotifications: new Date().getTime() },
            });
            const c = new ChangeUserSettings(newSettings);
            await c.execute(AuthClient.instance.commandStore);
        }
    }
    catch (e) {
        console.debug('cant save settings');
        DefaultErrorHandler.showDefaultErrorAlert(e);
    }
};
export function NotificationBellDialog(_props) {
    const [notifications, setNotifications] = useState([]);
    const [onlyUnRead, setOnlyUnRead] = useState(true);
    const t = new Date();
    const limit = new Date(t.getFullYear(), t.getMonth(), t.getDate() - 30, 0, 0, 0, 0);
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'NotificationBellDialog',
            readModelName: 'LogBook',
            callback: (e) => {
                let forMe = false;
                e.entities.forEach((l) => {
                    const lb = l.entity;
                    if (lb && lb.userIds.findIndex((a) => a.userId === CurrentUser.userId) >= 0)
                        forMe = true;
                });
                if (forMe) {
                    UpmeshClient.instance.modals.logBook
                        .get({
                        select: [
                            'id',
                            'projectId',
                            'projectTitle',
                            'readByUserIds',
                            'creator',
                            'creatorName',
                            'entityId',
                            'entityTitle',
                            'entityType',
                            'eventName',
                            'createdAt',
                            'data',
                        ],
                        filter: `userIds/userId eq '${CurrentUser.userId}' and creator ne '${CurrentUser.userId}' and createdBy ne '${CurrentUser.userId}' and createdAt gt ${limit.toISOString()}`,
                        orderby: 'createdAt DESC',
                        top: 99,
                    })
                        .then((e) => {
                        setNotifications(e);
                    })
                        .catch((err) => console.error(err));
                }
            },
        });
        UpmeshClient.instance.modals.logBook
            .get({
            select: [
                'id',
                'projectId',
                'projectTitle',
                'readByUserIds',
                'creator',
                'createdAt',
                'creatorName',
                'entityId',
                'entityTitle',
                'entityType',
                'eventName',
                'data',
            ],
            filter: `userIds/userId eq '${CurrentUser.userId}' and creator ne '${CurrentUser.userId}' and createdBy ne '${CurrentUser.userId}' and createdAt gt ${limit.toISOString()}`,
            orderby: 'createdAt DESC',
            top: 99,
        })
            .then((e) => {
            setNotifications(e);
            return saveLastOpenNotifications();
        })
            .catch((err) => console.error(err));
        return () => {
            UpmeshClient.eventDispatcher.detach('LogBook', 'NotificationBellDialog');
        };
    }, []);
    const getItemKey = (item) => {
        return `logbookEntry_bell_${item.notiffications[0].id}`;
    };
    const markGroupAsRead = (notifications) => (_e) => {
        MenuRaw.instance?.close();
        const unReaded = notifications.filter((item) => {
            return !(item.readByUserIds != null && item.readByUserIds.findIndex((a) => a.userId === CurrentUser.userId) > -1);
        });
        const promises = [];
        unReaded.forEach((item) => {
            promises.push(new Promise((r, re) => {
                const markLogBookAsRead = new MarkLogBookAsRead({}, item.id);
                markLogBookAsRead
                    .execute(AuthClient.instance.commandStore)
                    .then(() => r())
                    .catch((err) => re(err));
            }));
        });
        Promise.all(promises).catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    const markGroupAsUnRead = (notifications) => (_e) => {
        MenuRaw.instance?.close();
        const readed = notifications.filter((item) => {
            return item.readByUserIds != null && item.readByUserIds.findIndex((a) => a.userId === CurrentUser.userId) > -1;
        });
        const promises = [];
        readed.forEach((item) => {
            promises.push(new Promise((r, re) => {
                const markLogBookAsNotRead = new MarkLogBookAsNotRead({}, item.id);
                markLogBookAsNotRead
                    .execute(AuthClient.instance.commandStore)
                    .then(() => r())
                    .catch((err) => re(err));
            }));
        });
        Promise.all(promises).catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    const renderItem = ({ item, hideBorder, deep, group, }) => {
        const hasRead = item.readByUserIds != null && item.readByUserIds.findIndex((a) => a.userId === CurrentUser.userId) > -1;
        const markAsRead = () => {
            MenuRaw.instance?.close();
            if (!hasRead) {
                const markLogBookAsRead = new MarkLogBookAsRead({}, item.id);
                markLogBookAsRead.execute(AuthClient.instance.commandStore).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        const markAsNotRead = () => {
            MenuRaw.instance?.close();
            if (hasRead) {
                const markLogBookAsNotRead = new MarkLogBookAsNotRead({}, item.id);
                markLogBookAsNotRead.execute(AuthClient.instance.commandStore).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        const markGroupOrSingle = (e) => {
            if (group == null)
                return;
            UIManager.measure(e.nativeEvent.target, (_originX, _originY, _width, height, pageX, pageY) => {
                const client = { height, width: 300, x: pageX, y: pageY };
                MenuRaw.instance?.open({
                    client,
                    filterFormInput: false,
                    items: [
                        {
                            title: hasRead
                                ? `${I18n.m.getMessage('notificationsEntry')} ${I18n.m.getMessage('notificationsMarkAsNotRead')}`
                                : `${I18n.m.getMessage('notificationsEntry')} ${I18n.m.getMessage('notificationsMarkAsRead')}`,
                            onPress: hasRead ? markAsNotRead : markAsRead,
                        },
                        {
                            title: hasRead
                                ? `${I18n.m.getMessage('notificationsGroup')} ${I18n.m.getMessage('notificationsMarkAsNotRead')}`
                                : `${I18n.m.getMessage('notificationsGroup')} ${I18n.m.getMessage('notificationsMarkAsRead')}`,
                            onPress: hasRead ? markGroupAsUnRead(group) : markGroupAsRead(group),
                        },
                    ],
                });
            });
        };
        return (<View style={hideBorder ? styles.rowContainer : styles.rowContainerWithBorder}>
        <View style={styles.rowItemContainer}>
          <LogBookItem hideIcon closeDialogBefore key={`logbookEntry_bell2_${item.id}`} logbookItem={item} deep={deep} withUserImage flat/>
        </View>
        <View style={styles.rowReadContainer}>
          {hasRead ? (<Icon key="markAsNotReadIcon" icon="circle-outline" toolTip={I18n.m.getMessage('notificationsMarkAsNotRead')} onPress={group && group.length > 1 ? markGroupOrSingle : markAsNotRead} color="rgb(200, 200, 200)"/>) : (<Icon key="markAsReadIcon" icon="circle-medium" toolTip={I18n.m.getMessage('notificationsMarkAsRead')} onPress={group && group.length > 1 ? markGroupOrSingle : markAsRead} color="rgb(97, 97, 97)"/>)}
        </View>
      </View>);
    };
    const renderRow = ({ item }) => {
        if (item.notiffications.length === 0)
            return null;
        if (item.notiffications.length === 1)
            return renderItem({ item: item.notiffications[0], deep: 'multiproject' });
        const notiffication = item.notiffications.shift();
        if (!notiffication)
            return null;
        return (<View style={{
                width: '100%',
                borderStyle: 'solid',
                borderWidth: 0,
                borderBottomWidth: 1,
                borderColor: ThemeManager.style.borderColor,
            }}>
        {renderItem({
                item: notiffication,
                hideBorder: true,
                deep: 'multiproject',
                group: [notiffication, ...item.notiffications],
            })}
        <View style={{ width: '100%', paddingLeft: 8 }}>
          <Collapsible closed textOpened={I18n.m.getMessage('showLess')} textClosed={I18n.m.getMessage('notificationsShowMore', { count: item.notiffications.length })}>
            <View>{item.notiffications.map((a) => renderItem({ item: a, deep: 'ticket', hideBorder: true }))}</View>
          </Collapsible>
        </View>
      </View>);
    };
    const markAllAsRead = (_e) => {
        const unReaded = notifications.filter((item) => {
            return !(item.readByUserIds != null && item.readByUserIds.findIndex((a) => a.userId === CurrentUser.userId) > -1);
        });
        const promises = [];
        unReaded.forEach((item) => {
            promises.push(new Promise((r, re) => {
                const markLogBookAsRead = new MarkLogBookAsRead({}, item.id);
                markLogBookAsRead
                    .execute(AuthClient.instance.commandStore)
                    .then(() => r())
                    .catch((err) => re(err));
            }));
        });
        Promise.all(promises).catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    const nots2 = onlyUnRead
        ? notifications.filter((item) => {
            return !(item.readByUserIds != null && item.readByUserIds.findIndex((a) => a.userId === CurrentUser.userId) > -1);
        })
        : notifications;
    const tabelData = [];
    const nots3 = [];
    nots2.forEach((item) => {
        const n = item;
        if (n.data['ticketId'])
            n.groupByEntityId = n.data['ticketId'];
        else if (n.data['folder'])
            n.groupByEntityId = n.data['folder'];
        else
            n.groupByEntityId = n.entityId;
        nots3.push(n);
    });
    const nots = [];
    nots3.forEach((item) => {
        const has = nots.findIndex((a) => a.groupByEntityId === item.groupByEntityId);
        if (has === -1) {
            const i = item;
            i.subs = [];
            nots.push(i);
        }
        else {
            nots[has].subs.push(item);
        }
    });
    for (let i = 0; i < nots.length; i += 1) {
        const n = nots[i];
        tabelData.push({ entity: { title: n.entityTitle }, notiffications: [n, ...n.subs] });
    }
    return (<View style={styles.container}>
      <DialogTitle key="title">{`${I18n.m.getMessage('accountNotifications')} (${tabelData.length})`}</DialogTitle>
      <View style={{
            flexDirection: 'row',
            height: 48,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: 16,
        }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Switch value={onlyUnRead} labelText="nur ungelesene anzeigen" onChange={(e) => setOnlyUnRead(e)}/>
          <MaterialText type={MaterialTextTypes.Caption} centeredBox>
            {I18n.m.getMessage('notificationsShowOnlyUnread')}
          </MaterialText>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Chip title={I18n.m.getMessage('notificationsMarkAllAsRead')} onPressChip={markAllAsRead}/>
        </View>
      </View>
      {tabelData != null && tabelData.length > 0 ? (<FlatList key="logbook_all" keyExtractor={getItemKey} style={{
                height: (_props.dialogState?.height ? _props.dialogState.height : 0.9 * ResizeEvent.current.contentHeight) - 112,
                width: '100%',
            }} data={tabelData} renderItem={renderRow} onEndReachedThreshold={0.1}/>) : (<View style={{
                height: (_props.dialogState?.height ? _props.dialogState.height : 0.9 * ResizeEvent.current.contentHeight) - 112,
                width: '100%',
            }}>
          <Image style={{ width: '100%', height: 200, resizeMode: 'contain', marginTop: 50 }} resizeMode="contain" source={noNotificationImg}/>
          <View style={{ width: '100%', marginTop: 16, alignItems: 'center' }}>
            <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2} strong>
              {I18n.m.getMessage('notificationsEverythingReadHeader')}
            </MaterialText>
            <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2}>
              {I18n.m.getMessage('notificationsEverythingReadSubline')}
            </MaterialText>
          </View>
        </View>)}
    </View>);
}
export const openNotificationBellDialog = (_props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        content: <NotificationBellDialog />,
        fullscreenResponsive: true,
        contentPadding: false,
        openPosition,
        scrollable: false,
        openImmediately: false,
        closeOnTouchOutside: true,
        showCloseIcon: true,
    });
};
