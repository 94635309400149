import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import PdfViewerComponent from './PdfViewerComponent.web';
export class PDFviewer extends PureComponent {
    constructor(props) {
        super(props);
        this.closeDialog = (_e) => {
            const { hasChanged, lastSave } = this.state;
            const asyncNow = async () => {
                const { saveFunction, originalFilename } = this.props;
                try {
                    if (hasChanged !== 0 && hasChanged > lastSave && saveFunction != null && this.pdfview != null) {
                        const { documentViewer, annotationManager } = this.pdfview;
                        const doc = documentViewer.getDocument();
                        const xfdfString = await annotationManager.exportAnnotations();
                        const data = await doc.getFileData({
                            xfdfString,
                            flatten: false,
                        });
                        const arr = new Uint8Array(data);
                        const blob = new Blob([arr], { type: 'application/pdf' });
                        if (originalFilename != null) {
                            blob['name'] = originalFilename;
                        }
                        const file = new File([blob], originalFilename != null ? originalFilename : 'export.pdf', {
                            type: 'application/pdf',
                            lastModified: new Date().getTime(),
                        });
                        Dialog.instance?.close(() => {
                            saveFunction(file).catch((err) => console.debug('cant save pdf', err));
                        });
                    }
                    else {
                        Dialog.instance?.close(() => {
                            saveFunction().catch((err) => console.debug('cant save pdf', err));
                        });
                    }
                }
                catch (err) {
                    console.debug('cant save pdf', err);
                    Dialog.instance?.close();
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.saveFileDirectly = (_e) => {
            const asyncNow = async () => {
                const { saveFunctionDirectly, originalFilename } = this.props;
                try {
                    if (saveFunctionDirectly != null && this.pdfview != null) {
                        const { documentViewer, annotationManager } = this.pdfview;
                        const doc = documentViewer.getDocument();
                        const xfdfString = await annotationManager.exportAnnotations();
                        const data = await doc.getFileData({
                            xfdfString,
                            flatten: false,
                        });
                        const arr = new Uint8Array(data);
                        const blob = new Blob([arr], { type: 'application/pdf' });
                        if (originalFilename != null) {
                            blob['name'] = originalFilename;
                        }
                        const file = new File([blob], originalFilename != null ? originalFilename : 'export.pdf', {
                            type: 'application/pdf',
                            lastModified: new Date().getTime(),
                        });
                        saveFunctionDirectly(file, true)
                            .then(() => {
                            this.setState((prev) => ({
                                lastSave: prev.hasChanged,
                            }));
                        })
                            .catch((err) => console.debug('cant save pdf', err));
                    }
                }
                catch (err) {
                    console.debug('cant save pdf', err);
                    Dialog.instance?.close();
                }
            };
            if (this.saveTimeout == null) {
                asyncNow().catch((err) => console.error(err));
                this.saveTimeout = setTimeout(() => { }, 5000);
            }
            else {
                clearTimeout(this.saveTimeout);
                this.saveTimeout = setTimeout(() => {
                    asyncNow().catch((err) => console.error(err));
                }, 5000);
            }
        };
        this.setRef = (r) => {
            this.pdfview = r;
        };
        this.onChange = () => {
            this.setState((prev) => ({
                hasChanged: prev.hasChanged + 1,
            }));
        };
        this.state = { hasChanged: 0, lastSave: -1 };
    }
    render() {
        const { link, id } = this.props;
        return (<View style={{ width: '100%', height: '100%', position: 'relative' }}>
        <PdfViewerComponent close={this.closeDialog} saveDirectly={this.saveFileDirectly} setRef={this.setRef} document={link} onChange={this.onChange} storedFileId={id}/>
        <View style={{ flexDirection: 'row', position: 'absolute', top: 1, left: 16 }}>
          <Icon icon="arrow-left" toolTip="" onPress={this.closeDialog}/>
          <Icon icon="content-save-all-outline" toolTip="" onPress={this.saveFileDirectly}/>
        </View>
      </View>);
    }
}
PDFviewer.open = (props) => (e) => {
    const openPosition = e != null
        ? { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY }
        : { x: ResizeEvent.current.windowWidth / 2, y: ResizeEvent.current.windowHeight / 2 };
    Dialog.instance?.open({
        content: <PDFviewer {...props}/>,
        fullscreen: true,
        contentPadding: false,
        scrollable: false,
        openPosition,
    });
};
