import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { ExcelFileDialog } from 'materialTheme/src/theme/components/file/ExcelFileDialog';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateTask } from 'upmesh-core/src/client/commands/companies/tasks/CreateTask';
import { DeleteTask } from 'upmesh-core/src/client/commands/companies/tasks/DeleteTask';
import { CompanySettingsEntity } from 'upmesh-core/src/client/query/entities/CompanySettingsEntity';
import { TaskEntity } from 'upmesh-core/src/client/query/entities/TaskEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddTaskToCompanyDialog } from './AddTaskToCompanyDialog';
import { ChangeTaskCompanyDialog } from './ChangeTaskCompanyDialog';
const noTaskImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class TaskEntityWithType extends TaskEntity {
}
export class CompanyTasksView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.updateData = () => {
            const { company } = this.props;
            const asyncNow = async () => {
                const tasks = await UpmeshClient.instance.modals.task.get({
                    filter: `deleted ne true and companyId eq '${company.id}'`,
                });
                const data = [];
                for (const t of tasks) {
                    const d = new TaskEntityWithType(t);
                    d.taskType = (await d.getTaskType()).title;
                    data.push(d);
                }
                this.setState({ data });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.removeTask = (task) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveTaskQuestion', { task: task.mark }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeTaskNow(task.id)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeTaskNow = (taskId) => (_e) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new DeleteTask({}, taskId, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((e) => {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.openExcelDialog = () => {
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
                showCloseButton: false,
                width: 1042,
                content: (<ExcelFileDialog saveFunction={this.saveFunction} generateExampleRecords={this.generateExamples} columns={[
                        { title: I18n.m.getMessage('taskMark'), keyInData: 'Kennzeichen', style: { width: 200 } },
                        { title: I18n.m.getMessage('taskDescription'), keyInData: 'Bezeichnung', style: { width: 200 } },
                        { title: I18n.m.getMessage('taskType'), keyInData: 'Art', style: { width: 100 } },
                        { title: I18n.m.getMessage('taskWageType'), keyInData: 'Lohnart', style: { width: 200 } },
                        { title: I18n.m.getMessage('taskFollowUpWageType1'), keyInData: 'Folgelohnart1', style: { width: 200 } },
                        { title: I18n.m.getMessage('taskFollowUpWageType2'), keyInData: 'Folgelohnart2', style: { width: 200 } },
                        { title: I18n.m.getMessage('taskStatus'), keyInData: 'Status', style: { width: 100 } },
                    ]}/>),
            });
        };
        this.generateExamples = async () => {
            const { data } = this.state;
            if (data != null) {
                const mappedData = [];
                for (const element of data) {
                    mappedData.push({
                        Kennzeichen: element.mark,
                        Bezeichnung: element.description,
                        Art: (await element.getTaskType()).title,
                        Lohnart: element.wageType != null ? element.wageType : '',
                        Folgelohnart1: element.followUpWageType1 != null ? element.followUpWageType1 : '',
                        Folgelohnart2: element.followUpWageType2 != null ? element.followUpWageType2 : '',
                        Status: element.active ? I18n.m.getMessage('taskActive') : I18n.m.getMessage('taskInactive'),
                    });
                }
                return mappedData;
            }
            return [];
        };
        this.saveFunction = async (records) => {
            const { company } = this.props;
            const tasksPromises = [];
            for (const element of records) {
                const currentTaskData = {
                    mark: element.Kennzeichen,
                    followUpWageType1: element.Folgelohnart1,
                    followUpWageType2: element.Folgelohnart2,
                    wageType: element.Lohnart,
                    companyId: company.id,
                    description: element.Bezeichnung,
                    active: element.Status === I18n.m.getMessage('taskActive'),
                    taskTypeId: await this.getTaskTypeIdByType(element.Art),
                };
                tasksPromises.push(new CreateTask(currentTaskData).execute(ClientStore.commandStore));
            }
            await Promise.all(tasksPromises);
        };
        this.openFab = () => {
            if (Fab.instance == null) {
                window.setTimeout(this.openFab, 100);
                return;
            }
            const permitted = this.myRole() === 'admin';
            if (Platform.OS === 'web') {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: !permitted
                        ? () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') })
                        : undefined,
                    fabActions: permitted
                        ? [
                            {
                                text: I18n.m.getMessage('companyAddTaskDialogTitle'),
                                icon: 'plus',
                                onPress: this.openAddDialog,
                            },
                            {
                                text: I18n.m.getMessage('companyExcelDialogTitle'),
                                icon: 'microsoft-excel',
                                onPress: this.openExcelDialog,
                            },
                        ]
                        : undefined,
                });
            }
            else {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: permitted
                        ? this.openAddDialog
                        : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                });
            }
        };
        this.openAddDialog = (_e) => {
            const { company } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddTaskToCompanyDialog company={company}/>,
                contentPadding: false,
                fullscreenResponsive: true,
                showCloseIcon: true,
                scrollable: false,
            });
        };
        this.openEditDialog = (task) => (_e) => {
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <ChangeTaskCompanyDialog task={task} company={company}/>,
                    contentPadding: false,
                    fullscreenResponsive: true,
                    showCloseIcon: true,
                    scrollable: false,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.renderBooleanCells = (item, column, index, sLayout) => {
            const cellAlignment = sLayout ? 'flex-end' : 'flex-start';
            const { keyInData, style, cellStyle } = column;
            let text = '';
            if (keyInData === 'productive') {
                text = item[keyInData] === true ? I18n.m.getMessage('yes') : I18n.m.getMessage('no');
            }
            else {
                text = item[keyInData] === true ? I18n.m.getMessage('taskActive') : I18n.m.getMessage('taskInactive');
            }
            return (<View key={`cell-${item.id}-${index}`} style={[
                    {
                        justifyContent: 'center',
                        paddingHorizontal: 16,
                        height: sLayout ? 'auto' : 56,
                        alignSelf: cellAlignment,
                    },
                    style,
                ]}>
        {keyInData ? (<View style={[
                        {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: cellAlignment,
                        },
                        cellStyle,
                    ]}>
            <MaterialText textAlign={cellAlignment === 'flex-end' ? 'right' : undefined} ellipsizeMode="tail" numberOfLines={sLayout ? undefined : 1} centeredBox={false}>
              {text}
            </MaterialText>
          </View>) : null}
      </View>);
        };
        this.state = {
            myRole: this.myRole(),
        };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyTasksView',
            readModelName: 'Task',
            callback: this.updateData,
        });
        this.updateData();
        this.openFab();
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('Task', 'CompanyTasksView');
        if (Fab.instance != null)
            Fab.instance.close();
    }
    async getTaskTypeIdByType(type) {
        const types = await CompanySettingsEntity.getTaskTypes();
        const e = types.find((t) => t.title === type);
        if (e != null)
            return e.id;
        return '';
    }
    render() {
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const { size } = this.props;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(60);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('tasks')}
          </MaterialText>
        </View>
        <Card style={{ width: '100%', height: tableMaxHeight }}>
          <Table tableName="CompanyTasksView" actionItemsLength={2} actions={myRole === 'admin'
                ? (_item) => [
                    { icon: 'pencil-outline', onAction: this.openEditDialog, toolTip: I18n.m.getMessage('edit') },
                    { icon: 'delete-outline', onAction: this.removeTask, toolTip: I18n.m.getMessage('delete') },
                ]
                : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noTaskImg} emptyTableHint={I18n.m.getMessage('companyTaskNoDataHint')} emptyTableText={I18n.m.getMessage('companyTaskNoDataText')} data={data} sortBy="mark" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('taskMark'),
                    keyInData: 'mark',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('taskDescription'),
                    keyInData: 'description',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('taskType'),
                    keyInData: 'taskType',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('taskProductive'),
                    keyInData: 'productive',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                    cellRenderer: this.renderBooleanCells,
                },
                {
                    title: I18n.m.getMessage('taskWageType'),
                    keyInData: 'wageType',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('taskFollowUpWageType1'),
                    keyInData: 'followUpWageType1',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('taskFollowUpWageType2'),
                    keyInData: 'followUpWageType2',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('taskStatus'),
                    keyInData: 'active',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                    cellRenderer: this.renderBooleanCells,
                },
            ]}/>
        </Card>
      </View>);
    }
}
