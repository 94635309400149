import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { ChangeCompanyUserForm } from '../../ChangeCompanyUserForm';
export function CompanyUserRights(props) {
    return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
      <ChangeCompanyUserForm key={`member_${props.user.member.id}`} companyMember={props.user.member} saved={() => {
            console.log('Saved');
        }}/>
    </View>);
}
