import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateInputFormFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeCompanyMemberWorkingTimeModels } from 'upmesh-core/src/client/commands/companies/ChangeCompanyMemberWorkingTimeModels';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../../i18n/I18n';
import { DefaultErrorHandler } from '../../../DefaultErrorHandler';
export function EditWorkingTimeModelsForMember(props) {
    const [workingTimeModels, setWorkingTimeModels] = useState();
    const [avalaibleWorkingTimeModels, setAvalaibleWorkingTimeModels] = useState();
    const orgWorkingTimeModels = useRef();
    const styles = useRef({
        container: {
            width: '100%',
        },
    });
    const load = async () => {
        const w = [];
        if (props.member.workingTimeModels && props.member.workingTimeModels.length > 0) {
            for (const wtm of props.member.workingTimeModels) {
                const wt = await UpmeshClient.instance.modals.workingTimeModel.getById(wtm.workingTimeModelId);
                w.push({ validFrom: new Date(wtm.validFromDate), wtm: wt });
            }
        }
        else if (props.member.workingTimeModelId != null) {
            const wt = await UpmeshClient.instance.modals.workingTimeModel.getById(props.member.workingTimeModelId);
            w.push({ validFrom: new Date(props.member.createdAt), wtm: wt });
        }
        w.sort((a, b) => {
            return b.validFrom.getTime() - a.validFrom.getTime();
        });
        const available = await UpmeshClient.instance.modals.workingTimeModel.get({ filter: `deleted ne true` });
        return { current: w, available };
    };
    useEffect(() => {
        let mounted = true;
        load()
            .then((e) => {
            if (mounted) {
                setWorkingTimeModels(e.current);
                orgWorkingTimeModels.current = [...e.current];
                setAvalaibleWorkingTimeModels(e.available);
            }
        })
            .catch(DefaultErrorHandler.showDefaultErrorAlert);
        return () => {
            mounted = false;
        };
    }, []);
    const addEntry = (_e) => {
        if (avalaibleWorkingTimeModels != null && avalaibleWorkingTimeModels.length > 0 && workingTimeModels != null) {
            const a = [...workingTimeModels];
            a.push({ validFrom: new Date(), wtm: avalaibleWorkingTimeModels[0] });
            setWorkingTimeModels(a);
        }
    };
    const renderContent = () => {
        if (workingTimeModels == null || avalaibleWorkingTimeModels == null)
            return <Spinner />;
        if (workingTimeModels.length === 0 || avalaibleWorkingTimeModels.length === 0)
            return (<View style={styles.current.container}>
          <MaterialText>---</MaterialText>
        </View>);
        const pickerList = avalaibleWorkingTimeModels.map((a) => `${a.mark}`);
        return (<View style={styles.current.container}>
        {workingTimeModels.map((w, i) => {
                const selectedIndex = avalaibleWorkingTimeModels.findIndex((a) => a.id === w.wtm.id);
                return (<View style={{ width: '100%' }}>
              <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <MaterialText strong type={MaterialTextTypes.H6}>
                  {I18n.m.getMessage('validFrom')} {I18n.m.dateCurrent.localeDateString(w.validFrom)}: {w.wtm.mark}
                </MaterialText>
                <Icon icon="delete-outline" toolTip={I18n.m.getMessage('delete')} disabled={workingTimeModels.length <= 1} onPress={() => {
                        const b = [...workingTimeModels];
                        b.splice(i, 1);
                        setWorkingTimeModels(b);
                    }}/>
              </View>
              <View style={{ width: '100%' }}>
                <DateInputFormFilled labelText={I18n.m.getMessage('validFrom')} initValue={w.validFrom} onChange={(a) => {
                        if (a != null) {
                            const b = [...workingTimeModels];
                            b[i] = { validFrom: a, wtm: b[i].wtm };
                            setWorkingTimeModels(b);
                        }
                    }}/>
                <FormInputPicker list={pickerList} selectedIndex={selectedIndex} onChange={(a) => {
                        const b = [...workingTimeModels];
                        const av = avalaibleWorkingTimeModels[a.index];
                        b[i] = { validFrom: b[i].validFrom, wtm: av };
                        setWorkingTimeModels(b);
                    }}/>
              </View>
            </View>);
            })}
        <View style={{ width: '100%', paddingTop: 16 }}>
          <ContainedButton onPress={addEntry} title={I18n.m.getMessage('add')} outlineColor={ThemeManager.style.brandPrimary} full borderStyle="solid" textColor={ThemeManager.style.brandPrimary} backgroundColor="#FFFFFF"/>
        </View>
      </View>);
    };
    const hasChanged = orgWorkingTimeModels != null && JSON.stringify(orgWorkingTimeModels.current) !== JSON.stringify(workingTimeModels);
    const save = async () => {
        if (workingTimeModels == null || workingTimeModels.length === 0)
            return;
        const wtm = [];
        workingTimeModels.forEach((workingTimeModel) => {
            wtm.push({ workingTimeModelId: workingTimeModel.wtm.id, validFromDate: workingTimeModel.validFrom });
        });
        wtm.sort((a, b) => {
            return b.validFromDate.getTime() - a.validFromDate.getTime();
        });
        const c = new ChangeCompanyMemberWorkingTimeModels({
            workingTimeModels: wtm,
        }, props.member.id);
        await c.execute(ClientStore.commandStore);
        Dialog.instance?.close();
    };
    return (<>
      <DialogTitle key="dTitle">
        {I18n.m.getMessage('editWorkingTimeModelsMemberDialogTitle', { memberName: props.memberName })}
      </DialogTitle>
      <DialogContent key="dContent">{renderContent()}</DialogContent>
      <DialogActions key="dActions">
        <ContainedButton textColor={ThemeManager.style.brandPrimary} backgroundColor="#FFFFFF" title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close}/>
        <ContainedButton disabled={!hasChanged || workingTimeModels == null || workingTimeModels.length === 0} title={I18n.m.getMessage('save')} onPress={() => {
            save().catch(DefaultErrorHandler.showDefaultErrorAlert);
        }}/>
      </DialogActions>
    </>);
}
export const openEditWorkingTimeModelsForMember = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        contentPadding: false,
        scrollable: false,
        content: <EditWorkingTimeModelsForMember memberName={props.memberName} member={props.member}/>,
    });
};
