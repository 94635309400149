import Color from 'color';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CombinedExtraPayTrackingDialogsControl } from '../control/CombinedExtraPayTrackingDialogsControl';
import { openTimeTrackingDayForUserDialog } from '../dialogs/openTimeTrackingDayForUserDialog';
export function TimeTrackingListItemForDay(props) {
    const [currentMs, setCurrentMs] = useState(0);
    const [isCollidating, setIsCollidating] = useState([]);
    const updateTime = () => {
        const { userEntries } = props;
        userEntries.memberEntries.forEach((t) => {
            if (t.type === 'TimeTrackingEntity') {
                const timeTracking = t.orgEntry;
                if (timeTracking.ends == null) {
                    const duration = TimeTrackingEntity.getRunningTime(timeTracking);
                    setCurrentMs(duration == null ? 0 : duration);
                }
            }
        });
    };
    const init = async () => {
        const { userEntries } = props;
        const isCollidating = [];
        for (const t of userEntries.memberEntries) {
            if (t.type === 'TimeTrackingEntity') {
                const a = await TimeTrackingEntity.isColliding(t.orgEntry);
                if (a.length > 0) {
                    isCollidating.push({ id: t.id, collidingEntries: a });
                }
            }
        }
        return isCollidating;
        setIsCollidating(isCollidating);
    };
    useEffect(() => {
        let isMounted = true;
        init()
            .then((isCollidating) => {
            if (isMounted)
                setIsCollidating(isCollidating);
        })
            .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        return () => {
            isMounted = false;
        };
    }, []);
    useEffect(() => {
        let interval;
        props.userEntries.memberEntries.forEach((t) => {
            if (t.type === 'TimeTrackingEntity' && t.ends == null) {
                updateTime();
                if (interval != null)
                    clearInterval(interval);
                interval = setInterval(updateTime, 1000);
            }
        });
        return () => {
            if (interval)
                clearInterval(interval);
        };
    }, []);
    const openAddTimeTrackDialog = (e) => {
        CombinedExtraPayTrackingDialogsControl.openAddTimeTrackingDialog({
            title: I18n.m.getMessage('timeTrackingTaskOrProject'),
            hideTimes: { start: true, end: true, date: true, pause: true },
            selectedDate: new Date(),
            memberIds: [props.userEntries.memberId],
        })(e);
    };
    const renderQuickChips = (t) => {
        if (t.type !== 'TimeTrackingEntity')
            return null;
        const timeTracking = t.orgEntry;
        if (timeTracking.status !== 'Running')
            return null;
        return (<View style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
                paddingTop: 4,
                paddingBottom: 8,
                backgroundColor: isCollidating.length > 0 ? Color(ThemeManager.style.brandDanger).alpha(0.2).toString() : '#FFFFFF',
            }}>
        <Icon key="switchIcon" toolTip={I18n.m.getMessage('timeTrackingTaskOrProject')} icon="swap-horizontal" backgroundColor={ThemeManager.style.brandWarning} color="#FFFFFF" onPress={openAddTimeTrackDialog}/>
        <View key="swicthSpacer" style={{ width: 32 }}/>
        <Icon toolTip={I18n.m.getMessage('timeTrackingStopTimer')} onPress={CombinedExtraPayTrackingDialogsControl.stopTiming(t)} icon="stop" backgroundColor={ThemeManager.style.brandDanger} color="#FFFFFF"/>
      </View>);
    };
    const renderRecordingButtons = () => {
        if (currentMs === 0)
            return null;
        const { userEntries } = props;
        const activeTrack = userEntries.memberEntries.find((t) => t.type === 'TimeTrackingEntity' && t.sumInMS === 0);
        if (activeTrack == null)
            return null;
        return renderQuickChips(activeTrack);
    };
    const { userEntries } = props;
    const { user } = userEntries;
    const { member } = userEntries;
    const { holidayTimeInMS, targetTimeInMS } = userEntries;
    const workingTargetTime = Math.max(targetTimeInMS - holidayTimeInMS, 0);
    const targetTime = targetTimeInMS != null ? userEntries.totalTime + currentMs - workingTargetTime : null;
    const badgeColor = isCollidating.length > 0 ? ThemeManager.style.brandDanger : ThemeManager.style.brandPrimary;
    return (<Card style={{ width: '100%' }} outerPadding={8}>
      <View style={{ width: '100%' }}>
        <Ripple style={{ width: '100%', borderRadius: 6 }} onPress={openTimeTrackingDayForUserDialog({
            memberId: props.userEntries.memberId,
            day: props.userEntries.date,
            userId: props.userEntries.userId,
        })} hoverColor={isCollidating.length > 0
            ? Color(ThemeManager.style.brandDanger).alpha(0.25).toString()
            : ThemeManager.style.getDefaultHoverColor(ThemeManager.style.brandPrimary)} backgroundColor={isCollidating.length > 0 ? Color(ThemeManager.style.brandDanger).alpha(0.2).toString() : '#FFFFFF'}>
          <View style={{
            width: '100%',
            padding: 8,
        }}>
            <View style={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            overflow: 'visible',
        }}>
              {user != null ? (<UserImage size={36} user={user} lightBox={false} badgeColor={badgeColor} badge={userEntries.memberEntries.length > 1 ? userEntries.memberEntries.length.toString(10) : undefined}/>) : member != null ? (<UserImage size={36} badgeColor={badgeColor} badge={userEntries.memberEntries.length > 1 ? userEntries.memberEntries.length.toString(10) : undefined} lightBox={false} user={new UserEntity({ firstname: member.firstName, lastname: member.lastName })}/>) : (<View style={{
                width: 40,
                height: 40,
                ...ThemeManager.style.borderStyle,
                borderRadius: 20,
                backgroundColor: '#F2F2F2',
                borderColor: '#F2F2F2',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                  <MaterialText centeredText centeredBox type={MaterialTextTypes.H5}>
                    {userEntries.memberEntries.length}
                  </MaterialText>
                </View>)}
              <View style={{ flex: 1, paddingLeft: 8 }}>
                <MaterialText numberOfLines={1} fixedWidth="100%">
                  {userEntries.memberName}
                </MaterialText>
                {userEntries.memberEntries.length > 0 ? (<MaterialText numberOfLines={1} fixedWidth="100%" type={MaterialTextTypes.Body2}>
                    {userEntries.tasks.length > 0 ? userEntries.tasks.join(', ') : '-'}
                    {userEntries.projectTitles.length > 0 ? ` (${userEntries.projectTitles.join(', ')})` : ''}
                  </MaterialText>) : (<MaterialText color={ThemeManager.style.brandDanger} numberOfLines={1} fixedWidth="100%" type={MaterialTextTypes.Body2}>
                    {I18n.m.getMessage('noTimeTrackingRecordsForDay')}
                  </MaterialText>)}
              </View>
              <View style={{ width: 85, paddingLeft: 8, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <MaterialText centeredBox fixedWidth="100%" numberOfLines={1} strong textAlign="right">
                  {userEntries.totalTime + currentMs > 0
            ? TimeTrackingEntity.msToTime(userEntries.totalTime + currentMs, currentMs > 0, true)
            : ' '}
                </MaterialText>

                <MaterialText fixedWidth="100%" numberOfLines={1} type={MaterialTextTypes.Body2} textAlign="right" color={targetTime != null && targetTime < 0
            ? ThemeManager.style.brandDanger
            : ThemeManager.style.brandSuccess}>
                  {targetTime != null && targetTime !== 0
            ? `${targetTime >= 0 ? '+' : '-'} ${TimeTrackingEntity.msToTime(Math.abs(targetTime), false, true)}`
            : ' '}
                </MaterialText>
              </View>
            </View>
          </View>
        </Ripple>
        {renderRecordingButtons()}
      </View>
    </Card>);
}
