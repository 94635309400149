var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Collapsible = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _ContainedButton = require("./button/ContainedButton");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Collapsible = exports.Collapsible = function (_PureComponent) {
  function Collapsible(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Collapsible);
    _this = _callSuper(this, Collapsible, [props]);
    _this.onContentLayout = function (e) {
      if (e && e.nativeEvent && e.nativeEvent.layout && e.nativeEvent.layout.height > 0) {
        var contentHeight = _this.state.contentHeight;
        if (contentHeight !== e.nativeEvent.layout.height) {
          _this.setState({
            contentHeight: e.nativeEvent.layout.height
          }, function () {
            _this.animations = _this.createAnimations();
            _this.forceUpdate();
          });
        }
      }
    };
    _this.toggle = function () {
      var closed = _this.state.closed;
      var closedNew = !closed;
      _this.setState({
        closed: closedNew
      }, function () {
        _this.toValue(closedNew ? 0 : 1);
      });
    };
    _this.animate = props.closed === true ? new _reactNativeWeb.Animated.Value(0) : new _reactNativeWeb.Animated.Value(1);
    _this.animationDuration = 195;
    _this.state = {
      closed: props.closed === true,
      contentHeight: 0
    };
    _this.animations = _this.createAnimations();
    return _this;
  }
  (0, _inherits2.default)(Collapsible, _PureComponent);
  return (0, _createClass2.default)(Collapsible, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        textClosed = _this$props.textClosed,
        textOpened = _this$props.textOpened,
        contentUnderButton = _this$props.contentUnderButton,
        disabled = _this$props.disabled;
      var closed = this.state.closed;
      if (disabled != null && this.props.children != null) {
        return this.props.children;
      }
      var content = (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
        style: Object.assign({}, this.animations),
        children: this.props.isCode && (_reactNativeWeb.Platform.OS === 'ios' || _reactNativeWeb.Platform.OS === 'android') ? (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
          nestedScrollEnabled: true,
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
            nestedScrollEnabled: true,
            horizontal: true,
            children: this.props.children
          })
        }) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          onLayout: this.onContentLayout,
          children: this.props.children
        })
      }, "content");
      var button = (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
        title: closed ? textClosed : textOpened,
        onPress: this.toggle,
        backgroundColor: "transparent",
        textColor: _ThemeManager.ThemeManager.style.brandPrimary
      }, "button");
      var view = [];
      if (contentUnderButton != null) {
        view.push(button, content);
      } else {
        view.push(content, button);
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        children: view
      });
    }
  }, {
    key: "createAnimations",
    value: function createAnimations() {
      var contentHeight = this.state.contentHeight;
      var toHeight = contentHeight > 0 ? contentHeight : 0.003;
      if (this.props.isCode && (_reactNativeWeb.Platform.OS === 'ios' || _reactNativeWeb.Platform.OS === 'android')) {
        toHeight = 200;
      }
      return {
        height: this.animate.interpolate({
          inputRange: [0, 1],
          outputRange: [0, toHeight]
        })
      };
    }
  }, {
    key: "toValue",
    value: function toValue(_toValue, onFinished) {
      _reactNativeWeb.Animated.timing(this.animate, {
        toValue: _toValue,
        duration: this.animationDuration,
        useNativeDriver: false
      }).start(function () {
        if (onFinished != null) {
          requestAnimationFrame(function () {
            onFinished();
          });
        }
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        closed: true,
        textClosed: 'Mehr anzeigen',
        textOpened: 'Weniger anzeigen',
        textPosition: 'left',
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {})
      };
    }
  }]);
}(_react.PureComponent);