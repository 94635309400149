import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CombinedExtraPayAndTimeTrackingEntity } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { ApprovalStatusChip } from '../../companies/approvalprocess/ApprovalStatusChip';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { ReadmodelHistory } from '../../root/ReadmodelHistory';
import { CombinedExtraPayTrackingDialogsControl } from '../control/CombinedExtraPayTrackingDialogsControl';
import { TimeTrackingDialogHeader } from './TimeTrackingDialogHeader';
export function TimeTrackingDialog(props) {
    const [absence, setAbsence] = useState();
    const [extraPay, setExtraPay] = useState();
    const [entry, setEntry] = useState(props.entry);
    const loadData = async () => {
        if (entry.type === 'TimeTrackingEntity' && entry.orgEntry['absenceId']) {
            const t = await UpmeshClient.instance.modals.absence.getById(entry.orgEntry['absenceId']);
            setAbsence(t);
        }
        if (entry.type === 'ExtraPayTrackingEntity') {
            const ent = entry.orgEntry;
            const e = await UpmeshClient.instance.modals.extraPay.getById(ent.extraPayId);
            setExtraPay(e);
        }
    };
    const updateEntry = async (id) => {
        const readModelName = entry.type === 'TimeTrackingEntity'
            ? entry.orgEntry['absenceId']
                ? 'absence'
                : 'timeTracking'
            : 'extraPayTracking';
        const g = await UpmeshClient.instance.modals[readModelName].getById(id);
        if (readModelName === 'absence') {
            const a = g;
            setAbsence(a);
        }
        else {
            const a = g;
            const c = await CombinedExtraPayAndTimeTrackingEntity.convert(a);
            setEntry(c);
        }
    };
    useEffect(() => {
        loadData().catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    }, [entry]);
    useEffect(() => {
        const readModelName = entry.type === 'TimeTrackingEntity'
            ? entry.orgEntry['absenceId']
                ? 'Absence'
                : 'TimeTracking'
            : 'ExtraPayTracking';
        const attachKey = `TimeTrackingDialog_${entry.id}`;
        const entityId = entry.type === 'TimeTrackingEntity' && entry.orgEntry['absenceId']
            ? entry.orgEntry['absenceId']
            : entry.orgEntry.id;
        UpmeshClient.eventDispatcher.attach({
            attachKey,
            readModelName,
            entityId,
            callback: (_e) => {
                updateEntry(entityId).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            },
        });
        return () => {
            UpmeshClient.eventDispatcher.detach(readModelName, attachKey);
        };
    }, []);
    const specialOnProperties = [
        {
            prop: 'currentApprovalProcessStep',
            value: async (e) => {
                if (e.entity && e.entity.approvalProcessId) {
                    try {
                        const process = await UpmeshClient.instance.modals.approvalProcess.getById(e.entity.approvalProcessId);
                        const step = e.entity.approvalProcessStep ? e.entity.approvalProcessStep + 1 : 1;
                        const steps = process.approvalSteps.length;
                        return { to: `${step}/${steps}` };
                    }
                    catch (e) {
                        console.warn('cant get proccess steps on TimeTrackingDialog');
                    }
                }
                return { to: '1/1' };
            },
        },
    ];
    const renderItem = (label, value, icon, strong, center = true) => {
        return (<View style={{ width: '100%', paddingVertical: 8 }}>
        <View style={{
                width: '100%',
                padding: 4,
                backgroundColor: 'rgb(246,246,248)',
                borderRadius: ThemeManager.style.borderRadius,
                justifyContent: center ? 'center' : 'flex-start',
                alignItems: center ? 'center' : 'flex-start',
            }}>
          {icon != null && icon.length > 0 && <Icon icon={icon} toolTip={label}/>}
          <MaterialText centeredBox={center} type={MaterialTextTypes.Caption}>
            {label}
          </MaterialText>
          <MaterialText centeredBox={center} strong={strong}>
            {value}
          </MaterialText>
        </View>
        
      </View>);
    };
    const renderHeader = (forDay, status, extraText) => {
        return <TimeTrackingDialogHeader entry={entry} forDay={forDay} extraText={extraText} status={status}/>;
    };
    const renderTimeTrackingEntry = () => {
        return (<View style={{ width: '100%' }}>
        {renderHeader(entry.starts
                ? `${I18n.m.dateCurrent.localeDateFormat(new Date(entry.starts), 'dddd')}, ${I18n.m.dateCurrent.localeDateString(new Date(entry.starts), true)}`
                : '?', <ApprovalStatusChip currentStep={entry.orgEntry} onType="timeTracking" entityId={entry.id}/>)}

        <View style={{
                flexDirection: 'row',
                width: '100%',
                backgroundColor: 'rgb(246,246,248)',
                borderRadius: ThemeManager.style.borderRadius,
                flexWrap: 'wrap',
                paddingHorizontal: '3%',
            }}>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('starts'), entry.starts != null ? I18n.m.dateCurrent.getLocalTimeString(new Date(entry.starts)) : '', 'arrow-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('ends'), entry.ends != null ? I18n.m.dateCurrent.getLocalTimeString(new Date(entry.ends)) : '', 'arrow-collapse-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('pause'), entry.pause != null
                ? `${entry.pause.hours.toString(10).padStart(2, '0')}:${entry.pause.minutes
                    .toString(10)
                    .padStart(2, '0')}`
                : '0:00', 'coffee-outline', true)}
            </View>
          </View>
        </View>

        {entry.note != null && entry.note.length > 0 && renderItem(I18n.m.getMessage('note'), entry.note)}
        <View style={{ width: '100%', paddingTop: 16 }}>
          <ReadmodelHistory windowWidth={props.dialogState?.windowWidth} readmodel="timeTracking" id={entry.id} ignoresProperties={[
                'projectTitle',
                'lastModifiedBy',
                'nextApprovalStepFor',
                'approvals',
                'approvalProcessId',
            ]} specialOnProperties={specialOnProperties}/>
        </View>
      </View>);
    };
    const renderExtraPayEntry = () => {
        if (extraPay == null)
            return <Spinner />;
        const ent = entry.orgEntry;
        let content = <View />;
        let label = '';
        if (ent.extraField != null && extraPay.fieldType === 'number') {
            label = I18n.m.getMessage('extraPayFieldTypeNumberIn', {
                unit: extraPay.field?.unit,
            });
            content = (<View style={{
                    flexDirection: 'row',
                    width: '100%',
                    backgroundColor: 'rgb(246,246,248)',
                    flexWrap: 'wrap',
                    paddingHorizontal: '10%',
                    borderRadius: ThemeManager.style.borderRadius,
                }}>
          <View style={{ minWidth: 64, flex: 1 }}>
            {renderItem(I18n.m.getMessage('extraPayFieldTypeNumber'), ent.extraField?.toString(), 'google-circles-communities', true)}
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('extraPayFieldTypeNumberUnit'), extraPay.field && extraPay.field.unit ? extraPay.field.unit : '-', 'label-outline', true)}
            </View>
          </View>
        </View>);
        }
        if (ent.extraField != null && extraPay.fieldType === 'duration') {
            const f = ent.extraField;
            label = I18n.m.getMessage('absenceRange');
            const from = `${f.from.hours.toString(10).padStart(2, '0')}:${f.from.minutes.toString(10).padStart(2, '0')}`;
            const to = `${f.to.hours.toString(10).padStart(2, '0')}:${f.to.minutes.toString(10).padStart(2, '0')}`;
            const dateRange = new Date(new Date(1970, 0, 1, f.to.hours, f.to.minutes, 0, 0).getTime() -
                new Date(1970, 0, 1, f.from.hours, f.from.minutes, 0, 0).getTime());
            const h = Math.floor(dateRange.getTime() / 60 / 60 / 1000);
            const m = dateRange.getTime() / 60 / 1000 - h * 60;
            const range = `${h.toString(10).padStart(2, '0')}:${m.toString(10).padStart(2, '0')}`;
            content = (<View style={{
                    flexDirection: 'row',
                    width: '100%',
                    backgroundColor: 'rgb(246,246,248)',
                    borderRadius: ThemeManager.style.borderRadius,
                    flexWrap: 'wrap',
                    paddingHorizontal: '10%',
                }}>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('starts'), from, 'arrow-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('ends'), to, 'arrow-collapse-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('range'), range, 'calendar-range-outline', true)}
            </View>
          </View>
        </View>);
        }
        return (<View style={{ width: '100%' }}>
        {renderHeader(`${I18n.m.dateCurrent.localeDateFormat(ent.day, 'dddd')}, ${I18n.m.dateCurrent.localeDateString(ent.day, true)}`, <ApprovalStatusChip currentStep={ent} onType="extraPay" entityId={ent.id}/>, label)}
        {content}

        {ent.notes != null && ent.notes.length > 0 && renderItem(I18n.m.getMessage('note'), ent.notes)}

        <View style={{ width: '100%', paddingTop: 16 }}>
          <ReadmodelHistory windowWidth={props.dialogState?.windowWidth} readmodel="extraPayTracking" id={ent.id} ignoresProperties={[
                'projectTitle',
                'lastModifiedBy',
                'nextApprovalStepFor',
                'approvals',
                'approvalProcessId',
            ]} specialOnProperties={specialOnProperties}/>
        </View>
      </View>);
    };
    const renderAbsence = () => {
        if (absence == null)
            return <Spinner />;
        const starts = new Date(absence.starts);
        const ends = new Date(absence.ends);
        const dateRange = new Date(ends.getTime() - starts.getTime());
        const days = Math.round(dateRange.getTime() / 60 / 60 / 1000 / 24);
        const from = `${I18n.m.dateCurrent.localeDateFormat(new Date(absence.starts), 'dddd')}, ${I18n.m.dateCurrent.localeDateString(starts, true)}`;
        const to = `${I18n.m.dateCurrent.localeDateFormat(new Date(absence.ends), 'dddd')}, ${I18n.m.dateCurrent.localeDateString(ends, true)}`;
        let range = '';
        if (days > 0) {
            range = `${days.toString(10)} ${I18n.m.getMessage('days')}`;
        }
        else {
            const h = Math.floor(dateRange.getTime() / 60 / 60 / 1000);
            const m = dateRange.getTime() / 60 / 1000 - h * 60;
            range = `${h.toString(10).padStart(2, '0')}:${m.toString(10).padStart(2, '0')}`;
        }
        return (<View style={{ width: '100%' }}>
        {renderHeader(`${I18n.m.dateCurrent.localeDateFormat(entry.orgEntry.createdAt, 'dddd')}, ${I18n.m.dateCurrent.localeDateString(entry.orgEntry.createdAt, true)}`, <ApprovalStatusChip currentStep={absence} onType="absence" entityId={absence.id}/>)}

        <View style={{
                flexDirection: 'row',
                width: '100%',
                backgroundColor: 'rgb(246,246,248)',
                borderRadius: ThemeManager.style.borderRadius,
                flexWrap: 'wrap',
                paddingHorizontal: '3%',
            }}>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('starts'), from, 'arrow-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('ends'), to, 'arrow-collapse-right', true)}
            </View>
          </View>
          <View style={{ minWidth: 64, flex: 1 }}>
            <View style={{ alignSelf: 'center' }}>
              {renderItem(I18n.m.getMessage('range'), range, 'calendar-range-outline', true)}
            </View>
          </View>
        </View>

        {absence.note != null && absence.note.length > 0 && renderItem(I18n.m.getMessage('absenceNote'), absence.note)}

        <View style={{ width: '100%', paddingTop: 16 }}>
          <ReadmodelHistory windowWidth={props.dialogState?.windowWidth} readmodel="absence" id={absence.id} ignoresProperties={[
                'projectTitle',
                'lastModifiedBy',
                'nextApprovalStepFor',
                'approvals',
                'approvalProcessId',
            ]} specialOnProperties={specialOnProperties}/>
        </View>
      </View>);
    };
    let title = entry.type === 'TimeTrackingEntity' && !entry.orgEntry['absenceId']
        ? I18n.m.getMessage('timeTracking')
        : entry.type === 'TimeTrackingEntity' && entry.orgEntry['absenceId']
            ? I18n.m.getMessage('absence')
            : I18n.m.getMessage('extraPay');
    if (entry.type === 'ExtraPayTrackingEntity' && extraPay != null) {
        title += ` (${extraPay.getName()})`;
    }
    return (<>
      <DialogTitle iconRight={{
            toolTip: I18n.m.getMessage('close'),
            icon: 'close',
            onPress: () => Dialog.instance?.close(),
        }} additionalIcons={[
            {
                icon: 'pencil-outline',
                toolTip: I18n.m.getMessage('edit'),
                onPress: CombinedExtraPayTrackingDialogsControl.editEntry(entry),
            },
        ]}>
        {title}
      </DialogTitle>
      <DialogContent>
        {entry.type === 'TimeTrackingEntity' && !entry.orgEntry['absenceId']
            ? renderTimeTrackingEntry()
            : entry.type === 'TimeTrackingEntity' && entry.orgEntry['absenceId']
                ? renderAbsence()
                : renderExtraPayEntry()}
      </DialogContent>
    </>);
}
