import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
import { ApprovalsView } from '../timetracking/ApprovalsView';
export function OpenAbsenceRequestWidget(_props) {
    const [size, setSize] = React.useState(null);
    const onL = (e) => {
        const s = {
            windowWidth: e.nativeEvent.layout.width,
            windowHeight: e.nativeEvent.layout.height - 48,
            contentWidth: e.nativeEvent.layout.width,
            contentHeight: e.nativeEvent.layout.height - 48,
            contentBottom: 0,
            contentLeft: 0,
            contentRight: 0,
            contentTop: 0,
            currentKeyboardHeight: 0,
            windowBottom: 0,
            sidebarWidth: 0,
        };
        setSize(s);
    };
    return (<View style={{ width: '100%', height: '100%', maxHeight: '100%' }} onLayout={onL}>
      <View style={{ padding: 16, paddingBottom: 0, width: '100%', height: 48 }}>
        <MaterialText type={MaterialTextTypes.H5}>{I18n.m.getMessage('absenceOpenRequests')}</MaterialText>
      </View>
      {size && <ApprovalsView size={size} height={size.contentHeight} hideTabs/>}
    </View>);
}
