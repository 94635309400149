import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { ExcelFileDialog } from 'materialTheme/src/theme/components/file/ExcelFileDialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Image, Platform, UIManager, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeWorkingTimeModel } from 'upmesh-core/src/client/commands/companies/workingtimemodels/ChangeWorkingTimeModel';
import { CreateWorkingTimeModel } from 'upmesh-core/src/client/commands/companies/workingtimemodels/CreateWorkingTimeModel';
import { DeleteWorkingTimeModel } from 'upmesh-core/src/client/commands/companies/workingtimemodels/DeleteWorkingTimeModel';
import { SetWorkingTimeModelAsDefault } from 'upmesh-core/src/client/commands/companies/workingtimemodels/SetWorkingTimeModelAsDefault';
import { Daytime, WorkingTimeModelEntity, } from 'upmesh-core/src/client/query/entities/WorkingTimeModelEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddOrChangeWorkingTimeModelDialog } from './AddOrChangeWorkingTimeModelDialog';
import { CompanyWorkingTimeModelItem } from './CompanyWorkingTimeModelItem';
const noWorkingTimeModelImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyWorkingTimeModelView extends PureComponent {
    static getDerivedStateFromProps(nextProps) {
        return {
            tableMaxHeight: nextProps.size.contentHeight - 2 * ThemeManager.style.headerHeight - nextProps.size.contentBottom,
        };
    }
    constructor(props) {
        super(props);
        this.chipsList = [
            { title: '1' },
            { title: '2' },
            { title: '3' },
            { title: '4' },
            { title: '5' },
            { title: '6' },
            { title: '7' },
            { title: '8' },
            { title: '9' },
            { title: '10' },
            { title: '11' },
            { title: '12' },
            { title: '13' },
            { title: '14' },
            { title: '15' },
            { title: '16' },
            { title: '17' },
            { title: '18' },
            { title: '19' },
            { title: '20' },
            { title: '21' },
            { title: '22' },
            { title: '23' },
            { title: '24' },
            { title: '25' },
            { title: '26' },
            { title: '27' },
            { title: '28' },
        ];
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.updateData = (_e) => {
            const { company } = this.props;
            const asyncNow = async () => {
                const models = await UpmeshClient.instance.modals.workingTimeModel.get({
                    filter: `deleted ne true and companyId eq '${company.id}'`,
                });
                models.forEach((c) => c.sortSections());
                this.setState({ data: models });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.openMenu = (w) => (e) => {
            const { companySettings } = this.props;
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                const items = [
                    {
                        text: I18n.m.getMessage('edit'),
                        onPress: this.openEditDialog(w),
                        thumbnail: {
                            thumbnail: <Icon toolTip="" icon="pencil-outline" color={ThemeManager.style.defaultIconColor}/>,
                            width: 24,
                        },
                    },
                    {
                        text: I18n.m.getMessage('copy'),
                        onPress: this.openCopyDialog(w),
                        thumbnail: {
                            thumbnail: <Icon toolTip="" icon="content-copy" color={ThemeManager.style.defaultIconColor}/>,
                            width: 24,
                        },
                    },
                ];
                if (companySettings.defaultWorkingTimeModelId !== w.id) {
                    items.push({
                        text: I18n.m.getMessage('workingTimeModelSetAsDefault'),
                        onPress: this.setAsDefault(w),
                        thumbnail: {
                            thumbnail: <Icon toolTip="" icon="standard-definition" color={ThemeManager.style.defaultIconColor}/>,
                            width: 24,
                        },
                    }, {
                        text: I18n.m.getMessage('delete'),
                        onPress: this.removeModel(w),
                        thumbnail: {
                            thumbnail: <Icon toolTip="" icon="delete" color={ThemeManager.style.defaultIconColor}/>,
                            width: 24,
                        },
                    });
                }
                Menu.instance?.open({
                    client,
                    items: [...items],
                });
            });
        };
        this.removeModel = (w) => (_e) => {
            Menu.instance?.close();
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveWorkingTimeModelQuestion', { name: w.mark }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeModelNow(w.id)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.setAsDefault = (w) => (_e) => {
            Menu.instance?.close();
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyWorkingTimeModelSetAsDefaultQuestion', { name: w.mark }),
                buttons: [
                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('workingTimeModelSetAsDefault')} onPress={this.setAsDefaultNow(w)}/>,
                ],
            });
        };
        this.removeModelNow = (modelId) => (_e) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new DeleteWorkingTimeModel({}, modelId, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((e) => {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.setAsDefaultNow = (model) => (_e) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new SetWorkingTimeModelAsDefault({ defaultWorkingTimeModelId: model.id }, model.companyId, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((e) => {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.openFab = () => {
            if (Fab.instance == null) {
                window.setTimeout(this.openFab, 100);
                return;
            }
            const permitted = this.myRole() === 'admin';
            if (Platform.OS === 'web') {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: !permitted
                        ? () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') })
                        : undefined,
                    fabActions: permitted
                        ? [
                            {
                                text: I18n.m.getMessage('workingTimeModelAdd'),
                                icon: 'plus',
                                onPress: this.openAddDialog,
                            },
                            {
                                text: I18n.m.getMessage('companyExcelDialogTitle'),
                                icon: 'microsoft-excel',
                                onPress: this.openExcelDialog,
                            },
                        ]
                        : undefined,
                });
            }
            else {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: permitted
                        ? this.openAddDialog
                        : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                });
            }
        };
        this.openExcelDialog = () => {
            const weekdays = I18n.m.dateCurrent.weekdays();
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
                showCloseButton: false,
                width: 1042,
                content: (<ExcelFileDialog saveFunction={this.saveFunction} generateExampleRecords={this.generateExamples} columns={[
                        {
                            title: I18n.m.getMessage('workingTimeModelMark'),
                            keyInData: 'Kennzeichen',
                            style: { width: 200 },
                        },
                        {
                            title: I18n.m.getMessage('companyExcelWorkingTimePostRecordingHeader'),
                            keyInData: 'NacherfassenBis',
                            style: { width: 200 },
                            dataType: 'number',
                        },
                        {
                            title: I18n.m.getMessage('validFrom'),
                            keyInData: 'Ab',
                            style: { width: 200 },
                            dataType: 'Date',
                        },
                        {
                            title: `${weekdays[1]} ${I18n.m.getMessage('companyExcelWorkingTimeStart')}`,
                            keyInData: 'MontagStart',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[1]} ${I18n.m.getMessage('companyExcelWorkingTimeEnd')}`,
                            keyInData: 'MontagEnde',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[1]} ${I18n.m.getMessage('companyExcelWorkingTimePause')}`,
                            keyInData: 'MontagPause',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[2]} ${I18n.m.getMessage('companyExcelWorkingTimeStart')}`,
                            keyInData: 'DienstagStart',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[2]} ${I18n.m.getMessage('companyExcelWorkingTimeEnd')}`,
                            keyInData: 'DienstagEnde',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[2]} ${I18n.m.getMessage('companyExcelWorkingTimePause')}`,
                            keyInData: 'DienstagPause',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[3]} ${I18n.m.getMessage('companyExcelWorkingTimeStart')}`,
                            keyInData: 'MittwochStart',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[3]} ${I18n.m.getMessage('companyExcelWorkingTimeEnd')}`,
                            keyInData: 'MittwochEnde',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[3]} ${I18n.m.getMessage('companyExcelWorkingTimePause')}`,
                            keyInData: 'MittwochPause',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[4]} ${I18n.m.getMessage('companyExcelWorkingTimeStart')}`,
                            keyInData: 'DonnerstagStart',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[4]} ${I18n.m.getMessage('companyExcelWorkingTimeEnd')}`,
                            keyInData: 'DonnerstagEnde',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[4]} ${I18n.m.getMessage('companyExcelWorkingTimePause')}`,
                            keyInData: 'DonnerstagPause',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[5]} ${I18n.m.getMessage('companyExcelWorkingTimeStart')}`,
                            keyInData: 'FreitagStart',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[5]} ${I18n.m.getMessage('companyExcelWorkingTimeEnd')}`,
                            keyInData: 'FreitagEnde',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[5]} ${I18n.m.getMessage('companyExcelWorkingTimePause')}`,
                            keyInData: 'FreitagPause',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[6]} ${I18n.m.getMessage('companyExcelWorkingTimeStart')}`,
                            keyInData: 'SamstagStart',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[6]} ${I18n.m.getMessage('companyExcelWorkingTimeEnd')}`,
                            keyInData: 'SamstagEnde',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[6]} ${I18n.m.getMessage('companyExcelWorkingTimePause')}`,
                            keyInData: 'SamstagPause',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[0]} ${I18n.m.getMessage('companyExcelWorkingTimeStart')}`,
                            keyInData: 'SonntagStart',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[0]} ${I18n.m.getMessage('companyExcelWorkingTimeEnd')}`,
                            keyInData: 'SonntagEnde',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                        {
                            title: `${weekdays[0]} ${I18n.m.getMessage('companyExcelWorkingTimePause')}`,
                            keyInData: 'SonntagPause',
                            style: { width: 200 },
                            dataType: 'Time',
                        },
                    ]}/>),
            });
        };
        this.generateExamples = async () => {
            const { data } = this.state;
            const examples = [];
            if (data != null) {
                data.forEach((element) => {
                    element.sections.forEach((section) => {
                        examples.push({
                            Kennzeichen: element.mark,
                            NacherfassenBis: element.postRecordingInDays,
                            Ab: new Date(0, section.validFromMonth, section.validFromDay),
                            MontagStart: section.workingTime.mon != null ? Daytime.toDate(section.workingTime.mon.start) : null,
                            MontagEnde: section.workingTime.mon != null ? Daytime.toDate(section.workingTime.mon.stop) : null,
                            MontagPause: section.workingTime.mon != null ? Daytime.toDate(section.workingTime.mon.pause) : null,
                            DienstagStart: section.workingTime.tue != null ? Daytime.toDate(section.workingTime.tue.start) : null,
                            DienstagEnde: section.workingTime.tue != null ? Daytime.toDate(section.workingTime.tue.stop) : null,
                            DienstagPause: section.workingTime.tue != null ? Daytime.toDate(section.workingTime.tue.pause) : null,
                            MittwochStart: section.workingTime.wed != null ? Daytime.toDate(section.workingTime.wed.start) : null,
                            MittwochEnde: section.workingTime.wed != null ? Daytime.toDate(section.workingTime.wed.stop) : null,
                            MittwochPause: section.workingTime.wed != null ? Daytime.toDate(section.workingTime.wed.pause) : null,
                            DonnerstagStart: section.workingTime.thi != null ? Daytime.toDate(section.workingTime.thi.start) : null,
                            DonnerstagEnde: section.workingTime.thi != null ? Daytime.toDate(section.workingTime.thi.stop) : null,
                            DonnerstagPause: section.workingTime.thi != null ? Daytime.toDate(section.workingTime.thi.pause) : null,
                            FreitagStart: section.workingTime.fri != null ? Daytime.toDate(section.workingTime.fri.start) : null,
                            FreitagEnde: section.workingTime.fri != null ? Daytime.toDate(section.workingTime.fri.stop) : null,
                            FreitagPause: section.workingTime.fri != null ? Daytime.toDate(section.workingTime.fri.pause) : null,
                            SamstagStart: section.workingTime.sat != null ? Daytime.toDate(section.workingTime.sat.start) : null,
                            SamstagEnde: section.workingTime.sat != null ? Daytime.toDate(section.workingTime.sat.stop) : null,
                            SamstagPause: section.workingTime.sat != null ? Daytime.toDate(section.workingTime.sat.pause) : null,
                            SonntagStart: section.workingTime.sun != null ? Daytime.toDate(section.workingTime.sun.start) : null,
                            SonntagEnde: section.workingTime.sun != null ? Daytime.toDate(section.workingTime.sun.stop) : null,
                            SonntagPause: section.workingTime.sun != null ? Daytime.toDate(section.workingTime.sun.pause) : null,
                        });
                    });
                });
            }
            return examples;
        };
        this.saveFunction = async (records) => {
            const { company } = this.props;
            const handledWorkingTimes = new Map();
            for (const element of records) {
                if (!handledWorkingTimes.has(element.Kennzeichen)) {
                    const isMarkExistent = await UpmeshClient.instance.modals.workingTimeModel.get({
                        filter: `companyId eq '${company.id}' and mark eq '${element.Kennzeichen}' and deleted ne true`,
                        top: 1,
                    });
                    if (isMarkExistent != null && isMarkExistent.length > 0) {
                        handledWorkingTimes.set(isMarkExistent[0].mark, isMarkExistent[0]);
                    }
                    else {
                        const tmpWorking = new WorkingTimeModelEntity();
                        tmpWorking.id = '';
                        tmpWorking.companyId = company.id;
                        tmpWorking.mark = element.Kennzeichen;
                        tmpWorking.postRecordingInDays = element.NacherfassenBis;
                        handledWorkingTimes.set(element.Kennzeichen, tmpWorking);
                    }
                }
                let workingWeek = {};
                if (element.MontagStart != null) {
                    workingWeek = {
                        ...workingWeek,
                        mon: {
                            start: Daytime.fromDate(element.MontagStart),
                            stop: Daytime.fromDate(element.MontagEnde),
                            pause: Daytime.fromDate(element.MontagPause),
                        },
                    };
                }
                if (element.DienstagStart != null) {
                    workingWeek = {
                        ...workingWeek,
                        tue: {
                            start: Daytime.fromDate(element.DienstagStart),
                            stop: Daytime.fromDate(element.DienstagEnde),
                            pause: Daytime.fromDate(element.DienstagPause),
                        },
                    };
                }
                if (element.MittwochStart != null) {
                    workingWeek = {
                        ...workingWeek,
                        wed: {
                            start: Daytime.fromDate(element.MittwochStart),
                            stop: Daytime.fromDate(element.MittwochEnde),
                            pause: Daytime.fromDate(element.MittwochPause),
                        },
                    };
                }
                if (element.DonnerstagStart != null) {
                    workingWeek = {
                        ...workingWeek,
                        thi: {
                            start: Daytime.fromDate(element.DonnerstagStart),
                            stop: Daytime.fromDate(element.DonnerstagEnde),
                            pause: Daytime.fromDate(element.DonnerstagPause),
                        },
                    };
                }
                if (element.FreitagStart != null) {
                    workingWeek = {
                        ...workingWeek,
                        fri: {
                            start: Daytime.fromDate(element.FreitagStart),
                            stop: Daytime.fromDate(element.FreitagEnde),
                            pause: Daytime.fromDate(element.FreitagPause),
                        },
                    };
                }
                if (element.SamstagStart != null) {
                    workingWeek = {
                        ...workingWeek,
                        sat: {
                            start: Daytime.fromDate(element.SamstagStart),
                            stop: Daytime.fromDate(element.SamstagEnde),
                            pause: Daytime.fromDate(element.SamstagPause),
                        },
                    };
                }
                if (element.SonntagStart != null) {
                    workingWeek = {
                        ...workingWeek,
                        sun: {
                            start: Daytime.fromDate(element.SonntagStart),
                            stop: Daytime.fromDate(element.SonntagEnde),
                            pause: Daytime.fromDate(element.SonntagPause),
                        },
                    };
                }
                const currentWorkingTime = handledWorkingTimes.get(element.Kennzeichen);
                const currentSection = {
                    validFromMonth: new Date(element.Ab).getMonth() + 1,
                    validFromDay: new Date(element.Ab).getDate(),
                    workingTime: workingWeek,
                };
                if (currentWorkingTime != null) {
                    if (currentWorkingTime.sections != null) {
                        currentWorkingTime.sections.push(currentSection);
                    }
                    else {
                        currentWorkingTime.sections = [currentSection];
                    }
                }
                else {
                    console.log('should never ever happen');
                }
            }
            const workingTimePromises = [];
            handledWorkingTimes.forEach((element) => {
                if (element.id != null && element.id.length > 0) {
                    workingTimePromises.push(new ChangeWorkingTimeModel({ ...element }, element.id).execute(ClientStore.commandStore));
                }
                else {
                    const correctId = new WorkingTimeModelEntity();
                    element.id = correctId.id;
                    workingTimePromises.push(new CreateWorkingTimeModel({ ...element }).execute(ClientStore.commandStore));
                }
            });
            await Promise.all(workingTimePromises);
        };
        this.openAddDialog = (_e) => {
            const { company } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddOrChangeWorkingTimeModelDialog company={company}/>,
                contentPadding: false,
                fullscreenResponsive: true,
                scrollable: false,
                width: 1024,
            });
        };
        this.openEditDialog = (w) => (_e) => {
            Menu.instance?.close();
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <AddOrChangeWorkingTimeModelDialog model={w} company={company}/>,
                    contentPadding: false,
                    fullscreenResponsive: true,
                    scrollable: false,
                    width: 1024,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.openCopyDialog = (w) => (_e) => {
            Menu.instance?.close();
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: (<AddOrChangeWorkingTimeModelDialog model={new WorkingTimeModelEntity({
                            companyId: w.companyId,
                            mark: '',
                            sections: w.sections,
                            postRecordingInDays: w.postRecordingInDays,
                        })} company={company} createNewFromDraft/>),
                    contentPadding: false,
                    fullscreenResponsive: true,
                    scrollable: true,
                    width: 1024,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.renderWorkingTimeModel = (w) => {
            const { companySettings } = this.props;
            const defaultText = companySettings.defaultWorkingTimeModelId === w.id ? `(${I18n.m.getMessage('default')})` : '';
            return (<Card style={{ width: '100%' }}>
        <View style={{ width: '100%', padding: ThemeManager.style.contentPaddingValue, position: 'relative' }}>
          <MaterialText type={MaterialTextTypes.H6}>
            {w.mark} {defaultText}
          </MaterialText>
          <CompanyWorkingTimeModelItem key={`${w.id}_${w.lastModifiedAt}`} wtm={w} openMenu={this.openMenu(w)}/>
        </View>
      </Card>);
        };
        this.state = {
            tableMaxHeight: ResizeEvent.current.contentHeight - 2 * ThemeManager.style.headerHeight - ResizeEvent.current.contentBottom,
            myRole: this.myRole(),
        };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyWorkingTimeModelView',
            readModelName: 'WorkingTimeModel',
            callback: this.updateData,
        });
        this.updateData();
        this.openFab();
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('WorkingTimeModel', 'CompanyWorkingTimeModelView');
        if (Fab.instance != null) {
            Fab.instance.close();
        }
    }
    render() {
        const { data, tableMaxHeight, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        return (<ScrollContainer style={{
                width: '100%',
                maxWidth: '100%',
                alignSelf: 'center',
                height: tableMaxHeight,
                maxHeight: tableMaxHeight,
            }}>
        <View style={{
                paddingHorizontal: 8,
                width: '100%',
                flexDirection: 'row',
                marginLeft: 8 + ThemeManager.style.contentPaddingValue,
                paddingTop: ThemeManager.style.contentPaddingValue,
            }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('workingTimeModels')}
          </MaterialText>
        </View>
        <View style={{ width: '100%', position: 'relative', paddingBottom: ThemeManager.style.contentPaddingValue }}>
          {data == null || data.length === 0 ? (<Image style={{ width: '100%', height: 200, resizeMode: 'contain' }} resizeMode="contain" source={noWorkingTimeModelImg}/>) : (data.map(this.renderWorkingTimeModel))}
        </View>
      </ScrollContainer>);
    }
}
