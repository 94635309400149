import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { RemoveProjectUser } from 'upmesh-core/src/client/commands/user/RemoveProjectUser';
import { TeammemberFilter } from 'upmesh-core/src/client/query/filter/TeammemberFilter';
import { TicketFilter } from 'upmesh-core/src/client/query/filter/TicketFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { InfoButton } from 'materialTheme/src/theme/components/InfoButton';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { NoRights } from '../NoRights';
import { CurrentProject } from '../project/CurrentProject';
import { PageView } from '../root/PageView';
import { TeamDialogFilter } from './TeamDialogFilter';
import { TeamMembersList } from './TeamMembersList';
import { TeamRolesList } from './TeamRolesList';
import { upmeshSession } from '../../utils/Storage';
export class TeamView extends PureComponent {
    constructor(props) {
        super(props);
        this.filter = new TeammemberFilter();
        this.filteredTeammembers = [];
        this.isLoading = false;
        this.changeTab = (index) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                if (index === 1) {
                    Routing.instance.goTo(`/projects/${projectId}/team/roles`);
                }
                else {
                    Routing.instance.goTo(`/projects/${projectId}/team`);
                }
            }
        };
        this.update = () => {
            this.loadTeamMember();
        };
        this.filterProjectsState = () => {
            const teammembers = this.getfilteredTeammembers();
            const searchedTeammembers = this.applySearch(teammembers);
            this.setState({ filteredTeammembers: teammembers, searchedTeammembers });
        };
        this.getfilteredTeammembers = () => {
            const allMembers = this.filteredTeammembers;
            if (this.filter === new TeammemberFilter()) {
                return allMembers;
            }
            const filteredTeammembers = TeammemberFilter.filterTeammember(allMembers, this.filter);
            return [...filteredTeammembers];
        };
        this.getTranslatedStatus = (status) => {
            if (status === 'accepted')
                return I18n.m.getMessage('teamMemberActive');
            if (status === 'deleted')
                return I18n.m.getMessage('teamMemberRemoved');
            if (status === 'invited')
                return I18n.m.getMessage('teamMemberInvited');
            return I18n.m.getMessage('teamMemberRemoved');
        };
        this.applySearch = (filteredTeammembers, searchValue) => {
            const { searchbarValue } = this.state;
            const s = searchValue?.toLowerCase() ?? searchbarValue;
            if (s.length === 0)
                return filteredTeammembers;
            const res = filteredTeammembers.filter((member) => member.name?.toLowerCase().includes(s) ||
                member.emails.toLowerCase().includes(s) ||
                member.company?.toLowerCase().includes(s) ||
                (member.status && this.getTranslatedStatus(member.status).toLowerCase().includes(s)));
            return res;
        };
        this.onSearch = (s) => {
            const { searchbarValue, filteredTeammembers } = this.state;
            Routing.instance.changeQueryParameter({ q: s });
            if (s !== searchbarValue)
                this.setState({ searchbarValue: s });
            const searchedTeammembers = this.applySearch(filteredTeammembers, s);
            this.setState({ searchedTeammembers });
        };
        this.abortDelete = (_e) => {
            Alert.instance?.close();
        };
        this.loadTeamMember = () => {
            if (this.isLoadingTo != null) {
                clearTimeout(this.isLoadingTo);
            }
            if (this.isLoading) {
                this.isLoadingTo = setTimeout(this.loadTeamMember, 100);
            }
            else {
                this.isLoading = true;
                if (CurrentUser.entity == null) {
                    this.setState({ error: 'forbidden' });
                    return;
                }
                const { project } = this.props;
                const userId = CurrentUser.entity.id;
                const asyncNow = async () => {
                    const canRead = await RightsManager.hasReadRight(project.id, userId, 'teams');
                    if (!canRead) {
                        this.setState({ error: 'forbidden' });
                        return;
                    }
                    let canIInvite = false;
                    canIInvite = await RightsManager.hasWriteRight(project.id, userId, 'commandInviteUser');
                    let canICreateRoles = false;
                    canICreateRoles = await RightsManager.hasWriteRight(project.id, userId, 'commandCreateProjectRole');
                    const userRole = CurrentProject.instance
                        .getCurrentProjectTeam('all')
                        .find((e) => e.user.id === CurrentUser.userId)?.role;
                    const canSeeExtraColumns = userRole === 'admin' || userRole === 'projectManager';
                    const teammembers = [];
                    const creatorId = project.creator;
                    try {
                        let item;
                        if (project.creator.startsWith('company')) {
                            const companyId = project.creator.slice(7);
                            const company = await UpmeshClient.instance.modals.company.getById(companyId);
                            item = {
                                company: company.company,
                                companyItem: company,
                                id: `creator_${creatorId}`,
                                translatedRole: I18n.m.getMessage('projectOwner'),
                                name: company.company,
                                userId: company.id,
                                emails: company.email,
                                toMail: '',
                                user: undefined,
                                status: 'accepted',
                            };
                        }
                        else {
                            const creator = await AuthClient.instance.modals.user.getById(creatorId);
                            const companies = await UpmeshClient.instance.modals.company.get({
                                filter: `users/userId eq '${creator.userId}'`,
                            });
                            let company = '';
                            if (companies.length > 0) {
                                company = companies[0].company;
                            }
                            else {
                                company = creator.company ? creator.company : '';
                            }
                            item = {
                                id: `creator_${creatorId}`,
                                translatedRole: I18n.m.getMessage('projectOwner'),
                                name: creator.getFullName(),
                                userId: creator.userId,
                                company,
                                emails: creator.emails == null ? '' : creator.emails.join(', '),
                                toMail: '',
                                user: creator,
                                status: 'accepted',
                            };
                        }
                        teammembers.push(item);
                    }
                    catch (e) {
                        console.debug('cant get creator');
                    }
                    const projectUser = await UpmeshClient.instance.modals.projectUser.get({
                        filter: `projectId eq '${project.id}'`,
                    });
                    for (let i = 0; i < projectUser.length; i += 1) {
                        let username = '';
                        let company = '';
                        let companyItem;
                        try {
                            let user;
                            if (projectUser[i].userId != null && projectUser[i].status === 'accepted') {
                                try {
                                    user = await AuthClient.instance.modals.user.getById(projectUser[i].userId);
                                    username = `${user.firstname} ${user.lastname}`;
                                }
                                catch (err) {
                                    console.warn('cant get user in TeamView', err);
                                }
                                const companies = await UpmeshClient.instance.modals.company.get({
                                    filter: `users/userId eq '${projectUser[i].userId}'`,
                                });
                                if (companies.length > 0) {
                                    company = companies[0].company;
                                    companyItem = companies[0];
                                }
                                else {
                                    company = user && user.company ? user.company : '';
                                }
                            }
                            else {
                                username = I18n.m.getMessage('teamInvitationPending');
                            }
                            const role = await UpmeshClient.instance.modals.projectRoles.getById(projectUser[i].roleId);
                            let translatedRole = I18n.m.getMessage(role.roleName);
                            if (companyItem != null &&
                                project.creator.startsWith('company') &&
                                role.roleName === 'subcontractor' &&
                                `company${companyItem.id}` === project.creator) {
                                translatedRole = I18n.m.getMessage('subcontractorInOwnCompany');
                            }
                            let joinedData;
                            if (canSeeExtraColumns && user != null) {
                                joinedData = await this.findJoinedDateForUserAndInvitee(user.userId, project.id);
                            }
                            teammembers.push({
                                id: projectUser[i].id,
                                translatedRole,
                                user,
                                company,
                                companyItem,
                                emails: user != null ? (user.emails == null ? '' : user.emails.join(', ')) : projectUser[i].toEmail,
                                toMail: projectUser[i].toEmail,
                                name: username,
                                status: projectUser[i].status,
                                userId: projectUser[i].userId,
                                joined: joinedData ? joinedData.joined : undefined,
                                invitee: joinedData ? joinedData.invitee : undefined,
                                lastActive: user ? new Date(user.lastLogin ?? '') : undefined,
                            });
                        }
                        catch (e) {
                            console.debug(e);
                        }
                    }
                    this.filteredTeammembers = teammembers;
                    const filteredTeammembers = this.getfilteredTeammembers();
                    const searchedTeammembers = this.applySearch(filteredTeammembers);
                    this.setState({
                        filteredTeammembers,
                        allTeammembers: teammembers,
                        searchedTeammembers,
                        canIInvite,
                        canICreateRoles,
                        init: true,
                    }, () => {
                        this.isLoading = false;
                    });
                };
                asyncNow().catch((err) => console.error(err));
            }
        };
        this.openTeamFilter = () => {
            TeamDialogFilter.open(this.state.allTeammembers, this.setFilter, this.filter);
        };
        this.removeMe = () => {
            const { project } = this.props;
            UpmeshClient.instance.modals.projectUser
                .get({
                filter: `userId eq '${CurrentUser.userId}' and projectId eq '${project.id}'`,
            })
                .then((me) => {
                if (me.length > 0) {
                    this.removeUser({
                        id: me[0].id,
                        status: 'accepted',
                        name: CurrentUser.entity?.getFullName(),
                        emails: '',
                        toMail: '',
                        userId: me[0].userId,
                        user: CurrentUser.entity,
                        company: '',
                        translatedRole: '',
                    })();
                }
            })
                .catch(DefaultErrorHandler.showDefaultErrorAlert);
        };
        this.removeUser = (pu) => () => {
            Menu.instance?.close();
            const message = pu.userId === CurrentUser.userId ? 'teamRemovePeopleMe' : 'teamRemovePeople';
            const actionMessage = pu.userId === CurrentUser.userId ? 'teamLeaveProject' : 'teamRemoveUser';
            Routing.instance.alert.post({
                text: I18n.m.getMessage(message, { username: pu.name }),
                buttons: [
                    <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={this.abortDelete}/>,
                    <ContainedButton key="save" title={I18n.m.getMessage(actionMessage)} onPress={this.removeUserNow(pu)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeUserNow = (pu) => (_e) => {
            Alert.instance?.close(() => {
                LoadingEvents.instance.startLoading();
                const { project } = this.props;
                if (project != null) {
                    const r = new RemoveProjectUser({}, pu.id);
                    r.execute()
                        .then(() => {
                        LoadingEvents.instance.stopLoading();
                        if (pu.userId === CurrentUser.userId) {
                            if (AuthClient.instance != null) {
                                AuthClient.instance.startSync().catch((err) => console.debug(err));
                            }
                            Routing.instance.goTo('/');
                        }
                        else {
                            this.loadTeamMember();
                        }
                    })
                        .catch((e) => {
                        if (e.messageCode != null) {
                            if (e.messageCode === 'cantDeleteHasAssigendTickets') {
                                const alertMessage = pu.userId === CurrentUser.userId ? 'cantDeleteHasAssigendTicketsMe' : 'cantDeleteHasAssigendTickets';
                                Routing.instance.alert.post({
                                    text: I18n.m.getMessage(alertMessage),
                                    buttons: [
                                        <ContainedButton key="showTickets" title={I18n.m.getMessage('teamRemoveUserShowTickets')} onPress={this.showTickets(pu, project.id)} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                                        <ContainedButton key="abort" title={I18n.m.getMessage('abort')} onPress={Alert.instance?.close}/>,
                                    ],
                                    closeOnTouchOutside: true,
                                });
                            }
                            else {
                                DefaultErrorHandler.showDefaultErrorAlert(e);
                            }
                        }
                        else {
                            DefaultErrorHandler.showDefaultErrorAlert(e);
                            ErrorReporter.sendReport({
                                data: e,
                                subject: e.messageCode != null ? e.messageCode : 'cant remove user',
                                type: 'warn',
                            });
                        }
                        LoadingEvents.instance.stopLoading();
                    });
                }
            });
        };
        this.setFilter = (memberFilter) => {
            upmeshSession.setItem(this.filterKey, memberFilter);
        };
        this.showTickets = (pu, projectId) => (_e) => {
            Alert.instance?.close(() => {
                const userTicketFilter = new TicketFilter();
                userTicketFilter.u = [pu.userId];
                userTicketFilter.u2 = ['assignedTo', 'approvedBy'];
                userTicketFilter.a = 1;
                userTicketFilter.d = 1;
                const f = encodeURIComponent(JSON.stringify(userTicketFilter));
                const deepLinkState = `/projects/${projectId}/tickets?f=${f}`;
                Routing.instance.goTo(deepLinkState);
            });
        };
        this.filter = new TeammemberFilter();
        this.filterKey = `teamfilter_${props.project.id}`;
        if (props.f != null) {
            try {
                this.filter = JSON.parse(props.f);
            }
            catch (e) {
                console.debug('cant read TeammemberFilter', e);
            }
        }
        else {
            const filter = upmeshSession.getItem(this.filterKey);
            if (filter) {
                Routing.instance.changeQueryParameter({
                    f: JSON.stringify(filter),
                });
                this.filter = filter;
            }
        }
        this.state = {
            init: false,
            canIInvite: false,
            canICreateRoles: false,
            selectedTab: props.pathvars.planId === 'roles' ? 1 : 0,
            allTeammembers: [],
            filteredTeammembers: [],
            searchedTeammembers: [],
            searchbarValue: props.q ?? '',
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const selectedTab = nextProps.pathvars.planId === 'roles' ? 1 : 0;
        if (selectedTab !== prevState.selectedDate) {
            return { selectedTab };
        }
        return null;
    }
    componentDidMount() {
        CurrentProject.teamChanged.attach(this.update);
        this.loadTeamMember();
    }
    componentDidUpdate(prevProps, _prevState, _prevContext) {
        if (prevProps.project.creator !== this.props.project.creator) {
            this.loadTeamMember();
        }
        if (prevProps.f !== this.props.f) {
            this.filter = new TeammemberFilter();
            if (this.props.f != null) {
                try {
                    this.filter = JSON.parse(this.props.f);
                    SimpleStorage.set(`${CurrentUser.userId}_saved_teammemberfilter`, this.props.f);
                }
                catch (e) {
                    console.debug('cant read ProjectFilter', e);
                }
            }
            this.filterProjectsState();
        }
    }
    componentWillUnmount() {
        CurrentProject.teamChanged.detach(this.update);
        if (this.isLoadingTo != null) {
            clearTimeout(this.isLoadingTo);
        }
    }
    async findJoinedDateForUserAndInvitee(userId, projectId) {
        const projectUser = await UpmeshClient.instance.modals.projectUser.get({
            filter: `userId eq '${userId}' and projectId eq '${projectId}'`,
        });
        if (projectUser.length > 0) {
            return {
                joined: projectUser[0].createdAt,
                invitee: projectUser[0].addedByUserName,
            };
        }
        return undefined;
    }
    render() {
        const { size } = this.props;
        const { error, selectedTab, searchbarValue } = this.state;
        const isFilterActive = this.filter != null &&
            ((this.filter.roles != null && this.filter.roles.length > 0) ||
                (this.filter.companies != null && this.filter.companies.length > 0) ||
                this.filter.status == null ||
                !(this.filter.status.length === 2 &&
                    this.filter.status.includes('accepted') &&
                    this.filter.status.includes('invited')));
        if (error != null && error.length > 0) {
            return (<PageView headerProps={{ title: I18n.m.getMessage('menuProjectInvolved') }}>
          <NoRights error={error}/>
          <View style={{ width: 'auto', alignSelf: 'center', paddingTop: 16 }}>
            <ContainedButton onPress={this.removeMe} title={I18n.m.getMessage('teamLeaveProject')}/>
          </View>
        </PageView>);
        }
        let filterIcon = (<Icon key="teammemberfilter" icon={isFilterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={this.openTeamFilter} color={isFilterActive ? ThemeManager.style.brandPrimary : ThemeManager.style.defaultIconColor}/>);
        if (isFilterActive && ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM) {
            filterIcon = (<View key={`filterButtonBigOuter${isFilterActive}`}>
          <ContainedButton key="teammemberfilter" title={I18n.m.getMessage('filterChange')} icon={{ icon: 'filter-remove', color: '#ffffff' }} onPress={this.openTeamFilter}/>
        </View>);
        }
        return (<PageView headerProps={{
                searchBarProps: {
                    accessibilityLabel: I18n.m.getMessage('teamSearchMembers'),
                    searchBarValue: searchbarValue,
                    searchBarPlaceholder: I18n.m.getMessage('teamSearchMembers'),
                    searchOnChange: this.onSearch,
                    tooltip: I18n.m.getMessage('teamSearchMembers'),
                },
                title: selectedTab === 0 ? '' : I18n.m.getMessage('menuProjectInvolved'),
                rightButtons: selectedTab === 0 ? [filterIcon] : undefined,
            }} scrollable={false}>
        {!this.state.init ? (<Spinner />) : (<View>
            <TabBar textColor={ThemeManager.style.black87} unfocusedTextColor={ThemeManager.style.disabledColor} underlineColor={ThemeManager.style.brandPrimary} width={size.contentWidth} selected={selectedTab} onChangeSelected={this.changeTab} tabs={[{ title: I18n.m.getMessage('members') }, { title: I18n.m.getMessage('roles') }]}/>
            {this.renderContent()}
          </View>)}
      </PageView>);
    }
    renderContent() {
        const { selectedTab, searchedTeammembers, canIInvite, canICreateRoles, searchbarValue } = this.state;
        const { project, size } = this.props;
        const { contentHeight } = size;
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        const userRole = CurrentProject.instance
            .getCurrentProjectTeam('all')
            .find((e) => e.user.id === CurrentUser.userId)?.role;
        const canSeeExtraColumns = userRole === 'admin' || userRole === 'projectManager';
        let content;
        if (searchedTeammembers == null) {
            content = <Spinner />;
        }
        else if (selectedTab === 1) {
            content = (<TeamRolesList project={project} canEdit={canICreateRoles} size={size} maxHeight={contentHeight -
                    ThemeManager.style.headerHeight -
                    ThemeManager.style.getScreenRelativePixelSize(116) -
                    sViewHeight}/>);
        }
        else {
            content = (<TeamMembersList project={project} size={size} canEdit={canIInvite} canIInvite={canIInvite} users={searchedTeammembers} maxHeight={contentHeight -
                    ThemeManager.style.headerHeight -
                    ThemeManager.style.getScreenRelativePixelSize(104) -
                    16 -
                    sViewHeight} removeUser={this.removeUser} emptyTableText={searchbarValue.length > 0 ? I18n.m.getMessage('teamNoSearchResultText') : undefined} emptyTableHint={searchbarValue.length > 0 ? I18n.m.getMessage('teamNoSearchResultHint') : undefined} canSeeExtraColumns={canSeeExtraColumns}/>);
        }
        return (<View style={{
                width: selectedTab === 1 ? 'auto' : '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {selectedTab === 1 ? I18n.m.getMessage('roles') : I18n.m.getMessage('projectMembers')}
          </MaterialText>
          {selectedTab === 1 ? <InfoButton text={I18n.m.getMessage('rolesInformationText')}/> : null}
        </View>
        <Card style={{
                alignContent: 'center',
                width: selectedTab === 1 ? 'auto' : '100%',
                height: selectedTab === 1
                    ? undefined
                    : contentHeight -
                        ThemeManager.style.headerHeight -
                        ThemeManager.style.getScreenRelativePixelSize(104) -
                        sViewHeight,
            }}>
          {content}
        </Card>
      </View>);
    }
}
TeamView.defaultProps = {
    title: 'upmesh',
};
