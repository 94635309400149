import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import React from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { planFilterGetTagsGroupsOfProjects } from '../plans/PlansFilterDialog';
import { TicketDialogFilter } from './TicketDialogFilter';
export const openTagFilter = (currentFilter, tickets, setFilter) => (e) => {
    const projectTags = TicketDialogFilter.getTags(tickets);
    const selectedTags = TicketDialogFilter.getSelectedChipsByIds(projectTags, currentFilter.t);
    const asyncNow = async () => {
        const projectIds = new Set();
        tickets.forEach((t) => projectIds.add(t.projectId));
        const projects = await UpmeshClient.instance.modals.project.get({
            filter: `id in ${JSON.stringify(projectIds.values())}`,
        });
        const projectTagGroups = planFilterGetTagsGroupsOfProjects(projects);
        const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
        const listItems = [];
        for (let i = 0; i < projectTags.length; i += 1) {
            const aChip = projectTags[i];
            if (aChip.title != null) {
                listItems.push({
                    selected: selectedTags != null && selectedTags.length > 0
                        ? selectedTags.findIndex((a) => a.title === aChip.title) > -1
                        : false,
                    secondTextLine: aChip.groupId,
                    thumbnail: aChip.backgroundColor
                        ? {
                            thumbnail: (<View style={{ height: 24, width: 24, borderRadius: 12, backgroundColor: aChip.backgroundColor }}/>),
                            width: 24,
                            rounded: true,
                        }
                        : undefined,
                    title: aChip.title,
                    groupId: aChip.groupId,
                    id: aChip.id ? aChip.id : aChip.title,
                });
            }
        }
        Dialog.instance?.open({
            openPosition,
            content: (<ChipDialogForm key="TagsChipDialogForm" title={I18n.m.getMessage('tags')} chipGroups={projectTagGroups} sortByGroup icon={{ icon: 'tag-multiple-outline', toolTip: '' }} items={listItems} onCancel={Dialog.instance.close} multiselect onSave={(selectedTags) => {
                    const newFilter = { ...currentFilter };
                    if (selectedTags != null && selectedTags.length > 0) {
                        newFilter.t = [];
                        selectedTags.forEach((t) => {
                            if (t.id != null && t.selected)
                                newFilter.t.push(t.id);
                        });
                    }
                    Dialog.instance?.close(() => {
                        setFilter(newFilter);
                    });
                }} canAddNewChips={false}/>),
            contentPadding: false,
            fullscreenResponsive: true,
            showCloseButton: false,
            showCloseIcon: false,
        });
    };
    asyncNow().catch((err) => console.error(err));
};
