"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeTracking = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var TimeTrackingEntity_1 = require("./entities/TimeTrackingEntity");
var TimeTracking = function (_ReadModelClientOffli) {
  function TimeTracking() {
    var _this;
    (0, _classCallCheck2.default)(this, TimeTracking);
    _this = _callSuper(this, TimeTracking, arguments);
    _this.exampleEntity = new TimeTrackingEntity_1.TimeTrackingEntity();
    _this.syncPriority = 1;
    _this.onEvents = [{
      eventName: 'TimeTrackingCreated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TimeTrackingChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TimeTrackingDeleted',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(TimeTracking, _ReadModelClientOffli);
  return (0, _createClass2.default)(TimeTracking, [{
    key: "onTimeTrackingCreated",
    value: function () {
      var _onTimeTrackingCreated = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = new TimeTrackingEntity_1.TimeTrackingEntity(yield this.defaultEventHandler(e, ent));
        entity.status = 'Running';
        if (entity.ends != null) entity.status = 'Recorded';
        entity.lastModifiedAt = e.createdAt;
        entity.createdBy = e.createdBy;
        try {
          if (e.data.projectId != null && e.data.projectId.length > 0) {
            var projects = ReadModels_1.ReadModels.get('project');
            var project = yield projects.getById(e.data.projectId);
            entity.projectTitle = project.title;
          }
        } catch (e) {}
        return entity;
      });
      function onTimeTrackingCreated(_x, _x2) {
        return _onTimeTrackingCreated.apply(this, arguments);
      }
      return onTimeTrackingCreated;
    }()
  }, {
    key: "onTimeTrackingChanged",
    value: function () {
      var _onTimeTrackingChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = new TimeTrackingEntity_1.TimeTrackingEntity(yield this.defaultEventHandler(e, ent));
        if (entity == null) return undefined;
        if (entity.status == null) entity.status = 'Running';
        if (entity.ends != null && entity.status === 'Running') entity.status = 'Recorded';
        try {
          if (e.data.projectId != null && e.data.projectId.length > 0) {
            var projects = ReadModels_1.ReadModels.get('project');
            var project = yield projects.getById(e.data.projectId);
            entity.projectTitle = project.title;
          } else if (entity.projectTitle != null && entity.projectTitle.length > 0 && (entity.projectId == null || entity.projectId.length === 0)) {
            entity.projectTitle = '';
          }
        } catch (e) {}
        entity.lastModifiedAt = e.createdAt;
        return entity;
      });
      function onTimeTrackingChanged(_x3, _x4) {
        return _onTimeTrackingChanged.apply(this, arguments);
      }
      return onTimeTrackingChanged;
    }()
  }, {
    key: "onTimeTrackingDeleted",
    value: function () {
      var _onTimeTrackingDeleted = (0, _asyncToGenerator2.default)(function* (e, ent) {
        ent.deleted = true;
        ent.lastModifiedAt = e.createdAt;
        return ent;
      });
      function onTimeTrackingDeleted(_x5, _x6) {
        return _onTimeTrackingDeleted.apply(this, arguments);
      }
      return onTimeTrackingDeleted;
    }()
  }]);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.TimeTracking = TimeTracking;