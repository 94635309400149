"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PayrollAccountingEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var PayrollAccountingEntity = function (_ReadModelEntity_1$Re) {
  function PayrollAccountingEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, PayrollAccountingEntity);
    _this = _callSuper(this, PayrollAccountingEntity, [obj]);
    _this.entityName = 'PayrollAccounting';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(PayrollAccountingEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(PayrollAccountingEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new PayrollAccountingEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('periodId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('memberId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('originId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('origin', new OdataEntity_1.OdataIndex(true));
      baseMap.set('day', new OdataEntity_1.OdataIndex(true));
      baseMap.set('from', new OdataEntity_1.OdataIndex(true));
      baseMap.set('to', new OdataEntity_1.OdataIndex(true));
      baseMap.set('pause', new OdataEntity_1.OdataIndex());
      baseMap.set('sumInMS', new OdataEntity_1.OdataIndex());
      baseMap.set('unit', new OdataEntity_1.OdataIndex());
      baseMap.set('value', new OdataEntity_1.OdataIndex());
      baseMap.set('notes', new OdataEntity_1.OdataIndex());
      baseMap.set('taskId', new OdataEntity_1.OdataIndex());
      baseMap.set('taskMark', new OdataEntity_1.OdataIndex(true));
      baseMap.set('taskDescription', new OdataEntity_1.OdataIndex());
      baseMap.set('wageType', new OdataEntity_1.OdataIndex());
      baseMap.set('followUpWageType1', new OdataEntity_1.OdataIndex());
      baseMap.set('followUpWageType2', new OdataEntity_1.OdataIndex());
      baseMap.set('costCenterId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('costCenterMark', new OdataEntity_1.OdataIndex(true));
      baseMap.set('costCenterDescription', new OdataEntity_1.OdataIndex());
      baseMap.set('extraFieldOrgValue', new OdataEntity_1.OdataIndex());
      baseMap.set('changes', new OdataEntity_1.OdataIndex());
      baseMap.set('exported', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.PayrollAccountingEntity = PayrollAccountingEntity;