import { Icon } from 'materialTheme/src/theme/components/Icon';
import { CacheableImage } from 'materialTheme/src/theme/components/images/CacheableImage';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { PlanZoomTileMapper } from './PlanZoomTileMapper';
export const PlanThumb169 = React.memo((props) => {
    const [toolTip, setToolTip] = useState('');
    const imageSize = {
        width: props.width,
        height: Math.round((props.width / 16) * 9),
    };
    const renderBadge = () => {
        const { badge } = props;
        if (badge != null && badge.length > 0) {
            return (<View key="planBadge" style={{
                    position: 'absolute',
                    top: -ThemeManager.style.getScreenRelativePixelSize(6),
                    right: -ThemeManager.style.getScreenRelativePixelSize(6),
                    backgroundColor: ThemeManager.style.brandPrimary,
                    borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                    minWidth: ThemeManager.style.getScreenRelativePixelSize(24),
                    height: ThemeManager.style.getScreenRelativePixelSize(24),
                    justifyContent: 'center',
                }}>
          <MaterialText centeredBox centeredText type={MaterialTextTypes.Caption} color="#FFFFFF">
            {badge}
          </MaterialText>
        </View>);
        }
        return null;
    };
    const renderTrashIcon = () => {
        const { deleteFunction } = props;
        if (deleteFunction != null) {
            return (<View key="trashIcon" style={{ position: 'absolute', top: 8, right: 8 }}>
          <Icon icon="delete-outline" onPress={deleteFunction} toolTip={I18n.m.getMessage('deletePlan')}/>
        </View>);
        }
        return <View />;
    };
    const renderDownloadIcon = () => {
        const { downloadFunction, fileSource } = props;
        if (downloadFunction != null && fileSource !== 'map')
            return (<View key="downloadIcon" style={{ position: 'absolute', top: 8, left: 8 }}>
          <Icon icon="download-outline" onPress={downloadFunction} toolTip={I18n.m.getMessage('downloadPlan')}/>
        </View>);
        return <View />;
    };
    const renderBottomRightIcon = () => {
        const { bottomRightIcon, selected } = props;
        if (bottomRightIcon == null)
            return <View />;
        return (<View style={{ height: 36, width: 36, position: 'absolute', right: 0, bottom: 0 }}>
        <Icon {...bottomRightIcon} color={selected === true ? '#FFFFFF' : bottomRightIcon.color}/>
      </View>);
    };
    const renderTitleOverlay = () => {
        const { title, selected, width, bottomRightIcon } = props;
        if (title == null || title.length === 0)
            return <View />;
        return (<View key="planOverlayTitle" style={{
                width,
                position: 'absolute',
                bottom: 0,
                backgroundColor: selected === true
                    ? ThemeManager.style.planThumbOverlayColorActive
                    : ThemeManager.style.planThumbOverlayColor,
                justifyContent: 'center',
                overflow: 'visible',
            }}>
        <View key="subContent" style={{
                padding: ThemeManager.style.getScreenRelativePixelSize(8),
                height: ThemeManager.style.getScreenRelativePixelSize(36),
                overflow: 'visible',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
          <View style={{
                alignSelf: 'center',
                alignItems: 'center',
                height: '100%',
                overflow: 'visible',
                width: '100%',
                paddingRight: bottomRightIcon != null ? 24 : 0,
            }}>
            <MaterialText type={MaterialTextTypes.Subtitle1} numberOfLines={1} fixedWidth="100%" color={selected === true
                ? ThemeManager.style.planThumbOverlayTextColorActive
                : ThemeManager.style.planThumbOverlayTextColor} onToolTipCreated={(t) => setToolTip(t)} showToolTipOnCutText centeredBox>
              {title}
            </MaterialText>
          </View>
        </View>
      </View>);
    };
    const renderImageAndIconsOverlay = () => {
        const { onPress, onPressData, fileSource, fileId, projectId, plan } = props;
        let view = (<View style={{ ...imageSize, alignItems: 'center', justifyContent: 'center' }}>
        <Icon toolTip="" icon="map-outline" iconSize={Math.min(imageSize.width / 2, imageSize.height / 2)}/>
      </View>);
        if (fileSource === 'map' && fileId === 'MAP' && plan != null) {
            view = (<View style={{ ...imageSize, alignItems: 'center', justifyContent: 'center' }}>
          <PlanZoomTileMapper plan={plan} type="map" url="map" planVersion={undefined} zoomAble={false}/>
        </View>);
        }
        else if (fileSource !== 'map' && fileId !== 'MAP') {
            const localSynced = OfflineDataDownloader.isPlanVersionSynced(projectId, fileId);
            const localSource = localSynced == null
                ? undefined
                : localSynced.urlTemplate.replace('{z}', '0').replace('{x}', '0').replace('{y}', '0');
            view = (<View style={{ ...imageSize, alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ width: imageSize.width * 2, height: imageSize.height * 2 }}>
            <CacheableImage resizeMode="contain" onlyOnConnectedServer={!(localSynced != null)} style={{ width: imageSize.width * 2, height: imageSize.height * 2 }} width={imageSize.width * 2} height={imageSize.height * 2} localSource={localSource} source={{ uri: `${UpmeshClient.instance.url}/plan/tiles/${projectId}/${fileSource}/${fileId}/0/0/0` }}/>
          </View>
        </View>);
        }
        view = (<View style={{ position: 'relative', overflow: 'visible' }}>
        {view}
        {renderTitleOverlay()}
      </View>);
        if (onPress != null) {
            view = (<Ripple key={`test_${toolTip}`} onPress={() => onPress(onPressData)} toolTip={toolTip} style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}>
          {view}
        </Ripple>);
        }
        view = (<View style={{ position: 'relative', overflow: 'visible' }}>
        {view}
        {renderTrashIcon()}
        {renderDownloadIcon()}
        {renderBottomRightIcon()}
      </View>);
        return view;
    };
    return (<View style={{
            width: props.width,
            height: imageSize.height,
            position: 'relative',
            overflow: 'visible',
        }}>
      <View style={{
            width: props.width,
            height: imageSize.height,
            position: 'relative',
            overflow: 'hidden',
            borderRadius: ThemeManager.style.borderRadius,
            borderWidth: props.noBorder ? 0 : ThemeManager.style.borderWidth,
            borderColor: props.selected === true
                ? ThemeManager.style.planThumbOverlayColorActive
                : ThemeManager.style.borderColor,
        }}>
        <View style={{
            ...imageSize,
            width: props.width,
            height: imageSize.height,
            position: 'absolute',
            backgroundColor: '#FFFFFF',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflow: 'visible',
        }}>
          {renderImageAndIconsOverlay()}
          {props.children}
        </View>
      </View>
      {renderBadge()}
    </View>);
});
