import color from 'color';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { FlatList, Platform, View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
import { CombinedExtraPayTrackingDialogsControl } from '../control/CombinedExtraPayTrackingDialogsControl';
import { TrackingMultiSelectControl } from '../control/TrackingMultiSelectControl';
import { openTimeTrackingDialog } from '../dialogs/openTimeTrackingDialog';
export function TimeTrackingList(props) {
    const [selectedIDs, setSelectedIDs] = useState(new Set());
    const [data, setData] = useState([]);
    useEffect(() => {
        const d = [];
        for (const e of props.data) {
            const type = e.type === 'ExtraPayTrackingEntity'
                ? 'extraPay'
                : e.orgEntry.absenceId
                    ? 'absence'
                    : 'timeTracking';
            let infoText = '';
            let infoText2 = '';
            if (type === 'timeTracking' && e.starts) {
                infoText = `${I18n.m.dateCurrent.getLocalTimeString(e.starts)} -${I18n.m.dateCurrent.getLocalTimeString(e.ends)}`;
                infoText2 = `${I18n.m.dateCurrent.localeDateString(e.starts)}`;
            }
            else if (type === 'extraPay') {
                infoText = e.extraFieldValue;
                infoText2 = `${I18n.m.dateCurrent.localeDateString(e.day)}`;
            }
            else if (type === 'absence' && e.starts && e.ends) {
                infoText = `${I18n.m.dateCurrent.getLocalTimeString(e.starts)} -${I18n.m.dateCurrent.getLocalTimeString(e.ends)}`;
                infoText2 = `${I18n.m.dateCurrent.localeDateString(e.starts)}`;
            }
            const i = {
                orgEntry: e,
                day: e.starts ? new Date(e.starts) : new Date(e.day),
                infoText,
                infoText2,
                type,
            };
            d.push(i);
        }
        d.sort((a, b) => b.day.getTime() - a.day.getTime());
        setData(d);
    }, [props.data]);
    const onMultiSelect = (e) => {
        setSelectedIDs(e);
        if (e.size > 0) {
            TrackingMultiSelectControl.openMultiselectHeader(e, props.data, () => setSelectedIDs(new Set()));
        }
        else {
            TrackingMultiSelectControl.closeMultiselectHeader();
        }
    };
    const toggleIdSelection = (id) => () => {
        const sIds = new Set(selectedIDs);
        if (sIds.has(id)) {
            sIds.delete(id);
        }
        else {
            sIds.add(id);
        }
        onMultiSelect(sIds);
    };
    const renderItem = ({ item }) => {
        const icon = item.type === 'extraPay'
            ? 'database-plus-outline'
            : item.type === 'absence'
                ? 'calendar-arrow-right'
                : 'timer-outline';
        const transparentColor = Platform.OS === 'android' ? '#FFFFFF01' : 'transparent';
        const selected = selectedIDs.has(item.orgEntry.id);
        const borderColor = selected ? ThemeManager.style.brandPrimary : transparentColor;
        const backgroundColor = selected ? color(ThemeManager.style.brandPrimary).alpha(0.16).toString() : undefined;
        const notSelectable = item.type === 'absence';
        let secondLine = `${item.orgEntry.taskName} | ${item.orgEntry.costCenterName}`;
        if (item.type === 'extraPay') {
            secondLine = `${item.orgEntry.extraPayTitle} | ${item.orgEntry.costCenterName}`;
        }
        return (<View style={{ height: 64, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{
                height: 48,
                width: '100%',
                maxWidth: 1024,
                alignSelf: 'center',
                backgroundColor: '#FFFFFF',
                overflow: 'visible',
            }}>
          <View style={{
                position: 'relative',
                height: 48,
                width: '100%',
                backgroundColor,
                borderColor,
                borderRadius: 6,
                borderStyle: 'solid',
            }}>
            <Ripple style={{ position: 'absolute', height: 48, left: 0, top: 0, right: 48 }} onPress={selectedIDs.size === 0 || notSelectable
                ? openTimeTrackingDialog({ entry: item.orgEntry })
                : toggleIdSelection(item.orgEntry.id)} onLongPress={notSelectable ? undefined : toggleIdSelection(item.orgEntry.id)}>
              <View style={{ position: 'absolute', width: 42, height: 42, left: 6, top: 6 }}>
                <UserImage user={item.orgEntry.user} size={36}/>
              </View>
              <View style={{ position: 'absolute', height: 48, left: 56, top: 6 }}>
                <MaterialText>{item.orgEntry.memberName}</MaterialText>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Icon icon={icon} toolTip="" iconSize={16} outerSize={16}/>
                  <MaterialText type={MaterialTextTypes.Body2} centeredText centeredBox>
                    {secondLine}
                  </MaterialText>
                </View>
              </View>
              <View style={{
                position: 'absolute',
                height: 36,
                top: 6,
                right: 0,
                justifyContent: 'center',
                alignItems: 'flex-end',
                paddingRight: 4,
            }}>
                <MaterialText type={MaterialTextTypes.Body2}>{item.infoText}</MaterialText>
                {item.infoText2 && <MaterialText type={MaterialTextTypes.Body2}>{item.infoText2}</MaterialText>}
              </View>
            </Ripple>
            <View style={{ position: 'absolute', height: 36, right: 6, top: 6, flexDirection: 'row', alignItems: 'center' }}>
              <Icon icon="dots-vertical" toolTip="" color={ThemeManager.style.defaultIconColor} onPress={CombinedExtraPayTrackingDialogsControl.openMenu(item.orgEntry)}/>
            </View>
          </View>
        </Card>
      </View>);
    };
    return (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ width: '100%', maxWidth: '100%', paddingHorizontal: 8 }}>
        <FlatList data={data} renderItem={renderItem} style={{ height: props.maxHeight }} keyExtractor={(item) => `${item.orgEntry.id}_${item.orgEntry.lastModifiedAt}_${selectedIDs.has(item.orgEntry.id) ? 'selected' : ''}`} extraData={Array.from(selectedIDs)}/>
      </View>
    </View>);
}
