import { Picker } from 'materialTheme/src/theme/components/Picker';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import UserContext from 'materialTheme/src/userContext';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ChangeUserSettings } from 'upmesh-auth-core/src/client/commands/user/ChangeUserSettings';
import { UserSettingsEntity } from 'upmesh-auth-core/src/client/query/entities/UserSettingsEntity';
import { I18n } from '../i18n/I18n';
import { DefaultErrorHandler } from './DefaultErrorHandler';
import { CompanyUserInfo } from './root/CompanyUserInfo';
export function UpmeshSettings(_props) {
    const userContext = useContext(UserContext);
    const getCurrentSettings = () => {
        if (userContext.settings.appSettings != null) {
            return userContext.settings.appSettings;
        }
        return {};
    };
    const onChangeCluster = (e) => {
        const newSettings = new UserSettingsEntity({
            ...userContext.settings,
            appSettings: { ...getCurrentSettings(), clusterMarker: e },
        });
        const c = new ChangeUserSettings(newSettings);
        c.execute(AuthClient.instance.commandStore).catch((e) => {
            console.debug('cant save settings');
            DefaultErrorHandler.showDefaultErrorAlert(e);
        });
    };
    const onChangeTickets = (e) => {
        const newSettings = new UserSettingsEntity({
            ...userContext.settings,
            appSettings: { ...getCurrentSettings(), openTicketsInSidePanel: e },
        });
        const c = new ChangeUserSettings(newSettings);
        c.execute(AuthClient.instance.commandStore).catch((e) => {
            console.debug('cant save settings');
            DefaultErrorHandler.showDefaultErrorAlert(e);
        });
    };
    const value = userContext.settings.appSettings == null || userContext.settings.appSettings['clusterMarker'] === true;
    const ticketsValue = userContext.settings.appSettings == null || userContext.settings.appSettings['openTicketsInSidePanel'] === true;
    const availableStartPages = StoredStartPages.getAvailableStartPages(CompanyUserInfo.companySettings?.hasModule('timeTracking') ?? false);
    const availableProjectStartPages = StoredStartPages.getAvailableProjectStartPages();
    const [cStart, setCStart] = useState(0);
    const [cPrStart, setCPrStart] = useState(0);
    useEffect(() => {
        const getProjectValue = StoredStartPages.getStoredProjectStartPage();
        const projectIndex = availableProjectStartPages.findIndex((curr) => curr.data.id === getProjectValue);
        setCPrStart(projectIndex >= 0 ? projectIndex : 0);
        const vStoredStartPages = StoredStartPages.getStoredStartPage();
        const index = availableStartPages.findIndex((curr) => curr.data.id === vStoredStartPages);
        setCStart(index >= 0 ? index : 0);
    }, []);
    const onChangeProjectStartPage = (e) => {
        StoredStartPages.storeProjectStartPage(e);
        setCPrStart(e);
    };
    const onChangeStartPage = (e) => {
        StoredStartPages.storeStartPage(e);
        setCStart(e);
    };
    return (<View style={{
            width: '100%',
            maxWidth: '100%',
            paddingHorizontal: 0,
        }}>
      <View style={{
            width: '100%',
            paddingVertical: 0,
            borderWidth: 0,
            borderColor: ThemeManager.style.borderColor,
            borderTopWidth: ThemeManager.style.borderWidth,
            marginTop: 4,
        }}>
        <View style={{ ...ThemeManager.style.contentPadding }}>
          <View style={{
            width: '100%',
            paddingVertical: 8,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}>
            <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
              <MaterialText fixedWidth="100%" centeredBox>
                {I18n.m.getMessage('settingsClusterMarker')}
              </MaterialText>
            </View>
            <View style={{ width: 56, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <Switch key={`clusterSettings_${value}`} value={value} onPress={onChangeCluster}/>
            </View>
          </View>
          <View style={{
            width: '100%',
            maxWidth: '100%',
            paddingVertical: 8,
            alignItems: 'center',
            flexDirection: 'row',
        }}>
            <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
              <MaterialText fixedWidth="100%" centeredBox>
                {I18n.m.getMessage('settingsTicketsInSidePanel')}
              </MaterialText>
            </View>
            <View style={{ width: 56, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <Switch key={`imageEditorSettings_${ticketsValue}`} value={ticketsValue} onPress={onChangeTickets}/>
            </View>
          </View>
        </View>
      </View>
      <View style={{
            width: '100%',
            paddingVertical: 0,
            borderWidth: 0,
            borderColor: ThemeManager.style.borderColor,
            borderTopWidth: ThemeManager.style.borderWidth,
            marginTop: 4,
        }}>
        <View style={{ ...ThemeManager.style.contentPadding }}>
          <View style={{ paddingBottom: 0 }}>
            <MaterialText strong>{I18n.m.getMessage('startHeader')}</MaterialText>
          </View>
          <View style={{
            width: '100%',
            maxWidth: '100%',
            paddingVertical: 8,
            alignItems: 'center',
            flexDirection: 'row',
        }}>
            <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
              <MaterialText fixedWidth="100%" centeredBox>
                {I18n.m.getMessage('startHeaderGeneral')}
              </MaterialText>
            </View>
            <View style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <Picker list={availableStartPages} selectedIndex={cStart} onChange={onChangeStartPage}/>
            </View>
          </View>
          <View style={{
            width: '100%',
            maxWidth: '100%',
            paddingVertical: 8,
            alignItems: 'center',
            flexDirection: 'row',
        }}>
            <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
              <MaterialText fixedWidth="100%" centeredBox>
                {I18n.m.getMessage('startHeaderProjects')}
              </MaterialText>
            </View>
            <View style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <Picker list={availableProjectStartPages} selectedIndex={cPrStart} onChange={onChangeProjectStartPage}/>
            </View>
          </View>
        </View>
      </View>
    </View>);
}
