import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Map } from 'materialTheme/src/theme/components/Map';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { Keyboard, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AddressEntity } from 'upmesh-auth-core/src/client/query/entities/AddressEntity';
import { CreateMap } from 'upmesh-core/src/client/commands/plans/CreateMap';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
export const AddMapPU = React.memo((props) => {
    const [mapName, setMapName] = useState('');
    const [address, setAddress] = useState(new AddressEntity({ latitude: 51.1139155, longitude: 9.9943952 }));
    const [inputValue, setInputValue] = useState('');
    const [zoomLevel, setZoomLevel] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const title = I18n.m.getMessage('addMapTitle');
    useEffect(() => {
        return () => {
            if (Keyboard != null)
                Keyboard.dismiss();
        };
    }, []);
    const onAddressChanged = (newAddress) => {
        const updatedAddress = {
            ...address,
            ...newAddress,
        };
        setAddress(new AddressEntity(updatedAddress));
        setZoomLevel(15);
        setInputValue(newAddress.addressInput != null ? newAddress.addressInput : '');
    };
    const onSave = (_e) => {
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (projectId == null)
            return;
        setIsLoading(true);
        const addMap = new CreateMap({
            projectId,
            address,
            title: mapName,
            activatedBy: CurrentUser.userId,
            activatedOn: new Date(),
        });
        addMap
            .execute()
            .then((_r) => {
            Routing.instance.goTo(`/projects/${projectId}/plans/${addMap.entityId}`, true);
        })
            .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    };
    const renderOfflineMessage = () => {
        return (<View style={{
                ...ThemeManager.style.contentPadding,
                paddingBottom: 4,
            }}>
        <MaterialText centeredText>{I18n.m.getMessage('commandOfflineNotPossible')}</MaterialText>
        <View style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <View>
            <ContainedButton onPress={Routing.instance.goBackButton} title={I18n.m.getMessage('ok')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          </View>
        </View>
      </View>);
    };
    const render = () => {
        const { size } = props;
        const s = size != null ? size : ResizeEvent.current;
        if (!AuthClient.instance.serverConnection.isConnected) {
            return renderOfflineMessage();
        }
        const height = s.windowWidth <= ThemeManager.style.breakpointM ? s.contentHeight - 128 : s.windowHeight * 0.9 - 196;
        return [
            <DialogTitle key="title">{title}</DialogTitle>,
            <DialogContent key="content">
        <FormInputFilled maxLength={40} labelText={I18n.m.getMessage('addMapMapTitle')} onChange={setMapName}/>
        <View style={{ position: 'relative', top: 0, width: '100%', height: height - 124 }} pointerEvents="box-none">
          <Map showAddressInput style={{ width: '100%', height: height - 124 }} address={address} allowScaling showCurrentLocation={false} zoomLevel={zoomLevel} showGoToCurrentLocationButton onChangeAddressInput={onAddressChanged}/>
        </View>
      </DialogContent>,
            <DialogActions onBottom key="buttons">
        <ContainedButton key="close" onPress={Routing.instance.closeDialog(true)} title={I18n.m.getMessage('close')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton key="save" loading={isLoading} disabled={mapName.length === 0 ||
                    inputValue.length === 0 ||
                    address.addressInput == null ||
                    address.addressInput.length === 0} onPress={onSave} title={I18n.m.getMessage('add')} backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF"/>
      </DialogActions>,
        ];
    };
    return <View>{render()}</View>;
});
