import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { SUPPORTED_EXPORTS, } from 'upmesh-core/src/server/webserver/commands/timetracking/ExportInformation';
import { I18n } from '../../../../i18n/I18n';
export function BRZOptions(props) {
    const [brzCompanyNumber, setBrzCompanyNumber] = useState('');
    useEffect(() => {
        const value = SimpleStorage.get(`timeTrackingOptions${SUPPORTED_EXPORTS.brz.short}`);
        if (value != null && value.length > 0) {
            const storedData = JSON.parse(value);
            setBrzCompanyNumber(storedData.brzCompanyNumber);
        }
    }, []);
    useEffect(() => {
        const data = SUPPORTED_EXPORTS.brz;
        data.additionalData.brzCompanyNumber = brzCompanyNumber;
        props.onChange(data);
    }, [brzCompanyNumber]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            SimpleStorage.set(`timeTrackingOptions${SUPPORTED_EXPORTS.brz.short}`, JSON.stringify(SUPPORTED_EXPORTS.brz.additionalData));
        }, 250);
        return () => {
            clearTimeout(timeout);
        };
    }, [brzCompanyNumber]);
    const brzCmpnyRegex = /^\d{1,6}$/;
    return (<View>
      <MaterialText>{I18n.m.getMessage('brzExportCompanyNumberNeeded')}</MaterialText>,
      <FormInputFilled labelText={I18n.m.getMessage('brzExportCompanyNumber')} onChange={setBrzCompanyNumber} numberOfLines={1} value={brzCompanyNumber}/>
      {!brzCmpnyRegex.test(brzCompanyNumber) ? (<MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage('brzExportCompanyInvalid', { error: brzCompanyNumber })}
        </MaterialText>) : null}
    </View>);
}
