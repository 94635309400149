import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialTextWithOutToolTip';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
export class ColorComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.getValue = () => {
            return this.state.color;
        };
        this.closeDialog = () => {
            Dialog.instance?.close();
        };
        this.onChanged = (color) => {
            this.setState({ color }, () => {
                const { onChange } = this.props;
                if (onChange != null) {
                    onChange(color);
                }
            });
        };
        this.state = {
            color: props.initColor != null ? props.initColor : props.propColors[0],
            title: props.title ?? I18n.m.getMessage('projectColor'),
        };
    }
    blur() { }
    clear() { }
    focus() { }
    getId() {
        return '';
    }
    setValue(color) {
        this.init(color).catch((err) => console.debug(err));
    }
    componentDidMount() {
        this.init(this.props.project?.color).catch((err) => console.debug(err));
        if (this.props.initColor != null) {
            this.setState({ color: this.props.initColor });
        }
    }
    componentDidUpdate(prevProps, _prevState) {
        if (this.props.initColor !== prevProps.initColor) {
            const usedColors = this.props.propColors ?? [];
            this.setState({ color: this.props.initColor ?? usedColors[0] });
        }
        if (this.props.title !== prevProps.initColor) {
            this.setState({ title: this.props.title ?? I18n.m.getMessage('projectColor') });
        }
    }
    async init(color) {
        const { propColors } = this.props;
        const usedColors = propColors ?? [];
        const index = color != null ? usedColors.indexOf(color) : -1;
        this.setState({ color: index > -1 && color != null ? color : usedColors[0] });
    }
    render() {
        const { propColors, title } = this.props;
        const { color } = this.state;
        const colorButtons = [];
        const usedColors = propColors ?? [];
        for (let i = 0; i < usedColors.length; i += 1) {
            const selected = usedColors[i] === color;
            colorButtons.push(<Ripple key={`colors_${usedColors[i]}`} style={{
                    height: 32,
                    width: 32,
                    borderRadius: 16,
                    borderColor: ThemeManager.style.black87,
                    borderWidth: usedColors[i] === 'transparent' ? ThemeManager.style.borderWidth : 0,
                    margin: 4,
                    backgroundColor: usedColors[i],
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                }} accessibilityLabel="Tag Color" onPress={(_e) => this.onChanged(usedColors[i])}>
          {usedColors[i] === 'transparent' && !selected ? (<View style={{
                        height: 32,
                        width: 2,
                        transform: [{ rotate: '45deg' }],
                        backgroundColor: ThemeManager.style.brandDanger,
                    }}/>) : null}
          {color === usedColors[i] ? (<View style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: 32,
                        width: 32,
                        borderRadius: 16,
                        backgroundColor: usedColors[i],
                    }}>
              <Icon toolTip="" icon="check" color={usedColors[i] === 'transparent' ? ThemeManager.style.defaultIconColor : '#ffffff'} outerSize={32}/>
            </View>) : null}
        </Ripple>);
        }
        return (<View style={{}}>
        <MaterialText>{title}</MaterialText>
        <View style={{ flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>{colorButtons}</View>
      </View>);
    }
}
ColorComponent.defaultProps = {
    propColors: [
        'transparent',
        '#0073ff',
        '#00c6ff',
        '#8360c3',
        '#0f9b0f',
        '#38ef7d',
        '#FFE000',
        '#F37335',
        '#FF4B2B',
        '#203A43',
        '#bdc3c7',
    ],
};
