"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PayrollAccountingPeriodsPerMemberEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var PayrollAccountingPeriodsPerMemberEntity = function (_ReadModelEntity_1$Re) {
  function PayrollAccountingPeriodsPerMemberEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, PayrollAccountingPeriodsPerMemberEntity);
    _this = _callSuper(this, PayrollAccountingPeriodsPerMemberEntity, [obj]);
    _this.entityName = 'PayrollAccountingPeriodsPerMember';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(PayrollAccountingPeriodsPerMemberEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(PayrollAccountingPeriodsPerMemberEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new PayrollAccountingPeriodsPerMemberEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('memberId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('periodId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('from', new OdataEntity_1.OdataIndex(true));
      baseMap.set('to', new OdataEntity_1.OdataIndex(true));
      baseMap.set('targetHoursInMs', new OdataEntity_1.OdataIndex());
      baseMap.set('hoursInMs', new OdataEntity_1.OdataIndex());
      baseMap.set('changes', new OdataEntity_1.OdataIndex());
      baseMap.set('problems', new OdataEntity_1.OdataIndex());
      baseMap.set('unPaidOverTimeInMs', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }], [{
    key: "convertToPayrollAccountingPeriodsPerMemberDetails",
    value: function () {
      var _convertToPayrollAccountingPeriodsPerMemberDetails = (0, _asyncToGenerator2.default)(function* (e) {
        var en = e;
        en.overtimeRestMonthBefore = 0;
        var rm = ReadModels_1.ReadModels.get('PayrollAccountingPeriodsPerMember');
        var before = yield rm.get({
          filter: `memberId eq '${e.memberId}' and companyId eq '${e.companyId}' and to eq ${new Date(e.from.getTime() - 1).toISOString()}`
        });
        if (before && before.length > 0) {
          var usersBefore = before.find(function (a) {
            return a.memberId === e.memberId;
          });
          if (usersBefore && usersBefore.unPaidOverTimeInMs) en.overtimeRestMonthBefore = usersBefore.unPaidOverTimeInMs;
        }
        en.overtime = e.hoursInMs - e.targetHoursInMs;
        en.overtimeTotal = e.hoursInMs - e.targetHoursInMs + en.overtimeRestMonthBefore;
        en.paidOverTimeInMs = en.overtimeTotal - e.unPaidOverTimeInMs;
        return en;
      });
      function convertToPayrollAccountingPeriodsPerMemberDetails(_x) {
        return _convertToPayrollAccountingPeriodsPerMemberDetails.apply(this, arguments);
      }
      return convertToPayrollAccountingPeriodsPerMemberDetails;
    }()
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.PayrollAccountingPeriodsPerMemberEntity = PayrollAccountingPeriodsPerMemberEntity;