import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { I18n } from '../../i18n/I18n';
import { LogBook } from '../logbook/LogBook';
import { TimeTrackingMainView } from '../timetracking/TimeTrackingMainView';
import { AllTicketsView } from './AllTicketsView';
import { CompanyUserInfo } from './CompanyUserInfo';
import DashboardView from './DashboardView';
import { GlobalBar } from './GlobalBar';
import { HomeProjectsView } from './HomeProjectsView';
export class HomeViewContent extends PureComponent {
    static getSiteIndex(s, t) {
        const tabs = {
            selectedTab: 0,
            selectedTimeTrackingTab: 0,
        };
        if (s === 'activities') {
            return { selectedTab: 5, selectedTimeTrackingTab: 0 };
        }
        if (s === 'timeTracking') {
            tabs.selectedTab = 3;
            if (t === 'timeTracking')
                tabs.selectedTimeTrackingTab = 1;
            else if (t === 'user')
                tabs.selectedTimeTrackingTab = 2;
            else if (t === 'approvals')
                tabs.selectedTimeTrackingTab = 3;
            else if (t === 'payroll')
                tabs.selectedTimeTrackingTab = 4;
            return tabs;
        }
        if (s === 'allTickets') {
            return { selectedTab: 2, selectedTimeTrackingTab: 0 };
        }
        if (s === 'dashboard') {
            return { selectedTab: 1, selectedTimeTrackingTab: 0 };
        }
        if (s === 'absences') {
            return { selectedTab: 4, selectedTimeTrackingTab: 0 };
        }
        return tabs;
    }
    static getDerivedStateFromProps(nextProps, nextSate) {
        const { selectedTab, selectedTimeTrackingTab } = HomeViewContent.getSiteIndex(nextProps.site, nextProps.pathvars['page']);
        if (nextSate.selectedTab !== selectedTab || nextSate.selectedTimeTrackingTab !== selectedTimeTrackingTab) {
            return { selectedTab, init: nextProps.init != null, selectedTimeTrackingTab };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.mounted = true;
        this.updateCompanySettings = (companySettings) => this.setState({ companySettings });
        this.initFilter = () => {
            if (this.props.init == null) {
                const timeTrackingModule = CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTracking');
                let ft;
                let fab;
                const f = SimpleStorage.get(`${CurrentUser.userId}_saved_projectfilter`);
                const fat = SimpleStorage.get(`${CurrentUser.userId}_saved_allticketsfilter`);
                if (timeTrackingModule) {
                    ft = SimpleStorage.get(`${CurrentUser.userId}_saved_timetrackingfilter2`);
                    fab = SimpleStorage.get(`${CurrentUser.userId}_saved_absencesfilter`);
                }
                const query = { ft, fab, fat, f, init: '1' };
                requestAnimationFrame(() => {
                    Routing.instance.changeQueryParameter(query);
                });
            }
        };
        this.backToPageOne = () => {
            Routing.instance.goTo('/projects');
        };
        this.changeTimeTrackingTab = (index, _e) => {
            if (index === 3) {
                Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/timeTracking/approvals'));
            }
            else if (index === 0) {
                const availableStartPages = StoredStartPages.getAvailableStartPages(CompanyUserInfo.companySettings?.hasModule('timeTracking') ?? false);
                const value = StoredStartPages.getStoredStartPage();
                const index = availableStartPages.findIndex((curr) => curr.data.id === value);
                const startPageSettings = availableStartPages[index];
                let link = 'projects';
                if (startPageSettings.data['id'] === 'dashboard') {
                    link = 'dashboard';
                }
                else if (startPageSettings.data['id'] === 'allTickets') {
                    link = 'allTickets';
                }
                Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter(`/${link}`));
            }
            else if (index === 1) {
                Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/timeTracking/timeTracking'));
            }
            else if (index === 2) {
                Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/timeTracking/user'));
            }
            else if (index === 4) {
                Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/timeTracking/payroll'));
            }
        };
        this.changeTab = (index, _e) => {
            this.setState({ selectedTab: index }, () => {
                if (index === 3) {
                    Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/timeTracking/timeTracking'));
                }
                else if (index === 4) {
                    Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/absences'));
                }
                else if (index === 2) {
                    Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/allTickets'));
                    if (Fab.isOpened) {
                        Fab.instance?.close();
                    }
                }
                else if (index === 1) {
                    Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/dashboard'));
                    if (Fab.isOpened) {
                        Fab.instance?.close();
                    }
                }
                else {
                    Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/projects'));
                    if (Fab.isOpened) {
                        Fab.instance?.close();
                    }
                }
            });
        };
        this.getItemKey = (item, _index) => `project_${item.id}`;
        const { selectedTab, selectedTimeTrackingTab } = HomeViewContent.getSiteIndex(props.site, props.pathvars['page']);
        this.state = {
            selectedTab,
            selectedTimeTrackingTab,
            companySettings: CompanyUserInfo.companySettings,
        };
    }
    componentDidMount() {
        this.mounted = true;
        CompanyUserInfo.companySettingsChanged.attach(this.updateCompanySettings);
        this.initFilter();
    }
    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        this.initFilter();
    }
    componentWillUnmount() {
        this.mounted = false;
        CompanyUserInfo.companySettingsChanged.detach(this.updateCompanySettings);
        if (Fab.instance != null)
            Fab.instance.close();
    }
    getTime() {
        return I18n.m.dateCurrent.getLocalTimeString(new Date());
    }
    getActivitiesView(_secondViewWidth, secondViewHeight) {
        return (<View style={{
                width: '100%',
                height: secondViewHeight + ThemeManager.style.headerHeight,
                backgroundColor: 'transparent',
                alignItems: 'center',
            }}>
        <LogBook height={secondViewHeight + ThemeManager.style.headerHeight}/>
      </View>);
    }
    renderProjectsView() {
        return <HomeProjectsView {...this.props}/>;
    }
    renderTabBar() {
        const { size } = this.props;
        const { selectedTab, companySettings, selectedTimeTrackingTab } = this.state;
        const sView = size.windowWidth <= ThemeManager.style.breakpointM;
        if (sView) {
            if (selectedTab === 3) {
                const availableStartPages = StoredStartPages.getAvailableStartPages(CompanyUserInfo.companySettings?.hasModule('timeTracking') ?? false);
                const value = StoredStartPages.getStoredStartPage();
                const index = availableStartPages.findIndex((curr) => curr.data.id === value);
                const startPageSettings = availableStartPages[index];
                let startPage = {
                    title: I18n.m.getMessage('projects'),
                    icon: 'office-building',
                    hideTitle: size.windowWidth < 600,
                    accessibilityLabel: 'projects',
                };
                if (startPageSettings.data['id'] === 'dashboard') {
                    startPage = {
                        title: I18n.m.getMessage('dashboard'),
                        icon: 'view-dashboard-outline',
                        hideTitle: size.windowWidth < 600,
                        accessibilityLabel: 'dashboard',
                    };
                }
                else if (startPageSettings.data['id'] === 'allTickets') {
                    startPage = {
                        title: I18n.m.getMessage('allTickets'),
                        icon: 'check-circle-outline',
                        hideTitle: size.windowWidth < 600,
                        accessibilityLabel: 'allTickets',
                    };
                }
                const tabs = [
                    startPage,
                    {
                        title: I18n.m.getMessage('timeTracking'),
                        icon: 'timer-outline',
                        hideTitle: size.windowWidth < 600,
                        accessibilityLabel: 'projects',
                    },
                    {
                        title: I18n.m.getMessage('collaborator'),
                        icon: 'account-multiple-outline',
                        hideTitle: size.windowWidth < 600,
                        accessibilityLabel: 'projects',
                    },
                    {
                        title: I18n.m.getMessage('approvalProcess'),
                        icon: 'check-decagram-outline',
                        hideTitle: size.windowWidth < 600,
                        accessibilityLabel: 'projects',
                    },
                ];
                if (CompanyUserInfo.me?.payroll) {
                    tabs.push({
                        title: I18n.m.getMessage('billing'),
                        icon: 'database-arrow-down-outline',
                        hideTitle: size.windowWidth < 600,
                        accessibilityLabel: 'projects',
                    });
                }
                return (<View key="Tabbar" style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        backgroundColor: '#000000',
                        height: ResizeEvent.current.contentBottom + 48,
                        width: ResizeEvent.current.windowWidth,
                    }}>
            <TabBar indicatorOnTop textColor="rgb(255, 255, 255)" unfocusedTextColor="rgba(255, 255, 255, 0.5)" contentHeight={0} width={ResizeEvent.current.windowWidth} onChangeSelected={this.changeTimeTrackingTab} selected={selectedTimeTrackingTab} upperCase={false} underlineColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" borderHeight={4} minItemWidth={ResizeEvent.current.windowWidth / tabs.length} textProps={{
                        fontSize: 12,
                    }} tabs={tabs}/>
          </View>);
            }
            const tabs = [
                {
                    title: I18n.m.getMessage('projects'),
                    icon: 'office-building',
                    hideTitle: size.windowWidth < 600,
                    accessibilityLabel: 'projects',
                },
                {
                    title: I18n.m.getMessage('dashboard'),
                    icon: 'view-dashboard-outline',
                    hideTitle: size.windowWidth < 600,
                    accessibilityLabel: 'dashboard',
                },
                {
                    title: I18n.m.getMessage('allTickets'),
                    icon: 'check-circle-outline',
                    hideTitle: size.windowWidth < 600,
                    accessibilityLabel: 'allTickets',
                },
            ];
            if (companySettings != null && companySettings.hasModule('timeTracking')) {
                tabs.push({
                    title: I18n.m.getMessage('timeTrackingShort'),
                    icon: 'timer-outline',
                    hideTitle: size.windowWidth < 600,
                    accessibilityLabel: 'timeTracking',
                });
            }
            return (<View key="Tabbar" style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    backgroundColor: '#000000',
                    height: ResizeEvent.current.contentBottom + 48,
                    width: ResizeEvent.current.windowWidth,
                }}>
          <TabBar indicatorOnTop textColor="rgb(255, 255, 255)" unfocusedTextColor="rgba(255, 255, 255, 0.5)" contentHeight={0} width={ResizeEvent.current.windowWidth} onChangeSelected={this.changeTab} selected={selectedTab} upperCase={false} underlineColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" borderHeight={4} minItemWidth={ResizeEvent.current.windowWidth / tabs.length} textProps={{
                    fontSize: 12,
                }} tabs={tabs}/>
        </View>);
        }
        return <View key="noTabbar"/>;
    }
    render() {
        const { contentHeight } = this.props.size;
        const { size, pathvars } = this.props;
        const { selectedTab, companySettings } = this.state;
        const sView = size.windowWidth <= ThemeManager.style.breakpointM;
        const menuHeight2 = !sView ? 0 : 48;
        const secondViewHeight = contentHeight - ThemeManager.style.headerHeight - menuHeight2;
        let visibleTab = <View />;
        if (selectedTab === 0) {
            visibleTab = this.renderProjectsView();
        }
        else if (selectedTab === 2) {
            visibleTab = <AllTicketsView {...this.props} goBackHome={this.backToPageOne} height={secondViewHeight}/>;
        }
        else if (selectedTab === 3) {
            visibleTab = (<TimeTrackingMainView size={size} pathvars={pathvars} ft={this.props.ft} companySettings={companySettings} height={secondViewHeight}/>);
        }
        else if (selectedTab === 1) {
            visibleTab = <DashboardView width={size.contentWidth} height={secondViewHeight} size={size}/>;
        }
        else if (selectedTab === 5) {
            visibleTab = this.getActivitiesView(size.windowWidth, secondViewHeight);
        }
        if (this.props.init == null)
            visibleTab = <Spinner />;
        return [
            <View key="HOMEVIEWCONTENT" style={{
                    position: 'absolute',
                    paddingTop: ResizeEvent.current.contentTop,
                    top: 0,
                    left: ResizeEvent.current.contentLeft,
                    right: ResizeEvent.current.contentRight,
                    bottom: ResizeEvent.current.contentBottom,
                    backgroundColor: 'rgba(0,0,0,0.2)',
                }}>
        <View style={{ height: contentHeight, width: ResizeEvent.current.contentWidth }}>{visibleTab}</View>
      </View>,
            this.renderTabBar(),
        ];
    }
}
HomeViewContent.defaultProps = {
    title: 'upmesh',
    showAllTickets: false,
};
