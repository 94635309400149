import { Card } from 'materialTheme/src/theme/components/Card';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Table } from 'materialTheme/src/theme/components/Table';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { GroupEntity } from 'upmesh-core/src/client/query/entities/GroupEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
import { openCompanyMemberDialog } from '../companies/member/openCompanyMemberDialog';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { CollaboratorThumb } from './elementcomponents/CollaboratorThumb';
const maxLicensesReachedImage = require('../../assets/img/maxLicensesReached.png');
export function CollaboratorsView(props) {
    const [collaborators, setCollaborators] = useState();
    const [updateCounter, setUpdateCounter] = useState(0);
    if (CompanyUserInfo.company == null)
        return null;
    const loadCollaborators = async () => {
        const { company } = CompanyUserInfo;
        if (company == null)
            return [];
        const c = [];
        const member = await UpmeshClient.instance.modals.companyMember.get({
            filter: `companyId eq '${company.id}' and deleted ne true and role ne 'inactive'`,
        });
        for (const m of member) {
            const co = m;
            if (co.userId != null)
                co.user = CachedEntities.knownUsers.get(co.userId);
            if (co.user != null && (co.firstName !== co.user.firstname || co.lastName !== co.user.lastname)) {
                co.firstName = co.user.firstname;
                co.lastName = co.user.lastname;
            }
            if (co.groupId != null) {
                const group = await UpmeshClient.instance.modals.group.getById(co.groupId);
                co.groupName =
                    group.groupName === GroupEntity.getDefaultName()
                        ? I18n.m.getMessage('companyGroupDefaultName')
                        : group.groupName;
            }
            co.timeTrackingStatus = I18n.m.getMessage('timeTrackingStatusLoggedOut');
            const t = await UpmeshClient.instance.modals.timeTracking.get({
                filter: `memberId eq '${co.id}' and ends eq null and deleted ne true`,
            });
            if (t.length > 0) {
                co.timeTrackingStatus = I18n.m.getMessage('timeTrackingStatusLoggedIn');
                if (t[0].taskId != null) {
                    try {
                        const task = await UpmeshClient.instance.modals.task.getById(t[0].taskId);
                        co.timeTrackingStatusExtended = task.getName();
                    }
                    catch (err) {
                        console.debug('cant get task');
                    }
                }
            }
            else {
                const today = new Date();
                const a = await UpmeshClient.instance.modals.absence.get({
                    filter: `memberId eq '${co.id}' and starts lt ${today.toISOString()} and ends gt ${today.toISOString()} and (isBlocked ne 'open' or isBlocked ne 'rejected')`,
                });
                if (a.length > 0) {
                    co.timeTrackingStatus = I18n.m.getMessage('timeTrackingStatusAbsent');
                    if (a[0].taskId != null) {
                        try {
                            const task = await UpmeshClient.instance.modals.task.getById(a[0].taskId);
                            co.timeTrackingStatusExtended = task.getName();
                        }
                        catch (err) {
                            console.debug('cant get task');
                        }
                    }
                }
            }
            c.push(co);
        }
        return c;
    };
    useEffect(() => {
        let mounted = true;
        loadCollaborators()
            .then((c) => {
            if (mounted)
                setCollaborators(c);
        })
            .catch(DefaultErrorHandler.showDefaultErrorAlert);
        return () => {
            mounted = false;
        };
    }, [updateCounter]);
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CollaboratorsView',
            readModelName: 'TimeTracking',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CollaboratorsViewAbsence',
            readModelName: 'Absence',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CollaboratorsViewMember',
            readModelName: 'CompanyMember',
            callback: (_e) => {
                setUpdateCounter(updateCounter + 1);
            },
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CollaboratorsViewUser',
            readModelName: 'User',
            callback: (_e) => {
                setUpdateCounter(updateCounter + 1);
            },
        });
        return () => {
            UpmeshClient.eventDispatcher.detach('TimeTracking', 'CollaboratorsView');
            UpmeshClient.eventDispatcher.detach('Absence', 'CollaboratorsViewAbsence');
            UpmeshClient.eventDispatcher.detach('CompanyMember', 'CollaboratorsViewMember');
            UpmeshClient.eventDispatcher.detach('User', 'CollaboratorsViewUser');
        };
    }, [updateCounter]);
    const renderStatusChip = (item, columnData, _index, sLayout) => {
        if (item == null)
            return <View />;
        const { style } = columnData;
        let title = item.timeTrackingStatus;
        if (item.timeTrackingStatusExtended != null && item.timeTrackingStatusExtended.length > 0) {
            title += ` (${item.timeTrackingStatusExtended})`;
        }
        let backgroundColor;
        let textColor;
        if (I18n.m.getMessage('timeTrackingStatusAbsent') === item.timeTrackingStatus) {
            backgroundColor = ThemeManager.style.brandDanger;
            textColor = '#FFFFFF';
        }
        else if (I18n.m.getMessage('timeTrackingStatusLoggedIn') === item.timeTrackingStatus) {
            backgroundColor = ThemeManager.style.brandSuccess;
            textColor = '#FFFFFF';
        }
        return (<View style={[
                {
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingRight: 16,
                    paddingLeft: 16,
                    flexDirection: 'row',
                    direction: sLayout ? 'rtl' : 'ltr',
                    overflow: 'hidden',
                    maxWidth: '100%',
                },
                style,
            ]} key={`ViewAroundThumb${item.id}}`}>
        <View style={{ paddingRight: sLayout ? 0 : 8, paddingLeft: sLayout ? 8 : 0, paddingVertical: 4 }}>
          <Chip title={title} backgroundColor={backgroundColor} textColor={textColor}/>
        </View>
      </View>);
    };
    return (<Card style={{ width: '100%', height: props.height - 16 }}>
      <Table actionItemsLength={0} onRowPress={(item, e) => {
            openCompanyMemberDialog({ memberId: item.id, currentList: collaborators != null ? collaborators : [] })(e);
        }} tableName="collaborator_timetracking" data={collaborators} emptyTableImage={maxLicensesReachedImage} emptyTableText="" emptyTableHint="" maxHeight={props.height - 16} columns={[
            {
                title: { icon: 'account-circle', toolTip: I18n.m.getMessage('userImage') },
                keyInData: 'firstName',
                style: { width: 64 },
                sortable: false,
                cellRenderer: (item, columnData, _index, sLayout) => (<CollaboratorThumb item={item} columnData={columnData} sLayout={sLayout}/>),
                dataType: 'string',
                minWidth: 64,
            },
            {
                title: I18n.m.getMessage('firstName'),
                keyInData: 'firstName',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('lastName'),
                keyInData: 'lastName',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('group'),
                keyInData: 'groupName',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('status'),
                keyInData: 'timeTrackingStatus',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                cellRenderer: renderStatusChip,
                minWidth: 180,
            },
        ]}/>
    </Card>);
}
