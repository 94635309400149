import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import React from 'react';
import { TimeTrackingDialog } from './TimeTrackingDialog';
export const openTimeTrackingDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        content: <TimeTrackingDialog {...props}/>,
        fullscreenResponsive: true,
        width: 768,
        contentPadding: false,
        openPosition,
        scrollable: false,
        openImmediately: false,
        closeOnTouchOutside: true,
        showCloseIcon: false,
    });
};
