import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import React from 'react';
import { ApprovalProcessDialog } from './ApprovalProcessDialog';
export const openApprovalProcessDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <ApprovalProcessDialog currentStep={props.currentStep} onType={props.onType} entityId={props.entityId}/>,
    });
};
