var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RAW_HEIGHT = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../i18n/I18n");
var _ThemeManager = require("../ThemeManager");
var _Icon = require("./Icon");
var _Menu = require("./Menu");
var _TableTypes = require("./TableTypes");
var _Measurement = require("./utils/Measurement");
var _Ripple = require("./utils/Ripple");
var _TableCell = require("./TableCell");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var RAW_HEIGHT = exports.RAW_HEIGHT = 56;
function TableRow(props) {
  var _useState = (0, _react.useState)(props.selectedRow),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    selected = _useState2[0],
    setSelected = _useState2[1];
  var item = props.item,
    multiselectEnabled = props.multiselectEnabled,
    actions = props.actions,
    onRowPress = props.onRowPress,
    lineHeight = props.lineHeight,
    isSelectable = props.isSelectable,
    actionWidth = props.actionWidth,
    selectedColumns = props.selectedColumns,
    columns = props.columns,
    minWidth = props.minWidth,
    scrollViewSize = props.scrollViewSize,
    columnWidth = props.columnWidth,
    rightPosition = props.rightPosition,
    backgroundColor = props.backgroundColor,
    rowKey = props.rowKey,
    order = props.order;
  (0, _react.useEffect)(function () {
    if (selected !== props.selectedRow) setSelected(props.selectedRow);
  }, [props.selectedRow]);
  var onMenuPress = function onMenuPress(a, item) {
    return function (e) {
      var _Menu$instance;
      a.onAction(item)(e);
      (_Menu$instance = _Menu.Menu.instance) == null || _Menu$instance.close();
    };
  };
  var openActionMenu = function openActionMenu(item, ignoreFromBeginning) {
    return function (e) {
      _Measurement.Measurement.measure(e.currentTarget).then(function (p) {
        var _Menu$instance2;
        var client = {
          height: p.height,
          width: p.width,
          x: p.pageX,
          y: p.pageY
        };
        var items = [];
        var actions2 = actions != null ? actions(item) : undefined;
        if (ignoreFromBeginning) actions2 == null || actions2.splice(0, ignoreFromBeginning);
        actions2 == null || actions2.forEach(function (a) {
          items.push({
            onPress: onMenuPress(a, item),
            thumbnail: {
              thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, a)),
              width: 40
            },
            text: a.toolTip,
            disabled: a.disabled
          });
        });
        (_Menu$instance2 = _Menu.Menu.instance) == null || _Menu$instance2.open({
          client: client,
          items: items,
          formInput: false
        });
      }).catch(function (err) {
        return void 0;
      });
    };
  };
  var onPressMultiSelectCheckbox = function onPressMultiSelectCheckbox(item) {
    return function () {
      setSelected(!selected);
      if (props.onPressMultiSelectCheckbox) {
        props.onPressMultiSelectCheckbox(item);
      }
    };
  };
  var isMultiselectable = isSelectable == null || isSelectable(item);
  var multiselectCheckbox = (0, _react.useMemo)(function () {
    return multiselectEnabled ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        height: '100%',
        justifyContent: 'center',
        width: 44,
        paddingHorizontal: 4
      },
      children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
        toolTip: "",
        icon: selected ? 'checkbox-marked-outline' : 'checkbox-blank-outline',
        color: selected ? _ThemeManager.ThemeManager.style.brandPrimary : _ThemeManager.ThemeManager.style.defaultIconColor,
        onPress: onPressMultiSelectCheckbox(item),
        disabled: !isMultiselectable
      }, `checkbox_${item.id}_${selected}`)
    }) : undefined;
  }, [selected, multiselectEnabled, onPressMultiSelectCheckbox]);
  var actionIcons = [];
  if (actions != null) {
    var actions2 = actions(item);
    if (minWidth != null && minWidth > scrollViewSize.width && actions2.length > 1) {
      actionIcons.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          backgroundColor: 'transparent',
          width: 44,
          height: '100%',
          paddingBottom: _ThemeManager.ThemeManager.style.borderWidth,
          borderWidth: 0,
          borderLeftWidth: 0,
          borderStyle: 'solid',
          borderColor: _ThemeManager.ThemeManager.style.borderColor
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 44,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: _I18n.I18n.m.getMessage('tableActions'),
            icon: "dots-vertical",
            onPress: openActionMenu(item),
            backgroundColor: backgroundColor
          }, `action_dots2_${item.id}`)
        }, `action_dots_${item.id}`)
      }, `action_dots_${item.id}`));
    } else if (actions2.length <= 3) {
      actions2 == null || actions2.forEach(function (a, i) {
        actionIcons.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 44,
            justifyContent: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, a, {
            onPress: a.onAction(item),
            backgroundColor: backgroundColor
          }), `action2_${item.id}_${a.toolTip}`)
        }, `action_${i}_${item.id}_${a.toolTip}`));
      });
    } else {
      for (var i = 0; i < 2; i += 1) {
        var a = actions2[i];
        actionIcons.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 44,
            justifyContent: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, a, {
            onPress: a.onAction(item),
            backgroundColor: backgroundColor
          }), `action2_${item.id}_${a.toolTip}`)
        }, `action_${i}_${item.id}_${a.toolTip}`));
      }
      actionIcons.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: 44,
          justifyContent: 'center'
        },
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          toolTip: _I18n.I18n.m.getMessage('tableActions'),
          onPress: openActionMenu(item, 2),
          icon: "dots-vertical",
          backgroundColor: backgroundColor
        }, `action2_${item.id}_dots_overflow`)
      }, `action_dots_overflow_${item.id}`));
    }
  }
  var row = [order.map(function (columnIndex) {
    var column = columns[columnIndex];
    if (!column) return null;
    var t = column.hiddenTitleForSettings != null ? column.hiddenTitleForSettings : typeof column.title === 'string' ? column.title : column.title.toolTip;
    if (selectedColumns == null || selectedColumns.includes(t)) {
      var cellWidth = columnWidth[columnIndex];
      var rendererdCell = column.cellRenderer ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: [column.style, {
          flex: 1,
          width: cellWidth
        }],
        children: column.cellRenderer(item, Object.assign({}, column, {
          style: [column.style, {
            flex: 1,
            width: cellWidth
          }]
        }), columnIndex, false)
      }) : (0, _jsxRuntime.jsx)(_TableCell.TableCell, {
        item: item,
        column: Object.assign({}, column, {
          style: [column.style, {
            flex: 1,
            width: cellWidth
          }]
        }),
        index: columnIndex,
        sLayout: false,
        lineHeight: lineHeight
      });
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: cellWidth
        },
        children: rendererdCell
      }, `cell_${column.keyInData.toString()}_${item.id}_${columnIndex}`);
    }
    return null;
  })];
  var actionsRightPosition = rightPosition;
  var content = (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: [{
      height: lineHeight,
      minHeight: RAW_HEIGHT,
      backgroundColor: backgroundColor
    }, _TableTypes.styles.row],
    children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: multiselectCheckbox == null ? 0 : 4,
        width: multiselectCheckbox == null ? 0 : 44,
        height: 'auto'
      }
    }), row, (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: actionWidth > 0 ? 8 : 0,
        width: actionWidth,
        height: 'auto'
      }
    })]
  });
  if (onRowPress == null) {
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        minHeight: RAW_HEIGHT,
        height: lineHeight,
        width: '100%',
        position: 'relative',
        flexDirection: 'row',
        backgroundColor: backgroundColor
      },
      children: [content, (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          left: 5,
          height: lineHeight,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: multiselectCheckbox == null ? 0 : 4,
          width: multiselectCheckbox == null ? 0 : 44
        },
        children: multiselectCheckbox
      }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          right: actionsRightPosition,
          minHeight: RAW_HEIGHT,
          height: lineHeight,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingHorizontal: 0,
          width: actionWidth
        },
        children: actionIcons
      })]
    }, `tableRow${rowKey}`);
  }
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: {
      height: lineHeight,
      width: '100%',
      position: 'relative',
      flexDirection: 'row',
      backgroundColor: backgroundColor
    },
    children: [(0, _jsxRuntime.jsx)(_Ripple.Ripple, {
      onPress: function onPress(e) {
        if (onRowPress && item) onRowPress(item, e);
      },
      style: {
        height: lineHeight,
        width: '100%'
      },
      onLongPress: multiselectEnabled === true && isMultiselectable ? onPressMultiSelectCheckbox(item) : undefined,
      disabled: onRowPress == null,
      children: content
    }, `tableRowRipple${rowKey}`), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        position: 'absolute',
        left: 5,
        height: lineHeight,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: multiselectCheckbox == null ? 0 : 4,
        width: multiselectCheckbox == null ? 0 : 44
      },
      children: multiselectCheckbox
    }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        position: 'absolute',
        right: actionsRightPosition,
        height: lineHeight,
        minHeight: RAW_HEIGHT,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingHorizontal: 0,
        width: actionWidth
      },
      children: actionIcons
    })]
  }, `tableRow${rowKey}`);
}
var _default = exports.default = _react.default.memo(TableRow);