import { CacheableImage } from 'materialTheme/src/theme/components/images/CacheableImage';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { Config } from '../../config/Config';
export const PlanUploadedThumbs = React.memo((props) => {
    const getBigSource = () => {
        const headers = {};
        headers['authorization'] = `Bearer ${CurrentUser.token}`;
        return {
            headers,
            uri: `${Config.b2cURL}/plan/thumb/temp/${props.fileId}/`,
        };
    };
    const getIsRotated = () => props.rotation === 90 || props.rotation === 270;
    return (<View style={{
            height: props.imageHeight,
            width: props.imageWidth,
            margin: props.margin != null && props.margin ? 16 : 0,
            padding: 0,
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignContent: 'center',
            alignItems: 'center',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: ThemeManager.style.borderColor,
            borderRadius: ThemeManager.style.borderRadius,
            overflow: 'hidden',
        }}>
        <CacheableImage showLoading lightBox style={{
            transform: props.rotation != null ? [{ rotate: `${props.rotation}deg` }] : undefined,
            overflow: 'hidden',
        }} width={getIsRotated() ? props.imageHeight : props.imageWidth} height={getIsRotated() ? props.imageWidth : props.imageHeight} source={getBigSource()} resizeMode="contain"/>
      </View>);
});
