"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AbsenceEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var AbsenceEntity = function (_ReadModelEntity_1$Re) {
  function AbsenceEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, AbsenceEntity);
    _this = _callSuper(this, AbsenceEntity, [obj]);
    _this.entityName = 'Absence';
    _this.deleted = false;
    _this.state = 'open';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(AbsenceEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(AbsenceEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new AbsenceEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('memberId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('userId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('starts', new OdataEntity_1.OdataIndex(true));
      baseMap.set('ends', new OdataEntity_1.OdataIndex(true));
      baseMap.set('note', new OdataEntity_1.OdataIndex());
      baseMap.set('taskId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('costCenterId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('state', new OdataEntity_1.OdataIndex(true));
      baseMap.set('approvedBy', new OdataEntity_1.OdataIndex(true));
      baseMap.set('foreignId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      baseMap.set('nextApprovalStepFor', new OdataEntity_1.OdataIndex(true));
      baseMap.set('approvalProcessId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('currentApprovalProcessStep', new OdataEntity_1.OdataIndex(true));
      baseMap.set('isBlocked', new OdataEntity_1.OdataIndex(true));
      baseMap.set('approvals', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }, {
    key: "getDayDiff",
    value: function getDayDiff() {
      if (!(this.starts instanceof Date)) this.starts = new Date(this.starts);
      if (!(this.ends instanceof Date)) this.ends = new Date(this.ends);
      var _MS_PER_DAY = 1000 * 60 * 60 * 24;
      var utc1 = Date.UTC(this.starts.getFullYear(), this.starts.getMonth(), this.starts.getDate());
      var utc2 = Date.UTC(this.ends.getFullYear(), this.ends.getMonth(), this.ends.getDate() + 1);
      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.AbsenceEntity = AbsenceEntity;