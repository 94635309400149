import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { SUPPORTED_EXPORTS, } from 'upmesh-core/src/server/webserver/commands/timetracking/ExportInformation';
import { I18n } from '../../../i18n/I18n';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
import { AddisonOptions } from './options/AddisonOptions';
import { BRZOptions } from './options/BRZOptions';
import { DatevLodasOptions } from './options/DatevLodasOptions';
import { DatevLohnOptions } from './options/DatevLohnOptions';
import { NovalineOptions } from './options/NovalineOptions';
import { TimeTrackingDownload } from './TimeTrackingDownload';
const calcExportOptions = () => {
    const result = [];
    for (const key in SUPPORTED_EXPORTS) {
        const exportType = SUPPORTED_EXPORTS[key];
        result.push(I18n.m.getMessage(exportType.titleKey));
    }
    return result;
};
export function TimeTrackingExportDialog(props) {
    const payroll = !!CompanyUserInfo.me?.payroll;
    const [exportLock, setExportLock] = useState(!payroll ? false : !!SimpleStorage.get('timeTrackingExportLastExportLock'));
    const [includeExported, setIncludeExported] = useState(!payroll ? true : !!SimpleStorage.get('timeTrackingExportLastIncludeExported'));
    const [includeAbsence, setIncludeAbsence] = useState(!!SimpleStorage.get('timeTrackingExportLastIncludeAbsence'));
    const lastSeletcedIndex = SimpleStorage.get('timeTrackingExportLastType');
    const [selectedIndex, setSelectedIndex] = useState(lastSeletcedIndex != null && Number.parseInt(lastSeletcedIndex, 10) > 0
        ? Number.parseInt(lastSeletcedIndex, 10)
        : 0);
    const [numberOfExportEntries, setNumberOfExportEntries] = useState(0);
    const [warnings, setWarnings] = useState([]);
    const exportOptions = useMemo(() => calcExportOptions(), []);
    const exportInformationRef = useRef(null);
    useEffect(() => {
        SimpleStorage.set('timeTrackingExportLastExportLock', exportLock ? '1' : '');
    }, [exportLock]);
    useEffect(() => {
        SimpleStorage.set('timeTrackingExportLastIncludeExported', includeExported ? '1' : '');
    }, [includeExported]);
    useEffect(() => {
        SimpleStorage.set('timeTrackingExportLastIncludeAbsence', includeAbsence ? '1' : '');
    }, [includeAbsence]);
    const countEntries = async (options) => {
        const { periodId, includeExported, memberId, includeAbsences } = options;
        let filter = `periodId eq '${periodId}'`;
        if (memberId != null)
            filter += ` and memberId eq '${memberId}'`;
        if (!includeExported)
            filter += ` and exported ne true`;
        if (!includeAbsences)
            filter += ` and origin ne 'absence'`;
        return UpmeshClient.instance.modals.payrollAccounting.count(filter);
    };
    useEffect(() => {
        countEntries({ periodId: props.periodId, includeAbsences: includeAbsence, memberId: props.memberId })
            .then((a) => {
            setNumberOfExportEntries(a);
        })
            .catch(console.error);
    }, [includeAbsence, includeExported]);
    const download = () => {
        const asyncNow = async () => {
            const tmpExportInformation = exportInformationRef.current ?? SUPPORTED_EXPORTS.csv;
            tmpExportInformation.periodId = props.periodId;
            tmpExportInformation.includeAbsences = includeAbsence;
            tmpExportInformation.memberId = props.memberId;
            tmpExportInformation.includeAbsences = includeAbsence;
            tmpExportInformation.includeExported = includeExported;
            const tmpWarn = await TimeTrackingDownload.downloadCSV(exportLock, tmpExportInformation);
            if (tmpWarn.length === 0) {
                Dialog.instance?.close();
            }
            setWarnings(tmpWarn);
        };
        asyncNow().catch((err) => console.error(err));
    };
    const onChangeExtraData = (data) => {
        if (data != null) {
            exportInformationRef.current = data;
        }
    };
    const renderExtraOptions = () => {
        switch (selectedIndex) {
            case 0:
                exportInformationRef.current = SUPPORTED_EXPORTS.csv;
                return null;
            case 1:
                return <BRZOptions onChange={onChangeExtraData}/>;
            case 2:
                return <AddisonOptions onChange={onChangeExtraData}/>;
            case 3:
                exportInformationRef.current = SUPPORTED_EXPORTS.gdi;
                return null;
            case 4:
                return <NovalineOptions onChange={onChangeExtraData}/>;
            case 5:
                return <DatevLodasOptions onChange={onChangeExtraData}/>;
            case 6:
                return <DatevLohnOptions onChange={onChangeExtraData}/>;
            case 7:
                exportInformationRef.current = SUPPORTED_EXPORTS.vrg;
                return null;
            default:
                return null;
        }
    };
    const renderWarnings = () => {
        if (warnings.length === 0)
            return [];
        const result = [];
        result.push(<MaterialText key="timeTrackingWarningHelper">{I18n.m.getMessage('timeTrackingExportWarningText')}</MaterialText>);
        for (const warning of warnings) {
            result.push(<MaterialText key={`timeTrackingWarnings_${warning.messageCode.toString()}`} type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage(warning.messageCode, { error: warning.messageData['error'] })}
        </MaterialText>);
        }
        return result;
    };
    return (<>
      <DialogTitle>{I18n.m.getMessage('timeTrackingExportTitle')}</DialogTitle>
      <DialogContent>
        <View>
          <MaterialText>
            {I18n.m.getMessage('timeTrackingCSVexportText', { count: numberOfExportEntries })}
          </MaterialText>
          {!payroll ? null : (<View style={{
                width: '100%',
            }}>
              <View style={{
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingVertical: 8,
            }}>
                <View style={{ width: '100%', flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                  <MaterialText fixedWidth="100%" centeredBox>
                    {I18n.m.getMessage('timeTrackingCSVexportLockText')}
                  </MaterialText>
                </View>
                <View style={{ width: 56, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  <Switch value={exportLock} onPress={setExportLock}/>
                </View>
              </View>

              <View style={{
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingVertical: 8,
            }}>
                <View style={{ width: '100%', flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                  <MaterialText fixedWidth="100%" centeredBox>
                    {I18n.m.getMessage('timeTrackingCSVexportInlcudeExportedText')}
                  </MaterialText>
                </View>
                <View style={{ width: 56, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  <Switch value={includeExported} onPress={setIncludeExported}/>
                </View>
              </View>
            </View>)}
          <View style={{
            width: '100%',
            paddingVertical: 8,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}>
            <View style={{ width: '100%', flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
              <MaterialText fixedWidth="100%" centeredBox>
                {I18n.m.getMessage('timeTrackingCSVexportIncludeAbsence')}
              </MaterialText>
            </View>
            <View style={{ width: 56, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <Switch value={includeAbsence} onPress={(value) => {
            setIncludeAbsence(value);
        }}/>
            </View>
          </View>
          <FormInputPicker list={exportOptions} labelText={I18n.m.getMessage('timeTrackingExportType')} selectedIndex={selectedIndex} onChange={(selected) => {
            SimpleStorage.set('timeTrackingExportLastType', selected.index.toString(10));
            setSelectedIndex(selected.index);
        }}/>
          {renderExtraOptions()}
          {renderWarnings()}
        </View>
      </DialogContent>
      <DialogActions>
        <ContainedButton title={I18n.m.getMessage('cancel')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={() => Dialog.instance?.close()}/>
        <ContainedButton disabled={numberOfExportEntries === 0} title={I18n.m.getMessage('timeTrackingCSVexportButtonExport')} onPress={download}/>
      </DialogActions>
    </>);
}
export const openTimeTrackingExportDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        content: <TimeTrackingExportDialog periodId={props.periodId}/>,
        showCloseIcon: false,
        showCloseButton: false,
        contentPadding: false,
    });
};
