import ConnectionContext from 'materialTheme/src/connectionContext';
import { Card } from 'materialTheme/src/theme/components/Card';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useContext } from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
import { PayrollViewMemberDetailsview } from './PayrollViewMemberDetailsview';
import { PayrollViewMemberview } from './PayrollViewMemberview';
import { PayrollViewOverview } from './PayrollViewOverview';
export function PayrollView(props) {
    const connection = useContext(ConnectionContext);
    const sView = props.size.windowWidth <= ThemeManager.style.breakpointM;
    const maxHeight = sView ? props.size.contentHeight : props.size.contentHeight - 48;
    if (!connection.connectedToServer) {
        return (<View style={{ width: '100%', maxWidth: '100%', paddingHorizontal: 8 }}>
        <Card outerPadding={8} style={{ height: maxHeight - 16 }}>
          <View style={{ width: '100%', padding: 8, height: maxHeight - 16, justifyContent: 'center' }}>
            <MaterialText centeredText centeredBox fixedWidth="100%">
              {I18n.m.getMessage('offlineNotAvailable')}
            </MaterialText>
          </View>
        </Card>
      </View>);
    }
    return (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      {props.memberId && props.periodId ? (<PayrollViewMemberDetailsview periodId={props.periodId} maxHeight={maxHeight} memberId={props.memberId}/>) : props.periodId ? (<PayrollViewMemberview periodId={props.periodId} maxHeight={maxHeight}/>) : (<PayrollViewOverview maxHeight={maxHeight}/>)}
    </View>);
}
