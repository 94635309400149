import { MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextEditableV2 } from 'materialTheme/src/theme/components/text/MaterialTextEditableV2';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ChangeTicketDescription, } from 'upmesh-core/src/client/commands/tickets/ChangeTicketDescription';
import { I18n } from '../../../i18n/I18n';
export class TicketDescriptionInput extends PureComponent {
    constructor(props) {
        super(props);
        this.changedDescription = (text) => {
            const { errorResult } = this.props;
            const { ticket } = this.props;
            const onErrorDesc = ticket.description;
            const description = text;
            ticket.description = text;
            const c = new ChangeTicketDescription({ description }, ticket.id);
            c.execute().catch((e) => {
                ticket.description = onErrorDesc;
                this.setState({ ticket });
                errorResult(e);
            });
        };
        this.state = { allowedToChangeDescription: false, ticket: props.ticket };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (JSON.stringify(prevState.ticket) !== JSON.stringify(nextProps.ticket)) {
            return { ticket: nextProps.ticket };
        }
        return null;
    }
    componentDidMount() {
        this.getDisabled()
            .then((state) => {
            this.setState(state);
        })
            .catch((err) => console.debug('cant check setDisabled ', err));
    }
    render() {
        const gap = 16;
        const { ticket } = this.state;
        const { allowedToChangeDescription } = this.state;
        const description = ticket.description != null && ticket.description.length > 0
            ? ticket.description
            : allowedToChangeDescription
                ? ''
                : I18n.m.getMessage('ticketsDetailsNoDescription');
        return (<View style={{ overflow: 'visible' }}>
        <View style={{ height: gap }}/>
        <MaterialTextEditableV2 toolTip={I18n.m.getMessage('tooltipEditTicketDescription')} key={`desc_${allowedToChangeDescription}_${ticket.lastModifiedAt.toISOString()}`} materailTextProps={{ type: MaterialTextTypes.Body1, recognizeUrl: true }} onChanged={this.changedDescription} placeholder={I18n.m.getMessage('ticketsDetailsAddDescription')} blurOnSubmit={false} editable={allowedToChangeDescription} scrollContainerId="ticketDetails">
          {description}
        </MaterialTextEditableV2>
      </View>);
    }
    async checkDisabledDesc() {
        const { ticket } = this.props;
        const cDesc = new ChangeTicketDescription({}, ticket.id);
        try {
            const canIDesc = await cDesc.canI();
            return !(typeof canIDesc === 'boolean' && canIDesc);
        }
        catch (e) {
            return true;
        }
    }
    async getDisabled() {
        const allowedToChangeDescription = !(await this.checkDisabledDesc());
        return { allowedToChangeDescription };
    }
}
