"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderedApprovalProcess = void 0;
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ApprovalProcessEntity_1 = require("../ApprovalProcessEntity");
var OrderedApprovalProcess = function (_ApprovalProcessEntit) {
  function OrderedApprovalProcess() {
    (0, _classCallCheck2.default)(this, OrderedApprovalProcess);
    return _callSuper(this, OrderedApprovalProcess, arguments);
  }
  (0, _inherits2.default)(OrderedApprovalProcess, _ApprovalProcessEntit);
  return (0, _createClass2.default)(OrderedApprovalProcess, null, [{
    key: "getApprovalProcessStep",
    value: function () {
      var _getApprovalProcessStep = (0, _asyncToGenerator2.default)(function* (process, memberId, stepNumber) {
        if (process && process.approvalSteps.length > stepNumber) {
          var step = process.approvalSteps[stepNumber];
          var nextApprovalStepFor = [];
          if (step.who === 'member' && step.whoExtra && step.whoExtra.length > 0) {
            var member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(step.whoExtra);
            if (member.userId) nextApprovalStepFor.push({
              userId: member.userId,
              canEditInDays: step.canEditInDays
            });
          } else if (step.who === 'leadership') {
            var _member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(memberId);
            if (_member.leadership) {
              var leader = yield CommandReadModels_1.CommandReadModels.companyMember.getById(_member.leadership);
              if (leader.userId) nextApprovalStepFor.push({
                userId: leader.userId,
                canEditInDays: step.canEditInDays
              });
            }
          } else if (step.who === 'leadershipOfLeadership') {
            var _member2 = yield CommandReadModels_1.CommandReadModels.companyMember.getById(memberId);
            if (_member2.leadership) {
              var _leader = yield CommandReadModels_1.CommandReadModels.companyMember.getById(_member2.leadership);
              if (_leader.leadership) {
                var leaderOfLeader = yield CommandReadModels_1.CommandReadModels.companyMember.getById(_leader.leadership);
                if (leaderOfLeader.userId) nextApprovalStepFor.push({
                  userId: leaderOfLeader.userId,
                  canEditInDays: step.canEditInDays
                });
              }
            }
          } else if (step.who === 'payroll') {
            var _member3 = yield CommandReadModels_1.CommandReadModels.companyMember.get({
              filter: `companyId eq '${process.companyId}' and payroll eq true and deleted ne true`
            });
            for (var m of _member3) {
              if (m.userId) nextApprovalStepFor.push({
                userId: m.userId
              });
            }
          }
          if (nextApprovalStepFor.length > 0) {
            return {
              approvalProcessId: process.id,
              currentApprovalProcessStep: stepNumber,
              isBlocked: 'open',
              nextApprovalStepFor: nextApprovalStepFor
            };
          }
          if (process.approvalSteps.length > stepNumber) {
            return OrderedApprovalProcess.getApprovalProcessStep(process, memberId, stepNumber + 1);
          }
        }
        return {
          isBlocked: null,
          nextApprovalStepFor: null,
          currentApprovalProcessStep: stepNumber
        };
      });
      function getApprovalProcessStep(_x, _x2, _x3) {
        return _getApprovalProcessStep.apply(this, arguments);
      }
      return getApprovalProcessStep;
    }()
  }, {
    key: "getInitialApprovalProcessStep",
    value: function () {
      var _getInitialApprovalProcessStep = (0, _asyncToGenerator2.default)(function* (forType, companyId, memberId, groupId, forTaskOrExtraPay) {
        var processes = yield CommandReadModels_1.CommandReadModels.approvalProcess.get({
          filter: `companyId eq '${companyId}' and deleted ne true and active eq true`
        });
        var orders = OrderedApprovalProcess.orderApprovals(processes);
        var process = orders.find(function (a) {
          if ((a.forType === 'all' || a.forType === forType) && (a.validFor === 'all' || a.validFor === 'group' && a.validForExtra && a.validForExtra.findIndex(function (a) {
            return a['groupId'] && a['groupId'] === groupId;
          }) > -1 || a.validFor === 'members' && a.validForExtra && a.validForExtra.findIndex(function (a) {
            return a['memberId'] && a['memberId'] === memberId;
          }) > -1) && (a.forTaskOrExtraPay == null || a.forTaskOrExtraPay === forTaskOrExtraPay)) {
            return true;
          }
          return false;
        });
        if (process) {
          var s = yield OrderedApprovalProcess.getApprovalProcessStep(process, memberId, 0);
          return Object.assign({}, s, {
            approvals: []
          });
        }
        return {
          approvals: [],
          approvalProcessId: null,
          currentApprovalProcessStep: null,
          nextApprovalStepFor: null,
          isBlocked: null
        };
      });
      function getInitialApprovalProcessStep(_x4, _x5, _x6, _x7, _x8) {
        return _getInitialApprovalProcessStep.apply(this, arguments);
      }
      return getInitialApprovalProcessStep;
    }()
  }, {
    key: "orderApprovals",
    value: function orderApprovals(a) {
      var all = [];
      var absences = [];
      var timeTrackings = [];
      var extraPay = [];
      var pushToList = function pushToList(aproval) {
        if (aproval.forType === 'all') all.push(aproval);else if (aproval.forType === 'absence') absences.push(aproval);else if (aproval.forType === 'extraPay') extraPay.push(aproval);else if (aproval.forType === 'timeTracking') timeTrackings.push(aproval);
      };
      a.forEach(function (approval, i) {
        if (approval.validFor === 'members') {
          pushToList(approval);
          a.splice(i, 1);
        }
      });
      a.forEach(function (approval, i) {
        if (approval.validFor === 'group') {
          pushToList(approval);
          a.splice(i, 1);
        }
      });
      a.forEach(function (approval, i) {
        if (approval.forTaskOrExtraPay) {
          pushToList(approval);
          a.splice(i, 1);
        }
      });
      a.forEach(function (approval, i) {
        if (approval.active) {
          pushToList(approval);
          a.splice(i, 1);
        }
      });
      a.forEach(function (approval, i) {
        if (!approval.active) {
          pushToList(approval);
          a.splice(i, 1);
        }
      });
      var b = [].concat(absences, timeTrackings, extraPay, all);
      var o = [];
      for (var i = 0; i < b.length; i += 1) {
        var c = b[i];
        c.order = i;
        o.push(c);
      }
      return o;
    }
  }]);
}(ApprovalProcessEntity_1.ApprovalProcessEntity);
exports.OrderedApprovalProcess = OrderedApprovalProcess;