import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { TicketStatus, TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UnArchiveTicket } from 'upmesh-core/src/client/commands/tickets/UnArchiveTicket';
import { ChangeTicketStatus } from 'upmesh-core/src/client/commands/tickets/ChangeTicketStatus';
import { ArchiveTicket } from 'upmesh-core/src/client/commands/tickets/ArchiveTicket';
import ImagePickerComp from 'materialTheme/src/file/ImagePicker';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export const TicketDetailsBottomBar = React.memo((props) => {
    const [hasCam, setHasCam] = useState(false);
    useEffect(() => {
        ImagePickerComp.hasCamera()
            .then((result) => {
            if (result === true) {
                setHasCam(true);
            }
            else {
                setHasCam(false);
            }
        })
            .catch((err) => {
            setHasCam(false);
            console.debug('no cam on ticket bottom bar', err);
        });
    }, []);
    const getNextTicketState = () => {
        if (props.archived != null && props.archived) {
            return { nextState: I18n.m.getMessage('unarchiveTicketShort'), color: '#618081' };
        }
        switch (props.ticketState) {
            case TicketStatus.open:
                return { nextState: I18n.m.getMessage('ticketsDetailsStateprocessing'), color: TicketStatusColor.processing };
            case TicketStatus.processing:
                return { nextState: I18n.m.getMessage('ticketsDetailsStateclosed'), color: TicketStatusColor.closed };
            case TicketStatus.closed:
                return { nextState: I18n.m.getMessage('ticketsDetailsStatechecked'), color: TicketStatusColor.checked };
            default:
                return { nextState: I18n.m.getMessage('archiveTicketShort'), color: '#3A6073' };
        }
    };
    const nextTicketState = getNextTicketState();
    const changeToNextTicketState = () => {
        if (props.ticketId != null) {
            if (props.archived != null && props.archived) {
                const c = new UnArchiveTicket({}, props.ticketId);
                c.execute().catch((error) => {
                    DefaultErrorHandler.getDefaultErrorMessages(error, I18n.m, error);
                });
                return;
            }
            let nextState;
            switch (props.ticketState) {
                case TicketStatus.checked:
                    nextState = null;
                    break;
                case TicketStatus.closed:
                    nextState = TicketStatus.checked;
                    break;
                case TicketStatus.processing:
                    nextState = TicketStatus.closed;
                    break;
                default:
                    nextState = TicketStatus.processing;
            }
            if (nextState != null) {
                const c = new ChangeTicketStatus({ ticketStatus: nextState }, props.ticketId);
                c.execute().catch((e) => {
                    if (e.messageCode != null && e.message != null && e.messageCode === 'ticketsDetailsErrorStatusChange') {
                        e.messageData.newState = I18n.m.getMessage(`ticketsDetailsState${TicketStatus[e.messageData.stateId]}`);
                    }
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                });
            }
            else {
                const c = new ArchiveTicket({}, props.ticketId);
                c.execute().catch((error) => {
                    DefaultErrorHandler.showDefaultErrorAlert(error, I18n.m, error);
                });
            }
        }
    };
    return (<View style={{
            position: 'absolute',
            width: props.contentWidth,
            bottom: props.fullscreen
                ? ResizeEvent.current.currentKeyboardHeight - 10
                : Math.max(0, ResizeEvent.current.currentKeyboardHeight - 0.1 * ResizeEvent.current.contentHeight),
            borderColor: ThemeManager.style.borderColor,
            borderStyle: 'solid',
            borderWidth: 0,
            borderTopWidth: ThemeManager.style.borderWidth,
            backgroundColor: '#FFFFFF',
            height: ThemeManager.style.getScreenRelativePixelSize(56),
            alignItems: 'center',
            justifyContent: 'space-around',
            flexDirection: 'row',
        }}>
        <Icon toolTip={I18n.m.getMessage('ticketsDetailsAddAttachment')} icon="attachment" onPress={props.addAttachment}/>
        <View style={{ margin: 5, alignItems: 'stretch', paddingHorizontal: 0 }}>
          <ContainedButton title={nextTicketState.nextState} backgroundColor={nextTicketState.color} onPress={changeToNextTicketState} disabled={!(props.ticketId != null)}/>
        </View>
        <Icon toolTip={I18n.m.getMessage('tooltipOpenCamera')} icon="camera" onPress={(e) => (props.openCam != null ? props.openCam(e) : null)} disabled={!hasCam}/>
      </View>);
});
