"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Absence = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var AbsenceEntity_1 = require("./entities/AbsenceEntity");
var TimeTrackingEntity_1 = require("./entities/TimeTrackingEntity");
var Absence = function (_ReadModelClientOffli) {
  function Absence() {
    var _this;
    (0, _classCallCheck2.default)(this, Absence);
    _this = _callSuper(this, Absence, arguments);
    _this.exampleEntity = new AbsenceEntity_1.AbsenceEntity();
    _this.onEvents = [{
      eventName: 'AbsenceCreated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'AbsenceChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'AbsenceDeleted',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(Absence, _ReadModelClientOffli);
  return (0, _createClass2.default)(Absence, [{
    key: "onAbsenceDeleted",
    value: function () {
      var _onAbsenceDeleted = (0, _asyncToGenerator2.default)(function* (e, ent) {
        ent.deleted = true;
        ent.lastModifiedAt = e.createdAt;
        return ent;
      });
      function onAbsenceDeleted(_x, _x2) {
        return _onAbsenceDeleted.apply(this, arguments);
      }
      return onAbsenceDeleted;
    }()
  }, {
    key: "getSyncFilter",
    value: function () {
      var _getSyncFilter = (0, _asyncToGenerator2.default)(function* (lastModifiedAt, reSyncFilter) {
        var date = yield TimeTrackingEntity_1.TimeTrackingEntity.offlineDateLimit();
        if (lastModifiedAt == null) {
          if (this.localRepo) yield this.localRepo.deleteFilter(`ends lt ${date.toISOString()}`);
          var all = new Date(-1);
          if (all.toISOString() === this.lastSyncFirstValue.toISOString()) {
            return `ends ge ${date.toISOString()}`;
          }
          return `ends ge ${date.toISOString()} and lastModifiedAt gt ${this.lastSyncFirstValue.toISOString()}`;
        }
        return reSyncFilter != null && reSyncFilter.length > 0 ? `${reSyncFilter} and ends ge ${date.toISOString()} and lastModifiedAt gt ${lastModifiedAt.toISOString()}` : `ends ge ${date.toISOString()} and lastModifiedAt gt ${lastModifiedAt.toISOString()}`;
      });
      function getSyncFilter(_x3, _x4) {
        return _getSyncFilter.apply(this, arguments);
      }
      return getSyncFilter;
    }()
  }]);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.Absence = Absence;