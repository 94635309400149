import ConnectionContext from 'materialTheme/src/connectionContext';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import AudioPlayer from 'materialTheme/src/theme/components/audio/AudioPlayer';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { CacheableImage } from 'materialTheme/src/theme/components/images/CacheableImage';
import { ImageEditor } from 'materialTheme/src/theme/components/lightbox/ImageEditor';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { UploadTypes } from 'materialTheme/src/theme/components/upload/UploadTypes';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeStoredFilename, } from 'upmesh-core/src/client/commands/storedfile/ChangeStoredFilename';
import { EditTicketComment } from 'upmesh-core/src/client/commands/ticketcomments/EditTicketComment';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
import { AddFileTypeAndCommentDialog } from './AddFileTypeAndCommentDialog';
import { EditedFileSaver } from './EditedFileSaver';
import { StoredFileDownloader } from './StoredFileDownloader';
import { StoredFileDownloaderOptions } from './StoredFileDownloaderOptions';
const bytes = require('bytes');
export class StoredFilesThumb extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.storedFileChanged = (en) => {
            const { storedFile } = this.state;
            en.entities.forEach((e) => {
                if (e.entity.id === storedFile?.id) {
                    this.setState({ storedFile: e.entity }, () => {
                        if (e.entity.fileType === 'image')
                            this.getImage().catch((err) => console.debug(err));
                    });
                }
            });
        };
        this.downloadImage = (_e) => {
            const { storedFile } = this.state;
            if (storedFile == null) {
                return;
            }
            const localFile = OfflineDataDownloader.isMediaSynced(storedFile.projectId, storedFile.id, storedFile.getFileId());
            StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({ id: storedFile.id, orgFilename: storedFile.orgFilename }, false, localFile)).catch((err) => console.error(err));
        };
        this.editImage = (item) => (_e) => {
            const { imageSource } = this.state;
            const localFile = OfflineDataDownloader.isMediaSynced(item.projectId, item.id, item.getFileId());
            if (localFile != null || (imageSource != null && imageSource.uri != null)) {
                ImageEditor.openImageEditor(localFile != null ? localFile : imageSource.uri, item.orgFilename, EditedFileSaver.saveEditedImage(item), true).catch((err) => {
                    console.debug('cant open image editor', err);
                });
            }
        };
        this.editPdf = (storedFile, editable = false) => () => {
            const localFile = OfflineDataDownloader.isMediaSynced(storedFile.projectId, storedFile.id, storedFile.getFileId());
            StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({ id: storedFile.id, orgFilename: storedFile.orgFilename }, true, localFile, storedFile, editable)).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.changeFileName = (item) => (_e) => {
            const dataOptions = new Map();
            let { orgFilename } = item;
            let ext;
            if (item.orgFilename.lastIndexOf('.') >= 0) {
                orgFilename = item.orgFilename.substr(0, item.orgFilename.lastIndexOf('.'));
                ext = item.orgFilename.substr(item.orgFilename.lastIndexOf('.'));
            }
            dataOptions.set('orgFilename', {
                formType: 'string',
                cols: 1,
                props: { labelText: I18n.m.getMessage('filesRenameNewFileName') },
            });
            const currentData = {
                orgFilename,
                ext,
            };
            const content = (<Form key="ChangeStoredFileNameForm" formHeaderProps={{ formTitle: I18n.m.getMessage('filesRenameHeader') }} stickyActions={false} command={new ChangeStoredFilename(currentData, item.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={(_result) => {
                    Dialog.instance?.close();
                }} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[
                    <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>,
                ]}/>);
            Dialog.instance?.open({
                closeOnTouchOutside: true,
                content,
                height: 0,
                contentPadding: false,
            });
        };
        this.deleteFile = () => () => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('filesDeleteQuestion'),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.deleteFileNow()} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.deleteFileNow = () => (_e) => {
            const { activityId } = this.props;
            Alert.instance?.close(() => {
                const asyncNow = async () => {
                    LoadingEvents.instance.startLoading();
                    const { storedFile } = this.state;
                    if (storedFile != null && storedFile.id != null && activityId != null) {
                        const rmComment = new EditTicketComment({ comment: '', deleteFile: true }, activityId);
                        try {
                            await rmComment.execute();
                        }
                        catch (e) {
                            DefaultErrorHandler.showDefaultErrorAlert(e);
                        }
                    }
                    LoadingEvents.instance.stopLoading();
                };
                asyncNow().catch((err) => console.error(err));
            });
        };
        this.openDocument = (download, orgFilename, localSource) => () => {
            const { storedFile } = this.state;
            if (storedFile != null) {
                StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({ id: storedFile.id, orgFilename }, !download, localSource)).catch((err) => console.debug(err));
            }
        };
        this.tagItem = () => () => {
            const { storedFile } = this.state;
            if (storedFile != null) {
                UpmeshClient.instance.modals.project
                    .getById(storedFile.projectId)
                    .then((project) => {
                    if (project != null) {
                        Dialog.instance?.open({
                            closeOnTouchOutside: true,
                            content: (<AddFileTypeAndCommentDialog fileTypes={project.fileTypes != null ? project.fileTypes : []} fileId={storedFile.id}/>),
                            height: 0,
                            contentPadding: false,
                        });
                    }
                })
                    .catch((err) => console.error(err));
            }
        };
        this.getImage = async (e, counter = 0) => {
            const { storedFileId, storedFile } = this.props;
            let storedFile2 = e != null ? e : storedFile != null ? storedFile : undefined;
            try {
                if (storedFile2 == null) {
                    storedFile2 = await UpmeshClient.instance.modals.storedFile.getById(storedFileId);
                }
            }
            catch (err) {
                if (counter > 2) {
                    console.debug('cant load storedFile', err);
                    if (err['statusCode'] === 404) {
                        console.warn('Cant load image in Ticket, maybe a Project Access Problem', { storedFileId });
                    }
                }
                await new Promise((r) => {
                    setTimeout(() => r(), 2000 * (counter + 1));
                });
                if (this.mounted && counter <= 3)
                    this.getImage(e, counter + 1).catch((err) => console.warn(err));
                return;
            }
            if (storedFile2 == null) {
                if (this.mounted) {
                    this.setState({ init: true });
                }
                return;
            }
            const source = `${UpmeshClient.instance.url}/storedfile/file/${storedFile2.id}`;
            if (storedFile2.fileType === 'image') {
                if (this.mounted) {
                    const orgSize = this.getSizeFormMetaData(storedFile2);
                    const newSource = source.indexOf('?') > -1
                        ? `${source}&lm=${new Date(storedFile2.lastModifiedAt).getTime()}`
                        : `${source}?lm=${new Date(storedFile2.lastModifiedAt).getTime()}`;
                    this.setState({
                        ...this.getSize(orgSize),
                        storedFile: storedFile2,
                        imageSource: { uri: newSource },
                        init: true,
                    });
                }
            }
            else if (storedFile2.fileType === 'audio') {
                if (this.mounted) {
                    let audioSource = source;
                    const localFile = OfflineDataDownloader.isMediaSynced(storedFile2.projectId, storedFile2.id, storedFile2.getFileId());
                    if (localFile != null) {
                        audioSource = localFile.startsWith('file://') ? localFile : `file://${localFile}`;
                    }
                    this.setState({ audioSource, storedFile: storedFile2, init: true });
                }
            }
            else {
                if (this.mounted) {
                    this.setState({ storedFile: storedFile2, init: true });
                }
            }
        };
        this.onLongPress = (e) => {
            if (this.props.onLongPress != null) {
                this.props.onLongPress(e);
            }
        };
        this.onPress = (e) => {
            if (this.props.onPress != null) {
                this.props.onPress(e);
            }
        };
        const orgSize = this.props.storedFile != null ? this.getSizeFormMetaData(this.props.storedFile) : null;
        this.state = {
            imageSource: null,
            audioSource: undefined,
            ...this.getSize(orgSize),
            init: false,
            storedFile: this.props.storedFile,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.width !== prevState.width) {
            return { width: nextProps.width };
        }
        return null;
    }
    componentDidMount() {
        this.mounted = true;
        if (this.attachKey != null) {
            this.attachKey = uuid.v4();
        }
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'StoredFile',
            attachKey: this.attachKey,
            callback: this.storedFileChanged,
        });
        this.getImage().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        this.mounted = false;
        if (this.timeOut) {
            window.clearTimeout(this.timeOut);
        }
        this.detach();
    }
    render() {
        const { imageSource, audioSource, orgHeight, orgWidth, init, width, height, storedFile } = this.state;
        const { group, index, border } = this.props;
        const bordered = border
            ? { ...ThemeManager.style.borderStyle, justifyContent: 'center' }
            : { justifyContent: 'center' };
        if (storedFile == null) {
            return this.renderLoading(this.props.width, this.props.width);
        }
        const project = CurrentProject.instance?.getCurrentProject();
        const editFileTypes = (project != null &&
            project.projectSubscription === 'enterprise' &&
            ((storedFile.mimeType != null && storedFile.mimeType.includes('pdf')) ||
                (storedFile.orgFilename && storedFile.orgFilename.includes('pdf')))) ||
            (storedFile.mimeType && storedFile.mimeType.includes('png')) ||
            (storedFile.mimeType && storedFile.mimeType.includes('jpeg')) ||
            (storedFile.mimeType && storedFile.mimeType.includes('jpg'));
        const editDisabled = !editFileTypes;
        let icons = 4;
        const canDelete = CurrentUser.userId === storedFile.createdBy &&
            storedFile.createdAt.getTime() + 1000 * 60 * 60 * 24 > new Date().getTime();
        if (canDelete)
            icons += 1;
        if (storedFile.fileType === 'image') {
            if (!init) {
                return this.renderLoading(this.props.width, this.props.width);
            }
            if (imageSource != null && imageSource.uri != null && storedFile != null) {
                let panoramaImage = false;
                if (!Number.isNaN(orgWidth) && !Number.isNaN(orgHeight) && orgWidth / orgHeight === 2) {
                    panoramaImage = true;
                }
                const metaData = storedFile.imageMetadata;
                if (metaData != null) {
                    metaData.comment = storedFile.comment;
                    metaData.category = storedFile.type;
                }
                const lightBoxProps = {
                    group,
                    index,
                    metaData,
                    downloadFunction: this.downloadImage,
                    editSettings: {
                        saveEditedFunction: (f) => {
                            EditedFileSaver.saveEditedImage(storedFile)(f).catch((err) => console.error(err));
                        },
                        originalname: storedFile.orgFilename,
                        targetUrl: `${UpmeshClient.instance.url}/storedfile/file/${storedFile.id}`,
                    },
                    expandButton: false,
                    rippleProps: {
                        onLongPress: this.onLongPress,
                        style: { width, height },
                    },
                };
                if (this.props.onPress != null) {
                    lightBoxProps.expandButton = true;
                    lightBoxProps.rippleProps = {
                        onLongPress: this.onLongPress,
                        onPressIn: this.onPress,
                        style: { width, height },
                    };
                }
                const { connectedToServer } = this.context;
                const localFile = OfflineDataDownloader.isMediaSynced(storedFile.projectId, storedFile.id, storedFile.getFileId());
                return (<View style={{
                        ...bordered,
                        position: 'relative',
                        alignItems: 'center',
                    }}>
            <CacheableImage lastModified={storedFile.lastModifiedAt} panoramaImage={panoramaImage} localSource={localFile} lightBox auth lightBoxProps={lightBoxProps} key={`lb_${orgWidth}x${orgHeight}_${index}_${storedFile.comment}_${storedFile.type}`} style={{ width, height }} width={width} height={height} source={imageSource} resizeMode="contain" resizeMethod="auto" showNotFound showLoading/>
            <MaterialText fixedWidth="100%" textAlign="right">
              {storedFile.orgFilename}
            </MaterialText>
            <View style={{
                        flexDirection: 'row',
                        alignSelf: 'flex-end',
                        justifyContent: 'flex-end',
                        height: 36,
                        width: 36 * icons,
                    }}>
              <Icon icon="download-outline" disabled={!connectedToServer || localFile != null} onPress={this.openDocument(true, storedFile.orgFilename)} toolTip={I18n.m.getMessage('download')}/>
              <Icon icon="comment-outline" onPress={this.tagItem()} toolTip={I18n.m.getMessage('filesChangeCommentTypeTooltip')}/>
              <Icon icon="form-textbox" onPress={this.changeFileName(storedFile)} toolTip={I18n.m.getMessage('filesRename')}/>
              <Icon icon="pencil-outline" onPress={this.editImage(storedFile)} toolTip={I18n.m.getMessage('edit')} disabled={editDisabled}/>
              <Icon disabled={!canDelete} icon="delete-outline" onPress={this.deleteFile()} toolTip={I18n.m.getMessage(!canDelete ? 'cantDeleteFileHasAssigendTickets' : 'delete')}/>
            </View>
          </View>);
            }
        }
        else if (storedFile.fileType === 'audio') {
            if (!init) {
                return this.renderLoading(this.props.width, this.props.width);
            }
            const { connectedToServer } = this.context;
            const localFile = OfflineDataDownloader.isMediaSynced(storedFile.projectId, storedFile.id, storedFile.getFileId());
            let duration;
            try {
                if (storedFile.metaData != null && storedFile.metaData.length > 0) {
                    const m = JSON.parse(storedFile.metaData);
                    duration = Number.parseFloat(m['duration']);
                }
            }
            catch (e) {
                console.debug('cant get duration', e);
            }
            const iconWidth = 32 * icons;
            const sideBySide = width > 312 + iconWidth;
            return (<View style={{
                    flexDirection: sideBySide ? 'row' : 'column',
                    width: '100%',
                    alignItems: sideBySide ? 'center' : 'flex-start',
                    justifyContent: sideBySide ? 'space-between' : 'flex-start',
                    flexWrap: sideBySide ? undefined : 'wrap',
                }}>
          <View collapsable={false} style={{ flex: 1, width: sideBySide ? undefined : '100%' }}>
            <AudioPlayer src={audioSource} duration={duration} withAuth errorHandler={DefaultErrorHandler.showDefaultErrorAlert}/>
          </View>
          <View style={{
                    flexDirection: 'row',
                    width: sideBySide ? iconWidth : '100%',
                    justifyContent: sideBySide ? 'center' : 'flex-end',
                }}>
            <Icon icon="download-outline" disabled={!connectedToServer || localFile != null} onPress={this.openDocument(true, storedFile.orgFilename)} toolTip={I18n.m.getMessage('download')}/>
            <Icon icon="comment-outline" onPress={this.tagItem()} toolTip={I18n.m.getMessage('filesChangeCommentTypeTooltip')}/>
            {icons > 3 ? (<Icon icon="delete-outline" onPress={this.deleteFile()} toolTip={I18n.m.getMessage('delete')}/>) : null}
          </View>
        </View>);
        }
        const localFile = OfflineDataDownloader.isMediaSynced(storedFile.projectId, storedFile.id, storedFile.getFileId());
        const targetUrl = `${UpmeshClient.instance.url}/storedfile/file/${storedFile.id}`;
        const metaData = storedFile.imageMetadata;
        if (metaData != null) {
            metaData.comment = storedFile.comment;
            metaData.category = storedFile.type;
        }
        const thumb = UploadTypes.getThumbnailForFileName({ targetUrl, name: storedFile.orgFilename, preview: localFile }, undefined, {
            metaData,
            downloadFunction: this.downloadImage,
            editSettings: {
                saveEditedFunction: (f) => {
                    EditedFileSaver.saveEditedImage(storedFile)(f).catch((err) => console.error(err));
                },
                originalname: storedFile.orgFilename,
                targetUrl: `${UpmeshClient.instance.url}/storedfile/file/${storedFile.id}`,
            },
        });
        const size = storedFile.fileSizeInBytes != null ? bytes(storedFile.fileSizeInBytes) : '0';
        if (!border) {
            const { connectedToServer } = this.context;
            const iconList = [
                {
                    key: 'storedthumb_icon_download',
                    icon: 'download-outline',
                    disabled: !connectedToServer || localFile != null,
                    onPress: this.openDocument(true, storedFile.orgFilename),
                    toolTip: I18n.m.getMessage('download'),
                },
                {
                    key: 'storedthumb_icon_tag',
                    icon: 'comment-outline',
                    disabled: false,
                    onPress: this.tagItem(),
                    toolTip: I18n.m.getMessage('filesChangeCommentTypeTooltip'),
                },
                {
                    key: 'storedthumb_icon_textbox',
                    icon: 'form-textbox',
                    onPress: this.changeFileName(storedFile),
                    toolTip: I18n.m.getMessage('filesRename'),
                },
            ];
            if (icons > 3) {
                iconList.push({
                    key: 'storedthumb_icon_pencil',
                    icon: 'pencil-outline',
                    disabled: editDisabled,
                    onPress: this.editPdf(storedFile),
                    toolTip: I18n.m.getMessage('edit'),
                });
            }
            if (icons > 4) {
                iconList.push({
                    key: 'storedthumb_icon_trash',
                    icon: 'delete-outline',
                    disabled: false,
                    onPress: this.deleteFile(),
                    toolTip: I18n.m.getMessage('delete'),
                });
            }
            return (<View style={{ width: '100%' }}>
          <ListItem paddingMidToLeft={ThemeManager.style.getScreenRelativePixelSize(8)} onPress={connectedToServer || localFile != null
                    ? !editDisabled &&
                        ((storedFile.mimeType && storedFile.mimeType.includes('pdf')) ||
                            (storedFile.orgFilename && storedFile.orgFilename.includes('pdf')))
                        ? this.editPdf(storedFile)
                        : this.openDocument(false, storedFile.orgFilename, localFile)
                    : undefined} backgroundColor="transparent" title={storedFile.orgFilename} secondTextLine={size} thirdTextLine={storedFile.type != null ? storedFile.type : I18n.m.getMessage('filesChangeToNoType')} thumbnail={thumb}/>
          <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%' }}>
            {iconList.map((e) => (<Icon {...e}/>))}
          </View>
        </View>);
        }
        return (<Ripple style={{ width, height, ...ThemeManager.style.borderStyle, alignItems: 'center', justifyContent: 'center' }} onLongPress={this.onLongPress} onPress={this.onPress}>
        {thumb.thumbnail}
        <MaterialText numberOfLines={2} centeredBox centeredText type={MaterialTextTypes.Body1}>
          {storedFile.orgFilename}
        </MaterialText>
        <MaterialText numberOfLines={1} fontSize={12} centeredBox centeredText type={MaterialTextTypes.Body2}>
          {size}
        </MaterialText>
      </Ripple>);
    }
    detach() {
        if (this.attachKey != null) {
            UpmeshClient.eventDispatcher.detach('StoredFile', this.attachKey);
            this.attachKey = undefined;
        }
    }
    getSize(orgSize) {
        const { width, height } = this.props;
        const maxSize = Math.min(width, height);
        const targetImageSize = {
            width: maxSize,
            height: maxSize,
            orgWidth: maxSize,
            orgHeight: maxSize,
        };
        if (orgSize != null) {
            targetImageSize.orgHeight = orgSize.orgHeight;
            targetImageSize.orgWidth = orgSize.orgWidth;
            const ratio = Math.max(orgSize.orgWidth / width, orgSize.orgHeight / height);
            targetImageSize.width = orgSize.orgWidth / ratio;
            targetImageSize.height = orgSize.orgHeight / ratio;
        }
        return { ...targetImageSize };
    }
    getSizeFormMetaData(s) {
        if (s.metaData != null && typeof s.metaData === 'string' && s.metaData.length > 0) {
            try {
                const metaData = JSON.parse(s.metaData);
                if (metaData != null && metaData.width != null) {
                    const orgWidth = metaData.width;
                    const orgHeight = metaData.height;
                    return { orgWidth, orgHeight };
                }
            }
            catch (e) {
                console.debug('Cant read metaData', e);
            }
        }
        return null;
    }
    renderLoading(width, height) {
        const { border } = this.props;
        if (!border) {
            return (<MaterialText centeredText centeredBox>
          {I18n.m.getMessage('loading')}
        </MaterialText>);
        }
        return (<View pointerEvents="none" style={{
                width,
                height,
                position: 'relative',
                justifyContent: 'center',
                ...ThemeManager.style.borderStyle,
            }}>
        <MaterialText centeredText centeredBox>
          {I18n.m.getMessage('loading')}
        </MaterialText>
      </View>);
    }
}
StoredFilesThumb.contextType = ConnectionContext;
