import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipDialogForm, } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { InviteUser } from 'upmesh-core/src/client/commands/user/InviteUser';
import { RemoveInvitation } from 'upmesh-core/src/client/commands/user/RemoveInvitation';
import { ResendInvitation } from 'upmesh-core/src/client/commands/user/ResendInvitation';
import { GroupEntity } from 'upmesh-core/src/client/query/entities/GroupEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CompanyLogo } from '../companies/CompanyLogo';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { OwnerChangeDialog } from './OwnerChangeDialog';
const noMemberImage = require('../../assets/img/noProjectmembers.png');
export class TeamMembersList extends PureComponent {
    constructor() {
        super(...arguments);
        this.addTeamMember = (e) => {
            const { project } = this.props;
            if (project == null)
                return;
            const asyncNow = async () => {
                if (project != null) {
                    const catched = await DefaultErrorHandler.getProjectErrors(project.id, true);
                    if (catched)
                        return;
                }
                Routing.instance.openDialog('addTeam', { projectId: project.id })(e);
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.closeCompanyMemberDialog = (e) => {
            Dialog.instance?.close();
            if (e != null && Array.isArray(e) && e.length === this.companyMember.length) {
                const selected = [];
                for (let i = 0; i < e.length; i += 1) {
                    const member = this.companyMember.find((a) => e[i].data === a.companyMember.id);
                    if (member)
                        if (e[i]['selected'])
                            selected.push(member);
                }
                if (selected.length > 0)
                    this.addCompanyUsersAsTeamMemberNow(selected).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
        };
        this.addUserToSelection = async (m) => {
            try {
                if (m.userId != null) {
                    const user = await AuthClient.instance.modals.user.getById(m.userId);
                    return { companyMember: m, user };
                }
                return null;
            }
            catch (e) {
                console.debug('cant load user', e);
                return null;
            }
        };
        this.companyMember = [];
        this.addCompanyUserAsTeamMember = (_e) => {
            const { project } = this.props;
            if (project == null)
                return;
            const asyncNow = async () => {
                if (project != null) {
                    const catched = await DefaultErrorHandler.getProjectErrors(project.id, true);
                    if (catched)
                        return;
                }
                const pus = await UpmeshClient.instance.modals.projectUser.get({
                    filter: `projectId eq '${project.id}' and status ne 'deleted'`,
                });
                const member = CompanyUserInfo.companyMember.filter((s) => s.role !== 'inactive');
                const promises = [];
                member.forEach((m) => {
                    const i = pus.findIndex((a) => a.userId === m.userId);
                    if (i === -1)
                        promises.push(this.addUserToSelection(m));
                });
                const cm = await Promise.all(promises);
                this.companyMember = [];
                cm.forEach((c) => {
                    if (c != null) {
                        this.companyMember.push(c);
                    }
                });
                const companyGroups = [];
                if (member.length > 0) {
                    const groups = await UpmeshClient.instance.modals.group.get({
                        filter: `deleted ne true and companyId eq '${member[0].companyId}'`,
                    });
                    groups.forEach((g) => {
                        let name = g.groupName;
                        if (name === GroupEntity.getDefaultName())
                            name = I18n.m.getMessage('companyGroupDefaultName');
                        companyGroups.push({ id: g.id, groupName: name, backgroundColor: ThemeManager.style.chipDefaultBg });
                    });
                }
                const menuElements = [];
                this.companyMember.forEach((c) => {
                    const group = companyGroups.find((g) => g.id === c.companyMember.groupId);
                    menuElements.push({
                        thumbnail: {
                            rounded: true,
                            thumbnail: <UserImage size={36} user={c.user}/>,
                            width: 40,
                        },
                        title: c.user.getFullName(),
                        data: c.companyMember.id,
                        selected: false,
                        groupId: c.companyMember.groupId,
                        secondTextLine: group ? group.groupName : undefined,
                    });
                });
                Dialog.instance?.open({
                    content: (<ChipDialogForm items={menuElements} title={I18n.m.getMessage('projectAddCompanyUserToTeamTitle')} onCancel={this.closeCompanyMemberDialog} onSave={this.closeCompanyMemberDialog} multiselect singleSelectSelected={0} canAddNewChips={false} saveButtonText={I18n.m.getMessage('add')} sortByGroup chipGroups={companyGroups}/>),
                    contentPadding: false,
                    fullscreenResponsive: true,
                });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.addCompanyUsersAsTeamMemberNow = async (selected) => {
            LoadingEvents.instance.startLoading();
            for (let i = 0; i < selected.length; i += 1) {
                const s = selected[i];
                LoadingEvents.instance.startLoading(`${i}/${selected.length}`);
                await this.addCompanyUserAsTeamMemberNow(s);
            }
            LoadingEvents.instance.stopLoading();
        };
        this.addCompanyUserAsTeamMemberNow = async (s) => {
            const { project } = this.props;
            if (project != null && s.user.emails != null && s.user.emails.length > 0) {
                const command = new InviteUser({
                    projectId: project.id,
                    userGroup: undefined,
                    email: s.user.emails[0],
                    roleId: s.companyMember.role === 'collaborator'
                        ? '3'
                        : s.companyMember.role === 'admin' || s.companyMember.role === 'user'
                            ? '1'
                            : '2',
                });
                await command.execute();
            }
        };
        this.changeOwner = (company) => (item) => (_e) => {
            const { project } = this.props;
            if (project != null) {
                const userName = company != null ? company.company : item.name != null ? item.name : item.toMail;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: (<OwnerChangeDialog toType={company != null ? 'company' : 'user'} projectUserId={company != null ? `company${company.id}` : item.id} userName={userName} project={project}/>),
                    contentPadding: false,
                });
            }
        };
        this.changeRole = (user) => (e) => {
            this.changeRoleDialog(user.id)(e).catch((err) => console.debug(err));
        };
        this.changeRoleDialog = (id) => async (e) => {
            Menu.instance?.close();
            Routing.instance.openDialog('changeRole', { id })(e);
        };
        this.openFab = () => {
            const { project, canIInvite, size } = this.props;
            if (!project || !canIInvite)
                return;
            if (Fab.instance != null) {
                const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
                if (CompanyUserInfo.company != null) {
                    Fab.instance.open({
                        extraPaddingBottom: sViewHeight,
                        fabIcon: 'plus',
                        fabIconOpen: 'close',
                        small: false,
                        fabColor: ThemeManager.style.brandPrimary,
                        fabColorOpen: ThemeManager.style.brandSecondary,
                        fabActions: [
                            {
                                text: I18n.m.getMessage('inviteTeamMember'),
                                icon: 'account-plus-outline',
                                onPress: this.addTeamMember,
                            },
                            {
                                text: I18n.m.getMessage('addCompanyUserAsTeamMember'),
                                icon: 'domain',
                                onPress: this.addCompanyUserAsTeamMember,
                            },
                        ],
                    });
                }
                else {
                    Fab.instance.open({
                        extraPaddingBottom: sViewHeight,
                        fabIcon: 'plus',
                        fabIconOpen: 'close',
                        small: false,
                        fabColor: ThemeManager.style.brandPrimary,
                        fabColorOpen: ThemeManager.style.brandSecondary,
                        onPressFab: this.addTeamMember,
                    });
                }
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.removeInvitation = (user) => (_e) => {
            Menu.instance?.close();
            Routing.instance.alert.post({
                text: I18n.m.getMessage('teamRemoveInvitationAlert'),
                buttons: [
                    <ContainedButton key="abort" title={I18n.m.getMessage('abort')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                    <ContainedButton key="save" title={I18n.m.getMessage('teamRemoveInvitation')} onPress={this.removeInvitationNow(user.id)}/>,
                ],
            });
        };
        this.removeInvitationNow = (id) => (_e) => {
            Alert.instance?.close();
            const r = new RemoveInvitation({}, id);
            r.execute().catch((e) => {
                if (e.messageCode != null) {
                    Routing.instance.alert.post({ text: I18n.m.getMessage(e.messageCode) });
                }
                else {
                    ErrorReporter.sendReport({
                        data: e,
                        subject: e.messageCode != null ? e.messageCode : 'cant remove user',
                        type: 'warn',
                    });
                }
            });
        };
        this.renderEmails = (item, columnData, index) => {
            const { style } = columnData;
            return (<View key={`cellEmail-${item.id}-${index}`} style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16 }, style]}>
        <MaterialText ellipsizeMode="tail" numberOfLines={1}>
          {item.emails}
        </MaterialText>
      </View>);
        };
        this.renderNames = (item, columnData, index) => {
            const { style } = columnData;
            const name = item.status === 'accepted' ? item.name : item.toMail;
            return (<View key={`cellName-${item.id}-${index}`} style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16 }, style]}>
        <MaterialText ellipsizeMode="tail" numberOfLines={1}>
          {name}
        </MaterialText>
      </View>);
        };
        this.renderThumb = (item, columnData, _index) => {
            const { style } = columnData;
            if (item.status !== 'accepted') {
                const icon = item.status === 'invited' ? 'email-check-outline' : 'account-remove-outline';
                return (<View style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16 }, style]} key={`ViewAroundThumb${item.id}}`}>
          <Icon key="emailSend" toolTip="" icon={icon}/>
        </View>);
            }
            if (item == null)
                return <View style={style}/>;
            const { companyItem } = item;
            if (item.user == null && companyItem != null) {
                return (<View style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16 }, style]}>
          <CompanyLogo company={companyItem} round={false} size={36}/>
        </View>);
            }
            if (item.user == null)
                return <View style={style}/>;
            return (<View style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16 }, style]} key={`ViewAroundThumb${item.id}}`}>
        <UserImage size={36} user={item.user}/>
      </View>);
        };
        this.renderUserStatus = (item, columnData, index) => {
            const { style } = columnData;
            let title = '';
            if (item.status === 'accepted') {
                title = I18n.m.getMessage('teamMemberActive');
            }
            else if (item.status === 'deleted') {
                title = I18n.m.getMessage('teamMemberRemoved');
            }
            else if (item.status === 'invited') {
                title = I18n.m.getMessage('teamMemberInvited');
            }
            else if (item.status === 'rejected') {
                title = I18n.m.getMessage('teamMemberRemoved');
            }
            return (<View key={`cellStatus-${item.id}-${index}`} style={[{ justifyContent: 'center', alignItems: 'center', paddingHorizontal: 16 }, style]}>
        <MaterialText ellipsizeMode="tail" numberOfLines={1}>
          {title}
        </MaterialText>
      </View>);
        };
        this.resendInvitation = (u) => (_e) => {
            Routing.instance.alert.post({
                text: `${I18n.m.getMessage('teamResendInvitation')}?`,
                buttons: [
                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="abort" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="save" title={I18n.m.getMessage('send')} onPress={this.resendInvitationNow(u.id)}/>,
                ],
            });
        };
        this.resendInvitationNow = (id) => (_e) => {
            Alert.instance?.close(() => {
                const rI = new ResendInvitation({}, id);
                rI.execute()
                    .then(() => {
                    Routing.instance.alert.post({ text: I18n.m.getMessage('teamResendSuccess') });
                })
                    .catch((e) => {
                    if (e.messageCode != null) {
                        Routing.instance.alert.post({ text: I18n.m.getMessage(e.messageCode) });
                    }
                    else {
                        ErrorReporter.sendReport({
                            data: e,
                            subject: e.messageCode != null ? e.messageCode : 'cant resend invitation',
                            type: 'warn',
                        });
                    }
                });
            });
        };
        this.showMemberDetails = (u, e) => {
            if (u.status === 'accepted') {
                Routing.instance.openDialog('teammember', { projectUserId: u.id, userId: u.userId })(e);
            }
        };
    }
    componentDidMount() {
        const { project } = this.props;
        if (project != null)
            this.openFab();
    }
    componentWillUnmount() {
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { canSeeExtraColumns, users, maxHeight, canEdit, removeUser, project } = this.props;
        const actions = (item) => {
            if (item.id.startsWith('creator'))
                return [];
            if (!canEdit && item.userId === CurrentUser.userId) {
                return [{ icon: 'delete-outline', onAction: removeUser, toolTip: I18n.m.getMessage('remove') }];
            }
            if (!canEdit)
                return [];
            const action = [];
            if (project != null &&
                project.projectSubscription !== 'enterprise' &&
                project.creator === CurrentUser.userId &&
                item.status === 'accepted' &&
                item.userId !== CurrentUser.userId) {
                action.push({
                    icon: 'account-arrow-right-outline',
                    onAction: this.changeOwner(undefined),
                    toolTip: I18n.m.getMessage('changeOwner', { username: item.name }),
                });
            }
            else if (item.userId !== CurrentUser.userId &&
                project != null &&
                CompanyUserInfo.company != null &&
                project.projectSubscription === 'enterprise' &&
                item.companyItem != null &&
                project.creator !== `company${item.companyItem.id}` &&
                project.creator === `company${CompanyUserInfo.company.id}`) {
                action.push({
                    icon: 'account-arrow-right-outline',
                    onAction: this.changeOwner(item.companyItem),
                    toolTip: I18n.m.getMessage('changeOwner', { username: item.companyItem.company }),
                });
            }
            if (item.status === 'invited') {
                action.push({
                    icon: 'email-sync-outline',
                    onAction: this.resendInvitation,
                    toolTip: I18n.m.getMessage('teamResendInvitation'),
                }, {
                    icon: 'delete-outline',
                    onAction: this.removeInvitation,
                    toolTip: I18n.m.getMessage('teamDeleteInvitation'),
                });
            }
            else if (!item.id.startsWith('creator') && item.status === 'accepted') {
                action.push({ icon: 'pencil-outline', onAction: this.changeRole, toolTip: I18n.m.getMessage('edit') }, { icon: 'delete-outline', onAction: removeUser, toolTip: I18n.m.getMessage('remove') });
            }
            return action;
        };
        let actionItemsLength = 0;
        if (project != null)
            actionItemsLength =
                project.creator === CurrentUser.userId || project.creator === `company${CompanyUserInfo.company?.id}` ? 3 : 2;
        const availableColumns = [
            {
                title: '',
                hiddenTitleForSettings: I18n.m.getMessage('thumb'),
                keyInData: 'userId',
                style: { width: 64 },
                sortable: false,
                cellRenderer: this.renderThumb,
            },
            {
                title: I18n.m.getMessage('username'),
                keyInData: 'name',
                style: { width: 200 },
                sortable: true,
                cellRenderer: this.renderNames,
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('email'),
                keyInData: 'emails',
                style: { width: 200 },
                dataType: 'string',
                cellRenderer: this.renderEmails,
            },
            {
                title: I18n.m.getMessage('company'),
                keyInData: 'company',
                dataType: 'string',
                style: { width: 200 },
            },
            {
                title: I18n.m.getMessage('role'),
                keyInData: 'translatedRole',
                dataType: 'string',
                style: { width: 200 },
            },
            {
                title: I18n.m.getMessage('teamMemberStatus'),
                keyInData: 'status',
                dataType: 'string',
                style: { width: 200 },
                cellRenderer: this.renderUserStatus,
            },
        ];
        if (canSeeExtraColumns === true) {
            availableColumns.push({
                title: I18n.m.getMessage('joined'),
                keyInData: 'joined',
                dataType: 'DateWithTime',
                style: { width: 200 },
            });
            availableColumns.push({
                title: I18n.m.getMessage('invitedBy'),
                keyInData: 'invitee',
                dataType: 'string',
                style: { width: 200 },
            });
            availableColumns.push({
                title: I18n.m.getMessage('lastTimeActive'),
                keyInData: 'lastActive',
                dataType: 'DateWithTime',
                style: { width: 200 },
            });
        }
        return (<Table tableName="TeamMemberList" onRowPress={this.showMemberDetails} actionItemsLength={actionItemsLength} maxHeight={maxHeight} actions={project != null ? actions : undefined} data={users} sortBy="name" sortDirection="asc" emptyTableImage={noMemberImage} emptyTableHint={this.props.emptyTableHint ? this.props.emptyTableHint : I18n.m.getMessage('teamNoMemberText')} emptyTableText={this.props.emptyTableText ? this.props.emptyTableText : I18n.m.getMessage('teamNoMember')} columns={availableColumns}/>);
    }
}
