import 'text-encoding';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import { I18n } from '../i18n/I18n';
import { Download } from '../repo/file/Download';
import VCardGenerator from '../utils/VCardGenerator';
import { DefaultErrorHandler } from './DefaultErrorHandler';
export function QRCodeView(props) {
    const size = props.size ? props.size : 256;
    const generateQrCodeValue = () => {
        const { user, company } = props;
        let generator = null;
        if (user != null) {
            generator = VCardGenerator.fromUserEntity(user);
        }
        else if (company != null) {
            generator = VCardGenerator.fromCompanyEntity(company);
        }
        if (generator != null) {
            return generator.getAsVcard();
        }
        return '';
    };
    const downloadQrCode = (_e) => {
        const { user, company } = props;
        let generator = null;
        const asyncNow = async () => {
            try {
                if (user != null) {
                    generator = await VCardGenerator.fromUserEntityWithLogo(user);
                }
                else if (company != null) {
                    generator = await VCardGenerator.fromCompanyEntityWithLogo(company);
                }
                if (generator != null) {
                    Download.downloadDataAsString('vcard.vcf', 'text/vcard', generator.getAsVcard()).catch((err) => {
                        DefaultErrorHandler.showDefaultErrorAlert(err);
                    });
                }
            }
            catch (err) {
                DefaultErrorHandler.showDefaultErrorAlert(err);
            }
        };
        asyncNow().catch((err) => console.error(err));
    };
    return (<View style={{ alignItems: 'center' }}>
      <Ripple style={{ width: size, height: size }} toolTip={I18n.m.getMessage('userDetailsQrDownload')} onPress={downloadQrCode}>
        <QRCode value={generateQrCodeValue()} backgroundColor="transparent" size={size}/>
      </Ripple>
      {!props.hideButton && (<View style={{
                marginTop: 4,
                justifyContent: 'center',
                flexDirection: 'row',
            }}>
          <ContainedButton backgroundColor="transparent" title={I18n.m.getMessage('userDetailsQrDownload')} onPress={downloadQrCode} textColor={ThemeManager.style.brandPrimary}/>
        </View>)}
    </View>);
}
