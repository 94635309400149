"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateExtraPayTracking = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ExtraPayTrackingCreated_1 = require("../../../events/company/extrapay/ExtraPayTrackingCreated");
var OrderedApprovalProcess_1 = require("../../../query/entities/simple/OrderedApprovalProcess");
var TimeTrackingEntity_1 = require("../../../query/entities/TimeTrackingEntity");
var CreateExtraPayTracking = function (_CommandRoot_1$Comman) {
  function CreateExtraPayTracking(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v4();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateExtraPayTracking);
    _this = _callSuper(this, CreateExtraPayTracking, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'CreateExtraPayTracking';
    return _this;
  }
  (0, _inherits2.default)(CreateExtraPayTracking, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(CreateExtraPayTracking, [{
    key: "getExtraPay",
    value: function () {
      var _getExtraPay = (0, _asyncToGenerator2.default)(function* () {
        if (this._extraPay == null) this._extraPay = yield CommandReadModels_1.CommandReadModels.extraPay.getById(this.data.extraPayId);
        return this._extraPay;
      });
      function getExtraPay() {
        return _getExtraPay.apply(this, arguments);
      }
      return getExtraPay;
    }()
  }, {
    key: "getMe",
    value: function () {
      var _getMe = (0, _asyncToGenerator2.default)(function* () {
        if (this._me == null) {
          var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
          var extraPay = yield this.getExtraPay();
          var company = yield CommandReadModels_1.CommandReadModels.company.getById(extraPay.companyId);
          var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
            filter: `companyId eq '${company.id}' and userId eq '${userId}' and deleted ne true`,
            top: 1
          });
          if (companyMember.length === 1) this._me = companyMember[0];
        }
        return this._me;
      });
      function getMe() {
        return _getMe.apply(this, arguments);
      }
      return getMe;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var me = yield this.getMe();
        if (me != null) {
          if (me.role !== 'inactive' && me.payroll) {
            return true;
          }
          yield this.checkForRecordingPeriod(this.data.memberId);
          var forCompanyMember = yield CommandReadModels_1.CommandReadModels.companyMember.getById(this.data.memberId);
          if (forCompanyMember.leadership === userId) return true;
          if (this.data.memberId != null && this.data.memberId !== me.id && me.canExtraPayFor === 'group') {
            var forMember = yield CommandReadModels_1.CommandReadModels.companyMember.getById(this.data.memberId);
            if (forMember.groupId !== me.groupId) {
              throw {
                message: 'Keine Berechtigung',
                messageCode: 'forbiddenCommand'
              };
            }
          }
          if (me.role !== 'inactive' && me.canExtraPayFor !== 'nobody') {
            return true;
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "checkForRecordingPeriod",
    value: function () {
      var _checkForRecordingPeriod = (0, _asyncToGenerator2.default)(function* (memberId) {
        var member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(memberId);
        if (member != null) {
          var period = yield TimeTrackingEntity_1.TimeTrackingEntity.getRecordingPeriod(member);
          var starts = new Date(this.data.day);
          if (starts.getTime() < period.start.getTime() || starts.getTime() > period.end.getTime()) {
            throw {
              message: 'Das Datum liegt nicht im möglichen Erfassungszeitraum',
              messageCode: 'timeTrackingNotInRecordingPeriod'
            };
          }
        }
      });
      function checkForRecordingPeriod(_x) {
        return _checkForRecordingPeriod.apply(this, arguments);
      }
      return checkForRecordingPeriod;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.getById(this.data.memberId);
        var approvalStep = yield OrderedApprovalProcess_1.OrderedApprovalProcess.getInitialApprovalProcessStep('extraPay', this.data.companyId, companyMember.id, companyMember.groupId, this.data.extraPayId);
        return new ExtraPayTrackingCreated_1.ExtraPayTrackingCreated(this.entityId, Object.assign({
          memberId: this.data.memberId,
          day: this.data.day,
          extraPayId: this.data.extraPayId,
          extraField: this.data.extraField,
          notes: this.data.notes,
          companyId: this.data.companyId,
          foreignId: this.data.foreignId,
          costCenterId: this.data.costCenterId,
          projectId: this.data.projectId
        }, approvalStep));
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        var data = this.data;
        if (key == null || key === 'companyId') {
          if (data.companyId == null || data.companyId.length === 0) {
            errors.push({
              message: 'Die Firma wird benötigt',
              messageCode: 'required',
              key: 'companyId'
            });
          }
        }
        if (key == null || key === 'costCenterId' || key === 'projectId') {
          if (data.costCenterId == null || data.costCenterId.length === 0) {
            errors.push({
              message: 'Die Kostenstelle wird benötigt',
              messageCode: 'required',
              key: 'costCenterId'
            });
          } else {
            try {
              var cc = yield CommandReadModels_1.CommandReadModels.costCenter.getById(data.costCenterId);
              if (cc.costType === 'costUnit') {
                if (data.projectId == null) {
                  errors.push({
                    message: 'Wenn ein Kostenträger gewählt ist, wird die Projekt id benötigt',
                    messageCode: 'costUnitNeedsProject',
                    key: 'projectId'
                  });
                }
                var isIn = cc.projectIds == null ? -1 : cc.projectIds.findIndex(function (p) {
                  return p === data.projectId;
                });
                if (isIn === -1) {
                  errors.push({
                    message: 'Das Projekt ist dem Kostenträger nicht zugeordnet',
                    messageCode: 'projectNotInCostUnit',
                    key: 'projectId'
                  });
                }
              }
            } catch (e) {
              errors.push({
                message: 'Diese Kostenstelle existiert nicht',
                messageCode: 'costCenterIdNotFound',
                key: 'costCenterId'
              });
            }
          }
        }
        if (key == null || key === 'memberId') {
          if (data.memberId == null || data.memberId.length === 0) {
            errors.push({
              message: 'Min. 1 Benutzer wird benötigt',
              messageCode: 'required',
              key: 'memberIds'
            });
          }
        }
        var me = yield this.getMe();
        if (me != null && !me.payroll) {
          if ((key == null || key === 'memberId' || key === 'day') && data.memberId != null && data.memberId.length > 0) {
            try {
              yield this.checkForRecordingPeriod(data.memberId);
            } catch (err) {
              errors.push({
                message: err.message,
                messageCode: err.messageCode,
                key: 'day'
              });
            }
          }
        }
        if (key == null || key === 'day') {
          if (data.day == null) {
            errors.push({
              message: 'Das Datum wird benötigt',
              messageCode: 'required',
              key: 'starts'
            });
          }
        }
        if (key == null || key === 'extraPayId') {
          if (data.extraPayId == null || data.extraPayId.length === 0) {
            errors.push({
              message: 'Die Zulagenart wird benötigt',
              messageCode: 'required',
              key: 'extraPayId'
            });
          } else {
            var extraPay = yield this.getExtraPay();
            if (extraPay.onceADay && this.data.memberId != null) {
              var day = new Date(this.data.day);
              var start = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0, 0);
              var end = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1, 0, 0, 0);
              var exists = yield CommandReadModels_1.CommandReadModels.extraPayTracking.get({
                filter: `memberId eq '${this.data.memberId}' and extraPayId eq '${this.data.extraPayId}' and deleted ne true and day ge ${start.toISOString()} and day lt ${end.toISOString()}`
              });
              if (exists.length > 0 && exists[0].id !== this.entityId) {
                errors.push({
                  message: 'wird benötigt',
                  messageCode: 'extraOnlyOnceADay',
                  key: 'extraField'
                });
              }
            }
            if (extraPay.fieldType === 'number') {
              if (this.data.extraField == null) {
                errors.push({
                  message: 'wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (typeof data.extraField !== 'number') {
                errors.push({
                  message: 'Zahl wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (extraPay.field != null && extraPay.field.min != null && data.extraField < extraPay.field.min) {
                errors.push({
                  message: `Der Wert ist zu klein, min ${extraPay.field.min}`,
                  messageCode: 'numberToSmall',
                  messageData: {
                    value: extraPay.field.min
                  },
                  key: 'extraField'
                });
              } else if (extraPay.field != null && extraPay.field.max != null && data.extraField > extraPay.field.max) {
                errors.push({
                  message: `Der Wert ist zu groß, maximum ${extraPay.field.min}`,
                  messageCode: 'numberToBig',
                  messageData: {
                    value: extraPay.field.max
                  },
                  key: 'extraField'
                });
              }
            } else if (extraPay.fieldType === 'duration') {
              var _data$extraField, _data$extraField2;
              if (this.data.extraField == null) {
                errors.push({
                  message: 'wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (typeof data.extraField === 'number') {
                errors.push({
                  message: 'Zeitspanne wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (((_data$extraField = data.extraField) == null ? void 0 : _data$extraField.from) == null) {
                errors.push({
                  message: 'Zeit wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              } else if (((_data$extraField2 = data.extraField) == null ? void 0 : _data$extraField2.to) == null) {
                errors.push({
                  message: 'Zeit wird benötigt',
                  messageCode: 'required',
                  key: 'extraField'
                });
              }
            }
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validate(_x2) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.CreateExtraPayTracking = CreateExtraPayTracking;