var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileDropZone = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _Routing = require("../../routing/Routing");
var _ThemeManager = require("../../ThemeManager");
var _Dialog = require("../Dialog");
var _ListItem = require("../ListItem");
var _MaterialText = require("../text/MaterialText");
var _FileDropHandler = require("./FileDropHandler");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var FileDropZone = exports.FileDropZone = function (_PureComponent) {
  function FileDropZone(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, FileDropZone);
    _this = _callSuper(this, FileDropZone, [props, context]);
    _this.acceptedHandlers = [];
    _this.dragOverEndHandler = function (ev) {
      _this.endTimeOut = setTimeout(function () {
        _this.setState({
          backgroundColor: 'transparent'
        });
      }, 200);
      ev.preventDefault();
    };
    _this.dragOverHandler = function (ev) {
      ev.preventDefault();
      ev.dataTransfer.dropEffect = _this.dropEffect;
      _this.setState({
        backgroundColor: _ThemeManager.ThemeManager.style.dropFileOverlayColor
      });
    };
    _this.dragStartHandler = function (ev) {
      ev.preventDefault();
      if (_this.endTimeOut != null) {
        clearTimeout(_this.endTimeOut);
      }
      _this.setState({
        backgroundColor: _ThemeManager.ThemeManager.style.dropFileOverlayColor
      });
    };
    _this.createAccepted = function (files) {
      var defaultHandler = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      _this.acceptedHandlers = [];
      var handler = _FileDropHandler.FileDropHandler.targets;
      var multiAcceptedHandlers = new Map();
      for (var h of handler) {
        if (!defaultHandler && !h.defaultHandler || defaultHandler && h.defaultHandler) {
          if (files.length === 1 || h.multiFiles) {
            if (h.id != null) {
              multiAcceptedHandlers.set(h.id, h);
            }
          }
        }
      }
      var _loop = function _loop(i) {
        multiAcceptedHandlers.forEach(function (item, key) {
          if (files[i].type != null && item != null) {
            if (item.filter != null && !item.filter.includes('allFiles')) {
              var accepted = false;
              if (Array.isArray(item.filter)) {
                for (var filt of item.filter) {
                  accepted = _this.checkFilter(FileDropZone.types[filt], files[i].type);
                  if (accepted) break;
                }
              } else {
                accepted = _this.checkFilter(FileDropZone.types[item.filter], files[i].type);
              }
              if (!accepted) multiAcceptedHandlers.delete(key);
            }
          }
        });
      };
      for (var i = 0; i < files.length; i += 1) {
        _loop(i);
      }
      if (!defaultHandler && multiAcceptedHandlers.size === 0) _this.createAccepted(files, true);else _this.acceptedHandlers = Array.from(multiAcceptedHandlers.values());
    };
    _this.dragStartHandler2 = function (ev) {
      ev.preventDefault();
      if (_this.endTimeOut != null) {
        clearTimeout(_this.endTimeOut);
      }
      var dt = ev.dataTransfer;
      var files = [];
      for (var i = 0; i < dt.items.length; i += 1) {
        try {
          if (dt.items[i].kind === 'file' && dt.items[i].type != null) {
            files.push({
              type: dt.items[i].type
            });
          }
        } catch (e) {
          _Routing.Routing.instance.alert.post({
            text: _I18n.I18n.m.getMessage('fileDragError')
          });
        }
      }
      _this.createAccepted(files);
      if (dt.items) {
        if (_this.state.countedFiles !== dt.items.length) {
          _this.setState({
            countedFiles: dt.items.length
          });
        }
        ev.dataTransfer.dropEffect = 'copy';
        if (_this.acceptedHandlers.length === 0) {
          _this.dropEffect = 'none';
          ev.dataTransfer.dropEffect = 'copy';
          ev.dataTransfer.effectAllowed = 'none';
        } else {
          _this.dropEffect = 'copy';
        }
        _this.forceUpdate();
      }
    };
    _this.onPaste = function (e) {
      _this.droppedFiles = [];
      if (e.clipboardData != null && e.clipboardData.files != null && e.clipboardData.files.length > 0) {
        e.preventDefault();
        _this.createAccepted(e.clipboardData.files);
        for (var i = 0; i < e.clipboardData.files.length; i += 1) {
          var f = e.clipboardData.files[i];
          _this.droppedFiles.push(f);
        }
      }
      if (_this.droppedFiles.length > 0) _this.onGotFiles(_this.droppedFiles);
    };
    _this.getFilesOrFolder = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (item) {
        var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        if (item != null) {
          if (item.isFile) {
            return new Promise(function (resolve) {
              item.file(function (file) {
                file.folder = path;
                file.filepath = path + file.name;
                resolve([file]);
              });
            });
          }
          if (item.isDirectory) {
            var files = [];
            files.push(Object.assign({}, item, {
              folder: `${path + item.name}/`
            }));
            var dirReader = item.createReader();
            var inDir = yield new Promise(function (resolve) {
              dirReader.readEntries(function (entries) {
                var entriesPromises = [];
                for (var entr of entries) entriesPromises.push(_this.getFilesOrFolder(entr, `${path + item.name}/`));
                resolve(Promise.all(entriesPromises));
              });
            });
            inDir.forEach(function (f) {
              if (Array.isArray(f)) {
                files.push.apply(files, (0, _toConsumableArray2.default)(f));
              } else {
                files.push(f);
              }
            });
            return files;
          }
        }
        return [];
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    _this.onDrop = function (ev) {
      ev.preventDefault();
      var asyncNow = function () {
        var _ref2 = (0, _asyncToGenerator2.default)(function* () {
          _this.droppedFiles = [];
          var dt = ev.dataTransfer;
          _this.droppedFiles = yield _this.getFilesWebkitDataTransferItems(dt.items);
          _this.onGotFiles(_this.droppedFiles);
          _this.dragOverEndHandler(ev);
        });
        return function asyncNow() {
          return _ref2.apply(this, arguments);
        };
      }();
      asyncNow().catch(function (err) {
        return void 0;
      });
    };
    _this.onGotFiles = function (files) {
      if (_this.acceptedHandlers.length > 1) {
        var _Dialog$instance;
        var handlers = [];
        for (var handler of _this.acceptedHandlers) {
          handlers.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, {
            title: handler.multiHandlerDialogText,
            onPress: _this.sendFilesToHandlerDialog(handler, files)
          }, handler.id));
        }
        (_Dialog$instance = _Dialog.Dialog.instance) == null || _Dialog$instance.open({
          content: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              type: _MaterialText.MaterialTextTypes.H6,
              children: _I18n.I18n.m.getMessage('selectFileHandler')
            }), handlers]
          })
        });
      } else if (_this.acceptedHandlers.length === 1) {
        _this.sendFilesToHandler(_this.acceptedHandlers[0], files);
      }
    };
    _this.sendFilesToHandlerDialog = function (handler, files) {
      return function (_e) {
        var _Dialog$instance2;
        (_Dialog$instance2 = _Dialog.Dialog.instance) == null || _Dialog$instance2.close();
        _this.sendFilesToHandler(handler, files);
      };
    };
    _this.state = {
      backgroundColor: 'transparent',
      countedFiles: 1
    };
    return _this;
  }
  (0, _inherits2.default)(FileDropZone, _PureComponent);
  return (0, _createClass2.default)(FileDropZone, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      document.addEventListener('paste', this.onPaste);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      document.removeEventListener('paste', this.onPaste);
    }
  }, {
    key: "render",
    value: function render() {
      var id = this.props.id;
      var backgroundColor = this.state.backgroundColor;
      return (0, _jsxRuntime.jsxs)("div", {
        id: `drop_zone_${id}`,
        style: Object.assign({
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }, this.props.style),
        onDragEnter: this.dragStartHandler,
        children: [(0, _jsxRuntime.jsx)("div", {
          style: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            pointerEvents: backgroundColor !== 'transparent' ? 'none' : 'auto',
            zIndex: 1
          },
          children: this.props.children
        }), (0, _jsxRuntime.jsx)("div", {
          onDragEnter: this.dragStartHandler2,
          onDragOver: this.dragOverHandler,
          onDragLeave: this.dragOverEndHandler,
          onDragExit: this.dragOverEndHandler,
          onDrop: this.onDrop,
          onPaste: this.onPaste,
          style: {
            backgroundColor: backgroundColor,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            pointerEvents: backgroundColor === 'transparent' ? 'none' : 'auto',
            opacity: backgroundColor === 'transparent' ? 0 : 1,
            zIndex: 100000,
            justifyContent: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              justifyContent: 'center',
              alignSelf: 'center',
              height: '100%'
            },
            pointerEvents: "none",
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              centeredBox: true,
              centeredText: true,
              type: _MaterialText.MaterialTextTypes.H6,
              color: _ThemeManager.ThemeManager.style.dropFileTextColor,
              children: this.getText()
            })
          })
        })]
      });
    }
  }, {
    key: "checkFilter",
    value: function checkFilter(filt, type) {
      if (filt === type) return true;
      if (filt.indexOf('*') >= 0) {
        var ind = filt.indexOf('*');
        if (type.length > ind && type.substr(0, ind) === filt.substr(0, ind)) {
          return true;
        }
      }
      return filt === '*';
    }
  }, {
    key: "getText",
    value: function getText() {
      if (this.state.countedFiles === 1) return _I18n.I18n.m.getMessage('dropHint');
      return _I18n.I18n.m.getMessage('dropHintMulti');
    }
  }, {
    key: "getFilesWebkitDataTransferItems",
    value: function () {
      var _getFilesWebkitDataTransferItems = (0, _asyncToGenerator2.default)(function* (dataTransferItems) {
        var _this2 = this;
        return new Promise(function (resolve, _reject) {
          var entriesPromises = [];
          for (var it of dataTransferItems) entriesPromises.push(_this2.getFilesOrFolder(it.webkitGetAsEntry()));
          Promise.all(entriesPromises).then(function (entries) {
            var files = [];
            entries.forEach(function (f) {
              return files.push.apply(files, (0, _toConsumableArray2.default)(f));
            });
            resolve(files);
          }).catch(function (err) {
            return void 0;
          });
        });
      });
      function getFilesWebkitDataTransferItems(_x2) {
        return _getFilesWebkitDataTransferItems.apply(this, arguments);
      }
      return getFilesWebkitDataTransferItems;
    }()
  }, {
    key: "sendFilesToHandler",
    value: function sendFilesToHandler(handler, files) {
      handler.onGotFile(files);
    }
  }]);
}(_react.PureComponent);
FileDropZone.types = {
  allFiles: '*/*',
  audio: 'audio/*',
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  images: 'image/*',
  pdf: 'application/pdf',
  plainText: 'text/plain',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  video: 'video/*',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/zip'
};