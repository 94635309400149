import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { Daytime, } from 'upmesh-core/src/client/query/entities/WorkingTimeModelEntity';
import { I18n } from '../../i18n/I18n';
export function CompanyWorkingTimeModelItem(props) {
    const renderTimeCell = (e, _column, _index, smallLayout) => {
        const time = e[_column.keyInData];
        const format = CurrentUser.settings.timeFormat;
        const firstLine = time == null
            ? '-'
            : `${Daytime.toString(time.start, false, format)}-${Daytime.toString(time.stop, false, format)} ${format === '24' ? I18n.m.getMessage('oClock') : ''}`;
        const secondLine = time == null
            ? ''
            : `${Daytime.toString(time.pause, true)} ${I18n.m.getMessage('minutesShort')} ${I18n.m.getMessage('pause')}`;
        return (<View key={`times_${e.id}_${_column.keyInData}`} style={{
                flex: 1,
                justifyContent: 'flex-start',
                paddingHorizontal: 16,
                alignItems: 'center',
                alignSelf: smallLayout ? 'flex-start' : 'center',
            }}>
        <MaterialText type={MaterialTextTypes.Body2}>{firstLine}</MaterialText>
        <MaterialText type={MaterialTextTypes.Body2}>{secondLine}</MaterialText>
      </View>);
    };
    const renderSection = (s) => {
        const data = [];
        data.push({
            id: `${s.validFromDay}_${s.validFromMonth}`,
            ...s.workingTime,
        });
        const weekdays = I18n.m.dateCurrent.weekdays();
        return (<View style={{ width: '100%', paddingBottom: 24 }}>
        <MaterialText strong>
          {I18n.m.getMessage('validFrom')}: {s.validFromDay < 10 ? `0${s.validFromDay}` : s.validFromDay}.{' '}
          {I18n.m.dateCurrent.monthsNames()[s.validFromMonth - 1]}
        </MaterialText>
        <Table tableName="CompanyWorkingTimeModelView" actionItemsLength={0} maxHeight={0} data={data} sortBy="id" sortDirection="asc" headerAlignment="center" columns={[
                {
                    title: weekdays[1],
                    keyInData: 'mon',
                    style: { width: 200 },
                    sortable: false,
                    cellRenderer: renderTimeCell,
                    dataType: 'string',
                },
                {
                    title: weekdays[2],
                    keyInData: 'tue',
                    style: { width: 200 },
                    sortable: false,
                    cellRenderer: renderTimeCell,
                    dataType: 'string',
                },
                {
                    title: weekdays[3],
                    keyInData: 'wed',
                    style: { width: 200 },
                    sortable: false,
                    cellRenderer: renderTimeCell,
                    dataType: 'string',
                },
                {
                    title: weekdays[4],
                    keyInData: 'thi',
                    style: { width: 200 },
                    sortable: false,
                    cellRenderer: renderTimeCell,
                    dataType: 'string',
                },
                {
                    title: weekdays[5],
                    keyInData: 'fri',
                    style: { width: 200 },
                    sortable: false,
                    cellRenderer: renderTimeCell,
                    dataType: 'string',
                },
                {
                    title: weekdays[6],
                    keyInData: 'sat',
                    style: { width: 200 },
                    sortable: false,
                    cellRenderer: renderTimeCell,
                    dataType: 'string',
                },
                {
                    title: weekdays[0],
                    keyInData: 'sun',
                    style: { width: 200 },
                    sortable: false,
                    cellRenderer: renderTimeCell,
                    dataType: 'string',
                },
            ]}/>
      </View>);
    };
    return (<View style={{ width: '100%' }}>
      <MaterialText>
        {I18n.m.getMessage('workingTimeModelPostRecordingInDaysText', { count: props.wtm.postRecordingInDays })}
      </MaterialText>
      <View style={{ height: 16 }}/>
      {props.wtm.sections != null && props.wtm.sections.map(renderSection)}
      {props.openMenu && (<View style={{ position: 'absolute', top: 16, right: 8 }}>
          <Icon icon="dots-vertical" toolTip="" onPress={props.openMenu}/>
        </View>)}
    </View>);
}
