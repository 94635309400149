var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _reactNativeSvg = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react-native-svg"));
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var CircularProgressBar = function CircularProgressBar(_ref) {
  var file = _ref.file,
    _ref$progress = _ref.progress,
    progress = _ref$progress === void 0 ? 0 : _ref$progress,
    _ref$error = _ref.error,
    error = _ref$error === void 0 ? false : _ref$error,
    _ref$size = _ref.size,
    size = _ref$size === void 0 ? 24 : _ref$size,
    _ref$color = _ref.color,
    color = _ref$color === void 0 ? _ThemeManager.ThemeManager.style.brandPrimary : _ref$color,
    _ref$width = _ref.width,
    width = _ref$width === void 0 ? 3 : _ref$width,
    canCancel = _ref.canCancel,
    onCheckError = _ref.onCheckError,
    onCancelUpload = _ref.onCancelUpload,
    connectedToServer = _ref.connectedToServer,
    _ref$showErrorIconFor = _ref.showErrorIconForMobile,
    showErrorIconForMobile = _ref$showErrorIconFor === void 0 ? false : _ref$showErrorIconFor,
    _ref$touchDevice = _ref.touchDevice,
    touchDevice = _ref$touchDevice === void 0 ? false : _ref$touchDevice;
  var radius = (size - width) / 2;
  var circumference = 2 * Math.PI * radius;
  var strokeDashoffset = circumference - progress / 100 * circumference;
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    hovered = _useState2[0],
    setHovered = _useState2[1];
  var handlePress = function handlePress() {
    if (file.error) onCheckError(file);else if (canCancel) onCancelUpload(file);
  };
  var renderProgressCircle = function renderProgressCircle() {
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        padding: 6
      },
      children: (0, _jsxRuntime.jsxs)(_reactNativeSvg.default, {
        width: size,
        height: size,
        children: [(0, _jsxRuntime.jsx)(_reactNativeSvg.Circle, {
          cx: size / 2,
          cy: size / 2,
          r: radius,
          stroke: error ? _ThemeManager.ThemeManager.style.brandDanger : _ThemeManager.ThemeManager.style.brandLight,
          strokeWidth: width,
          fill: "none"
        }), (0, _jsxRuntime.jsx)(_reactNativeSvg.Circle, {
          cx: size / 2,
          cy: size / 2,
          r: radius,
          stroke: error ? _ThemeManager.ThemeManager.style.brandDanger : color,
          strokeWidth: width,
          fill: "none",
          strokeDasharray: circumference,
          strokeDashoffset: strokeDashoffset,
          strokeLinecap: "round",
          rotation: "-90",
          origin: `${size / 2}, ${size / 2}`
        })]
      })
    });
  };
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    style: {
      flexDirection: 'row'
    },
    children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.Pressable, Object.assign({
      style: Object.assign({
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }, _reactNativeWeb.Platform.OS === 'web' && {
        cursor: canCancel ? 'pointer' : 'default'
      })
    }, _reactNativeWeb.Platform.OS === 'web' && !touchDevice ? {
      onMouseEnter: function onMouseEnter() {
        return setHovered(true);
      },
      onMouseLeave: function onMouseLeave() {
        return setHovered(false);
      }
    } : {}, {
      children: [!hovered && !error && canCancel && _reactNativeWeb.Platform.OS === 'web' && connectedToServer && renderProgressCircle(), (!error && !canCancel && _reactNativeWeb.Platform.OS === 'web' || !canCancel && !connectedToServer && _reactNativeWeb.Platform.OS === 'web' && !error) && (0, _jsxRuntime.jsx)(_Icon.Icon, {
        toolTip: "",
        icon: "progress-upload",
        color: _ThemeManager.ThemeManager.style.brandSidebar,
        onPress: undefined
      }), !hovered && _reactNativeWeb.Platform.OS !== 'web' && !(showErrorIconForMobile && error) && renderProgressCircle(), (hovered && canCancel && !error || canCancel && !connectedToServer && !error) && (0, _jsxRuntime.jsx)(_Icon.Icon, {
        toolTip: "",
        icon: "close",
        color: _ThemeManager.ThemeManager.style.brandSidebar,
        backgroundColor: !connectedToServer ? 'transparent' : _ThemeManager.ThemeManager.style.brandLight,
        onPress: handlePress
      }), error && _reactNativeWeb.Platform.OS === 'web' && !touchDevice && (0, _jsxRuntime.jsx)(_Icon.Icon, {
        toolTip: "",
        icon: "alert-circle-outline",
        color: _ThemeManager.ThemeManager.style.brandDanger,
        onPress: handlePress
      }), error && (_reactNativeWeb.Platform.OS !== 'web' || touchDevice) && showErrorIconForMobile && (0, _jsxRuntime.jsx)(_Icon.Icon, {
        toolTip: "",
        icon: "alert-circle-outline",
        color: _ThemeManager.ThemeManager.style.brandDanger,
        onPress: handlePress
      })]
    }))
  });
};
var _default = exports.default = CircularProgressBar;