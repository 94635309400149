import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { Card } from 'materialTheme/src/theme/components/Card';
import { EmptyState } from 'materialTheme/src/theme/components/EmptyState';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useContext, useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { EntriesPerUserForDay } from 'upmesh-core/src/client/query/entities/TimeTrackingCombinedEntities';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CompanyUser } from '../companies/member/CompanyUser';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { CombinedExtraPayTrackingDialogsControl } from './control/CombinedExtraPayTrackingDialogsControl';
import { openTimeTrackingDialog } from './dialogs/openTimeTrackingDialog';
const emptyStateImage = require('../../assets/img/no_abcence_requests.png');
export function ApprovalsView(props) {
    const connection = useContext(ConnectionContext);
    const [data, setData] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [buttonHeight, setButtonHeight] = useState(0);
    const [updateCounter, setUpdateCounter] = useState(0);
    const loadData = async () => {
        let timeTrackings = [];
        let extraPayTrackings = [];
        let absences = [];
        const absence = UpmeshClient.instance.modals.absence.getRemote;
        const timeTracking = UpmeshClient.instance.modals.timeTracking.getRemote;
        const extraPayTracking = UpmeshClient.instance.modals.extraPayTracking.getRemote;
        const { me } = CompanyUserInfo;
        if (!connection.connectedToServer || !me)
            return [];
        const collection = [];
        try {
            const filter = me.payroll && showAll
                ? `isBlocked eq 'open' and deleted ne true`
                : `nextApprovalStepFor/userId eq '${me.userId}' and isBlocked eq 'open' and deleted ne true`;
            timeTrackings = await timeTracking.get({ filter });
            for (const item of timeTrackings) {
                if (item.ends) {
                    const m = await CompanyUser.load(await item.getMemberId());
                    const s = item.nextApprovalStepFor?.find((a) => a.userId === me.userId);
                    const itemAgeInDays = EntriesPerUserForDay.dateDiffInDays(item.starts, new Date());
                    let taskName = '?';
                    if (item.taskId)
                        taskName = (await UpmeshClient.instance.modals.task.getById(item.taskId)).getName();
                    let costCenterName = '?';
                    if (item.costCenterId)
                        costCenterName = (await UpmeshClient.instance.modals.costCenter.getById(item.costCenterId)).getName();
                    collection.push({
                        iconText: `${taskName} | ${costCenterName}`,
                        itemType: 'timeTracking',
                        day: new Date(item.starts),
                        infoText: `${I18n.m.dateCurrent.getLocalTimeString(item.starts)} -${I18n.m.dateCurrent.getLocalTimeString(item.ends)}`,
                        infoText2: `${I18n.m.dateCurrent.localeDateString(item.starts)}`,
                        memberName: m.getName(),
                        itemId: item.id,
                        memberId: m.member.id,
                        user: m.user ? m.user : new UserEntity({ firstname: m.member.firstName, lastname: m.member.lastName }),
                        canEdit: !!me.payroll || (s != null && s.canEditInDays != null && s.canEditInDays > itemAgeInDays),
                    });
                }
            }
            extraPayTrackings = await extraPayTracking.get({ filter });
            for (const item of extraPayTrackings) {
                const m = await CompanyUser.load(item.memberId);
                const s = item.nextApprovalStepFor?.find((a) => a.userId === me.userId);
                const itemAgeInDays = EntriesPerUserForDay.dateDiffInDays(item.day, new Date());
                let costCenterName = '?';
                if (item.costCenterId)
                    costCenterName = (await UpmeshClient.instance.modals.costCenter.getById(item.costCenterId)).getName();
                collection.push({
                    iconText: costCenterName,
                    day: new Date(item.day),
                    itemType: 'extraPay',
                    infoText: '',
                    memberName: m.getName(),
                    itemId: item.id,
                    memberId: m.member.id,
                    user: m.user ? m.user : new UserEntity({ firstname: m.member.firstName, lastname: m.member.lastName }),
                    canEdit: !!me.payroll || (s != null && s.canEditInDays != null && s.canEditInDays > itemAgeInDays),
                });
            }
            absences = await absence.get({ filter });
            for (const item of absences) {
                const m = await CompanyUser.load(item.memberId);
                let taskName = '?';
                if (item.taskId)
                    taskName = (await UpmeshClient.instance.modals.task.getById(item.taskId)).getName();
                let costCenterName = '?';
                const s = item.nextApprovalStepFor?.find((a) => a.userId === me.userId);
                const itemAgeInDays = EntriesPerUserForDay.dateDiffInDays(item.createdAt, new Date());
                if (item.costCenterId)
                    costCenterName = (await UpmeshClient.instance.modals.costCenter.getById(item.costCenterId)).getName();
                collection.push({
                    iconText: `${taskName} | ${costCenterName}`,
                    day: new Date(item.starts),
                    itemType: 'absence',
                    infoText: `${I18n.m.dateCurrent.localeDateString(item.starts)} - ${I18n.m.dateCurrent.localeDateString(item.ends)}`,
                    memberName: m.getName(),
                    itemId: item.id,
                    memberId: m.member.id,
                    user: m.user ? m.user : new UserEntity({ firstname: m.member.firstName, lastname: m.member.lastName }),
                    canEdit: !!me.payroll || (s != null && s.canEditInDays != null && s.canEditInDays > itemAgeInDays),
                });
            }
        }
        catch (err) {
            console.error('cant get time trackings', err);
        }
        collection.sort((a, b) => a.day.getTime() - b.day.getTime());
        return collection;
    };
    useEffect(() => {
        loadData()
            .then((a) => {
            setData(a);
        })
            .catch(DefaultErrorHandler.showDefaultErrorAlert);
    }, [showAll, updateCounter]);
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'ApprovalsView',
            readModelName: 'TimeTracking',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'ApprovalsViewExtra',
            readModelName: 'ExtraPayTracking',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'ApprovalsViewAbsence',
            readModelName: 'Absence',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        return () => {
            UpmeshClient.eventDispatcher.detach('TimeTracking', 'ApprovalsView');
            UpmeshClient.eventDispatcher.detach('ExtraPayTracking', 'ApprovalsViewExtra');
            UpmeshClient.eventDispatcher.detach('Absence', 'ApprovalsViewAbsence');
        };
    }, [updateCounter]);
    const sView = props.size.windowWidth <= ThemeManager.style.breakpointM;
    const maxHeight = sView ? props.size.contentHeight : props.size.contentHeight - 48;
    if (!connection.connectedToServer) {
        return (<View style={{ width: '100%', maxWidth: '100%', paddingHorizontal: 8 }}>
        <Card outerPadding={8} style={{ height: maxHeight - 16 }}>
          <View style={{ width: '100%', padding: 8, height: maxHeight - 16, justifyContent: 'center' }}>
            <MaterialText centeredText centeredBox fixedWidth="100%">
              {I18n.m.getMessage('offlineNotAvailable')}
            </MaterialText>
          </View>
        </Card>
      </View>);
    }
    const openTrackingEntry = (item) => (e) => {
        CombinedExtraPayTrackingDialogsControl.getFromTrackingEntry(item.itemId, item.day, item.itemType)
            .then((entry) => {
            if (entry)
                openTimeTrackingDialog({ entry })(e);
        })
            .catch(console.error);
    };
    const renderItem = ({ item }) => {
        const icon = item.itemType === 'timeTracking'
            ? 'timer-outline'
            : item.itemType === 'absences'
                ? 'calendar-arrow-right'
                : 'database-plus-outline';
        return (<Card style={{ width: '100%' }}>
        <View style={{ flexDirection: 'row', padding: 8, width: '100%' }}>
          <Ripple style={{ flex: 1, flexDirection: 'row' }} onPress={openTrackingEntry(item)}>
            <View style={{ paddingRight: 8 }}>
              <UserImage user={item.user} size={36}/>
            </View>
            <View style={{
                flex: 1,
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
              <View>
                <MaterialText centeredText>{item.user.getFullName()}</MaterialText>
                <View style={{ flexDirection: 'row' }}>
                  <Icon icon={icon} toolTip="" iconSize={16} outerSize={16}/>
                  <MaterialText centeredText type={MaterialTextTypes.Body2}>
                    {item.iconText}
                  </MaterialText>
                </View>
              </View>
              <View>
                <MaterialText centeredText type={MaterialTextTypes.Body2}>
                  {item.infoText}
                </MaterialText>
                {item.infoText2 && (<MaterialText centeredText type={MaterialTextTypes.Body2}>
                    {item.infoText2}
                  </MaterialText>)}
              </View>
            </View>
          </Ripple>
          <View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
            <Icon icon="pencil-outline" toolTip="" color={ThemeManager.style.defaultIconColor} disabled={!item.canEdit} onPress={CombinedExtraPayTrackingDialogsControl.editTrackingEntry(item.itemId, item.day, item.itemType)}/>
            <Icon icon="close-circle-outline" toolTip="" color={ThemeManager.style.brandDanger} onPress={() => {
                CombinedExtraPayTrackingDialogsControl.rejectTracking(item.itemId, { onType: item.itemType })
                    .then(() => {
                    setUpdateCounter(updateCounter + 1);
                })
                    .catch(DefaultErrorHandler.showDefaultErrorAlert)
                    .finally(() => LoadingEvents.instance.stopLoading());
            }}/>
            <Icon icon="check-circle-outline" toolTip="" color={ThemeManager.style.brandSuccess} onPress={() => {
                CombinedExtraPayTrackingDialogsControl.approveTracking(item.itemId, { onType: item.itemType })
                    .then(() => {
                    setUpdateCounter(updateCounter + 1);
                })
                    .catch(DefaultErrorHandler.showDefaultErrorAlert)
                    .finally(() => LoadingEvents.instance.stopLoading());
            }}/>
          </View>
        </View>
      </Card>);
    };
    return (<View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }} onLayout={(e) => {
            setButtonHeight(e.nativeEvent.layout.height);
        }}>
        {CompanyUserInfo.me?.payroll && !props.hideTabs && (<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
            <TabBar width={Math.min(1024, props.size.contentWidth)} selected={showAll ? 1 : 0} onChangedSelected={(i) => {
                if (i === 1 && !showAll)
                    setShowAll(true);
                else if (i === 0 && showAll)
                    setShowAll(false);
            }} backgroundColor="transparent" textColor="#FFFFFF" tabs={[
                { title: I18n.m.getMessage('approvalProcessMy') },
                { title: I18n.m.getMessage('approvalProcessAll') },
            ]}/>
          </View>)}
      </View>
      {data.length === 0 ? (<View style={{ width: '100%', height: maxHeight - buttonHeight }}>
          <EmptyState textColor={props.hideTabs ? undefined : '#FFFFFF'} imageSrc={emptyStateImage} description={I18n.m.getMessage('absenceNoOpenRequestsHelper')}/>
        </View>) : (<FlatList data={data} renderItem={renderItem} style={{ height: maxHeight - buttonHeight, width: '100%' }}/>)}
    </View>);
}
