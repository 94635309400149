import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import DraggableFlatList from 'materialTheme/src/theme/components/DraggableFlatList';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { SwitchListItem } from 'materialTheme/src/theme/components/SwitchListItem';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextAsync } from 'materialTheme/src/theme/components/text/MaterialTextAsync';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { ChangeApprovalProcess } from 'upmesh-core/src/client/commands/companies/approvalprocess/ChangeApprovalProcess';
import { CreateApprovalProcess } from 'upmesh-core/src/client/commands/companies/approvalprocess/CreateApprovalProcess';
import { ApprovalProcessEntity, } from 'upmesh-core/src/client/query/entities/ApprovalProcessEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
import { openApprovalProcessStepDialog } from './ApprovalProcessStepDialog';
export function ApprovalProcessEditCreateDialog(props) {
    const [process, setProcess] = useState(props.approvalProcess ||
        new ApprovalProcessEntity({
            forType: 'all',
            active: true,
            validFor: 'all',
            companyId: CompanyUserInfo.company?.id,
        }));
    const [tasklist, setTasklist] = useState([]);
    const [validForList, setValidForList] = useState([]);
    const [componentState, setComponentState] = useState('ready');
    const loadValidForList = async (forType) => {
        if (forType === 'all')
            return setValidForList([]);
        const l = [];
        if (forType === 'group') {
            const groups = await UpmeshClient.instance.modals.group.get({ filter: 'deleted ne true' });
            for (const group of groups) {
                l.push({ id: group.id, title: group.groupName });
            }
            return setValidForList(l);
        }
        const members = await UpmeshClient.instance.modals.companyMember.get({ filter: 'deleted ne true' });
        for (const member of members) {
            let user = new UserEntity({ firstname: member.firstName, lastname: member.lastName });
            if (member.userId) {
                user = await AuthClient.instance.modals.user.getById(member.userId);
            }
            l.push({ id: member.id, title: user.getFullName(), thumbnail: <UserImage size={36} user={user}/> });
        }
        return setValidForList(l);
    };
    const loadTaskList = async (forType) => {
        if (forType === 'all')
            return setTasklist([]);
        const l = [
            { title: I18n.m.getMessage('approvalProcessOnTypeAll'), data: undefined },
        ];
        if (forType === 'extraPay') {
            const extraPay = await UpmeshClient.instance.modals.extraPay.get({ filter: 'delete ne true' });
            for (const e of extraPay) {
                l.push({ title: e.getName(), data: e.id });
            }
            return setTasklist(l);
        }
        const tasks = await UpmeshClient.instance.modals.task.get({ filter: 'delete ne true' });
        for (const e of tasks) {
            if (forType === 'absence' && !e.productive) {
                l.push({ title: e.getName(), data: e.id });
            }
            else if (forType === 'timeTracking' && e.productive) {
                l.push({ title: e.getName(), data: e.id });
            }
        }
        return setTasklist(l);
    };
    useEffect(() => {
        loadTaskList(process.forType ? process.forType : 'all').catch(console.error);
        loadValidForList(process.validFor ? process.validFor : 'all').catch(console.error);
    }, []);
    const seletedTask = tasklist.findIndex((a) => a.data === process.forTaskOrExtraPay);
    const selectedValidForChips = [];
    if (process.validForExtra != null && process.validForExtra.length > 0) {
        process.validForExtra.forEach((a) => {
            const k = process.validFor === 'group' ? 'groupId' : 'memberId';
            const c = validForList.find((b) => b.id === a[k]);
            if (c)
                selectedValidForChips.push(c);
        });
    }
    const renderStep = ({ item, getIndex }) => {
        const a = item;
        const index = getIndex();
        const k = `${a.who.charAt(0).toUpperCase()}${a.who.slice(1)}`;
        const text = I18n.m.getMessage(`approvalProcessStepWho${k}`);
        return (<View style={{
                padding: 0,
                paddingVertical: 4,
                ...ThemeManager.noSelectionWebStyle(),
                alignItems: 'center',
                width: '100%',
                backgroundColor: 'white',
            }}>
        <View style={{
                padding: 4,
                ...ThemeManager.style.borderStyle,
                borderRadius: ThemeManager.style.borderRadius,
                ...ThemeManager.noSelectionWebStyle(),
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                backgroundColor: 'white',
            }}>
          <View style={{ width: 48 }}>
            <Icon icon="drag-horizontal" toolTip=""/>
          </View>
          <View style={{ flex: 1 }}>
            {a.who === 'member' ? (<MaterialTextAsync asyncText={async () => {
                    if (a.whoExtra) {
                        const m = await UpmeshClient.instance.modals.companyMember.getById(a.whoExtra);
                        let user = new UserEntity({ firstname: m.firstName, lastname: m.lastName });
                        if (m.userId)
                            user = await AuthClient.instance.modals.user.getById(m.userId);
                        return user.getFullName();
                    }
                    return text;
                }}/>) : (<MaterialText>{text}</MaterialText>)}
            {a.who !== 'payroll' ? (<MaterialText type={MaterialTextTypes.Body2}>
                {I18n.m.getMessage('approvalProcessStepEditableInDays', {
                    days: (a.canEditInDays ? a.canEditInDays : 0).toString(10),
                })}
              </MaterialText>) : null}
          </View>
          <View style={{ width: 40 }}>
            <Icon icon="dots-vertical" toolTip="" onPress={(e) => {
                const asyncNow = async () => {
                    let x = 0;
                    let y = 0;
                    try {
                        if (e.nativeEvent?.pageX != null && e.nativeEvent.pageY) {
                            x = e.nativeEvent.pageX;
                            y = e.nativeEvent.pageY;
                        }
                        else {
                            const s = await Measurement.measure(e.currentTarget);
                            x = s.pageX;
                            y = s.pageY;
                        }
                    }
                    catch (err) {
                        console.warn(err);
                    }
                    const client = {
                        height: 0,
                        width: 300,
                        x,
                        y,
                    };
                    const items = [];
                    items.push({
                        disabled: item.systemField != null,
                        thumbnail: { thumbnail: <Icon icon="pencil-outline" toolTip=""/>, width: 40 },
                        title: I18n.m.getMessage('edit'),
                        onPress: (e) => {
                            MenuRaw.instance?.close();
                            openApprovalProcessStepDialog({
                                step: item,
                                onAdd: (s) => {
                                    const steps = [...process.approvalSteps];
                                    steps[index] = s;
                                    setProcess(new ApprovalProcessEntity({ ...process, approvalSteps: steps }));
                                },
                            })(e);
                        },
                    });
                    items.push({
                        thumbnail: { thumbnail: <Icon icon="delete-outline" toolTip=""/>, width: 40 },
                        title: I18n.m.getMessage('delete'),
                        onPress: () => {
                            Routing.instance.alert.post({
                                title: I18n.m.getMessage('approvalProcessDeleteStepQuestionTitle'),
                                text: I18n.m.getMessage('approvalProcessDeleteStepQuestion'),
                                buttons: [
                                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={() => {
                                            Alert.instance?.close(() => {
                                                const s = [...process.approvalSteps];
                                                s.splice(index, 1);
                                                setProcess(new ApprovalProcessEntity({ ...process, approvalSteps: s }));
                                            });
                                        }} backgroundColor={ThemeManager.style.brandDanger}/>,
                                ],
                            });
                            MenuRaw.instance?.close();
                        },
                    });
                    MenuRaw.instance?.open({
                        client,
                        items,
                    });
                };
                asyncNow().catch((err) => console.error(err));
            }}/>
          </View>
        </View>
      </View>);
    };
    return [
        <DialogTitle>
      {props.approvalProcess
                ? I18n.m.getMessage('approvalProcessChangeTitle')
                : I18n.m.getMessage('approvalProcessAddTitle')}
    </DialogTitle>,
        <DialogContent>
      <FormInputFilled labelText={I18n.m.getMessage('approvalProcessName')} value={process.title} onChange={(a) => {
                setProcess(new ApprovalProcessEntity({ ...process, title: a }));
            }}/>
      <FormInputPicker labelText={I18n.m.getMessage('approvalProcessOnType')} list={[
                {
                    title: I18n.m.getMessage('approvalProcessOnTypeAll'),
                    data: 'all',
                },
                {
                    title: I18n.m.getMessage('approvalProcessOnTypeTimeTracking'),
                    data: 'timeTracking',
                },
                {
                    title: I18n.m.getMessage('approvalProcessOnTypeExtraPay'),
                    data: 'extraPay',
                },
                {
                    title: I18n.m.getMessage('approvalProcessOnTypeAbsence'),
                    data: 'absence',
                },
            ]} selectedIndex={process.forType === 'timeTracking'
                ? 1
                : process.forType === 'extraPay'
                    ? 2
                    : process.forType === 'absence'
                        ? 3
                        : 0} onChange={(a) => {
                setProcess(new ApprovalProcessEntity({ ...process, forType: a.value.data, forTaskOrExtraPay: undefined }));
                setTasklist([]);
                loadTaskList(a.value.data).catch(DefaultErrorHandler.showDefaultErrorAlert);
            }}/>
      {tasklist.length > 0 && (<FormInputPicker labelText={process.forType === 'absence'
                    ? I18n.m.getMessage('absenceTask')
                    : process.forType === 'extraPay'
                        ? I18n.m.getMessage('extraPay')
                        : I18n.m.getMessage('task')} selectedIndex={seletedTask > 0 ? seletedTask : 0} list={tasklist} onChange={(a) => {
                    setProcess(new ApprovalProcessEntity({ ...process, forTaskOrExtraPay: a.value.data }));
                }}/>)}
      <FormInputPicker labelText={I18n.m.getMessage('approvalProcessValidFor')} list={[
                { title: I18n.m.getMessage('approvalProcessValidForAll'), data: 'all' },
                { title: I18n.m.getMessage('approvalProcessValidForGroup'), data: 'group' },
                { title: I18n.m.getMessage('approvalProcessValidForMembers'), data: 'members' },
            ]} selectedIndex={process.validFor === 'group' ? 1 : process.validFor === 'members' ? 2 : 0} onChange={(a) => {
                setProcess(new ApprovalProcessEntity({ ...process, validFor: a.value.data, validForExtra: undefined }));
                setValidForList([]);
                loadValidForList(a.value.data).catch(DefaultErrorHandler.showDefaultErrorAlert);
            }}/>

      {validForList.length > 0 && (<View style={{ paddingLeft: 8, paddingBottom: 16 }}>
          <ChipGroup label={process.validFor === 'group'
                    ? I18n.m.getMessage('approvalProcessValidForGroup')
                    : I18n.m.getMessage('approvalProcessValidForMembers')} availableChips={validForList} chips={selectedValidForChips} onChanged={(a) => {
                    const validForExtra = [];
                    const k = process.validFor === 'group' ? 'groupId' : 'memberId';
                    for (const b of a) {
                        const i = {};
                        i[k] = b.id;
                        validForExtra.push(i);
                    }
                    setProcess(new ApprovalProcessEntity({ ...process, validForExtra }));
                }}/>
        </View>)}

      <View style={{ paddingLeft: 8, paddingBottom: 4 }}>
        <MaterialText type={MaterialTextTypes.Body2} strong>
          {I18n.m.getMessage('approvalProcessSteps')}
        </MaterialText>
        <View style={{ height: 8 }}/>
        <DraggableFlatList style={{ width: '100%', marginRight: Platform.OS !== 'web' ? 32 : 0 }} data={process.approvalSteps ? process.approvalSteps : []} renderItem={renderStep} keyExtractor={(item, i) => `${JSON.stringify(item)}-${i}`} onDragEnd={({ data }) => {
                setProcess(new ApprovalProcessEntity({ ...process, approvalSteps: data }));
            }}/>
        <View style={{ height: 8 }}/>
        <Icon toolTip="" borderStyle="dashed" outerSize={30} icon="plus" borderColor={ThemeManager.style.borderColor} color={ThemeManager.style.brandPrimary} onPress={openApprovalProcessStepDialog({
                onAdd: (data) => {
                    const s = process.approvalSteps ? [...process.approvalSteps, data] : [data];
                    setProcess(new ApprovalProcessEntity({ ...process, approvalSteps: s }));
                },
            })}/>
      </View>
      <SwitchListItem title={I18n.m.getMessage('active')} value={process.active} onChange={(e) => {
                setProcess(new ApprovalProcessEntity({ ...process, active: e }));
            }}/>
    </DialogContent>,
        <DialogActions>
      <ContainedButton disabled={componentState === 'loading'} title={I18n.m.getMessage('cancel')} onPress={() => Dialog.instance?.close()} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>
      <ContainedButton loading={componentState === 'loading'} title={props.approvalProcess
                ? I18n.m.getMessage('approvalProcessChangeButton')
                : I18n.m.getMessage('approvalProcessAddButton')} onPress={() => {
                setComponentState('loading');
                const c = props.approvalProcess
                    ? new ChangeApprovalProcess({ ...process }, process.id)
                    : new CreateApprovalProcess({ ...process });
                c.execute(AuthClient.instance.commandStore)
                    .then(() => {
                    Dialog.instance?.close();
                })
                    .catch((e) => {
                    setComponentState('ready');
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                });
            }}/>
    </DialogActions>,
    ];
}
export const openApprovalProcessEdiCreateDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <ApprovalProcessEditCreateDialog approvalProcess={props.approvalProcess}/>,
    });
};
