"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApproveTracking = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ServerEnvirement_1 = require("cqrs-core/build/src/server/webserver/ServerEnvirement");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var SendNotificationOnApprovedOrRejectedTracking_1 = require("../../../../server/webserver/commands/SendNotificationOnApprovedOrRejectedTracking");
var TrackingApproved_1 = require("../../../events/company/approvalprocess/TrackingApproved");
var OrderedApprovalProcess_1 = require("../../../query/entities/simple/OrderedApprovalProcess");
var ApproveTracking = function (_CommandRoot_1$Comman) {
  function ApproveTracking(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ApproveTracking);
    _this = _callSuper(this, ApproveTracking, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ApproveTracking';
    return _this;
  }
  (0, _inherits2.default)(ApproveTracking, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ApproveTracking, [{
    key: "getEntity",
    value: function () {
      var _getEntity = (0, _asyncToGenerator2.default)(function* () {
        if (this._entity == null) this._entity = yield CommandReadModels_1.CommandReadModels[this.data.onType === 'extraPay' ? 'extraPayTracking' : this.data.onType].getById(this.entityId);
        return this._entity;
      });
      function getEntity() {
        return _getEntity.apply(this, arguments);
      }
      return getEntity;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var _a$nextApprovalStepFo;
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var a = yield this.getEntity();
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${a.companyId}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && companyMember[0].payroll) {
          return true;
        }
        var isIn = (_a$nextApprovalStepFo = a.nextApprovalStepFor) == null ? void 0 : _a$nextApprovalStepFo.find(function (a) {
          return a.userId === userId;
        });
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && isIn != null) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var e = yield this.getEntity();
        var memberId = e.memberId,
          approvalProcessId = e.approvalProcessId;
        if (memberId == null || approvalProcessId == null) return null;
        var process = yield CommandReadModels_1.CommandReadModels.approvalProcess.getById(approvalProcessId);
        var approvals = e.approvals ? (0, _toConsumableArray2.default)(e.approvals) : [];
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        approvals.push({
          userId: userId,
          approved: true,
          date: new Date(),
          step: e.currentApprovalProcessStep ? e.currentApprovalProcessStep : 0
        });
        var s = yield OrderedApprovalProcess_1.OrderedApprovalProcess.getApprovalProcessStep(process, memberId, e.currentApprovalProcessStep ? e.currentApprovalProcessStep + 1 : 1);
        if (memberId != null) {
          var c = new SendNotificationOnApprovedOrRejectedTracking_1.SendNotificationOnApprovedOrRejectedTracking({
            approved: true,
            actionBy: userId,
            memberId: memberId,
            trackingForDay: e['starts'] ? e['starts'] : e['day'],
            trackingType: this.data.onType
          }, this.entityId);
          c.execute(ServerEnvirement_1.ServerEnvirement.writeStore).catch(function () {});
        }
        return new TrackingApproved_1.TrackingApproved(this.entityId, Object.assign({}, s, {
          approvals: approvals
        }), e.companyId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ApproveTracking = ApproveTracking;