import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { Keyboard, View } from 'react-native';
import { ChangePlanDetails } from 'upmesh-core/src/client/commands/plans/ChangePlanDetails';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
export const PlanEditPU = React.memo((props) => {
    const [plan, setPlan] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [planNameInput, setPlanNameInput] = useState(null);
    const [planNameError, setPlanNameError] = useState('');
    const [planNotesInput, setPlanNotesInput] = useState(null);
    const [projectTags, setProjectTags] = useState([]);
    const [projectTagGroups, setProjectTagGroups] = useState([]);
    const [currentTags, setCurrentTags] = useState([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [planTitleExists, setPlanTitleExists] = useState(false);
    const [plansList, setPlansList] = useState(CurrentProject.instance.getCurrentPlans());
    const submitNow = async () => {
        if (plan == null || planNameInput == null || planNotesInput == null) {
            return;
        }
        plan.title = planNameInput.getValue();
        plan.notes = planNotesInput.getValue();
        if (plan.title == null || plan.title.length === 0) {
            setIsLoading(false);
            setPlanNameError(I18n.m.getMessage('plansErrorTitleRequired'));
            return;
        }
        const tags = currentTags.map((c) => ({ tagName: c.id, color: c.backgroundColor, groupName: c.groupId }));
        const c = new ChangePlanDetails({
            tags,
            title: plan.title,
            notes: plan.notes,
        }, plan.id);
        try {
            await c.execute();
        }
        catch (e) {
            const planNameErr = I18n.m.getMessage(e.messageCode);
            setPlanNameError(planNameErr);
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
        Routing.instance.closeDialog(true)(null);
    };
    const submit = () => {
        setIsLoading(true);
        submitNow().catch((err) => console.debug(err));
    };
    const checkData = async () => {
        await DateLocale.waitFor(() => UpmeshClient.instance.ready);
        let currentPlan;
        if (props.data != null && props.data.planId != null && props.data.planId.length > 0) {
            currentPlan = await UpmeshClient.instance.modals.plan.getById(props.data.planId);
            const close = () => {
                Routing.instance.closeDialog(true)(null);
            };
            const catched = await DefaultErrorHandler.getProjectErrors(currentPlan.projectId, true, close);
            if (catched) {
                return;
            }
            const prTags = [];
            const prTagGroups = [];
            const project = await UpmeshClient.instance.modals.project.getById(currentPlan.projectId);
            project.tags?.forEach((t) => {
                prTags.push({
                    id: t.tagName,
                    groupId: t.groupName,
                    title: t.tagName,
                    backgroundColor: t.color,
                });
            });
            for (const key in project.tagGroups) {
                prTagGroups.push({
                    backgroundColor: project.tagGroups[key].color,
                    groupName: project.tagGroups[key].groupName,
                    id: project.tagGroups[key].groupName,
                });
            }
            const plTags = [];
            if (currentPlan != null && currentPlan.tags != null && currentPlan.tags.length > 0) {
                currentPlan.tags.forEach((t) => {
                    plTags.push({
                        id: t.tagName,
                        title: t.tagName,
                        backgroundColor: t.color,
                        groupId: t.groupName,
                    });
                });
            }
            setProjectTagGroups(prTagGroups);
            setCurrentTags([...plTags]);
            setProjectTags(prTags);
            setPlan(currentPlan);
        }
        else {
            Routing.instance.closeDialog(true)(null);
        }
    };
    const onPlansChanged = () => {
        setPlansList(CurrentProject.instance.getCurrentPlans());
    };
    useEffect(() => {
        checkData().catch((err) => console.debug(err));
        CurrentProject.plansChanged.attach(onPlansChanged);
        return () => {
            CurrentProject.plansChanged.detach(onPlansChanged);
            if (Keyboard != null) {
                Keyboard.dismiss();
            }
        };
    }, []);
    const changedTitle = (text) => {
        if (plan != null) {
            const planTitleExisting = plansList.find((inPlans) => inPlans.title === text && inPlans.id !== plan.id) != null;
            const saveButtonIsDisabled = planTitleExisting || text.length < 1;
            setPlanTitleExists(planTitleExisting);
            setSaveButtonDisabled(saveButtonIsDisabled);
        }
    };
    const onChangedForm = () => {
        setSaveButtonDisabled(false);
    };
    const onChangedTags = (chips) => {
        setSaveButtonDisabled(false);
        setCurrentTags([...chips]);
    };
    const getTitleErrorText = () => {
        let titleErrorText = '';
        if (planTitleExists) {
            titleErrorText = I18n.m.getMessage('planEditTitleExists');
        }
        else if (planNameError.length > 0) {
            titleErrorText = planNameError;
        }
        return titleErrorText;
    };
    return plan == null ? (<Spinner />) : ([
        <DialogContent key="content">
        <DialogTitle inContent>{I18n.m.getMessage('plansFormTitle')}</DialogTitle>
        <FormInputFilled ref={(p) => setPlanNameInput(p)} labelText={`${I18n.m.getMessage('planTitle')}*`} autoFocus={false} initValue={plan.title} name="projectName" autoCompleteWeb="projectName" disabled={isLoading} error={getTitleErrorText().length > 0} helperText={getTitleErrorText()} maxLength={100} onChange={changedTitle}/>
        <ChipGroup label="Tags" textColor="#FFFFFF" bordered={false} key={`test${projectTags.length}`} availableChips={projectTags} chips={currentTags} chipGroups={projectTagGroups} sortFormByGroup onChanged={onChangedTags} tooltipAddChip={I18n.m.getMessage('addTags')}/>
        <View style={{ height: 16 }}/>
        <FormInputFilled ref={(p) => setPlanNotesInput(p)} labelText={I18n.m.getMessage('plansNotes')} autoFocus={false} initValue={plan.notes} name="projectDescription" autoCompleteWeb="projectDescription" disabled={isLoading} numberOfLines={3} onChange={onChangedForm}/>
      </DialogContent>,
        <DialogActions key="actions" onBottom>
        <ContainedButton onPress={Routing.instance.closeDialog(true)} title={I18n.m.getMessage('abort')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton onPress={submit} title={I18n.m.getMessage('save')} textColor="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary} disabled={saveButtonDisabled}/>
      </DialogActions>,
    ]);
});
