"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EntriesPerUserForDay = void 0;
var AuthClient_1 = require("upmesh-auth-core/build/src/client/AuthClient");
var UserEntity_1 = require("upmesh-auth-core/build/src/client/query/entities/UserEntity");
var uuid = require("uuid");
var CombinedExtraPayAndTimeTrackingFilter_1 = require("../filter/CombinedExtraPayAndTimeTrackingFilter");
var EntriesPerUserForDay = function () {
  function EntriesPerUserForDay(obj) {
    (0, _classCallCheck2.default)(this, EntriesPerUserForDay);
    this.memberFirstname = '?';
    this.memberLastname = '?';
    this.staffNumber = '?';
    if (obj != null) {
      for (var k in obj) {
        try {
          if (k != null && !k.startsWith('$')) {
            if (this[k] instanceof Date && !(obj[k] instanceof Date)) {
              this[k] = new Date(obj[k]);
            } else if (obj[k] != null && Array.isArray(obj[k])) {
              this[k] = Array.from(obj[k]);
            } else {
              this[k] = obj[k];
            }
          }
        } catch (e) {}
      }
    }
    this.user;
  }
  return (0, _createClass2.default)(EntriesPerUserForDay, [{
    key: "id",
    get: function get() {
      return `${this.date.getFullYear()}-${this.date.getMonth() + 1}-${this.date.getDate()}-${this.memberId}-${this.lastChange}`;
    }
  }, {
    key: "user",
    get: function get() {
      if (this.userId == null) return undefined;
      if (this.userUpdateListener == null) {
        this.userUpdateListener = uuid.v4();
        AuthClient_1.AuthClient.instance.eventDispatcher.attach({
          attachKey: this.userUpdateListener,
          readModelName: 'User',
          callback: function callback(a) {
            a.entities.forEach(function (e) {
              if (e.entity && EntriesPerUserForDay.userCache.has(e.entity.id)) EntriesPerUserForDay.userCache.set(e.entity.id, e.entity);
            });
          }
        });
      }
      if (EntriesPerUserForDay.userCache.has(this.userId)) return EntriesPerUserForDay.userCache.get(this.userId);
      AuthClient_1.AuthClient.instance.modals.user.getById(this.userId).then(function (e) {
        EntriesPerUserForDay.userCache.set(e.id, e);
        return e;
      }).catch(function (err) {
        return undefined;
      });
      return undefined;
    }
  }], [{
    key: "dateDiffInDays",
    value: function dateDiffInDays(a, b) {
      var _MS_PER_DAY = 1000 * 60 * 60 * 24;
      var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }
  }, {
    key: "convert",
    value: function () {
      var _convert = (0, _asyncToGenerator2.default)(function* (data, startDate, to, userIds, companyMember) {
        var showMissing = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
        var daysDiff = EntriesPerUserForDay.dateDiffInDays(startDate, to);
        var entriesInDates = [];
        var lastChange = new Date();
        for (var i = 0; i <= daysDiff; i += 1) {
          var memberEntries = [];
          var day = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i, 12, 0, 0, 0);
          var today = new Date();
          today.setHours(13);
          if (day.getTime() > today.getTime()) break;
          var _loop = function* _loop(userId) {
            var member = companyMember.find(function (a) {
              return a.userId == null ? a.id === userId : a.userId === userId;
            });
            if (member != null && member.role !== 'inactive') {
              var memberId = member.id;
              var user = new UserEntity_1.UserEntity({
                id: member.userId || member.id,
                firstname: member.firstName,
                lastname: member.lastName
              });
              if (member.userId) {
                var u = yield EntriesPerUserForDay.getUser(member.userId);
                if (u) user = u;
              }
              var entry = {
                memberId: memberId,
                member: member,
                userId: member.userId,
                date: day,
                memberName: user.getFullName(),
                memberFirstname: `${user.firstname}`,
                memberLastname: `${user.lastname}`,
                staffNumber: `${member.staffNumber}`,
                memberEntries: [],
                totalTime: 0,
                projectTitles: [],
                tasks: [],
                targetTimeInMS: 0,
                holidayTimeInMS: 0,
                diffTimeInMS: 0
              };
              if (showMissing) {
                var w = yield CombinedExtraPayAndTimeTrackingFilter_1.CombinedExtraPayAndTimeTrackingEntity.getWorkingTimeModelForMember(day, memberId);
                if (w != null && w.targetTimeInMS > 0 && w.targetTimeInMS - w.holidayTimeInMS > 0) {
                  memberEntries.push(new EntriesPerUserForDay(Object.assign({}, entry, {
                    workinTimeModel: w.workinTimeModel,
                    targetTimeInMS: w.targetTimeInMS,
                    holidayTimeInMS: w.holidayTimeInMS,
                    targetStart: w.targetStart,
                    targetEnd: w.targetEnd,
                    targetPause: w.targetPause,
                    diffTimeInMS: 0 - w.targetTimeInMS
                  })));
                }
              }
            }
          };
          for (var userId of userIds) {
            yield* _loop(userId);
          }
          entriesInDates.push({
            date: day,
            lastChange: lastChange,
            totalTime: 0,
            entries: memberEntries
          });
        }
        var _loop2 = function* _loop2() {
          var e = data[_i];
          var starts = typeof e.day === 'number' ? new Date(e.day) : e.day;
          var d = new Date(starts.getFullYear(), starts.getMonth(), starts.getDate(), 12, 0, 0, 0);
          var dateExists = entriesInDates.findIndex(function (day) {
            return day.date.getTime() === d.getTime();
          });
          var title = e.projectTitle != null && e.projectTitle.length > 0 ? e.projectTitle : e.costCenterName;
          var member = companyMember.find(function (a) {
            return a.id === e.memberId;
          });
          var memberEntry = new EntriesPerUserForDay({
            memberId: e.memberId,
            userId: e.userId,
            date: d,
            memberName: e.memberName,
            member: member,
            memberFirstname: e.firstName,
            memberLastname: e.lastName,
            staffNumber: e.staffNumber,
            memberEntries: [e],
            totalTime: e.sumInMS,
            projectTitles: title && title.length > 0 ? [title] : [],
            tasks: e.taskNameFull ? [e.taskNameFull] : [],
            endTime: e.ends,
            startTime: e.starts,
            diffTimeInMS: e.sumInMS
          });
          if (dateExists > -1) {
            var updated = entriesInDates[dateExists];
            updated.totalTime += e.sumInMS;
            var memberExists = updated.entries.findIndex(function (m) {
              return m.memberId === e.memberId;
            });
            if (memberExists > -1) {
              var _m$projectTitles, _m$projectTitles2;
              var m = updated.entries[memberExists];
              m.totalTime += e.sumInMS;
              if (title && title.length > 0 && !((_m$projectTitles = m.projectTitles) != null && _m$projectTitles.includes(title))) (_m$projectTitles2 = m.projectTitles) == null || _m$projectTitles2.push(title);
              if (e.taskNameFull != null && !m.tasks.includes(e.taskNameFull)) m.tasks.push(e.taskNameFull);
              if (e.starts != null && (m.startTime == null || e.starts.getTime() < m.startTime.getTime())) m.startTime = e.starts;
              if (e.ends != null && (m.endTime == null || e.ends.getTime() > m.endTime.getTime())) m.endTime = e.ends;
              m.diffTimeInMS = m.totalTime - m.targetTimeInMS;
              m.memberEntries.push(e);
            } else {
              var w = yield CombinedExtraPayAndTimeTrackingFilter_1.CombinedExtraPayAndTimeTrackingEntity.getWorkingTimeModelForMember(d, memberEntry.memberId);
              memberEntry.targetTimeInMS = w.targetTimeInMS;
              memberEntry.holidayTimeInMS = w.holidayTimeInMS;
              memberEntry.workinTimeModel = w.workinTimeModel;
              memberEntry.diffTimeInMS = memberEntry.totalTime - w.targetTimeInMS;
              updated.entries = [].concat((0, _toConsumableArray2.default)(updated.entries), [memberEntry]);
            }
            entriesInDates[dateExists] = updated;
          } else {
            var _w = yield CombinedExtraPayAndTimeTrackingFilter_1.CombinedExtraPayAndTimeTrackingEntity.getWorkingTimeModelForMember(d, memberEntry.memberId);
            memberEntry.targetTimeInMS = _w.targetTimeInMS;
            memberEntry.holidayTimeInMS = _w.holidayTimeInMS;
            memberEntry.workinTimeModel = _w.workinTimeModel;
            entriesInDates.push({
              date: d,
              lastChange: lastChange,
              totalTime: memberEntry.totalTime,
              entries: [memberEntry]
            });
          }
        };
        for (var _i = 0; _i < data.length; _i += 1) {
          yield* _loop2();
        }
        entriesInDates.sort(function (a, b) {
          if (a.date.getTime() > b.date.getTime()) return -1;
          if (a.date.getTime() < b.date.getTime()) return 1;
          return 0;
        });
        entriesInDates.forEach(function (f) {
          f.entries.sort(function (a, b) {
            if (a.memberId > b.memberId) return -1;
            if (a.memberId < b.memberId) return 1;
            return 0;
          });
        });
        return entriesInDates;
      });
      function convert(_x, _x2, _x3, _x4, _x5) {
        return _convert.apply(this, arguments);
      }
      return convert;
    }()
  }, {
    key: "getUser",
    value: function () {
      var _getUser = (0, _asyncToGenerator2.default)(function* (userId) {
        if (EntriesPerUserForDay.userCache.has(userId)) return EntriesPerUserForDay.userCache.get(userId);
        try {
          var u = yield AuthClient_1.AuthClient.instance.modals.user.getById(userId);
          EntriesPerUserForDay.userCache.set(userId, u);
          return u;
        } catch (e) {
          return undefined;
        }
      });
      function getUser(_x6) {
        return _getUser.apply(this, arguments);
      }
      return getUser;
    }()
  }]);
}();
exports.EntriesPerUserForDay = EntriesPerUserForDay;
EntriesPerUserForDay.userCache = new Map();