import { scrollbarWidth } from '@xobotyi/scrollbar-width';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Datepicker } from 'materialTheme/src/theme/components/datepickerv2/Datepicker';
import { Dialog, DialogState } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { KeyboardEvents } from 'materialTheme/src/theme/components/utils/KeyboardEvents';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { EntriesPerUserForDay, } from 'upmesh-core/src/client/query/entities/TimeTrackingCombinedEntities';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
import { TimeTrackingCalendarDay } from './TimeTrackingCalendarDay';
import { TimeTrackingCalendarFunctions } from './TimeTrackingCalendarFunctions';
export function TimeTrackingCalendar(props) {
    const [hideCalender, setHideCalender] = useState(!SimpleStorage.get('TimeTrackingCalendarVisibility'));
    const showWeekends = true;
    const startsWithMonday = CurrentUser.settings.startWithMonday;
    const type = TimeTrackingCalendarFunctions.getSelectedType(props.start, props.end, startsWithMonday, props.periodSelection);
    const [selectedType, setSelectedType] = useState(type.type);
    const [periodIndex, setPeriodIndex] = useState(type.periodIndex);
    const [currentWidth, setCurrentWidth] = useState(0);
    const [holidays, setHolidays] = useState([]);
    const [regions, setRegions] = useState([]);
    const [scrollViewHeight, setScrollViewHeight] = useState(0);
    const [overtimeLastMonth, setOvertimeLastMonth] = useState(0);
    const [scrollViewContentHeight, setScrollViewContentHeight] = useState(0);
    const [dateQuickSelection, setDateQuickSelection] = useState([]);
    const monthNames = I18n.m.dateCurrent.monthsNames();
    let selectedEmployers = [];
    let selectedWorkTime = 0;
    let diffWorkTime = 0;
    const weekNumberWidth = 25;
    const breakpoint = 800;
    const weekdayNamesShort = I18n.m.dateCurrent.weekdayNamesShort();
    const intervall = useRef();
    const resetTime = (date, days = 0, ms = 0) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days, 0, 0, 0, ms);
    };
    const sMode = () => {
        return currentWidth <= breakpoint || ResizeEvent.current.contentHeight < 768;
    };
    const updateSelection = (startDate, endDate) => {
        if (props.onChangeSelected) {
            props.onChangeSelected({ startDate, endDate });
        }
    };
    const getEndDate = (type, startDate) => {
        if (type === 'date') {
            return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1, 0, 0, 0, -1);
        }
        if (type === 'week') {
            return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 7, 0, 0, 0, -1);
        }
        return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1, 0, 0, 0, -1);
    };
    const calculateStartOfWeek = (date) => {
        let dayCoefficient = date.getDay();
        if (startsWithMonday) {
            dayCoefficient = dayCoefficient === 0 ? 6 : dayCoefficient - 1;
        }
        return new Date(date.getTime() - dayCoefficient * 24 * 60 * 60 * 1000);
    };
    const selectDate = (type, startDate) => {
        selectedEmployers = [];
        selectedWorkTime = 0;
        let newStartDate = resetTime(startDate);
        let endDate = getEndDate(type, startDate);
        const sameTimePeriod = props.start &&
            props.end &&
            props.start.getTime() === newStartDate.getTime() &&
            props.end.getTime() === endDate.getTime();
        if (props.start) {
            if (type === 'month' || (!sMode() && sameTimePeriod)) {
                newStartDate = resetTime(new Date(props.start.getFullYear(), props.start.getMonth(), 1));
                endDate = getEndDate('month', newStartDate);
            }
            else if (sMode() && sameTimePeriod) {
                newStartDate = calculateStartOfWeek(props.start);
                endDate = getEndDate('week', newStartDate);
            }
        }
        if (type === 'date') {
            if (!sameTimePeriod && TimeTrackingCalendarFunctions.beforeDayPeriod == null) {
                TimeTrackingCalendarFunctions.beforeDayPeriod = {
                    startDate: props.start,
                    endDate: props.end,
                    type: selectedType,
                };
            }
            else if (sameTimePeriod && TimeTrackingCalendarFunctions.beforeDayPeriod != null) {
                newStartDate = TimeTrackingCalendarFunctions.beforeDayPeriod.startDate;
                endDate = TimeTrackingCalendarFunctions.beforeDayPeriod.endDate;
                TimeTrackingCalendarFunctions.beforeDayPeriod = undefined;
            }
        }
        else if (type === 'week' && selectedType !== 'date') {
            if (!sameTimePeriod && TimeTrackingCalendarFunctions.beforeWeekPeriod == null) {
                TimeTrackingCalendarFunctions.beforeWeekPeriod = {
                    startDate: props.start,
                    endDate: props.end,
                    type: selectedType,
                };
            }
            else if (sameTimePeriod && TimeTrackingCalendarFunctions.beforeWeekPeriod != null) {
                newStartDate = TimeTrackingCalendarFunctions.beforeWeekPeriod.startDate;
                endDate = TimeTrackingCalendarFunctions.beforeWeekPeriod.endDate;
                TimeTrackingCalendarFunctions.beforeWeekPeriod = undefined;
            }
        }
        const newType = TimeTrackingCalendarFunctions.getSelectedType(newStartDate, endDate, startsWithMonday, props.periodSelection);
        setSelectedType(newType.type);
        setPeriodIndex(newType.periodIndex);
        updateSelection(newStartDate, endDate);
    };
    const onPressNextLastSelection = (direction) => {
        if (!props.start || !props.end)
            return;
        const shiftMonth = (date, months) => {
            return new Date(date.getFullYear(), date.getMonth() + months, date.getDate(), 0, 0, 0, 0);
        };
        const currentType = TimeTrackingCalendarFunctions.getSelectedType(props.start, props.end, startsWithMonday, props.periodSelection);
        switch (currentType.type) {
            case 'period':
                if (currentType.periodIndex > -1 && props.periodSelection) {
                    const updatedIndex = direction === 'next' ? periodIndex - 1 : periodIndex + 1;
                    const newPeriod = updatedIndex > -1 && updatedIndex < props.periodSelection.length
                        ? props.periodSelection[updatedIndex]
                        : props.periodSelection[0];
                    updateSelection(newPeriod.from, newPeriod.to);
                    setPeriodIndex(updatedIndex > -1 && updatedIndex < props.periodSelection.length ? updatedIndex : 0);
                }
                break;
            case 'month': {
                const shift = direction === 'next' ? 1 : -1;
                const newStart = shiftMonth(props.start, shift);
                const newEnd = new Date(newStart.getFullYear(), newStart.getMonth() + 1, newStart.getDate() - 1);
                updateSelection(newStart, newEnd);
                break;
            }
            case 'week': {
                const daysToShift = direction === 'next' ? 7 : -7;
                const newStart = resetTime(props.start, daysToShift);
                selectDate(currentType.type, newStart);
                break;
            }
            case 'date': {
                const daysToShift = direction === 'next' ? 1 : -1;
                const newStart = resetTime(props.start, daysToShift);
                selectDate(currentType.type, newStart);
                break;
            }
            default: {
                const monthDiff = TimeTrackingCalendarFunctions.monthDiff(props.start, props.end) + 1;
                const dayDiff = TimeTrackingCalendarFunctions.dateDiffInDays(props.start, props.end);
                let newStart = props.start;
                let newEnd = props.end;
                if (direction === 'next') {
                    newStart = resetTime(props.end, 1);
                    if (monthDiff > 1) {
                        newEnd = shiftMonth(newStart, monthDiff - 1);
                    }
                    else if (dayDiff > 0) {
                        newEnd = resetTime(newStart, dayDiff + 1, -1);
                    }
                    else {
                        newEnd = resetTime(newStart, 2, -1);
                    }
                }
                else {
                    newEnd = resetTime(props.start, 0, -1);
                    if (monthDiff > 1) {
                        newStart = shiftMonth(newEnd, -(monthDiff - 1));
                    }
                    else if (dayDiff > 0) {
                        newStart = resetTime(newEnd, -dayDiff);
                    }
                    else {
                        newStart = resetTime(newEnd, -1);
                    }
                }
                updateSelection(newStart, newEnd);
                break;
            }
        }
    };
    const init = async () => {
        const { company } = CompanyUserInfo;
        let holidays = [];
        let regions = [];
        if (company) {
            holidays = await UpmeshClient.instance.modals.holiday.get({
                filter: `deleted ne true and companyId eq '${company.id}'`,
            });
            regions = await UpmeshClient.instance.modals.region.get({
                filter: `deleted ne true and companyId eq '${company.id}'`,
            });
        }
        setHolidays(holidays);
        setRegions(regions);
    };
    const updateRunning = () => {
        const today = new Date();
        const date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0, 0);
        const isSelected = (props.start == null && props.end == null) ||
            (props.start != null &&
                props.end != null &&
                date.getTime() >= props.start.getTime() &&
                date.getTime() <= props.end.getTime()) ||
            (props.start != null && props.end == null && props.start.getTime() <= date.getTime()) ||
            (props.end != null && props.start == null && props.end.getTime() >= date.getTime());
        if (isSelected) {
            const { entriesInDates } = props;
            const index = entriesInDates.findIndex((e) => e.date.getTime() === date.getTime());
            if (index > -1) {
                const employee = entriesInDates[index];
                let currentTime = 0;
                employee.entries.forEach((e) => {
                    e.memberEntries.forEach((f) => {
                        if (f.currentInMS)
                            currentTime += f.currentInMS;
                    });
                });
            }
        }
    };
    const loadQuickSelections = async () => {
        const dateQuickSelection = [];
        if (CompanyUserInfo.company != null && CompanyUserInfo.companySettings != null) {
            const d = await UpmeshClient.instance.modals.payrollAccountingPeriods.get({
                filter: `companyId eq '${CompanyUserInfo.company.id}'`,
                orderby: 'starts DESC',
                top: 2,
            });
            if (d.length > 0) {
                const starts = new Date(d[0].starts);
                const dateRange = EntriesPerUserForDay.dateDiffInDays(starts, new Date(d[0].ends));
                dateQuickSelection.push({
                    name: I18n.m.getMessage('timeTrackingFilterThisCalcMonth'),
                    days: starts.getTime(),
                    rangeInDays: dateRange,
                });
                if (d.length > 1) {
                    const startsLast = new Date(d[1].starts);
                    const dateRangeLast = EntriesPerUserForDay.dateDiffInDays(startsLast, new Date(d[1].ends));
                    dateQuickSelection.push({
                        name: I18n.m.getMessage('timeTrackingFilterLastCalcMonth'),
                        days: startsLast.getTime(),
                        rangeInDays: dateRangeLast,
                    });
                }
            }
        }
        setDateQuickSelection(dateQuickSelection);
    };
    const loadOvertime = async () => {
        if (periodIndex > -1) {
            const p = props.periodSelection && props.periodSelection[periodIndex];
            if (p &&
                CompanyUserInfo.company &&
                props.entriesInDates.length > 0 &&
                props.entriesInDates[0].entries.length > 0) {
                const { memberId } = props.entriesInDates[0].entries[0];
                const before = await UpmeshClient.instance.modals.payrollAccountingPeriodsPerMember.get({
                    filter: `companyId eq '${CompanyUserInfo.company.id}' and memberId eq '${memberId}' and to eq ${new Date(p.from.getTime() - 1).toISOString()}`,
                });
                if (before && before.length > 0 && before[0].unPaidOverTimeInMs && before[0].unPaidOverTimeInMs > 0) {
                    return before[0].unPaidOverTimeInMs;
                }
            }
        }
        return 0;
    };
    useEffect(() => {
        loadOvertime()
            .then((o) => {
            if (o !== overtimeLastMonth)
                setOvertimeLastMonth(o);
        })
            .catch(console.error);
    }, [periodIndex]);
    useEffect(() => {
        init().catch((err) => console.debug(err));
        if (intervall.current)
            clearInterval(intervall.current);
        intervall.current = setInterval(updateRunning, 30000);
        loadQuickSelections().catch(console.error);
        KeyboardEvents.onKeyDown((e) => {
            if (Dialog.instance?.dialogState === DialogState.opened)
                return;
            switch (e.keyCode) {
                case 37:
                    e.preventDefault();
                    onPressNextLastSelection('last');
                    break;
                case 39:
                    e.preventDefault();
                    onPressNextLastSelection('next');
                    break;
            }
        }, 'TimeTrackingCalendar');
        return () => {
            if (intervall.current)
                clearInterval(intervall.current);
            KeyboardEvents.remove('TimeTrackingCalendar');
        };
    }, [props.start, props.end]);
    const onResized = (e) => {
        setCurrentWidth(e.nativeEvent.layout.width);
    };
    const calenderVisibility = (show) => (_e) => {
        SimpleStorage.set('TimeTrackingCalendarVisibility', show ? '1' : '');
        setHideCalender(!show);
    };
    const renderSumView = () => {
        let totalSum = diffWorkTime || 0;
        let textLine = diffWorkTime
            ? `${diffWorkTime > 0 ? '+ ' : ''}${TimeTrackingEntity.msToTime(diffWorkTime, false, true)}`
            : '00:00';
        if (periodIndex > -1 && overtimeLastMonth > 0 && selectedEmployers.length === 1) {
            totalSum = diffWorkTime ? diffWorkTime + overtimeLastMonth : overtimeLastMonth;
            textLine = `${TimeTrackingEntity.msToTime(overtimeLastMonth, false, true)} ${textLine} = ${TimeTrackingEntity.msToTime(totalSum, false, true)}`;
        }
        return (<View style={{ flexDirection: 'row', justifyContent: 'center', height: 40, alignItems: 'center' }}>
        <Icon toolTip="" icon="sigma"/>
        <MaterialText centeredText centeredBox>
          {selectedWorkTime > 0 ? TimeTrackingEntity.msToTime(selectedWorkTime, false, true) : '00:00'} /
          {selectedWorkTime - diffWorkTime > 0
                ? TimeTrackingEntity.msToTime(selectedWorkTime - diffWorkTime, false, true)
                : '00:00'}
        </MaterialText>
        <Icon toolTip="" icon="plus-minus"/>
        <MaterialText color={totalSum >= 0 ? ThemeManager.style.brandSuccess : ThemeManager.style.brandDanger} centeredText centeredBox>
          {textLine}
        </MaterialText>
        <Icon toolTip="" icon="account-outline"/>
        <MaterialText centeredText centeredBox>
          {selectedEmployers.length > 0 ? selectedEmployers.length : '-'}
        </MaterialText>
      </View>);
    };
    const onPressDateSelector = (e) => {
        Datepicker.open({
            onChange: (f) => {
                if (!f['from'] || !f['to'])
                    return;
                const d = f;
                const startDate = new Date(d.from.getFullYear(), d.from.getMonth(), d.from.getDate(), 0, 0, 0, 0);
                const endDate = new Date(d.to.getFullYear(), d.to.getMonth(), d.to.getDate() + 1, 0, 0, 0, -1);
                const type = TimeTrackingCalendarFunctions.getSelectedType(startDate, endDate, startsWithMonday, props.periodSelection);
                setPeriodIndex(type.periodIndex);
                setSelectedType(type.type);
                updateSelection(startDate, endDate);
            },
            mode: 'date',
            range: true,
            selectedDate: { from: props.start, to: props.end },
            quickSelections: dateQuickSelection,
        }, e);
    };
    const onPressDateChip = (e) => {
        if (props.periodSelection == null || props.periodSelection.length === 0) {
            onPressDateSelector(e);
        }
        else {
            const p = [];
            for (const period of props.periodSelection) {
                p.push(<Ripple style={{ width: '100%', padding: 8, flexDirection: 'row', justifyContent: 'space-between' }} onPress={() => {
                        Dialog.instance?.close(() => {
                            setSelectedType('period');
                            updateSelection(period.from, period.to);
                        });
                    }}>
            <MaterialText>{`${monthNames[period.from.getMonth()]} ${period.from.getFullYear()}`}</MaterialText>
            <MaterialText>{`${I18n.m.dateCurrent.localeDateString(period.from)} - ${I18n.m.dateCurrent.localeDateString(period.to)}`}</MaterialText>
          </Ripple>);
            }
            Dialog.instance?.open({
                contentPadding: false,
                content: (<>
            <DialogTitle iconRight={{
                        icon: 'calendar',
                        onPress: (e) => {
                            Dialog.instance?.close(() => {
                                onPressDateSelector(e);
                            });
                        },
                        toolTip: I18n.m.getMessage('selectCustomDateRange'),
                    }}>
              {I18n.m.getMessage('timeTrackingChoosePeriod')}
            </DialogTitle>
            <DialogContent>{p}</DialogContent>
          </>),
            });
        }
    };
    const renderChangeChip = () => {
        let selectedText = '';
        if (selectedType === 'month' && props.start != null) {
            selectedText = monthNames[props.start.getMonth()];
            selectedText += ` ${props.start.getFullYear()}`;
        }
        else if (selectedType === 'week' && props.start != null) {
            selectedText = `${I18n.m.getMessage('calendarWeekShort')} ${TimeTrackingCalendarFunctions.getKW(props.start)}`;
            selectedText += ` ${props.start.getFullYear()}`;
        }
        else if (selectedType === 'date' && props.start != null) {
            selectedText = I18n.m.dateCurrent.localeDateString(props.start);
        }
        else if (props.start != null && props.end != null) {
            selectedText = `${I18n.m.dateCurrent.localeDateString(props.start)} - ${I18n.m.dateCurrent.localeDateString(props.end)}`;
        }
        else if (props.start != null) {
            selectedText = `> ${I18n.m.dateCurrent.localeDateString(props.start)}`;
        }
        else if (props.end != null) {
            selectedText = `< ${I18n.m.dateCurrent.localeDateString(props.end)}`;
        }
        return (<View>
        <Chip title={selectedText} thumbnail={<Icon icon="chevron-left" toolTip={`${I18n.m.getMessage('prev')} (${I18n.m.getMessage('hotkeyLeft')})`} onPress={() => onPressNextLastSelection('last')}/>} removeIconSize={24} removeIcon="chevron-right" removeIconTooltip={`${I18n.m.getMessage('next')} (${I18n.m.getMessage('hotkeyRight')})`} button onPressButton={() => onPressNextLastSelection('next')} onPressText={onPressDateChip}/>
      </View>);
    };
    const renderDay = (date, withMonth) => {
        let targetWorkingTime = 0;
        const employees = [];
        const holiToday = holidays.filter((h) => {
            const holiDate = new Date(h.date);
            const isDate = holiDate.getDate() === date.getDate() && holiDate.getMonth() === date.getMonth();
            return (holiDate.getFullYear() === date.getFullYear() && isDate) || (h.annual && isDate);
        });
        let holidayTooltip = '';
        if (holiToday.length > 0) {
            holiToday.forEach((h, index) => {
                const regionList = regions.filter((r) => {
                    return h.regionIds.indexOf(r.id) > -1;
                });
                let regionString = '';
                regionList.forEach((there, index) => {
                    regionString = regionString + (index !== 0 ? ', ' : '') + there.region;
                });
                holidayTooltip = `${holidayTooltip}${index === 0 ? '' : '; '}${h.name} ${I18n.m.getMessage('regions')}: ${regionString}`;
            });
        }
        const isSelected = (props.start == null && props.end == null) ||
            (props.start != null &&
                props.end != null &&
                date.getTime() >= props.start.getTime() &&
                date.getTime() <= props.end.getTime()) ||
            (props.start != null && props.end == null && props.start.getTime() <= date.getTime()) ||
            (props.end != null && props.start == null && props.end.getTime() >= date.getTime());
        let dateEntry;
        if (isSelected) {
            const { entriesInDates } = props;
            const index = entriesInDates.findIndex((e) => e.date.getTime() === date.getTime());
            if (index > -1) {
                dateEntry = entriesInDates[index];
                entriesInDates[index].entries.forEach((e) => {
                    employees.push(e.memberId);
                    targetWorkingTime += Math.max(e.targetTimeInMS - e.holidayTimeInMS, 0);
                });
            }
        }
        let timeTotalString = '';
        let diffTimeString = '';
        let diffTime = 0;
        let currentTime = 0;
        const today = new Date();
        if (dateEntry != null) {
            if (dateEntry.entries.length > 1)
                timeTotalString += `(${dateEntry.entries.length})${sMode() ? '\n' : ' '}`;
            if (isSelected) {
                selectedWorkTime += dateEntry.totalTime;
                dateEntry.entries.forEach((employee) => {
                    if (selectedEmployers.indexOf(employee.memberId) === -1) {
                        selectedEmployers.push(employee.memberId);
                    }
                    if (today.getFullYear() === date.getFullYear() &&
                        today.getMonth() === date.getMonth() &&
                        today.getDay() === date.getDay()) {
                        employee.memberEntries.forEach((f) => {
                            if (f.currentInMS)
                                currentTime += f.currentInMS;
                        });
                    }
                });
                timeTotalString += `${TimeTrackingEntity.msToTime(dateEntry.totalTime + currentTime, false, true)}`;
                diffTime = dateEntry.totalTime + currentTime - targetWorkingTime;
                diffTimeString = `${TimeTrackingEntity.msToTime(diffTime, false, true)}`;
            }
        }
        diffWorkTime += diffTime;
        return {
            date,
            day: (<TimeTrackingCalendarDay key={`renderDay_${date}`} diffTime={diffTime} onPress={() => selectDate('date', date)} isSelected={isSelected} diffTimeString={diffTimeString} holidayTooltip={holidayTooltip} timeTotalString={timeTotalString} sMode={sMode()} withMonth={withMonth} date={date}/>),
            workingTime: dateEntry != null ? dateEntry.totalTime + currentTime : 0,
            targetWorkingTime,
            employees,
        };
    };
    const renderDayNames = (sMode) => {
        const { showKW } = CurrentUser.settings;
        const renderedWeekNames = [];
        const weekdays = [];
        if (startsWithMonday || (!startsWithMonday && !showWeekends)) {
            weekdays.push(weekdayNamesShort[1].slice(0, 2));
            weekdays.push(weekdayNamesShort[2].slice(0, 2));
            weekdays.push(weekdayNamesShort[3].slice(0, 2));
            weekdays.push(weekdayNamesShort[4].slice(0, 2));
            weekdays.push(weekdayNamesShort[5].slice(0, 2));
            if (showWeekends) {
                weekdays.push(weekdayNamesShort[6].slice(0, 2));
                weekdays.push(weekdayNamesShort[0].slice(0, 2));
            }
        }
        else {
            weekdays.push(...weekdayNamesShort);
        }
        weekdays.push(I18n.m.getMessage('timeTrackingSum'));
        for (let i = 0; i < weekdays.length; i += 1) {
            const style = sMode && weekdays[i] === I18n.m.getMessage('timeTrackingSum') ? { width: 64 } : { flex: 1 };
            renderedWeekNames.push(<View key={`daynames_${i}_${weekdays[i]}`} style={{
                    ...style,
                    alignItems: weekdays[i] === I18n.m.getMessage('timeTrackingSum') ? 'flex-end' : 'flex-start',
                    justifyContent: 'center',
                    borderLeftColor: ThemeManager.style.borderColor,
                    borderLeftWidth: ThemeManager.style.borderWidth,
                    backgroundColor: weekdays[i] === I18n.m.getMessage('timeTrackingSum') ? '#f6f6f8' : '#ffffff',
                    paddingRight: weekdays[i] === I18n.m.getMessage('timeTrackingSum') ? 6 : 0,
                }}>
          {weekdays[i] === I18n.m.getMessage('timeTrackingSum') ? (<View>
              <Icon iconSize={24} outerSize={24} radius={0} icon="sigma" toolTip={I18n.m.getMessage('timeTrackingSum')}/>
            </View>) : (<View>
              <MaterialText>{weekdays[i]}</MaterialText>
            </View>)}
        </View>);
        }
        return (<View style={{
                width: '100%',
                flexDirection: 'row',
                height: weekNumberWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                borderBottomWidth: ThemeManager.style.borderWidth,
                paddingRight: scrollViewContentHeight > scrollViewHeight ? scrollbarWidth() : 0,
                backgroundColor: '#f6f6f8',
            }}>
        {showKW && <View style={{ backgroundColor: '#f6f6f8', width: weekNumberWidth }}/>}
        <View style={{
                flex: 1,
                flexDirection: 'row',
                height: weekNumberWidth,
            }}>
          {renderedWeekNames}
        </View>
      </View>);
    };
    const getFirstDisplayedDay = () => {
        const startDate = props.start;
        if ((startsWithMonday && startDate.getDay() === 1) || (!startsWithMonday && startDate.getDay() === 0)) {
            return startDate;
        }
        if (startsWithMonday && startDate.getDay() === 0) {
            return new Date(startDate.getTime() - 6 * 24 * 60 * 60 * 1000);
        }
        return new Date(startDate.getTime() - (startDate.getDay() - (startsWithMonday ? 1 : 0)) * 24 * 60 * 60 * 1000);
    };
    const renderWeek = (firstDay, firstLine) => {
        const { showKW } = CurrentUser.settings;
        const days = [];
        for (let i = 0; i < (showWeekends ? 7 : 5); i += 1) {
            days.push(renderDay(new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + i, 12, 0, 0, 0), firstLine && i === 0));
        }
        const renderedDays = [];
        let weeklyWorkingTime = 0;
        let weeklyTargetTime = 0;
        const weeklyEmployees = [];
        days.forEach((day) => {
            renderedDays.push(day.day);
            weeklyWorkingTime += day.workingTime;
            weeklyTargetTime += day.targetWorkingTime;
            day.employees.forEach((employer) => {
                if (weeklyEmployees.indexOf(employer) === -1) {
                    weeklyEmployees.push(employer);
                }
            });
        });
        const monday = new Date(firstDay.getTime());
        if (firstDay.getDay() === 0)
            monday.setDate(monday.getDate() + 1);
        const weekNumber = TimeTrackingCalendarFunctions.getKW(monday);
        const { sMode } = this;
        const fontSize = sMode
            ? ThemeManager.style.getScreenRelativePixelSize(12)
            : ThemeManager.style.getScreenRelativePixelSize(14);
        const sumStyle = sMode ? { width: 64 } : { flex: 1 };
        const diffTime = weeklyWorkingTime - weeklyTargetTime;
        return (<View key={`renderedWeek_${weekNumber}_${firstDay}`} style={{
                width: '100%',
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                flexDirection: 'row',
                height: sMode ? 96 : 40,
                overflow: 'hidden',
            }}>
        {showKW && (<Ripple onPress={() => selectDate('week', firstDay)} style={{
                    backgroundColor: '#f6f6f8',
                    width: weekNumberWidth,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'auto',
                }}>
            <View style={{ height: '100%', justifyContent: 'center' }}>
              <MaterialText type={MaterialTextTypes.Caption} centeredText centeredBox>
                {weekNumber}
              </MaterialText>
            </View>
          </Ripple>)}
        <View style={{
                flex: 1,
                flexDirection: 'row',
                overflow: 'hidden',
            }}>
          {renderedDays}
          <Ripple onPress={() => selectDate('week', firstDay)} key={`renderedWeekSum_${weekNumber}`} style={{
                ...sumStyle,
                flexDirection: 'column',
                borderLeftColor: ThemeManager.style.borderColor,
                borderLeftWidth: ThemeManager.style.borderWidth,
                justifyContent: 'center',
                backgroundColor: '#f6f6f8',
                paddingRight: 6,
                paddingTop: sMode ? 12 : 0,
            }}>
            <MaterialText fontSize={fontSize} fixedWidth="100%" textAlign="right" type={MaterialTextTypes.Body2}>{`${weeklyEmployees.length > 1 ? `(${weeklyEmployees.length})` : ''}${sMode ? '\n' : ''}${TimeTrackingEntity.msToTime(weeklyWorkingTime, false, true)}`}</MaterialText>

            <MaterialText fixedWidth="100%" fontSize={fontSize} textAlign="right" color={diffTime < 0 ? ThemeManager.style.brandDanger : ThemeManager.style.brandSuccess} type={MaterialTextTypes.Body2}>
              {`${diffTime > 0 ? '+ ' : ''}${TimeTrackingEntity.msToTime(diffTime, false, true)}`}
            </MaterialText>
          </Ripple>
        </View>
      </View>);
    };
    const renderWeeks = (maximum = 100) => {
        const weeks = [];
        let firstDayNextWeek = getFirstDisplayedDay();
        for (let i = 0; i < maximum; i += 1) {
            weeks.push(renderWeek(firstDayNextWeek, i === 0));
            firstDayNextWeek = new Date(firstDayNextWeek.getFullYear(), firstDayNextWeek.getMonth(), firstDayNextWeek.getDate() + 7, 0, 0, 0, 0);
            if (firstDayNextWeek.getTime() > props.end.getTime()) {
                i = maximum;
            }
        }
        return <View style={{ width: '100%' }}>{weeks}</View>;
    };
    const renderCalendar = () => {
        const content = renderWeeks();
        if (hideCalender)
            return null;
        return (<View style={{ width: '100%', height: 'auto' }}>
        {renderDayNames(sMode())}
        <ScrollView onLayout={(e) => setScrollViewHeight(e.nativeEvent.layout.height)} onContentSizeChange={(_width, height) => setScrollViewContentHeight(height)} style={{ maxHeight: Math.max(100, ResizeEvent.current.windowHeight / 2 - 200) }}>
          {content}
        </ScrollView>
      </View>);
    };
    return (<View onLayout={onResized} style={{
            width: '100%',
            paddingVertical: 8,
            paddingHorizontal: 16,
        }}>
      {currentWidth === 0 ? null : (<View style={{
                backgroundColor: '#FFFFFF',
                borderRadius: ThemeManager.style.borderRadius,
            }}>
          <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                borderBottomColor: ThemeManager.style.borderColor,
                borderBottomWidth: ThemeManager.style.borderWidth,
                height: 48,
                alignItems: 'center',
                alignContent: 'center',
                position: 'relative',
                width: '100%',
            }}>
            {renderChangeChip()}
            <View style={{ position: 'absolute', right: 8, top: 6 }}>
              {hideCalender ? (<Icon icon="chevron-up" toolTip="" onPress={calenderVisibility(true)}/>) : (<Icon icon="chevron-down" toolTip="" onPress={calenderVisibility(false)}/>)}
            </View>
          </View>

          {renderCalendar()}
          {renderSumView()}
        </View>)}
    </View>);
}
