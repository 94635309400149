var _a;
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeAbsence } from 'upmesh-core/src/client/commands/companies/absence/ChangeAbsence';
import { ApproveTracking, } from 'upmesh-core/src/client/commands/companies/approvalprocess/ApproveTracking';
import { RejectTracking } from 'upmesh-core/src/client/commands/companies/approvalprocess/RejectTracking';
import { ChangeExtraPayTracking } from 'upmesh-core/src/client/commands/companies/extrapay/ChangeExtraPayTracking';
import { DeleteExtraPayTracking } from 'upmesh-core/src/client/commands/companies/extrapay/DeleteExtraPayTracking';
import { ChangeTimeTracking, } from 'upmesh-core/src/client/commands/companies/timetracking/ChangeTimeTracking';
import { DeleteTimeTracking } from 'upmesh-core/src/client/commands/companies/timetracking/DeleteTimeTracking';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { CombinedExtraPayAndTimeTrackingEntity, CombinedExtraPayAndTimeTrackingFilter, } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { openAbsenceCreationChangeDialog } from '../../absences/AbsenceCreationChangeDialog';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { AddOrChangeExtraPayTrackDialog } from '../../extrapay/AddOrChangeExtraPayTrackDialog';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
import { AddTimeTrackDialog } from '../dialogs/AddTimeTrackDialog';
import { ChangeTimeTrackDialog } from '../dialogs/ChangeTimeTrackDialog';
export class CombinedExtraPayTrackingDialogsControl {
    static async approveTracking(entityId, data) {
        LoadingEvents.instance.startLoading();
        const a = new ApproveTracking(data, entityId);
        a.execute(AuthClient.instance.commandStore)
            .then(() => {
            Toast.instance?.open({ title: I18n.m.getMessage('trackingApproved') });
        })
            .catch(DefaultErrorHandler.showDefaultErrorAlert)
            .finally(() => LoadingEvents.instance.stopLoading());
    }
    static async rejectTracking(entityId, data) {
        LoadingEvents.instance.startLoading();
        const a = new RejectTracking(data, entityId);
        a.execute(AuthClient.instance.commandStore)
            .then(() => {
            Toast.instance?.open({ title: I18n.m.getMessage('trackingRejected') });
        })
            .catch(DefaultErrorHandler.showDefaultErrorAlert)
            .finally(() => LoadingEvents.instance.stopLoading());
    }
    static async getOnlineOrOfflineModels(date) {
        const offlineDate = await TimeTrackingEntity.offlineDateLimit();
        const onlyOnline = date.getTime() < offlineDate.getTime();
        const timeTracking = onlyOnline
            ? UpmeshClient.instance.modals.timeTracking.getRemote
            : UpmeshClient.instance.modals.timeTracking;
        const extraPayTracking = onlyOnline
            ? UpmeshClient.instance.modals.extraPayTracking.getRemote
            : UpmeshClient.instance.modals.extraPayTracking;
        const absence = onlyOnline ? UpmeshClient.instance.modals.absence.getRemote : UpmeshClient.instance.modals.absence;
        return { onlineOnly: onlyOnline, timeTracking, extraPayTracking, absence };
    }
}
_a = CombinedExtraPayTrackingDialogsControl;
CombinedExtraPayTrackingDialogsControl.openAddTimeTrackingDialog = (options) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        content: (<AddTimeTrackDialog hideTimes={options?.hideTimes} title={options?.title} selectedDate={options?.selectedDate} memberIds={options?.memberIds} start={options?.start} pause={options?.pause} end={options?.end}/>),
        fullscreenResponsive: true,
        contentPadding: false,
        scrollable: false,
    });
};
CombinedExtraPayTrackingDialogsControl.openMenu = (t) => (e) => {
    Measurement.measure(e.currentTarget)
        .then(async (s) => {
        const client = {
            x: s.pageX,
            height: s.height,
            y: s.pageY > ThemeManager.style.statusBarHeight ? s.pageY : s.y,
            width: 256,
        };
        const items = [];
        const isPayRol = !!CompanyUserInfo.me?.payroll &&
            !(t.type === 'TimeTrackingEntity' && t.orgEntry.absenceId != null);
        let canEdit = isPayRol;
        if (!canEdit) {
            try {
                if (t.type === 'TimeTrackingEntity') {
                    if (t.orgEntry.absenceId != null) {
                        canEdit =
                            (await new ChangeAbsence({ ...t.orgEntry }, t.orgEntry.absenceId).canI()) ===
                                true;
                    }
                    else
                        canEdit =
                            (await new ChangeTimeTracking({ ...t.orgEntry, changeTime: new Date() }, t.id).canI()) === true;
                }
                else {
                    canEdit = (await new ChangeExtraPayTracking({ ...t.orgEntry }, t.id).canI()) === true;
                }
            }
            catch (e) {
                console.debug('cant edit', e);
            }
        }
        if (canEdit) {
            items.unshift({
                text: I18n.m.getMessage('edit'),
                onPress: _a.editEntry(t),
                thumbnail: { thumbnail: <Icon icon="pencil-outline" toolTip=""/>, width: 24 },
            });
        }
        if (t.type === 'TimeTrackingEntity' && t.ends == null) {
            items.unshift({
                text: I18n.m.getMessage('timeTrackingStopTimer'),
                onPress: (e) => {
                    _a.stopTiming(e)(t);
                },
                thumbnail: { thumbnail: <Icon icon="stop-circle-outline" toolTip=""/>, width: 24 },
            });
        }
        if (canEdit && t.orgEntry.absenceId == null) {
            items.push({
                text: I18n.m.getMessage('delete'),
                onPress: _a.deleteEntry(t),
                thumbnail: { thumbnail: <Icon icon="delete-outline" toolTip=""/>, width: 24 },
            });
        }
        Menu.instance?.open({
            client,
            items,
        });
    })
        .catch((err) => console.debug(err));
};
CombinedExtraPayTrackingDialogsControl.stopTiming = (t, openEditDialog = false) => (_e) => {
    const asyncNow = async () => {
        Menu.instance?.close();
        if (openEditDialog) {
            const entry = await UpmeshClient.instance.modals.timeTracking.getById(t.id);
            entry.ends = new Date();
            const convert = await CombinedExtraPayAndTimeTrackingEntity.convert(entry);
            _a.editEntry(convert)(null);
        }
        else {
            try {
                const data = {
                    ends: new Date(),
                    changeTime: new Date(),
                };
                const create = new ChangeTimeTracking(data, t.id);
                await create.execute(ClientStore.commandStore);
            }
            catch (err) {
                DefaultErrorHandler.showDefaultErrorAlert(err);
            }
        }
    };
    asyncNow().catch(DefaultErrorHandler.showDefaultErrorAlert);
};
CombinedExtraPayTrackingDialogsControl.deleteEntry = (t) => () => {
    Menu.instance?.close();
    Routing.instance.alert.post({
        text: I18n.m.getMessage(t.type === 'TimeTrackingEntity' ? 'timeTrackingDeleteEntryQuestion' : 'timeTrackingDeleteEntryQuestion'),
        buttons: [
            <ContainedButton key="okButton" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
            <ContainedButton key="deleteNow" title={I18n.m.getMessage('delete')} onPress={_a.deleteEntryNow(t)} backgroundColor={ThemeManager.style.brandDanger}/>,
        ],
    });
};
CombinedExtraPayTrackingDialogsControl.deleteEntryNow = (t) => (_e) => {
    Alert.instance?.close(() => {
        LoadingEvents.instance.startLoading();
        const asyncNow = async () => {
            if (t.type === 'TimeTrackingEntity') {
                const create = new DeleteTimeTracking({}, t.id);
                await create.execute(ClientStore.commandStore);
            }
            else {
                const create = new DeleteExtraPayTracking({}, t.id);
                await create.execute(ClientStore.commandStore);
            }
        };
        asyncNow()
            .catch(DefaultErrorHandler.showDefaultErrorAlert)
            .finally(() => {
            LoadingEvents.instance.stopLoading();
        });
    });
};
CombinedExtraPayTrackingDialogsControl.getFromTrackingEntry = async (id, day, type) => {
    const models = await _a.getOnlineOrOfflineModels(day);
    let entry;
    if (type === 'absence') {
        const a = await models.absence.getById(id);
        const d = new Date(a.starts);
        const end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);
        const ends = new Date(a.ends).getTime() > end.getTime() ? end : new Date(a.ends);
        const tt = await TimeTrackingEntity.convertAbsenceToTimeTracking(a, CombinedExtraPayAndTimeTrackingEntity.getWorkingTimeModelForMember, d, ends);
        if (tt.length > 0)
            entry = await CombinedExtraPayAndTimeTrackingEntity.convert(tt[0]);
    }
    else if (type === 'timeTracking') {
        const t = await models.timeTracking.getById(id);
        entry = await CombinedExtraPayAndTimeTrackingEntity.convert(t);
    }
    else if (type === 'extraPay') {
        const e = await models.extraPayTracking.getById(id);
        entry = await CombinedExtraPayAndTimeTrackingEntity.convert(e);
    }
    return entry;
};
CombinedExtraPayTrackingDialogsControl.editTrackingEntry = (id, day, type) => (e) => {
    const asyncNow = async () => {
        const models = await _a.getOnlineOrOfflineModels(day);
        if (type === 'absence') {
            const a = await models.absence.getById(id);
            openAbsenceCreationChangeDialog({ absence: a })(e);
        }
        else if (type === 'timeTracking') {
            const t = await models.timeTracking.getById(id);
            Dialog.instance?.open({
                content: (<ChangeTimeTrackDialog checkAndSaveTimeTracking={_a.checkAndSaveTimeTracking} timeTrackingEntry={t}/>),
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
            });
        }
        else if (type === 'extraPay') {
            const e = await models.extraPayTracking.getById(id);
            Dialog.instance?.open({
                content: <AddOrChangeExtraPayTrackDialog extraPayTracking={e}/>,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
            });
        }
    };
    asyncNow().catch(DefaultErrorHandler.showDefaultErrorAlert);
};
CombinedExtraPayTrackingDialogsControl.editEntry = (t) => (_e) => {
    Menu.instance?.close();
    if (t.type === 'TimeTrackingEntity') {
        if (t.orgEntry.absenceId != null) {
            _a.getOnlineOrOfflineModels(t.starts || t.day)
                .then((m) => {
                return m.absence.getById(t.orgEntry.absenceId);
            })
                .then((a) => {
                openAbsenceCreationChangeDialog({ absence: a })(_e);
            })
                .catch((err) => {
                console.debug(err);
                Routing.instance.goTo('/absences');
            });
        }
        else {
            Dialog.instance?.open({
                content: (<ChangeTimeTrackDialog checkAndSaveTimeTracking={_a.checkAndSaveTimeTracking} timeTrackingEntry={t.orgEntry}/>),
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
            });
        }
    }
    else {
        Dialog.instance?.open({
            content: <AddOrChangeExtraPayTrackDialog extraPayTracking={t.orgEntry}/>,
            fullscreenResponsive: true,
            contentPadding: false,
            scrollable: false,
        });
    }
};
CombinedExtraPayTrackingDialogsControl.checkAndSaveTimeTracking = (entry, save, collidingEntries = null, secondButtonText = I18n.m.getMessage('timeTrackingChangeSaveAnyway')) => {
    const asyncNow = async () => {
        const reallyColliding = collidingEntries == null ? await TimeTrackingEntity.isColliding(entry) : collidingEntries;
        if (reallyColliding.length > 0) {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('timeTrackingChangeFoundCollision', { count: reallyColliding.length }),
                buttons: [
                    <ContainedButton backgroundColor="transparent" key="cancel" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('cancel')} onPress={() => {
                            Alert.instance?.close();
                        }}/>,
                    <ContainedButton backgroundColor="transparent" key="show_collision_tickets" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('timeTrackingChangeShowCollision')} onPress={() => {
                            _a.showColliding(entry, reallyColliding);
                        }}/>,
                    <ContainedButton key="not_show_collision_tickets" title={secondButtonText} onPress={() => {
                            Alert.instance?.close();
                            save();
                        }}/>,
                ],
            });
        }
        else {
            save();
        }
    };
    asyncNow().catch(DefaultErrorHandler.showDefaultErrorAlert);
};
CombinedExtraPayTrackingDialogsControl.showColliding = (tt, collidingEntries) => {
    const timeTracking = new TimeTrackingEntity(tt);
    Alert.instance?.close(() => {
        const async = async () => {
            let start = new Date(timeTracking.starts);
            let end = timeTracking.ends != null ? new Date(timeTracking.ends) : new Date(timeTracking.starts);
            collidingEntries.forEach((entry) => {
                if (new Date(entry.starts).getTime() < start.getTime()) {
                    start = new Date(entry.starts);
                }
                if (entry.ends != null && new Date(entry.ends).getTime() > end.getTime()) {
                    end = new Date(entry.ends);
                }
            });
            const userId = timeTracking.userId ? timeTracking.userId : await timeTracking.getMemberId();
            const timeTrackingFilter = new CombinedExtraPayAndTimeTrackingFilter();
            timeTrackingFilter.date = start.getTime();
            timeTrackingFilter.dateTo = end.getTime();
            timeTrackingFilter.userIds = [userId];
            Routing.instance.changeQueryParameter({ ft: JSON.stringify(timeTrackingFilter) }, false);
            Dialog.instance?.close();
        };
        async().catch(DefaultErrorHandler.showDefaultErrorAlert);
    });
};
