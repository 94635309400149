import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { FlatList, Image, View } from 'react-native';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { I18n } from '../../../i18n/I18n';
import { CombinedExtraPayTrackingDialogsControl } from '../control/CombinedExtraPayTrackingDialogsControl';
import { TimeTrackingStopDialog } from '../dialogs/TimeTrackingStopDialog';
import { TimeTrackingListItemForDay } from '../elementcomponents/TimeTrackingLisItemForDay';
const noTimeTrackings = require('../../../assets/img/noTimeTrackings.png');
export function TimeTrackingListPerDay(props) {
    const { entries, maxHeight } = props;
    const openAddTimeTrackDialog = (memberIds) => (e) => {
        CombinedExtraPayTrackingDialogsControl.openAddTimeTrackingDialog({
            title: I18n.m.getMessage('timeTrackingTaskOrProject'),
            selectedDate: new Date(),
            hideTimes: { start: true, end: true, date: true, pause: true },
            memberIds,
        })(e);
    };
    const renderRow = (data) => {
        const { item } = data;
        if (!item.entries.length)
            return null;
        const timeString = TimeTrackingEntity.msToTime(item.totalTime, false, true);
        const isToday = I18n.m.date().localeDateString(new Date()) === I18n.m.date().localeDateString(item.date);
        const unendenTimeTracks = [];
        if (isToday) {
            item.entries.forEach((r) => {
                r.memberEntries.forEach((m) => {
                    if (m.type === 'TimeTrackingEntity' && m.orgEntry.ends == null) {
                        unendenTimeTracks.push({ id: m.id, memberName: m.memberName, userId: m.userId, memberId: m.memberId });
                    }
                });
            });
        }
        const endedEntries = item.entries.length === 0 ? null : (<View style={{ width: '100%', alignItems: 'center' }}>
          <View style={{ width: '100%', maxWidth: 1200 }}>
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <View style={{ paddingLeft: 16, flexDirection: 'row', alignItems: 'center' }}>
                <View>
                  <MaterialText type={MaterialTextTypes.Body1} color="#FFFFFF">
                    {`${I18n.m.date().localeDateString(item.date, false, true, true)}${item.entries.length > 1
                ? ` (${item.entries.length}${unendenTimeTracks.length > 0 ? `, ${unendenTimeTracks.length}` : ''})`
                : ''}`}
                  </MaterialText>
                </View>
                {unendenTimeTracks.length > 0 ? (<View style={{
                    paddingLeft: 4,
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    gap: 8,
                }}>
                    <Icon toolTip={I18n.m.getMessage('timeTrackingTaskOrProject')} onPress={openAddTimeTrackDialog(unendenTimeTracks.map((a) => (a.memberId ? a.memberId : a.id)))} icon="swap-horizontal" backgroundColor={ThemeManager.style.brandWarning} color="#FFFFFF"/>
                    <Icon toolTip={I18n.m.getMessage('timeTrackingStopTimer')} onPress={TimeTrackingStopDialog.openDialog(unendenTimeTracks)} icon="stop" backgroundColor={ThemeManager.style.brandDanger} color="#FFFFFF"/>
                  </View>) : null}
              </View>
              <View style={{ paddingRight: 16 }}>
                <MaterialText type={MaterialTextTypes.Body1} color="#FFFFFF">
                  {timeString}
                </MaterialText>
              </View>
            </View>
            {item.entries.map((tt) => {
                const key = `${item.date}_${tt.memberId}`;
                return <TimeTrackingListItemForDay currentDate={item.date} key={key} userEntries={tt}/>;
            })}
          </View>
        </View>);
        return <View style={{ width: '100%', paddingBottom: 32 }}>{endedEntries}</View>;
    };
    if (entries.length === 0) {
        return (<View style={{ width: '100%' }}>
        <Image style={{ width: '100%', height: 200, resizeMode: 'contain' }} resizeMode="contain" source={noTimeTrackings}/>
        <View style={{ width: '100%', marginTop: 16, alignItems: 'center' }}>
          <MaterialText centeredBox centeredText color="#FFFFFF" type={MaterialTextTypes.Subtitle2} strong>
            {I18n.m.getMessage('timeTrackingNoDataText')}
          </MaterialText>
          <MaterialText centeredBox centeredText color="#FFFFFF" type={MaterialTextTypes.Subtitle2}>
            {I18n.m.getMessage('timeTrackingNoDataHint')}
          </MaterialText>
        </View>
      </View>);
    }
    return (<FlatList key="timeTrackingEntries" keyExtractor={(item) => `${item.date.getTime()}_${item.totalTime}_${JSON.stringify(item.entries)}`} style={{ width: '100%', height: maxHeight, maxHeight }} data={entries} renderItem={renderRow} contentContainerStyle={{ paddingBottom: 92 }}/>);
}
