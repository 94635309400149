import moment from 'moment';
export class TimeTrackingCalendarFunctions {
    static getKW(date) {
        const doDat = TimeTrackingCalendarFunctions.getNextThursday(date);
        const year = doDat.getFullYear();
        const doKW1 = TimeTrackingCalendarFunctions.getNextThursday(new Date(year, 0, 4));
        return Math.floor(1.5 + (doDat.getTime() - doKW1.getTime()) / 86400000 / 7);
    }
    static getNextThursday(date) {
        const thursday = new Date();
        thursday.setTime(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000);
        return thursday;
    }
    static monthDiff(dateFrom, dateTo) {
        const a = moment(dateTo);
        const b = moment(dateFrom);
        return a.diff(b, 'months');
    }
    static dateDiffInDays(a, b) {
        const MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / MS_PER_DAY);
    }
}
TimeTrackingCalendarFunctions.getSelectedType = (startDate, endDate, startsWithMonday, periods) => {
    if (startDate == null || endDate == null)
        return { type: '?', periodIndex: -1 };
    if (periods != null && periods.length > 0) {
        const periodIndex = periods.findIndex((p) => p.from.getTime() === startDate.getTime() && p.to.getTime() === endDate.getTime());
        if (periodIndex > -1)
            return { type: 'period', periodIndex };
    }
    const startDateWOTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
    const endDateWOTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1, 0, 0, 0, -1);
    const endDateWOTime2 = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0);
    if (startDateWOTime.getTime() === endDateWOTime2.getTime()) {
        return { type: 'date', periodIndex: -1 };
    }
    if (new Date(startDateWOTime.getFullYear(), startDateWOTime.getMonth(), startDate.getDate() + 7, 0, 0, 0, -1).getTime() === endDateWOTime.getTime() &&
        startDateWOTime.getDay() === (startsWithMonday ? 1 : 0)) {
        return { type: 'week', periodIndex: -1 };
    }
    if (startDateWOTime.getDate() === 1 &&
        endDateWOTime.getTime() ===
            new Date(startDateWOTime.getFullYear(), startDateWOTime.getMonth() + 1, 1, 0, 0, 0, -1).getTime()) {
        return { type: 'month', periodIndex: -1 };
    }
    return { type: '?', periodIndex: -1 };
};
