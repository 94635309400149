import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextAsync } from 'materialTheme/src/theme/components/text/MaterialTextAsync';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
import { CombinedExtraPayTrackingDialogsControl } from '../../timetracking/control/CombinedExtraPayTrackingDialogsControl';
export function ApprovalProcessDialog(props) {
    const [approvalProcess, setApprovalProcess] = React.useState();
    const [entity, setEntity] = React.useState();
    const [updateCounter, setUpdateCounter] = useState(0);
    const loadData = async () => {
        if (props.currentStep.approvalProcessId) {
            const p = await UpmeshClient.instance.modals.approvalProcess.getById(props.currentStep.approvalProcessId);
            setApprovalProcess(p);
            const _entity = await UpmeshClient.instance.modals[props.onType === 'extraPay' ? 'extraPayTracking' : props.onType].getById(props.entityId);
            setEntity(_entity);
        }
    };
    useEffect(() => {
        loadData().catch(DefaultErrorHandler.showDefaultErrorAlert);
    }, [updateCounter]);
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'ApprovalProcessDialog',
            readModelName: 'TimeTracking',
            callback: (_e) => {
                if (_e.entities.has(props.entityId))
                    setUpdateCounter(updateCounter + 1);
            },
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'ApprovalProcessDialogExtra',
            readModelName: 'ExtraPayTracking',
            callback: (_e) => {
                if (_e.entities.has(props.entityId))
                    setUpdateCounter(updateCounter + 1);
            },
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'ApprovalProcessDialogAbsence',
            readModelName: 'Absence',
            callback: (_e) => {
                if (_e.entities.has(props.entityId))
                    setUpdateCounter(updateCounter + 1);
            },
        });
        return () => {
            UpmeshClient.eventDispatcher.detach('TimeTracking', 'ApprovalProcessDialog');
            UpmeshClient.eventDispatcher.detach('ExtraPayTracking', 'ApprovalProcessDialogExtra');
            UpmeshClient.eventDispatcher.detach('Absence', 'ApprovalProcessDialogAbsence');
        };
    }, [updateCounter]);
    const renderSteps = () => {
        if (approvalProcess == null || approvalProcess.approvalSteps.length === 0)
            return null;
        const steps = [];
        for (let i = 0; i < approvalProcess.approvalSteps.length; i += 1) {
            const a = approvalProcess.approvalSteps[i];
            const k = `${a.who.charAt(0).toUpperCase()}${a.who.slice(1)}`;
            const text = I18n.m.getMessage(`approvalProcessStepWho${k}`);
            const aStep = entity?.approvals?.find((a) => a.step === i);
            steps.push(<View style={{ width: '100%', paddingBottom: 16, flexDirection: 'row' }}>
          <View style={{ width: 32, justifyContent: 'center' }}>
            <MaterialText type={MaterialTextTypes.H6}>{(i + 1).toString(10)}</MaterialText>
          </View>
          <View style={{ flex: 1 }}>
            {a.who === 'member' ? (<MaterialTextAsync asyncText={async () => {
                        if (a.whoExtra) {
                            const m = await UpmeshClient.instance.modals.companyMember.getById(a.whoExtra);
                            let user = new UserEntity({ firstname: m.firstName, lastname: m.lastName });
                            if (m.userId)
                                user = await AuthClient.instance.modals.user.getById(m.userId);
                            return user.getFullName();
                        }
                        return text;
                    }}/>) : (<MaterialText>{text}</MaterialText>)}
            {aStep ? (<MaterialTextAsync asyncText={async () => {
                        const text = `${I18n.m.getMessage(aStep.approved ? 'absenceStateApproved' : 'absenceStateRejected')} (${I18n.m.dateCurrent.localeDateWithTimeString(new Date(aStep.date))}`;
                        if (aStep) {
                            try {
                                const user = await AuthClient.instance.modals.user.getById(aStep.userId);
                                return `${text}, ${user.getFullName()})`;
                            }
                            catch (e) {
                                console.error(e);
                            }
                        }
                        return `${text})`;
                    }}/>) : entity && entity.currentApprovalProcessStep && entity.currentApprovalProcessStep > i ? (<MaterialText>{I18n.m.getMessage('absenceStateSkipped')}</MaterialText>) : (<MaterialText>{I18n.m.getMessage('absenceStateOpen')}</MaterialText>)}
          </View>
          {aStep == null &&
                    !(entity && entity.currentApprovalProcessStep && entity.currentApprovalProcessStep > i) &&
                    (CompanyUserInfo.me?.payroll ||
                        (entity &&
                            entity.nextApprovalStepFor &&
                            entity.nextApprovalStepFor.findIndex((a) => a.userId === CurrentUser.userId) > -1)) ? (<View style={{ width: 72, flexDirection: 'row' }}>
              <Icon icon="close-circle-outline" toolTip="" color={ThemeManager.style.brandDanger} onPress={() => {
                        CombinedExtraPayTrackingDialogsControl.rejectTracking(props.entityId, { onType: props.onType })
                            .then(() => {
                            setUpdateCounter(updateCounter + 1);
                        })
                            .catch(DefaultErrorHandler.showDefaultErrorAlert);
                    }}/>
              <Icon icon="check-circle-outline" toolTip="" color={ThemeManager.style.brandSuccess} onPress={() => {
                        CombinedExtraPayTrackingDialogsControl.approveTracking(props.entityId, { onType: props.onType })
                            .then(() => {
                            setUpdateCounter(updateCounter + 1);
                        })
                            .catch(DefaultErrorHandler.showDefaultErrorAlert);
                    }}/>
            </View>) : null}
        </View>);
        }
        return <View>{steps}</View>;
    };
    return [
        <DialogTitle iconRight={{ icon: 'close', onPress: () => Dialog.instance?.close(), toolTip: I18n.m.getMessage('close') }}>
      {`${I18n.m.getMessage('approvalProcessStepsDialogTitle')} ${approvalProcess && approvalProcess.title ? approvalProcess.title : ''}`}
    </DialogTitle>,
        <DialogContent>{approvalProcess == null || entity == null ? <Spinner /> : renderSteps()}</DialogContent>,
    ];
}
