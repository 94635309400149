"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExtraPayTrackingEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var ExtraPayTrackingEntity = function (_ReadModelEntity_1$Re) {
  function ExtraPayTrackingEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, ExtraPayTrackingEntity);
    _this = _callSuper(this, ExtraPayTrackingEntity, [obj]);
    _this.entityName = 'ExtraPayTracking';
    _this.deleted = false;
    _this.status = 'Recorded';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(ExtraPayTrackingEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(ExtraPayTrackingEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new ExtraPayTrackingEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('foreignId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectTitle', new OdataEntity_1.OdataIndex());
      baseMap.set('costCenterId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('day', new OdataEntity_1.OdataIndex(true));
      baseMap.set('memberId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('extraPayId', new OdataEntity_1.OdataIndex());
      baseMap.set('extraField', new OdataEntity_1.OdataIndex());
      baseMap.set('notes', new OdataEntity_1.OdataIndex());
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      baseMap.set('status', new OdataEntity_1.OdataIndex(true));
      baseMap.set('nextApprovalStepFor', new OdataEntity_1.OdataIndex(true));
      baseMap.set('approvalProcessId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('currentApprovalProcessStep', new OdataEntity_1.OdataIndex(true));
      baseMap.set('isBlocked', new OdataEntity_1.OdataIndex(true));
      baseMap.set('approvals', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.ExtraPayTrackingEntity = ExtraPayTrackingEntity;