import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../../i18n/I18n';
import { DefaultErrorHandler } from '../../../DefaultErrorHandler';
import { CompanyWorkingTimeModelItem } from '../../CompanyWorkingTimeModelItem';
import { openEditWorkingTimeModelsForMember } from './EditWorkingTimeModelsForMember';
export function CompanyUserWorkingTimeModels(props) {
    const [workingTimeModels, setWorkingTimeModels] = useState();
    const styles = useRef({
        container: {
            padding: 16,
            width: '100%',
        },
    });
    const load = async () => {
        const w = [];
        if (props.user.member.workingTimeModels && props.user.member.workingTimeModels.length > 0) {
            for (const wtm of props.user.member.workingTimeModels) {
                const wt = await UpmeshClient.instance.modals.workingTimeModel.getById(wtm.workingTimeModelId);
                w.push({ validFrom: new Date(wtm.validFromDate), wtm: wt });
            }
        }
        else if (props.user.member.workingTimeModelId != null) {
            const wt = await UpmeshClient.instance.modals.workingTimeModel.getById(props.user.member.workingTimeModelId);
            w.push({ validFrom: new Date(props.user.member.createdAt), wtm: wt });
        }
        w.sort((a, b) => {
            return b.validFrom.getTime() - a.validFrom.getTime();
        });
        return w;
    };
    useEffect(() => {
        let mounted = true;
        load()
            .then((e) => {
            if (mounted)
                setWorkingTimeModels(e);
        })
            .catch(DefaultErrorHandler.showDefaultErrorAlert);
        return () => {
            mounted = false;
        };
    }, [props.user]);
    if (workingTimeModels == null)
        return <Spinner />;
    if (workingTimeModels.length === 0)
        return (<View style={styles.current.container}>
        <MaterialText>---</MaterialText>
      </View>);
    return (<View style={styles.current.container}>
      <View style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <Icon toolTip={I18n.m.getMessage('edit')} icon="pencil-outline" onPress={openEditWorkingTimeModelsForMember({ member: props.user.member, memberName: props.user.getName() })}/>
      </View>
      {workingTimeModels.map((w) => {
            return (<View style={{ width: '100%' }}>
            <View style={{ width: '100%' }}>
              <MaterialText strong type={MaterialTextTypes.H6}>
                {I18n.m.getMessage('validFrom')} {I18n.m.dateCurrent.localeDateString(w.validFrom)}: {w.wtm.mark}
              </MaterialText>
            </View>
            <CompanyWorkingTimeModelItem wtm={w.wtm}/>
          </View>);
        })}
    </View>);
}
