import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import React, { useState } from 'react';
import { View } from 'react-native';
import { TicketEntity } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { I18n } from '../../../../i18n/I18n';
import { TicketDetailsEditable } from '../../../tickets/TicketDetailsEditable';
const getPreviewTicket = (project) => {
    const firstTag = project.tags?.at(0);
    const pT = new TicketEntity();
    pT.type = I18n.m.getMessage('previewTicketType');
    pT.title = I18n.m.getMessage('previewTicketTitle');
    pT.description = I18n.m.getMessage('previewTicketDescription');
    pT.tags = firstTag != null ? [firstTag] : [];
    pT.ticketNumber = 42;
    return pT;
};
function PreviewWrapper(props) {
    const [size, setSize] = useState({
        contentHeight: props.size?.contentHeight ?? 0,
        contentWidth: props.size?.contentWidth ?? 0,
    });
    const onLayout = (e) => {
        setSize({
            contentHeight: props.size?.contentHeight ?? 0,
            contentWidth: e.nativeEvent.layout.width,
        });
    };
    return (<DialogContent scrollDisabled padding={0}>
      <View style={{ width: '100%' }} onLayout={onLayout}>
        <TicketDetailsEditable contentWidth={size.contentWidth} contentHeight={size.contentHeight - 64} project={props.project} errorResult={(_err) => { }} ticket={getPreviewTicket(props.project)} showProject={false} ticketLayout={props.ticketLayout} fullScreen={false} hasSubTickets={false} canEditSubTickets={false} bottomBar={false}/>
      </View>
    </DialogContent>);
}
export const openPreviewDialog = (props) => {
    Dialog.instance?.open({
        content: <PreviewWrapper {...props}/>,
        closeOnTouchOutside: true,
        scrollable: false,
        fullscreenResponsive: true,
        contentPadding: false,
        showCloseIcon: true,
    });
};
