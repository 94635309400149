import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialTextEditableNumber } from 'materialTheme/src/theme/components/text/MaterialTextEditableNumber';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ChangeMembersUnpaidOvertime } from 'upmesh-core/src/client/commands/companies/ChangeMembersUnpaidOvertime';
import { PayrollAccountingPeriodsPerMemberEntity, } from 'upmesh-core/src/client/query/entities/PayrollAccountingPeriodsPerMemberEntity';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../../i18n/I18n';
import { DefaultErrorHandler } from '../../../DefaultErrorHandler';
import { CompanyUserInfo } from '../../../root/CompanyUserInfo';
import { PayrollViewMemberDetailsview } from '../../../timetracking/PayrollViewMemberDetailsview';
export function CompanyUserTimeTrackingOverview(props) {
    const [data, setData] = useState();
    const [updateCounter, setUpdateCounter] = useState(0);
    const [periodId, setPeriodId] = useState();
    const loadData = async () => {
        const d = await UpmeshClient.instance.modals.payrollAccountingPeriodsPerMember.get({
            filter: `memberId eq '${props.user.member.id}'`,
        });
        const d2 = [];
        for (const p of d) {
            const e = await PayrollAccountingPeriodsPerMemberEntity.convertToPayrollAccountingPeriodsPerMemberDetails(p);
            d2.push(e);
        }
        setData(d2);
    };
    useEffect(() => {
        loadData().catch((err) => console.error(err));
    }, [props.user, updateCounter]);
    const onCellPress = (i) => setPeriodId(i.periodId);
    const columns = [
        {
            title: I18n.m.getMessage('absenceStarts'),
            keyInData: 'from',
            onCellPress,
            style: { width: 150 },
            dataType: 'Date',
            sortable: false,
        },
        {
            title: I18n.m.getMessage('absenceEnds'),
            keyInData: 'to',
            onCellPress,
            style: { width: 150 },
            dataType: 'Date',
            sortable: false,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelTargetTime'),
            format: (a) => TimeTrackingEntity.msToTime(a.targetHoursInMs, false, true),
            keyInData: 'targetHoursInMs',
            onCellPress,
            style: { width: 150 },
            dataType: 'number',
            sortable: false,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelActualTime'),
            format: (a) => TimeTrackingEntity.msToTime(a.hoursInMs, false, true),
            keyInData: 'hoursInMs',
            onCellPress,
            style: { width: 150 },
            dataType: 'number',
            sortable: false,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelOverTime'),
            keyInData: 'overtime',
            style: { width: 150 },
            onCellPress,
            format: (a) => TimeTrackingEntity.msToTime(a.overtime, false, true),
            dataType: 'number',
            sortable: false,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelOverTimeMonthBefore'),
            keyInData: 'overtimeRestMonthBefore',
            style: { width: 150 },
            onCellPress,
            format: (a) => TimeTrackingEntity.msToTime(a.overtimeRestMonthBefore, false, true),
            dataType: 'number',
            sortable: false,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelOverTimeTotal'),
            keyInData: 'overtimeTotal',
            style: { width: 150 },
            onCellPress,
            format: (a) => TimeTrackingEntity.msToTime(a.overtimeTotal, false, true),
            dataType: 'number',
            sortable: false,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelOverTimePaid'),
            keyInData: 'paidOverTimeInMs',
            onCellPress,
            style: { width: 150 },
            format: (a) => TimeTrackingEntity.msToTime(a.paidOverTimeInMs, false, true),
            dataType: 'number',
            sortable: false,
        },
        {
            title: I18n.m.getMessage('timeTrackingExportExcelRestTime'),
            keyInData: 'unPaidOverTimeInMs',
            style: { width: 150 },
            format: (a) => TimeTrackingEntity.msToTime(a.unPaidOverTimeInMs, false, true),
            dataType: 'number',
            sortable: false,
            cellRenderer: !CompanyUserInfo.me?.payroll
                ? undefined
                : (item, column, _index) => {
                    const value = Math.round(item.unPaidOverTimeInMs / 1000 / 6 / 6) / 100;
                    return (<View key={`diff_${item.id}`} style={[{ justifyContent: 'center', paddingLeft: 16 }, column.style]}>
                <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                  <MaterialTextEditableNumber style={{ width: '100%' }} materailTextProps={{
                            textAlign: 'right',
                            fixedWidth: '100%',
                            numberOfLines: 1,
                        }} onChanged={(v) => {
                            const value = Math.round(Number.parseFloat(v) * 1000 * 60 * 60);
                            const c = new ChangeMembersUnpaidOvertime({ unPaidOverTimeInMs: value }, item.id);
                            c.execute(AuthClient.instance.commandStore)
                                .then(() => {
                                setUpdateCounter(updateCounter + 1);
                            })
                                .catch(DefaultErrorHandler.showDefaultErrorAlert);
                        }} formatNumber={(v) => TimeTrackingEntity.msToTime(v * 1000 * 60 * 60, false, true)}>
                    {value.toString(10)}
                  </MaterialTextEditableNumber>
                </View>
              </View>);
                },
        },
    ];
    if (periodId) {
        return (<View>
        <Icon icon="arrow-left" onPress={() => setPeriodId(undefined)} toolTip={I18n.m.getMessage('back')}/>
        <PayrollViewMemberDetailsview tableOnly memberId={props.user.member.id} periodId={periodId} maxHeight={props.maxHeight}/>
      </View>);
    }
    return (<View>
      <Table data={data} columns={columns} tableName="payrollAccountingPeriodsPerMember" maxHeight={props.maxHeight} actionItemsLength={0} sortBy="from" sortDirection="desc"/>
    </View>);
}
