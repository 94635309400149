import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { InfoBox } from 'materialTheme/src/theme/components/InfoBox';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useRef, useState } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateHoliday } from 'upmesh-core/src/client/commands/companies/holiday/CreateHoliday';
import { HolidayEntity } from 'upmesh-core/src/client/query/entities/HolidayEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { STATE_CHIPS } from './AllHolidayStates';
export function HolidayImportDialog(props) {
    const [selectedChips, setSelectedChips] = useState([]);
    const [numberOfFound, setNumberOfFound] = useState(null);
    const [wageType, setWageType] = useState('');
    const [loading, setLoading] = useState(true);
    const existingHolidays = useRef(new Map());
    const newHolidays = useRef(new Map());
    const [availableRegions, setAvailableRegions] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const makeQuery = (short, year) => {
        const query = `https://feiertage-api.de/api/?jahr=${year}&nur_land=${short}`;
        return fetch(query);
    };
    const onChangedChips = (currentSelected) => {
        const asyncNow = async () => {
            setSelectedChips([...currentSelected]);
            const queryPromises = [];
            currentSelected.forEach((chip) => {
                const currentYear = new Date().getFullYear();
                queryPromises.push(makeQuery(chip.id ?? '', currentYear));
                queryPromises.push(makeQuery(chip.id ?? '', currentYear + 1));
            });
            const queryResults = await Promise.all(queryPromises);
            const bodyPromises = queryResults.map((elem) => elem.text());
            const stringResults = await Promise.all(bodyPromises);
            const cRemoteHolidays = new Map();
            const nRemoteHolidays = new Map();
            for (const result of stringResults) {
                const currentObj = JSON.parse(result);
                for (const key in currentObj) {
                    const tmpHoliday = {
                        date: HolidayEntity.getNormalizedDate(new Date(currentObj[key].datum)),
                        name: key,
                        yearly: false,
                    };
                    if (tmpHoliday.date.getFullYear() > new Date().getFullYear()) {
                        nRemoteHolidays.set(key, tmpHoliday);
                    }
                    else {
                        cRemoteHolidays.set(key, tmpHoliday);
                    }
                }
            }
            for (const tmpHoliday of cRemoteHolidays.values()) {
                const compareHoliday = nRemoteHolidays.get(tmpHoliday.name);
                if (compareHoliday != null &&
                    compareHoliday.date.getMonth() === tmpHoliday.date.getMonth() &&
                    compareHoliday.date.getDate() === tmpHoliday.date.getDate()) {
                    tmpHoliday.date = HolidayEntity.getNormalizedDate(tmpHoliday.date, true);
                    tmpHoliday.yearly = true;
                }
            }
            const currentMap = existingHolidays.current;
            const addedHolidays = new Map();
            for (const possibleNew of cRemoteHolidays.values()) {
                const key = JSON.stringify(possibleNew, ['name', 'date']);
                if (!currentMap.has(key)) {
                    addedHolidays.set(key, possibleNew);
                }
            }
            newHolidays.current = addedHolidays;
            setNumberOfFound(addedHolidays.size);
        };
        asyncNow().catch((err) => console.error(err));
    };
    useEffect(() => {
        const rawChips = SimpleStorage.get('holiday_state_selected_chips');
        if (rawChips != null) {
            const parsed = JSON.parse(rawChips);
            onChangedChips(parsed);
        }
        const value = SimpleStorage.get('holiday_import_wage_type');
        if (value != null && value.length > 0) {
            setWageType(value);
        }
        setLoading(false);
    }, []);
    useEffect(() => {
        const start = new Date(new Date(new Date().getFullYear(), 0, 0, 0, 0, 0, 0).getTime() - 1);
        const end = new Date(new Date(new Date().getFullYear() + 2, 0, 0, 0, 0, 0, 0).getTime() + 1);
        UpmeshClient.instance.modals.holiday
            .get({
            filter: `deleted ne true and (annual eq true or (date gt ${start.toISOString()} and date lt ${end.toISOString()}))`,
        })
            .then((holidayResult) => {
            const currentMap = existingHolidays.current;
            holidayResult.forEach((holiday) => {
                const tmpSimpleHoliday = {
                    name: holiday.name,
                    date: new Date(holiday.date),
                    yearly: holiday.annual,
                };
                currentMap.set(JSON.stringify(tmpSimpleHoliday, ['name', 'date']), tmpSimpleHoliday);
            });
            existingHolidays.current = currentMap;
        })
            .catch((err) => {
            console.error('could not get holidays from db', err);
        });
    }, []);
    useEffect(() => {
        const timeout = setTimeout(() => {
            SimpleStorage.set('holiday_state_selected_chips', JSON.stringify(selectedChips));
            SimpleStorage.set('holiday_import_wage_type', wageType);
        }, 250);
        return () => {
            clearTimeout(timeout);
        };
    }, [wageType, selectedChips]);
    const createMissingHolidays = () => {
        const asyncNow = async () => {
            const tmp = newHolidays.current;
            const promiseCommands = [];
            for (const tmpHoliday of tmp.values()) {
                const createHolidayData = {
                    name: tmpHoliday.name,
                    date: new Date(tmpHoliday.date),
                    type: 'day',
                    regionIds: selectedRegions.map((region) => region.id),
                    amount: 1,
                    annual: tmpHoliday.yearly,
                    wageType,
                    companyId: props.companyId,
                };
                const singleCommand = new CreateHoliday(createHolidayData);
                promiseCommands.push(singleCommand.execute(ClientStore.commandStore));
            }
            try {
                await Promise.all(promiseCommands);
                Dialog.instance?.close();
            }
            catch (err) {
                console.error('cant create holidays:', err);
                DefaultErrorHandler.showDefaultErrorAlert(err);
            }
        };
        asyncNow().catch((err) => console.error(err));
    };
    useEffect(() => {
        UpmeshClient.instance.modals.region
            .get({
            filter: `deleted ne true`,
        })
            .then((dbRegions) => {
            const regionChips = [];
            dbRegions.forEach((region) => {
                regionChips.push({
                    backgroundColor: ThemeManager.style.brandPrimary,
                    textColor: '#ffffff',
                    title: `${region.region} - ${region.country}`,
                    id: region.id,
                });
            });
            setAvailableRegions(regionChips);
        })
            .catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    }, []);
    return (<>
      <DialogContent>
        <DialogTitle inContent>{I18n.m.getMessage('companyImportHolidayHeadline')}</DialogTitle>
        <MaterialText>
          {I18n.m.getMessage('companyImportHolidayTitle', {
            first: new Date().getFullYear(),
        })}
        </MaterialText>
        <ChipGroup onChanged={onChangedChips} label={I18n.m.getMessage('companyImportHolidayState')} availableChips={STATE_CHIPS} chips={selectedChips}/>
        {loading ? null : (<FormInputFilled initValue={wageType} labelText={I18n.m.getMessage('companyImportHolidayWageType')} onChange={setWageType} helperText={wageType === '' ? I18n.m.getMessage('required') : undefined} error={wageType === '' && numberOfFound != null && numberOfFound > 0}/>)}
        <ChipGroup onChanged={setSelectedRegions} label={I18n.m.getMessage('companyImportHolidayRegions')} availableChips={availableRegions} chips={selectedRegions}/>
        {numberOfFound != null ? (<InfoBox text={I18n.m.getMessage('companyImportHolidayResult', { count: numberOfFound })}/>) : null}
      </DialogContent>
      <DialogActions>
        <ContainedButton textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" title={I18n.m.getMessage('abort')} onPress={Dialog.instance?.close}/>
        <ContainedButton title="importieren" onPress={createMissingHolidays} disabled={wageType === ''}/>
      </DialogActions>
    </>);
}
