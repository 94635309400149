import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { View } from 'react-native';
import { CustomFieldPersonOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import { I18n } from '../../../../i18n/I18n';
import { CurrentProject } from '../../CurrentProject';
import { TicketLayoutDefaultOptions } from './TicketLayoutDefaultOptions';
export const chipList = () => {
    const chips = [];
    const team = CurrentProject.instance.getCurrentProjectTeam();
    for (let i = 0; i < team.length; i += 1) {
        chips.push({
            title: `${team[i].user.firstname} ${team[i].user.lastname}`,
            subtitle: team[i].user.company,
            onPressChipData: team[i].user.id,
            thumbnail: <UserImage size={24} user={team[i].user}/>,
        });
    }
    return chips;
};
export function TicketLayoutPersonFieldOptions(props) {
    const [currentField, setCurrentField] = useState(props.field);
    const [selectedChip, setSelectedChip] = useState(currentField?.customField?.options?.person ? currentField?.customField?.options?.person : '');
    const [formError, setFormError] = useState(new Map());
    const icon = TicketLayoutsEntity.getIconName(props.field);
    const submitForm = (_e) => {
        if (currentField.label == null || currentField.label.length === 0) {
            const s = new Map();
            s.set('label', I18n.m.getMessage('required'));
            setFormError(s);
            return;
        }
        setFormError(new Map());
        props.onSave(currentField);
        Dialog.instance?.close();
    };
    const getChipIndexHandler = (e) => {
        const arr = chipList();
        for (let i = 0; i < arr.length; i += 1) {
            if (arr[i].onPressChipData === e) {
                return i;
            }
        }
        return -1;
    };
    const changeSelectedPersonHandler = (e) => {
        const customField = currentField.customField
            ? currentField.customField
            : { type: 'person', options: new CustomFieldPersonOptions() };
        if (customField.options == null) {
            customField.options = new CustomFieldPersonOptions();
        }
        customField.options.person = e;
        setCurrentField({ ...currentField, customField });
        setSelectedChip(e);
    };
    return (<>
      <DialogTitle iconRight={{ icon, color: ThemeManager.style.brandSuccess, toolTip: '' }}>
        {props.newField
            ? I18n.m.getMessage('ticketLayoutOptionsTileNew')
            : I18n.m.getMessage('ticketLayoutOptionsTileEdit', { title: currentField.label })}
      </DialogTitle>
      <DialogContent>
        <TicketLayoutDefaultOptions approverCanVisible={props.approverCanVisible} assigneeCanEditVisible={props.assigneeCanEditVisible} field={currentField} onChange={(f) => {
            setCurrentField({ ...currentField, ...f });
        }} onError={(err) => {
            setFormError(err);
        }} onSuccess={() => setFormError(new Map())}>
          {chipList().length !== 0 && (<>
              <View style={{ width: '100%', padding: 5 }}>
                <MaterialText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2}>
                  {I18n.m.getMessage('ticketLayoutOptionsDefaultValue')}
                </MaterialText>
              </View>
              <OpenableChip key="PersonenChip" showDeleteIcon emptyStateChip={{
                thumbnail: (<View style={{
                        borderStyle: 'dashed',
                        borderWidth: ThemeManager.style.borderWidth,
                        borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                        width: ThemeManager.style.getScreenRelativePixelSize(24),
                        height: ThemeManager.style.getScreenRelativePixelSize(24),
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}>
                      <Icon toolTip="" icon="help" iconSize={20} outerSize={ThemeManager.style.getScreenRelativePixelSize(24)} radius={0}/>
                    </View>),
                title: I18n.m.getMessage('ticketsDetailsTitleNotAssignedToUser'),
                onPressChipData: '0',
            }} accessibilityLabel="person" elevation={40} selected={getChipIndexHandler(selectedChip)} onPressChip={changeSelectedPersonHandler} widthOpen={256} textColor={ThemeManager.style.black87} chipsList={chipList()}/>
            </>)}
        </TicketLayoutDefaultOptions>
      </DialogContent>
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => Dialog.instance?.close()} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton disabled={formError.size > 0 || currentField.label == null || currentField.label.length === 0} onPress={submitForm} title={props.newField ? I18n.m.getMessage('create') : I18n.m.getMessage('save')}/>
      </DialogActions>
    </>);
}
