import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { AssignTicketTo } from 'upmesh-core/src/client/commands/tickets/AssignTicketTo';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../../project/CurrentProject';
import { ProjectMemberDetailsPU } from '../../team/ProjectMemberDetailsPU';
export const AssigneeChip = React.memo((props) => {
    const [disabledChip, setDisabledChip] = React.useState(true);
    useEffect(() => {
        if (props.ticketId != null) {
            const c = new AssignTicketTo({}, props.ticketId);
            c.canI()
                .then((result) => {
                if (result === true) {
                    setDisabledChip(false);
                }
                else {
                    setDisabledChip(true);
                }
            })
                .catch((err) => {
                console.debug(err);
                setDisabledChip(true);
            });
        }
        else {
            setDisabledChip(true);
        }
    }, [props.ticketId]);
    const assignedTeamMemberList = () => {
        const chips = [];
        const team = CurrentProject.instance.getCurrentProjectTeam();
        for (let i = 0; i < team.length; i += 1) {
            chips.push({
                title: `${team[i].user.firstname} ${team[i].user.lastname}`,
                subtitle: team[i].user.company,
                onPressChipData: team[i].user.id,
                thumbnail: <UserImage size={24} user={team[i].user}/>,
            });
        }
        return chips;
    };
    const chipList = assignedTeamMemberList();
    let chipListSelected = -1;
    if (props.assignedToUserId != null && props.assignedToUserId.length > 0) {
        for (let i = 0; i < chipList.length; i += 1) {
            if (chipList[i].onPressChipData === props.assignedToUserId) {
                chipListSelected = i;
                break;
            }
        }
    }
    const openMemberDetails = (_e) => {
        if (props.assignedToUserId != null && props.projectId != null) {
            UpmeshClient.instance.modals.projectUser
                .get({
                filter: `projectId eq '${props.projectId}' and userId eq '${props.assignedToUserId}' and status eq 'accepted'`,
            })
                .then((projectUser) => {
                const projectUserId = projectUser.length === 0 ? `creator_${props.assignedToUserId}` : projectUser[0].id;
                if (props.assignedToUserId != null && props.projectId != null) {
                    Dialog.instance?.open({
                        content: (<ProjectMemberDetailsPU projectId={props.projectId} data={{ projectUserId, userId: props.assignedToUserId }} closeFunction={Dialog.instance?.close}/>),
                    });
                }
            })
                .catch((err) => {
                console.error(err);
            });
        }
    };
    const onChangeAssignedTeamMember = (index) => {
        if (props.ticketId != null) {
            const c = new AssignTicketTo({ assignedToUserId: index }, props.ticketId);
            c.execute().catch((err) => {
                props.errorResult(err);
            });
        }
    };
    return (<View>
      <View style={props.labelStyle} key="AssigneeLabel">
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('ticketsDetailsTitleAssignedToUser')}
        </MaterialText>
      </View>
      <OpenableChip key="AssigneeChip" showDeleteIcon emptyStateChip={{
            thumbnail: (<View style={{
                    borderStyle: 'dashed',
                    borderWidth: ThemeManager.style.borderWidth,
                    borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                    width: ThemeManager.style.getScreenRelativePixelSize(24),
                    height: ThemeManager.style.getScreenRelativePixelSize(24),
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}>
              <Icon toolTip="" icon="help" iconSize={20} outerSize={ThemeManager.style.getScreenRelativePixelSize(24)} radius={0}/>
            </View>),
            title: I18n.m.getMessage('ticketsDetailsTitleNotAssignedToUser'),
            onPressChipData: '0',
        }} dialogTitle={I18n.m.getMessage('ticketsDetailsTitleAssignedToUser')} accessibilityLabel="assignee" elevation={40} selected={chipListSelected} onPressChip={onChangeAssignedTeamMember} chipsList={chipList} chipDisabled={disabledChip} onPressMainChip={props.assignedToUserId != null ? openMemberDetails : undefined}/>
    </View>);
});
