import ConnectionContext from 'materialTheme/src/connectionContext';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useContext, useEffect, useState } from 'react';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpdatePayrollAccountingPeriod } from 'upmesh-core/src/client/commands/companies/UpdatePayrollAccountingPeriod';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CombinedExtraPayTrackingDialogsControl } from '../control/CombinedExtraPayTrackingDialogsControl';
export function PayrollAccountingPeriodStatusChip(props) {
    const connection = useContext(ConnectionContext);
    const [status, setStatus] = useState();
    const [updateCounter, setUpdateCounter] = useState(0);
    const loadData = async () => {
        const p = props.accounting;
        if (!connection.connectedToServer)
            return setStatus(undefined);
        const models = await CombinedExtraPayTrackingDialogsControl.getOnlineOrOfflineModels(p.starts);
        const { absence, timeTracking, extraPayTracking } = models;
        if (!p.lastUpdate)
            return setStatus('needsUpdate');
        const lastUpdate = p.lastUpdate ? p.lastUpdate.toISOString() : new Date(1).toISOString();
        const newerTT = await timeTracking.get({
            filter: `starts ge ${p.starts.toISOString()} and starts le ${p.ends.toISOString()} and lastModifiedAt gt ${lastUpdate}`,
            top: 1,
        });
        let needsUpdate = newerTT.length > 0;
        if (!needsUpdate) {
            const newerAb = await absence.get({
                filter: `((starts ge ${p.starts.toISOString()} and starts le ${p.ends.toISOString()}) or (ends ge ${p.starts.toISOString()} and ends le ${p.ends.toISOString()}) or (starts le ${p.starts.toISOString()} and ends ge ${p.ends.toISOString()})) and lastModifiedAt gt ${lastUpdate}`,
                top: 1,
            });
            needsUpdate = newerAb.length > 0;
        }
        if (!needsUpdate) {
            const newerAb = await extraPayTracking.get({
                filter: `day ge ${p.starts.toISOString()} and day le ${p.ends.toISOString()} and lastModifiedAt gt ${lastUpdate}`,
                top: 1,
            });
            needsUpdate = newerAb.length > 0;
        }
        const status = needsUpdate ? 'needsUpdate' : 'uptodate';
        return setStatus(status);
    };
    const updateNow = (_e) => {
        Routing.instance.alert.post({
            title: I18n.m.getMessage('payrollStatusUpdateNowTitle'),
            text: I18n.m.getMessage('payrollStatusUpdateNowText'),
            buttons: [
                <ContainedButton key={1} title={I18n.m.getMessage('cancel')} onPress={() => Alert.instance?.close()} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                <ContainedButton key={2} title={I18n.m.getMessage('payrollStatusUpdateNowAction')} onPress={() => {
                        Alert.instance?.close();
                        LoadingEvents.instance.startLoading('', 1200000);
                        const c = new UpdatePayrollAccountingPeriod({}, props.accounting.id, CurrentUser.token);
                        c.execute(AuthClient.instance.commandStore)
                            .catch(DefaultErrorHandler.showDefaultErrorAlert)
                            .finally(() => {
                            LoadingEvents.instance.stopLoading();
                            if (props.onUpdated)
                                props.onUpdated();
                        });
                    }}/>,
            ],
        });
    };
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'PayrollAccountingPeriodStatusChip',
            readModelName: 'TimeTracking',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'PayrollAccountingPeriodStatusChipExtra',
            readModelName: 'ExtraPayTracking',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'PayrollAccountingPeriodStatusChipAbsence',
            readModelName: 'Absence',
            callback: (_e) => setUpdateCounter(updateCounter + 1),
        });
        return () => {
            UpmeshClient.eventDispatcher.detach('TimeTracking', 'PayrollAccountingPeriodStatusChip');
            UpmeshClient.eventDispatcher.detach('ExtraPayTracking', 'PayrollAccountingPeriodStatusChipExtra');
            UpmeshClient.eventDispatcher.detach('Absence', 'PayrollAccountingPeriodStatusChipAbsence');
        };
    }, [updateCounter]);
    useEffect(() => {
        loadData().catch(console.error);
    }, [connection.connectedToServer, updateCounter]);
    if (!connection.connectedToServer) {
        return (<Chip title={I18n.m.getMessage('payrollStatusOffline')} backgroundColor={ThemeManager.style.brandWarning} textColor="#FFFFFF"/>);
    }
    if (!status || status === 'uptodate') {
        return (<Chip title={I18n.m.getMessage('payrollStatusUptodate')} backgroundColor={ThemeManager.style.brandSuccess} textColor="#FFFFFF" onPressChip={updateNow}/>);
    }
    return (<Chip title={I18n.m.getMessage('payrollStatusNeedsUpdate')} backgroundColor={ThemeManager.style.brandDanger} textColor="#FFFFFF" onPressChip={updateNow}/>);
}
