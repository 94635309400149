import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { I18n } from '../../../i18n/I18n';
import { openApprovalProcessDialog } from './openApprovalProcessDialog';
export function ApprovalStatusChip(props) {
    const onPress = props.currentStep.approvalProcessId
        ? openApprovalProcessDialog({ currentStep: props.currentStep, onType: props.onType, entityId: props.entityId })
        : undefined;
    if (props.currentStep.isBlocked === 'open') {
        return (<Chip backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF" thumbnail={<Icon icon="circle-outline" toolTip="" color="#FFFFFF"/>} title={I18n.m.getMessage('absenceStateOpen')} onPressChip={onPress}/>);
    }
    if (props.currentStep.isBlocked === 'rejected') {
        return (<Chip backgroundColor={ThemeManager.style.brandDanger} textColor="#FFFFFF" thumbnail={<Icon icon="close" toolTip="" color="#FFFFFF"/>} title={I18n.m.getMessage('absenceStateRejected')} onPressChip={onPress}/>);
    }
    if (props.currentStep.isBlocked == null) {
        return (<Chip backgroundColor={ThemeManager.style.brandSuccess} textColor="#FFFFFF" thumbnail={<Icon icon="check" toolTip="" color="#FFFFFF"/>} title={I18n.m.getMessage('absenceStateApproved')} onPressChip={onPress}/>);
    }
    return null;
}
