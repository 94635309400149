import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Keyboard, Linking, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../i18n/I18n';
import { CompanyLogo } from '../companies/CompanyLogo';
import { CurrentProject } from '../project/CurrentProject';
import { QRCodeView } from '../QRCodeView';
export class ProjectMemberDetailsPU extends PureComponent {
    constructor(props) {
        super(props);
        this.renderSpace = (<View style={{ height: 8 }}/>);
        this.renderAddress = (company) => {
            return (<View key="phone-address" style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                }}>
        <View style={{
                    flex: 1,
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                }}>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
            {I18n.m.getMessage('address')}
          </MaterialText>
          <MaterialText>{`${company.street} ${company.streetNr}`}</MaterialText>
          <MaterialText>{`${company.zip} ${company.city}`}</MaterialText>
          <MaterialText>{company.country}</MaterialText>
        </View>
        <Icon onPress={this.openUrl(`https://www.google.de/maps/dir//'${company.street} ${company.streetNr} ${company.zip} ${company.city} ${company.country}'`)} icon="directions" color="#FFFFFF" toolTip={I18n.m.getMessage('address')} backgroundColor={ThemeManager.style.brandPrimary}/>
      </View>);
        };
        this.renderPhone = (phone, isCompany = false) => {
            if (phone == null || phone.length === 0) {
                return null;
            }
            return (<View key="phone-company" style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                }}>
        <View style={{
                    flex: 1,
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                }}>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
            {isCompany ? I18n.m.getMessage('addressPhoneCompany') : I18n.m.getMessage('addressPhone')}
          </MaterialText>
          <MaterialText>{phone}</MaterialText>
        </View>
        <Icon onPress={this.openUrl(`tel:${phone}`)} icon="phone" color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('addressCall')} backgroundColor="#FFFFFF" borderStyle="solid"/>
      </View>);
        };
        this.renderMail = (mails) => {
            if (mails == null || mails.length === 0) {
                return null;
            }
            return (<View key="mail" style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                }}>
        <View style={{
                    flex: 1,
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                }}>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
            {I18n.m.getMessage('email')}
          </MaterialText>
          <MaterialText>{mails.join(', ')}</MaterialText>
        </View>
        <Icon onPress={this.openUrl(`mailto:${mails[0]}`)} icon="email-outline" color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('email')} backgroundColor="#FFFFFF" borderStyle="solid"/>
      </View>);
        };
        this.openUrl = (url) => (_e) => {
            Linking.openURL(url).catch((err) => console.debug(err));
        };
        this.state = {
            isLoading: false,
            init: false,
            user: null,
            company: null,
            projectUser: null,
            userCompany: null,
            projectRole: '',
            companyPhone: '',
            qrCodeView: false,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        if (Keyboard != null) {
            Keyboard.dismiss();
        }
    }
    render() {
        const { closeFunction } = this.props;
        const { qrCodeView, init, user, projectUser, company, userCompany, projectRole, companyPhone } = this.state;
        let content = <Spinner />;
        if (init && company != null && projectUser != null) {
            if (qrCodeView) {
                content = (<View style={{
                        flexDirection: 'row',
                        padding: ThemeManager.style.getScreenRelativePixelSize(16),
                        width: '100%',
                        justifyContent: 'center',
                    }} pointerEvents="box-none">
            <QRCodeView user={user} company={company}/>
          </View>);
            }
            else {
                content = (<View style={{
                        flexDirection: 'column',
                        padding: ThemeManager.style.getScreenRelativePixelSize(16),
                        width: '100%',
                    }} pointerEvents="box-none">
            <View style={{
                        width: ThemeManager.style.getScreenRelativePixelSize(128),
                        height: ThemeManager.style.getScreenRelativePixelSize(128),
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}>
              <CompanyLogo company={company} round={false} size={128}/>
            </View>
            <View style={{
                        width: '100%',
                        paddingTop: ThemeManager.style.getScreenRelativePixelSize(16),
                    }}>
              <MaterialText type={MaterialTextTypes.H6}>{`${company.company}`}</MaterialText>
              {this.renderSpace}
              <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('role')}</MaterialText>
              <MaterialText>{projectRole}</MaterialText>
              {this.renderSpace}
              {this.renderAddress(company)}
              {this.renderSpace}
              {company.email ? this.renderMail([company.email]) : undefined}
              {this.renderPhone(company.phone)}
            </View>
          </View>);
            }
        }
        else if (init && user != null && projectUser != null) {
            let userCompanyName = '---';
            if (userCompany != null) {
                userCompanyName = userCompany.company;
            }
            else if (user.company != null) {
                userCompanyName = user.company;
            }
            if (qrCodeView) {
                content = (<View style={{
                        flexDirection: 'row',
                        padding: ThemeManager.style.getScreenRelativePixelSize(16),
                        width: '100%',
                        justifyContent: 'center',
                    }} pointerEvents="box-none">
            <QRCodeView user={user} company={company}/>
          </View>);
            }
            else {
                content = (<View style={{
                        flexDirection: 'column',
                        padding: ThemeManager.style.getScreenRelativePixelSize(16),
                        width: '100%',
                    }} pointerEvents="box-none">
            <View style={{
                        borderRadius: ThemeManager.style.getScreenRelativePixelSize(64),
                        width: ThemeManager.style.getScreenRelativePixelSize(128),
                        alignSelf: 'center',
                    }}>
              <UserImage size={128} user={user}/>
            </View>
            <View style={{
                        width: '100%',
                        paddingTop: ThemeManager.style.getScreenRelativePixelSize(16),
                    }}>
              <MaterialText type={MaterialTextTypes.H6}>{`${user.firstname} ${user.lastname}`}</MaterialText>
              {this.renderSpace}
              <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('role')}</MaterialText>
              <MaterialText>{projectRole}</MaterialText>
              {this.renderSpace}
              <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('accountCompany')}</MaterialText>
              
              <MaterialText>{userCompanyName}</MaterialText>
              {this.renderSpace}
              {this.renderPhone(user.phone)}
              {this.renderSpace}
              {this.renderPhone(companyPhone, true)}
              {companyPhone.length > 0 ? this.renderSpace : null}
              {this.renderMail(user.emails)}
            </View>
          </View>);
            }
        }
        return (<View style={{ width: '100%', position: 'relative' }}>
        <View style={{ position: 'absolute', top: 4, left: 4 }}>
          {qrCodeView ? (<Icon key="backToDetails" icon="account-outline" toolTip={I18n.m.getMessage('userDetailsBackToUser')} onPress={() => {
                    this.setState({ qrCodeView: false });
                }}/>) : (<Icon key="showQrCode" icon="qrcode" toolTip={I18n.m.getMessage('userDetailsQrCodeHelp')} onPress={() => {
                    this.setState({ qrCodeView: true });
                }}/>)}
        </View>
        <View style={{ position: 'absolute', top: 4, right: 4 }}>
          <Icon icon="close" toolTip={I18n.m.getMessage('close')} onPress={closeFunction != null ? closeFunction : Routing.instance.closeDialog(true)}/>
        </View>
        {content}
      </View>);
    }
    async init() {
        await DateLocale.waitFor(() => UpmeshClient.instance.ready);
        let projectUser;
        let user = null;
        let company = null;
        let userCompany = null;
        let projectRole = '';
        if (this.props.data.projectUserId.startsWith('creator_company')) {
            company = await UpmeshClient.instance.modals.company.getById(this.props.data.projectUserId.slice(15));
            projectUser = {
                id: 'creator',
                userId: this.props.data.userId,
                userGroup: '',
                projectId: '',
                status: 'accepted',
            };
            projectRole = I18n.m.getMessage('projectCreator');
        }
        else if (this.props.data.projectUserId.startsWith('creator')) {
            user = await AuthClient.instance.modals.user.getById(this.props.data.userId);
            const companies = await UpmeshClient.instance.modals.company.get({
                filter: `users/userId eq '${this.props.data.userId}'`,
            });
            userCompany = companies[0];
            projectUser = {
                id: 'creator',
                userId: this.props.data.userId,
                userGroup: user.company,
                projectId: '',
                status: 'accepted',
            };
            projectRole = I18n.m.getMessage('projectCreator');
        }
        else {
            user = await AuthClient.instance.modals.user.getById(this.props.data.userId);
            const companies = await UpmeshClient.instance.modals.company.get({
                filter: `users/userId eq '${this.props.data.userId}'`,
            });
            userCompany = companies && companies.length > 0 ? companies[0] : null;
            projectUser = await UpmeshClient.instance.modals.projectUser.getById(this.props.data.projectUserId);
            if (projectUser.projectId != null) {
                const role = await RightsManager.getRoleForUserInProject(user.userId, projectUser.projectId);
                if (role != null) {
                    projectRole = I18n.m.getMessage(role.roleName);
                    const project = CurrentProject.instance.getCurrentProject();
                    if (project != null &&
                        companies.length > 0 &&
                        project.creator.startsWith('company') &&
                        role.roleName === 'subcontractor' &&
                        `company${companies[0].id}` === project.creator) {
                        projectRole = I18n.m.getMessage('subcontractorInOwnCompany');
                    }
                }
            }
        }
        if (user != null && projectUser.phone != null && projectUser.phone.length > 0) {
            user.phone = projectUser.phone;
        }
        let companyPhoneNumber = '';
        const companyUsers = await UpmeshClient.instance.modals.companyMember.get({
            filter: `userId eq '${this.props.data.userId}' and deleted ne true`,
        });
        if (companyUsers.length > 0 && companyUsers[0].phone != null) {
            companyPhoneNumber = companyUsers[0].phone;
        }
        this.setState({
            user,
            projectUser,
            company,
            userCompany,
            projectRole,
            init: true,
            companyPhone: companyPhoneNumber,
        });
    }
}
ProjectMemberDetailsPU.defaultProps = {
    data: null,
};
ProjectMemberDetailsPU.openMemberDetails = (projectUserId, userID, projectId) => (_e) => {
    Dialog.instance?.open({
        content: (<ProjectMemberDetailsPU projectId={projectId} data={{ projectUserId, userId: userID }} closeFunction={Dialog.instance?.close}/>),
    });
};
