import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { MaterialTextEditableV2 } from 'materialTheme/src/theme/components/text/MaterialTextEditableV2';
import { ChangeTicketTitle } from 'upmesh-core/src/client/commands/tickets/ChangeTicketTitle';
import { MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../../project/CurrentProject';
export const TicketTextInputs = React.memo((props) => {
    const [canChangeTitle, setCanChangeTitle] = useState(false);
    const [titleAutoComplete, setTitleAutoComplete] = useState([]);
    useEffect(() => {
        UpmeshClient.instance.modals.ticket
            .get({
            filter: `projectId eq '${CurrentProject.instance.getCurrentProjectId()}' and deleted ne true`,
        })
            .then((result) => {
            const resultSet = new Set();
            result.forEach((ticket) => {
                if (ticket.title.length > 0 && ticket.title !== I18n.m.getMessage('ticketsDetailsTitlePlaceholder')) {
                    resultSet.add(ticket.title);
                }
            });
            setTitleAutoComplete([...resultSet]);
        })
            .catch((err) => {
            console.error('cant get title auto complete list', err);
        });
    }, []);
    useEffect(() => {
        const checkCanChangeTitle = async () => {
            if (props.ticketId == null) {
                return false;
            }
            const cTitle = new ChangeTicketTitle({}, props.ticketId);
            try {
                const canITitle = await cTitle.canI();
                return typeof canITitle === 'boolean' && canITitle;
            }
            catch (e) {
                return false;
            }
        };
        checkCanChangeTitle()
            .then((result) => {
            setCanChangeTitle(result);
        })
            .catch((err) => {
            console.error('could not detect canChangeTitle', err);
        });
    }, [props.ticketId]);
    const changedTitle = (title) => {
        if (props.ticketId == null)
            return;
        const nextTitle = title == null || title.length === 0 ? I18n.m.getMessage('ticketsDetailsTitlePlaceholder') : title;
        const c = new ChangeTicketTitle({ title: nextTitle }, props.ticketId);
        c.execute().catch((err) => {
            props.errorResult(err);
        });
    };
    return (<View style={{ overflow: 'visible' }}>
        <MaterialTextEditableV2 toolTip={I18n.m.getMessage('tooltipEditTicketTitle')} key={`title_${canChangeTitle}`} materailTextProps={{ type: MaterialTextTypes.H6, recognizeUrl: true }} onChanged={changedTitle} placeholder={I18n.m.getMessage('ticketsDetailsEditableAddTitle')} editable={canChangeTitle} autoCompleteList={titleAutoComplete} scrollContainerId="ticketDetails">
          {props.ticketTitle === I18n.m.getMessage('ticketsDetailsTitlePlaceholder') && canChangeTitle
            ? ''
            : props.ticketTitle}
        </MaterialTextEditableV2>
        <View style={{ height: ThemeManager.style.gap }}/>
      </View>);
});
