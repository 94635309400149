import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { CombinedExtraPayAndTimeTrackingFilter, } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CombinedExtraPayAndTimeTrackingDialogFilter } from './CombinedExtraPayAndTimeTrackingDialogFilter';
export function CombinedExtraPayAndTimeTrackingFilterIcon(props) {
    const isFilterActive = CombinedExtraPayAndTimeTrackingFilter.isSet(props.currentFilter);
    const pressFilter = async () => {
        if (props.allEntries != null) {
            CombinedExtraPayAndTimeTrackingDialogFilter.open(props.allEntries, props.currentFilter, false);
        }
    };
    let filterIcon = (<Icon icon={isFilterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={() => {
            pressFilter().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        }} color={isFilterActive ? ThemeManager.style.brandWarning : 'white'} key="filter-outline"/>);
    if (isFilterActive && !props.sDisplay) {
        filterIcon = (<View key={`filterButtonBigOuter${isFilterActive}`}>
        <ContainedButton title={I18n.m.getMessage('filterRemove')} icon={{ icon: 'filter-remove', color: '#ffffff' }} onPress={() => {
                pressFilter().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }} backgroundColor={ThemeManager.style.brandWarning}/>
      </View>);
    }
    return filterIcon;
}
