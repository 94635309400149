import { CameraMemento, math } from '@xeokit/xeokit-sdk/src';
import { NavCubePlugin } from '@xeokit/xeokit-sdk/src/plugins/NavCubePlugin/NavCubePlugin';
import { StoreyViewsPlugin } from '@xeokit/xeokit-sdk/src/plugins/StoreyViewsPlugin/StoreyViewsPlugin';
import { XKTLoaderPlugin } from '@xeokit/xeokit-sdk/src/plugins/XKTLoaderPlugin/XKTLoaderPlugin';
import { Viewer } from '@xeokit/xeokit-sdk/src/viewer/Viewer';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import * as uuid from 'uuid';
export function BimViewer(props) {
    const [viewId, setViewId] = React.useState();
    useEffect(() => {
        if (viewId == null)
            return;
        const viewer = new Viewer({
            canvasId: `xeokit_canvas${viewId}`,
            transparent: true,
            dtxEnabled: true,
        });
        viewer.camera.eye = [-3.933, 2.855, 27.018];
        viewer.camera.look = [4.4, 3.724, 8.899];
        viewer.camera.up = [-0.018, 0.999, 0.039];
        viewer.cameraControl.navMode = 'firstPerson';
        viewer.cameraControl.followPointer = true;
        console.log('FILE', props.file);
        const xktLoader = new XKTLoaderPlugin(viewer);
        const sceneModel = xktLoader.load({
            id: 'myModel',
            src: props.file,
            metaModelSrc: props.json,
            saoEnabled: true,
            edges: true,
            dtxEnabled: true,
        });
        const storeyViewsPlugin = new StoreyViewsPlugin(viewer);
        const allStoreyMaps = [];
        let selectedStoreyId = '';
        const marker = document.getElementById('marker');
        console.log(marker);
        new NavCubePlugin(viewer, {
            canvasElement: document.getElementById(`myNavCubeCanvas${viewId}`),
            visible: true,
            cameraFly: true,
            cameraFitFOV: 45,
            cameraFlyDuration: 0.5,
            fitVisible: false,
            synchProjection: false,
            color: '#99FF99',
            frontColor: '#a8d4f8',
            backColor: '#55FF55',
            leftColor: '#FF5555',
            rightColor: '#FF0055',
            topColor: '#5555FF',
            bottomColor: '#fff155',
            hoverColor: 'rgba(0,0.5,0,0.4)',
            textColor: 'black',
        });
        const unSelectAllOptions = () => {
            const optionElements = document.querySelectorAll('.option');
            optionElements.forEach((el) => {
                el.classList.remove('selected');
            });
        };
        const getFloorText = (number) => {
            return `Ebene ${number.toString()}`;
        };
        const selectOption = (floor) => {
            unSelectAllOptions();
            const selectedFloor = document.getElementById('selected-floor');
            if (selectedFloor)
                selectedFloor.innerHTML = `${getFloorText(floor)}`;
            const floorElement = document.getElementById(`option${floor}`);
            if (floorElement)
                floorElement.classList.add('selected');
        };
        const setFloorDropdown = (storeyId) => {
            if (storeyId === selectedStoreyId)
                return;
            selectedStoreyId = storeyId;
            let floor = 0;
            for (let i = 0; i < allStoreyMaps.length; i += 1) {
                if (storeyId === allStoreyMaps[i].storeyId) {
                    floor = i + 1;
                    break;
                }
            }
            selectOption(floor);
        };
        const toggleDropdown = (visible) => {
            const dropdown = document.getElementById('dropdown');
            const dropdownOptions = document.getElementById('dropdown-options');
            if (dropdown && dropdownOptions) {
                if (visible) {
                    dropdown.classList.remove('opened');
                    dropdown.classList.add('closed');
                    dropdownOptions.style.display = 'none';
                }
                else {
                    dropdown.classList.remove('closed');
                    dropdown.classList.add('opened');
                    dropdownOptions.style.display = 'flex';
                }
            }
        };
        const onDropdownClicked = (_e) => {
            const dropdown = document.getElementById('dropdown');
            if (dropdown != null)
                toggleDropdown(dropdown.classList.contains('opened'));
        };
        const hideStoreyMaps = () => {
            const elements = document.querySelectorAll('.storeyMap');
            if (elements && elements.length)
                elements.forEach((element) => {
                    if (element && element['style'])
                        element['style'].display = 'none';
                });
        };
        const calculateAngleFromDirection = (direction) => {
            const angleInRad = Math.atan2(direction[0], direction[1]);
            const angleInDeg = angleInRad * (180 / Math.PI);
            return angleInDeg;
        };
        const rotatePoint = (x, y, angle) => {
            const radians = (angle * Math.PI) / 180;
            const cos = Math.cos(radians);
            const sin = Math.sin(radians);
            return {
                x: cos * x - sin * y,
                y: sin * x + cos * y,
            };
        };
        const transformCoordinates = (x, y, angle, centerX, centerY) => {
            const translatedX = x - centerX;
            const translatedY = y - centerY;
            const rotatedPoint = rotatePoint(translatedX, translatedY, -angle);
            const finalX = rotatedPoint.x + centerX;
            const finalY = rotatedPoint.y + centerY;
            return { x: finalX, y: finalY };
        };
        const getStoreyId = (cameraPos) => {
            let storey = null;
            storey = storeyViewsPlugin.getStoreyContainingWorldPos(cameraPos);
            if (storey === null) {
                storey = storeyViewsPlugin.getStoreyInVerticalRange(cameraPos);
                if (storey === null) {
                    storey = storeyViewsPlugin.isPositionAboveOrBelowBuilding(cameraPos);
                }
            }
            return storey;
        };
        const getStorey = () => {
            const cameraPos = viewer.camera.eye;
            const storeyId = getStoreyId(cameraPos);
            setFloorDropdown(storeyId);
            hideStoreyMaps();
            const el = document.getElementById(`${storeyId}`);
            if (el != null)
                el.style.display = 'block';
            const cameraDir = viewer.camera.worldForward;
            const storeyMap = allStoreyMaps.filter((storey) => storey.storeyId === storeyId)[0];
            const imageDir = math.vec2();
            const imagePos = math.vec2();
            storeyViewsPlugin.worldDirToStoreyMap(storeyMap, cameraDir, imageDir);
            storeyViewsPlugin.worldPosToStoreyMap(storeyMap, cameraPos, imagePos);
            const centerX = 400 / 2;
            const centerY = 400 / 2;
            const angle = calculateAngleFromDirection(imageDir);
            const transformedPosition = transformCoordinates(imagePos[0], imagePos[1], -angle, centerX, centerY);
            const translateX = centerX - transformedPosition.x;
            const translateY = centerY - transformedPosition.y;
            if (el)
                el.style.transform = `translate(${translateX}px, ${translateY}px) rotate(${angle}deg)`;
        };
        const onOptionSelected = (_e, floor) => {
            selectOption(floor);
            toggleDropdown(true);
            const { storeyId } = allStoreyMaps[floor - 1];
            const storey = storeyViewsPlugin.storeys[storeyId];
            const worldPos = [
                (storey.aabb[0] + storey.aabb[3]) / 2,
                (storey.aabb[1] + storey.aabb[4]) / 2,
                (storey.aabb[2] + storey.aabb[5]) / 2,
            ];
            viewer.cameraFlight.flyTo({
                eye: worldPos,
                up: viewer.camera.worldUp,
                look: math.addVec3(worldPos, viewer.camera.worldForward, []),
                projection: 'perspective',
                duration: 1.5,
            }, () => {
                getStorey();
                viewer.cameraControl.navMode = 'firstPerson';
            });
        };
        const buildStoreyMapsMenu = () => {
            const cameraMemento = new CameraMemento();
            cameraMemento.saveCamera(viewer.scene);
            const storeyDiv = document.getElementById('storeys');
            const optionsDiv = document.getElementById('dropdown-options');
            const storeys = storeyViewsPlugin.storeysList;
            for (let i = 0, len = storeys.length; i < len; i += 1) {
                const storey = storeys[i];
                const { storeyId } = storey;
                const storeyMap = storeyViewsPlugin.createStoreyMap(storeyId, {
                    format: 'png',
                    width: 400,
                });
                allStoreyMaps.push(storeyMap);
                const img = document.createElement('img');
                img.className = 'storeyMap';
                img.src = storeyMap.imageData;
                img.id = `${storeyId}`;
                img.style.borderRadius = '15px';
                img.style.background = 'lightblue';
                img.style.width = '100%';
                img.style.height = '100%';
                img.style.opacity = '0.8';
                img.style.display = 'none';
                if (storeyDiv)
                    storeyDiv.appendChild(img);
                const option = document.createElement('div');
                option.className = 'option';
                option.innerHTML = `
                <label for="check" class="check"><i class="fas fa-check"></i></label>
                <span class="floor-number">${getFloorText(i + 1)}</span>
            `;
                option.id = `option${i + 1}`;
                if (optionsDiv)
                    optionsDiv.appendChild(option);
                img.onmouseenter = () => {
                    img.style.cursor = 'default';
                };
                img.onmousemove = (e) => {
                    img.style.cursor = 'default';
                    const imagePos = [e.offsetX, e.offsetY];
                    const pickResult = storeyViewsPlugin.pickStoreyMap(storeyMap, imagePos, {});
                    if (pickResult) {
                        const { entity } = pickResult;
                        const metaObject = viewer.metaScene.metaObjects[entity.id];
                        if (metaObject) {
                            img.style.cursor = 'pointer';
                        }
                    }
                };
                img.onmouseleave = (_e) => {
                    img.style.cursor = 'default';
                };
                const worldPos = math.vec3();
                img.onclick = (e) => {
                    toggleDropdown(true);
                    const imagePos = [e.offsetX, e.offsetY];
                    const pickResult = storeyViewsPlugin.pickStoreyMap(storeyMap, imagePos, {
                        pickSurface: true,
                    });
                    if (pickResult) {
                        worldPos.set(pickResult.worldPos);
                        const { camera } = viewer.scene;
                        const idx = camera.xUp ? 0 : camera.yUp ? 1 : 2;
                        const storey = storeyViewsPlugin.storeys[storeyMap.storeyId];
                        worldPos[idx] = (storey.aabb[idx] + storey.aabb[3 + idx]) / 2;
                        viewer.cameraFlight.flyTo({
                            eye: worldPos,
                            up: viewer.camera.worldUp,
                            look: math.addVec3(worldPos, viewer.camera.worldForward, []),
                            projection: 'perspective',
                            duration: 1.5,
                        }, () => {
                            getStorey();
                            viewer.cameraControl.navMode = 'firstPerson';
                        });
                    }
                };
            }
            viewer.camera.on('viewMatrix', () => {
                getStorey();
            });
            const st = document.getElementById('storeys');
            if (st != null)
                st.style.display = 'block';
            const dropdown = document.getElementById('dropdown');
            if (dropdown)
                dropdown.addEventListener('click', onDropdownClicked);
            document.querySelectorAll('.option').forEach((el, index) => {
                el.addEventListener('click', (e) => {
                    onOptionSelected(e, index + 1);
                });
            });
        };
        sceneModel.on('loaded', (_e) => {
            console.debug('sceneModel loaded');
            viewer.cameraFlight.jumpTo(sceneModel);
            try {
                if (storeyViewsPlugin.storeysList && storeyViewsPlugin.storeysList.length > 0) {
                    buildStoreyMapsMenu();
                }
                else {
                    const st = document.getElementById('storeys');
                    if (st != null)
                        st.style.display = 'none';
                }
            }
            catch (e) {
                console.error(e);
            }
        });
        window['viewer'] = viewer;
    }, [viewId]);
    const onLayout = (_e) => {
        setViewId(uuid.v4());
    };
    return (<View key="bimViewer" id="123" testID="1234" style={{
            width: '100%',
            position: 'relative',
            height: props.size ? props.size.contentHeight - 64 : '100%',
            backgroundColor: 'white',
        }} onLayout={onLayout}>
      <canvas id={`xeokit_canvas${viewId}`} style={{ width: '100%', height: '100%', position: 'absolute' }}/>
      <canvas id={`myNavCubeCanvas${viewId}`} style={{ width: '100', height: '150', position: 'absolute', zIndex: 20000, bottom: 10, right: 10 }}/>

      <div id="storeys">
        <div className="dropdown closed" id="dropdown">
          <span className="floor" id="selected-floor">
            3rd Floor
          </span>
        </div>
        <div className="dropdown-options" id="dropdown-options"/>
        <div className="bimMarker" id="marker"/>
      </div>
    </View>);
}
