import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { GlobalBar } from '../root/GlobalBar';
import { Redirect } from '../root/Redirect';
import { ApprovalsView } from './ApprovalsView';
import { CollaboratorsView } from './CollaboratorsView';
import { PayrollView } from './PayrollView';
import { TimeTrackingsView } from './TimeTrackingsView';
export function TimeTrackingMainView(props) {
    const { size } = props;
    const bigScreen = ThemeManager.style.breakpointM >= size.windowWidth;
    const globalBar = bigScreen ? null : (<View style={{
            position: 'relative',
            left: 0,
            right: 0,
            height: ThemeManager.style.headerHeight,
            backgroundColor: 'transparent',
        }}>
      <GlobalBar size={size} user={CurrentUser.entity} site="timeTracking"/>
    </View>);
    const renderContent = () => {
        if (props.pathvars && props.pathvars.page) {
            if (props.pathvars.page === 'timeTracking')
                return <TimeTrackingsView size={props.size} height={props.height} ft={props.ft}/>;
            if (props.pathvars.page === 'payroll')
                return <PayrollView size={props.size} periodId={props.pathvars.subpage} memberId={props.pathvars.subpage2}/>;
            if (props.pathvars.page === 'user')
                return <CollaboratorsView height={props.height}/>;
            if (props.pathvars.page === 'approvals')
                return <ApprovalsView size={props.size} height={props.height}/>;
        }
        else {
            return <Redirect to="/timeTracking/timeTracking"/>;
        }
        return <Spinner />;
    };
    const renderMenuItem = (settings) => {
        const cq = '';
        const active = props.pathvars.page === settings.target;
        return (<Ripple style={{ height: 48, width: 48, justifyContent: 'center', alignItems: 'center' }} onPress={Routing.instance.goToButton(`/timeTracking/${settings.target}${cq}`)} toolTip={I18n.m.getMessage(settings.messageCode)}>
        <Icon color={active ? ThemeManager.style.brandPrimary : ThemeManager.style.white87} icon={settings.icon} toolTip={I18n.m.getMessage(settings.messageCode)}/>
      </Ripple>);
    };
    const sView = size.windowWidth <= ThemeManager.style.breakpointM;
    if (sView) {
        return (<View collapsable={false} style={{
                ...ThemeManager.style.absoluteStyle,
                left: size.contentLeft,
                right: size.contentRight,
                top: size.contentTop,
                bottom: size.contentBottom,
                position: 'absolute',
                flexDirection: 'row',
            }}>
        {renderContent()}
      </View>);
    }
    return (<View collapsable={false} style={{
            ...ThemeManager.style.absoluteStyle,
            left: size.contentLeft,
            right: size.contentRight,
            top: size.contentTop,
            bottom: size.contentBottom,
            position: 'absolute',
            flexDirection: 'row',
        }}>
      <View style={{
            left: 0,
            height: size.windowHeight,
            width: size.contentWidth,
            position: 'relative',
            flexDirection: 'column',
        }}>
        {globalBar}
        <View style={{
            width: '100%',
            height: globalBar != null ? size.contentHeight - ThemeManager.style.headerHeight : size.contentHeight,
            position: 'relative',
            backgroundColor: 'transparent',
            flexDirection: 'row',
        }}>
          <View style={{
            width: 48,
            height: '100%',
            backgroundColor: 'transparent',
        }}>
            {renderMenuItem({ icon: 'timer-outline', messageCode: 'timeTracking', target: 'timeTracking' })}
            {renderMenuItem({ icon: 'account-multiple-outline', messageCode: 'collaborator', target: 'user' })}
            {renderMenuItem({ icon: 'check-decagram-outline', messageCode: 'approvalProcess', target: 'approvals' })}
            {CompanyUserInfo.me?.payroll &&
            renderMenuItem({ icon: 'database-arrow-down-outline', messageCode: 'billing', target: 'payroll' })}
          </View>
          <View style={{ width: size.windowWidth - 48 }}>{renderContent()}</View>
        </View>
      </View>
    </View>);
}
