import color from 'color';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Draggable } from 'materialTheme/src/theme/components/dragndrop/Dragable';
import { DragHandler } from 'materialTheme/src/theme/components/dragndrop/DragHandler';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useMemo, useState } from 'react';
import { Text, View } from 'react-native';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { SetTicketShouldCompleteDate } from 'upmesh-core/src/client/commands/tickets/SetTicketShouldCompleteDate';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import { TicketStatus, TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { CommandReadModels } from 'upmesh-core/src/server/webserver/commands/CommandReadModels';
import * as uuid from 'uuid';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { TicketCard } from '../TicketCard';
import { CalendarHeader } from './CalendarHeader';
import { compareDates, dateIsInRange, isS } from './calendarUtil';
import DayCalendar from './DayCalendar';
import MonthCalendar from './MonthCalendar';
import NoDateTickets from './NoDateTickets';
import WeekCalendar from './WeekCalendar';
export function TicketCalender(props) {
    const [range, setRange] = useState('month');
    const [rangeIndex, setRangeIndex] = useState(2);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [noDateWidth, setNoDateWidth] = useState(40);
    const useMonth = useMemo(() => props.height - 160 > 400, [props.height]);
    const getRangeIndex = (pRange) => {
        switch (pRange ?? range) {
            case 'day':
                return 0;
            case 'week':
                return 1;
            case 'month':
                return 2;
            default:
                return 0;
        }
    };
    const changeView = (diff) => {
        const newDate = new Date(currentDate);
        switch (range) {
            case 'month':
                newDate.setMonth(newDate.getMonth() + diff);
                break;
            case 'week':
                newDate.setDate(newDate.getDate() + diff * 7);
                break;
            case 'day':
                newDate.setDate(newDate.getDate() + diff);
                break;
            default:
                newDate.setMonth(newDate.getMonth() + diff);
                break;
        }
        setCurrentDate(newDate);
    };
    const changeRange = (rangeIndex) => {
        let range = 'day';
        if (!useMonth && rangeIndex === 2)
            rangeIndex = 1;
        if (rangeIndex === 1)
            range = 'week';
        else if (rangeIndex === 2)
            range = 'month';
        setRange(range);
        setRangeIndex(rangeIndex);
    };
    const setNewDate = (date, range) => {
        if (!useMonth && range === 'month')
            range = 'week';
        setCurrentDate(date);
        if (range) {
            setRange(range);
            setRangeIndex(getRangeIndex(range));
        }
    };
    const changeDate = async (ticketId, field, newDate) => {
        Dialog.instance?.close();
        if (field.systemField === 'completionOn') {
            const completionOn = newDate;
            try {
                const c = new SetTicketShouldCompleteDate({ completionOn }, ticketId);
                await c.execute();
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            }
        }
        else if (field.customField != null && field.customField.type === 'Date') {
            try {
                const c = new ChangeTicketCustomField({ fieldId: field.id, value: { date: newDate } }, ticketId);
                await c.execute();
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            }
        }
        else if (field.customField != null && field.customField.type === 'DateRange') {
            const c = new ChangeTicketCustomField({ fieldId: field.id, value: { from: newDate, to: newDate } }, ticketId);
            c.execute().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        }
    };
    const openDropDateSelection = (ticketId, fields, date) => {
        const listItems = [];
        for (const f of fields) {
            listItems.push(<ListItem key={f.id} title={f.label} thumbnail={{
                    width: 24,
                    thumbnail: (<Icon icon={TicketLayoutsEntity.getIconName(f)} color={f.customField != null ? ThemeManager.style.brandSecondary : ThemeManager.style.brandPrimary} toolTip=""/>),
                }} onPress={() => {
                    changeDate(ticketId, f, date).catch((err) => console.error(err));
                }}/>);
        }
        Dialog.instance?.open({
            content: <View style={{ height: listItems.length * 56 }}>{listItems}</View>,
            showCloseIcon: true,
            title: I18n.m.getMessage('ticketsCalendarMoveWhichDate'),
            fixedTitle: true,
            scrollable: true,
            fullscreenResponsive: true,
            closeFunction: () => Dialog.instance?.close(),
            fullscreenButtonsBottom: true,
            buttons: [
                <ContainedButton key="closeButton" title={I18n.m.getMessage('cancel')} onPress={() => Dialog.instance?.close()} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ],
        });
    };
    const onDrop = (date) => async (ticket) => {
        const project = await CommandReadModels.instance.project.getById(ticket.projectId);
        if (project != null) {
            const ticketLayout = ChangeTicketCustomField.getTicketLayout(ticket, project);
            if (ticketLayout && ticketLayout.fields.length > 0) {
                const fields = [];
                for (const f of ticketLayout.fields) {
                    if (f.customField != null && (f.customField.type === 'Date' || f.customField.type === 'DateRange'))
                        fields.push(f);
                    else if (f.systemField === 'completionOn')
                        fields.push(f);
                }
                if (fields.length >= 2) {
                    return openDropDateSelection(ticket.id, fields, date);
                }
                if (fields.length === 1)
                    return changeDate(ticket.id, fields[0], date);
            }
        }
        return Alert.instance?.open({
            content: I18n.m.getMessage('ticketsCalendarMoveNoDateError'),
            closeOnTouchOutside: true,
            buttons: [
                <ContainedButton title={I18n.m.getMessage('close')} onPress={() => Alert.instance?.close()} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="AlertClose"/>,
            ],
        });
    };
    const setMultiSelect = (ticket) => {
        const { onMultiSelect } = props;
        onMultiSelect?.(ticket);
    };
    const getTicketColor = (ticket) => {
        if (ticket.ticketStatus === TicketStatus.open)
            return TicketStatusColor.open;
        if (ticket.ticketStatus === TicketStatus.processing)
            return TicketStatusColor.processing;
        if (ticket.ticketStatus === TicketStatus.checked)
            return TicketStatusColor.checked;
        return TicketStatusColor.closed;
    };
    const getTicketsForDate = (forDate) => {
        const { tickets } = props;
        const filtered = tickets.filter((t) => {
            if (t.completionOn != null && compareDates(t.completionOn || undefined, forDate))
                return true;
            return (t.fields != null &&
                t.fields.length > 0 &&
                t.fields.find((f) => {
                    return ((f.type === 'Date' && compareDates(f.value ? f.value.date : undefined, forDate)) ||
                        (f.type === 'DateRange' && f.value != null && dateIsInRange(forDate, f.value.from, f.value.to, false) === 0));
                }));
        });
        return filtered;
    };
    const renderTicketName = ({ item }) => {
        const { onMultiSelect, activeMultiselect, selectedIDs } = props;
        const dragId = `${item.id}_${uuid.v1()}`;
        const selected = selectedIDs.has(item.id);
        const opened = RouterControl.instance.currentUrl.search.includes(item.id);
        let pressOut = new Date();
        let dragstarted = false;
        const onDropped = () => {
            const d = new Date();
            if (pressOut.getTime() + 300 <= d.getTime() && !dragstarted) {
                if (activeMultiselect && onMultiSelect)
                    setMultiSelect(item);
                else {
                    Routing.instance.openDialog('ticket', { id: item.id })(null);
                }
            }
            dragstarted = false;
        };
        return (<View key={dragId}>
        <Draggable startsOnLongPress={false} dropData={item} dragId={dragId} onDragShouldStart={() => {
                dragstarted = true;
                pressOut = new Date();
            }} onStartDrag={() => {
                dragstarted = true;
            }} onDropNotInside={onDropped} onDropped={onDropped} onDragEnd={() => {
                onDropped();
            }}>
          <Ripple onPressIn={activeMultiselect && onMultiSelect
                ? () => onMultiSelect?.(item)
                : (_e) => {
                    pressOut = new Date();
                    DragHandler.startDragById(dragId)();
                    setTimeout(() => {
                        if (!dragstarted) {
                            onDropped();
                        }
                    }, 300);
                }} style={{
                ...ThemeManager.style.elevation2,
                backgroundColor: selected || opened ? color(ThemeManager.style.brandPrimary).alpha(0.16).toString() : 'white',
                borderColor: selected ? ThemeManager.style.brandPrimary : 'transparent',
                borderLeftColor: getTicketColor(item),
                borderWidth: 1,
                borderLeftWidth: 5,
                borderRadius: 5,
                marginBottom: 2,
                justifyContent: 'center',
                ...ThemeManager.noSelectionWebStyle(),
            }}>
            <MaterialText fontSize={14} fixedWidth="100%" textAlign="left">
              #{item.ticketNumber} {item.title}
            </MaterialText>
          </Ripple>
        </Draggable>
      </View>);
    };
    const renderTicketCard = ({ item }) => {
        const { planId, activeMultiselect, onMultiSelect, selectedIDs, showProject } = props;
        const dragId = `${item.id}_${uuid.v1()}`;
        let pressOut = new Date();
        let dragstarted = false;
        const onDropped = () => {
            const d = new Date();
            if (pressOut.getTime() + 300 <= d.getTime() && !dragstarted) {
                if (activeMultiselect && onMultiSelect)
                    setMultiSelect(item);
                else {
                    Routing.instance.openDialog('ticket', { id: item.id })(null);
                }
            }
            dragstarted = false;
        };
        return (<Draggable startsOnLongPress={false} dropData={item} dragId={dragId} onDragShouldStart={() => {
                dragstarted = true;
                pressOut = new Date();
            }} onStartDrag={() => {
                dragstarted = true;
            }} onDropNotInside={onDropped} onDropped={onDropped} onDragEnd={() => {
                onDropped();
            }}>
        <TicketCard onLongPress={activeMultiselect && onMultiSelect ? undefined : DragHandler.startDragById(dragId)} onPressIn={activeMultiselect && onMultiSelect
                ? () => setMultiSelect(item)
                : (_e) => {
                    pressOut = new Date();
                    DragHandler.startDragById(dragId)();
                    setTimeout(() => {
                        if (!dragstarted) {
                            onDropped();
                        }
                    }, 300);
                }} ticket={item} key={`tCard${item.id}`} bullseye={planId != null && planId !== 'all'} activeMultiselect={activeMultiselect} status selected={selectedIDs.has(item.id)} showProjects={showProject}/>
      </Draggable>);
    };
    const renderContent = () => {
        const { height, tickets, iosContentInset, width } = props;
        const hideSideBar = isS();
        let calendar = null;
        if (range === 'month')
            calendar = (<MonthCalendar currentDate={currentDate} tickets={tickets} iosContentInset={iosContentInset} onDrop={onDrop} renderTicketCard={renderTicketCard} renderTicketName={renderTicketName} width={width} height={height} noDateWidth={noDateWidth} setNewDate={setNewDate} hideRightBorder={hideSideBar} getTicketsForDate={getTicketsForDate}/>);
        else if (range === 'week')
            calendar = (<WeekCalendar currentDate={currentDate} tickets={tickets} iosContentInset={iosContentInset} onDrop={onDrop} renderTicketCard={renderTicketCard} renderTicketName={renderTicketName} width={width} height={height} noDateWidth={noDateWidth} setNewDate={setNewDate} getTicketsForDate={getTicketsForDate}/>);
        else {
            calendar = (<DayCalendar currentDate={currentDate} dayHeight={height - 96} iosContentInset={iosContentInset} onDrop={onDrop} renderTicketCard={renderTicketCard} filteredTickets={getTicketsForDate(currentDate)}/>);
        }
        if (hideSideBar) {
            return <View style={{ flex: 1, height: height - 96 }}>{calendar}</View>;
        }
        if (noDateWidth <= 40) {
            const text = I18n.m.getMessage('ticketsWithoutCompletionOn');
            const TEXT_LENGTH = 200;
            const TEXT_HEIGHT = 36;
            const OFFSET = TEXT_LENGTH / 2 - TEXT_HEIGHT / 2;
            return (<View style={{
                    flexDirection: 'row',
                    flex: 1,
                    height: height - 96,
                    backgroundColor: ThemeManager.style.appBgColor,
                }}>
          <View style={{ flex: 1, height: height - 96 }}>{calendar}</View>
          <View style={{ width: noDateWidth, paddingLeft: 4, height: height - 96 }}>
            <Ripple style={{
                    width: noDateWidth - 4,
                    height: '100%',
                    borderLeftWidth: 0.5,
                    borderColor: '#ccc',
                }} toolTip={I18n.m.getMessage('ticketsWithoutCompletionOn')} onPress={() => setNoDateWidth(256)}>
              <View style={{ height: 40, justifyContent: 'center' }}>
                <Icon icon="menu-open" toolTip={I18n.m.getMessage('ticketsWithoutCompletionOn')} onPress={() => setNoDateWidth(256)}/>
              </View>
              <View style={{ flex: 1 }}>
                <View style={{ width: TEXT_HEIGHT, height: TEXT_LENGTH }}>
                  <Text style={{
                    ...ThemeManager.style.Body1,
                    transform: [{ rotate: '90deg' }, { translateX: OFFSET }, { translateY: OFFSET + 10 }],
                    width: TEXT_LENGTH,
                    height: TEXT_HEIGHT,
                }}>
                    {text}
                  </Text>
                </View>
              </View>
            </Ripple>
          </View>
        </View>);
        }
        return (<View style={{ flexDirection: 'row', flex: 1, height: height - 96 }}>
        <View style={{ flex: 1, height: height - 96 }}>{calendar}</View>
        <View style={{ width: noDateWidth, height: height - 96 }}>
          <NoDateTickets height={height} iosContentInset={iosContentInset} noDateWidth={noDateWidth} tickets={tickets} onDrop={onDrop} renderTicketName={renderTicketName} setNoDateWidth={setNoDateWidth}/>
        </View>
      </View>);
    };
    const { height, showProject } = props;
    return (<View style={{ padding: 16, width: '100%', maxHeight: height }}>
      <CalendarHeader changeRange={changeRange} changeView={changeView} currentDate={currentDate} range={range} rangeIndex={rangeIndex} setNewDate={setNewDate} showProject={showProject} useMonth={useMonth}/>
      <View style={{
            borderRadius: ThemeManager.style.borderRadius,
            borderColor: '#ccc',
            borderWidth: 0.5,
            backgroundColor: ThemeManager.style.appBgColor,
            overflow: 'hidden',
            height: height - 96,
        }}>
        {renderContent()}
      </View>
    </View>);
}
