import { Platform, View } from 'react-native';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import color from 'color';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import React, { useEffect, useState } from 'react';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { MaterialTextEditable } from 'materialTheme/src/theme/components/text/MaterialTextEditable';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { onPressViewOptions, renameView } from './ticketViewUtil';
import { I18n } from '../../i18n/I18n';
export default function TicketTab(props) {
    const [title, setTitle] = useState(props.view.name);
    const [isRenaming, setRenaming] = useState(false);
    const [isHovering, setHovering] = useState(false);
    const confirmRename = () => {
        const { view } = props;
        renameView(view.id, title);
        setRenaming(false);
    };
    const cancelRename = () => {
        setRenaming(false);
        setTitle(props.view.name);
    };
    useEffect(() => {
        if (!props.selected)
            cancelRename();
    }, [props.selected]);
    const { tab, index, dimensions: { width, height }, selected, onSelect, view, additionalData, viewDataHasChanged, } = props;
    const textColor = ThemeManager.style.defaultTextColor;
    const unfocusedColor = ThemeManager.style.defaultTextColorLighten;
    const { userId } = CurrentUser;
    const canEdit = (!view.isPublic || CurrentUser.userId === view.createdBy) && !(view.id === 'main');
    let rightIcons = null;
    if (isRenaming)
        rightIcons = (<>
        <Icon icon="close" toolTip="" onPress={cancelRename}/>
        <Icon icon="check" toolTip="" onPress={confirmRename}/>
      </>);
    else if ((selected || isHovering) && canEdit)
        rightIcons = (<View style={{ position: 'absolute', right: 0 }}>
        <Icon icon="dots-vertical" toolTip="" onPress={onPressViewOptions(view, () => setRenaming(true), additionalData, viewDataHasChanged && selected)} color={selected ? textColor : unfocusedColor}/>
      </View>);
    const isWeb = Platform.OS === 'web';
    const isOwnDefault = view.default && view.createdBy === userId;
    return (<View key={`tabbarElement${index}${tab.title ? tab.title : ''}`} style={{
            alignSelf: 'center',
            width,
            height,
            alignItems: 'center',
            flexDirection: 'row',
            position: 'relative',
        }} onPointerEnter={isWeb ? () => setHovering(true) : undefined} onPointerLeave={isWeb ? () => setHovering(false) : undefined}>
      {isRenaming ? (<View style={{ flex: 1, paddingTop: 4, justifyContent: 'center' }}>
          <MaterialTextEditable placeholder={view.name} underlineColor="transparent" autoFocus autoSelectToEnd onChange={(text) => setTitle(text.substring(0, 56))} onSubmitByEnter={confirmRename} materailTextProps={{}}>
            {title}
          </MaterialTextEditable>
        </View>) : (<Ripple style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                height,
                alignSelf: 'center',
                flexDirection: 'row',
                position: 'relative',
                paddingRight: 8,
                paddingLeft: 8,
            }} key={`tabRipple${view.isPublic}`} rippleColor={color(textColor).alpha(0.4).toString()} onPress={onSelect} disabled={selected} toolTip={`${view.name}${view.isPublic ? ` (${I18n.m.getMessage('ticketsViewPublic')})` : ''}`}>
          {isOwnDefault && (<Icon icon="star-outline" toolTip="" iconSize={20} color={selected ? textColor : unfocusedColor}/>)}
          {view.isPublic && (<Icon icon="web" toolTip={I18n.m.getMessage('ticketsViewPublic')} iconSize={20} color={selected ? textColor : unfocusedColor}/>)}
          <View style={{
                flex: 1,
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
            }}>
            <MaterialText type={MaterialTextTypes.Button} color={selected ? textColor : unfocusedColor} centeredText centeredBox numberOfLines={1} additionalTextStyle={{
                paddingLeft: !isOwnDefault && !view.isPublic ? 8 : 0,
                paddingRight: 8,
            }}>
              {tab.title}
            </MaterialText>
            <View style={{
                marginLeft: 4,
                height: 6,
                width: 6,
                borderRadius: 3,
                backgroundColor: viewDataHasChanged && selected ? textColor : 'transparent',
            }}/>
          </View>
        </Ripple>)}
      {rightIcons}
    </View>);
}
