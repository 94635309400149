import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import React from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
import { BimViewer } from './BimViewer';
export function BimViewerDialog(props) {
    return (<View key="bimViewer" id="123" testID="1234" style={{ width: '100%', height: props.size ? props.size.contentHeight + 64 : '100%', backgroundColor: 'white' }}>
      <BimViewer file={props.file} json={props.json} size={props.size ? { ...props.size, contentHeight: props.size.contentHeight + 64 } : undefined}/>
      <View style={{ position: 'absolute', top: 8, right: 8, width: 48, height: 48 }}>
        <Icon icon="close" toolTip={I18n.m.getMessage('close')} onPress={Dialog.instance?.close}/>
      </View>
    </View>);
}
