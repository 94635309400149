import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { StringList } from 'materialTheme/src/theme/components/forminput/StringList';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddCompanyList } from 'upmesh-core/src/client/commands/companies/lists/AddCompanyList';
import { ChangeCompanyList } from 'upmesh-core/src/client/commands/companies/lists/ChangeCompanyList';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export function AddOrChangeCompanyList(props) {
    const [currentList, setCurrentList] = useState(props.list ? props.list : { list: [], name: '' });
    const [formError, setFormError] = useState(new Map());
    const [isLoading, setIsLoading] = useState(false);
    const submitForm = (_e) => {
        setIsLoading(true);
        if (currentList.name == null || currentList.name.length === 0) {
            const s = new Map();
            s.set('name', I18n.m.getMessage('required'));
            setFormError(s);
            setIsLoading(false);
            return;
        }
        setFormError(new Map());
        if (currentList.id != null) {
            const c = new ChangeCompanyList({ list: currentList.list, name: currentList.name, listKey: currentList.id }, props.companySettings.id);
            c.execute(ClientStore.commandStore)
                .then(() => {
                Dialog.instance?.close();
            })
                .catch((err) => {
                setIsLoading(false);
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        }
        else {
            const add = new AddCompanyList({ list: currentList.list, name: currentList.name }, props.companySettings.id);
            add
                .execute(ClientStore.commandStore)
                .then(() => {
                Dialog.instance?.close();
            })
                .catch((err) => {
                setIsLoading(false);
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        }
    };
    return (<>
      <DialogTitle>
        {currentList.id == null
            ? I18n.m.getMessage('companylistsTitleCreateNew')
            : I18n.m.getMessage('companylistsTitleEdit', { name: currentList.name })}
      </DialogTitle>
      <DialogContent>
        <FormInputFilled disabled={isLoading} helperText={formError.has('name') ? formError.get('name') : ''} error={formError.has('name')} labelText={I18n.m.getMessage('ticketLayoutOptionsLabel')} initValue={currentList.name} onChange={(v) => {
            if (formError.has('name'))
                setFormError(new Map());
            setCurrentList({ ...currentList, name: v });
        }}/>
        <StringList disabled={isLoading} title={I18n.m.getMessage('ticketLayoutOptionsListItems')} initValue={[...currentList.list]} onChange={(e) => {
            setCurrentList({ ...currentList, list: e });
        }}/>
      </DialogContent>
      <DialogActions>
        <ContainedButton disabled={isLoading} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => Dialog.instance?.close()} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton loading={isLoading} onPress={submitForm} title={currentList.id == null ? I18n.m.getMessage('create') : I18n.m.getMessage('save')}/>
      </DialogActions>
    </>);
}
export const openAddOrChangeCompanyList = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        content: <AddOrChangeCompanyList {...props}/>,
        closeOnTouchOutside: false,
        contentPadding: false,
        scrollable: false,
        fullscreenResponsive: true,
        showCloseIcon: false,
    });
};
