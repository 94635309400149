import { Url } from 'cqrs-shared/src/uri/Url';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Page } from 'materialTheme/src/theme/components/Page';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { TabContent } from 'materialTheme/src/theme/components/tabs/TabBarContent';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { ProjectUploadButton } from '../file/ProjectUploadButton';
import { NoRights } from '../NoRights';
import { CurrentProject } from '../project/CurrentProject';
import { AppBar } from '../root/AppBar';
import { PlanDetailsComponent } from './PlanDetailsComponent';
import { PlanVersionsList } from './PlanVersionsList';
import { PlanZoomTileMapper } from './PlanZoomTileMapper';
export const PlanSplitView = React.memo((props) => {
    const [init, setInit] = useState(false);
    const [plan, setPlan] = useState();
    const [planVersions, setPlanVersions] = useState([]);
    const [planVersionCount, setPlanVersionCount] = useState(0);
    const [selectedPlanVersion, setSelectedPlanVersion] = useState(undefined);
    const [error, setError] = useState('');
    const [splitView, setSplitView] = useState(false);
    const initRunning = useRef(false);
    const initTO = useRef(null);
    const getSplit = () => {
        return ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM;
    };
    const gotoPlanVersions = () => {
        const { projectId, planId } = props;
        Routing.instance.goTo(`/projects/${projectId}/plans/${planId}/versions`, true);
    };
    const gotoPlanDetails = () => {
        const { projectId, planId } = props;
        Routing.instance.goTo(`/projects/${projectId}/plans/${planId}`, true);
    };
    const initFunction = async () => {
        if (!initRunning.current) {
            initRunning.current = true;
            if (initTO.current != null)
                clearTimeout(initTO.current);
            const { planId, versionId, projectId } = props;
            await DateLocale.waitFor(() => UpmeshClient.instance.modals.plan != null);
            try {
                const getPlan = await UpmeshClient.instance.modals.plan.getById(planId);
                if (getPlan.deleted) {
                    Routing.instance.goTo(`/projects/${getPlan.projectId}/plans`, true);
                    Routing.instance.alert.post({ text: I18n.m.getMessage('planNotFound') });
                    initRunning.current = false;
                    return;
                }
                if (getPlan.activePlanId !== 'MAP') {
                    const getPlanVersions = await UpmeshClient.instance.modals.planVersion.get({
                        filter: `planId eq '${planId}' and deleted ne true`,
                    });
                    const planVersionId = versionId != null && versionId.length > 3 ? versionId : getPlan.activePlanId;
                    const pv = await UpmeshClient.instance.modals.planVersion.getById(planVersionId);
                    if (pv.deleted) {
                        Routing.instance.goTo(`/projects/${getPlan.projectId}/plans/${getPlan.id}/versions`, true);
                        initRunning.current = false;
                        return;
                    }
                    const planVersions = getPlanVersions;
                    planVersions.sort((a, b) => (a.version > b.version ? -1 : a.version < b.version ? 1 : 0));
                    setPlanVersions(planVersions);
                    setSelectedPlanVersion(pv);
                    setPlan(getPlan);
                    setInit(true);
                    setPlanVersionCount(planVersions.length);
                    initRunning.current = false;
                }
                else {
                    setPlanVersions([]);
                    setPlan(getPlan);
                    setInit(true);
                    setPlanVersionCount(0);
                    initRunning.current = false;
                }
            }
            catch (e) {
                initRunning.current = false;
                if (e.statusCode === 404) {
                    Routing.instance.goTo(`/projects/${projectId}/plans`, true);
                    Routing.instance.alert.post({ text: I18n.m.getMessage('planNotFound') });
                    setError(I18n.m.getMessage('planNotFound'));
                }
                else {
                    Routing.instance.alert.post({
                        text: `${I18n.m.getMessage('plansErrorNotFound')} ${DefaultErrorHandler.getDefaultErrorMessages(e)}`,
                    });
                    console.debug('plan error details', e);
                    ErrorReporter.sendReport({
                        subject: `plan error - ${e.name} ${e.message}`,
                        data: e,
                        type: 'warn',
                    });
                }
            }
        }
        else if (initTO.current == null)
            initTO.current = setTimeout(() => {
                initFunction().catch((err) => console.error(err));
            }, 500);
    };
    const handlePlanChange = (_e) => {
        initFunction().catch((err) => console.debug(err));
    };
    const handleVersionsChange = (_e) => {
        initFunction().catch((err) => console.debug(err));
    };
    useEffect(() => {
        setInit(false);
        initFunction().catch((e) => console.debug('cant init PlanSplitView', e));
    }, [props.planId, props.versionId]);
    useEffect(() => {
        CurrentProject.planChanged.attach(handlePlanChange);
        CurrentProject.planVersionsChanged.attach(handleVersionsChange);
        initFunction().catch((e) => console.debug('cant init PlanSplitView', e));
        return () => {
            CurrentProject.planChanged.detach(handlePlanChange);
            CurrentProject.planVersionsChanged.detach(handleVersionsChange);
        };
    }, []);
    const onLayout = (_e) => {
        const splitView = getSplit();
        setSplitView(splitView);
    };
    const goBack = () => {
        if (plan != null) {
            Routing.instance.goTo(`/projects/${plan.projectId}/plans`, true);
        }
        else {
            Routing.instance.goTo('/', true);
        }
    };
    const goBackFromVersion = () => {
        const { tab } = props;
        if (plan != null) {
            Routing.instance.goTo(`/projects/${plan.projectId}/plans/${plan.id}/${tab}`, true);
        }
        else {
            Routing.instance.goTo('/', true);
        }
    };
    const changeTabLink = (i) => {
        const { versionId } = props;
        if (splitView || versionId == null) {
            if (i === 1) {
                gotoPlanVersions();
            }
            else {
                gotoPlanDetails();
            }
        }
    };
    const renderPlan = (planVersion) => {
        if (plan == null)
            return null;
        if (planVersion == null) {
            return (<PlanZoomTileMapper plan={plan} type="map" planVersion={planVersion} zoomAble key={`plan_${plan.id}`} url={`${UpmeshClient.instance.url}`}/>);
        }
        return (<PlanZoomTileMapper type="plan" plan={plan} planVersion={planVersion} offlineData={planVersion == null
                ? undefined
                : OfflineDataDownloader.isPlanVersionSynced(planVersion.projectId, planVersion.id)} zoomAble key={`planVersion_${planVersion.id}`} url={`${UpmeshClient.instance.url}/plan/tiles/${planVersion.projectId}/planVersion/${planVersion.id}`}/>);
    };
    const renderInit = () => {
        return (<Page>
          <Spinner />
        </Page>);
    };
    const renderError = () => {
        return <NoRights error={error}/>;
    };
    const getTitle = (currentPlan) => {
        if (selectedPlanVersion != null)
            return `${currentPlan.title} v${selectedPlanVersion.version}`;
        return currentPlan.title;
    };
    const renderTabContent = () => {
        const { projectId, planId, versionId } = props;
        if (plan == null)
            return [];
        const tabContent = [
            <ScrollView key={`PlanDetails${planId}`} style={{
                    flexDirection: 'column',
                    width: '100%',
                    height: ResizeEvent.current.contentHeight - ThemeManager.style.headerHeight - 48,
                }}>
          <PlanDetailsComponent plan={plan} showThumb={!splitView}/>
        </ScrollView>,
        ];
        if (plan.activePlanId !== 'MAP') {
            tabContent.push(<View key={`PlanVersions${plan.id}`}>
            <View style={{
                    padding: ThemeManager.style.contentPaddingValue,
                    paddingBottom: 0,
                    width: 256,
                    alignSelf: 'center',
                }}>
              <ProjectUploadButton uploadText={I18n.m.getMessage('planVersionsUploadDirect')} uploadIcon="cloud-upload-outline" uploadDropDialogText={I18n.m.getMessage('planVersionsUploadDirect')} multiple={false} endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/planfiles/version`)} data={{ projectId, planId }} id={`planVersion_${planId}`} key={`planVersion_${planVersionCount}`} filter={['pdf', 'images']} iconPosition="top" maxImageSize={0}/>
            </View>
            <PlanVersionsList key="PlanVersionsList" width={splitView ? 256 : ResizeEvent.current.contentWidth} height={ResizeEvent.current.contentHeight -
                    ThemeManager.style.headerHeight -
                    ThemeManager.style.getScreenRelativePixelSize(194)} plan={plan} planVersions={planVersions} selectedPlanVersionId={versionId}/>
          </View>);
        }
        return tabContent;
    };
    const renderSingleView = (currentPlan, secondView) => {
        return (<View onLayout={onLayout} style={{
                width: '100%',
                height: '100%',
            }}>
          <AppBar title={getTitle(currentPlan)} leftButtons={[
                <Icon toolTip={I18n.m.getMessage('back')} key="backIcon" icon="arrow-left" onPress={goBackFromVersion} outerSize={48}/>,
            ]}/>
          <View onLayout={onLayout} style={{
                width: '100%',
                height: ResizeEvent.current.contentHeight - ThemeManager.style.headerHeight,
            }}>
            {secondView}
          </View>
        </View>);
    };
    const renderSplitView = (currentPlan, secondView) => {
        const { tab, versionId } = props;
        let page1Style = { width: '100%' };
        if (splitView || (versionId != null && versionId.length > 0)) {
            page1Style = {
                maxWidth: 256,
                borderWidth: 0,
                borderRightWidth: ThemeManager.style.borderWidth,
                borderStyle: 'solid',
                borderColor: ThemeManager.style.borderColor,
                backgroundColor: '#FFFFFF',
            };
        }
        const tabMenu = [{ title: I18n.m.getMessage('planDetails') }];
        if (currentPlan.activePlanId !== 'MAP') {
            tabMenu.push({ title: I18n.m.getMessage('planVersions') });
        }
        let selectedTab = tab === 'versions' ? 1 : 0;
        if (currentPlan.activePlanId === 'MAP')
            selectedTab = 0;
        return (<View onLayout={onLayout} style={{
                width: '100%',
                height: ResizeEvent.current.contentHeight,
                flexDirection: 'row',
            }}>
          <View style={page1Style}>
            <View style={{
                flexDirection: 'column',
            }}>
              <AppBar title={currentPlan.title} additionalContentPaddingLeft={false} leftButtons={[
                <Icon toolTip={I18n.m.getMessage('back')} key="backIcon" icon="arrow-left" onPress={goBack} outerSize={48}/>,
            ]} additionalContent={<View style={{
                    height: ThemeManager.style.getScreenRelativePixelSize(48),
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: '100%',
                    flexDirection: 'row',
                }}>
                    <TabBar textColor={ThemeManager.style.black87} unfocusedTextColor={ThemeManager.style.disabledColor} underlineColor={ThemeManager.style.brandPrimary} contentHeight={0} width={splitView ? 255 : ResizeEvent.current.contentWidth} onChangeSelected={changeTabLink} selected={selectedTab} tabs={tabMenu}/>
                  </View>}/>
              <TabContent height={ResizeEvent.current.contentHeight - ThemeManager.style.headerHeight - 48} selected={selectedTab} width={splitView ? 256 : ResizeEvent.current.contentWidth} changeSelection={changeTabLink} contents={renderTabContent()}/>
            </View>
          </View>
          <View style={{
                width: splitView ? ResizeEvent.current.contentWidth - 312 : ResizeEvent.current.contentWidth,
                flexDirection: 'row',
            }}>
            {secondView}
          </View>
        </View>);
    };
    if (!init)
        return renderInit();
    if (error != null && error.length > 0)
        return renderError();
    if (plan == null)
        return <View />;
    const secondView = [];
    if (splitView || (props.versionId != null && props.versionId.length > 0)) {
        if (selectedPlanVersion != null && plan.activePlanId !== 'MAP') {
            secondView.push(renderPlan(selectedPlanVersion));
        }
        else if (plan.activePlanId === 'MAP') {
            secondView.push(renderPlan());
        }
        else {
            if (!splitView) {
                gotoPlanDetails();
            }
        }
    }
    if (splitView || props.versionId == null || props.versionId.length === 0) {
        return renderSplitView(plan, secondView);
    }
    return renderSingleView(plan, secondView);
});
