import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { ProgressBar } from 'materialTheme/src/theme/components/ProgressBar';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export function PromisesBulkChangesDialog(props) {
    const [step, setStep] = useState(0);
    const [errors, setErrors] = useState([]);
    useEffect(() => {
        let mounted = true;
        if (step < props.promises.length) {
            props.promises[step]
                .execute()
                .catch((e) => {
                const err = DefaultErrorHandler.getDefaultErrorMessages(e);
                setErrors([...errors, err]);
            })
                .finally(() => {
                if (mounted)
                    setStep(step + 1);
            });
        }
        return () => {
            mounted = false;
        };
    }, [step]);
    const progressInPercent = Math.round((step / props.promises.length) * 100);
    return [
        <DialogTitle>{props.title ? props.title : I18n.m.getMessage('bulkChanges')}</DialogTitle>,
        <DialogContent>
      {progressInPercent >= 100 && errors.length > 0 ? (<View style={{ width: '100%' }}>
          <MaterialText>
            {I18n.m.getMessage('bulkChangesFinishWithErrors', { count: step - errors.length, errors: errors.length })}
          </MaterialText>
          {errors.map((error, i) => {
                    return (<MaterialText>
                ${i}: ${error}
              </MaterialText>);
                })}
        </View>) : (<MaterialText>
          {I18n.m.getMessage(progressInPercent >= 100
                    ? 'bulkChangesFinish'
                    : props.progressMessageCode
                        ? props.progressMessageCode
                        : 'bulkChangesProgress', {
                    count: step,
                    total: props.promises.length,
                })}
        </MaterialText>)}
      <ProgressBar progressInPercent={progressInPercent}/>
    </DialogContent>,
        <DialogActions>
      <ContainedButton title={step >= props.promises.length ? I18n.m.getMessage('close') : I18n.m.getMessage('cancel')} onPress={() => {
                Dialog.instance?.close(props.onClose);
            }}/>
    </DialogActions>,
    ];
}
export const openPromisesBulkChangesDialog = (props) => (e) => {
    const openPosition = e
        ? { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY }
        : { x: ResizeEvent.current.windowWidth / 2, y: ResizeEvent.current.windowHeight / 2 };
    Dialog.instance?.open({
        content: <PromisesBulkChangesDialog promises={props.promises} title={props.title}/>,
        fullscreenResponsive: true,
        width: 768,
        contentPadding: false,
        openPosition,
        scrollable: false,
        openImmediately: false,
        closeOnTouchOutside: false,
        showCloseIcon: false,
    });
};
