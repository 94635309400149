import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { DeleteApprovalProcess } from 'upmesh-core/src/client/commands/companies/approvalprocess/DeleteApprovalProcess';
import { OrderedApprovalProcess } from 'upmesh-core/src/client/query/entities/simple/OrderedApprovalProcess';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
import { openApprovalProcessEdiCreateDialog } from './ApprovalProcessEditCreateDialog';
const noGroupImg = require('../../../assets/img/no_types_or_crafts_or_tags.png');
export function CompanyApprovalProcessView(props) {
    const [data, setData] = useState([]);
    const myRole = CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
    const loadData = async () => {
        const d = await UpmeshClient.instance.modals.approvalProcess.get({ filter: 'deleted ne true' });
        const e = OrderedApprovalProcess.orderApprovals(d);
        const d2 = [];
        for (const item of e) {
            const i = item;
            i.creatorName = '?';
            try {
                const user = item.createdBy === 'all'
                    ? new UserEntity({ firstname: '', lastname: 'System' })
                    :
                        await AuthClient.instance.modals.user.getById(item.createdBy);
                i.creatorName = user.getFullName();
            }
            catch (e) {
                console.error('cant get user name on CompanyApprovalProcessView', e);
            }
            const k = `${i.forType.charAt(0).toUpperCase()}${i.forType.slice(1)}`;
            i.forTypeTranslated = I18n.m.getMessage(`approvalProcessOnType${k}`);
            if (i.forTaskOrExtraPay) {
                if (i.forType === 'extraPay') {
                    const ep = await UpmeshClient.instance.modals.extraPay.getById(i.forTaskOrExtraPay);
                    i.forTaskOrExtraPayTranslated = ep.getName();
                }
                else {
                    const task = await UpmeshClient.instance.modals.task.getById(i.forTaskOrExtraPay);
                    i.forTaskOrExtraPayTranslated = task.getName();
                }
            }
            else {
                i.forTaskOrExtraPayTranslated = I18n.m.getMessage('approvalProcessOnTypeAll');
            }
            const k2 = `${i.validFor.charAt(0).toUpperCase()}${i.validFor.slice(1)}`;
            i.validForTranslated = I18n.m.getMessage(`approvalProcessValidFor${k2}`);
            d2.push(i);
        }
        setData(d2);
    };
    const openEditDialog = (a) => (e) => {
        if (myRole === 'admin') {
            openApprovalProcessEdiCreateDialog({ approvalProcess: a })(e);
        }
        else {
            Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
        }
    };
    const openFab = () => {
        if (Fab.instance == null) {
            window.setTimeout(openFab, 100);
            return;
        }
        const permitted = myRole === 'admin';
        Fab.instance.open({
            fabIcon: 'plus',
            small: false,
            fabColor: ThemeManager.style.brandPrimary,
            fabColorOpen: ThemeManager.style.brandSecondary,
            onPressFab: permitted
                ? openApprovalProcessEdiCreateDialog({})
                : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
        });
    };
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyApprovalProcessView',
            readModelName: 'ApprovalProcess',
            callback: () => {
                loadData().catch(DefaultErrorHandler.showDefaultErrorAlert);
            },
        });
        loadData().catch(DefaultErrorHandler.showDefaultErrorAlert);
        openFab();
        return () => {
            UpmeshClient.eventDispatcher.detach('ApprovalProcess', 'CompanyApprovalProcessView');
            if (Fab.instance != null)
                Fab.instance.close();
        };
    }, []);
    const removeHolidayNow = (a) => (_e) => {
        LoadingEvents.instance.startLoading();
        Alert.instance?.close(() => {
            const remove = new DeleteApprovalProcess({}, a.id, CurrentUser.token);
            remove
                .execute(ClientStore.commandStore)
                .catch((err) => {
                console.error(err);
                Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(err, I18n.m) });
            })
                .finally(() => {
                LoadingEvents.instance.stopLoading();
            });
        });
    };
    const remove = (a) => (_e) => {
        Routing.instance.alert.post({
            title: I18n.m.getMessage('companyRemoveApprovalProcessQuestionTitle'),
            text: I18n.m.getMessage('companyRemoveApprovalProcessQuestion', { title: a.title }),
            buttons: [
                <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={removeHolidayNow(a)} backgroundColor={ThemeManager.style.brandDanger}/>,
            ],
        });
    };
    if (!(myRole === 'admin' || myRole === 'user'))
        return null;
    const tableMaxHeight = props.size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(60);
    console.log('DATA 2', data, tableMaxHeight);
    return (<View style={{
            width: '100%',
            maxWidth: '100%',
            paddingTop: ThemeManager.style.contentPaddingValue,
            alignSelf: 'center',
            paddingHorizontal: 8,
        }}>
      <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
        <MaterialText centeredBox type={MaterialTextTypes.H6}>
          {I18n.m.getMessage('approvalProcess')}
        </MaterialText>
      </View>
      <Card style={{ width: '100%', height: tableMaxHeight }}>
        <Table key={JSON.stringify(data)} tableName="CompanyApprovalProcesses" actionItemsLength={myRole === 'admin' ? 2 : 0} sortable={false} actions={myRole === 'admin'
            ? (_item) => [
                {
                    icon: 'pencil-outline',
                    onAction: openEditDialog,
                    toolTip: I18n.m.getMessage('edit'),
                },
                {
                    icon: 'delete-outline',
                    onAction: remove,
                    toolTip: I18n.m.getMessage('delete'),
                },
            ]
            : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noGroupImg} emptyTableHint={I18n.m.getMessage('companyApprovalProcessNoDataHint')} emptyTableText={I18n.m.getMessage('companyApprovalProcessNoDataText')} data={data} sortBy="order" sortDirection="asc" columns={[
            {
                title: I18n.m.getMessage('approvalProcessName'),
                keyInData: 'title',
                style: { width: 200 },
                sortable: false,
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('approvalProcessOnType'),
                keyInData: 'forTypeTranslated',
                style: { width: 200 },
                sortable: false,
                dataType: 'string',
            },
            {
                title: `${I18n.m.getMessage('task')} / ${I18n.m.getMessage('absenceTask')} / ${I18n.m.getMessage('extraPay')}`,
                keyInData: 'forTaskOrExtraPayTranslated',
                style: { width: 200 },
                sortable: false,
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('approvalProcessValidFor'),
                keyInData: 'validForTranslated',
                style: { width: 200 },
                sortable: false,
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('status'),
                keyInData: 'active',
                style: { width: 200 },
                sortable: false,
                format: (a) => {
                    if (a.active)
                        return I18n.m.getMessage('active');
                    return I18n.m.getMessage('inactive');
                },
                dataType: 'string',
            },
        ]}/>
      </Card>
    </View>);
}
