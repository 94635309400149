import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { SwitchListItem } from 'materialTheme/src/theme/components/SwitchListItem';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import { I18n } from '../../../../i18n/I18n';
export function TicketLayoutDueDateOptions(props) {
    const [currentField, setCurrentField] = useState(props.field);
    const [formError, setFormError] = useState(new Map());
    const submitForm = (_e) => {
        if (currentField.label == null || currentField.label.length === 0) {
            const s = new Map();
            s.set('label', I18n.m.getMessage('required'));
            setFormError(s);
            return;
        }
        setFormError(new Map());
        props.onSave(currentField);
        Dialog.instance?.close();
    };
    const icon = TicketLayoutsEntity.getIconName(props.field);
    return (<>
      <DialogTitle iconRight={{ icon, color: ThemeManager.style.brandSuccess, toolTip: '' }}>
        {I18n.m.getMessage('ticketLayoutOptionsTileEdit', { title: currentField.label })}
      </DialogTitle>
      <DialogContent>
        <SwitchListItem title={I18n.m.getMessage('ticketLayoutOptionsDateWithTime')} value={!currentField.systemFieldOptions?.hideTime} onChange={(e) => {
            setCurrentField({ ...currentField, systemFieldOptions: { hideTime: !e } });
        }}/>
      </DialogContent>
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => Dialog.instance?.close()} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton disabled={formError.size > 0 || currentField.label == null || currentField.label.length === 0} onPress={submitForm} title={I18n.m.getMessage('save')}/>
      </DialogActions>
    </>);
}
