import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateInputFormFilled';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { BackHandler, Platform, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeExtraPayTracking, } from 'upmesh-core/src/client/commands/companies/extrapay/ChangeExtraPayTracking';
import { CreateExtraPayTrackings, } from 'upmesh-core/src/client/commands/companies/extrapay/CreateExtraPayTrackings';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { I18n } from '../../i18n/I18n';
import { Collaborator } from '../companies/Collaborator';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export class AddOrChangeExtraPayTrackDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const { extraPayTracking, selectedDate } = this.props;
            const projects = await UpmeshClient.instance.modals.project.get({ filter: `archived ne true and deleted ne true and active ne false` }, CurrentUser.userId);
            const projectPickerList = [];
            projects.forEach((pr, index) => projectPickerList.push({ title: pr.title, onPressChipData: { type: 'project', id: pr.id, index } }));
            const extraPays = await UpmeshClient.instance.modals.extraPay.get({ filter: `deleted ne true` }, CurrentUser.userId);
            const extraPayList = [];
            extraPays.forEach((ta) => extraPayList.push({ title: `${ta.mark} - ${ta.description}`, data: ta.id, extraPay: ta }));
            let selectedExtraPay = 0;
            const collaborators = await Collaborator.getCollaboratorList('lastExtraPayCollaborators', 'canExtraPayFor');
            const me = await AuthClient.instance.modals.user.getById(CurrentUser.userId);
            const companyMemberMe = await UpmeshClient.instance.modals.companyMember.get({
                filter: `userId eq '${me.id}'`,
                top: 1,
            });
            const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                filter: `companyId eq '${companyMemberMe[0].companyId}' and deleted ne true and active ne false`,
            });
            const costUnitList = [];
            const costCenterList = [];
            costCenters.forEach((cc) => {
                if (cc.costType === 'costUnit')
                    costUnitList.push({
                        title: `${cc.mark} - ${cc.description}`,
                        onPressChipData: { id: cc.id, type: 'costUnit', index: costUnitList.length },
                    });
                else
                    costCenterList.push({
                        title: `${cc.mark} - ${cc.description}`,
                        onPressChipData: { id: cc.id, type: 'costCenter', index: costCenterList.length },
                    });
            });
            let selectedProjectAndCost = {
                type: '',
                projectId: '',
                costId: '',
                costName: '',
            };
            let errorMessage;
            if (projectPickerList.length > 0) {
                const checked = await this.costCenterCheckForError(projectPickerList[0].onPressChipData);
                selectedProjectAndCost = checked.projectAndCost;
                errorMessage = checked.errorMessage;
            }
            else if (costCenterList.length > 0) {
                const checked = await this.costCenterCheckForError(costCenterList[0].onPressChipData);
                selectedProjectAndCost = checked.projectAndCost;
                errorMessage = checked.errorMessage;
            }
            else if (costUnitList.length > 0) {
                const checked = await this.costCenterCheckForError(costUnitList[0].onPressChipData);
                selectedProjectAndCost = checked.projectAndCost;
                errorMessage = checked.errorMessage;
            }
            else {
                errorMessage = I18n.m.getMessage('timeTrackingNoProjectOrCostCenter');
            }
            const segmentedList = [];
            if (projectPickerList.length > 0)
                segmentedList.push({ chips: projectPickerList, title: I18n.m.getMessage('projects'), type: 'project' });
            if (costCenterList.length > 0)
                segmentedList.push({ chips: costCenterList, title: I18n.m.getMessage('costCenters'), type: 'costCenter' });
            if (costUnitList.length > 0)
                segmentedList.push({ chips: costUnitList, title: I18n.m.getMessage('costUnits'), type: 'costUnit' });
            try {
                const getLastExtraPayTrackingExtraPay = SimpleStorage.get('lastExtraPay');
                if (getLastExtraPayTrackingExtraPay != null) {
                    const selectedExtraPayId = extraPayTracking != null ? extraPayTracking.extraPayId : getLastExtraPayTrackingExtraPay;
                    const extraPayIndex = extraPayList.findIndex((t) => t.data === selectedExtraPayId);
                    if (extraPayIndex > 0)
                        selectedExtraPay = extraPayIndex;
                }
            }
            catch (e) {
                console.debug('cant get lastTimeTrackingTask', e);
            }
            let selectedProject = 0;
            let selectedSegment = 0;
            try {
                if (extraPayTracking == null) {
                    const getLastTimeTrackingCostCenter = SimpleStorage.get('lastTimeTrackingCostCenter');
                    if (getLastTimeTrackingCostCenter != null) {
                        selectedProjectAndCost = JSON.parse(getLastTimeTrackingCostCenter);
                        selectedSegment = segmentedList.findIndex((c) => c.type === selectedProjectAndCost.type);
                        const list = segmentedList[selectedSegment];
                        const val = list.type === 'project' ? 'projectId' : 'costId';
                        const chip = list.chips.find((p) => p.onPressChipData.id === selectedProjectAndCost[val]);
                        if (chip != null) {
                            selectedProject = chip.onPressChipData.index;
                            const checked = await this.costCenterCheckForError(chip.onPressChipData);
                            selectedProjectAndCost = checked.projectAndCost;
                            errorMessage = checked.errorMessage;
                        }
                    }
                }
                else if (extraPayTracking.projectId != null && extraPayTracking.projectId.length > 0) {
                    const pi = projectPickerList.findIndex((t) => t.onPressChipData.id === extraPayTracking.projectId);
                    if (pi !== -1) {
                        selectedProject = pi;
                        selectedSegment = 0;
                        const checked = await this.costCenterCheckForError(projectPickerList[pi].onPressChipData);
                        selectedProjectAndCost = checked.projectAndCost;
                        errorMessage = checked.errorMessage;
                    }
                }
                else if (extraPayTracking.costCenterId != null) {
                    const pi = costCenterList.findIndex((t) => t.onPressChipData.id === extraPayTracking.costCenterId);
                    if (pi !== -1) {
                        selectedProject = pi;
                        selectedSegment = 1;
                        const checked = await this.costCenterCheckForError(costCenterList[pi].onPressChipData);
                        selectedProjectAndCost = checked.projectAndCost;
                        errorMessage = checked.errorMessage;
                    }
                }
            }
            catch (e) {
                console.debug('cant get lastTimeTrackingCostCenter', e);
            }
            let day;
            if (extraPayTracking != null) {
                day = extraPayTracking.day;
            }
            else if (selectedDate != null) {
                day = selectedDate;
            }
            else {
                day = new Date();
            }
            this.setState({
                day,
                segmentedList,
                extraPayList,
                selectedExtraPay,
                collaboratorList: collaborators.collaboratorList,
                selectedCollaborators: collaborators.selectedCollaborators,
                collaboratorGroups: collaborators.collaboratorGroups,
                isLoading: false,
                selectedListIndex: selectedProject,
                selectedSegment,
                selectedProjectAndCost,
                projectAndCostHelperText: errorMessage,
            });
        };
        this.onSave = () => {
            const { extraPayTracking } = this.props;
            const { day, selectedExtraPay, extraPayList, notes, selectedCollaborators, selectedProjectAndCost, numberField, startField, endField, isLoading, } = this.state;
            if (isLoading)
                return;
            this.setState({ isLoading: true }, () => {
                const asyncNow = async () => {
                    try {
                        let field;
                        const currentExtraPay = extraPayList[selectedExtraPay];
                        if (currentExtraPay.extraPay.fieldType === 'number') {
                            field = numberField;
                        }
                        else if (currentExtraPay.extraPay.fieldType === 'duration') {
                            if (startField == null)
                                throw [
                                    {
                                        message: 'wird benötigt',
                                        messageCode: 'required',
                                        key: 'extraField',
                                    },
                                ];
                            if (endField == null)
                                throw [
                                    {
                                        message: 'wird benötigt',
                                        messageCode: 'required',
                                        key: 'extraField',
                                    },
                                ];
                            field = {
                                from: { hours: startField.getHours(), minutes: startField.getMinutes() },
                                to: { hours: endField.getHours(), minutes: endField.getMinutes() },
                            };
                        }
                        if (extraPayTracking == null) {
                            const companyMember = (await UpmeshClient.instance.modals.companyMember.get({
                                filter: `userId eq ${CurrentUser.userId} and deleted ne true`,
                                top: 1,
                            }))[0];
                            const memberIds = [];
                            selectedCollaborators.forEach((u) => {
                                if (u.id != null) {
                                    memberIds.push(u.id);
                                }
                            });
                            const data = {
                                memberIds,
                                day,
                                extraPayId: extraPayList[selectedExtraPay].data,
                                companyId: companyMember.companyId,
                                notes: notes == null ? '' : notes,
                                projectId: selectedProjectAndCost.projectId,
                                costCenterId: selectedProjectAndCost.costId,
                                extraField: field,
                            };
                            const create = new CreateExtraPayTrackings(data);
                            await create.validate();
                            await create.execute(ClientStore.commandStore);
                        }
                        else {
                            const data = {
                                day,
                                extraPayId: extraPayList[selectedExtraPay].data,
                                notes: notes == null ? '' : notes,
                                projectId: selectedProjectAndCost.projectId,
                                costCenterId: selectedProjectAndCost.costId,
                                extraField: field,
                            };
                            const change = new ChangeExtraPayTracking(data, extraPayTracking.id);
                            await change.execute(ClientStore.commandStore);
                        }
                        Dialog.instance?.close();
                    }
                    catch (e) {
                        console.debug('errors', e);
                        this.setState({ isLoading: false }, () => {
                            DefaultErrorHandler.showDefaultErrorAlert(e);
                        });
                    }
                };
                asyncNow().catch((err) => console.error(err));
            });
        };
        this.costCenterCheckForError = async (selected) => {
            let errorMessage;
            let projectAndCost = {
                type: selected.type,
                projectId: selected.type === 'project' ? selected.id : undefined,
                costId: selected.type !== 'project' ? selected.id : '',
                costName: '',
            };
            if (selected.type === 'project') {
                const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                    filter: `deleted ne true`,
                });
                const center = costCenters.find((c) => c.projectIds != null && c.projectIds.indexOf(selected.id) > -1);
                projectAndCost = {
                    projectId: projectAndCost.projectId,
                    costId: center != null ? center.id : '',
                    type: projectAndCost.type,
                    costName: center != null ? center.getName() : '',
                };
                if (projectAndCost.costId === '') {
                    errorMessage = I18n.m.getMessage('costCenterProjectWithoutCenterError');
                }
            }
            else if (selected.type === 'costUnit') {
                const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                    filter: `deleted ne true and id eq '${selected.id}'`,
                });
                const { projectIds } = costCenters[0];
                if (projectIds == null || projectIds.length === 0) {
                    errorMessage = I18n.m.getMessage('costCenterNoProjectError');
                }
                else if (projectIds.length > 1) {
                    errorMessage = I18n.m.getMessage('costCenterMultipleProjectsError');
                }
                else {
                    projectAndCost = {
                        projectId: projectIds[0] != null ? projectIds[0] : undefined,
                        costId: projectAndCost.costId,
                        type: projectAndCost.type,
                        costName: costCenters[0].getName(),
                    };
                }
            }
            return { errorMessage, projectAndCost };
        };
        this.onPressProjectChip = (newSelected) => {
            const { segmentedList } = this.state;
            this.costCenterCheckForError(newSelected)
                .then((checked) => {
                if (checked.errorMessage == null || checked.errorMessage.length === 0) {
                    SimpleStorage.set('lastTimeTrackingCostCenter', JSON.stringify(checked.projectAndCost));
                }
                this.setState({
                    selectedProjectAndCost: checked.projectAndCost,
                    projectAndCostHelperText: checked.errorMessage,
                    selectedListIndex: newSelected.index,
                    selectedSegment: segmentedList.findIndex((c) => c.type === newSelected.type),
                });
            })
                .catch((err) => console.error(err));
        };
        this.validate = () => {
            const { extraPayTracking } = this.props;
            const { day, selectedExtraPay, extraPayList, notes, selectedCollaborators, selectedProjectAndCost, numberField, startField, endField, } = this.state;
            this.setState({ isLoading: true }, () => {
                const asyncNow = async () => {
                    try {
                        let field;
                        const currentExtraPay = extraPayList[selectedExtraPay];
                        if (currentExtraPay.extraPay.fieldType === 'number') {
                            field = numberField;
                        }
                        else if (currentExtraPay.extraPay.fieldType === 'duration') {
                            if (startField == null)
                                throw [
                                    {
                                        message: 'wird benötigt',
                                        messageCode: 'required',
                                        key: 'extraField',
                                    },
                                ];
                            if (endField == null)
                                throw [
                                    {
                                        message: 'wird benötigt',
                                        messageCode: 'required',
                                        key: 'extraField',
                                    },
                                ];
                            field = {
                                from: { hours: startField.getHours(), minutes: startField.getMinutes() },
                                to: { hours: endField.getHours(), minutes: endField.getMinutes() },
                            };
                        }
                        if (extraPayTracking == null) {
                            const companyMember = (await UpmeshClient.instance.modals.companyMember.get({
                                filter: `userId eq ${CurrentUser.userId} and deleted ne true`,
                                top: 1,
                            }))[0];
                            const memberIds = [];
                            selectedCollaborators.forEach((u) => {
                                if (u.id != null) {
                                    memberIds.push(u.id);
                                }
                            });
                            const data = {
                                memberIds,
                                day,
                                extraPayId: extraPayList[selectedExtraPay].data,
                                companyId: companyMember.companyId,
                                notes: notes == null ? '' : notes,
                                projectId: selectedProjectAndCost.projectId,
                                costCenterId: selectedProjectAndCost.costId,
                                extraField: field,
                            };
                            const create = new CreateExtraPayTrackings(data);
                            await create.validate();
                        }
                        else {
                            const data = {
                                day,
                                memberId: extraPayTracking.memberId,
                                extraPayId: extraPayList[selectedExtraPay].data,
                                notes: notes == null ? '' : notes,
                                projectId: selectedProjectAndCost.projectId,
                                costCenterId: selectedProjectAndCost.costId,
                                extraField: field,
                            };
                            const change = new ChangeExtraPayTracking(data, extraPayTracking.id);
                            await change.validate();
                        }
                        this.setState({ isLoading: false, errors: undefined });
                    }
                    catch (e) {
                        console.debug('errors', e);
                        this.setState({ isLoading: false, errors: [...e] });
                    }
                };
                asyncNow().catch((err) => console.error(err));
            });
        };
        this.removeMember = (members) => () => {
            const { selectedCollaborators } = this.state;
            if (selectedCollaborators) {
                const selectedChips = selectedCollaborators.slice();
                for (const m of members) {
                    const i = selectedChips.findIndex((r) => (m.userId && r.id === m.userId) || r.id === m.id);
                    if (i > -1)
                        selectedChips.splice(i, 1);
                }
                this.setState({ selectedCollaborators: selectedChips });
            }
        };
        this.addMember = (members) => () => {
            const { selectedCollaborators, collaboratorList } = this.state;
            if (selectedCollaborators) {
                const selectedChips = selectedCollaborators.slice();
                for (const m of members) {
                    if (selectedChips.findIndex((s) => s.id === m.id) < 0) {
                        const selected = collaboratorList.find((s) => s.id === m.id);
                        if (selected != null)
                            selectedChips.push(selected);
                    }
                }
                this.setState({ selectedCollaborators: selectedChips });
            }
        };
        let startField = new Date();
        let endField = new Date();
        let numberField = 0;
        const { extraPayTracking } = props;
        if (extraPayTracking != null) {
            if (typeof extraPayTracking.extraField === 'number') {
                numberField = extraPayTracking.extraField;
            }
            else if (extraPayTracking.extraField != null) {
                startField = new Date(1970, 0, 1, extraPayTracking.extraField.from.hours, extraPayTracking.extraField.from.minutes);
                endField = new Date(1970, 0, 1, extraPayTracking.extraField.to.hours, extraPayTracking.extraField.to.minutes);
            }
        }
        this.state = {
            startField,
            endField,
            numberField,
            notes: extraPayTracking ? extraPayTracking.notes : undefined,
            segmentedList: [],
            extraPayList: [],
            collaboratorList: [],
            collaboratorGroups: [],
            day: props.selectedDate != null ? props.selectedDate : new Date(),
            selectedExtraPay: 0,
            selectedListIndex: 0,
            selectedSegment: 0,
            selectedCollaborators: [],
            isLoading: true,
            selectedProjectAndCost: { type: '', projectId: '', costId: '', costName: '' },
            numberFieldFocused: false,
            numberFieldTemp: '',
        };
    }
    componentDidMount() {
        if (Platform.OS === 'android')
            this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
                Dialog.instance?.close();
                return true;
            });
        this.init().catch((err) => console.debug('cant load data', err));
    }
    componentWillUnmount() {
        if (this.backHandler)
            this.backHandler.remove();
    }
    formatValue(currentExtraPay, value) {
        const v = value == null ? 0 : value;
        const delimiter = currentExtraPay?.extraPay?.field?.delimiter ? 1 : 0;
        const dezimals = currentExtraPay?.extraPay?.field?.dezimals ? currentExtraPay?.extraPay?.field?.dezimals : 0;
        return `${v.toLocaleString(delimiter === 0 ? 'de-DE' : 'en-US', {
            maximumFractionDigits: dezimals,
            minimumFractionDigits: dezimals,
        })}`;
    }
    renderExtraPayFields() {
        const { extraPayList, selectedExtraPay, numberField, errors, startField, endField, numberFieldFocused, numberFieldTemp, } = this.state;
        const currentExtraPay = extraPayList[selectedExtraPay];
        if (currentExtraPay.extraPay.fieldType === 'number') {
            const error = errors != null && errors.length > 0 ? errors.find((e) => e.key === 'extraField') : null;
            return (<FormInputFilled labelText={`${I18n.m.getMessage('extraPayFieldTypeNumberIn', {
                    unit: currentExtraPay?.extraPay?.field?.unit,
                })}`} keyboardType="numeric" onChange={(val) => {
                    this.setState({ numberFieldTemp: val });
                }} onFocus={() => {
                    this.setState({
                        numberFieldFocused: true,
                        numberFieldTemp: this.formatValue(currentExtraPay, numberField),
                    });
                }} onBlur={() => {
                    let cv = this.state.numberFieldTemp;
                    if (currentExtraPay?.extraPay?.field?.delimiter) {
                        cv = cv.replace(/[^\d,]+/g, '');
                    }
                    else {
                        cv = cv.replace(/[^\d,]+/g, '').replace(/,/g, '.');
                    }
                    this.setState({ numberField: Number.parseFloat(cv), numberFieldFocused: false }, this.validate);
                }} value={Number.isNaN(numberField)
                    ? ''
                    : numberFieldFocused
                        ? numberFieldTemp
                        : this.formatValue(currentExtraPay, numberField)} filterText={(v) => {
                    return v;
                }} error={error != null} helperText={error != null && error.messageCode != null ? I18n.m.getMessage(error.messageCode, error.messageData) : ''}/>);
        }
        if (currentExtraPay.extraPay.fieldType === 'duration') {
            return (<View style={{ flexDirection: 'row', width: '100%' }}>
          <View style={{ flex: 1, paddingRight: 8 }}>
            <DateInputFormFilled selectDate={false} clearButton={false} selectTime labelText={I18n.m.getMessage('workingTimeModelsStart')} value={startField} onChange={(startField) => {
                    if (startField != null) {
                        this.setState({ startField }, this.validate);
                    }
                }}/>
          </View>
          <View style={{ flex: 1, paddingLeft: 8 }}>
            <DateInputFormFilled selectDate={false} clearButton={false} selectTime labelText={I18n.m.getMessage('workingTimeModelsStop')} value={endField} onChange={(endField) => {
                    if (endField != null) {
                        this.setState({ endField }, this.validate);
                    }
                }}/>
          </View>
        </View>);
        }
        return null;
    }
    render() {
        const { extraPayTracking } = this.props;
        const { extraPayList, selectedExtraPay, selectedListIndex, selectedCollaborators, collaboratorList, day, notes, isLoading, collaboratorGroups, projectAndCostHelperText, selectedSegment, segmentedList, errors, selectedProjectAndCost, } = this.state;
        const dateError = errors != null && errors.length > 0 ? errors.find((e) => e.key === 'day') : null;
        if (extraPayList.length === 0) {
            return [
                <DialogTitle key="noExtraPays">{I18n.m.getMessage('companyExtraPayNoDataText')}</DialogTitle>,
                <DialogContent key="noExtraPaysInfo">
          <MaterialText>{I18n.m.getMessage('companyExtraPayNoDataHintTracking')}</MaterialText>
        </DialogContent>,
                <DialogActions key="actions" onBottom>
          <ContainedButton title={I18n.m.getMessage('close')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={() => Dialog.instance?.close()} disabled={isLoading}/>
          <ContainedButton title={I18n.m.getMessage('goToExtraPayAdmin')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={() => Dialog.instance?.close(() => {
                        Routing.instance.goTo('/company/extraPay');
                    })} disabled={isLoading}/>
        </DialogActions>,
            ];
        }
        let defaultError = '';
        if (errors && errors.length > 0) {
            errors.forEach((error) => {
                if (!error.key) {
                    defaultError += `${DefaultErrorHandler.getDefaultErrorMessages(error)}`;
                }
            });
        }
        let costCenterPickerLabel = I18n.m.getMessage('costCenter');
        let costCenterPickerHelperText = projectAndCostHelperText || '';
        if (selectedProjectAndCost.type === 'costUnit')
            costCenterPickerLabel = I18n.m.getMessage('costUnit');
        else if (selectedProjectAndCost.type === 'project') {
            costCenterPickerLabel = I18n.m.getMessage('project');
            if ((costCenterPickerHelperText == null || costCenterPickerHelperText.length === 0) &&
                selectedProjectAndCost.costId.length > 0) {
                if (selectedProjectAndCost.costName && selectedProjectAndCost.costName.length > 0)
                    costCenterPickerHelperText = `${I18n.m.getMessage('costCenter')}: ${selectedProjectAndCost.costName}`;
            }
        }
        const quickSelectedMe = selectedCollaborators.findIndex((r) => r.id === CompanyUserInfo.me?.id) > -1;
        const myGroup = CompanyUserInfo.me?.groupId;
        const myGroupMember = [];
        let quickSelectedMyGroup = false;
        let inSelected = 0;
        if (myGroup) {
            CompanyUserInfo.companyMember.forEach((a) => {
                if (a.groupId === myGroup) {
                    myGroupMember.push(a);
                    if (selectedCollaborators.find((c) => (a.userId && c.id === a.userId) || c.id === a.id)) {
                        inSelected += 1;
                    }
                }
            });
            quickSelectedMyGroup = inSelected === myGroupMember.length;
        }
        return [
            <DialogTitle key="title">
        {extraPayTracking != null ? I18n.m.getMessage('extraPayChangeTrack') : I18n.m.getMessage('extraPayAddTrack')}
      </DialogTitle>,
            <DialogContent key="content">
        <View style={{ padding: 0 }}>
          <DateInputFormFilled clearButton={false} labelText={I18n.m.getMessage('date')} value={day} onChange={(day) => {
                    if (day != null) {
                        this.setState({ day }, this.validate);
                    }
                }} error={dateError != null} helperText={dateError != null && dateError.messageCode != null
                    ? I18n.m.getMessage(dateError.messageCode, dateError.messageData)
                    : ''}/>
          {segmentedList.length === 0 ? null : (<OpenableChip formInputLabel={costCenterPickerLabel} dialogTitle={`${I18n.m.getMessage('costCenter')}/${I18n.m.getMessage('costUnit')}/${I18n.m.getMessage('project')}`} selected={selectedListIndex} selectedSegment={selectedSegment} onPressChip={this.onPressProjectChip} looksLikeFI formInputHelperText={costCenterPickerHelperText} formInputError={projectAndCostHelperText != null} segmentedChipsList={segmentedList}/>)}
          {extraPayTracking != null || collaboratorList.length === 1 ? null : (<View>
              <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('collaborator')}</MaterialText>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                <Chip thumbnail={<UserImage user={CurrentUser.entity} size={24}/>} title={I18n.m.getMessage('timeTrackingMyTimes')} backgroundColor={quickSelectedMe ? ThemeManager.style.brandPrimary : undefined} textColor={quickSelectedMe ? '#FFFFFF' : undefined} onPressChip={quickSelectedMe ? this.removeMember([CompanyUserInfo.me]) : this.addMember([CompanyUserInfo.me])} key={`myTimesChip_${quickSelectedMe}`}/>
                {myGroupMember.length > 1 && (<Chip thumbnail={<Icon icon="account-group-outline" color={quickSelectedMyGroup ? '#ffffff' : undefined} toolTip=""/>} title={I18n.m.getMessage('timeTrackingMyGroup')} backgroundColor={quickSelectedMyGroup ? ThemeManager.style.brandPrimary : undefined} textColor={quickSelectedMyGroup ? '#FFFFFF' : undefined} onPressChip={quickSelectedMyGroup ? this.removeMember(myGroupMember) : this.addMember(myGroupMember)} key={`myGroupChip_${quickSelectedMyGroup}`}/>)}
              </View>
              <ChipGroup key={`${JSON.stringify(selectedCollaborators)}_${JSON.stringify(collaboratorList)}`} chips={selectedCollaborators} availableChips={collaboratorList} label={I18n.m.getMessage('collaborator')} sortFormByGroup chipGroups={collaboratorGroups} onChanged={(chips) => {
                        SimpleStorage.set('lastExtraPayCollaborators', JSON.stringify(chips));
                        this.setState({ selectedCollaborators: chips }, () => this.forceUpdate(this.validate));
                    }}/>
            </View>)}
          <OpenableChip chipsList={extraPayList} dialogTitle={I18n.m.getMessage('extraPay')} formInputLabel={I18n.m.getMessage('extraPay')} selected={selectedExtraPay} onPressChip={(newIndex) => {
                    const { extraPayList } = this.state;
                    SimpleStorage.set('lastExtraPay', extraPayList[newIndex].data);
                    this.setState({ selectedExtraPay: newIndex }, this.validate);
                }} looksLikeFI/>
          {this.renderExtraPayFields()}
          <FormInputFilled labelText={I18n.m.getMessage('timeTrackingNotes')} value={notes} onChange={(notes) => this.setState({ notes }, this.validate)}/>
          {defaultError && defaultError.length > 0 ? (<MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
              {defaultError}
            </MaterialText>) : null}
        </View>
      </DialogContent>,
            <DialogActions key="actions" onBottom>
        <ContainedButton title={I18n.m.getMessage('cancel')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={() => Dialog.instance?.close()} disabled={isLoading}/>
        <ContainedButton title={extraPayTracking == null ? I18n.m.getMessage('timeTrackingCapture') : I18n.m.getMessage('save')} onPress={this.onSave} loading={isLoading} disabled={projectAndCostHelperText != null ||
                    selectedCollaborators.length === 0 ||
                    (errors != null && errors.length > 0)}/>
      </DialogActions>,
        ];
    }
}
