import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import React, { useEffect, useState } from 'react';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export function CompanyUserMemberPicker(props) {
    const [user, setUser] = useState();
    const onPress = (id) => () => {
        props.onChange(id);
    };
    const loadMember = async () => {
        const u = await UpmeshClient.instance.modals.companyMember.get({ filter: 'deleted ne true' });
        const l = [];
        l.push({ title: ` ${I18n.m.getMessage('selectMemberNoneOption')}`, onPressChip: onPress() });
        if (props.optionalEntries && props.optionalEntries.length > 0) {
            l.push(...props.optionalEntries);
        }
        for (const member of u) {
            if (props.exceptIds == null || props.exceptIds.indexOf(member.id) === -1) {
                let title = `${member.firstName} ${member.lastName}`;
                let user = new UserEntity({ firstname: member.firstName, lastname: member.lastName });
                if (member.userId) {
                    user = await AuthClient.instance.modals.user.getById(member.userId);
                    title = user.getFullName();
                }
                l.push({
                    title,
                    onPressChipData: member.id,
                    onPressChip: onPress(member.id),
                    thumbnail: <UserImage size={36} user={user}/>,
                });
            }
        }
        setUser(l);
    };
    useEffect(() => {
        loadMember().catch(DefaultErrorHandler.showDefaultErrorAlert);
    }, [props.selectedId]);
    if (user == null)
        return null;
    const selectedUser = props.selectedId ? user.findIndex((a) => a.onPressChipData === props.selectedId) : 0;
    return (<OpenableChip looksLikeFI selected={selectedUser === -1 ? 0 : selectedUser} chipsList={user} formInputLabel={props.labelText || I18n.m.getMessage('selectMember')} thumbOnlyOnList={false}/>);
}
