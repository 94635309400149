import { Url } from 'cqrs-shared/src/uri/Url';
import { WaitFor } from 'cqrs-shared/src/WaitFor';
import fetch from 'cross-fetch';
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { FileHandler } from 'materialTheme/src/FileHandler';
import { ClientDBadapter } from 'materialTheme/src/odataDB/loki/ClientDBadapter';
import { DeviceManager } from 'materialTheme/src/securestore/DeviceManager';
import { SecureLocalStoreB } from 'materialTheme/src/securestore/SecureLocalStoreB';
import { LokiDBOptions } from 'odatarepos/src/db/loki/LokiDataBase';
import { LokiODataDB } from 'odatarepos/src/db/loki/LokiODataDB';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { Platform } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { LoginWithAccessToken } from 'upmesh-core/src/client/commands/LoginWithAccessToken';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { Config } from './config/Config';
import { Init } from './config/Init';
import { SharedWorkerWeb } from './config/SharedWorkerWeb';
import { UpdateHint } from './UpdateHint';
export class ClientDB {
    constructor() {
        this.initialized = false;
        this.isInit = false;
        this.initDB = async (userId) => {
            console.log('load loki initDB', userId, this.isInit);
            if (Platform.OS === 'web' && Init.hasWorker) {
                return { db: undefined, dbOptions: {} };
            }
            if (this.isInit)
                await WaitFor.instance.waitFor(() => !this.isInit);
            this.isInit = true;
            if (this.clientDB != null && this.lastUserId != null && this.lastUserId.length > 0 && this.lastUserId === userId) {
                this.isInit = false;
                return { db: this.clientDB, dbOptions: {} };
            }
            if (this.clientDB != null && this.lastUserId !== userId) {
                try {
                    await Uploads.instance.stopUploads();
                }
                catch (e) {
                    console.debug('cant stop uploads', e);
                }
                await this.closeDB(false);
            }
            if (userId != null && userId.length > 0) {
                this.clientDB = new LokiODataDB();
                const adapter = await ClientDBadapter.getAdapter(userId);
                this.dbOptions = new LokiDBOptions(new FileHandler(`${Config.b2cURL}/files`), `${userId}.json`, adapter, undefined, false, true, false, undefined, undefined, undefined, true, Config.b2cURL, '');
                this.lastUserId = userId;
                this.isInit = false;
                console.log('load loki initDB finished', userId, this.isInit);
                return { db: this.clientDB, dbOptions: this.dbOptions };
            }
            await this.closeDB(false);
            this.lastUserId = undefined;
            this.isInit = false;
            return { db: undefined, dbOptions: {} };
        };
        if (ClientDB._instance != null) {
            throw new Error('ClientDB singleton');
        }
    }
    static get instance() {
        if (ClientDB._instance == null) {
            ClientDB._instance = new ClientDB();
        }
        return ClientDB._instance;
    }
    get db() {
        return this.clientDB;
    }
    get localDb() {
        return this.clientDB;
    }
    async closeDB(deleteDB) {
        if (this.clientDB != null) {
            await this.clientDB.closeDB();
            if (deleteDB && this.lastUserId != null) {
            }
        }
        this.clientDB = undefined;
    }
    async init() {
        console.log('init ClientDB!!!');
        await DeviceManager.loadDeviceId();
        const resetToken = SimpleStorage.get('resetToken');
        const { host } = Url.getURLfromString(Config.b2cURL);
        const tokenKey = `tt_${host}`;
        if (resetToken == null) {
            await SecureLocalStoreB.instance.removeItem(tokenKey);
            SimpleStorage.set('resetToken', 'no');
        }
        const getToken = await SecureLocalStoreB.instance.getItem(tokenKey);
        console.log('load loki init ClientDB!!!', getToken);
        try {
            await this.initAuth(getToken);
        }
        catch (e) {
            console.debug('load loki init ClientDB failed', e);
            if (getToken != null) {
                await SecureLocalStoreB.instance.removeItem(tokenKey);
                await AuthClient.instance.logIn({ token: '' });
            }
            else {
                throw e;
            }
        }
        this.initialized = true;
    }
    async initAuth(token) {
        if (this.upmeshConfig == null) {
            await this.checkForMinVersion();
            await Init.getServerConnection().connect(2000);
            if (Platform.OS === 'web')
                Init.connectionStatusChanged.post(Init.connectionStatus);
            this.upmeshConfig = {
                remoteUrl: Config.b2cURL,
                clientDB: this,
                reInitDB: this.initDB,
                serverConnection: () => Init.getServerConnection(token),
                worker: Platform.OS === 'web' && Init.hasWorker ? SharedWorkerWeb.instance : undefined,
                discardOnErrors: -1,
                startSync: false,
            };
            console.log('init upmesh Client');
            await UpmeshClient.instance.init(this.upmeshConfig, token);
            UpmeshClient.eventDispatcher.timeOutForCollectEntities = 150;
        }
        else {
            this.upmeshConfig.clientDB = undefined;
        }
        if (Platform.OS === 'web' && location.href.indexOf('#at=') >= 0) {
            const accessToken = location.href.split('#at=')[1];
            if (accessToken != null && accessToken.length > 0) {
                try {
                    const c = new LoginWithAccessToken({ token: accessToken });
                    await c.execute();
                    await SecureLocalStoreB.instance.setItem(`tt_${Url.getURLfromString(Config.b2cURL).host}`, c.result.token);
                    location.replace(location.href.split('#at=')[0]);
                }
                catch (e) {
                    console.debug('cant login with accesstoken', e);
                }
            }
        }
    }
    async checkForMinVersion() {
        try {
            const minVersionFetch = await fetch(`${Config.b2cURL}/backend/minVersion`);
            if (minVersionFetch.status === 200) {
                const minVersion = Number.parseInt(await minVersionFetch.text(), 10);
                const currentVersion = Number.parseInt(Config.getVersion().split('@')[1], 10);
                if (currentVersion < minVersion) {
                    setTimeout(UpdateHint.showUpdateHintForConnection, 5000);
                }
            }
        }
        catch (e) {
            console.debug('cant fetch min required version');
        }
    }
}
