import color from 'color';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import querystring from 'query-string';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { TicketStatus, TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
import { ProjectImage } from '../project/ProjectImage';
import { TicketEntitySynced } from './TicketEntitySynced';
export class TicketCardContent extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.onUrlChange = () => {
            const bg = this.getBackgroundColor();
            if (this.state.backgroundColor !== bg) {
                this.setState({ backgroundColor: bg });
            }
        };
        this.ticketChanged = (ticket) => {
            if (ticket.id === this.state.ticket.id) {
                this.setState({ ticket }, () => {
                    this.init(ticket).catch((err) => console.debug(err));
                });
            }
        };
        this.openTicket = (e) => {
            const { onOpen } = this.props;
            const { ticket } = this.state;
            Routing.instance.openDialog('ticket', { id: ticket.id })(e);
            if (onOpen != null)
                onOpen(ticket, e);
        };
        this.showTicketOnPlan = (_e) => {
            const { ticket } = this.state;
            let zoomLevel = 3;
            try {
                if (RouterControl.instance.currentUrl.search != null) {
                    const query = { ...querystring.parse(RouterControl.instance.currentUrl.search) };
                    if (query != null && query['zTo'] != null) {
                        const s = query['zTo'].toString().split('x');
                        if (s.length >= 3) {
                            const z = Number.parseFloat(s[2]);
                            if (z > zoomLevel)
                                zoomLevel = z;
                        }
                    }
                }
            }
            catch (e) {
                console.debug('cant get query search', e);
            }
            Routing.instance.changeQueryParameter({ zTo: `${ticket.planPositionX}x${ticket.planPositionY}x${zoomLevel.toString()}x${ticket.id}` }, true);
        };
        this.iconSize = 20;
        this.paddingSize = ThemeManager.style.getScreenRelativePixelSize(4);
        this.state = {
            backgroundColor: this.getBackgroundColor(),
            assignedToUser: null,
            approvedByUser: null,
            project: props.project != null && props.project.id === props.ticket.id ? props.project : null,
            ticket: props.ticket,
        };
    }
    getBackgroundColor() {
        const inUrl = RouterControl.instance.currentUrl.search.includes(this.props.ticket.id);
        return inUrl ? color(ThemeManager.style.brandPrimary).alpha(0.16).toString() : '#FFFFFF';
    }
    componentDidMount() {
        this.mounted = true;
        RouterControl.instance.urlChanged.attach(this.onUrlChange);
        this.init(this.state.ticket).catch((err) => console.debug(err));
        CurrentProject.ticketChanged.attach(this.ticketChanged);
    }
    componentWillUnmount() {
        this.mounted = false;
        RouterControl.instance.urlChanged.detach(this.onUrlChange);
        CurrentProject.ticketChanged.detach(this.ticketChanged);
    }
    async init(ticket) {
        let project = null;
        try {
            project = await UpmeshClient.instance.modals.project.getById(ticket.projectId);
        }
        catch (e) {
            console.debug('cant find project', e);
        }
        if (this.mounted) {
            this.setState({ assignedToUser: ticket.assigneeUser, approvedByUser: ticket.approverUser, project });
        }
    }
    renderAssignedToIcon() {
        const { assignedToUser, approvedByUser, ticket, project } = this.state;
        let appoverField = true;
        let assigneeField = true;
        if (project != null) {
            const l = ChangeTicketCustomField.getTicketLayout(ticket, project);
            appoverField = l.fields.find((a) => a.systemField === 'approver') != null;
            assigneeField = l.fields.find((a) => a.systemField === 'assignee') != null;
            if (!appoverField && !assigneeField) {
                return null;
            }
        }
        if (!assigneeField &&
            (ticket.ticketStatus === TicketStatus.open || ticket.ticketStatus === TicketStatus.processing))
            return null;
        if (!appoverField && (ticket.ticketStatus === TicketStatus.checked || ticket.ticketStatus === TicketStatus.closed))
            return null;
        const shownUser = ticket.ticketStatus === TicketStatus.open || ticket.ticketStatus === TicketStatus.processing
            ? assignedToUser
            : approvedByUser;
        if (shownUser == null) {
            return (<View style={{
                    borderColor: ThemeManager.style.borderColor,
                    borderStyle: 'dashed',
                    borderWidth: 1,
                    borderRadius: 12,
                    flexDirection: 'row',
                    margin: this.paddingSize,
                    marginRight: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 24,
                    width: 24,
                }}>
          <Icon toolTip="" icon="help" iconSize={20} outerSize={24} radius={0}/>
        </View>);
        }
        return (<View style={{
                flexDirection: 'row',
                marginLeft: this.paddingSize,
                borderRadius: 12,
                height: 24,
                width: 24,
                overflow: 'hidden',
            }}>
        <UserImage size={24} user={shownUser}/>
      </View>);
    }
    renderCompletionIcon() {
        const { ticket } = this.state;
        if (ticket.completionOn == null) {
            return null;
        }
        const completionStatus = TicketEntitySynced.getStatusForCompletionOn(ticket.ticketStatus, ticket.completionOn);
        const completionColor = completionStatus === 'overdue' ? ThemeManager.style.brandDanger : ThemeManager.style.defaultTextColor;
        const completionBGColor = completionStatus === 'overdue'
            ? color(ThemeManager.style.brandDanger).alpha(0.12).toString()
            : ThemeManager.style.brandLight;
        const dateString = `${I18n.m.dateCurrent.localeDateString(ticket.completionOn)}\u200B`;
        return (<View style={{
                flexDirection: 'row',
                margin: this.paddingSize,
                marginRight: 0,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: completionBGColor,
                paddingLeft: 4,
                paddingRight: 4,
                height: 24,
                borderRadius: ThemeManager.style.borderRadius,
            }}>
        <MaterialText color={completionColor} numberOfLines={1} centeredBox centeredText type={MaterialTextTypes.Caption}>
          {dateString}
        </MaterialText>
      </View>);
    }
    renderCommentsIcon() {
        const { ticket } = this.state;
        if (ticket.commentCounter === 0) {
            return null;
        }
        return this.renderIcon(<Icon radius={0} toolTip="" icon="message-text" iconSize={this.iconSize} outerSize={this.iconSize} color={ThemeManager.style.defaultIconColor}/>, ticket.commentCounter.toString());
    }
    renderSubTicketFromIcon() {
        const { ticket } = this.state;
        if (ticket.subTicketFrom == null || ticket.subTicketFrom.length === 0) {
            return null;
        }
        return this.renderIcon(<Icon radius={0} toolTip="" icon="arrow-left-top" iconSize={this.iconSize} outerSize={this.iconSize} color={ThemeManager.style.defaultIconColor}/>);
    }
    renderSubTicketsIcon() {
        const { ticket } = this.state;
        if (ticket.subTicketsCounter === 0) {
            return null;
        }
        return this.renderIcon(<Icon radius={0} toolTip="" icon="arrow-right-bottom" iconSize={this.iconSize} outerSize={this.iconSize} color={ThemeManager.style.defaultIconColor}/>, ticket.subTicketsCounter.toString());
    }
    renderFilesIcon() {
        const { ticket } = this.state;
        if (ticket.fileCounter === 0) {
            return null;
        }
        return this.renderIcon(<Icon radius={0} toolTip="" icon="paperclip" iconSize={this.iconSize} outerSize={this.iconSize} color={ThemeManager.style.defaultIconColor}/>, ticket.fileCounter.toString());
    }
    renderTicketNumberIcon() {
        const { ticket } = this.state;
        if (ticket.ticketNumber == null || ticket.ticketNumber === 0) {
            return null;
        }
        return (<View style={{
                height: 24,
                borderRadius: ThemeManager.style.borderRadius,
                margin: this.paddingSize,
                marginRight: 0,
                paddingLeft: 4,
                paddingRight: 4,
                justifyContent: 'center',
                backgroundColor: ThemeManager.style.brandLight,
            }}>
        <MaterialText type={MaterialTextTypes.Caption}>{`#${ticket.ticketNumber}`}</MaterialText>
      </View>);
    }
    renderProjectIcon() {
        const { showProjects } = this.props;
        const { project } = this.state;
        if (showProjects !== true || project == null)
            return null;
        return (<View style={{ paddingLeft: 4 }}>
        <ProjectImage project={project} size={24}/>
      </View>);
    }
    renderBullseyeIcon() {
        const { bullseye } = this.props;
        if (bullseye !== true) {
            return null;
        }
        return (<View style={{
                position: 'absolute',
                right: this.paddingSize,
                bottom: this.paddingSize,
                flexDirection: 'row',
                margin: this.paddingSize,
                marginRight: 0,
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
            }}>
        <Icon toolTip="" icon="bullseye" onPress={this.showTicketOnPlan} iconSize={this.iconSize} color={ThemeManager.style.defaultIconColor}/>
      </View>);
    }
    renderIcon(icon, text) {
        let textElement = null;
        if (text != null) {
            textElement = (<MaterialText centeredBox centeredText type={MaterialTextTypes.Caption}>
          {text}
        </MaterialText>);
        }
        return (<View style={{
                flexDirection: 'row',
                margin: this.paddingSize,
                marginRight: 0,
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
            }}>
        {icon}
        {textElement}
      </View>);
    }
    renderStatus() {
        const { status, ticket } = this.props;
        if (!status) {
            return null;
        }
        let title = '?';
        switch (ticket.ticketStatus) {
            case TicketStatus.open:
                title = 'ticketsDetailsStateopen';
                break;
            case TicketStatus.processing:
                title = 'ticketsDetailsStateprocessing';
                break;
            case TicketStatus.closed:
                title = 'ticketsDetailsStateclosed';
                break;
            case TicketStatus.noStatus:
                title = 'ticketsDetailsStateNoStatus';
                break;
            case TicketStatus.checked:
                title = 'ticketsDetailsStatechecked';
                break;
            default:
                title = '?';
        }
        return (<View style={{
                height: 24,
                borderRadius: 12,
                margin: this.paddingSize,
                marginRight: 0,
                paddingLeft: 8,
                paddingRight: 8,
                backgroundColor: TicketStatusColor.getColorForStatus(ticket.ticketStatus),
                justifyContent: 'center',
                borderColor: ticket.ticketStatus === TicketStatus.noStatus ? 'rgb(114, 114, 114)' : undefined,
                borderWidth: ticket.ticketStatus === TicketStatus.noStatus ? 0.5 : undefined,
            }}>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage(title)}</MaterialText>
      </View>);
    }
    renderIcons() {
        const { bullseye } = this.props;
        return (<View style={{
                paddingRight: bullseye === true ? ThemeManager.style.getScreenRelativePixelSize(30) : 0,
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignContent: 'flex-end',
                alignItems: 'center',
                justifyContent: 'flex-end',
                alignSelf: 'flex-end',
                height: 'auto',
                minHeight: 36,
            }}>
        {this.renderAssignedToIcon()}
        {this.renderSubTicketFromIcon()}
        {this.renderSubTicketsIcon()}
        {this.renderStatus()}
        {this.renderCompletionIcon()}
        {this.renderCommentsIcon()}
        {this.renderFilesIcon()}
        {this.renderProjectIcon()}
        {this.renderTicketNumberIcon()}
      </View>);
    }
    render() {
        const { fixedHeight, bullseye, onMultiSelect, activeMultiselect, selected, onPress, onPressIn, onLongPress } = this.props;
        const { ticket } = this.state;
        let ticketTitle = ticket.title;
        if (ticket.archived && ticket.deleted) {
            ticketTitle = `${ticket.title} (${I18n.m.getMessage('archived')}, ${I18n.m.getMessage('deleted')})`;
        }
        else if (ticket.archived) {
            ticketTitle = `${ticket.title} (${I18n.m.getMessage('archived')})`;
        }
        else if (ticket.deleted) {
            ticketTitle = `${ticket.title} (${I18n.m.getMessage('deleted')})`;
        }
        const completionStatus = TicketEntitySynced.getStatusForCompletionOn(ticket.ticketStatus, ticket.completionOn);
        const transparentColor = Platform.OS === 'android' ? '#FFFFFF01' : 'transparent';
        const borderColor = selected ? ThemeManager.style.brandPrimary : transparentColor;
        return (<View pointerEvents="box-none" style={{
                backgroundColor: selected
                    ? color(ThemeManager.style.brandPrimary).alpha(0.16).toString()
                    : this.state.backgroundColor,
                width: '100%',
                paddingRight: bullseye ? 0 : 4,
                borderStyle: 'solid',
                margin: 0,
                borderWidth: 1,
                borderTopColor: borderColor,
                borderBottomColor: borderColor,
                borderRightColor: borderColor,
                borderLeftWidth: completionStatus === 'overdue' ? 4 : 1,
                borderLeftColor: completionStatus === 'overdue' ? ThemeManager.style.brandDanger : borderColor,
                borderRadius: 6,
            }}>
        <Ripple onPress={onPress || (activeMultiselect && onMultiSelect ? () => onMultiSelect(ticket) : this.openTicket)} onLongPress={onLongPress || onMultiSelect} onPressIn={onPressIn} style={{
                width: '100%',
                padding: this.paddingSize,
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                alignItems: 'center',
                height: fixedHeight != null ? fixedHeight : 'auto',
            }}>
          <View style={{ height: 'auto', maxWidth: '100%', alignSelf: 'center', flexGrow: 1 }}>
            <MaterialText numberOfLines={fixedHeight != null && fixedHeight > 0 ? 1 : undefined} type={MaterialTextTypes.Body1} ellipsizeMode="tail">
              <MaterialText numberOfLines={fixedHeight != null && fixedHeight > 0 ? 1 : undefined} type={MaterialTextTypes.Body1} ellipsizeMode="tail">
                {ticketTitle}
              </MaterialText>
            </MaterialText>
          </View>
          <View style={{ height: 'auto', maxWidth: '100%', alignSelf: 'center', flexGrow: 1 }}>
            {this.renderIcons()}
          </View>
        </Ripple>
        {this.renderBullseyeIcon()}
      </View>);
    }
}
