var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TuiPhotoEditor = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _tuiImageEditor = _interopRequireDefault(require("tui-image-editor"));
require("../../../assets/tui/tuiwebstyle.css");
var _I18n = require("../../i18n/I18n");
var _ResizeEvent = require("../../ResizeEvent");
var _LoadingEvents = require("../../routing/LoadingEvents");
var _ThemeManager = require("../../ThemeManager");
var _Dialog = require("../Dialog");
var _Icon = require("../Icon");
var _CacheableImage = require("../images/CacheableImage");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var TuiPhotoEditor = exports.TuiPhotoEditor = function (_PureComponent) {
  function TuiPhotoEditor(props) {
    var _this;
    (0, _classCallCheck2.default)(this, TuiPhotoEditor);
    _this = _callSuper(this, TuiPhotoEditor, [props]);
    _this.rootElement = _react.default.createRef();
    _this.imageEditor = null;
    _this.closeDialog = function (alreadyAccepted) {
      return function (_e) {
        var _this$props = _this.props,
          saveFunction = _this$props.saveFunction,
          imageDataUrl = _this$props.imageDataUrl,
          orgFilename = _this$props.orgFilename;
        if (saveFunction != null) {
          try {
            if (TuiPhotoEditor.wasEdited()) _LoadingEvents.LoadingEvents.instance.startLoading();
            var dataURL = TuiPhotoEditor.instance != null && TuiPhotoEditor.wasEdited() ? TuiPhotoEditor.getEditedImageData(orgFilename) : undefined;
            if (dataURL != null) {
              var _Dialog$instance;
              var byteString = window.atob(dataURL.split(',')[1]);
              var mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
              var ab = new ArrayBuffer(byteString.length);
              var ia = new Uint8Array(ab);
              for (var i = 0; i < byteString.length; i += 1) {
                ia[i] = byteString.charCodeAt(i);
              }
              var blob = new Blob([ab], {
                type: mimeString
              });
              var fileData = new File([blob], orgFilename, {
                type: 'image/jpeg',
                lastModified: new Date().getTime()
              });
              var tmp = _CacheableImage.CacheableImage.webcache;
              for (var k in tmp) {
                if (k.includes(imageDataUrl)) {
                  tmp[k] = dataURL;
                }
              }
              _CacheableImage.CacheableImage.webcache = tmp;
              _CacheableImage.CacheableImage.webcache[imageDataUrl] = dataURL;
              (_Dialog$instance = _Dialog.Dialog.instance) == null || _Dialog$instance.close(function () {
                saveFunction(fileData, alreadyAccepted).catch(function (err) {
                  return void 0;
                });
              });
            } else {
              var _Dialog$instance2;
              (_Dialog$instance2 = _Dialog.Dialog.instance) == null || _Dialog$instance2.close(function () {
                saveFunction().catch(function (err) {
                  return void 0;
                });
              });
            }
          } catch (err) {
            var _Dialog$instance3;
            (_Dialog$instance3 = _Dialog.Dialog.instance) == null || _Dialog$instance3.close();
          }
        }
        _LoadingEvents.LoadingEvents.instance.stopLoading();
      };
    };
    _this.onLayout = function (e) {
      var s = {
        width: e.nativeEvent.layout.width,
        height: e.nativeEvent.layout.height
      };
      if (JSON.stringify(s) !== JSON.stringify(_this.state.size)) {
        _this.setState({
          size: s
        }, function () {
          var _this$imageEditor;
          (_this$imageEditor = _this.imageEditor) == null || _this$imageEditor.resizeCanvasDimension(Object.assign({}, s)).catch(function (err) {
            return void 0;
          });
        });
      }
    };
    _this.state = {
      size: props.size ? props.size : {
        width: _ResizeEvent.ResizeEvent.current.windowWidth,
        height: _ResizeEvent.ResizeEvent.current.windowHeight
      }
    };
    return _this;
  }
  (0, _inherits2.default)(TuiPhotoEditor, _PureComponent);
  return (0, _createClass2.default)(TuiPhotoEditor, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this$props2 = this.props,
        imageDataUrl = _this$props2.imageDataUrl,
        crop = _this$props2.crop,
        flip = _this$props2.flip,
        rotate = _this$props2.rotate,
        draw = _this$props2.draw,
        shape = _this$props2.shape,
        icon = _this$props2.icon,
        text = _this$props2.text,
        mask = _this$props2.mask,
        filter = _this$props2.filter;
      var menuArray = [];
      if (crop) menuArray.push('crop');
      if (flip) menuArray.push('flip');
      if (rotate) menuArray.push('rotate');
      if (draw) menuArray.push('draw');
      if (shape) menuArray.push('shape');
      if (icon) menuArray.push('icon');
      if (text) menuArray.push('text');
      if (mask) menuArray.push('mask');
      if (filter) menuArray.push('filter');
      var locale_not_en = {};
      if (_I18n.I18n.m.currentLanguage !== 'en') {
        locale_not_en = {
          Apply: _I18n.I18n.m.getMessage('photoEditor_Apply'),
          Arrow: _I18n.I18n.m.getMessage('photoEditor_Arrow'),
          'Arrow-2': _I18n.I18n.m.getMessage('photoEditor_Arrow_2'),
          'Arrow-3': _I18n.I18n.m.getMessage('photoEditor_Arrow_3'),
          Blend: _I18n.I18n.m.getMessage('photoEditor_Blend'),
          Blur: _I18n.I18n.m.getMessage('photoEditor_Blur'),
          Bold: _I18n.I18n.m.getMessage('photoEditor_Bold'),
          Brightness: _I18n.I18n.m.getMessage('photoEditor_Brightness'),
          Bubble: _I18n.I18n.m.getMessage('photoEditor_Bubble'),
          Cancel: _I18n.I18n.m.getMessage('photoEditor_Cancel'),
          Center: _I18n.I18n.m.getMessage('photoEditor_Center'),
          Circle: _I18n.I18n.m.getMessage('photoEditor_Circle'),
          Color: _I18n.I18n.m.getMessage('photoEditor_Color'),
          'Color Filter': _I18n.I18n.m.getMessage('photoEditor_Color_Filter'),
          Crop: _I18n.I18n.m.getMessage('photoEditor_Crop'),
          Custom: _I18n.I18n.m.getMessage('photoEditor_Custom'),
          'Custom icon': _I18n.I18n.m.getMessage('photoEditor_Custom_icon'),
          Delete: _I18n.I18n.m.getMessage('photoEditor_Delete'),
          'Delete-all': _I18n.I18n.m.getMessage('photoEditor_Delete_all'),
          Distance: _I18n.I18n.m.getMessage('photoEditor_Distance'),
          Download: _I18n.I18n.m.getMessage('photoEditor_Download'),
          Draw: _I18n.I18n.m.getMessage('photoEditor_Draw'),
          Emboss: _I18n.I18n.m.getMessage('photoEditor_Emboss'),
          Fill: _I18n.I18n.m.getMessage('photoEditor_Fill'),
          Filter: _I18n.I18n.m.getMessage('photoEditor_Filter'),
          Flip: _I18n.I18n.m.getMessage('photoEditor_Flip'),
          'Flip X': _I18n.I18n.m.getMessage('photoEditor_Flip_X'),
          'Flip Y': _I18n.I18n.m.getMessage('photoEditor_Flip_Y'),
          Free: _I18n.I18n.m.getMessage('photoEditor_Free'),
          Grayscale: _I18n.I18n.m.getMessage('photoEditor_Grayscale'),
          Heart: _I18n.I18n.m.getMessage('photoEditor_Heart'),
          Icon: _I18n.I18n.m.getMessage('photoEditor_Icon'),
          Invert: _I18n.I18n.m.getMessage('photoEditor_Invert'),
          Italic: _I18n.I18n.m.getMessage('photoEditor_Italic'),
          Left: _I18n.I18n.m.getMessage('photoEditor_Left'),
          Load: _I18n.I18n.m.getMessage('photoEditor_Load'),
          'Load Mask Image': _I18n.I18n.m.getMessage('photoEditor_Load_Mask_Image'),
          Location: _I18n.I18n.m.getMessage('photoEditor_Location'),
          Mask: _I18n.I18n.m.getMessage('photoEditor_Mask'),
          Multiply: _I18n.I18n.m.getMessage('photoEditor_Multiply'),
          Noise: _I18n.I18n.m.getMessage('photoEditor_Noise'),
          Pixelate: _I18n.I18n.m.getMessage('photoEditor_Pixelate'),
          Polygon: _I18n.I18n.m.getMessage('photoEditor_Polygon'),
          Range: _I18n.I18n.m.getMessage('photoEditor_Range'),
          Rectangle: _I18n.I18n.m.getMessage('photoEditor_Rectangle'),
          Redo: _I18n.I18n.m.getMessage('photoEditor_Redo'),
          'Remove White': _I18n.I18n.m.getMessage('photoEditor_Remove_White'),
          Reset: _I18n.I18n.m.getMessage('photoEditor_Reset'),
          Right: _I18n.I18n.m.getMessage('photoEditor_Right'),
          Rotate: _I18n.I18n.m.getMessage('photoEditor_Rotate'),
          Sepia: _I18n.I18n.m.getMessage('photoEditor_Sepia'),
          Sepia2: _I18n.I18n.m.getMessage('photoEditor_Sepia2'),
          Shape: _I18n.I18n.m.getMessage('photoEditor_Shape'),
          Sharpen: _I18n.I18n.m.getMessage('photoEditor_Sharpen'),
          Square: _I18n.I18n.m.getMessage('photoEditor_Square'),
          'Star-1': _I18n.I18n.m.getMessage('photoEditor_Star_1'),
          'Star-2': _I18n.I18n.m.getMessage('photoEditor_Star_2'),
          Straight: _I18n.I18n.m.getMessage('photoEditor_Straight'),
          Stroke: _I18n.I18n.m.getMessage('photoEditor_Stroke'),
          Text: _I18n.I18n.m.getMessage('photoEditor_Text'),
          'Text size': _I18n.I18n.m.getMessage('photoEditor_Text_size'),
          Threshold: _I18n.I18n.m.getMessage('photoEditor_Threshold'),
          Tint: _I18n.I18n.m.getMessage('photoEditor_Tint'),
          Triangle: _I18n.I18n.m.getMessage('photoEditor_Triangle'),
          Underline: _I18n.I18n.m.getMessage('photoEditor_Underline'),
          Undo: _I18n.I18n.m.getMessage('photoEditor_Undo'),
          Value: _I18n.I18n.m.getMessage('photoEditor_Value')
        };
      }
      var options = {
        includeUI: {
          loadImage: {
            path: imageDataUrl,
            name: 'sampleImage'
          },
          menu: menuArray,
          initMenu: 'draw',
          uiSize: {
            width: `${_ResizeEvent.ResizeEvent.current.windowWidth}px`,
            height: `${_ResizeEvent.ResizeEvent.current.windowHeight}px`
          },
          locale: locale_not_en,
          menuBarPosition: 'bottom'
        },
        usageStatistics: false,
        cssMaxWidth: _ResizeEvent.ResizeEvent.current.windowWidth,
        cssMaxHeight: _ResizeEvent.ResizeEvent.current.windowHeight - 290,
        selectionStyle: {
          cornerSize: 20,
          rotatingPointOffset: 70
        }
      };
      this.imageEditor = new _tuiImageEditor.default(this.rootElement.current, Object.assign({}, options));
      TuiPhotoEditor.instance = this;
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.imageEditor != null) {
        this.imageEditor.destroy();
      }
      this.imageEditor = null;
    }
  }, {
    key: "renderBackButton",
    value: function renderBackButton() {
      return [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          top: 16,
          left: 8,
          height: 48,
          width: 48,
          zIndex: 1001,
          elevation: 1001,
          justifyContent: 'space-between',
          flexDirection: 'row'
        },
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "arrow-left",
          toolTip: _I18n.I18n.m.getMessage('close'),
          iconSize: 24,
          outerSize: 32,
          backgroundColor: _ThemeManager.ThemeManager.style.black38,
          color: "#FFFFFF",
          onPress: this.closeDialog(false)
        })
      }, "BackButton"), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          top: 16,
          right: 8,
          height: 48,
          width: 32,
          zIndex: 1001,
          elevation: 1001,
          justifyContent: 'flex-end',
          flexDirection: 'row'
        },
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "check",
          toolTip: _I18n.I18n.m.getMessage('save'),
          iconSize: 24,
          outerSize: 32,
          backgroundColor: _ThemeManager.ThemeManager.style.black38,
          color: "#FFFFFF",
          onPress: this.closeDialog(true)
        })
      }, "SaveButton")];
    }
  }, {
    key: "render",
    value: function render() {
      var size = this.state.size;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        onLayout: this.onLayout,
        style: {
          width: '100%',
          height: '100%',
          position: 'relative'
        },
        children: [this.renderBackButton(), (0, _jsxRuntime.jsx)("div", {
          ref: this.rootElement,
          style: Object.assign({}, size)
        })]
      });
    }
  }], [{
    key: "openImageEditor",
    value: function openImageEditor(image, originalfilename, saveFunction) {
      var _Dialog$instance4;
      (_Dialog$instance4 = _Dialog.Dialog.instance) == null || _Dialog$instance4.open({
        fullscreen: true,
        overlayBackgroundColor: '#000000',
        contentPadding: false,
        openImmediately: true,
        width: _ResizeEvent.ResizeEvent.current.windowWidth,
        height: _ResizeEvent.ResizeEvent.current.windowHeight,
        content: (0, _jsxRuntime.jsx)(TuiPhotoEditor, {
          orgFilename: originalfilename,
          saveFunction: saveFunction,
          imageDataUrl: image,
          draw: true,
          text: true,
          crop: true,
          shape: true,
          rotate: true
        })
      }, true);
    }
  }, {
    key: "getEditedImageData",
    value: function getEditedImageData(orgFilename) {
      if (TuiPhotoEditor.instance != null) {
        var _TuiPhotoEditor$insta;
        var ext = `${orgFilename.substr(orgFilename.lastIndexOf('.'))}`;
        var options = {
          format: ext.toLowerCase() === '.png' ? 'png' : 'jpeg'
        };
        var dataURL = (_TuiPhotoEditor$insta = TuiPhotoEditor.instance.imageEditor) == null ? void 0 : _TuiPhotoEditor$insta.toDataURL(options);
        if (dataURL != null && dataURL.length > 0) {
          return dataURL;
        }
      }
      return '';
    }
  }, {
    key: "setImageData",
    value: function setImageData(imageData) {
      var _this2 = this;
      var imageTitle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'hi';
      var title = imageTitle != null ? imageTitle : '';
      if (TuiPhotoEditor.instance.imageEditor != null) {
        TuiPhotoEditor.instance.imageEditor.loadImageFromURL(imageData, title).then(function () {
          var _this2$instance$image;
          (_this2$instance$image = _this2.instance.imageEditor) == null || _this2$instance$image.ui.activeMenuEvent();
        }).catch(function (error) {});
      }
    }
  }, {
    key: "wasEdited",
    value: function wasEdited() {
      if (TuiPhotoEditor.instance.imageEditor != null) {
        return !TuiPhotoEditor.instance.imageEditor.isEmptyUndoStack();
      }
      return false;
    }
  }, {
    key: "getInstance",
    value: function getInstance() {
      return TuiPhotoEditor.instance;
    }
  }]);
}(_react.PureComponent);