"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PDFOverlay = void 0;
var pdf_lib_1 = require("pdf-lib");
var QRCodeA = require("qrcode");
var matter_js_1 = require("matter-js");
var TicketEntity_1 = require("../client/query/entities/TicketEntity");
var PDFOverlay = function () {
  function PDFOverlay(fileAsBuffer, planVersion) {
    (0, _classCallCheck2.default)(this, PDFOverlay);
    this.filesLoaded = false;
    this.imageToPdfScale = 2.833333;
    this.MAX_MARKER_SIZE = 25;
    this.MIN_MARKER_SIZE = 5;
    this.generateQRCode = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (url) {
        try {
          return yield QRCodeA.toString(url);
        } catch (error) {
          throw error;
        }
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    this.fileAsBuffer = fileAsBuffer;
    this.planVersion = planVersion;
  }
  return (0, _createClass2.default)(PDFOverlay, [{
    key: "loadFiles",
    value: function () {
      var _loadFiles = (0, _asyncToGenerator2.default)(function* () {
        if (this.planVersion.orgFilename.endsWith('.pdf')) {
          this.basePdfDoc = yield pdf_lib_1.PDFDocument.load(this.fileAsBuffer);
        } else {
          this.basePdfDoc = yield pdf_lib_1.PDFDocument.create();
          var page = this.basePdfDoc.addPage([this.planVersion.pageWidth, this.planVersion.pageHeight]);
          var image = yield this.basePdfDoc.embedJpg(this.fileAsBuffer);
          page.drawImage(image, {
            x: 0,
            y: 0,
            height: this.planVersion.pageHeight,
            width: this.planVersion.pageWidth
          });
        }
        this.existingPages = yield this.basePdfDoc.getPages();
        this.filesLoaded = true;
        this.font = yield this.basePdfDoc.embedFont(pdf_lib_1.StandardFonts.Helvetica);
      });
      function loadFiles() {
        return _loadFiles.apply(this, arguments);
      }
      return loadFiles;
    }()
  }, {
    key: "calculateTriangleCorners",
    value: function calculateTriangleCorners(a, b, baseLength) {
      var dir = {
        x: b.x - a.x,
        y: b.y - a.y
      };
      var perp = {
        x: -dir.y,
        y: dir.x
      };
      var length = Math.sqrt(perp.x ** 2 + perp.y ** 2);
      var perpNorm = {
        x: perp.x / length * (baseLength / 2),
        y: perp.y / length * (baseLength / 2)
      };
      var c = {
        x: b.x + perpNorm.x,
        y: b.y + perpNorm.y
      };
      var d = {
        x: b.x - perpNorm.x,
        y: b.y - perpNorm.y
      };
      return [c, a, d];
    }
  }, {
    key: "addTickets",
    value: function () {
      var _addTickets = (0, _asyncToGenerator2.default)(function* (tickets) {
        if (!this.filesLoaded) yield this.loadFiles();
        var firstPage = this.existingPages[0];
        var area = firstPage.getSize().height * firstPage.getSize().height;
        var areaTickets = area * 0.05;
        var calcedScale = Math.min(Math.max(areaTickets / tickets.length, this.MIN_MARKER_SIZE), this.MAX_MARKER_SIZE);
        var engine = matter_js_1.Engine.create();
        engine.gravity.x = 0;
        engine.gravity.y = 0;
        var placementCircles = [];
        var ticketCoordinates = [];
        var placedBalls = 0;
        var furthestDistance = 0;
        for (var ticket of tickets) {
          var onPlanCoordinates = this.getCoordinates(ticket);
          var distance = Math.sqrt(onPlanCoordinates.x ** 2 + onPlanCoordinates.y ** 2);
          if (distance > furthestDistance) {
            furthestDistance = distance;
          }
          ticketCoordinates.push(onPlanCoordinates);
        }
        for (var _ticket of tickets) {
          var _onPlanCoordinates = this.getCoordinates(_ticket);
          var vec = {
            x: _onPlanCoordinates.x,
            y: _onPlanCoordinates.y
          };
          var _distance = Math.sqrt(vec.x ** 2 + vec.y ** 2);
          vec.x = vec.x / _distance * furthestDistance * (calcedScale * placedBalls);
          vec.y = vec.y / _distance * furthestDistance * (calcedScale * placedBalls);
          placementCircles.push(matter_js_1.Bodies.circle(vec.x, vec.y, calcedScale, {
            restitution: 0.3
          }));
        }
        var worldIterations = 1;
        var pdfSizeFactor = Math.sqrt(firstPage.getWidth() ** 2 + firstPage.getHeight() ** 2) / 1000;
        var customGravityFunction = function customGravityFunction(circle, target) {
          var forceMagnitude = 9 / worldIterations * pdfSizeFactor;
          var vector = {
            x: target.x - circle.position.x,
            y: target.y - circle.position.y
          };
          var distance = Math.sqrt(vector.x ** 2 + vector.y ** 2);
          if (distance != null || Number.isNaN(distance)) {
            var normalized = {
              x: vector.x / distance,
              y: vector.y / distance
            };
            var force = {
              x: normalized.x * forceMagnitude,
              y: normalized.y * forceMagnitude
            };
            if (worldIterations > 90) {
              var resultVec = {
                x: 0,
                y: 0
              };
              for (var cTicket of ticketCoordinates) {
                var pushV = {
                  x: cTicket.x - circle.position.x,
                  y: cTicket.y - circle.position.y
                };
                var tDis = Math.sqrt(pushV.x ** 2 + pushV.y ** 2);
                if (tDis < 50) {
                  resultVec.x += pushV.x * (1 / tDis) * -1;
                  resultVec.y += pushV.y * (1 / tDis) * -1;
                }
              }
              var pushForce = 0.12;
              force.x += resultVec.x * pushForce;
              force.y += resultVec.y * pushForce;
            }
            matter_js_1.Body.setVelocity(circle, {
              x: 0,
              y: 0
            });
            matter_js_1.Body.applyForce(circle, {
              x: circle.position.x,
              y: circle.position.y
            }, force);
          } else {
            circle.position.x = circle.positionPrev.x;
            circle.position.y = circle.positionPrev.y;
            matter_js_1.Body.setVelocity(circle, {
              x: 0.001,
              y: 0.001
            });
          }
        };
        matter_js_1.World.add(engine.world, placementCircles);
        while (worldIterations < 100) {
          matter_js_1.Engine.update(engine, 17);
          worldIterations += 1;
          for (var i = 0; i < placementCircles.length; i += 1) {
            var cCircle = placementCircles[i];
            var cTicket = ticketCoordinates[i];
            customGravityFunction(cCircle, cTicket);
          }
        }
        for (var _i = 0; _i < placementCircles.length; _i += 1) {
          var _cCircle = placementCircles[_i];
          var tCords = ticketCoordinates[_i];
          var _ticket2 = tickets[_i];
          firstPage.moveTo(tCords.x, tCords.y);
          var points = this.calculateTriangleCorners(tCords, _cCircle.position, 10);
          firstPage.drawSvgPath(`M 0 0 L ${points[0].x - points[1].x} ${points[1].y - points[0].y} L ${points[2].x - points[1].x} ${points[1].y - points[2].y} Z`, {
            color: this.getMarkerColor(_ticket2)
          });
        }
        for (var _i2 = 0; _i2 < placementCircles.length; _i2 += 1) {
          var _cCircle2 = placementCircles[_i2];
          var _ticket3 = tickets[_i2];
          var factor = 0.012;
          var rotation = firstPage.getRotation();
          if (rotation.angle === 0) {
            firstPage.moveTo(_cCircle2.position.x - PDFOverlay.MARKER_DIMENSIONS.width * 0.5 * factor * calcedScale, _cCircle2.position.y + PDFOverlay.MARKER_DIMENSIONS.height * 0.5 * factor * calcedScale);
          }
          if (rotation.angle === 90) {
            firstPage.moveTo(_cCircle2.position.x - PDFOverlay.MARKER_DIMENSIONS.height * 0.5 * factor * calcedScale, _cCircle2.position.y - PDFOverlay.MARKER_DIMENSIONS.width * 0.5 * factor * calcedScale);
          }
          if (rotation.angle === 180) {
            firstPage.moveTo(_cCircle2.position.x + PDFOverlay.MARKER_DIMENSIONS.width * 0.5 * factor * calcedScale, _cCircle2.position.y - PDFOverlay.MARKER_DIMENSIONS.height * 0.5 * factor * calcedScale);
          }
          if (rotation.angle === 270) {
            firstPage.moveTo(_cCircle2.position.x + PDFOverlay.MARKER_DIMENSIONS.height * 0.5 * factor * calcedScale, _cCircle2.position.y + PDFOverlay.MARKER_DIMENSIONS.width * 0.5 * factor * calcedScale);
          }
          for (var path of PDFOverlay.SVG_PATHS_BODY) {
            firstPage.drawSvgPath(path, {
              color: this.getMarkerColor(_ticket3),
              scale: calcedScale * factor,
              rotate: firstPage.getRotation()
            });
          }
          var ticketText = `# ${_ticket3.ticketNumber}`;
          var textSize = Math.floor(40 * calcedScale * factor);
          var textWidth = this.font.widthOfTextAtSize(ticketText, textSize);
          firstPage.moveTo(_cCircle2.position.x - textWidth * 0.5, _cCircle2.position.y);
          if (rotation.angle === 0) {
            firstPage.moveTo(_cCircle2.position.x - textWidth * 0.5, _cCircle2.position.y);
          }
          if (rotation.angle === 90) {
            firstPage.moveTo(_cCircle2.position.x, _cCircle2.position.y - textWidth * 0.5);
          }
          if (rotation.angle === 180) {
            firstPage.moveTo(_cCircle2.position.x + textWidth * 0.5, _cCircle2.position.y);
          }
          if (rotation.angle === 270) {
            firstPage.moveTo(_cCircle2.position.x, _cCircle2.position.y + textWidth * 0.5);
          }
          firstPage.drawText(ticketText, {
            size: textSize,
            rotate: rotation
          });
        }
      });
      function addTickets(_x2) {
        return _addTickets.apply(this, arguments);
      }
      return addTickets;
    }()
  }, {
    key: "getMarkerColor",
    value: function getMarkerColor(ticket) {
      if (ticket.ticketStatus === TicketEntity_1.TicketStatus.closed) return (0, pdf_lib_1.rgb)(0, 115 / 255, 255 / 255);
      if (ticket.ticketStatus === TicketEntity_1.TicketStatus.checked) return (0, pdf_lib_1.rgb)(0, 205 / 255, 158 / 255);
      if (ticket.ticketStatus === TicketEntity_1.TicketStatus.open) return (0, pdf_lib_1.rgb)(245 / 255, 124 / 255, 0);
      return (0, pdf_lib_1.rgb)(255 / 255, 213 / 255, 0);
    }
  }, {
    key: "getCoordinates",
    value: function getCoordinates(ticket) {
      var firstPage = this.existingPages[0];
      var result;
      if (this.planVersion.orgFilename.endsWith('.pdf')) {
        var _ticket$planPositionX, _ticket$planPositionY;
        result = this.planVersion.getPointInPageFromRealpointInCM({
          x: (_ticket$planPositionX = ticket.planPositionX) != null ? _ticket$planPositionX : 0,
          y: (_ticket$planPositionY = ticket.planPositionY) != null ? _ticket$planPositionY : 0
        });
      } else {
        var _ticket$planPositionX2, _ticket$planPositionY2;
        result = {
          x: ((_ticket$planPositionX2 = ticket.planPositionX) != null ? _ticket$planPositionX2 : 0) * this.imageToPdfScale,
          y: ((_ticket$planPositionY2 = ticket.planPositionY) != null ? _ticket$planPositionY2 : 0) * this.imageToPdfScale
        };
      }
      var _result = result,
        x = _result.x;
      var _result2 = result,
        y = _result2.y;
      if (firstPage.getRotation().angle === 90) {
        result.x = firstPage.getWidth() - y;
        result.y = x;
      }
      if (firstPage.getRotation().angle === 180) {
        result.x = firstPage.getWidth() - x;
        result.y = firstPage.getHeight() - y;
      }
      if (firstPage.getRotation().angle === 270) {
        result.x = y;
        result.y = firstPage.getHeight() - x;
      }
      return result;
    }
  }, {
    key: "addQrCode",
    value: function () {
      var _addQrCode = (0, _asyncToGenerator2.default)(function* (project, planVersion, config) {
        var _project$planQrCodeHo, _project$planQrCodeVe, _project$planQrCodeLa;
        if (!this.filesLoaded) yield this.loadFiles();
        var firstPage = this.existingPages[0];
        var pageSize = firstPage.getSize();
        var horizontalOffset = ((_project$planQrCodeHo = project.planQrCodeHorizontalOffset) != null ? _project$planQrCodeHo : 0) * 2.83465;
        var verticalOffset = ((_project$planQrCodeVe = project.planQrCodeVerticalOffset) != null ? _project$planQrCodeVe : 0) * 2.83465;
        var qrCodeSize = Math.min(pageSize.width, pageSize.height) * 0.05;
        var qrCodeX = 0;
        var qrCodeY = 0;
        if (project.planQrCodePosition === 'Top left') {
          qrCodeX = horizontalOffset;
          qrCodeY = pageSize.height - qrCodeSize - verticalOffset;
        } else if (project.planQrCodePosition === 'Top right') {
          qrCodeX = pageSize.width - qrCodeSize - horizontalOffset;
          qrCodeY = pageSize.height - qrCodeSize - verticalOffset;
        } else if (project.planQrCodePosition === 'Bottom left') {
          qrCodeX = horizontalOffset;
          qrCodeY = verticalOffset;
        } else if (project.planQrCodePosition === 'Bottom right') {
          qrCodeX = pageSize.width - qrCodeSize - horizontalOffset;
          qrCodeY = verticalOffset;
        } else {
          throw new Error(`Invalid QR code position: ${project.planQrCodePosition}`);
        }
        var qrCodeImage = yield this.generateQRCode(`${config}/public/${planVersion.id}/status`);
        var svgPathValues = this.extractPathDValues(qrCodeImage);
        var svgQRCodeWidth = svgPathValues.size;
        var newScale = qrCodeSize / svgQRCodeWidth;
        var realSize = svgQRCodeWidth * newScale;
        firstPage.drawSvgPath(svgPathValues.paths[0], {
          x: qrCodeX,
          y: qrCodeY + realSize,
          scale: newScale,
          borderWidth: 1,
          color: (0, pdf_lib_1.rgb)(1, 1, 1)
        });
        firstPage.drawSvgPath(svgPathValues.paths[1], {
          x: qrCodeX,
          y: qrCodeY + realSize,
          scale: newScale,
          borderWidth: 1,
          color: (0, pdf_lib_1.rgb)(245 / 255, 124 / 255, 0)
        });
        var label = (_project$planQrCodeLa = project == null ? void 0 : project.planQrCodeLabel) != null ? _project$planQrCodeLa : '';
        if (label !== '') {
          var labelFontSize = 10;
          var font = yield this.basePdfDoc.embedFont(pdf_lib_1.StandardFonts.Helvetica);
          var labelX = 0;
          if (project.planQrCodePosition === 'Top right' || project.planQrCodePosition === 'Bottom right') {
            labelX = qrCodeX - 10 - font.widthOfTextAtSize(label, labelFontSize);
          } else if (project.planQrCodePosition === 'Top left' || project.planQrCodePosition === 'Bottom left') {
            labelX = qrCodeX + qrCodeSize + 10;
          }
          var labelY = qrCodeY + qrCodeSize / 2 - labelFontSize / 2;
          firstPage.drawText(label, {
            x: labelX,
            y: labelY,
            size: labelFontSize,
            font: font,
            color: (0, pdf_lib_1.rgb)(0, 0, 0)
          });
        }
        var linkAnnotationDict = this.basePdfDoc.context.obj({
          Type: pdf_lib_1.PDFName.of('Annot'),
          Subtype: pdf_lib_1.PDFName.of('Link'),
          Rect: this.basePdfDoc.context.obj([pdf_lib_1.PDFNumber.of(qrCodeX), pdf_lib_1.PDFNumber.of(qrCodeY), pdf_lib_1.PDFNumber.of(qrCodeX + qrCodeSize), pdf_lib_1.PDFNumber.of(qrCodeY + qrCodeSize)]),
          Border: this.basePdfDoc.context.obj([0, 0, 0]),
          A: this.basePdfDoc.context.obj({
            Type: pdf_lib_1.PDFName.of('Action'),
            S: pdf_lib_1.PDFName.of('URI'),
            URI: pdf_lib_1.PDFString.of(`${config}/public/${planVersion.id}/status`)
          })
        });
        var annotations = firstPage.node.get(pdf_lib_1.PDFName.of('Annots'));
        if (annotations) {
          annotations.push(linkAnnotationDict);
        } else {
          firstPage.node.set(pdf_lib_1.PDFName.of('Annots'), this.basePdfDoc.context.obj([linkAnnotationDict]));
        }
      });
      function addQrCode(_x3, _x4, _x5) {
        return _addQrCode.apply(this, arguments);
      }
      return addQrCode;
    }()
  }, {
    key: "finalize",
    value: function () {
      var _finalize = (0, _asyncToGenerator2.default)(function* () {
        if (!this.filesLoaded) yield this.loadFiles();
        return yield this.basePdfDoc.save();
      });
      function finalize() {
        return _finalize.apply(this, arguments);
      }
      return finalize;
    }()
  }, {
    key: "finalizeToBase64",
    value: function () {
      var _finalizeToBase = (0, _asyncToGenerator2.default)(function* () {
        if (!this.filesLoaded) yield this.loadFiles();
        return yield this.basePdfDoc.saveAsBase64();
      });
      function finalizeToBase64() {
        return _finalizeToBase.apply(this, arguments);
      }
      return finalizeToBase64;
    }()
  }, {
    key: "extractSize",
    value: function extractSize(path) {
      var hIndex = path.indexOf('h');
      if (hIndex === -1) return 0;
      var start = hIndex + 1;
      var end = start;
      while (end < path.length && /[0-9]/.test(path[end])) {
        end += 1;
      }
      var numberStr = path.substring(start, end);
      return parseInt(numberStr, 10);
    }
  }, {
    key: "extractPathDValues",
    value: function extractPathDValues(svg) {
      var paths = [];
      var index = 0;
      while (index < svg.length) {
        var dStart = svg.indexOf('d="', index);
        if (dStart === -1) break;
        var valueStart = dStart + 3;
        var valueEnd = svg.indexOf('"', valueStart);
        if (valueEnd === -1) break;
        var dValue = svg.substring(valueStart, valueEnd);
        paths.push(dValue);
        index = valueEnd + 1;
      }
      return {
        paths: paths,
        size: this.extractSize(paths[0])
      };
    }
  }]);
}();
exports.PDFOverlay = PDFOverlay;
PDFOverlay.SVG_PATHS_BODY = ['M 0 37.5 A 37.5 37.5 0 0 1 75 37.5 A 37.5 37.5 0 0 1 0 37.5 Z', 'M 37.5 0 H 112.5 V 75 H 37.5 Z', 'M 75 37.5 A 37.5 37.5 0 0 1 150 37.5 A 37.5 37.5 0 0 1 75 37.5 Z'];
PDFOverlay.MARKER_DIMENSIONS = {
  width: 150,
  height: 100
};