import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Datepicker } from 'materialTheme/src/theme/components/datepickerv2/Datepicker';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { ContentHeaderEventHandler, } from 'materialTheme/src/theme/components/header/ContentHeaderEventHandler';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ChangeExtraPayTracking, } from 'upmesh-core/src/client/commands/companies/extrapay/ChangeExtraPayTracking';
import { ChangeTimeTracking, } from 'upmesh-core/src/client/commands/companies/timetracking/ChangeTimeTracking';
import { I18n } from '../../../i18n/I18n';
import { openPromisesBulkChangesDialog } from '../../root/PromisesBulkChangesDialog';
import { TimeTrackingBulkChangeDialogContent } from '../dialogs/TimeTrackingBulkChangeDialogContent';
export class TrackingMultiSelectControl {
}
TrackingMultiSelectControl.closeMultiselectHeader = () => {
    const data = {
        open: false,
        headerHeight: 0,
        headerContent: undefined,
    };
    ContentHeaderEventHandler.statusEvent.post(data);
    TrackingMultiSelectControl.deSelectAll();
};
TrackingMultiSelectControl.openMultiselectOptionsMenu = (menuElements) => (e) => {
    Measurement.measure(e.currentTarget)
        .then((p) => {
        const client = {
            height: p.height,
            width: p.width,
            x: p.pageX,
            y: p.pageY,
        };
        Menu.instance?.open({ items: menuElements, client });
    })
        .catch((err) => {
        console.debug('cant open Menu', err);
    });
};
TrackingMultiSelectControl.getBulkChangeOptions = () => {
    const maxOptions = Math.floor((ResizeEvent.current.contentWidth - 128) / 36);
    console.log('maxOptions', maxOptions);
    const selectedTypes = new Set();
    for (const e of TrackingMultiSelectControl.allEntries) {
        if (TrackingMultiSelectControl.selectedIDs.has(e.orgEntry.id)) {
            const type = e.type === 'ExtraPayTrackingEntity'
                ? 'extraPay'
                : e.orgEntry.absenceId
                    ? 'absence'
                    : 'timeTracking';
            if (!selectedTypes.has(type))
                selectedTypes.add(type);
            if (selectedTypes.size === 2)
                break;
        }
    }
    const menuElements = [];
    const options = [
        {
            icon: 'clock-start',
            title: I18n.m.getMessage('timeTrackingBulkChangesChangeStart'),
            color: ThemeManager.style.brandPrimary,
            onPress: TrackingMultiSelectControl.openBulkChangeDialog('starttime'),
            disabled: TrackingMultiSelectControl.selectedIDs.size === 0,
        },
        {
            icon: 'clock-end',
            title: I18n.m.getMessage('timeTrackingBulkChangesChangeEnd'),
            color: ThemeManager.style.brandPrimary,
            onPress: TrackingMultiSelectControl.openBulkChangeDialog('endtime'),
            disabled: TrackingMultiSelectControl.selectedIDs.size === 0,
        },
        {
            icon: 'coffee-outline',
            title: I18n.m.getMessage('timeTrackingBulkChangesChangePause'),
            color: ThemeManager.style.brandPrimary,
            onPress: TrackingMultiSelectControl.openBulkChangeDialog('pause'),
            disabled: !selectedTypes.has('timeTracking') || TrackingMultiSelectControl.selectedIDs.size === 0,
        },
        {
            icon: 'domain',
            title: I18n.m.getMessage('timeTrackingBulkChangesChangeProjekt'),
            color: ThemeManager.style.brandPrimary,
            onPress: TrackingMultiSelectControl.openBulkChangeDialog('project'),
            disabled: TrackingMultiSelectControl.selectedIDs.size === 0,
        },
        {
            icon: 'hand-coin-outline',
            title: I18n.m.getMessage('timeTrackingBulkChangesChangeCostCenter'),
            color: ThemeManager.style.brandPrimary,
            onPress: TrackingMultiSelectControl.openBulkChangeDialog('costcenter'),
            disabled: TrackingMultiSelectControl.selectedIDs.size === 0,
        },
        {
            icon: 'briefcase-outline',
            title: I18n.m.getMessage('timeTrackingBulkChangesChangeTask'),
            color: ThemeManager.style.brandPrimary,
            onPress: TrackingMultiSelectControl.openBulkChangeDialog('task'),
            disabled: !selectedTypes.has('timeTracking') || TrackingMultiSelectControl.selectedIDs.size === 0,
        },
        {
            icon: 'numeric',
            title: I18n.m.getMessage('timeTrackingBulkChangesChangeNumber'),
            color: ThemeManager.style.brandPrimary,
            onPress: TrackingMultiSelectControl.openBulkChangeDialog('number'),
            disabled: !selectedTypes.has('extraPay') || TrackingMultiSelectControl.selectedIDs.size === 0,
        },
    ];
    options.push({
        icon: 'delete-outline',
        title: I18n.m.getMessage('bulkChangesDelete'),
        color: ThemeManager.style.brandDanger,
        onPress: TrackingMultiSelectControl.openBulkChangeDialog('delete'),
    });
    const icons = [];
    for (let i = 0; i < options.length; i += 1) {
        const option = options[i];
        const icon = (<Icon key={`bulkChangeOption_${option.title}_${i}`} icon={option.icon} color={option.color} toolTip={option.title} onPress={option.onPress} disabled={option.disabled}/>);
        if (i < maxOptions) {
            icons.push(icon);
        }
        else {
            menuElements.push({
                thumbnail: { thumbnail: icon, width: 40 },
                text: option.title,
                disabled: option.disabled,
                onPress: (e) => {
                    Menu.instance?.close();
                    option.onPress(e);
                },
            });
        }
    }
    if (menuElements.length > 0) {
        icons.push(<Icon key="bulkChangeMenuIcon" icon="dots-vertical" color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('bulkChangesMoreOptions')} onPress={TrackingMultiSelectControl.openMultiselectOptionsMenu(menuElements)}/>);
    }
    return icons;
};
TrackingMultiSelectControl.openMultiselectHeader = (selectedIDs, allEntries, deSelectAll) => {
    TrackingMultiSelectControl.selectedIDs = selectedIDs;
    TrackingMultiSelectControl.allEntries = allEntries;
    TrackingMultiSelectControl.deSelectAll = deSelectAll;
    const options = TrackingMultiSelectControl.getBulkChangeOptions();
    const globalbar = ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM;
    const headerHeight = globalbar
        ? ThemeManager.style.headerHeight + ThemeManager.style.getScreenRelativePixelSize(48)
        : ThemeManager.style.headerHeight;
    const headerContent = (<View style={{
            flexDirection: 'row',
            height: '100%',
            justifyContent: 'space-between',
            paddingLeft: ThemeManager.style.contentPaddingValue,
            paddingRight: ThemeManager.style.contentPaddingValue,
            width: '100%',
            alignItems: 'center',
        }}>
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
        }}>
          <Icon toolTip={I18n.m.getMessage('close')} icon="close" color={ThemeManager.style.brandPrimary} onPress={() => {
            TrackingMultiSelectControl.closeMultiselectHeader();
        }}/>
          <MaterialText color={ThemeManager.style.brandPrimary} centeredText centeredBox>
            {selectedIDs.size}
          </MaterialText>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>{options}</View>
      </View>);
    const data = {
        headerContent,
        open: true,
        headerHeight,
    };
    ContentHeaderEventHandler.statusEvent.post(data);
};
TrackingMultiSelectControl.openBulkChangeDialog = (topic) => (e) => {
    const openPosition = e
        ? { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY }
        : { x: ResizeEvent.current.windowWidth / 2, y: ResizeEvent.current.windowHeight / 2 };
    TrackingMultiSelectControl.bulkChange = topic;
    if (topic !== 'starttime' && topic !== 'endtime' && topic !== 'pause') {
        Dialog.instance?.open({
            closeOnTouchOutside: true,
            fullscreenResponsive: topic !== 'delete' && topic !== 'status',
            openPosition,
            content: (<TimeTrackingBulkChangeDialogContent changedTopic={topic} selectedIDs={TrackingMultiSelectControl.selectedIDs} onChange={() => {
                    TrackingMultiSelectControl.closeMultiselectHeader();
                }} errorDialog={(err) => console.log(err)} allEntries={TrackingMultiSelectControl.allEntries}/>),
            contentPadding: false,
        });
    }
    else {
        TrackingMultiSelectControl.openDatePicker(e);
    }
};
TrackingMultiSelectControl.openDatePicker = (e) => {
    Dialog.instance?.close();
    let label = '';
    if (TrackingMultiSelectControl.bulkChange === 'starttime') {
        label = I18n.m.getMessage('timeTrackingBulkChangesChangeStart');
    }
    else if (TrackingMultiSelectControl.bulkChange === 'endtime') {
        label = I18n.m.getMessage('timeTrackingBulkChangesChangeEnd');
    }
    else if (TrackingMultiSelectControl.bulkChange === 'pause') {
        label = I18n.m.getMessage('timeTrackingBulkChangesChangePause');
    }
    const today = new Date();
    Datepicker.open({
        onChange: TrackingMultiSelectControl.confirmDateChange,
        labelText: label,
        mode: 'time',
        selectedDate: TrackingMultiSelectControl.bulkChange === 'pause'
            ? new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
            : today,
    }, e);
};
TrackingMultiSelectControl.confirmDateChange = (newDate) => {
    Routing.instance.alert.post({
        title: I18n.m.getMessage('timeTrackingBulkChangesConfirmationDialogHeader'),
        text: I18n.m.getMessage('bulkChangesConfirmationDialog', { count: TrackingMultiSelectControl.selectedIDs.size }),
        buttons: [
            <ContainedButton title={I18n.m.getMessage('back')} onPress={(e) => {
                    Alert.instance?.close(() => {
                        TrackingMultiSelectControl.openDatePicker(e);
                    });
                }} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            <ContainedButton title={I18n.m.getMessage('apply')} onPress={() => {
                    Alert.instance?.close(() => {
                        TrackingMultiSelectControl.changeDates(newDate);
                    });
                }}/>,
        ],
    });
};
TrackingMultiSelectControl.changeDates = (newDate) => {
    const promises = [];
    TrackingMultiSelectControl.selectedIDs.forEach((a) => {
        const entry = TrackingMultiSelectControl.allEntries.find((b) => b.id === a);
        if (entry && entry.type === 'TimeTrackingEntity') {
            const data = { changeTime: new Date() };
            const d = entry.starts
                ? new Date(entry.starts.getFullYear(), entry.starts.getMonth(), entry.starts.getDate(), newDate.getHours(), newDate.getMinutes())
                : new Date(newDate);
            if (TrackingMultiSelectControl.bulkChange === 'starttime' && entry.starts)
                data.starts = d;
            if (TrackingMultiSelectControl.bulkChange === 'endtime' && entry.starts)
                data.ends = d;
            if (TrackingMultiSelectControl.bulkChange === 'pause')
                data.pause = { hours: d.getHours(), minutes: d.getMinutes() };
            const c = new ChangeTimeTracking(data, entry.id);
            promises.push({ execute: () => c.execute(AuthClient.instance.commandStore) });
        }
        else if (entry &&
            entry.type === 'ExtraPayTrackingEntity' &&
            entry.extraFieldOrgValue &&
            entry.extraFieldOrgValue['from'] &&
            (TrackingMultiSelectControl.bulkChange === 'starttime' || TrackingMultiSelectControl.bulkChange === 'endtime')) {
            const data = {};
            const newTime = { hours: newDate.getHours(), minutes: newDate.getMinutes() };
            if (TrackingMultiSelectControl.bulkChange === 'starttime')
                data.extraField = { from: newTime, to: entry.extraFieldOrgValue['to'] };
            if (TrackingMultiSelectControl.bulkChange === 'endtime')
                data.extraField = { to: newTime, from: entry.extraFieldOrgValue['from'] };
            const c = new ChangeExtraPayTracking(data, entry.id);
            promises.push({ execute: () => c.execute(AuthClient.instance.commandStore) });
        }
    });
    if (promises.length > 0) {
        openPromisesBulkChangesDialog({
            promises,
            onClose: () => {
                TrackingMultiSelectControl.closeMultiselectHeader();
            },
        })();
    }
};
